import React from 'react';
import { Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DefaultButton from '../Buttons/DefaultButton';

export default function FirstSectionHardworqTrial({
	backgroundImageUrl,
	logoUrl,
	buttonText,
	executeScroll,
	firstTextPaper,
	type,
	buttonProps,
	googleLogo,
	appleLogo,
	browserLogo,
	imageProps,
	hqLogo,
}) {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));
	const matchesXl = useMediaQuery(theme.breakpoints.down('xl'));
	const matchesUpXl = useMediaQuery(theme.breakpoints.up('xl'));
	const isExtensivo24 = window.location.pathname === '/extensivo-r1-2024';

	return (
		<Grid
			container
			alignContent="center"
			justifyContent="center"
			className={classes.firstSection}
			style={
				matchesMobile
					? {
							marginTop: 60,
							backgroundImage: `url(${backgroundImageUrl})`,
							backgroundSize: 'contain',
							backgroundRepeat: 'no-repeat',
					  }
					: matchesTablet
					? {
							backgroundImage: `url(${backgroundImageUrl})`,
							backgroundSize: 'contain',
							backgroundRepeat: 'no-repeat',
					  }
					: {
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'contain',
							height: '100vh',
							backgroundImage: `url(${backgroundImageUrl})`,
					  }
			}
		>
			<Grid
				item
				xs={12}
				md={8}
				container
				alignContent="center"
				// style={matchesMobile ? {alignContent: 'center !important'} : null}
			>
				<Grid
					item
					container
					justifyContent={'center'}
					alignContent={'center'}
					gap={'2rem'}
					onClick={buttonText ? null : isExtensivo24 ? () => executeScroll() : null}
					style={{ maxWidth: matchesUpXl && '80%' }}
				>
					<img
						src={logoUrl}
						alt=""
						className={classes.logo}
						// style={{ width: matchesMobile ? '50%' : imageProps.width }}
					/>
					<Grid
						container
						item
						xs={12}
						md={12}
						justifyContent={matchesMobile ? 'center' : 'start'}
						gap={!matchesMobile && '2rem'}
						style={{
							cursor: !browserLogo && 'pointer',
							marginTop: matchesMobile && '-6rem',
							zIndex: 1,
							right: !matchesMobile && '60px',
							display: matchesMobile && 'flex',
							flexDirection: matchesMobile && 'row',
							flexWrap: matchesMobile && 'nowrap',
							justifyContent: matchesMobile && 'center',
							alignItems: matchesMobile && 'center',
						}}
					>
						<a
							href="https://play.google.com/store/apps/details?id=br.com.hardworkmedicina.app.hardworq"
							target="_blank"
							rel="noopener noreferrer"
							style={{ display: 'flex', justifyContent: 'center' }}
						>
							<img
								src={googleLogo}
								alt=""
								width={matchesMobile && '60%'}
							/>
						</a>
						<a
							href="https://apps.apple.com/br/app/hardworq/id6470359505"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								src={appleLogo}
								alt=""
								width={matchesMobile && '60%'}
							/>
						</a>
						<img
							src={browserLogo}
							alt=""
							width={matchesMobile && '22%'}
							style={{ right: matchesMobile && '1.5rem' }}
						/>
					</Grid>
					<Grid
						container
						item
						xs={12}
						md={12}
						justifyContent={matchesMobile ? 'center' : 'start'}
						gap={'2rem'}
						style={{
							cursor: 'pointer',
							marginTop: matchesMobile && '-3.5rem',
							marginLeft: !matchesMobile && '4rem',
						}}
					>
						<a
							href="https://hardworq.hardworkmedicina.com.br/signin"
							target="_blank"
							rel="noopener noreferrer"
						>
							<Typography
								align="center"
								color="secondary"
								className={`${classes.text} poppins`}
							>
								Acesse o HardworQ aqui
							</Typography>
						</a>
					</Grid>

					{buttonText ? (
						<Grid
							item
							xs={8}
							sm={6}
							container
							justifyContent="center"
							className={`${classes.gridButton} ${
								type === 'red' ? 'residencia' : type === 'green' ? 'revalida' : null
							}`}
							style={
								buttonProps
									? {
											bottom: matchesMobile
												? 'unset'
												: matchesTablet
												? '2.5em'
												: matchesXl
												? '5.5em'
												: buttonProps.bottom,
											left: matchesLg ? '3em' : buttonProps.left,
									  }
									: null
							}
						>
							<DefaultButton
								variant="outlined"
								color={buttonProps ? 'primary' : 'secondary'}
								className={`${classes.btnFirstSection} ${
									buttonProps ? buttonProps.type : 'grid-btn-subscribe btn'
								} ${buttonProps ? 'white-btn-secondary' : 'btn-secondary'} poppins`}
								onClick={() => executeScroll()}
							>
								{buttonProps?.buttonLogoUrl ? (
									<img
										src={buttonProps.buttonLogoUrl}
										alt=""
										className={classes.logoBtn}
									/>
								) : null}
								&nbsp;{buttonText}
							</DefaultButton>
						</Grid>
					) : null}
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
					container
					justifyContent={window.location.pathname === '/migracao-trilha-revalida' ? 'center' : 'flex-start'}
					alignContent="center"
					className={classes.firstSectionCard}
				>
					{window.location.pathname === '/migracao-trilha-revalida' ? (
						<iframe
							// width="560"
							width="940"
							// height="315"
							height={matchesMobile ? '500' : matchesLg ? '400' : '529'}
							src="https://player.vimeo.com/video/857680008"
							title="Vimeo video player"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowfullscreen
							style={{ borderRadius: '20px', width: 'fit-content', border: '2px solid #5f5858' }}
						></iframe>
					) : (
						<Grid
							item
							xs={12}
							container
							justifyContent={matchesTablet ? 'center' : 'flex-end'}
							style={
								window.location.pathname === '/sprint-final-revalida' ||
								window.location.pathname === '/trilha-revalida' ||
								window.location.pathname === '/sprint-final'
									? {
											marginTop: '1rem',
											padding: matchesLg ? 0 : '0 4rem',
									  }
									: matchesMobile && window.location.pathname === '/trilha-r1-2024'
									? { marginBottom: '2rem' }
									: null
							}
						>
							<Paper
								color="primary"
								className={`${classes.firstSectionPaper} box box-primary`}
								elevation={4}
							>
								<Grid
									item
									xs={12}
									className={classes.gridItem}
									container
									justifyContent="flex-start"
									alignContent="center"
								>
									{firstTextPaper}
								</Grid>
							</Paper>
						</Grid>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	firstSection: {
		// maxWidth: '1410px',
		// margin: '20px auto',
		[theme.breakpoints.down('sm')]: {
			padding: '2vh 5% 0 !important',
			margin: '20px auto 0',
		},
	},
	gridButton: {
		position: 'absolute',
		bottom: '6.5em',
		[theme.breakpoints.down('md')]: {
			bottom: '0.5em',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			bottom: '1.5em',
		},
	},
	btnFirstSection: {
		width: '65%',
		fontSize: '1.425rem !important',
		fontWeight: 'bold !important',
		'&:hover': {
			scale: 1.05,
			color: '#ffffff',
			'& $logoBtn': {
				filter: 'invert(0%) !important' /* Linha para passar estilo do pai para o filho */,
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
			minHeight: 'unset !important',
			width: '80%',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
			width: '100%',
		},
	},
	firstSectionCard: {
		height: '100%',
		position: 'absolute',
		right: '0',
		[theme.breakpoints.down('xl')]: {
			right: '-3.5rem',
		},
		[theme.breakpoints.down('md')]: {
			position: 'unset !important',
			right: 'unset !important',
			height: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'unset !important',
			top: '3rem !important',
		},
	},
	firstSectionPaper: {
		color: 'unset !important',
		height: '100%',
		borderRadius: '30px !important',
		padding: '1.5rem 1.5em 2.8em',
		paddingTop: 20,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('lg')]: {
			height: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '1rem !important',
		},
	},
	gridItem: {
		padding: '.5em 0',
		maxWidth: '430px !important',
		[theme.breakpoints.up('xl')]: {
			maxWidth: 'unset !important',
		},
	},
	firstText: {
		fontSize: '3rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
		},
	},
	logo: {
		zIndex: 1,
		right: '9em',
		marginRight: '12rem',
		maxWidth: 498,
		// cursor: 'pointer',
		[theme.breakpoints.up('xl')]: {
			marginRight: '23rem',
		},
		[theme.breakpoints.down('xl')]: {
			// top: '10.75em',
			width: 450,
		},
		[theme.breakpoints.down('lg')]: {
			// top: '5.75em',
			width: 450,
		},
		[theme.breakpoints.down(1160)]: {
			// top: '5.75em',
			width: 400,
		},
		[theme.breakpoints.down('md')]: {
			// top: '5.75em',
			left: 100,
			width: 400,
			marginBottom: '2rem',
		},
		[theme.breakpoints.down('sm')]: {
			width: 300,
			marginBottom: '4rem',
			top: '1.75em',
			left: '6.5em',
		},
	},
	logoBtn: {
		filter: 'invert(100%)',
		height: window.location.pathname === '/migracao-trilha-revalida' ? '1.75rem' : null,
		marginRight: window.location.pathname === '/migracao-trilha-revalida' ? '0.5rem' : null,
		[theme.breakpoints.down('sm')]: {
			width: 25,
		},
		// '&:hover': {
		//  filter: 'invert(0%) !important',
		// },
	},
	text: {
		fontSize: '1rem !important',
		lineHeight: '1 !important',
		marginTop: '8px !important',
		padding: '8px 34px',
		//border: '2px solid',
		borderRadius: '16px',
		display: 'inline-block',
		cursor: 'pointer',
		background: 'linear-gradient(90deg, #FF005D 0%, #D52A8A 50%, #9F38B9 100%)',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
			marginTop: '2rem !important',
		},
	},
}));
