import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { Router } from './Router';
import ScrollToTop from './ScrollToTop';
import HeaderMobile from './Components/HeaderMobile';
import { useMediaQuery, useTheme } from '@mui/material';
import { usePromiseTracker } from 'react-promise-tracker';
import LoadingProgress from './Components/Utils/LoadingProgress';

function App() {
	const theme = useTheme();
	const { promiseInProgress } = usePromiseTracker();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	// const routeName = window.location.pathname;

	let path = window.location.pathname.split('/');
	path = path[1].split('-');

	return (
		<BrowserRouter>
			<ScrollToTop />
			{matchesMobile && path[0] !== 'apphardworq' ? <HeaderMobile /> : null}
			<Router />
			{promiseInProgress ? <LoadingProgress /> : null}
		</BrowserRouter>
	);
}

export default App;
