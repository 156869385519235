import React from 'react';
import { useState } from 'react';

//LIBRARIES
import { Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import DefaultButton from '../Buttons/DefaultButton';

//COMPONENTS
import Api from '../../Services/Api';

//ASSETS
import R3CMCardBackground from '../../Assets/Extensivo/R3CM/cardBackground.png';
import EXTR120232024CardBackground from '../../Assets/Extensivo/R1/2023-24/cardBackground.png';

export default function PriceCardVersion6({ idTurma, logo, iconBtn, bgImageUrl, color, discount, setPromotionalDate, titleArea }) {
	const classes = useStyles(idTurma);
	const theme = useTheme();
	const [turma, setTurma] = useState([]);
	const [lotValueLimit, setLotValueLimit] = useState();
	const [lot, setLot] = useState();
	const [endDate, setEndDate] = useState();

	const currentDate = new Date();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));

	const twoYearsPathname = window.location.pathname.includes('trilha-r1-2024-2025');

	useEffect(() => {
		Api.get(`turmasabertas/${idTurma}`).then((res) => {
			if (res) setTurma(res[0]);
			const activeLot = res[0]?.lotes?.find((lot, index) => {
				return lot?.id === res[0]?.id_lote_vigente;
			});

			if (activeLot) {
				const currentEndDateFormatted = new Date(activeLot?.data_limite);
				setEndDate(currentEndDateFormatted);
			}

			if (setPromotionalDate) setPromotionalDate(activeLot);
			setLot(activeLot);
			setLotValueLimit(new Date(activeLot?.data_limite).getTime());
		});
	}, [idTurma]);

	return (
		<Grid
			container
			alignContent={'center'}
			justifyContent="space-around"
			className={classes.extensivoR1Card}
			style={{
				// border: '2px solid black',
				height: discount ? 'unset' : null,
				backgroundColor: 'black',
			}}
		>
			<Grid
				item
				xs={12}
				// md={7}
				container
				direction={matchesTablet ? '' : 'column'}
				justifyContent="space-around"
				// alignItems="center"
				className={classes.gridImg}
			>
				<Grid
					container
					alignItems={'flex-start'}
					justifyContent={'center'}
					flexDirection={'column'}
                    style={{margin: '2rem 0 4rem 0'}}
				>
					<Typography className={`${classes.priceTextRed} poppins`}>TRILHA R+</Typography>
					<Typography className={`${classes.priceTextWhiteCard} poppins`}>{titleArea}</Typography>
				</Grid>

				<Grid
					item
					xs={12}
					md={5}
					container
					justifyContent="center"
					alignContent="center"
					className={color ? classes.gridPriceInfoBlack : classes.gridPriceInfo}
                    style={matchesMobile ? {marginTop: '-4rem'} : null}
				>
					<Grid
						item
						container
						justifyContent={matchesTablet ? 'center' : 'flex-end'}
						alignContent="center"
						// rowGap={1}
					>
						<Grid
							item
							xs={12}
							className={classes.gridDivider}
                            style={{margin: !matchesMobile ? '-2rem 0 -2rem 0' : '3rem 0'}}
						>
							<Divider
								variant="middle"
								className={classes.divider}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							container
							gap={'1rem'}
							direction={'column'}
							justifyContent={turma?.encerrada ? 'center' : 'flex-start'}
							className={classes.priceContainerInfo}
							style={
								matchesMobile
									? { width: turma?.encerrada ? null : 'unset' }
									: turma?.encerrada
									? { width: '70%', margin: '2rem 0' }
									: {
											width: window.location.pathname === '/trilha-r1-2024' ? '100%' : '70%',
									  }
							}
						>
							<Typography
								className={`${classes.priceTextWhite} poppins`}
								style={matchesMobile ? { paddingTop: '10px' } : null}
							>
								<span style={{ fontSize: matchesTablet ? '2rem' : matchesMobile ? '0.5rem' : '1rem', fontWeight: 'bold', color: 'rgba(106, 106, 106, 1)' }}>Mensal</span>
								<br />
								<strong className={`${classes.priceTextWhiteUnder} poppins`}>
									R$
									{lot
										? lot?.valor?.toLocaleString('pt-BR', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
										  })
										: turma?.valor?.toLocaleString('pt-BR', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
										  })}
								</strong>
								<span style={{ fontSize: '1rem', fontWeight: 'normal' }}>/mês</span>
							</Typography>
						</Grid>
						<>
							<Grid
								item
								xs={12}
								className={classes.gridDivider}
								style={{ marginBottom: '0.5rem' }}
							>
								<Typography className={`${classes.price24x} poppins`}>Sujeito a termos</Typography>
							</Grid>
						</>
						<Grid
							item
							container
							alignContent={'center'}
							justifyContent={'center'}
							style={matchesTablet ? null : { height: '4rem' }}
						>
							<DefaultButton
								variant="outlined"
								color="secondary"
								className={`${classes.btn} btn button-info-card`}
								component={Link}
								to={`//hardworkmedicina.com.br/inscricao/${idTurma}`}
							>
								{matchesMobile ? null : (
									<img
										src={iconBtn}
										alt=""
										className={classes.iconBtn}
									/>
								)}
								&nbsp; Assine agora!
							</DefaultButton>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	extensivoR1Card: {
		borderRadius: 13,
		// width: '50% !important',
		// minHeight: '380px !important',
		[theme.breakpoints.down('lg')]: {
			width: '300px !important',
		},
		[theme.breakpoints.down('md')]: {
			width: '500px !important',
			marginBottom: '2rem',
		},
	},
	gridImg: {
		height: '100%',
		borderBottomLeftRadius: 15,
		paddingTop: '1rem !important',
		[theme.breakpoints.down('md')]: {
			height: 'unset',
		},
		[theme.breakpoints.down('sm')]: {
			padding: 25,
			borderRadius: 25,
		},
	},
	extensivoLogoImg: {
		width: window.location.pathname === '/trilha-r3-clinica-medica' ? 'unset' : '80%',
		maxHeight: '65%',
		[theme.breakpoints.down('md')]: {
			padding: '0px',
			maxHeight: 'unset',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			padding: '0px 10px',
		},
	},
	gridPriceInfo: {
		backgroundColor: '#ffffff !important',
		borderRadius: 15,
		[theme.breakpoints.down('sm')]: {
			padding: '8px 0 ',
		},
	},
	gridPriceInfoBlack: {
		borderBottomRightRadius: 15,
		borderTopRightRadius: 15,
		[theme.breakpoints.down('sm')]: {
			padding: '0 0 8px',
		},
	},
	priceTextStrikeThrough: {
		fontSize: '1.5rem !important',
		color: '#A6A6A6 !important',
		lineHeight: 'unset !important',
		textDecoration: 'line-through',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
    priceTextRed: {
		color: '#fe2121',
        fontStyle: 'italic',
        alignSelf: 'baseline',
        marginLeft: '1em !important',
		fontSize: '1.5rem !important',
		fontWeight: 'bolder !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '2rem !important',
		},
		// [theme.breakpoints.down('sm')]: {
		// 	fontSize: '1rem !important',
		// },
		
	},
	priceTextWhite: {
		color: 'white !important',
		fontSize: '1.2rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
        marginTop: '-3rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
		// [theme.breakpoints.down(376)]: {
		// 	fontSize: '1rem !important',
		// },
	},
    priceTextWhiteCard: {
		color: 'white !important',
		fontSize: '2.25rem !important',
        fontStyle: 'italic',
		fontWeight: 'bold !important',
        marginLeft: '0.5em !important',
        // alignSelf: 'baseline !important',
		lineHeight: '0.9 !important',
		top: '0.25rem',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.8rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '3rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '3.3rem !important',
		},
	},
	priceTextWhiteUnder: {
		color: 'white !important',
		fontSize: '2.25rem !important',
		fontWeight: 'bold !important',
		lineHeight: '0.9 !important',
		top: '0.25rem',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.8rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '3.6rem !important',
		},
        [theme.breakpoints.down(466)]: {
			fontSize: '3rem !important',
		},
		// [theme.breakpoints.down('sm')]: {
		// 	fontSize: '0.75rem !important',
		// },
		// [theme.breakpoints.down(376)]: {
		// 	fontSize: '0.75rem !important',
		// },
	},
	iconBtn: {
		width: '13%',
        [theme.breakpoints.down('md')]: {
            width: '15%',
		},
	},
	btn: {
		width: 'fit-content',
		height: '65%',
		marginBottom: '1rem !important',
		fontSize: '1.35rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		borderColor: 'transparent',
		backgroundColor: '#00AB55 !important',
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				minWidth: '72% !important',
				fontSize: '1.45rem !important',
				backgroundColor: '#00543b !important',
				transition: '0.1s',
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.85rem !important',
			
		},
	},
	discount: {
		fontSize: '1.1rem',
		color: 'red',
		alignSelf: 'center',
		marginTop: '0.5em',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			margin: '0.5em 0',
		},
	},
	disclaimerText: {
		fontSize: '1rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	subscriptionInactiveText: {
		fontSize: '3.25rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
			margin: '1rem !important',
		},
	},
	divider: {
		margin: '0.5rem auto !important',
		backgroundColor: '#6D6D6D',
		minHeight: 1,
        [theme.breakpoints.down('sm')]: {
            minHeight: 2,

		},
	},
	priceContainerInfo: {
		paddingLeft: '2rem',
	},
	gridDivider: {
		paddingRight: '2rem',
		paddingLeft: '2rem',
	},
	price24x: {
		fontSize: '0.8rem !important',
		color: 'rgba(106, 106, 106, 1)',
        textDecoration: 'underline',
        marginTop: '-15px !important',
        marginBottom: '1rem !important',
        [theme.breakpoints.down('md')]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
            marginTop: '0.5rem !important'
		},
	},
}));
