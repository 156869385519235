import { Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useRef } from 'react';
import HostCard from '../Cards/HostCard';

import ArrowBack from '../../Assets/Svg/arrowBack.svg';
import ArrowForward from '../../Assets/Svg/arrowForward.svg';

export default function HostsCarrousel({ Hosts, type }) {
	const classes = useStyles();
	const theme = useTheme();
	const carousel = useRef(null);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const handleLeftClick = (e) => {
		e.preventDefault();

		carousel.current.scrollLeft -= carousel.current.offsetWidth;
	};

	const handleRightClick = (e) => {
		e.preventDefault();

		carousel.current.scrollLeft += 380;
	};

	return (
		<>
			<Grid
				item
				xs={12}
				className={type}
			>
				<Typography
					align="left"
					className={`${classes.title} gradient-text title-card-home`}
				>
					Olha quem vai conduzir o show:
				</Typography>
			</Grid>
			<Grid
				item
				xs={12}
				container
				justifyContent={matchesMobile ? 'space-between' : 'center'}
			>
				<Grid
					item
					className={classes.gridCarouselContainer}
					ref={carousel}
					style={matchesMobile ? { left: 0 } : null}
				>
					{Hosts.map((item, index) => (
						<Grid
							item
							container
							alignContent={'flex-end'}
							key={index}
							style={
								matchesMobile && index === 0
									? { marginRight: 35, right: 0 }
									: index === 8
									? { marginRight: 10 }
									: { marginRight: 35 }
							}
						>
							<HostCard
								ig_username={item.ig_username}
								img={item.img}
								specialty={item.specialty}
								key={index}
							/>
						</Grid>
					))}
				</Grid>
				{/* CARROSSEL INFINITO  */}
				{/* <div class="slider">
					<div class="slide-track">
						<div class="slide">
							<img
								src={require('../../Assets/LDL/fabricioValois.png')}
								height="100"
								width="250"
								alt=""
							/>
						</div>
						<div class="slide">
							<img
								src={require('../../Assets/LDL/fabioColagrossi.png')}
								height="100"
								width="250"
								alt=""
							/>
						</div>
						<div class="slide">
							<img
								src={require('../../Assets/LDL/fabioCabar.png')}
								height="100"
								width="250"
								alt=""
							/>
						</div>
						<div class="slide">
							<img
								src={require('../../Assets/LDL/claudioLima.png')}
								height="100"
								width="250"
								alt=""
							/>
						</div>
						<div class="slide">
							<img
								src={require('../../Assets/LDL/fabricioValois.png')}
								height="100"
								width="250"
								alt=""
							/>
						</div>
						<div class="slide">
							<img
								src={require('../../Assets/LDL/fabioColagrossi.png')}
								height="100"
								width="250"
								alt=""
							/>
						</div>
						<div class="slide">
							<img
								src={require('../../Assets/LDL/fabioCabar.png')}
								height="100"
								width="250"
								alt=""
							/>
						</div>
						<div class="slide">
							<img
								src={require('../../Assets/LDL/claudioLima.png')}
								height="100"
								width="250"
								alt=""
							/>
						</div>
						<div class="slide">
							<img
								src={require('../../Assets/LDL/fabricioValois.png')}
								height="100"
								width="250"
								alt=""
							/>
						</div>
						<div class="slide">
							<img
								src={require('../../Assets/LDL/fabioColagrossi.png')}
								height="100"
								width="250"
								alt=""
							/>
						</div>
						<div class="slide">
							<img
								src={require('../../Assets/LDL/fabioCabar.png')}
								height="100"
								width="250"
								alt=""
							/>
						</div>
						<div class="slide">
							<img
								src={require('../../Assets/LDL/claudioLima.png')}
								height="100"
								width="250"
								alt=""
							/>
						</div>
					</div>
				</div> */}
				{/* CARROSSEL COM SPLIDE */}
				{/* <Splide
					className={classes.splideMainContainer}
					options={{ arrows: true, autoplay: true , rewind: true }}
				>
					{Hosts.map((item, index) => {
						return (
							<SplideSlide key={index}>
								<Grid
									item
									key={item.id}
									className={classes.gridImage}
								>
									<HostCard
										ig_username={item.ig_username}
										img={item.img}
										specialty={item.specialty}
									/>
								</Grid>
							</SplideSlide>
						);
					})}					
				</Splide> */}
				{/* <Grid
					item
					container
					className={classes.gridCarouselContainer}
					ref={carousel}
					style={matchesMobile ? { left: 0 } : { left: -86 }}
				>
					{Hosts.map((item, index) => (
						<Grid
							item
							key={index}
							style={
								matchesMobile && index === 0
									? { marginRight: 0, right: 64 }
									: index === 8
									? { marginRight: 10 }
									: { marginRight: 35 }
							}
						>
							<HostCard
								ig_username={item.ig_username}
								img={item.img}
								specialty={item.specialty}
								key={index}
								classes={classes}
							/>
						</Grid>
					))}
				</Grid> */}
				{/* <Grid
					item
					xs={12}
					style={
						matchesMobile ? { margin: '1em 0' } : { margin: `1em 0 ${type === 'residencia' ? 0 : '1.5em'}` }
					}
				>
					<Typography
						align="left"
						color="secondary"
						className={`${classes.text} poppins`}
					>
						*Sujeito a alterações
					</Typography>
				</Grid> */}
				<Button
					onClick={handleLeftClick}
					className={`${classes.arrowBtn} ${classes.leftArrowBtn}`}
					style={{ top: 200 }}
				>
					<img
						src={ArrowBack}
						alt=""
						className={classes.arrowIcon}
					/>
				</Button>
				<Button
					onClick={handleRightClick}
					className={`${classes.arrowBtn} ${classes.arrowBtnRight}`}
					style={{ top: 200 }}
				>
					<img
						src={ArrowForward}
						alt=""
						className={classes.arrowIcon}
					/>
				</Button>
			</Grid>
		</>
	);
}

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 'bold !important',
		fontSize: '2.5rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
			lineHeight: 'unset !important',
			marginBottom: '8px !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
			lineHeight: 'unset !important',
		},
	},
	splideMainContainer: {
		width: '100%',
		'& .splide__arrow': {
			width: '4em',
			height: '4em',
			marginTop: '23%',
		},
		'& .splide__arrow svg': {
			height: '2em',
			width: '2em',
		},
		'& .splide__pagination': {
			display: 'flex !important',
			bottom: '-0.5em !important',
		},
		'& .splide__track--draggable': {
			margin: '.5em 0 !important',
		},
		'& .splide__slide': {
			display: 'flex',
		},
		[theme.breakpoints.down('sm')]: {
			'& .splide__pagination': {
				bottom: '-1.25em !important',
			},
		},
	},
	gridImage: {
		borderRadius: 15,
		margin: '1rem !important',
	},
	differentSplide: {
		'& .splide__slide': {
			justifyContent: 'flex-end',
		},
	},
	text: {
		fontSize: '1.3125rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	arrowBtn: {
		position: 'absolute !important',
		zIndex: 1000,
		borderRadius: '50% !important',
		width: 80,
		height: 80,
		backgroundColor: '#2D2D2D !important',
		opacity: 0.75,
		'&:hover': { opacity: 1, transition: '0.2s' },
		[theme.breakpoints.down('sm')]: {
			position: 'unset !important',
			zIndex: 'unset',
			width: 60,
			height: 60,
		},
	},
	leftArrowBtn: {
		left: -90,
		top: 150,
		[theme.breakpoints.down('xl')]: {
			left: -90,
		},
		[theme.breakpoints.down(1389)]: {
			left: -50,
		},
		[theme.breakpoints.down('md')]: {
			left: -90,
		},
	},
	arrowBtnRight: {
		right: -15,
		top: 150,
		[theme.breakpoints.down('xl')]: {
			right: -35,
		},
		[theme.breakpoints.down('lg')]: {
			right: -55,
		},
	},
	gridCarouselContainer: {
		display: 'flex !important',
		overflowX: 'hidden',
		scrollBehavior: 'smooth',
		flexWrap: 'nowrap !important',
		[theme.breakpoints.down('sm')]: {
			overflowX: 'auto',
		},
	},
}));
