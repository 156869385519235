import React from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import { Header } from '../../../../Components/Header';

import LogoReactRevalida from '../../../../Assets/Extensivo/Revalida/logo-react-revalida.svg';
import ProductCardWhite from '../../../../Components/Cards/ProductCardWhite';

export default function LiveTipsRevalida02032023() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					alignContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
				>
					<Grid
						item
						xs={12}
						// md={7}
						container
						justifyContent={matchesMobile ? 'center' : 'flex-start'}
						className={`${classes.gridTextContainer} revalida`}
					>
						{matchesMobile ? (
							<img
								src={LogoReactRevalida}
								alt="Logo React Revalida"
							/>
						) : null}
						<Typography
							className={`${classes.title} gradient-text roboto-condensed`}
							color="secondary"
						>
							Dicas Hardwork de última hora
						</Typography>
						<Typography
							className={`${classes.text} poppins`}
							color="secondary"
						>
							Os 20 temas mais quentes que caíram na prova do Revalida Inep nos últimos anos, 1 tema por
							minuto!
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						container
						justifyContent="center"
						style={matchesMobile ? { margin: '20px 0px' } : { margin: '50px 0px 80px' }}
					>
						<iframe
							width="1141"
							height={matchesMobile ? '250' : '641'}
							loading="lazy"
							src="https://www.youtube.com/embed/AS0cC2kYyls"
							title="Live stream video player"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media;"
							allowfullscreen
							style={{ borderRadius: '20px' }}
						></iframe>
					</Grid>
					<Grid
						item
						xs={12}
						container
						// justifyContent="space-between" Apply with 3 Cards
						justifyContent={matchesMobile ? 'center' : 'space-around'}
						className="section"
					>
						{/* <ProductCardWhite
							img={require('../../../../Assets/Arena/arenaDemoCardImage.png')}
							logo={require('../../../../Assets/Arena/logo-arenaHwmDemo.svg').default}
							description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum interdum, est commodo vulputate mollis, turpis magna finibus ex"
							titleButton="Testar demo"
							classes={classes}
						/> */}
						<ProductCardWhite
							img={require('../../../../Assets/Arena/arenaDemoCardImage.png')}
							logo={require('../../../../Assets/Arena/logo-arenaHwm.svg').default}
							description="Preparação para prova prática do revalida inep. Faça a pré-inscrição para ter prioridade nas inscrições e mais chances de garantir a vaga!"
							titleButton="Pré-inscrição"
							classes={classes}
                            url={'//hardworkmedicina.com.br/pre-inscricao/arena-hardwork-2023-1'}
						/>
						<ProductCardWhite
							img={require('../../../../Assets/Extensivo/Revalida/revalidaBannerImage.png')}
							logo={require('../../../../Assets/Extensivo/Revalida/revalida241Logo.svg').default}
							description="Vai fazer a prova do revalida inep de fevereiro de 2023? Agora o extensivo e sprint estão juntos!!! Inscrições abertas"
							titleButton="Saiba mais"
							classes={classes}
							url={'//hardworkmedicina.com.br/extensivo-revalida'}
						/>
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.35rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
			lineHeight: 'unset !important',
			marginBottom: '8px !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.18rem !important',
			marginBottom: '8px !important',
			lineHeight: 'unset !important',
		},
	},
	text: {
		fontSize: '1.324rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	bottomSignInCardText: {
		fontSize: '1.15rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
	},
	divider: {
		margin: 'unset !important',
		backgroundColor: 'grey',
		minHeight: 1,
	},
	workHourTitle: {
		fontSize: '1.7em !important',
		lineHeight: 1.25,
		fontWeight: 'bold !important',
	},
	containerWorkHours: {
		padding: '2em 0 2em 2em',
		[theme.breakpoints.down('lg')]: {
			padding: 0,
		},
	},
	workHourPaper: {
		height: '100%',
		[theme.breakpoints.down('lg')]: {
			height: 'unset !important',
		},
	},
	imgExtensivo: {
		width: '100% !important',
		[theme.breakpoints.down('md')]: {
			margin: '40px 0 !important',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '0px !important',
		},
	},
	impactText: {
		fontSize: '2.25rem !important',
		margin: '24px 0 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.6rem !important',
			margin: '16px 0 !important',
		},
	},
	paper: {
		width: 777,
		marginBottom: 50,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		[theme.breakpoints.down('xl')]: {
			width: 585,
		},
		[theme.breakpoints.down('lg')]: {
			height: 'unset',
			width: 435,
			marginBottom: 30,
		},
		[theme.breakpoints.down('md')]: {
			width: 585,
		},
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
			width: 'unset',
		},
	},
	titleInformationalCard: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		textShadow: '2px 2px 4px #000000',
		margin: '8px 0 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '16px 0 !important',
			lineHeight: 'unset !important',
		},
	},
	gridDescriptionCard: {
		backgroundColor: '#FFFFFF !important',
		padding: '1em 2em',
		borderRadius: 16,
		[theme.breakpoints.down('sm')]: {
			padding: '1em',
		},
	},
	gridForm: {
		width: 567,
		position: 'fixed',
		top: 385,
		zIndex: 10,
		[theme.breakpoints.down(1300)]: {
			width: 467,
			right: 30,
			top: 120,
			// height: 500,
		},
		[theme.breakpoints.down('lg')]: {
			right: 50,
		},
		[theme.breakpoints.down(1010)]: {
			right: 20,
		},
		[theme.breakpoints.down('md')]: {
			position: 'unset',
			right: 'unset',
			top: 'unset',
			zIndex: 0,
		},
	},
	gridTextContainer: {
		paddingBottom: '1em',
		[theme.breakpoints.down('lg')]: {
			paddingRight: '2em',
		},
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
	},
	gridWithBackgroundImage: {
		paddingLeft: 20,
		backgroundImage: `url(${require('../../../../Assets/Extensivo/Revalida/live-react-bg.png')})`,
		[theme.breakpoints.down('md')]: {
			paddingLeft: 'unset',
		},
	},
	paperDescription: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	gridTitle: {
		backgroundColor: '#000000',
		padding: '0px 25px',
		borderTopRightRadius: 20,
		borderTopLeftRadius: 20,
		[theme.breakpoints.down('sm')]: {
			padding: '0px 15px',
		},
	},
	ctaTextCard: {
		fontSize: '1.3rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
			fontWeight: '500 !important',
			margin: '5px 0 !important',
		},
	},
	textDateStyle: {
		borderTopLeftRadius: 20,
		borderBottomRightRadius: 20,
		width: '192px !important',
		[theme.breakpoints.down('sm')]: {
			width: '162px !important',
		},
	},
}));
