import React, { useState } from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import { Header } from '../../../../Components/Header';

import Form from '../../../../Components/Utils/Forms/PreRegistration';

export default function LiveSubscriptionSprintFinal2023() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const [closeGroup] = useState(false);

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					justifyContent={'space-between'}
					alignContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section} ${classes.bg}`}
					style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
				>
					<Grid
						item
						xs={12}
						md={7}
						container
						justifyContent={'flex-start'}
						className={`${classes.gridTextContainer} residencia`}
						gap={1}
					>
						<img
							src={require('../../../../Assets/Extensivo/R1/2023/liveLogo.svg').default}
							alt="Live logo"
							style={matchesMobile ? { width: '20%' } : { width: '10%' }}
						/>
						<Typography
							className={`${classes.title} roboto-condensed`}
							color="secondary"
						>
							Como a inteligência artificial vai
							<br />
							revolucionar a revisão para as provas?
						</Typography>
						<Grid
							item
							xs={12}
							container
							justifyContent={'space-between'}
							alignContent={'flex-end'}
							style={{ marginTop: '1rem' }}
						>
							<Grid
								item
								xs={5}
								container
							>
								<img
									src={require('../../../../Assets/SprintFinal/img-robot.png')}
									alt=""
								/>
								<Grid
									item
									xs={12}
									className={classes.boxImage}
									style={{
										backgroundImage: `url(${
											require('../../../../Assets/SprintFinal/img-ia-box.svg').default
										})`,
									}}
								></Grid>
							</Grid>
							<Grid
								item
								xs={5}
								container
								justifyContent={'center'}
								alignContent={'flex-end'}
							>
								<img
									src={require('../../../../Assets/SprintFinal/icon-plus.svg').default}
									alt=""
									className={classes.plusIcon}
								/>
								<img
									src={require('../../../../Assets/SprintFinal/img-fabricio-thinking.png')}
									alt=""
									style={{ width: '82%' }}
								/>
								<Grid
									item
									xs={12}
									className={classes.boxImage}
									style={{
										backgroundImage: `url(${
											require('../../../../Assets/SprintFinal/img-hwm-intelligence-box.svg')
												.default
										})`,
									}}
								></Grid>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							container
						>
							<Typography
								className={`${classes.text} poppins`}
								color="secondary"
							>
								E tem mais:
								<br />
								<br />
								Faremos também o lançamento oficial do{' '}
								<strong>
									Sprint
									<br />
									Final 2023
								</strong>
								, que trará revisões específicas para mais
								<br />
								de 30 provas de residência, juntando a inteligência
								<br />
								Hardwork com inteligência artificial.
								<br />
								<br />
							</Typography>
							<Typography
								align="left"
								color="secondary"
								className={`${classes.impactText} poppins`}
							>
								TERÇA-FEIRA
								<span className="bullet"> &#8226; </span>
								25JUL
								<span className="bullet"> &#8226; </span>
								20H
							</Typography>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						md={5}
						container
						justifyContent="center"
						className={classes.gridWithBackgroundImage}
						gap={matchesTablet ? 4 : 0}
					>
						<Grid
							item
							xs={12}
							className={classes.gridForm}
						>
							{matchesTablet ? null : (
								<img
									src={require('../../../../Assets/SprintFinal/sprintFinal23.svg').default}
									alt="Logo Sprint Final"
									style={{ width: '90%' }}
								/>
							)}
							<Form
								closeGroup={closeGroup}
								group="LIVE LANÇAMENTO SPRINT FINAL RESIDENCIA 2023"
								title={['Inscreva-se para', <br />, 'participar da live']}
								titleButton={'CONFIRMAR INSCRIÇÃO'}
								type="residencia"
								urlToRedirect="/live/inscricao-sprint-final"
								titleConfirmationMessage={'Massa!'}
								confirmationMessage={
									'Sua inscrição está confirmada! Em breve você também receberá uma confirmação pelo e-mail cadastrado.'
								}
							/>
						</Grid>
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
			marginBottom: '8px !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.3rem !important',
			marginBottom: '8px !important',
		},
	},
	text: {
		fontSize: '1.3125rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	gridForm: {
		zIndex: 10,
		display: 'flex',
		flexDirection: 'column !important',
		alignItems: 'center !important',
		[theme.breakpoints.down('md')]: {
			position: 'unset',
			right: 'unset',
			top: 'unset',
			zIndex: 0,
		},
	},
	gridTextContainer: {
		paddingRight: '4rem',
		height: 'fit-content',
		[theme.breakpoints.down('lg')]: {
			paddingRight: '2em',
		},
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
	},
	gridWithBackgroundImage: {
		[theme.breakpoints.down('md')]: {
			paddingLeft: 'unset',
			backgroundImage: 'unset',
		},
	},
	impactText: {
		margin: '0 0 2rem !important',
		fontSize: '2rem !important',
		lineHeight: 'unset !important',
		fontWeight: 'bold !important',
		background: 'linear-gradient(90deg, rgb(255 72 0) 10%, rgb(255 0 66) 100%)',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.45rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down(361)]: {
			fontSize: '1.25rem !important',
		},
	},
	imgTime: {
		height: 30,
	},
	bg: {
		backgroundImage: `url(${require('../../../../Assets/SprintFinal/bg-live-new-sprint-ia.png')})`,
		backgroundSize: 'cover',
	},
	boxImage: {
		height: 100,
		position: 'absolute',
		width: '100%',
		bottom: 70,
		borderRadius: 10,
		backgroundSize: 'cover',
		[theme.breakpoints.down('xl')]: {
			height: 82,
		},
		[theme.breakpoints.down('lg')]: {
			height: 65,
			bottom: 40,
		},
		[theme.breakpoints.down('sm')]: {
			height: 48,
			bottom: 0,
		},
	},
	plusIcon: {
		position: 'absolute !important',
		bottom: 85,
		left: -96,
		[theme.breakpoints.down('xl')]: {
			width: '20%',
			left: -80,
			bottom: 90,
		},
		[theme.breakpoints.down('lg')]: {
			left: -60,
			bottom: 53,
		},
		[theme.breakpoints.down('md')]: {
			left: -75,
		},
		[theme.breakpoints.down('sm')]: {
			width: '20%',
			left: -45,
			bottom: 7,
		},
	},
}));
