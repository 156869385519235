import { Button, Container, useMediaQuery, useTheme } from '@mui/material';
import { Helmet } from 'react-helmet';
import { Header } from '../../../../Components/Header';
import Footer from '../../../../Components/Footer';
import { useEffect, useState } from 'react';

import Api from '../../../../Services/Api';

import logoHWQ from '../Residencia/assets/logo-hwq.svg';
import logoPetroJu from '../Residencia/assets/logo-juazeiro-petrolina.svg';
import bgCidades from '../Residencia/assets/new-bg-cidades.png';
import DefaultButton from '../../../../Components/Buttons/DefaultButton';

import iconBonus1 from '../Residencia/assets/icon-bonus-1.svg';
import iconBonus2 from '../Residencia/assets/icon-bonus-2.svg';
import iconBonus3 from '../Residencia/assets/icon-bonus-3.svg';
import iconBonus4 from '../Residencia/assets/icon-bonus-4.svg';
import iconSacola from '../Residencia/assets/icon-sacola.svg';

export default function SalePageCidadesJua() {
	const theme = useTheme();

	const [turmaInfo, setTurmaInfo] = useState();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesLaptop = useMediaQuery(theme.breakpoints.down('xl'));

	useEffect(() => {
		const fetchData = async () => {
			const res = await Api.get(`/turmasabertas/1381`);

			setTurmaInfo(res[0]);
		};

		fetchData();
	}, []);

	return (
		<div style={{ backgroundColor: '#020202', minHeight: '100vh' }}>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Helmet>
					<title>Live das Lives 2024</title>
					<meta
						name="description"
						content={''}
					/>
				</Helmet>

				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
					className="residencia"
				>
					{!matchesMobile && <Header />}
				</Container>

				<div
					className="section"
					style={{
						display: 'flex',
						flexDirection: matchesTablet ? 'column' : 'row',
						marginTop: matchesMobile ? 85 : 40,
					}}
				>
					<div
						style={{
							width: '100%',
							height: matchesLaptop ? '100%' : '90vh',
							overflow: matchesLaptop ? 'visible' : 'scroll',
							padding: '0px 0px 60px 0px',
						}}
					>
						<h1
							style={{
								fontFamily: 'Manrope',
								fontSize: matchesTablet ? 33 : 54,
								fontWeight: 800,
								color: 'white',
								whiteSpace: matchesTablet ? 'normal' : 'pre-wrap',
								lineHeight: 0.95,
							}}
						>
							{`O Hardwork vai te visitar\nem Juazeiro e Petrolina!`}
						</h1>

						<p
							style={{
								fontFamily: 'Manrope',
								fontSize: matchesTablet ? 16 : 20,
								color: '#FCFCFC',
								marginTop: 24,
								paddingRight: 32,
								lineHeight: 1.4,
								whiteSpace: matchesTablet ? 'normal' : 'pre-wrap',
							}}
						>
							{`O time que mais entende de preparação com foco em bancas de residência fará um evento PRESENCIAL em Juazeiro e Petrolina, para discutirmos dezenas de temas importantíssimos para a prova de residência médica do ENARE e do SUS-BA!`}
							<br />
							<br />
							{`Uma noite intensa com o time Hardwork para entrar na alma da nova\n banca do ENARE e do SUS-BA.`}
							<br />
							<br />

							<span
								style={{
									fontSize: matchesTablet ? 25 : 32,
									color: '#FCFCFC',
									fontWeight: 800,
									lineHeight: 0.9,
								}}
							>
								24SET • Nobile Suites Del Rio
								{/* <span
									style={{
										color: '#959595',
									}}
								>
									Local a confirmar
								</span> */}
							</span>
							<br />
							<span style={{ fontSize: matchesTablet ? 12 : 16, color: '#FCFCFC', fontWeight: 400 }}>
								Av. Cardoso de Sá, 215 - Orla 2, Petrolina - PE, 56302-110
							</span>
							<br />
							<span
								style={{
									fontSize: matchesTablet ? 12 : 16,
									color: '#FCFCFC',
									fontWeight: 400,
									lineHeight: 0.7,
								}}
							>
								Horário do evento: 18h às 21h
							</span>
						</p>

						<p
							style={{
								fontFamily: 'Manrope',
								fontSize: matchesTablet ? 18 : 23,
								fontWeight: 700,
								color: '#FCFCFC',
								marginTop: 32,
							}}
						>
							O que eu ganharei indo ao Foco ENARE + SUS-BA em Juazeiro e Petrolina?
						</p>

						<div
							style={{
								width: matchesTablet ? '100%' : '90%',
								height: 70,
								backgroundColor: '#303030',
								padding: 16,
								borderRadius: 16,
								margin: '30px 0px 16px 0px',

								display: 'flex',
								alignItems: 'center',
								gap: 20,
							}}
						>
							<img
								src={iconBonus1}
								width={matchesTablet ? 38 : 48}
							/>

							<p style={{ fontFamily: 'Manrope', fontSize: matchesTablet ? 14 : 18, color: '#FCFCFC' }}>
								Discussão PRESENCIAL de mais de 30 temas com foco na nova banca do ENARE.
							</p>
						</div>
						<div
							style={{
								width: matchesTablet ? '100%' : '90%',
								height: 70,
								backgroundColor: '#303030',
								padding: 16,
								borderRadius: 16,
								margin: '16px 0px',

								display: 'flex',
								alignItems: 'center',
								gap: 20,
							}}
						>
							<img
								src={iconBonus2}
								width={matchesTablet ? 38 : 48}
							/>

							<p style={{ fontFamily: 'Manrope', fontSize: matchesTablet ? 14 : 18, color: '#FCFCFC' }}>
								Acesso gratuito e ilimitado ao{' '}
								<span
									className="gradient-text-enare-2"
									style={{ fontWeight: 700 }}
								>
									BANCO DE QUESTÕES
								</span>{' '}
								{!matchesTablet && <br />}
								Hardwork por 90 dias.
							</p>
						</div>
						<div
							style={{
								width: matchesTablet ? '100%' : '90%',
								height: 70,
								backgroundColor: '#303030',
								padding: 16,
								borderRadius: 16,
								margin: '16px 0px',

								display: 'flex',
								alignItems: 'center',
								gap: 20,
							}}
						>
							<img
								src={iconBonus3}
								width={matchesTablet ? 38 : 48}
							/>

							<p style={{ fontFamily: 'Manrope', fontSize: matchesTablet ? 14 : 18, color: '#FCFCFC' }}>
								Acesso gratuito e ilimitado ao{' '}
								<span
									className="gradient-text-enare-2"
									style={{ fontWeight: 700 }}
								>
									BANCO DE AULAS
								</span>{' '}
								Hardwork
								{!matchesTablet && <br />}
								por 30 dias.
							</p>
						</div>
						<div
							style={{
								width: matchesTablet ? '100%' : '90%',
								height: 70,
								backgroundColor: '#303030',
								padding: 16,
								borderRadius: 16,
								margin: '16px 0px',

								display: 'flex',
								alignItems: 'center',
								gap: 20,
							}}
						>
							<img
								src={iconBonus4}
								width={matchesTablet ? 38 : 48}
								style={{ maxHeight: matchesTablet ? 38 : 48 }}
							/>

							<p style={{ fontFamily: 'Manrope', fontSize: matchesTablet ? 14 : 18, color: '#FCFCFC' }}>
								Acesso gratuito e ilimitado ao{' '}
								<span
									className="gradient-text-enare-2"
									style={{ fontWeight: 700 }}
								>
									BANCO DE LIVES
								</span>{' '}
								Hardwork
								{!matchesTablet && <br />}
								por 30 dias.
							</p>
						</div>

						<p style={{ fontFamily: 'Manrope', fontSize: 12, marginTop: 24 }}>
							*Exclusivo para os presentes no evento.
						</p>
					</div>

					<div
						style={{
							width: matchesTablet ? '100%' : '80%',
							height: matchesLaptop ? '100%' : '90vh',
							backgroundImage: `url(${bgCidades})`,
							backgroundSize: 'cover',
							padding: '37px 0px',
							borderRadius: 40,

							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<img src={logoPetroJu} />

						<div
							style={{
								width: matchesTablet ? '95%' : '65%',
								padding: 1,
								marginTop: 65,
								borderRadius: 16,
								background: 'linear-gradient(90deg, #FD2C78 0%, #FF8251 100%)',
							}}
						>
							<div
								style={{
									width: '100%',
									height: '100%',
									background: '#1D1D1D',
									borderRadius: 16,
									padding: '48px 32px',
								}}
							>
								<div style={{ width: '100%' }}>
									<h2
										className="gradient-text-enare-2"
										style={{
											fontFamily: 'Manrope',
											fontSize: matchesTablet ? 28 : 27,
											textWrap: 'nowrap',
											fontWeight: 500,
											lineHeight: 0.95,
											marginBottom: '0.5rem',
										}}
									>
										Foco ENARE + SUS-BA
									</h2>
									<h3
										className="gradient-text-enare-2"
										style={{
											fontFamily: 'Manrope',
											fontSize: matchesTablet ? 46 : 48,
											// textWrap: 'nowrap',
											fontWeight: 800,
											lineHeight: 0.8,
										}}
									>
										Juazeiro e Petrolina
									</h3>
									<h4
										style={{
											fontFamily: 'Manrope',
											fontSize: matchesTablet ? 24 : 24,
											textWrap: 'nowrap',
											fontWeight: 400,
											lineHeight: 1,
											color: '#FCFCFC',

											marginTop: 12,
										}}
									>
										Evento presencial
									</h4>
								</div>

								<div style={{ width: '100%', marginTop: 70 }}>
									{/* <p
										style={{
											fontFamily: 'Manrope',
											fontSize: matchesTablet ? 18 : 24,
											fontWeight: 400,
											color: '#FCFCFC',
											lineHeight: 0.9,
										}}
									>
										À vista por
									</p> */}
									<p
										style={{
											fontFamily: 'Manrope',
											fontSize: matchesTablet ? 40 : 40,
											fontWeight: 800,
											color: '#FCFCFC',
										}}
									>
										Inscrições gratuitas
										{/* R${' '} */}
										{/* {turmaInfo?.valor?.toLocaleString('pt-BR', {
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
										}) || 'Carregando...'} */}
									</p>
								</div>

								<DefaultButton
									type="submit"
									variant="outlined"
									color="primary"
									className={`btn`}
									onClick={() =>
										window.open(`https://hardworkmedicina.com.br/inscricao/1405`, '_blank')
									}
									style={{
										width: '100%',
										height: 40,
										backgroundColor: '#33C96F',
										borderRadius: 30,
										marginTop: 70,

										fontFamily: 'Manrope',
										fontSize: matchesTablet ? 16.8 : 21,
										fontWeight: 700,
										color: 'white',
									}}
								>
									<img
										src={iconSacola}
										style={{ marginRight: 8 }}
									/>
									Inscreva-se
								</DefaultButton>
							</div>
						</div>
					</div>
				</div>

				<Footer />
			</Container>
		</div>
	);
}
