import React from 'react';
import { Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DefaultButton from '../Buttons/DefaultButton';
import { Link } from 'react-router-dom';

export default function ProductCardV2({ type, logo, img, value, buttonText, url }) {
	const classes = useStyles();

	return (
		<Paper
			color="primary"
			className={`${classes.root} box box-primary`}
			elevation={4}
		>
			<Grid
				container
				style={{ height: '100%' }}
			>
				<Grid
					item
					xs={12}
					container
					justifyContent={'center'}
					alignContent={'center'}
					className={classes.gridImage}
					style={{
						backgroundImage: `url(${img})`,
					}}
				>
					<img
						src={logo}
						alt=""
						style={{ width: '68%' }}
					/>
				</Grid>
				<Grid
					item
					container
					justifyContent={'center'}
					alignContent="center"
					className={`${classes.gridButton} residencia`}
				>
					<DefaultButton
						variant="contained"
						color="primary"
						className={`${classes.btn} btn-secondary poppins`}
						component={Link}
						to={url}
					>
						{buttonText}
					</DefaultButton>
				</Grid>
			</Grid>
		</Paper>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		color: 'unset !important',
		minHeight: 265,
		height: '100%',
		width: '100%',
		borderRadius: '30px !important',
		padding: 'unset',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: '#000000 !important',
		// backgroundColor: '#ffffff !important',
		[theme.breakpoints.down('sm')]: {
			minHeight: 160,
		},
	},
	content: {
		fontSize: '1rem !important',
		lineHeight: '1.2 !important',
	},
	btn: {
		width: '37%',
		textTransform: 'unset !important',
		fontWeight: 'bold !important',
		fontSize: '1rem !important',
		transition: '0.2s !important',
		height: '67%',
		border: '3px solid #4c4c4c !important',
		[theme.breakpoints.down('sm')]: {
			width: '50%',
			height: '70%',
		},
	},
	gridImage: {
		height: '80%',
		backgroundSize: 'cover',
		borderTopRightRadius: 30,
		borderTopLeftRadius: 30,
		[theme.breakpoints.down('sm')]: {
			height: '75%',
		},
	},
	gridButton: {
		height: '20%',
		backgroundColor: '#ffffff !important',
		borderBottomLeftRadius: 30,
		borderBottomRightRadius: 30,
		[theme.breakpoints.down('sm')]: {
			height: '25%',
		},
	},
}));
