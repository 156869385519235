import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

export default function MetricBoxInfo({ icon, title, description }) {
	const theme = useTheme();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<Grid
			item
			container
			justifyContent={'center'}
			alignContent={'flex-start'}
			style={{
				border: '1px solid #9E9E9E',
				padding: '2rem 1.5rem',
				borderRadius: 20,
				backgroundColor: '#101010',
			}}
		>
			<img
				src={icon}
				alt=""
				style={{ height: 128 }}
			/>
			<Typography
				color={'#FCFCFC'}
				fontFamily={'Bebas Neue'}
				fontSize={matchesMobile ? '2rem' : '3rem'}
				style={{ lineHeight: 1, marginBottom: '1rem' }}
			>
				{title}
			</Typography>
			<Typography
				color={'#F5F5F5'}
				align="center"
				fontFamily={'poppins'}
				fontSize={matchesMobile ? '0.875rem' : '1rem'}
			>
				{description}
			</Typography>
		</Grid>
	);
}
