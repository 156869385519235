import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../../Components/Footer';
import { Header } from '../../../../../Components/Header';

import Form from '../../../../../Components/Utils/Forms/PreRegistration';
import Api from '../../../../../Services/Api';

export default function EventBuenosAiresRevalida() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesDownXl = useMediaQuery(theme.breakpoints.down('xl'));

	const [closeGroup, setCloseGroup] = useState(false);

	useEffect(() => {
		const group = 'REVALIDA INEP 2023(PRESENCIAL BUENOS AIRES)';
		Api.get(`preinscricao/?agrupamento=${group}`).then((res) => {
			setCloseGroup(res >= 1500);
		});
	}, []);

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					justifyContent={'space-between'}
					alignContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
				>
					{matchesTablet ? null : (
						<img
							src={require('../Assets/bg-fabricio.png')}
							alt=""
							className={classes.fabricioImg}
						/>
					)}
					<Grid
						item
						xs={12}
						md={6}
						container
						justifyContent={'flex-start'}
						className={`${classes.gridTextContainer} revalida`}
						gap={1}
					>
						{matchesMobile && (
							<Grid
								style={{
									position: 'absolute',
									width: '100%',
									height: '100%',
									overflow: 'hidden',
									// backgroundImage: `url(${require('../Assets/bg-fabricio.png')})`,
								}}
							>
								<img
									src={require('../Assets/bg-fabricio.png')}
									alt=""
									className={classes.fabricioImg2}
								/>
							</Grid>
						)}
						{matchesTablet ? (
							<img
								src={
									require('../../../../../Assets/Extensivo/Revalida/logo-revalida-buenos-aires.svg')
										.default
								}
								alt="Logo Revalida Buenos"
								style={matchesDownXl ? null : matchesTablet ? { height: 120 } : null}
							/>
						) : null}
						<Typography
							className={`${classes.title} gradient-text roboto-condensed`}
							color="secondary"
						>
							Uma noite com o show&nbsp;
							{matchesTablet && <br />}
							de didática Hardwork.
						</Typography>
						<Typography
							className={`${classes.text} poppins`}
							color="secondary"
							style={{ marginBottom: matchesMobile ? '1rem' : '3rem' }}
						>
							{matchesTablet ? (
								<>
									O Hardwork vai te visitar em Buenos Aires! Na verdade, vamos te arrepiar. Uma noite
									repleta de discussões de temas quentíssimos da medicina, do melhor jeito, o jeito
									Hardwork.
									<br />
									<br />E tem mais, sorteios especiais para os presentes: Kits Hardwork, Flashcards
									Alexa Hardwork e Trilha Revalida.
								</>
							) : (
								<>
									O Hardwork vai te visitar em Buenos Aires! Na verdade,
									<br />
									vamos te arrepiar.
									<br />
									Uma noite repleta de discussões de temas quentíssimos
									<br />
									da medicina, do melhor jeito, o jeito Hardwork.
									<br />
									<br />
									E tem mais, sorteios especiais para os presentes:
									<br />
									Kits Hardwork, Flashcards Alexa Hardwork e Trilha Revalida.
								</>
							)}
						</Typography>
						<Typography
							color="secondary"
							className={`${classes.text} poppins`}
							style={{ width: '100%' }}
						>
							As inscrições são gratuitas, mas as vagas são limitadas.
						</Typography>
						<Typography
							align="left"
							color="secondary"
							className={`${classes.impactText} poppins`}
						>
							SEXTA-FEIRA
							<span className="bullet"> &#8226; </span>
							16JUN
							<span className="bullet"> &#8226; </span>
							19H
						</Typography>
						<Typography
							align="left"
							color="secondary"
							className={`${classes.impactText2} poppins`}
						>
							ONDE SERÁ O EVENTO?
						</Typography>
						<Typography
							color="secondary"
							className={`${classes.text} poppins`}
							style={{ marginBottom: '1.5rem' }}
						>
							<strong>La Rural, Predio Ferial de Buenos Aires</strong>
							<br />
							Rua Juncal 4431, C1425 CABA, Argentina - Pabellon Rojo
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						md={5}
						container
						justifyContent="center"
						className={classes.gridWithBackgroundImage}
					>
						<Grid
							item
							xs={12}
							className={classes.gridForm}
							gap={1}
						>
							{matchesTablet ? null : (
								<img
									src={
										require('../../../../../Assets/Extensivo/Revalida/logo-revalida-buenos-aires.svg')
											.default
									}
									alt="Logo Revalida Buenos Aires"
									className={classes.logo}
								/>
							)}
							<Form
								closeGroup={closeGroup}
								group="REVALIDA INEP 2023(PRESENCIAL BUENOS AIRES)"
								title={
									closeGroup
										? [
												'VAGAS ESGOTADAS!',
												<br />,
												'Cadastre-se para entrar',
												<br />,
												'na lista de espera.',
										  ]
										: ['Inscreva-se para garantir sua vaga e acompanhar o evento']
								}
								titleButton={closeGroup ? 'Entrar na lista de espera' : 'Confirmar minha vaga'}
								type="revalida"
								urlToRedirect="/materiais/hardwork-revalida-em-buenos-aires"
								confirmationMessage={
									closeGroup
										? 'Seu cadastro na lista de espera está confirmado!'
										: 'Sua inscrição está confirmada! Em breve você também receberá uma confirmação pelo e-mail cadastrado.'
								}
							/>
						</Grid>
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer noMarginBottom />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto 0',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.35rem !important',
		lineHeight: 'unset !important',
		marginBottom: '0.5rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
			lineHeight: 'unset !important',
			marginBottom: '8px !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.18rem !important',
			marginBottom: '8px !important',
			lineHeight: 'unset !important',
		},
	},
	text: {
		fontSize: '1.2rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	gridForm: {
		width: 567,
		display: 'flex',
		flexDirection: 'column !important',
		alignItems: 'center !important',
	},
	gridTextContainer: {
		height: 'fit-content',
		paddingBottom: '1em',
		[theme.breakpoints.down('lg')]: {
			paddingRight: '2em',
		},
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
	},
	gridWithBackgroundImage: {
		paddingLeft: 20,
		paddingBottom: '8rem !important',
		backgroundImage: `url(${require('../../../../../Assets/Extensivo/Revalida/live-react-bg.png')})`,
		[theme.breakpoints.down('xl')]: {
			paddingLeft: 'unset',
		},
		[theme.breakpoints.down('md')]: {
			paddingBottom: 'unset !important',
		},
	},
	impactText: {
		margin: '0 0 2rem !important',
		fontSize: '2.2rem !important',
		lineHeight: 'unset !important',
		background: 'linear-gradient(90deg, rgba(0,145,166,1) 10%, rgba(0,194,110,1) 100%)',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.45rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1rem !important',
		},
	},
	logo: {},
	divider: {
		position: 'absolute',
		border: '1.5px solid #fff !important',
		left: '4.85em',
		backgroundColor: '#fff',
		height: '230px !important',
		zIndex: -1,
		[theme.breakpoints.down('xl')]: {
			height: '200px !important',
		},
		[theme.breakpoints.down('lg')]: {
			height: '280px !important',
		},
		[theme.breakpoints.down('md')]: {
			height: '295px !important',
		},
		[theme.breakpoints.down('sm')]: {
			height: '240px !important',
		},
	},
	imgTime: {
		height: 30,
		top: 5,
	},
	timelineText: {
		fontSize: '1.5rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.2rem !important',
		},
	},
	impactText2: {
		margin: '1.5rem 0 0 !important',
		fontSize: '1.75rem !important',
		lineHeight: 'unset !important',
		fontWeight: 'bold !important',
		background: 'linear-gradient(90deg, rgba(0,145,166,1) 10%, rgba(0,194,110,1) 100%)',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.45rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1rem !important',
		},
	},
	fabricioImg: {
		position: 'absolute',
		left: '28%',
		top: '9%',
		width: '36%',
		opacity: '50%',
	},
	fabricioImg2: {
		left: '28%',
		top: '9%',
		width: '36%',
		opacity: '50%',
		[theme.breakpoints.down('md')]: {
			width: 'unset',
			left: '42%',
			top: '11%',
			opacity: '35%',
		},
		[theme.breakpoints.down('sm')]: {
			left: '29%',
			top: '17%',
		},
	},
}));
