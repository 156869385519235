import React from 'react';
import {
	Button,
	Checkbox,
	FormControl,
	Grid,
	InputBase,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

export default function FilterContainer({
	location,
	locations,
	specialty,
	specialties,
	onContestNameChange,
	onLocationChange,
	onSpecialtyNameChange,
	toggleLocation,
	toggleSpecialty,
	setSelectedLocations,
	setSelectedSpecialties,
}) {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	function capitalize(str) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	return (
		<Grid
			item
			xs={12}
			container
			className={classes.filterContainer}
		>
			<Typography
				align="left"
				color="primary"
				className={`${classes.filterText} poppins`}
			>
				Filtre ou busque seu concurso ou instituição
			</Typography>
			<Grid
				item
				container
				justifyContent={'space-between'}
				alignContent={'center'}
				className={classes.gridSearchBar}
				style={{ marginTop: '0.5rem' }}
			>
				<InputBase
					placeholder="Buscar..."
					classes={{ root: classes.inputRoot, input: classes.inputInput }}
					style={{ '&::placeholder': { color: '#00000099' } }}
					onChange={(e) => onContestNameChange(e.target.value)}
				></InputBase>
			</Grid>
			<Grid
				item
				xs={12}
				container
				gap={matchesMobile ? 2 : 1}
				className={classes.selectContainer}
			>
				<Grid
					item
					container
					xs={12}
				>
					<Typography
						color="primary"
						className={`${classes.textSelectLabel} poppins`}
					>
						Estado
					</Typography>
					<FormControl style={{ width: '100%' }}>
						<InputLabel
							id="test-select-label"
							className={classes.label}
						>
							Escolha os estados
						</InputLabel>
						<Select
							value={location}
							renderValue={(selected) =>
								location.length === locations.length ? 'Todos os estados' : selected.join(', ')
							}
							onChange={(e) => onLocationChange(e.target.value)}
							className={classes.selectMenu}
							inputProps={{ 'aria-label': 'Without label' }}
							displayEmpty
							multiple
						>
							<MenuItem className={`${classes.selectRow} poppins`}>
								<Button
									onClick={() =>
										setSelectedLocations(location.length === locations.length ? [] : locations)
									}
								>
									Marcar/Desmarcar todos
								</Button>
							</MenuItem>
							{locations.map((locationName, index) => {
								return (
									<Grid
										key={index}
										onClick={() => {
											const updatedLocation = toggleLocation(locationName);
											onLocationChange(updatedLocation);
										}}
									>
										<MenuItem
											value={locationName}
											className={`${classes.selectRow} poppins`}
										>
											<Checkbox
												checked={location.includes(locationName)}
												className={classes.checkbox}
											/>
											<ListItemText primary={locationName} />
										</MenuItem>
									</Grid>
								);
							})}
						</Select>
					</FormControl>
				</Grid>
				<Grid
					item
					xs={12}
					container
				>
					<Typography
						color="primary"
						className={`${classes.textSelectLabel} poppins`}
					>
						Especialidade
					</Typography>
					<FormControl style={{ width: '100%' }}>
						<InputLabel
							id="test-select-label"
							className={classes.label}
						>
							Escolha as especialidades
						</InputLabel>
						<Select
							value={specialty}
							renderValue={(selected) =>
								specialty.length === specialties.length
									? 'Todas as especialidades'
									: capitalize(selected.join(', '))
							}
							onChange={(e) => onSpecialtyNameChange(e.target.value)}
							MenuProps={{ classes: { paper: classes.selectMenuDropdown } }}
							className={classes.selectMenu}
							inputProps={{ 'aria-label': 'Without label' }}
							displayEmpty
							multiple
						>
							<MenuItem className={`${classes.selectRow} poppins`}>
								<Button
									onClick={() =>
										setSelectedSpecialties(
											specialty.length === specialties.length ? [] : specialties
										)
									}
								>
									Marcar/Desmarcar todos
								</Button>
							</MenuItem>
							{specialties.map((specialtyName, index) => {
								return (
									<Grid
										key={index}
										onClick={() => {
											const updatedSpecialty = toggleSpecialty(specialtyName);
											onSpecialtyNameChange(updatedSpecialty);
										}}
									>
										<MenuItem
											key={index}
											value={specialty}
											className={`${classes.selectRow} poppins`}
										>
											<Checkbox
												checked={specialty?.includes(specialtyName)}
												className={classes.checkbox}
											/>
											<ListItemText
												primary={capitalize(specialtyName)}
												onClick={() => {
													if (specialty.includes(specialtyName)) {
														onSpecialtyNameChange(
															specialties.filter((esp) => esp !== specialtyName)
														);
													} else {
														onSpecialtyNameChange([...specialties, specialtyName]);
													}
												}}
											/>
										</MenuItem>
									</Grid>
								);
							})}
						</Select>
					</FormControl>
				</Grid>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	filterContainer: {
		backgroundColor: '#fff !important',
		borderRadius: '15px !important',
		padding: '1.5rem !important',
		[theme.breakpoints.down('xl')]: {
			padding: '1.25rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '1rem !important',
		},
	},
	gridSearchBar: {
		height: 40,
		// backgroundColor: '#3A3A3A',
		backgroundColor: '#E1E1E1',
		// borderRadius: 10,
		borderRadius: 4,
		border: '1px solid',
		display: 'flex',
		alignSelf: 'center',
		minHeight: '1.4375em',
		'&:hover': {
			borderColor: '#000000',
		},
		[theme.breakpoints.down('lg')]: {
			height: 30,
		},
	},
	selectContainer: {
		marginTop: '1em !important',
		[theme.breakpoints.down('xl')]: {
			marginTop: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '1em !important',
		},
	},
	textSelectLabel: {
		fontWeight: 'bold !important',
	},
	selectMenu: {
		backgroundColor: '#E1E1E1',
		'& .MuiSelect-select': {
			padding: '12.5px 10px 10px !important',
		},
		[theme.breakpoints.down('sm')]: {
			height: 35,
			width: '100%',
			margin: '0 auto',
		},
	},
	selectRow: {
		padding: '0.1rem 0.25rem !important',
	},
	checkbox: {
		padding: '0.25rem 0.5rem !important',
	},
	label: {
		top: '-5px !important',
		'&.MuiInputLabel-root.Mui-focused': {
			display: 'none',
		},
		'&.MuiInputLabel-root.MuiFormLabel-filled': {
			display: 'none',
		},
	},
	textSelectItem: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	inputRoot: {
		color: 'inherit !important',
		flex: 1,
	},
	inputInput: {
		padding: '8px 12px !important',
		paddingRight: `calc(1em + 12px) !important`,
		transition: theme.transitions.create('width'),
		// color: '#ffffff !important',
		color: '#000000 !important',
		// '&::input-placeholder': {
		// 	color: '#ffffff !important',
		// },
	},
	filterText: {
		fontSize: '1.35rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.2rem !important',
		},
	},
	selectMenuDropdown: {
		[theme.breakpoints.down('sm')]: {
			maxHeight: '90vh !important',
			top: 'unset !important',
			bottom: '0.25rem',
		},
	},
}));
