import React from 'react';
import { useState } from 'react';

//LIBRARIES
import { Divider, Grid, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import DefaultButton from '../Buttons/DefaultButton';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

//COMPONENTS
import Api from '../../Services/Api';
import CountDownTimer from '../../Pages/LandingPages/Sale/LDL/components/CountDownTimer';

//ASSETS
import R3CMCardBackground from '../../Assets/Extensivo/R3CM/cardBackground.png';
import EXTR120232024CardBackground from '../../Assets/Extensivo/R1/2023-24/cardBackground.png';
import IntlTelInput from 'react-intl-tel-input';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';

export default function PriceCardHQTrial({
	group,
	idTurma,
	logo,
	iconBtn,
	bgImageUrl,
	color,
	discount,
	setPromotionalDate,
	playLogo,
	iosLogo,
	pcLogo,
	saleIcon,
}) {
	const classes = useStyles(idTurma);
	const theme = useTheme();
	const [turma, setTurma] = useState([]);
	const [lotValueLimit, setLotValueLimit] = useState();

	const [senha, setSenha] = useState('');
	const [confirmarSenha, setConfirmarSenha] = useState('');
	const [erro, setErro] = useState('');

	const handleSenhaChange = (e) => {
		const valorSenha = e.target.value;
		setSenha(valorSenha);

		// Verifica se a senha tem pelo menos 6 caracteres
		if (valorSenha.length < 6) {
			setErro('A senha deve ter ao menos 6 caracteres');
		} else {
			setErro(''); // Limpa o erro se a senha for válida
		}
	};

	const handleConfirmarSenhaChange = (e) => {
		const valorConfirmarSenha = e.target.value;
		setConfirmarSenha(valorConfirmarSenha);

		// Verifica se as senhas correspondem
		if (senha !== valorConfirmarSenha) {
			setErro('As senhas não correspondem');
		} else if (senha.length >= 6) {
			setErro(''); // Limpa o erro se as senhas forem iguais e válidas
		}
	};

	const [preInscricao, setPreInscricao] = useState({
		agrupamento: group ? `${group} LISTA DE ESPERA` : '',
		nome: '',
		email: '',
		telefone: '',
		senha: '',
	});

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		Api.get(`turmasabertas/${idTurma}`).then((res) => {
			if (res) setTurma(res[0]);
			const activeLot = res[0]?.lotes?.find((lot, index) => {
				return lot?.id === res[0]?.id_lote_vigente;
			});

			if (activeLot) {
				const currentEndDateFormatted = new Date(activeLot?.data_limite);
			}

			if (setPromotionalDate) setPromotionalDate(activeLot);

			setLotValueLimit(new Date(activeLot?.data_limite).getTime());
		});
	}, [idTurma]);

	let sendPreInscricao = function () {
		if (!preInscricao.nome) {
			alert('Ops... Por favor informe seu nome');
			return;
		}
		if (!preInscricao.email || !preInscricao.email.includes('@') || !preInscricao.email.includes('.')) {
			alert('Ops... Por favor preencha seu email');
			return;
		}
		if (!preInscricao.telefone || preInscricao.telefone.length < 11) {
			alert('Ops... Por favor preencha seu telefone');
			return;
		}

		trackPromise(
			Api.post('PreInscricao', preInscricao).then((res) => {
				if (res) {
					Swal.fire({
						title: 'Massa! ',
						text: 'Sua inscrição na lista de espera está confirmada! Avisaremos assim que a nova turma estiver liberada.',
						icon: 'success',
						confirmButtonText: 'Fechar',
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then((result) => {
						if (result.isConfirmed) {
							window.location.reload();
						}
					});
				}
			})
		);
	};

	return (
		<Grid
			container
			alignContent={matchesTablet ? 'space-around' : 'center'}
			justifyContent="space-between"
			className={classes.extensivoR1Card}
			style={
				color
					? {
							border: window.location.pathname === '/trilha-r1-2024' ? null : '2px solid black',
							height: discount ? 'unset' : null,
					  }
					: null
			}
		>
			<Grid
				item
				xs={12}
				md={7}
				container
				direction={window.location.pathname === '/trilha-r3-clinica-medica' ? 'column' : 'row'}
				justifyContent="center"
				alignItems="center"
				className={classes.gridImg}
				style={
					bgImageUrl
						? {
								backgroundImage: `url(${bgImageUrl})`,
								backgroundSize: 'cover',
								borderRadius: 15,
						  }
						: {
								backgroundImage: `url(${
									idTurma === '1174' || idTurma === '1157' || idTurma === '1187' || idTurma === '1203'
										? EXTR120232024CardBackground
										: idTurma === '1175'
										? R3CMCardBackground
										: null
								})`,
						  }
				}
			>
				<img
					src={logo}
					alt=""
					className={classes.extensivoLogoImg}
				/>
				<Grid
					item
					className="background-gradient-residencia"
					style={{
						borderRadius: 4,
						width: 'fit-content',
						padding: '0.5rem 1rem',
						marginTop: matchesTablet && '0.75rem',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'nowrap',
							gap: '2rem',
							marginTop: matchesMobile ? '0.4rem' : '1em',
						}}
					>
						<a
							href="https://play.google.com/store/apps/details?id=br.com.hardworkmedicina.app.hardworq"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								src={playLogo}
								alt=""
								width={matchesMobile && '150%'}
							/>
						</a>
						<a
							href="https://apps.apple.com/br/app/hardworq/id6470359505"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								src={iosLogo}
								alt=""
								width={matchesMobile && '150%'}
							/>
						</a>
						<div href="">
							<img
								src={pcLogo}
								alt=""
								width={matchesMobile && '150%'}
							/>
						</div>
					</div>
				</Grid>
			</Grid>
			<Grid
				item
				xs={12}
				md={5}
				container
				justifyContent="center"
				alignContent="center"
				gap={2}
				className={color ? classes.gridPriceInfoBlack : classes.gridPriceInfo}
				style={
					turma?.encerrada
						? { padding: '1em 0' }
						: discount
						? { padding: matchesTablet ? 0 : '1.5rem 0' }
						: { paddingTop: lotValueLimit !== undefined ? null : '2rem' }
				}
			>
				{/* <form
					className="form-control"
					action="javascript:;"
				>
					<Grid
						item
						container
						justifyContent={'center'}
						alignContent="center"
						rowGap={1}
					>
						<div
							style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '20px' }}
						>
							<p style={{ fontFamily: 'Manrope', color: 'white' }}>Inscreva-se e acesse a versão</p>
							<p style={{ fontFamily: 'Manrope', color: 'white' }}>Free Trial por 90 dias</p>
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: matchesMobile ? '0.4rem' : '1rem',
								width: '75%',
							}}
						>
							<input
								type="text"
								name=""
								id=""
								placeholder="Nome completo"
								style={{
									backgroundColor: '#1D1D1D',
									border: '1px solid #303030',
									borderRadius: '8px',
									fontFamily: 'Manrope',
									color: '#CCC',
									fontSize: '12px',
									padding: '8px',
								}}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										nome: e.target.value,
									})
								}
							/>
							<input
								type="email"
								name=""
								id=""
								placeholder="E-mail"
								style={{
									backgroundColor: '#1D1D1D',
									border: '1px solid #303030',
									borderRadius: '8px',
									fontFamily: 'Manrope',
									color: '#CCC',
									fontSize: '12px',
									padding: '8px',
								}}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										nome: e.target.value,
									})
								}
							/>
							<input
								type="tel"
								name=""
								id=""
								placeholder="Celular"
								style={{
									backgroundColor: '#1D1D1D',
									border: '1px solid #303030',
									borderRadius: '8px',
									fontFamily: 'Manrope',
									color: '#CCC',
									fontSize: '12px',
									padding: '8px',
								}}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										nome: e.target.value,
									})
								}
							/>
							<input
								type="password"
								placeholder="Senha"
								style={{
									backgroundColor: '#1D1D1D',
									border: '1px solid #303030',
									borderRadius: '8px',
									fontFamily: 'Manrope',
									color: '#CCC',
									fontSize: '12px',
									padding: '8px',
								}}
								onChange={handleSenhaChange}
								value={senha}
							/>
							<input
								type="password"
								placeholder="Digite novamente a Senha"
								style={{
									backgroundColor: '#1D1D1D',
									border: '1px solid #303030',
									borderRadius: '8px',
									fontFamily: 'Manrope',
									color: '#CCC',
									fontSize: '12px',
									padding: '8px',
								}}
								onChange={handleConfirmarSenhaChange}
								value={confirmarSenha}
							/>
							<span style={{ fontFamily: 'Manrope', fontSize: '12px', color: '#9E9E9E' }}>
								A senha deve ter ao menos 6 caracteres
							</span>
						</div>
						<Grid
							item
							container
							alignContent={'center'}
							justifyContent={'center'}
							style={matchesTablet ? null : { height: '4rem' }}
						>
							<DefaultButton
								variant="outlined"
								color="secondary"
								className={`${classes.btn} btn button-info-card`}
								onClick={() => sendPreInscricao()}
							>
								{matchesMobile ? null : (
									<img
										src={saleIcon}
										alt=""
										className={classes.iconBtn}
									/>
								)}
								&nbsp; Testar grátis!
							</DefaultButton>
						</Grid>
					</Grid>
				</form> */}
				<Grid
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Typography
						style={{
							fontFamily: 'Manrope',
							color: 'white',
							fontWeight: 500,
							fontSize: '28px',
						}}
					>
						Cadastre-se e <span style={{ display: 'inline', color: '#F92FA8' }}>teste</span>
					</Typography>

					<Typography
						style={{
							fontFamily: 'Manrope',
							color: 'white',
							fontWeight: 500,
							fontSize: '28px',
						}}
					>
						<span style={{ display: 'inline', color: '#F92FA8' }}>grátis</span> durante 90 dias!
					</Typography>
				</Grid>
				<Grid
					item
					container
					alignContent={'center'}
					justifyContent={'center'}
					style={matchesTablet ? null : { height: '4rem' }}
				>
					<DefaultButton
						variant="outlined"
						color="secondary"
						className={`${classes.btn} btn button-info-card`}
						// onClick={() => (window.location.href = 'https://hardworq.hardworkmedicina.com.br/signin')}
					>
						<a
							href="https://hardworq.hardworkmedicina.com.br/signin"
							target="_blank"
							rel="noopener noreferrer"
							style={{ textDecoration: 'none', color: 'white' }}
						>
							&nbsp; Testar grátis!
						</a>
					</DefaultButton>
				</Grid>
				<Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '10px' }}>
					<Typography style={{ alignSelf: 'center' }}>
						Já tem cadastro? <span style={{ color: 'white', display: 'inline' }}>Ative</span> o teste grátis
						dentro da plataforma.
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	extensivoR1Card: {
		borderRadius: 13,
		[theme.breakpoints.down('md')]: {
			height: 'unset',
		},
	},
	gridImg: {
		height: '100%',
		borderBottomLeftRadius: 15,
		paddingTop: '1rem !important',
		[theme.breakpoints.down('md')]: {
			height: 'unset',
		},
		[theme.breakpoints.down('sm')]: {
			padding: 25,
			borderRadius: 25,
		},
	},
	extensivoLogoImg: {
		width: window.location.pathname === '/trilha-r3-clinica-medica' ? 'unset' : '80%',
		maxHeight: '65%',
		[theme.breakpoints.down('md')]: {
			padding: '0px',
			maxHeight: 'unset',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			padding: '0px 10px',
		},
	},
	gridPriceInfo: {
		backgroundColor: '#ffffff !important',
		borderRadius: 15,
		[theme.breakpoints.down('sm')]: {
			padding: '8px 0 ',
		},
	},
	gridPriceInfoBlack: {
		paddingTop: '1rem',
		borderBottomRightRadius: 15,
		borderTopRightRadius: 15,
		[theme.breakpoints.down('sm')]: {
			padding: '0 0 8px',
		},
	},
	priceTextStrikeThrough: {
		fontSize: '1.5rem !important',
		color: '#A6A6A6 !important',
		lineHeight: 'unset !important',
		textDecoration: 'line-through',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	priceTextWhite: {
		color: 'white !important',
		fontSize: '1.2rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down(376)]: {
			fontSize: '1rem !important',
		},
	},
	priceTextWhiteUnder: {
		color: 'white !important',
		fontSize: '4.25rem !important',
		fontWeight: 'bold !important',
		lineHeight: '0.9 !important',
		top: '0.25rem',
		[theme.breakpoints.down('lg')]: {
			fontSize: '3rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '3.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.75rem !important',
		},
		[theme.breakpoints.down(376)]: {
			fontSize: '2.75rem !important',
		},
	},
	iconBtn: {
		width: '7%',
	},
	btn: {
		width: '75%',
		height: '65%',
		marginBottom: '1rem !important',
		fontSize: '1.35rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		borderColor: 'transparent',
		backgroundColor: '#00AB55 !important',
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				minWidth: '72% !important',
				fontSize: '1.5rem !important',
				backgroundColor: '#00543b !important',
				transition: '0.1s',
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.2rem !important',
			height: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			width: '90%',
		},
	},
	discount: {
		fontSize: '1.1rem',
		color: 'red',
		alignSelf: 'center',
		marginTop: '0.5em',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			margin: '0.5em 0',
		},
	},
	disclaimerText: {
		fontSize: '1rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	subscriptionInactiveText: {
		fontSize: '3.25rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
			margin: '1rem !important',
		},
	},
	divider: {
		margin: '0.5rem auto !important',
		backgroundColor: '#6D6D6D',
		minHeight: 1,
	},
	priceContainerInfo: {
		paddingLeft: '2rem',
	},
	gridDivider: {
		paddingRight: '2rem',
		paddingLeft: '2rem',
	},
	price24x: {
		fontSize: '1rem !important',
		color: 'grey !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	containerWaitList: {
		width: '100% !important',
		backgroundColor: '#d6cece',
		zIndex: 1,
		borderRadius: 15,
		padding: '0.5rem 1.25rem',
		boxShadow: '0px 0px 30px 0px #000000',
		border: '2px solid #000000',
		position: 'absolute',
		bottom: 0,
		[theme.breakpoints.down('sm')]: {
			padding: '1.5rem 1.25rem',
		},
	},
	textWaitList: {
		fontSize: '1.3rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.95rem !important',
		},
	},
	form: {
		borderRadius: '8px !important',
		backgroundColor: '#E1E1E1 !important',
		margin: '5px 0 !important',
	},
	formHeight: {
		maxHeight: '3em',
	},
	phoneNumberForm: {
		height: '3em',
		borderRadius: 8,
		width: '100%',
		border: 'unset',
	},
	cancelIcon: {
		color: '#000000',
		right: '-15px',
		top: '-5px',
		'&:hover': {
			cursor: 'pointer',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'absolute',
			top: '-20px',
			right: '-18px',
		},
	},
	btnWaitList: {
		fontSize: '1rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		backgroundColor: '#00AB55 !important',
		border: '1px solid #707070 !important',
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				minWidth: '72% !important',
				fontSize: '1.025rem !important',
				backgroundColor: '#00543b !important',
				transition: '0.1s',
			},
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
}));
