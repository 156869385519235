import React from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import { Header } from '../../../../Components/Header';

import Form from '../../../../Components/Utils/Forms/PreRegistration';

export default function SemanaEletrocardiograma() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					justifyContent={'space-between'}
					alignContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
				>
					<Grid
						item
						xs={12}
						md={6}
						container
						style={{ paddingBottom: matchesTablet && '6rem' }}
					>
						<img
							src={require('./Assets/semana-eletrocardiograma.png')}
							alt=""
						/>
						<Grid className={classes.gridText}>
							<Typography
								color="secondary"
								className={`${classes.title} poppins`}
							>
								SEMANA ELETROCARDIOGRAMA
							</Typography>
							<Typography
								color="secondary"
								className={`${classes.text} poppins`}
							>
								Domine todos os traçados
							</Typography>
							<Typography
								color="secondary"
								style={{ fontSize: '1.5rem' }}
								className={`${classes.impactText} poppins`}
							>
								06MAIO A 10MAIO
							</Typography>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						md={5}
						container
						justifyContent="center"
						className={classes.gridWithBackgroundImage}
					>
						<Grid
							item
							xs={12}
							className={classes.gridForm}
							gap={1}
						>
							<Form
								group="SEMANA ELETROCARDIOGRAMA"
								title={['Inscreva-se gratuitamente!']}
								titleButton="Confirmar inscrição"
								type="r-plus"
								urlToRedirect="/materiais/semana-eletrocardiograma"
								confirmationMessage={
									'Sua inscrição está confirmada! Te avisaremos momentos antes da live.'
								}
							/>
						</Grid>
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto 10%',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem !important',
			lineHeight: 'unset !important',
		},
	},
	text: {
		fontSize: '1.75rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
		},
	},
	gridWithBackgroundImage: {
		paddingLeft: 20,
		[theme.breakpoints.down('md')]: {
			paddingLeft: 'unset',
		},
	},
	gridText: {
		position: 'absolute',
		bottom: -70,
		[theme.breakpoints.down('md')]: {
			bottom: 0,
		},
	},
	impactText: {
		margin: '0 0 2rem !important',
		fontSize: '2.2rem !important',
		lineHeight: 'unset !important',
		width: 'fit-content !important',
		background:
			'linear-gradient(90deg, rgba(255,0,93,0.7) 10%, rgba(213,42,138,0.7) 50%, rgba(136, 55,182, 0.7) 100%)',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.45rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1rem !important',
		},
	},
	divider: {
		position: 'absolute',
		border: '1.5px solid #fff !important',
		left: '4.85em',
		backgroundColor: '#fff',
		height: '230px !important',
		zIndex: -1,
		[theme.breakpoints.down('xl')]: {
			height: '200px !important',
		},
		[theme.breakpoints.down('lg')]: {
			height: '280px !important',
		},
		[theme.breakpoints.down('md')]: {
			height: '295px !important',
		},
		[theme.breakpoints.down('sm')]: {
			height: '240px !important',
		},
	},
}));
