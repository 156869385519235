import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

const CountDownTimer = ({ targetDate, textColor, executeScroll }) => {
	const classes = useStyles();
	const calculateTimeLeft = (targetDate) => {
		const now = moment().utc();
		const difference = moment(targetDate).utc().diff(now);

		if (difference <= 0 || isNaN(difference)) {
			// Contagem regressiva terminou
			return { days: 0, hours: 0, minutes: 0, seconds: 0 };
		}

		const days = Math.floor(difference / (1000 * 60 * 60 * 24));
		const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((difference % (1000 * 60)) / 1000);

		return { days, hours, minutes, seconds };
	};
	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));

	useEffect(() => {
		const timer = setInterval(() => {
			setTimeLeft(calculateTimeLeft(targetDate));
		}, 1000);

		return () => {
			clearInterval(timer);
		};
	}, [targetDate]);

	const totalHours = timeLeft?.days * 24 + timeLeft?.hours;

	// useEffect(() => {
	// 	if (
	// 		(totalHours === 0) &
	// 		(timeLeft?.days === 0) &
	// 		(timeLeft?.hours === 0) &
	// 		(timeLeft?.minutes === 0) &
	// 		(timeLeft?.seconds === 0)
	// 	) {
	// 		window.location.reload();
	// 		executeScroll();
	// 	}
	// }, [totalHours, timeLeft]);

	return (
		<>
			<Grid>
				<Grid
					display={'flex'}
					item
					rowGap={'3'}
					alignContent={'space-between'}
				>
					{totalHours > 23 && (
						<>
							<Grid
								container
								justifyContent="center"
								className={classes.gridContainer}
							>
								<Grid
									item
									container
									justifyContent={'center'}
									alignContent={'center'}
									className={classes.containerTime}
								>
									<Typography
										variant="h4"
										className={`${classes.textNumber} poppins`}
									>
										{timeLeft?.days}
									</Typography>
								</Grid>
								<Grid
									item
									container
									justifyContent={'center'}
									alignContent={'center'}
								>
									<Typography
										color={textColor}
										display={'flex'}
										justifyContent={'center'}
										className={`${classes.text} poppins`}
									>
										dias
									</Typography>
								</Grid>
							</Grid>
							<Grid
								item
								container
								justifyContent={'center'}
								alignContent={'center'}
								className={classes.containerSeparator}
							>
								<Typography
									className={`${classes.separatorText} poppins`}
									color={textColor}
								>
									:
								</Typography>
							</Grid>
						</>
					)}

					<Grid
						container
						justifyContent="center"
						className={classes.gridContainer}
					>
						<Grid
							item
							container
							justifyContent={'center'}
							alignContent={'center'}
							className={classes.containerTime}
						>
							<Typography
								variant="h4"
								className={`${classes.textNumber} poppins`}
							>
								{totalHours > 23 ? timeLeft?.hours : totalHours}
							</Typography>
						</Grid>
						<Grid
							item
							container
							justifyContent={'center'}
							alignContent={'center'}
							style={{ width: 'fit-content' }}
						>
							<Typography
								color={textColor}
								display={'flex'}
								justifyContent={'center'}
								className={`${classes.text} poppins`}
							>
								horas
							</Typography>
						</Grid>
					</Grid>

					<Grid
						item
						container
						justifyContent={'center'}
						alignContent={'center'}
						className={classes.containerSeparator}
					>
						<Typography
							className={`${classes.separatorText} poppins`}
							color={textColor}
						>
							:
						</Typography>
					</Grid>

					<Grid
						container
						justifyContent="center"
						className={classes.gridContainer}
					>
						<Grid
							item
							container
							justifyContent={'center'}
							alignContent={'center'}
							className={classes.containerTime}
						>
							<Typography
								variant="h4"
								className={`${classes.textNumber} poppins`}
							>
								{timeLeft?.minutes}
							</Typography>
						</Grid>
						<Grid
							item
							container
							style={{ width: 'fit-content' }}
						>
							<Typography
								color={textColor}
								display={'flex'}
								justifyContent={'center'}
								className={`${classes.text} poppins`}
							>
								minutos
							</Typography>
						</Grid>
					</Grid>

					<Grid
						item
						container
						className={classes.containerSeparator}
						justifyContent={'center'}
						alignContent={'center'}
					>
						<Typography
							className={`${classes.separatorText} poppins`}
							color={textColor}
						>
							:
						</Typography>
					</Grid>

					<Grid
						container
						justifyContent="center"
						className={classes.gridContainer}
					>
						<Grid
							item
							container
							justifyContent={'center'}
							alignContent={'center'}
							className={classes.containerTime}
						>
							<Typography
								variant="h4"
								className={`${classes.textNumber} poppins`}
							>
								{timeLeft?.seconds}
							</Typography>
						</Grid>

						<Grid
							item
							container
							style={{ width: 'fit-content' }}
						>
							<Typography
								color={textColor}
								display={'flex'}
								justifyContent={'center'}
								className={`${classes.text} poppins`}
							>
								segundos
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

const useStyles = makeStyles((theme) => ({
	containerTime: {
		backgroundColor: '#222222',
		borderRadius: '6px',
		color: '#ffffff',
		width: '3rem !important',
		height: '2rem',
		[theme.breakpoints.down('sm')]: {
			width: '2.75rem !important',
		},
	},
	textNumber: {
		fontWeight: 'bold !important',
		fontSize: '0.9rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
		[theme.breakpoints.down(361)]: {
			fontSize: '0.8rem !important',
		},
	},
	text: {
		fontWeight: 'bold !important',
		fontSize: '0.7rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.65rem !important',
		},
		[theme.breakpoints.down(361)]: {
			fontSize: '0.6rem !important',
		},
	},
	containerSeparator: {
		color: '#ffffff',
		width: '1.2rem !important',
		height: '1.8rem',
	},

	separatorText: {
		fontWeight: 'bold !important',
		fontSize: '1.3rem !important',
	},
	textTimer: {
		textTransform: 'uppercase',
		fontWeight: 'bold',
		width: '3rem',
		height: '1rem',
		fontSize: '0.55rem',
	},
	gridContainer: {
		width: '3rem !important',
		[theme.breakpoints.down('sm')]: {
			width: '2.5rem !important',
		},
		[theme.breakpoints.down(361)]: {
			width: '2.25rem !important',
		},
	},
}));

export default CountDownTimer;

//Chamada no card
// const targetDate = new Date('2023-09-22T18:19:59').getTime();
//<CountDownTimer targetDate={targetDate} />
