import React from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import { Header } from '../../../../Components/Header';

import Register from '../../../../Components/Utils/Forms/Register';

export default function LiveBank() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					alignContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
				>
					<Grid
						item
						xs={12}
						md={6}
						container
						className={`${classes.titleContainer} residencia`}
						gap={2}
					>
						<Typography
							className={`${classes.title} gradient-text roboto-condensed`}
							color="secondary"
							// style={matchesTablet ? { marginTop: 16, paddingLeft: 0 } : { paddingLeft: 15 }}
						>
							Banco de lives
							<br />
							Hardwork Medicina
						</Typography>
						<Typography
							align="left"
							color="secondary"
							className={`${classes.text} poppins`}
						>
							Tenha acesso à biblioteca de lives do Hardwork e assista todas as lives já feitas em nosso
							Instagram.
							<br />
							<br />
							Com conteúdos completos e atualizados de todas as grandes áreas da medicina, você conseguirá
							trilhar uma rota de aprendizagem sem igual, com a didática incomparável do Hardwork
							Medicina.
							<br />
							<br />
							Cadastre-se no formulário para ter acesso gratuito!
						</Typography>
						<img
							src={require('./assets/img-banco-de-lives.png')}
							alt="Live"
							className={classes.img}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
						container
						justifyContent="center"
						alignContent="flex-end"
						className={classes.formContainer}
					>
						<Register
							tags={['BANCO DE LIVES']}
							title={
								matchesMobile
									? ['Cadastre-se abaixo para ter acesso ao banco de lives com conteúdos gratuitos!']
									: [
											'Cadastre-se abaixo para ter acesso ao',
											<br />,
											'banco de lives com conteúdos gratuitos!',
									  ]
							}
							titleButton="Confirmar inscrição"
							type="residencia"
							urlToRedirect="//hardworkmedicina.com.br/banco-de-lives"
						/>
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.8rem !important',
		lineHeight: '1 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '3.2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.8rem !important',
		},
	},
	text: {
		fontSize: '1.324rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	bottomSignInCardText: {
		fontSize: '1.15rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
	},
	divider: {
		margin: 'unset !important',
		backgroundColor: 'grey',
		minHeight: 1,
	},
	workHourTitle: {
		fontSize: '1.7em !important',
		lineHeight: 1.25,
		fontWeight: 'bold !important',
	},
	containerWorkHours: {
		padding: '2em 0 2em 2em',
		[theme.breakpoints.down('lg')]: {
			padding: 0,
		},
	},
	workHourPaper: {
		height: '100%',
		[theme.breakpoints.down('lg')]: {
			height: 'unset !important',
		},
	},
	img: {
		marginTop: '1.5em',
		width: '100%',
	},
	impactText: {
		fontSize: '2.25rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.6rem !important',
		},
	},
	titleContainer: {
		paddingBottom: '1em',
		paddingRight: '6em',
		[theme.breakpoints.down('sm')]: {
			paddingRight: 0,
		},
	},
	formContainer: {
		paddingBottom: 50,
		paddingLeft: '4em',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: 0,
		},
	},
}));
