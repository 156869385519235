import React, { useEffect, useRef, useState } from 'react';
import { Box, Container, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Header } from '../../../../Components/Header';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import CountUp from 'react-countup';
import Footer from '../../../../Components/Footer';
import { Helmet } from 'react-helmet';
import FirstSectionHWSolidario from '../../../../Components/Sections/FirstSectionHWSolidario';

import Api from '../../../../Services/Api';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import DefaultButton from '../../../../Components/Buttons/DefaultButton';

import BottleIcon from './assets/bottle.svg';
import Form from '../../../../Components/Utils/Forms/PreRegistration';
import BannerProductStatic from './components/BannerProductStatic';

import listYduqs from './assets/list-yduqs.png';
import listYduqsMobile from './assets/list-yduqs-mobile.png';

export default function TemplateHwmSolidario() {
	const classes = useStyles();
	const theme = useTheme();
	const doacaoQrCode = useRef(null);

	const [atLeastOneIntoArray, setAtLeastOneIntoArray] = useState(false);
	const [triggerAtualization, setTriggerAtualization] = useState(false);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const [scroll, setScroll] = useState(false);
	const [waterDonationCount, setWaterDonationCount] = useState(0);
	const [progress, setProgress] = useState(0);
	const hardworkValue = 20000;
	const [meta, setMeta] = useState(30000);

	const progressValue = (waterDonationCount / meta) * 100;

	const allGroupments =
		'HW SOLIDARIO - SEMANA TEP|HW SOLIDARIO - SEMANA TEGO|HW SOLIDARIO - LIVE DAS LIVES - REVALIDA INEP|HW SOLIDARIO - ENARE-DOMINADO';

	useEffect(() => {
		const timer = setInterval(() => {
			setProgress((prevProgress) => {
				const newProgress = prevProgress + 2;
				return newProgress >= progressValue ? progressValue : newProgress;
			});
		}, 100);

		return () => {
			clearInterval(timer);
		};
	}, [waterDonationCount, progressValue]);

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.scrollY;
			if (scrollPosition > 10) {
				setScroll(true);
			} else {
				setScroll(false);
			}
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	useEffect(() => {
		Api.get(`preinscricao/${encodeURIComponent(`${allGroupments}`)}`)
			.then((responses) => {
				// const sum = responses.reduce((acc, res) => acc + res, 0);
				setWaterDonationCount(responses + hardworkValue);
			})
			.catch((error) => {
				// Trate os erros aqui, se necessário
				console.error('Erro ao buscar os dados da API:', error);
			});
	}, [triggerAtualization]);

	useEffect(() => {
		setAtLeastOneIntoArray(false);
	}, []);

	const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
		height: 12,
		borderRadius: 5,
		width: '90%',
		border: '1.5px solid #FFFFFF',
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor: 'rgba(0,0,0,0.1)',
		},
		[`& .${linearProgressClasses.bar}`]: {
			borderRadius: 5,
			backgroundColor: '#FFFFFF',
		},
	}));

	const data = [
		// {
		// 	logo: require('./assets/logo-enare-dominado-wide.svg').default,
		// 	text: [
		// 		'O Time Hardwork está mobilizado em uma ação solidária pelo Rio Grande do Sul.',
		// 		<br />,
		// 		<br />,
		// 		'Nas próximas semanas faremos vários eventos gratuitos para mobilizar toda a comunidade da educação médica!',
		// 		<br />,
		// 		<br />,
		// 		'Já doamos 20MIL garrafas de água potável! A cada inscrição nos eventos gratuitos, doaremos 1	garrafa a mais.',
		// 		<br />,
		// 		<br />,
		// 		'Vamos juntos mostrar que nada supera o hardwork!',
		// 	],
		// 	date: 'De 06 de julho a 03 de agosto.',
		// 	group: 'HW SOLIDARIO - ENARE-DOMINADO',
		// 	type: 'central-concursos-horizontal',
		// 	confirmationMessage:
		// 		'Massa! Sua inscrição no Enare Dominado está confirmada! Em breve você receberá uma confirmação pelo e-mail cadastrado.',
		// },
		{
			logo: require('./assets/logo-ldl-revalida-inep.svg').default,
			text: [
				'Uma edição ESPECIAL e totalmente ONLINE da revolucionária revisão de véspera para a prova do REVALIDA INEP. Um dia com muitas discussões no padrão Hardwork dos temas quentes para a prova do INEP, numa live totalmente gratuita.',
				'Vamos mostrar a força da comunidade médica que busca a revalidação de diploma!',
				<br />,
				<br />,
				'#revalidapelosul',
				<br />,
				'#hardworkpelosul',
			],
			date: 'Dia 29 de junho.',
			group: 'HW SOLIDARIO - LIVE DAS LIVES - REVALIDA INEP',
			type: 'ldl-revalida-horizontal',
			confirmationMessage:
				'Massa! Sua inscrição na Live das Lives Revalida INEP (edição especial) está confirmada! Em breve você receberá uma confirmação pelo e-mail cadastrado.',
		},
		// {
		// 	logo: require('./assets/logo-tep-wide.svg').default,
		// 	text: [
		// 		'A prova de Título de Especialista em Pediatria está se aproximando, e vamos aproveitar a oportuindade para fazer aquilo que o Hardwork mais sabe: dominar uma banca! Uma semana inteira de discussões totalmente focadas na prova de Título de Especialista, totalmente online e gratuita!',
		// 		<br />,
		// 		<br />,
		// 		'#pediatriapelosul',
		// 		<br />,
		// 		'#hardworkpelosul',
		// 	],
		// 	date: 'De 20 a 24 de maio.',
		// 	group: 'HW SOLIDARIO - SEMANA TEP',
		// 	type: 'purple',
		// 	confirmationMessage:
		// 		'Massa! Sua inscrição na Semana TEP está confirmada! Em breve você receberá uma confirmação pelo e-mail cadastrado.',
		// },
		{
			logo: require('./assets/logo-tego-wide.svg').default,
			text: [
				'A prova do TEGO (Título de Especialista em Ginecologia e Obstetrícia) acontecerá em Julho! O Hardwork se mobilizou para dominar a prova contigo!',
				<br />,
				<br />,
				'Teremos uma semana intensa de revisão com foco nos temas esperados na prova. Tudo online, tudo gratuito!',
				<br />,
				<br />,
				'#TEGOpelosul',
				<br />,
				'#hardworkpelosul',
			],
			date: 'De 22 a 26 de julho.',
			group: 'HW SOLIDARIO - SEMANA TEGO',
			type: 'ldl-revalida-horizontal',
			confirmationMessage:
				'Massa! Sua inscrição na Semana TEGO está confirmada! Em breve você receberá uma confirmação pelo e-mail cadastrado.',
		},
	];

	const handleCopy = () => {
		const chavePix = 'doacao@cvbrs.org.br';
		navigator.clipboard.writeText(chavePix).then(() => {
			alert('Código Pix copiado com sucesso!');
		});
	};

	const handleScroll = () => {
		doacaoQrCode.current.scrollIntoView({
			behavior: 'auto',
			block: 'end',
		});
	};

	const url = window.location.pathname;

	const dataIndex = (() => {
		switch (url) {
			case '/hardwork-pelo-sul/enare-dominado':
				return 0;
			case '/hardwork-pelo-sul/ldl-revalida':
				return 1;
			case '/hardwork-pelo-sul/tep':
				return 2;
			default:
				return 3; // Use algum valor padrão se a URL não corresponder a nenhum caso
		}
	})();

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			<Helmet>
				<title>Hardwork Solidário - Hardwork Medicina</title>
				<meta
					name="description"
					content="A prova prática é um jogo e o time Hardwork só entra pra ganhar!"
				/>
			</Helmet>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
			</Container>
			<Grid
				container
				className={scroll ? classes.orangeBannerScroll : classes.orangeBanner}
				flexDirection={'column'}
				style={{
					transition: '0.3s',
					backgroundImage: `url(${require('./assets/faixa-laranja.png')})`,
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
					width: '100%',
					backgroundPosition: 'center',
				}}
			>
				<Typography className={scroll ? `${classes.firstTextScroll} poppins` : `${classes.firstText} poppins`}>
					Para cada inscrição nos eventos gratuitos Hardwork, 1 garrafa de água potável doada!
				</Typography>
				<Grid
					container
					item
					alignContent={'center'}
					justifyContent={'center'}
					columnGap={2}
				>
					<img
						src={BottleIcon}
						alt=""
					/>
					<Typography
						className={scroll ? `${classes.secondTextScroll} poppins` : `${classes.secondText} poppins`}
						style={{ marginRight: '15px' }}
					>
						<CountUp
							duration={3}
							end={waterDonationCount}
						/>
						&nbsp; garrafas arrecadadas
					</Typography>
				</Grid>
				<Box
					sx={{
						width: matchesMobile ? '80%' : '50%',
						display: 'flex',
						flexDirection: 'row',
						gap: 2,
						alignItems: 'center',
					}}
				>
					<BorderLinearProgress
						variant="determinate"
						value={progress}
					/>
					<Typography
						style={{ fontWeight: 'bold', fontSize: matchesMobile && '0.5rem', lineHeight: 'unset' }}
					>
						META <br /> 30 mil
					</Typography>
				</Box>
			</Grid>
			<Grid
				container
				className="revalida"
			>
				<FirstSectionHWSolidario
					backgroundImageUrl={
						matchesMobile
							? require('./assets/bg-hardwork-solidario-mobile.png')
							: require('./assets/bg-hardwork-solidario.png')
					}
					logoUrl={require('./assets/logo-hw-solidario.svg').default}
					firstTextPaper={[
						<Typography
							variant="h1"
							className={`${classes.gradientText} poppins`}
						>
							O SUL PRECISA DO NOSSO HARDWORK!
						</Typography>,
						<Typography
							align="left"
							color="secondary"
							className={`${classes.text} poppins`}
						>
							O Time Hardwork está mobilizado em uma ação solidária pelo Rio Grande do Sul.
							<br />
							<br />
							Nas próximas semanas faremos vários eventos gratuitos para mobilizar toda a comunidade da
							educação médica!
							<br />
							<br />
							Já doamos 20MIL garrafas de água potável! A cada inscrição nos eventos gratuitos, doaremos 1
							garrafa a mais.
							<br />
							<br />
							Vamos juntos mostrar que nada supera o hardwork!
						</Typography>,
					]}
					buttonProps={{
						bottom: '7.5em',
						left: '5em',
						type: 'white-btn',
						color: 'secondary',
						buttonLogoUrl: require('../../../../Assets/Svg/Icons/bagIcon.svg').default,
					}}
					imageProps={{ width: '37%', left: '10em', top: '5.75em' }}
				/>
			</Grid>
			<Grid
				container
				className="residencia section"
			>
				<Grid
					item
					container
					xs={12}
					justifyContent={'space-between'}
					className={`${classes.formGrid}`}
					rowGap={2}
				>
					<Grid
						item
						container
						xs={12}
					>
						<Typography
							fontWeight={'bold'}
							color={'#FFFFFF'}
							className={`${classes.textFormGrid} poppins`}
						>
							INSCREVA-SE GRATUITAMENTE.
						</Typography>
						<Typography
							color={'#FFFFFF'}
							className={`${classes.subtitleText} poppins`}
						>
							Para cada inscrição, o Hardwork Medicina doará 1 garrafa de água potável para o Rio Grande
							do Sul.
						</Typography>
					</Grid>
					<Grid
						item
						container
						xs={12}
						lg={7.5}
						justifyContent={'center'}
						style={{
							backgroundColor: '#09090A',
							borderRadius: 16,
							padding: matchesTablet ? '1rem' : '1rem 2rem',
						}}
					>
						<img
							src={data[dataIndex].logo}
							alt=""
							style={{ height: 270, marginBottom: 10 }}
						/>
						<Grid item>
							<Typography
								color="secondary"
								className="poppins"
								style={{ lineHeight: 'unset', fontSize: matchesTablet ? '0.9rem' : '1rem' }}
							>
								{data[dataIndex].text}
							</Typography>
							<Typography
								color="secondary"
								className="poppins"
								style={{ fontWeight: 'bold', fontSize: matchesTablet ? '1rem' : '1.2rem' }}
							>
								<br />
								<br />
								{data[dataIndex].date}
							</Typography>
						</Grid>
					</Grid>
					<Grid
						item
						container
						justifyContent={'center'}
						alignItems={'center'}
						xs={12}
						lg={4.25}
					>
						<Form
							group={data[dataIndex].group}
							title={'Inscreva-se gratuitamente!'}
							subTitle={'E garanta sua vaga para o evento'}
							titleButton={'GARANTIR MINHA VAGA'}
							type={data[dataIndex].type}
							urlToRedirect={url}
							confirmationMessage={data[dataIndex].confirmationMessage}
							handleScroll={handleScroll}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				style={{ padding: '1.25rem' }}
			>
				<Grid
					container
					style={{
						maxWidth: '1410px',
						margin: '0 auto',
					}}
				>
					<Typography
						color="secondary"
						className={`${classes.titleDonationCard} poppins`}
					>
						CASO QUEIRA, FAÇA TAMBÉM SUA DOAÇÃO
					</Typography>
				</Grid>
				<Grid
					container
					className={classes.donationCard}
				>
					<Grid
						item
						container
						alignContent={'space-between'}
						xs={12}
						md={6}
						style={{ borderRight: !matchesMobile && 'solid 2px #5B5B5B' }}
					>
						<Typography
							color="primary"
							className={`${classes.textDonationCard} poppins`}
						>
							Realize uma <span style={{ fontWeight: 'bold' }}>doação de qualquer valor</span> e ajude as
							vítimas atingidas pelas enchentes no Rio Grande do Sul.
							<br />
							As doações serão feitas diretamente para a conta da Cruz Vermelha Brasileira.
						</Typography>
						<img
							src={require('./assets/logo-cruz-vermelha.svg').default}
							alt=""
						/>
					</Grid>
					{matchesTablet && (
						<Grid
							item
							xs={12}
							style={{ padding: '1rem 0' }}
						>
							<Divider
								variant="middle"
								className={classes.divider}
							/>
						</Grid>
					)}
					<Grid
						ref={doacaoQrCode}
						item
						container
						direction={'column'}
						justifyContent={'center'}
						alignContent={'center'}
						xs={12}
						md={6}
						rowGap={2}
					>
						<Grid
							item
							container
							justifyContent={'center'}
						>
							<Typography
								align="center"
								color="primary"
								className={`${classes.subtitleDonationCard} poppins`}
							>
								ESCANEIE O QR-CODE
								<br />
								PARA FAZER SUA DOAÇÃO!
							</Typography>
							<Typography
								align="center"
								color="primary"
								className={`poppins`}
								style={{ fontSize: '0.9rem' }}
							>
								Utilize o aplicativo do seu banco para ler o QR-code
							</Typography>
						</Grid>
						<Grid
							item
							style={{ alignSelf: 'center' }}
						>
							<img
								src={require('./assets/img-pix-cv-brs.svg').default}
								alt=""
							/>
						</Grid>
						<Grid
							item
							container
							flexDirection={'column'}
							alignContent={'center'}
						>
							<Typography
								align="center"
								color="primary"
								className="poppins"
								style={{ fontSize: '1rem' }}
							>
								Ou use a chave PIX: <span style={{ color: 'red' }}>doacao@cvbrs.org.br</span>
							</Typography>
							<DefaultButton
								variant="outlined"
								color="secondary"
								className={`${classes.btn} btn btn-secondary`}
								onClick={() => handleCopy()}
							>
								Copiar chave pix
							</DefaultButton>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					container
					justifyContent={'center'}
					style={{ maxWidth: '1410px', margin: '2rem auto 0' }}
				>
					<img
						src={matchesMobile ? listYduqsMobile : listYduqs}
						alt=""
						style={{ width: '100%' }}
					/>
				</Grid>
				<BannerProductStatic />
			</Container>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Footer />
			</Container>
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	donationCard: {
		maxWidth: '1410px',
		margin: '0 auto',
		backgroundColor: '#ffffff',
		borderRadius: 32,
		padding: '2rem',
		[theme.breakpoints.down('sm')]: {
			padding: '1.5rem',
		},
	},
	formGrid: {
		padding: '2rem',
		border: '1px solid #FF357C',
		borderRadius: '24px',
		background: 'linear-gradient(to left, #FF5F4626, #FF357C26)',
		[theme.breakpoints.down('sm')]: {
			padding: '1rem',
		},
	},
	text: {
		fontSize: '1.25rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	orangeBanner: {
		position: 'sticky',
		top: 0,
		zIndex: 100,
		padding: '10px',
		justifyContent: 'center',
		alignItems: 'center',
		color: 'white',
		marginBottom: '-3rem',
		[theme.breakpoints.down('sm')]: {
			// height: '100px',
			marginBottom: '-5rem',
		},
	},
	orangeBannerScroll: {
		position: 'sticky',
		top: 0,
		zIndex: 9999,
		justifyContent: 'center',
		alignItems: 'center',
		color: 'white',
		paddingBottom: '0.5rem',
		marginBottom: '-3rem',
		[theme.breakpoints.down('sm')]: {
			// height: '100px',
			marginBottom: '-5rem',
		},
	},
	firstText: {
		fontSize: '1.3rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
			fontWeight: 'bold !important',
		},
	},
	firstTextScroll: {
		fontSize: '0.9rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
			fontWeight: 'bold !important',
		},
	},
	secondText: {
		fontSize: '2.5rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.3rem !important',
		},
	},
	secondTextScroll: {
		fontSize: '2.5rem !important',
		display: 'flex',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.3rem !important',
		},
	},
	gradientText: {
		color: 'white',
		fontSize: '2.5rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		marginBottom: '16px !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.8rem !important',
		},
	},
	btn: {
		borderColor: '#000000 !important',
		marginTop: '0.5rem !important',
		width: 'fit-content',
		padding: '0.5rem 2rem !important',
		alignSelf: 'center',
		fontSize: '1rem !important',
	},
	titleDonationCard: {
		fontSize: '3.25rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		marginBottom: '1rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem !important',
		},
	},
	textDonationCard: {
		fontSize: '1.25rem !important',
		fontWeight: '500 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	subtitleDonationCard: {
		fontSize: '1.75rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
		},
	},
	divider: {
		margin: 'unset !important',
		backgroundColor: '#5B5B5B',
		minHeight: 1,
	},
	textFormGrid: {
		fontSize: '2rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.75rem !important',
		},
	},
	subtitleText: {
		fontSize: '1.25rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
			marginTop: '1rem !important',
		},
	},
}));
