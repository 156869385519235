import React from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import { Header } from '../../../../Components/Header';
import Form from '../../../../Components/Utils/Forms/PreRegistration';

export default function LiveSprintFinal2023() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					justifyContent={'space-between'}
					alignContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
				>
					<Grid
						item
						xs={12}
						md={6}
						container
						justifyContent={'flex-start'}
						className={`${classes.gridTextContainer} revalida`}
						gap={3}
						style={{ height: 'fit-content' }}
					>
						<Typography
							className={`${classes.title} gradient-text roboto-condensed`}
							color="secondary"
						>
							Abertura de inscrições
							<br />
							Sprint Final Revalida
						</Typography>

						<Typography
							className={`${classes.text} poppins`}
							color="secondary"
						>
							{matchesLg ? (
								<>
									Inscreva-se para participar da live de abertura! Fique por dentro de todas as
									novidades do novo Sprint Final Revalida focado na prova da primeira edição de 2024!
									<br />
									<br />
									Iremos dominar o Revalida Inep mais uma vez.
								</>
							) : (
								<>
									Inscreva-se para participar da live de abertura!
									<br />
									Fique por dentro de todas as novidades do novo Sprint
									<br />
									Final Revalida focado na prova da primeira edição de 2024!
									<br />
									<br />
									Iremos dominar o Revalida Inep mais uma vez.
								</>
							)}
						</Typography>
						{/* <Typography
							align="left"
							color="secondary"
							className={`${classes.impactText} poppins`}
						>
							TERÇA-FEIRA
							<span className="bullet"> &#8226; </span>
							21NOV
							<span className="bullet"> &#8226; </span>
							20H
						</Typography> */}
					</Grid>
					<Grid
						item
						xs={12}
						md={5}
						container
						justifyContent="center"
						className={classes.gridWithBackgroundImage}
						gap={matchesTablet ? 4 : 0}
					>
						{matchesMobile ? null : (
							<img
								src={require('./assets/logo-new-sf-revalida.svg').default}
								alt="Logo Sprint Final Revalida"
								className={classes.logo}
							/>
						)}
						<Grid
							item
							xs={12}
							className={classes.gridForm}
							gap={1}
						>
							<Form
								group="LIVE LANÇAMENTO SPRINT REVALIDA 2024"
								title={['Inscreva-se para ser avisado e', <br />, 'participar da live de abertura']}
								titleButton="Confirmar Inscrição"
								type="revalida"
								urlToRedirect="/live/inscricao-sprint-final-revalida-2024"
								titleConfirmationMessage={'Inscrição confirmada!'}
								confirmationMessage={'Tu serás avisado pelo email cadastrado momentos antes da live.'}
							/>
						</Grid>
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto 0',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.2rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
			marginBottom: '8px !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.18rem !important',
			marginBottom: '8px !important',
		},
	},
	text: {
		fontSize: '1.3125rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	gridForm: {
		width: 567,
		flexDirection: 'column !important',
		alignItems: 'center !important',
		[theme.breakpoints.down('xl')]: {
			bottom: 20,
		},
		[theme.breakpoints.down(1300)]: {
			width: 467,
		},
		[theme.breakpoints.down('md')]: {
			position: 'unset',
			right: 'unset',
			top: 'unset',
			zIndex: 0,
		},
	},
	gridTextContainer: {
		[theme.breakpoints.down('lg')]: {
			paddingRight: '2em',
		},
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
	},
	gridWithBackgroundImage: {
		backgroundImage: `url(${require('./assets/bg-revalida.png')})`,
		backgroundSize: 'cover',
		padding: '2.5rem 2rem',
		[theme.breakpoints.down('lg')]: {
			padding: 'unset',
		},
		[theme.breakpoints.down('md')]: {
			backgroundImage: 'unset',
		},
	},
	impactText: {
		margin: '0 0 2rem !important',
		fontSize: '2rem !important',
		lineHeight: 'unset !important',
		background: 'linear-gradient(90deg, rgba(0,145,166,1) 10%, rgba(0,194,110,1) 100%)',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.45rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1rem !important',
		},
	},
	logo: {
		marginBottom: '1rem',
		[theme.breakpoints.down('xl')]: {
			marginBottom: '2.5rem',
		},
		[theme.breakpoints.down('md')]: {
			marginBottom: '1rem',
		},
	},
	timelineText: {
		fontSize: '1.5rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.2rem !important',
		},
	},
	divider: {
		position: 'absolute',
		border: '1.5px solid #fff !important',
		left: '4.65em',
		backgroundColor: '#fff',
		height: '230px !important',
		zIndex: -1,
		[theme.breakpoints.down('xl')]: {
			height: '200px !important',
		},
		[theme.breakpoints.down('lg')]: {
			height: '280px !important',
		},
		[theme.breakpoints.down('md')]: {
			height: '265px !important',
		},
		[theme.breakpoints.down('sm')]: {
			height: '180px !important',
		},
		[theme.breakpoints.down(376)]: {
			height: '220px !important',
		},
	},
	imgTime: {
		height: 30,
		top: 5,
	},
}));
