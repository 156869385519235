import React, { useEffect, useState } from 'react';
import { Container, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../../Components/Footer';
import { Header } from '../../../../../Components/Header';

import Form from '../../../../../Components/Utils/Forms/PreRegistration';
import HostsCarrousel from '../../../../../Components/Sections/HostsCarrousel';
import Api from '../../../../../Services/Api';

const Hosts = [
	{
		ig_username: '@fabricio.valois',
		img: require('../Assets/fabricioValois.png'),
		specialty: 'Clínica Médica',
	},
	{
		ig_username: '@drfabiocolagrossi',
		img: require('../Assets/fabioColagrossi.png'),
		specialty: 'Cirurgia Geral',
	},
	{
		ig_username: '@lais.komatsu',
		img: require('../Assets/laisKomatsu.png'),
		specialty: 'Medicina do Trabalho',
	},
	{
		ig_username: '@profa.anasouza',
		img: require('../Assets/anaSouza.png'),
		specialty: 'Ginecologia e Obstetrícia',
	},
	// {
	// 	ig_username: 'Claudio Lima',
	// 	img: require('../Assets/claudioLima.png'),
	// 	specialty: 'Pediatria',
	// },
	{
		ig_username: '@drdanielborasalvar',
		img: require('../Assets/prof-daniel.png'),
		specialty: 'Cirurgia Geral',
	},
	// {
	// 	ig_username: '@ericmdcosta',
	// 	img: require('./Assets/ericCosta.png'),
	// 	specialty: 'Psiquiatria',
	// },
];

export default function SubscriptionEventFortaleza() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesDownXl = useMediaQuery(theme.breakpoints.down('xl'));

	const [closeGroup, setCloseGroup] = useState(false);

	useEffect(() => {
		const group = 'HARDWORK FORTALEZA 2023)';
		Api.get(`preinscricao/?agrupamento=${group}`).then((res) => {
			setCloseGroup(res >= 700);
		});
	}, []);

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					justifyContent={'space-between'}
					alignContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
				>
					<Grid
						item
						xs={12}
						md={6}
						container
						justifyContent={'flex-start'}
						className={`${classes.gridTextContainer} residencia`}
						gap={1}
					>
						{matchesDownXl ? (
							<img
								src={require('../Assets/logo-ldl-experience-xl.svg').default}
								alt="Logo Hardwork em Fortaleza"
								style={matchesDownXl ? null : matchesTablet ? { height: 120 } : null}
							/>
						) : null}
						<Typography
							className={`${classes.title} gradient-text roboto-condensed`}
							color="secondary"
						>
							Uma noite com o show de didática Hardwork e com a revolução na preparação para as provas.
						</Typography>
						<Typography
							className={`${classes.text} poppins`}
							color="secondary"
						>
							No dia 31 de maio estaremos contigo em Fortaleza para te mostrar o modo Hardwork de ver a
							medicina...de quebra, tu vais experimentar o que é o show de revisão para provas de
							residência do Hardwork; vamos passar por temas peculiares de grandes provas, como USP-SP,
							USP-Ribeirão, UNIFESP, SUS-SP, SURCE, ENARE...
							<br />
							<br />
							As vagas são limitadas, e para se inscrever basta preencher o formulário ao lado e levar 2kg
							de alimento no dia do evento, que serão doados a instituições de caridade de Fortaleza.
							<br />
							<br />
						</Typography>
						<Typography
							align="left"
							color="secondary"
							className={`${classes.text} poppins`}
							style={{ marginBottom: '1rem' }}
						>
							CONFIRA O CRONOGRAMA:
						</Typography>
						<Grid
							item
							container
							gap={2}
							style={{ marginBottom: '3em' }}
						>
							<Divider
								variant="middle"
								orientation="vertical"
								className={classes.divider}
							/>
							<Grid
								item
								container
								wrap="nowrap"
								gap={2}
							>
								<img
									src={require('../Assets/19.svg').default}
									alt=""
									className={classes.imgTime}
								/>
								<Grid
									item
									container
								>
									<Typography
										color="secondary"
										className={`${classes.impactText3} poppins`}
										style={{ marginBottom: 'unset !important' }}
									>
										Distúrbio ácido-base
									</Typography>
									<Typography
										color="secondary"
										className={`${classes.text} poppins`}
										style={{ marginBottom: '1.25rem' }}
									>
										Uma aula arrepiante com Prof Fabrício Valois para desmistificar tudo sobre a
										interpretação da gasometria arterial.
									</Typography>
								</Grid>
							</Grid>
							<Grid
								item
								container
								wrap="nowrap"
								gap={2}
							>
								<img
									src={require('../Assets/20.svg').default}
									alt=""
									style={{ height: 30, top: 5 }}
								/>
								<Grid
									item
									container
								>
									<img
										src={require('../Assets/logo-ldl-experience.svg').default}
										alt=""
										className={classes.logoLdlExperience}
									/>
									<Typography
										color="secondary"
										className={`${classes.text} poppins`}
										style={{ marginBottom: '1.25rem' }}
									>
										Hora de sentir o grande show de revisão focada em cada prova do Hardwork. Vamos
										passar pelos temas que fazem a diferença nas maiores provas de residência do
										país.
									</Typography>
								</Grid>
							</Grid>
							<Grid
								item
								container
								wrap="nowrap"
								gap={2}
							>
								<img
									src={require('../Assets/22.svg').default}
									alt=""
									style={{ height: 30, top: 5 }}
								/>
								<Grid
									item
									container
								>
									<Typography
										color="secondary"
										className={`${classes.impactText3} poppins`}
									>
										Sorteios e Coffee de encerramento
									</Typography>
									<Typography
										color="secondary"
										className={`${classes.text} poppins`}
									>
										Hora da surpresa! Sorteios e um lanche muito especial.
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Typography
							color="secondary"
							className={`${classes.text} poppins`}
						>
							Marque na agenda, as vagas são limitadas.
						</Typography>
						<Typography
							align="left"
							color="secondary"
							className={`${classes.impactText3} poppins`}
						>
							QUARTA-FEIRA
							<span className="bullet"> &#8226; </span>
							31MAI
							<span className="bullet"> &#8226; </span>
							19:00h
						</Typography>
						<Typography
							color="secondary"
							className={`${classes.text} poppins`}
							style={{ marginBottom: '3.5rem', fontWeight: 'bold' }}
						>
							Theatro Via Sul Fortaleza - Seis Bocas, Fortaleza-CE
						</Typography>
						{!matchesTablet && (
							<HostsCarrousel
								Hosts={Hosts}
								type="residencia"
							/>
						)}
					</Grid>
					<Grid
						item
						xs={12}
						md={5}
						container
						justifyContent="center"
						className={classes.gridWithBackgroundImage}
						gap={matchesTablet ? 4 : 0}
					>
						<Grid
							item
							xs={12}
							className={classes.gridForm}
							gap={1}
						>
							{matchesDownXl ? null : (
								<img
									src={require('../Assets/logo-ldl-experience-xl.svg').default}
									alt="Logo Hardwork em Fortaleza"
									className={classes.logo}
								/>
							)}
							<Form
								closeGroup={closeGroup}
								group="HARDWORK FORTALEZA 2023"
								title={
									closeGroup
										? [
												'VAGAS ESGOTADAS!',
												<br />,
												'Cadastre-se para entrar',
												<br />,
												'na lista de espera.',
										  ]
										: ['Inscreva-se para garantir sua vaga e participar do evento']
								}
								titleButton={closeGroup ? 'Entrar na lista de espera' : 'Garantir minha vaga'}
								type="residencia"
								urlToRedirect="/materiais/hardwork-em-fortaleza"
								titleConfirmationMessage={'Inscrição confirmada!'}
								confirmationMessage={
									closeGroup
										? 'Seu cadastro na lista de espera está confirmado!'
										: 'Que tal fazermos uma grande ação social? Vamos arrecadar alimentos no dia do evento! Leve 2kg de alimento não perecível para doação! O mundo conta conosco.'
								}
								foodRequirement
							/>
						</Grid>
						{matchesTablet && (
							<HostsCarrousel
								Hosts={Hosts}
								type="residencia"
							/>
						)}
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.35rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
			marginBottom: '8px !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.18rem !important',
			marginBottom: '8px !important',
		},
	},
	text: {
		fontSize: '1.3125rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	gridForm: {
		width: 567,
		position: 'fixed',
		bottom: 20,
		zIndex: 10,
		display: 'flex',
		flexDirection: 'column !important',
		alignItems: 'center !important',
		[theme.breakpoints.down('xl')]: {
			bottom: 20,
		},
		[theme.breakpoints.down(1300)]: {
			width: 467,
		},
		[theme.breakpoints.down('md')]: {
			position: 'unset',
			right: 'unset',
			top: 'unset',
			zIndex: 0,
		},
	},
	gridTextContainer: {
		[theme.breakpoints.down('lg')]: {
			paddingRight: '2em',
		},
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
	},
	gridWithBackgroundImage: {
		backgroundImage: `url(${require('../Assets/bg-residencia.png')})`,
		[theme.breakpoints.down('md')]: {
			paddingLeft: 'unset',
			backgroundImage: 'unset',
		},
	},
	impactText: {
		margin: '0 0 2rem !important',
		fontSize: '2rem !important',
		lineHeight: 'unset !important',
		background: 'linear-gradient(90deg, rgb(255 72 0) 10%, rgb(255 0 66) 100%)',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.45rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down(361)]: {
			fontSize: '1.25rem !important',
		},
	},
	logo: {
		height: 175,
		[theme.breakpoints.down('xl')]: {
			height: 'unset',
			width: 250,
		},
	},
	timelineText: {
		fontSize: '1.7rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.2rem !important',
		},
	},
	divider: {
		position: 'absolute',
		border: '1.5px solid #fff !important',
		left: '4.85em',
		backgroundColor: '#fff',
		height: '415px !important',
		zIndex: -1,
		[theme.breakpoints.down('xl')]: {
			height: '360px !important',
		},
		[theme.breakpoints.down('lg')]: {
			height: '430px !important',
		},
		[theme.breakpoints.down('md')]: {
			height: '395px !important',
		},
		[theme.breakpoints.down('sm')]: {
			height: '345px !important',
		},
		[theme.breakpoints.down(394)]: {
			height: '355px !important',
		},
		[theme.breakpoints.down(391)]: {
			height: '355px !important',
		},
		[theme.breakpoints.down(376)]: {
			height: '345px !important',
		},
		[theme.breakpoints.down(361)]: {
			height: '365px !important',
		},
	},
	imgTime: {
		height: 30,
		top: 5,
	},
	impactText2: {
		margin: '1.5rem 0 0 !important',
		fontSize: '1.75rem !important',
		lineHeight: 'unset !important',
		fontWeight: 'bold !important',
		background: 'linear-gradient(90deg, rgb(255 72 0) 10%, rgb(255 0 66) 100%)',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.45rem !important',
			margin: 'unset !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1rem !important',
		},
	},
	impactText3: {
		margin: '0 0 0.5rem !important',
		fontSize: '2rem !important',
		lineHeight: 'unset !important',
		background: 'linear-gradient(90deg, rgb(255 72 0) 10%, rgb(255 0 66) 100%)',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.25rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.45rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(361)]: {
			fontSize: '1.2rem !important',
		},
	},
	logoLdlExperience: {
		width: '55%',
		[theme.breakpoints.down('lg')]: {
			width: 'unset',
		},
	},
}));
