import React, { useEffect, useRef, useState } from 'react';
import { Box, Container, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Header } from '../../../../Components/Header';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import CountUp from 'react-countup';
import Footer from '../../../../Components/Footer';
import '@splidejs/react-splide/css';
import { Helmet } from 'react-helmet';
import FirstSectionHWSolidario from '../../../../Components/Sections/FirstSectionHWSolidario';

import Api from '../../../../Services/Api';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import DefaultButton from '../../../../Components/Buttons/DefaultButton';
import CardProductSolidario from './components/CardProductSolidario';
import { cardInfos } from './constants/cardInfo';
import Form from '../../../../Components/Utils/Forms/PreRegistrationV4';
import listYduqs from './assets/list-yduqs.png';
import listYduqsMobile from './assets/list-yduqs-mobile.png';

import BottleIcon from './assets/bottle.svg';

export default function HardworkSolidario() {
	const classes = useStyles();
	const theme = useTheme();
	const doacaoQrCode = useRef(null);
	const carousel = useRef(null);

	const [isSelected, setIsSelected] = useState([]);
	const [atLeastOneIntoArray, setAtLeastOneIntoArray] = useState(false);
	const [triggerAtualization, setTriggerAtualization] = useState(false);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesLargeScreen = useMediaQuery(theme.breakpoints.down('xl'));

	const [scroll, setScroll] = useState(false);
	const [tepValue, setTepValue] = useState(0);
	const [progress, setProgress] = useState(0);
	const hardworkValue = 20000;
	const [meta, setMeta] = useState(30000);

	const progressValue = (tepValue / meta) * 100;
	// console.log((tepValue/meta) * 100);

	useEffect(() => {
		const timer = setInterval(() => {
			setProgress((prevProgress) => {
				const newProgress = prevProgress + 2;
				return newProgress >= progressValue ? progressValue : newProgress;
			});
		}, 100);

		return () => {
			clearInterval(timer);
		};
	}, [tepValue]);

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.scrollY;
			if (scrollPosition > 10) {
				setScroll(true);
			} else {
				setScroll(false);
			}
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const allGroupments =
		'HW SOLIDARIO - SEMANA TEP|HW SOLIDARIO - SEMANA TEGO|HW SOLIDARIO - LIVE DAS LIVES - REVALIDA INEP|HW SOLIDARIO - ENARE-DOMINADO';

	useEffect(() => {
		Api.get(`preinscricao/${encodeURIComponent(`${allGroupments}`)}`)
			.then((responses) => {
				// const sum = responses.reduce((acc, res) => acc + res, 0);
				setTepValue(responses + hardworkValue);
			})
			.catch((error) => {
				// Trate os erros aqui, se necessário
				console.error('Erro ao buscar os dados da API:', error);
			});
	}, [triggerAtualization]);

	const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
		height: 12,
		borderRadius: 5,
		width: '90%',
		border: '1.5px solid #FFFFFF',
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor: 'rgba(0,0,0,0.1)',
		},
		[`& .${linearProgressClasses.bar}`]: {
			borderRadius: 5,
			backgroundColor: '#FFFFFF',
		},
	}));

	useEffect(() => {
		setAtLeastOneIntoArray(false);
	}, []);

	const handleCopy = () => {
		const chavePix = 'doacao@cvbrs.org.br';
		navigator.clipboard.writeText(chavePix).then(() => {
			alert('Código Pix copiado com sucesso!');
		});
	};

	const handleSelectedCard = (elm) => {
		if (isSelected.includes(elm)) {
			const newArr = isSelected.filter((e) => e !== elm);
			setIsSelected(newArr);
			setAtLeastOneIntoArray(false);
		} else {
			setIsSelected([elm]);
			setAtLeastOneIntoArray(true);
		}
	};

	const handleScroll = () => {
		doacaoQrCode.current.scrollIntoView({
			behavior: 'auto',
			block: 'end',
		});
	};

	const handleLeftClick = () => {
		carousel.current.scrollLeft -= carousel.current.offsetWidth - 18;
	};

	const handleRightClick = () => {
		carousel.current.scrollLeft += carousel.current.offsetWidth + 18;
	};
	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			<Helmet>
				<title>Hardwork Solidário - Hardwork Medicina</title>
				<meta
					name="description"
					content="A prova prática é um jogo e o time Hardwork só entra pra ganhar!"
				/>
			</Helmet>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
			</Container>
			<Grid
				container
				className={scroll ? classes.orangeBannerScroll : classes.orangeBanner}
				flexDirection={'column'}
				style={{
					// height: scroll && '60px',
					transition: '0.3s',
					backgroundImage: `url(${require('./assets/faixa-laranja.png')})`,
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
					width: '100%',
					backgroundPosition: 'center',
				}}
			>
				<Typography className={scroll ? `${classes.firstTextScroll} poppins` : `${classes.firstText} poppins`}>
					Para cada inscrição nos eventos gratuitos Hardwork,
					<br style={{ display: !matchesTablet ? 'none' : 'flex' }} /> 1 garrafa de água potável doada!
				</Typography>
				<Grid
					container
					item
					alignContent={'center'}
					justifyContent={'center'}
					columnGap={2}
				>
					<img
						// style={{ width: '4%' }}
						src={BottleIcon}
						alt=""
					/>
					<Typography
						className={scroll ? `${classes.secondTextScroll} poppins` : `${classes.secondText} poppins`}
						style={{ marginRight: '15px' }}
					>
						<CountUp
							// className={scroll ? `${classes.secondTextScroll} poppins` : `${classes.secondText} poppins`}
							duration={3}
							end={tepValue}
						/>
						&nbsp; garrafas arrecadadas*
					</Typography>
				</Grid>
				<Box
					sx={{
						width: matchesMobile ? '80%' : '50%',
						display: 'flex',
						flexDirection: 'row',
						gap: 2,
						alignItems: 'center',
					}}
				>
					<BorderLinearProgress
						variant="determinate"
						value={progress}
					/>
					<Typography
						style={{ fontWeight: 'bold', fontSize: matchesMobile && '0.5rem', lineHeight: 'unset' }}
					>
						META <br style={{ display: matchesTablet ? 'none' : 'flex' }} /> 30 mil
					</Typography>
				</Box>
			</Grid>
			<Grid
				container
				className="residencia"
			>
				<FirstSectionHWSolidario
					backgroundImageUrl={
						matchesMobile
							? require('./assets/bg-hardwork-solidario-mobile.png')
							: require('./assets/bg-hardwork-solidario.png')
					}
					logoUrl={require('./assets/logo-hw-solidario.svg').default}
					// type="green"
					// buttonText="&nbsp;&nbsp;Inscreva-se"
					// executeScroll={executeScroll}
					firstTextPaper={[
						<Typography
							variant="h1"
							className={`${classes.gradientText} poppins`}
						>
							O SUL PRECISA DO NOSSO HARDWORK!
						</Typography>,
						<Typography
							align="left"
							color="secondary"
							className={`${classes.text} poppins`}
						>
							O Time Hardwork está mobilizado em uma ação solidária pelo Rio Grande do Sul.
							<br />
							<br />
							Nas próximas semanas faremos vários eventos gratuitos para mobilizar toda a comunidade da
							educação médica!
							<br />
							<br />
							Já doamos 20MIL garrafas de água potável! A cada inscrição nos eventos gratuitos, doaremos 1
							garrafa a mais.
							<br />
							<br />
							Vamos juntos mostrar que nada supera o hardwork!
						</Typography>,
					]}
					buttonProps={{
						bottom: '7.5em',
						left: '5em',
						type: 'white-btn',
						color: 'secondary',
						buttonLogoUrl: require('../../../../Assets/Svg/Icons/bagIcon.svg').default,
					}}
					imageProps={{ width: '37%', left: '10em', top: '5.75em' }}
				/>
			</Grid>
			<Grid
				container
				className="residencia section"
			>
				<Grid
					item
					xs={12}
					className={`${classes.formGrid}`}
				>
					<Typography
						fontWeight={'bold'}
						color={'#FFFFFF'}
						className={`${classes.textFormGrid} poppins`}
					>
						ESCOLHA O EVENTO E INSCREVA-SE GRATUITAMENTE.
					</Typography>
					<Typography
						color={'#FFFFFF'}
						className={`${classes.subtitleText} poppins`}
					>
						Para cada inscrição, o Hardwork Medicina doará 1 garrafa de água potável para o Rio Grande do
						Sul.
					</Typography>
					<Grid
						item
						container
					>
						<Grid
							ref={carousel}
							item
							container
							flexDirection={'row'}
							justifyContent={'space-between'}
							flexWrap={'nowrap'}
							paddingY={3}
							xs={12}
							gap={2}
							className={`${classes.carouselStyles}`}
						>
							{cardInfos?.map((elm) => (
								<CardProductSolidario
									key={Math.random()}
									logo={elm.img}
									description={elm.description}
									launchDate={elm.launchDate}
									atleastOneWasSelected={atLeastOneIntoArray}
									isSelected={isSelected.includes(elm)}
									handleSelectedCard={() => handleSelectedCard(elm)}
								/>
							))}
						</Grid>

						{!matchesMobile && !matchesTablet && (
							<>
								<Grid
									style={{
										position: 'absolute',
										top: '50%',
										right: matchesLargeScreen ? '-2rem' : '-3.8rem',
										cursor: 'pointer',
									}}
									className={`${classes.btnArrowStyle}`}
									onClick={() => handleRightClick()}
								>
									<img
										src={require('./assets/icon-right-arrow.png')}
										alt=""
									/>
								</Grid>
								<Grid
									style={{
										position: 'absolute',
										top: '50%',
										left: matchesLargeScreen ? '-2rem' : '-3.8rem',
										cursor: 'pointer',
										rotate: '180deg',
									}}
									className={`${classes.btnArrowStyle}`}
									onClick={() => handleLeftClick()}
								>
									<img
										src={require('./assets/icon-right-arrow.png')}
										alt=""
									/>
								</Grid>
							</>
						)}
					</Grid>
					<Grid
						item
						container
						justifyContent={'center'}
						alignItems={'center'}
						xs={12}
					>
						<Grid
							item
							xs={12}
							md={8}
							lg={6}
							paddingY={3}
						>
							<Form
								group={isSelected[0]?.agroup}
								title={'Inscreva-se gratuitamente!'}
								subTitle={'E garanta sua vaga para o evento'}
								titleButton={'GARANTIR MINHA VAGA'}
								type={atLeastOneIntoArray > 0 ? 'residencia' : 'transparent'}
								urlToRedirect={'/hardwork-solidario'}
								confirmationMessage={isSelected[0]?.confirmationMsg}
								atleastOneWasSelected={atLeastOneIntoArray}
								setTriggerAtualization={setTriggerAtualization}
								triggerAtualization={triggerAtualization}
								handleScroll={handleScroll}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				style={{ padding: '1.25rem' }}
			>
				<Grid
					container
					style={{
						maxWidth: '1410px',
						margin: '0 auto',
					}}
				>
					<Typography
						color="secondary"
						className={`${classes.titleDonationCard} poppins`}
					>
						CASO QUEIRA, FAÇA TAMBÉM SUA DOAÇÃO
					</Typography>
				</Grid>
				<Grid
					container
					className={classes.donationCard}
				>
					<Grid
						item
						container
						alignContent={'space-between'}
						xs={12}
						md={6}
						style={{ borderRight: !matchesMobile && 'solid 2px #5B5B5B' }}
					>
						<Typography
							color="primary"
							className={`${classes.textDonationCard} poppins`}
						>
							Realize uma <span style={{ fontWeight: 'bold' }}>doação de qualquer valor</span> e ajude as
							vítimas atingidas pelas enchentes no Rio Grande do Sul.
							<br />
							As doações serão feitas diretamente para a conta da Cruz Vermelha Brasileira.
						</Typography>
						<img
							src={require('./assets/logo-cruz-vermelha.svg').default}
							alt=""
						/>
					</Grid>
					{matchesTablet && (
						<Grid
							item
							xs={12}
							style={{ padding: '1rem 0' }}
						>
							<Divider
								variant="middle"
								className={classes.divider}
							/>
						</Grid>
					)}
					<Grid
						ref={doacaoQrCode}
						item
						container
						direction={'column'}
						justifyContent={'center'}
						alignContent={'center'}
						xs={12}
						md={6}
						rowGap={2}
					>
						<Grid
							item
							container
							justifyContent={'center'}
						>
							<Typography
								align="center"
								color="primary"
								className={`${classes.subtitleDonationCard} poppins`}
							>
								ESCANEIE O QR-CODE
								<br />
								PARA FAZER SUA DOAÇÃO!
							</Typography>
							<Typography
								align="center"
								color="primary"
								className={`poppins`}
								style={{ fontSize: '0.9rem' }}
							>
								Utilize o aplicativo do seu banco para ler o QR-code
							</Typography>
						</Grid>
						<Grid
							item
							style={{ alignSelf: 'center' }}
						>
							<img
								src={require('./assets/img-pix-cv-brs.svg').default}
								alt=""
							/>
						</Grid>
						<Grid
							item
							container
							flexDirection={'column'}
							alignContent={'center'}
						>
							<Typography
								align="center"
								color="primary"
								className="poppins"
								style={{ fontSize: '1rem' }}
							>
								Ou use a chave PIX: <span style={{ color: 'red' }}>doacao@cvbrs.org.br</span>
							</Typography>
							<DefaultButton
								variant="outlined"
								color="secondary"
								className={`${classes.btn} btn btn-secondary`}
								onClick={() => handleCopy()}
							>
								Copiar chave pix
							</DefaultButton>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					container
					justifyContent={'center'}
					style={{ maxWidth: '1410px', margin: '2rem auto 0' }}
				>
					<img
						src={matchesMobile ? listYduqsMobile : listYduqs}
						alt=""
						style={{ width: '100%' }}
					/>
				</Grid>
			</Container>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Footer />
			</Container>
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	btnArrowStyle: {
		transition: 'all 150ms ease',
		'&:hover': {
			scale: 1.05,
		},
	},
	donationCard: {
		maxWidth: '1410px',
		margin: '0 auto',
		backgroundColor: '#ffffff',
		borderRadius: 32,
		padding: '2rem',
		[theme.breakpoints.down('sm')]: {
			padding: '1.5rem',
		},
	},
	carouselStyles: {
		overflowX: 'scroll !important',
		scrollBehavior: 'smooth',
		'&::-webkit-scrollbar': {
			height: '5px',
		},
		'&::-webkit-scrollbar-track': {
			boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
			webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
			backgroundColor: 'transparent',
			// backgroundColor: 'transparent',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: 'transparent',
			'&:hover': {
				backgroundColor: 'transparent',
			},
		},
		[theme.breakpoints.down('md')]: {
			'&::-webkit-scrollbar': {
				height: '5px',
			},
			'&::-webkit-scrollbar-track': {
				boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
				webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
				backgroundColor: '#C1C1C126',
				// backgroundColor: 'transparent',
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: '#C1C1C1',
				'&:hover': {
					backgroundColor: '#C1C1C1',
				},
			},
		},
	},
	formGrid: {
		minHeight: '50rem',
		padding: '20px',
		border: '1px solid #FF357C',
		borderRadius: '24px',
		background: 'linear-gradient(to left, #FF5F4626, #FF357C26)',
	},
	text: {
		fontSize: '1.25rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	orangeBanner: {
		position: 'sticky',
		top: 0,
		zIndex: 100,
		padding: '10px',
		justifyContent: 'center',
		alignItems: 'center',
		color: 'white',
		marginBottom: '-3rem',
		[theme.breakpoints.down('sm')]: {
			// height: '100px',
			marginBottom: '-5rem',
		},
	},
	orangeBannerScroll: {
		position: 'sticky',
		top: 0,
		zIndex: 9999,
		justifyContent: 'center',
		alignItems: 'center',
		color: 'white',
		paddingBottom: '0.5rem',
		marginBottom: '-3rem',
		[theme.breakpoints.down('sm')]: {
			// height: '100px',
			marginBottom: '-5rem',
		},
	},
	firstText: {
		padding: '10px 20px 10px 20px',
		fontSize: '1.3rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
			fontWeight: 'bold !important',
		},
	},
	firstTextScroll: {
		padding: '10px 20px 0px 20px',
		fontSize: '0.9rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
			fontWeight: 'bold !important',
		},
	},
	secondText: {
		fontSize: '2.5rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.3rem !important',
		},
	},
	secondTextScroll: {
		fontSize: '2.5rem !important',
		display: 'flex',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.3rem !important',
		},
	},
	backgroundWaves: {
		backgroundImage: `url(${process.env.PUBLIC_URL + '/Assets/Img/bgWave2.png)'}`,
		width: '100vw !important',
	},
	gradientText: {
		color: 'white',
		fontSize: '2.5rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		marginBottom: '16px !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.8rem !important',
		},
	},
	timeText: {
		fontSize: '3.85rem !important',
		lineHeight: 'unset !important',
		color: 'yellow !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '2.5rem !important',
			alignSelf: 'center',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '3rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
		},
	},
	containerTime: {
		backgroundColor: '#ffffff2b',
		borderRadius: '6px',
		color: '#ffffff',
		width: '5rem !important',
		height: '4rem',
		[theme.breakpoints.down(1770)]: {
			width: '3.5rem !important',
			height: '3.5rem',
		},
		[theme.breakpoints.down('sm')]: {
			width: '2rem !important',
			height: '2.5rem',
		},
	},
	textNumber: {
		fontWeight: 'bold !important',
		fontSize: '1.4rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	containerSeparator: {
		color: '#ffffff',
		width: '2.2rem !important',
		height: '3.8rem',
		[theme.breakpoints.down('sm')]: {
			width: '1.2rem !important',
			height: '2.5rem',
		},
	},
	separatorText: {
		fontWeight: 'bold !important',
		fontSize: '1.3rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	textTimer: {
		textTransform: 'uppercase',
		fontWeight: 'bold !important',
		width: '5rem !important',
		height: '1rem',
		fontSize: '0.75rem !important',
		alignSelf: 'center',
		[theme.breakpoints.down(1770)]: {
			width: '4rem !important',
		},
		[theme.breakpoints.down(1240)]: {
			width: '3.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			width: '2rem !important',
			fontSize: '0.5rem !important',
		},
	},
	buttonCarouselRight: {
		position: 'absolute !important',
		top: '200px !important',
		right: '0px !important',
		width: '80px !important',
		height: '80px !important',
		borderRadius: '100% !important',
		background: '#F1F1F199',
		padding: '20px',
		cursor: 'pointer',
		transition: 'all 150ms ease',
		'&:hover': {
			scale: 1.05,
			border: '2px solid #f1f1f1',
		},
	},
	btn: {
		borderColor: '#000000 !important',
		marginTop: '0.5rem !important',
		width: 'fit-content',
		padding: '0.5rem 2rem !important',
		alignSelf: 'center',
		fontSize: '1rem !important',
	},
	titleDonationCard: {
		fontSize: '3.25rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		marginBottom: '1rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
		},
	},
	textDonationCard: {
		fontSize: '1.25rem !important',
		fontWeight: '500 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	subtitleDonationCard: {
		fontSize: '1.75rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
		},
	},
	divider: {
		margin: 'unset !important',
		backgroundColor: '#5B5B5B',
		minHeight: 1,
	},
	textFormGrid: {
		fontSize: '2rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
		},
	},
	subtitleText: {
		fontSize: '1.25rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
			marginTop: '1rem !important',
		},
	},
}));
