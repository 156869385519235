import React, { useEffect, useRef, useState } from 'react';
import { Container, Divider, Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

import Api from '../../Services/Api';

// COMPONENTS
import Footer from '../../Components/Footer';
import { Header } from '../../Components/Header';
import StudentsFeedbackSection from '../../Components/Sections/StudentsFeedback';
import DiferencialBox from '../../Components/Cards/DiferencialBox';
import DefaultButton from '../../Components/Buttons/DefaultButton';
import TestCard from '../../Components/Cards/TestCard';
import LearningPath from '../../Components/Sections/LearningPath';
import MigrationCard from '../../Components/Cards/MigrationCard';

// TO IMPLEMENT
// import FaqSection from '../../Components/Sections/FaqSection';
// import Newsletter from '../../Components/Utils/Newsletter';

// ASSETS
import MigracaoExtensivoLogo from '../../Assets/Extensivo/R1/2023/migracaoExtensivoLogo.svg';
import SprintHwmLogoSection from '../../Assets/SprintFinal/sprintHwmLogoSection.svg';
import SprintFinalMobile from '../../Assets/SprintFinal/sprintFinalMobile.svg';
import ExtensivoLogo from '../../Assets/Extensivo/R1/2023/extensivoLogo.svg';
import MigracaoExtensivoImg from '../../Assets/Extensivo/R1/2023/migracaoExtensivoImg.svg';
import MigracaoExtensivoImgMobile from '../../Assets/Extensivo/R1/2023/migracaoExtensivoImgMobile.svg';
import SprintFinalLogoText from '../../Assets/SprintFinal/sprintFinalLogoText.svg';
import SuitcaseIcon from '../../Assets/Svg/Icons/suitcaseIcon.svg';

// SF PROVAS
import AmrigsLogo from '../../Assets/SprintFinal/amrigsLogo.svg';
import EinsteinLogo from '../../Assets/SprintFinal/einsteinLogo.svg';
import EnareLogo from '../../Assets/SprintFinal/enareLogo.svg';
import IamspeLogo from '../../Assets/SprintFinal/iamspeLogo.svg';
import SesgoLogo from '../../Assets/SprintFinal/sesgoLogo.svg';
import FacMedicinaJundiaiLogo from '../../Assets/SprintFinal/facMedicinaJundiaiLogo.svg';
import PsuMgLogo from '../../Assets/SprintFinal/psuMgLogo.svg';
import SantaCasaLogo from '../../Assets/SprintFinal/santaCasaSpLogo.svg';
import SesDfLogo from '../../Assets/SprintFinal/sesDfLogo.svg';
import SesPeLogo from '../../Assets/SprintFinal/sesPeLogo.svg';
import UfrjLogo from '../../Assets/SprintFinal/ufrjLogo.svg';
import AbcLogo from '../../Assets/SprintFinal/abcLogo.svg';
import SirioLibanesLogo from '../../Assets/SprintFinal/sirioLibanesLogo.svg';
import SurceLogo from '../../Assets/SprintFinal/surceLogo.svg';
import SusBaLogo from '../../Assets/SprintFinal/susBaLogo.svg';
import SusSpLogo from '../../Assets/SprintFinal/susSpLogo.svg';
import UnespBotucatuLogo from '../../Assets/SprintFinal/unespBotucatuLogo.svg';
import AmpLogo from '../../Assets/SprintFinal/ampLogo.svg';
import UnifespLogo from '../../Assets/SprintFinal/unifestpLogo.svg';
import UspLogo from '../../Assets/SprintFinal/uspLogo.svg';
import UspRibeiraoLogo from '../../Assets/SprintFinal/uspRibeiraoLogo.svg';
import FamemaLogo from '../../Assets/SprintFinal/famemaLogo.svg';

// TO IMPLEMENT
// import DoctorCard from '../../Components/Cards/DoctorCard';

// import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

const exams = [
	{
		imageUrl: AmrigsLogo,
	},
	{
		imageUrl: EinsteinLogo,
	},
	{
		imageUrl: EnareLogo,
	},
	{
		imageUrl: IamspeLogo,
	},
	{
		imageUrl: SesgoLogo,
	},
	{
		imageUrl: FacMedicinaJundiaiLogo,
	},
	{
		imageUrl: PsuMgLogo,
	},
	{
		imageUrl: SantaCasaLogo,
	},
	{
		imageUrl: SesDfLogo,
	},
	{
		imageUrl: SesPeLogo,
	},
	{
		imageUrl: UfrjLogo,
	},
	{
		imageUrl: AbcLogo,
	},
	{
		imageUrl: SirioLibanesLogo,
	},
	{
		imageUrl: SurceLogo,
	},
	{
		imageUrl: SusBaLogo,
	},
	{
		imageUrl: SusBaLogo,
	},
	{
		imageUrl: SusSpLogo,
	},
	{
		imageUrl: UnespBotucatuLogo,
	},
	{
		imageUrl: AmpLogo,
	},
	{
		imageUrl: UnifespLogo,
	},
	{
		imageUrl: UspLogo,
	},
	{
		imageUrl: UspRibeiraoLogo,
	},
	{
		imageUrl: FamemaLogo,
	},
];

const MigrationTexts = [
	{
		title: 'Por que migrar?',
		description: [
			'Revolucionamos a maneira de se preparar para as provas de residência com a ',
			// <br />,
			<strong style={{ fontWeight: 'bold' }}>Trilha do Aprendizado Definitivo.</strong>,
			<br />,
			<br />,
			'E tem mais! A migração para o novo Extensivo agora também garante acesso a todos os ',
			<strong style={{ fontWeight: 'bold' }}>Sprints Finais 2023</strong>,
			' para provas de residência.',
		],
	},
	{
		title: 'Performance máxima ',
		description: [
			'Além dos recursos que tu já conheces, organizamos de uma nova maneira como tu irás estudar e vencer, ',
			<strong style={{ fontWeight: 'bold' }}>etapa por etapa</strong>,
			', com o auxílio dos professores.',
			<br />,
			<br />,
			'Tu poderás consultar diversas ',
			<strong style={{ fontWeight: 'bold' }}>estatísticas valiosas</strong>,
			' sobre desempenho nos estudos, terás acesso digital às grandes obras da medicina e contarás com apoio dos professores em caso de dúvidas.',
		],
	},
	{
		title: 'Qualquer um pode efetuar a migração?',
		description: [
			<strong style={{ fontWeight: 'bold' }}>Não.</strong>,
			' Apenas alunos regularmente inscritos nos extensivos 21-22-23 ou 22-23 que estejam sem pendências financeiras poderão realizar a migração. ',
			<br />,
			<br />,
			'Lembrando que o valor referente à migração será ',
			<strong style={{ fontWeight: 'bold' }}>adicionado ao investido no extensivo.</strong>,
			' Ou seja, eventuais parcelas futuras não serão inibidas.',
		],
	},
	{
		title: 'Acesso ao',
		description: [
			'É isso mesmo! No ato da migração tu terás acesso garantido a todos os ',
			<strong style={{ fontWeight: 'bold' }}>Sprints Finais 2023</strong>,
			' para provas de residência, que serão liberados no ',
			<strong style={{ fontWeight: 'bold' }}>segundo semestre</strong>,
			' de 2023.',
			<br />,
			<br />,
			'A listagem de todos os Sprints está sujeita a alterações, para mais ou para menos.',
		],
	},
	{
		title: 'Valor promocional',
		description: [
			'Fique ligado na ',
			<strong style={{ fontWeight: 'bold' }}>data</strong>,
			'!',
			<br />,
			<br />,
			'A migração pelo valor promocional de ',
			<strong style={{ fontWeight: 'bold' }}>R$1.400,00</strong>,
			' ficará disponível por ',
			<strong style={{ fontWeight: 'bold' }}>tempo limitado, até 03/02/23.</strong>,
			<br />,
			<br />,
			'Após esta data, o valor original de ',
			<strong style={{ fontWeight: 'bold' }}>R$1.900,00</strong>,
			' entrará em vigor.',
		],
	},
	{
		title: 'Acervo de livros',
		description: [
			'Apostila? ',
			<strong style={{ fontWeight: 'bold' }}>De jeito nenhum!</strong>,
			<br />,
			<br />,
			' Com o novo Extensivo Hardwork R1 tu estudarás junto com o professor pelas principais referências através de um ',
			<strong style={{ fontWeight: 'bold' }}>acervo digital!</strong>,
		],
	},
	{
		title: 'Flashcards Hardwork',
		description: [
			'A ',
			<strong style={{ fontWeight: 'bold' }}>migração</strong>,
			' para o novo Extensivo Hardwork R1 2023 ',
			<strong style={{ fontWeight: 'bold' }}>não</strong>,
			' contempla o envio do ',
			<strong style={{ fontWeight: 'bold' }}>Echo Dot 4.</strong>,
			' Contudo, tu podes utilizar gratuitamente a skill de Residência Médica do Hardwork em qualquer Alexa.',
		],
	},
	{
		title: 'Estudo por questões',
		description: [
			'Ao migrar para o novo Extensivo R1 a dinâmica muda radicalmente.',
			<br />,
			'Com isso, o progresso do Estudo por Questões será ',
			<strong style={{ fontWeight: 'bold' }}>reiniciado</strong>,
			' com a migração.',
		],
	},
	{
		title: 'Até quando terei acesso ao novo extensivo?',
		description: [
			'O acesso à plataforma será mantido até ',
			<strong style={{ fontWeight: 'bold' }}>28/02/24</strong>,
			', ou seja, o mesmo tempo de acesso que tu terias no extensivo anterior.',
		],
	},
	{
		title: 'E se eu optar por não fazer a migração?',
		description:
			'Caso opte por não fazer a migração, tu continuarás tendo acesso à plataforma original do extensivo vigente.',
	},
];

const learningPathTemplate = [
	{
		title: 'Primeira liga',
		description: [
			'A trilha do aprendizado funciona por faixas de evolução. Começaremos por temas mais palpáveis, evoluindo para outros mais peculiares.',
			<>
				<br />
				<br />
			</>,
			'São 8 faixas no total, cada uma com temas diferentes. Para passar para a próxima, precisaremos dominar por completo a atual.',
			<>
				<br />
				<br />
			</>,
			'Veja como tudo vai funcionar em cada faixa:',
		],
		bgColor: '#ffffff',
		titleColor: '#121212',
		descriptionColor: 'primary',
		url: 'https://player.vimeo.com/video/769930065',
	},
	{
		title: 'Aula Hardwork',
		description: [
			'Tudo começa com a apresentação ao tema, que no Hardwork é feito com rigor em didática.',
			<>
				<br />
				<br />
			</>,
			'São as melhores aulas que você já viu sobre cada tema.',
		],
		bgColor: '#0D0D0D',
		titleColor: '#FF005D',
		descriptionColor: 'secondary',
		url: 'https://player.vimeo.com/video/769929590',
	},
	{
		title: 'Estudo por questões',
		description: [
			'Alguns dias após a apresentação ao tema, vamos te conduzir pelo estudo por questões.',
			<>
				<br />
				<br />
			</>,
			'Aqui você entenderá todas as formas em que cada parte do tema é explorado nas provas.',
			<>
				<br />
				<br />
			</>,
			'O professor Hardwork te mostrará tudo em detalhes, aprofundando em cada tema.',
		],
		bgColor: '#0D0D0D',
		titleColor: '#FF005D',
		descriptionColor: 'secondary',
		url: 'https://player.vimeo.com/video/769929662',
	},
	{
		title: 'Revisões cíclicas e personalizadas',
		description: [
			'Questões, questões e questões! É assim que revisamos no Hardwork!',
			<>
				<br />
				<br />
			</>,
			'Toda a dinâmica de revisões é, na verdade, uma continuidade do estudo por questões, explorando com mais ênfase os pontos que você tiver mais dificuldade.',
			<>
				<br />
				<br />
			</>,
			'Todos os temas precisam ser dominados!',
		],
		bgColor: '#0D0D0D',
		titleColor: '#FF005D',
		descriptionColor: 'secondary',
		url: 'https://player.vimeo.com/video/769929881',
	},
	{
		title: 'Avaliação de desempenho',
		description: [
			'A regra é dominar temas! A avaliação de desempenho e domínio dos temas será diária, usando métricas do estudo por questões.',
			<>
				<br />
				<br />
			</>,
			'Assim que o grau de suficiência em um tema for atingido, ele estará dominado! Domine todos e migre para a próxima liga de aprendizado.',
			<>
				<br />
				<br />
			</>,
			'"E se eu tiver dificuldade"? Calma! Siga a trilha...',
		],
		bgColor: '#0D0D0D',
		titleColor: '#FF005D',
		descriptionColor: 'secondary',
		url: 'https://player.vimeo.com/video/769929824',
	},
	{
		title: 'Estude com professor',
		description: [
			'Teve dificuldade? O professor estudará contigo!',
			<>
				<br />
				<br />
			</>,
			'Isso mesmo... vamos abrir o livro juntos, entender frase por frase, parágrafo por parágrafo, o tema ou seu fragmento!',
			<br />,
			'Vamos fazer isso usando a principal referência disponível, que estará à sua disposição na biblioteca virtual do Extensivo.',
			<>
				<br />
				<br />
			</>,
			'Após o estudo em conjunto, nova avaliação de desempenho!',
		],
		bgColor: '#0D0D0D',
		titleColor: '#FF005D',
		descriptionColor: 'secondary',
		url: 'https://player.vimeo.com/video/769929949',
	},
	{
		title: 'Próxima liga',
		description: [
			'A evolução é contínua, são 8 ligas no total.',
			<>
				<br />
				<br />
			</>,
			'Na liga do aprendizado seguinte teremos novos temas, ou fragmentos complementares de temas anteriores.',
			<>
				<br />
				<br />
			</>,
			'Mas não pense que os já vistos serão esquecidos... jamais!',
			<>
				<br />
				<br />
			</>,
			'As revisões serão contínuas, até o fim!',
		],
		bgColor: '#ffffff',
		titleColor: '#121212',
		descriptionColor: 'primary',
		url: 'https://player.vimeo.com/video/769929780',
	},
];

// export const MigrationCard = ({ id, title, description, matchesMobile, matchesTablet }) => {
// 	let orderNumber = id + 1;
// 	return (
// 		<Paper
// 			className={`rounded-card`}
// 			color="secondary"
// 			elevation={4}
// 			style={
// 				matchesMobile
// 					? { minWidth: 'unset', maxWidth: '100%', padding: '1rem' }
// 					: matchesTablet
// 					? { minWidth: 'unset', maxWidth: '100%', padding: '1.2rem' }
// 					: {
// 							width: '100%',
// 							height: 265,
// 							minWidth: 420,
// 							padding: '1.5rem',
// 							display: 'flex',
// 							flexDirection: 'column',
// 							alignItems: 'flex-start',
// 					  }
// 			}
// 		>
// 			<Grid
// 				container
// 				style={matchesMobile ? { marginBottom: 5 } : { marginBottom: 10 }}
// 			>
// 				<Typography
// 					color="primary"
// 					style={
// 						matchesMobile
// 							? { fontSize: '1.5rem', fontWeight: 'bold' }
// 							: { fontSize: '2.2rem', fontWeight: 'bold' }
// 					}
// 					className="roboto-condensed"
// 				>
// 					{<span style={{ color: '#FF005D' }}>{orderNumber + '.'}</span>}
// 					&nbsp;{title}&nbsp;
// 				</Typography>
// 				{id === 3 ? (
// 					<img
// 						src={SprintFinalLogoText}
// 						alt=""
// 						style={matchesMobile ? { width: '35%' } : null}
// 					/>
// 				) : null}
// 			</Grid>
// 			<Grid>
// 				<Typography
// 					color="primary"
// 					className="poppins"
// 					style={matchesMobile ? { fontSize: '0.8rem' } : null}
// 				>
// 					{description}
// 				</Typography>
// 			</Grid>
// 		</Paper>
// 	);
// };

export default function ExtensivoR1Migracao2023() {
	const classes = useStyles();
	const theme = useTheme();
	const migrationCardRef = useRef(null);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const [turma, setTurma] = useState([]);
	useEffect(() => {
		Api.get('turmasabertas/1164').then((res) => {
			if (res) setTurma(res[0]);
		});
	}, []);

	const executeScroll = () => migrationCardRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className="section"
					style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 80 } : null}
				>
					<Grid
						item
						xs={12}
						container
						alignContent="center"
					>
						<Grid
							item
							xs={12}
							md={10}
						>
							<img
								src={MigracaoExtensivoLogo}
								alt=""
								// className={classes.sprintHwdLogo}
								style={
									matchesTablet
										? { position: 'absolute', zIndex: 1, width: '100%' }
										: { width: '55%', position: 'absolute', zIndex: 2 }
								}
							/>
							<img
								src={process.env.PUBLIC_URL + '/Assets/Img/migracaoExtensivo.png'}
								alt=""
								// className={classes.img}
								style={matchesTablet ? null : { width: '80%' }}
							/>
							<Grid
								item
								xs={8}
								sm={6}
								container
								justifyContent="flex-end"
								className={`${classes.gridButton} residencia`}
							>
								<DefaultButton
									variant="outlined"
									color="secondary"
									className={`${classes.btnToMigrate} grid-btn-subscribe btn btn-secondary poppins`}
									onClick={executeScroll}
								>
									Realizar migração
								</DefaultButton>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							container
							alignContent="center"
							className={classes.firstSectionCard}
						>
							<Grid
								item
								xs={12}
								container
								justifyContent={matchesTablet ? 'center' : 'flex-end'}
							>
								<Paper
									color="primary"
									className={`${classes.firstSectionPaper} box box-primary`}
									elevation={4}
								>
									<Grid
										item
										xs={12}
										className={classes.gridItem}
										container
										justifyContent="flex-start"
										alignContent="center"
									>
										<Typography
											align="left"
											color="secondary"
											className={classes.firstText}
										>
											A maior revolução da história
											<br />
											da preparação para as provas
											<br />
											de residência
										</Typography>
									</Grid>
									<Grid
										item
										xs={12}
										container
										className="residencia"
										justifyContent="flex-start"
										alignContent="center"
									>
										<Typography
											align="left"
											variant="h1"
											color="secondary"
											className={`${classes.gradientText} residencia gradient-text`}
										>
											Migre para o novo Extensivo
										</Typography>
									</Grid>
									<Grid
										item
										xs={12}
										className={classes.gridItem}
										container
										justifyContent="flex-start"
										alignContent="center"
									>
										{matchesMobile ? (
											<Typography
												align="left"
												color="secondary"
												className={`${classes.text} poppins`}
											>
												Caminharemos juntos na trilha do aprendizado definitivo para dominar
												qualquer prova de residência do Brasil!
											</Typography>
										) : (
											<Typography
												align="left"
												color="secondary"
												className={`${classes.text} poppins`}
											>
												Caminharemos juntos na{' '}
												<strong>
													trilha do
													<br />
													aprendizado definitivo
												</strong>{' '}
												para dominar
												<br />
												qualquer prova de residência do Brasil!
											</Typography>
										)}
									</Grid>
								</Paper>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Container>
			<LearningPath
				title={'Trilha do aprendizado definitivo'}
				cardsInfo={learningPathTemplate}
				background={'background-gradient-residencia'}
			/>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className="section"
				>
					<Grid
						item
						xs={12}
						container
						style={matchesMobile ? { marginBottom: 16 } : null}
					>
						<Typography
							className={`${classes.subtitle1} roboto-condensed`}
							color="secondary"
						>
							Além da trilha do aprendizado você também ganha
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
					>
						<DiferencialBox
							title="Banco de lives Hardwork"
							description="Acesso imediato ao banco de lives do Hardwork Medicina, com discussões de mais de 200 dos  principais temas voltados para provas de residência médica."
							details="Acesso ao banco de lives com temas das diversas áreas, numa conversa informal e olho-no-olho, no padrão consagrado do Hardwork Medicina."
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
					>
						<DiferencialBox
							title="Acesso aos principais livros"
							description={[
								'Apostila? De jeito nenhum!',
								<>
									<br />
								</>,
								'Tu vais estudar junto com o professor pelas grandes obras!',
							]}
							details="Sim! No extensivo tu terás acesso a milhares de obras consagradas...Vamos estudar pelas principais referências. Tudo JUNTO com o professor! Vamos evoluir frase por frase dentro dos problemas explorados pelas questões. Nada de se restringir a textos resumidos! Vamos dominar as principais fontes! Vamos te mostrar como."
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
					>
						<DiferencialBox
							title="Fala professor!"
							description={[
								'A base do aprendizado é a relação mestre-aprendiz!',
								<>
									<br />
								</>,
								'No Hardwork o seu contato com o professor será direto!',
							]}
							details="Não existe escola em que o aluno não fale com o professor! Aqui o contato é direto, todos os alunos têm acesso aos professores, tanto nos times, quanto via whatsapp."
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
					>
						<DiferencialBox
							title="Banco de questões"
							description="Mais de 100 mil questões de provas dos principais concursos de residência do Brasil."
						/>
					</Grid>
				</Grid>
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className="section"
				>
					<Grid
						item
						xs={12}
						container
					>
						<Grid
							item
							xs={12}
							container
							// style={matchesMobile ? null : { paddingBottom: '2em' }}
						>
							<Typography
								className={`${classes.subtitle1} roboto-condensed`}
								color="secondary"
							>
								E na hora da revisão, receba todos os Sprints
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
						>
							<DiferencialBox
								title="Simulados com comentários em vídeo"
								description="Simulados mensais a partir de abril, com análise de desempenho e comentários em vídeo."
								details="A partir de abril de 2023 teremos simulados mensais, com questões de provas antigas, com objetivo único de identificar lacunas na preparação e permitir ajustes no estudo enquanto há tempo. Nada de ranking! Aqui fazemos análise de desempenho questão por questão...além disso, todos os simulados trazem consigo questões comentadas em texto e em vídeos pelos professores. Ah, mas se tu quiseres, podes configurar um simulado para fazer a qualquer momento!"
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
						>
							<DiferencialBox
								title="Desempenho por concurso"
								description="Cada prova tem sua alma."
								details="Vamos te mostrar toda a tua evolução de acordo com as provas que farás.. Afinal, cada prova tem seus temas de predileção, sua forma de abordar cada assunto."
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
						>
							<DiferencialBox
								title="Grupos de discussão"
								description="Grupos com professores, mentores e alunos Hardwork, para discussão de tudo relacionado às provas!
Seguiremos juntos, sempre!"
								details="Tu farás parte de um time com um grupo de hardworkers, capitaneado por um tutor HWM... o objetivo? Aprender com os pares! Troca de informações, de opiniões, auxílio na orientação dos estudos, na resolução daquelas questões aparentemente espinhosas...Ah, no grupo também estão todos os professores do HWM."
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
						>
							<DiferencialBox
								title="Resumos Hardwork"
								description="Resumos dos principais temas explorados nas provas de residência médica."
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className="section"
				>
					<Grid
						item
						xs={12}
						container
						className="residencia"
						style={matchesMobile ? { paddingBottom: '1em' } : { paddingBottom: '2em' }}
					>
						<Typography
							className={`${classes.subtitle1} roboto-condensed`}
							color="secondary"
						>
							E no segundo semestre de 2023
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						container
					>
						<Grid
							item
							xs={4}
							container
							alignContent={matchesMobile ? 'center' : null}
						>
							{matchesMobile ? (
								<img
									src={SprintFinalMobile}
									alt=""
								/>
							) : (
								<img
									src={SprintHwmLogoSection}
									alt=""
								/>
							)}
						</Grid>
						<Grid
							item
							xs={8}
							container
							alignContent="center"
							style={{ paddingLeft: '2em' }}
						>
							<Typography
								color="secondary"
								className={`${classes.thirdSectionText} poppins`}
							>
								Acesso garantido a todos os <strong>Sprints Finais 2023*</strong> para provas de
								residência: a única revisão realmente focada em provas
							</Typography>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						container
						alignContent="center"
						justifyContent="flex-start"
						style={{ margin: '40px 0px 20px' }}
					>
						{exams.map((item, index) => (
							<Grid
								item
								xs={4}
								md={3}
								lg={2}
								key={index}
								container
								justifyContent="center"
								style={{ margin: '8px 0' }}
							>
								<TestCard imageUrl={item.imageUrl} />
							</Grid>
						))}
					</Grid>
					<Grid
						item
						xs={12}
						container
						alignContent="center"
						justifyContent="flex-start"
					>
						<Typography
							color="secondary"
							className={`${classes.thirdSectionText} poppins`}
						>
							*Sprints previstos para 2023. Listagem sujeita a alterações para mais ou para menos.
						</Typography>
					</Grid>
				</Grid>
			</Container>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					container
					alignContent={matchesTablet ? 'space-around' : 'center'}
					justifyContent="space-between"
					className={`${classes.extensivoR1Card} section migration-price-card`}
					ref={migrationCardRef}
					rowSpacing={matchesTablet ? 3 : 0}
				>
					<Grid
						item
						xs={12}
						md={2}
						container
						justifyContent="center"
						alignContent="center"
						className={classes.extensivoLogoImg}
					>
						<img
							src={matchesTablet ? MigracaoExtensivoImgMobile : MigracaoExtensivoImg}
							alt=""
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={3}
						container
						justifyContent="center"
						alignContent="center"
						className={classes.extensivoLogoImg}
					>
						<img
							src={ExtensivoLogo}
							alt="Logo Extensivo R1 "
							style={matchesTablet ? { width: '94%' } : null}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={3}
						container
						justifyContent={matchesTablet ? 'center' : 'space-between'}
						alignContent="center"
						style={matchesMobile ? { margin: '20px 0px' } : null}
					>
						{/* <img
							src={ExtensivoMigracaoImg}
							alt=""
							style={matchesMobile ? { width: '97%' } : { width: '80%' }}
						/> */}
						<Grid
							item
							container
							alignContent="center"
							style={matchesMobile ? { width: '85%' } : { width: '95%' }}
						>
							<Typography
								color="secondary"
								className={classes.priceTextWhite}
							>
								R$ {turma.valor?.toLocaleString('pt-BR')}
								<sup style={{ fontSize: '61%' }}>,00</sup>
							</Typography>
							<Typography className={classes.priceTextGrey}>ou parcelado em até 12x</Typography>
						</Grid>
						{matchesTablet ? null : (
							<Divider
								variant="middle"
								orientation="vertical"
								className={classes.dividerExtensivoCard}
							/>
						)}
					</Grid>
					<Grid
						item
						xs={12}
						md={3}
						container
						justifyContent="center"
						alignContent="center"
						className={classes.gridButtonPriceCard}
						style={matchesMobile ? null : { marginRight: 10 }}
					>
						<DefaultButton
							variant="outlined"
							color="secondary"
							className={`${classes.btn} btn button-info-card`}
							component={Link}
							to="//hardworkmedicina.com.br/inscricao/1164?desc=migracao"
						>
							<img
								src={SuitcaseIcon}
								alt=""
								style={{ width: 30 }}
							/>
							&nbsp; Realizar migração
						</DefaultButton>
					</Grid>
				</Grid>
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className="section"
				>
					<Grid
						item
						xs={12}
						container
						className="residencia"
						style={matchesMobile ? { paddingBottom: '1em' } : { paddingBottom: '2em' }}
					>
						<Typography
							className={`roboto-condensed`}
							color="secondary"
							style={
								matchesMobile
									? { fontSize: '1.6rem', fontWeight: 'bold' }
									: { fontSize: '3.5rem', fontWeight: 'bold', lineHeight: 'unset' }
							}
						>
							O que mudará ao migrar para o novo Extensivo Hardwork R1?
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						container
						spacing={matchesMobile ? 0 : 3}
						rowSpacing={matchesMobile ? 2 : 0}
					>
						{MigrationTexts.map((item, index) => {
							return (
								<Grid
									item
									xs={12}
									md={6}
									key={index}
								>
									<MigrationCard
										id={index}
										key={index}
										title={item.title}
										description={item.description}
										matchesMobile={matchesMobile}
										matchesTablet={matchesTablet}
										logo={SprintFinalLogoText}
										color="#FF005D"
									/>
								</Grid>
							);
						})}
					</Grid>
				</Grid>
			</Container>
			<StudentsFeedbackSection type="extensivo" />
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{/* <Grid
					container
					alignContent="center"
					justifyContent="center"
					className={classes.section}
				>
					<Grid
						item
						xs={12}
						style={{ paddingTop: '2em' }}
						container
						spacing={3}
					>
						{data.doctors.map((item, index) => (
							<Grid
								item
								xs={3}
								key={index}
							>
								<DoctorCard
									name={item.name}
									specialty={item.specialty}
									type="residencia"
									insta={item.insta}
								/>
							</Grid>
						))}
					</Grid>
				</Grid> */}
				{/* TO DO DEPOIS
				<FaqSection /> */}
				{/* <StudentsFeedbackSection type="residencia" /> */}
				{/* <Newsletter type="residencia" /> */}
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.5em !important',
		lineHeight: '1 !important',
	},
	gradientText: {
		fontSize: '3.2rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '2.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.68rem !important',
		},
	},
	firstText: {
		fontSize: '3rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
		},
	},
	text: {
		fontSize: '1.75rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.35rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.15rem !important',
		},
	},
	firstSectionPaper: {
		color: 'unset !important',
		height: '100%',
		borderRadius: '30px !important',
		padding: '1.5rem 1.5em 2.8em',
		paddingTop: 20,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			padding: '1rem !important',
		},
	},
	firstSectionCard: {
		height: '100%',
		position: 'absolute',
		right: '0',
		[theme.breakpoints.down('md')]: {
			position: 'unset !important',
			right: 'unset !important',
			height: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'unset !important',
			top: 'unset !important',
		},
	},
	gridItem: {
		padding: '.5em 0',
	},
	subtitle1: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('md')]: {
			lineHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
			lineHeight: 'unset !important',
		},
	},
	thirdSectionText: {
		fontSize: '1.12rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	btn: {
		fontSize: '1.35rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		borderColor: 'transparent',
		backgroundColor: '#00AB55 !important',
		border: '2px solid #fff !important',
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				minWidth: '105% !important',
				fontSize: '1.5rem !important',
				borderWidth: '3px !important',
				backgroundColor: '#00543b !important',
				transition: '0.1s',
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.2rem !important',
			width: '95%',
		},
	},
	btnToMigrate: {
		width: '65%',
		fontSize: '1.425rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
			minHeight: 'unset !important',
			width: '80%',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
			width: '90%',
		},
	},
	extensivoR1Card: {
		backgroundColor: '#2D2D2D',
		borderRadius: 20,
		padding: '0 20px',
		[theme.breakpoints.down('md')]: {
			padding: 25,
		},
		[theme.breakpoints.down('sm')]: {
			width: '90% !important',
			padding: '32px 16px !important',
			marginTop: '32px !important',
		},
	},
	dividerExtensivoCard: {
		border: '1.5px solid #fff !important',
		right: 0,
		backgroundColor: '#fff',
		height: '165px !important',
		margin: '20px 0px !important',
		[theme.breakpoints.down('md')]: {
			top: -17,
			height: '100px !important',
		},
		[theme.breakpoints.down('sm')]: {
			height: '70px !important',
			width: '0.05px !important',
			top: -1,
		},
	},
	extensivoLogoImg: {
		[theme.breakpoints.down('md')]: {
			padding: '0px',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0px 10px',
		},
	},
	gridButtonPriceCard: {
		[theme.breakpoints.down('sm')]: {
			padding: 0,
		},
	},
	gridButton: {
		position: 'absolute',
		bottom: '2.5em',
		[theme.breakpoints.down('md')]: {
			bottom: '0.5em',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			bottom: '1.5em',
		},
	},
	priceTextWhite: {
		fontSize: '3.5rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.75rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '3.25rem !important',
		},
		[theme.breakpoints.down(376)]: {
			fontSize: '3rem !important',
		},
	},
	priceTextGrey: {
		fontSize: '1.25rem !important',
		color: 'grey !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.95rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(376)]: {
			fontSize: '1.15rem !important',
		},
	},
}));
