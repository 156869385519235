import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, Grid, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link, useSearchParams } from 'react-router-dom';
import DefaultButton from '../Buttons/DefaultButton';

import Api from '../../Services/Api';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';

export default function ArenaHwmCard({ group, title, titleButton, confirmationMessage, urlToRedirect }) {
	const classes = useStyles();
	const [setTurmaSelecionada] = useState('');
	const theme = useTheme();
	const inputRef = useRef();
	const [searchParams] = useSearchParams();
	const [preInscricao, setPreInscricao] = useState({
		agrupamento: 'Inscricao arena - revalida 2024.1',
		nome: '',
		email: '',
		telefone: '',
		provas: '',
		optin: false,
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
	});
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));

	useEffect(() => {
		setPreInscricao({
			...preInscricao,
			utm_source: searchParams.get('utm_source'),
			utm_medium: searchParams.get('utm_medium'),
			utm_campaign: searchParams.get('utm_campaign'),
			utm_content: searchParams.get('utm_content'),
			utm_term: searchParams.get('utm_term'),
		});
	}, [preInscricao, searchParams]);

	let sendPreInscricao = function () {
		if (!preInscricao.nome) {
			alert('Ops... Por favor informe seu nome');
			return;
		}
		if (!preInscricao.email || !preInscricao.email.includes('@') || !preInscricao.email.includes('.')) {
			alert('Ops... Por favor preencha seu email');
			return;
		}
		if (!preInscricao.telefone || preInscricao.telefone.length < 11) {
			alert('Ops... Por favor preencha seu telefone');
			return;
		}
		// if (!preInscricao.provas) {
		// 	alert('Ops... Por favor escolha uma turma');
		// 	return;
		// }
		if (!preInscricao.optin) {
			alert('Ops... Você precisa ler e aceitar os termos de uso acima para se pré-inscrever');
			return;
		}

		trackPromise(
			Api.post('PreInscricao', preInscricao).then((res) => {
				if (res) {
					Swal.fire({
						title: 'Massa! ',
						text: `${
							confirmationMessage
								? confirmationMessage
								: 'Sua pré-inscrição está confirmada! Em breve avisaremos sobre as novidades.'
						}`,
						icon: 'success',
						confirmButtonText: 'Fechar',
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then((result) => {
						if (result.isConfirmed) {
							if (urlToRedirect) {
								document.location.href = `${urlToRedirect}`;
							} else {
								document.location.href = '/';
							}
						}
					});
				}
			})
		);
	};

	const handlePhone = (event) => {
		let input = event.target;
		input.value = phoneMask(input.value);
		setPreInscricao({
			...preInscricao,
			telefone: input.value,
		});
	};

	const phoneMask = (value) => {
		if (!value) return '';
		value = value.replace(/\D/g, '');
		value = value.replace(/(\d{2})(\d)/, '($1) $2');
		value = value.replace(/(\d)(\d{4})$/, '$1-$2');
		return value;
	};

	return (
		<Grid
			container
			className="revalida-live-card"
			direction={matchesTablet ? 'column' : 'row'}
			style={{ border: 'solid 3px #ffffff', borderRadius: 24 }}
		>
			<Grid
				item
				lg={6}
				container
				justifyContent={'center'}
				alignContent={'center'}
				className={classes.background}
			>
				<img
					src={require('../../Assets/Arena/logo-arena-24.svg').default}
					alt="logo"
					style={{ width: matchesLg ? '50%' : matchesTablet ? '70%' : '60%' }}
				/>
			</Grid>
			<Grid
				item
				lg={6}
				container
			>
				<form
					className="form-control"
					action="javascript:;"
				>
					<Grid
						xs={12}
						style={{
							padding: `1em ${matchesMobile ? '1.25em' : '2em'}`,
							backgroundColor: '#FFFFFF',
							borderRadius: 16,
						}}
					>
						<Grid
							item
							container
							justifyContent="center"
						>
							<Typography
								className={`${classes.cardTitle} poppins`}
								color="primary"
								align="left"
							>
								{title}
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
						>
							<TextField
								fullWidth
								type="string"
								name="nome"
								placeholder="Seu nome completo"
								required={true}
								InputProps={{
									className: `${classes.form} ${classes.formHeight}`,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										nome: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
						>
							<TextField
								fullWidth
								type="email"
								name="email"
								placeholder="Seu melhor e-mail"
								required={true}
								InputProps={{
									className: `${classes.form} ${classes.formHeight}`,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										email: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
						>
							<TextField
								fullWidth
								type="tel"
								name="telefone"
								placeholder="Seu celular com DDD"
								required={true}
								pattern="[0-9]*"
								inputProps={{
									maxLength: 15,
								}}
								InputProps={{
									className: `${classes.form} ${classes.formHeight}`,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								onChange={(e) => handlePhone(e)}
							/>
						</Grid>
						{/* <Grid
							item
							xs={12}
						>
							<FormControl style={{ width: '100%', margin: '5px 0px' }}>
								<InputLabel id="test-select-label">Turma</InputLabel>
								<input
									type="hidden"
									name="provas"
									ref={inputRef}
								/>
								<Select
									value={turmaSelecionada?.name}
									labelId="test-select-label"
									onChange={handleChangeGroup}
									className={classes.selectMenu}
								>
									{turmas.map((turma) => {
										return (
											<MenuItem
												key={turma.id}
												value={turma}
												className="poppins"
											>
												<Typography
													color="primary"
													className={`${classes.textSelectItem} poppins`}
												>
													{turma.name}
												</Typography>
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid> */}
						<Grid
							item
							xs={12}
							style={{ margin: '8px 0px' }}
						>
							<Checkbox
								style={{ padding: '0px' }}
								checked={preInscricao.optin}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										optin: !preInscricao.optin,
									})
								}
							/>
							<Typography
								variant="caption"
								color="primary"
							>
								Estou de acordo com o tratamento dos meus dados pessoais para fins de inscrição na
								plataforma Hardwork Medicina, bem como para envio de comunicações relativas aos serviços
								educacionais que se adequem ao meu perfil, de acordo com a nossa{' '}
								<Link
									color="primary"
									href="https://hardworkmedicina.com.br/politica-de-privacidade"
									target="_blank"
									underline="hover"
									variant="caption"
									align="right"
									className={`${classes.copyrightText} poppins`}
								>
									Politica de privacidade
								</Link>
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							container
							justifyContent="center"
							className="residencia"
							style={{ height: 50, padding: '4px 0px' }}
						>
							<DefaultButton
								type="submit"
								variant="outlined"
								color="primary"
								className={`${classes.btn} btn`}
								onClick={() => sendPreInscricao()}
							>
								{titleButton}
							</DefaultButton>
						</Grid>
					</Grid>
				</form>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	background: {
		backgroundImage: `url(${require('../../Assets/Arena/bg-arena-card.png')})`,
		backgroundSize: 'cover !important',
		borderTopLeftRadius: '15px !important',
		borderBottomLeftRadius: '15px !important',
		[theme.breakpoints.down('lg')]: {
			padding: '2em',
		},
		[theme.breakpoints.down('sm')]: {
			borderRadius: '20px !important',
		},
	},
	cardTitle: {
		fontSize: '2.1rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.2rem !important',
		},
	},
	btn: {
		fontSize: '1.15rem !important',
		width: '75%',
		fontWeight: 'bold !important',
		textTransform: 'uppercase !important',
		backgroundColor: '#09D785 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	copyrightText: {
		color: theme.palette.primary.main,
	},
	form: {
		borderRadius: '8px !important',
		backgroundColor: '#E1E1E1 !important',
		margin: '5px 0 !important',
	},
	formHeight: {
		maxHeight: '3em',
	},
	selectMenu: {
		width: '100%',
		borderRadius: '8px !important',
		backgroundColor: '#E1E1E1',
		maxHeight: 53,
		[theme.breakpoints.down('sm')]: {
			height: 40,
		},
	},
}));
