import React from 'react';

import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

const DefaultButton = (props) => {
	const classes = useStyles(props);

	const { variant, color, href, onClick, inDrawer, children, type } = props;

	return (
		<Button
			type={type}
			variant={variant}
			href={href}
			onClick={onClick}
			color={color}
			disabled={props.disabled}
			classes={{
				root: classes.root,
				label: `${classes.label} ${inDrawer ? classes.inDrawer : null}`,
				disabled: classes.disabled,
				outlined: classes.outlined,
			}}
			{...props}
		>
			{children}
		</Button>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		borderRadius: '30px !important',
		padding: '6px 19px',
		textTransform: 'none !important',
	},
	label: {
		fontSize: '1rem',
		fontWeight: 'normal',
		height: 25,
	},
	inDrawer: {
		height: 35,
		fontSize: '1.2rem',
	},
	outlined: {
		borderWidth: 2,
	},
	disabled: {},
}));

export default DefaultButton;
