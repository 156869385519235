import React, { useState } from 'react';
//Libraries
import { Dialog, DialogActions, DialogContent, Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
//Components
import DefaultButton from '../Buttons/DefaultButton';

const LearningSpliterCard = ({
	title,
	description,
	type,
	url,
	color,
	bgColor,
	titleColor,
	descriptionColor,
	nextSlide,
	handleShow,
	isOpenCard,
    isMany,
}) => {
	const classes = useStyles();
	const theme = useTheme();
	const [open, setOpen] = useState(false);

	const handleClickOpen = (e) => {
		e.preventDefault();
	};

	const handleClose = () => {
		setOpen(false);
	};

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Paper
			className={
				matchesMobile
					? `${isOpenCard ? classes.root : classes.minRoot} saiba-mais-card`
					: `${classes.root} saiba-mais-card`
			}
			elevation={bgColor === 'transparent' ? 0 : 4}
			style={
				bgColor === 'transparent'
					? { width: '220px', backgroundColor: `${bgColor}`, padding: 0 }
					: { backgroundColor: `${bgColor}` }
			}
		>
			{bgColor === 'transparent' ? (
				<Grid
					container
					justifyContent="center"
					alignContent="center"
					style={{ transform: 'rotate(90deg)' }}
				>
					{/* <img src={DoubleArrow} /> */}
				</Grid>
			) : (
				<Grid
					container
					justifyContent="center"
					alignContent="space-between"
				>
					<Grid
						item
						xs={12}
						container
						className={`${
							titleColor === 'purple'
								? 'clinica-medica'
								: titleColor === 'cirurgia'
								? 'cirurgia-geral'
								: null
						} saiba-mais-grid-img`}
					>
						<Typography
							color="secondary"
							align="left"
							className={`${classes.titleText} description-saiba-mais-card roboto-condensed ${
								titleColor === 'purple'
									? 'clinica-medica gradient-text'
									: titleColor === 'cirurgia'
									? 'cirurgia-geral gradient-text'
									: null
							}`}
							style={
								matchesMobile && !isOpenCard
									? { height: 'auto', boxSizing: 'content-box !important', color: titleColor }
									: { color: `${titleColor}` }
							}
						>
							{title}
						</Typography>
						<Typography
							color={`${descriptionColor}`}
							align="left"
							className={`${
								isOpenCard ? classes.content : classes.collapsedContent
							}  description-saiba-mais-card poppins`}
							style={url || matchesMobile ? { overflowY: 'auto' } : { overflowY: 'auto' }}
						>
							{description}
						</Typography>
					</Grid>

					<Grid
						item
						xs={12}
						className="grid-description-saiba-mais"
						// style={{ marginTop: 15, height: isOpenCard ? '50px !important' : '100px' }}
					>
						<Grid
							container
							justifyContent={'space-around'}
							alignItems={'center'}
							style={{ height: '100%' }}
							// style={matchesMobile && !isOpenCard ? { marginTop: 40 } : { marginTop: 10 }}
						>
							{matchesMobile && (
								<Grid
									item
									justifyContent={'center'}
									alignItems={'center'}
								>
									<DefaultButton
										variant="outlined"
										color="secondary"
										className={`${classes.popupBtn} `}
										style={
											descriptionColor === 'secondary'
												? { color: '#000', backgroundColor: '#fff' }
												: { backgroundColor: 'black' }
										}
										onClick={handleShow}
										autoFocus
									>
										{isOpenCard ? 'ver menos' : 'ver mais'}
									</DefaultButton>
								</Grid>
							)}
                            <Grid
								item
								justifyContent={'center'}
								alignItems={'center'}
							>
								<DefaultButton
									variant="outlined"
									color="secondary"
									className={`${classes.popupBtn} `}
									style={
										descriptionColor === 'secondary'
											? { color: '#000', backgroundColor: '#fff' }
											: { backgroundColor: 'black' }
									}
									onClick={nextSlide}
									autoFocus
								>
									Próximo {'>'}
								</DefaultButton>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className={classes.popup}
				maxWidth="xl"
				PaperProps={{
					style: {
						borderRadius: 20,
					},
				}}
			>
				<DialogContent className={classes.dialogContent}>
					<iframe
						title={title}
						src={url}
						style={{ border: 0, width: '100%', aspectRatio: 16 / 9, minWidth: '45vw', borderRadius: 8 }}
					></iframe>
				</DialogContent>
				<DialogActions style={{ width: '50%', alignSelf: 'center', padding: 16 }}>
					<DefaultButton
						variant="outlined"
						color="secondary"
						className={`${classes.popupBtn} ${descriptionColor === 'secondary' ? 'white-btn' : 'btn'}`}
						onClick={handleClose}
						autoFocus
					>
						Fechar
					</DefaultButton>
				</DialogActions>
			</Dialog>
		</Paper>
	);
};

const useStyles = makeStyles((theme, bgColor) => ({
	root: {
		color: 'unset !important',
		height: '420px !important',
		width: '332px',
		borderRadius: '15px !important',
		padding: '1.5rem 1.5rem 3rem 1.5rem',
		display: 'flex',
		marginBottom: 30,
		[theme.breakpoints.down('xl')]: {
			width: 'fit-content',
		},
		[theme.breakpoints.down('md')]: {
			width: '312px',
		},
		[theme.breakpoints.down('sm')]: {
			height: '370px !important',
			width: '320px !important',
		},
	},
	minRoot: {
		color: 'unset !important',
		height: '250px !important',
		width: '332px',
		borderRadius: '15px !important',
		padding: '1.5rem 1.5rem 1.5rem 1.5rem',
		display: 'flex',
		marginBottom: 30,
		[theme.breakpoints.down('xl')]: {
			width: 'fit-content',
		},
		[theme.breakpoints.down('md')]: {
			width: '312px',
		},
		[theme.breakpoints.down('sm')]: {
			// height: '200px !important',
			width: '320px !important',
		},
	},
	titleText: {
		fontSize: '2.25em !important',
		height: '4.5rem',
		marginBottom: '15px !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem !important',
		},
	},
	content: {
		lineHeight: '1.35 !important',
		height: 230,
		minHeight: 200,
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
			maxHeight: 200,
		},
	},
	collapsedContent: {
		lineHeight: '1.35 !important',
		// height: '20px',
		maxHeight: '80px !important',
		paddingTop: '0.5rem',
		// minHeight: 'auto',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
			maxHeight: 200,
		},
	},
	gridButton: {
		position: 'absolute',
		bottom: '-4%',
		left: 35,
		[theme.breakpoints.down('sm')]: {
			bottom: '-18px !important',
			left: 70,
		},
	},
	btn: {
		textTransform: 'unset !important',
		width: '70%',
		fontSize: '1rem !important',
		fontWeight: 'bold !important',
		color: '#fff !important',
		border: '3px solid #fff !important',
		backgroundColor: '#0d0d0d !important',
		'&:hover': {
			scale: 1.1,
			backgroundColor: '#383333 !important',
		},
		[theme.breakpoints.down('sm')]: {
			minHeight: 'unset !important',
			maxHeight: '3.5em !important',
			width: '150px',
			fontSize: '0.8rem !important',
		},
	},
	btnWhite: {
		textTransform: 'unset !important',
		width: '70%',
		fontSize: '1rem !important',
		fontWeight: 'bold !important',
		color: 'black !important',
		border: '3px solid black !important',
		backgroundColor: '#fff !important',
		'&:hover': {
			scale: 1.1,
			backgroundColor: '#c3b9b9 !important',
		},
		[theme.breakpoints.down('sm')]: {
			minHeight: 'unset !important',
			maxHeight: '3.5em !important',
			width: '150px',
			fontSize: '0.8rem !important',
		},
	},
	popup: {
		maxWidth: 'unset !important',
		minWidth: '90%',
	},
	dialogContent: {
		paddingBottom: '0 !important',
	},
	popupBtn: {
		maxWidth: 'fit-content',
		// fontWeight: 'bold !important',
		marginTop: '1rem !important',
		'&:hover': {
			scale: 1.1,
		},
		[theme.breakpoints.down('sm')]: {
			minHeight: 'unset !important',
			maxHeight: '3.5em !important',
			marginTop: 'unset !important',

			// width: '150px',
			// fontSize: '0.8rem !important',
		},
	},
}));

export default LearningSpliterCard;
