import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default function SprintFinalR3CM() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Grid
			container
			rowGap={2}
		>
			<Grid
				item
				xs={12}
				container
				rowGap={4}
				justifyContent={'center'}
			>
				{matchesTablet && (
					<img
						src={require('../Assets/logo-sf-cm-mobile-2024.svg').default}
						alt=""
					/>
				)}
				<img
					src={require('../Assets/img-sprint-app.png')}
					alt=""
				/>
			</Grid>
			<Grid
				item
				xs={12}
				container
				alignItems={'center'}
				columnGap={matchesTablet ? 1 : 1.5}
				className="residencia"
			>
				<img
					src={require('../Assets/live-icon.png')}
					alt=""
				/>
				<Typography
					align="left"
					color="primary"
					className={`${classes.title} gradient-text-sprint-default`}
					fontFamily={'Manrope'}
				>
					ABERTURA DE INSCRIÇÕES
				</Typography>
			</Grid>
			<Typography
				align="left"
				color="primary"
				className={`${classes.text}`}
				fontFamily={'Manrope'}
			>
				Segredos da prova de R3 de Clínica
				<br />
				médica da <span style={{ fontWeight: 'bold' }}>USP</span> e{' '}
				<span style={{ fontWeight: 'bold' }}>ENARE</span>!
			</Typography>
			<Grid
				item
				style={{
					backgroundColor: '#303030',
					width: 'fit-content',
					borderRadius: 16,
					padding: '1rem',
					margin: matchesTablet ? '1rem auto 0px' : '2rem 0 0 0',
				}}
			>
				<Typography
					align="left"
					color="secondary"
					className={`${classes.impactText}`}
					fontFamily={'Manrope'}
				>
					??AGO • ?????-FEIRA • 20:00H
				</Typography>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: '800 !important',
		fontSize: '2.5rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.375rem !important',
		},
	},
	subtitle: {
		fontSize: '1.25rem !important',
		lineHeight: '1.25 !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	text: {
		fontSize: '1.75rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	impactText: {
		fontWeight: 'bold !important',
		fontSize: '1.75rem !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.125rem !important',
		},
	},
}));
