import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Footer from '../Components/Footer';
import { Header } from '../Components/Header';
import HeaderMobile from '../Components/HeaderMobile';
import OurHistoryCard from '../Components/Cards/OurHistoryCard';

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.75rem !important',
		lineHeight: 'unset !important',
		marginBottom: '20px !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.75rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '2.35rem !important',
			marginBottom: '5px !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.8rem !important',
		},
	},
	greyText: {
		fontSize: '1.315rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.95rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '0.95rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	sectionMobile: {
		padding: '2vh 3% 0',
		[theme.breakpoints.down('md')]: {
			padding: '2vh 3% 3vh',
		},
	},
}));

export default function AboutUsPage() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container
			fixed
			maxWidth="xl"
			disableGutters={true}
		>
			{matchesMobile || matchesTablet ? <HeaderMobile /> : <Header />}
			<Grid
				container
				style={matchesMobile || matchesTablet ? { marginTop: 60 } : { marginTop: '2.5%' }}
				rowSpacing={matchesMobile ? 1 : 2}
				columnSpacing={matchesMobile ? 1 : 0}
				className={`${matchesMobile || matchesTablet ? classes.sectionMobile : classes.section}`}
			>
				<Grid
					item
					container
					xs={12}
					md={4}
					alignContent="center"
				>
					<Typography
						align="left"
						variant="h1"
						className={`${classes.title} residencia gradient-text title-home`}
					>
						Já vivemos o que
						<br />
						você vive agora
					</Typography>
					<Typography
						align="left"
						color="#DCDCDC"
						className={`${classes.greyText} home-page-text-content`}
					>
						Aliquam iaculis vestibulum nisl, eget imperdiet lectus ultrices at. Phasellus mattis, quam vitae
						consectetur tincidunt.
					</Typography>
				</Grid>
				<Grid
					item
					container
					justifyContent="center"
					xs={6}
					md={4}
					style={matchesMobile ? null : matchesTablet ? { paddingRight: 10 } : null}
				>
					<img
						src={process.env.PUBLIC_URL + '/Assets/Img/sobreNos1.png'}
						alt=""
					/>
				</Grid>
				<Grid
					item
					container
					justifyContent="center"
					xs={6}
					md={4}
					style={matchesMobile ? null : matchesTablet ? { paddingLeft: 10 } : null}
				>
					<img
						src={process.env.PUBLIC_URL + '/Assets/Img/sobreNos2.png'}
						alt=""
					/>
				</Grid>
				<Grid
					item
					container
					justifyContent="center"
					xs={12}
					md={8}
				>
					<img
						src={process.env.PUBLIC_URL + '/Assets/Img/sobreNos3.png'}
						alt=""
					/>
				</Grid>
				<Grid
					item
					container
					alignContent="center"
					style={matchesMobile ? null : matchesTablet ? { padding: '1rem 0' } : { padding: '1rem' }}
					xs={12}
					md={4}
				>
					<Typography
						align="left"
						variant="h1"
						className={`${classes.title} residencia gradient-text title-home`}
					>
						Nós conhecemos a<br />
						fundo o seu desafio
					</Typography>
					<Typography
						align="left"
						color="#DCDCDC"
						className={`${classes.greyText} home-page-text-content`}
					>
						Aliquam iaculis vestibulum nisl, eget imperdiet lectus ultrices at. Phasellus mattis, quam vitae
						consectetur tincidunt.
					</Typography>
				</Grid>
			</Grid>
			<Grid
				container
				className={`${matchesMobile || matchesTablet ? classes.sectionMobile : classes.section}`}
			>
				<Grid
					item
					xs={12}
					md={6}
					style={matchesMobile ? { order: 1 } : null}
				>
					<Typography
						align="left"
						variant="h1"
						className={`${classes.title} residencia gradient-text title-home`}
					>
						Nossa história
					</Typography>
					<Typography
						align="left"
						color="#DCDCDC"
						className={`${classes.greyText} home-page-text-content`}
					>
						Sed eleifend id mi sit amet consectetur. Donec congue sem sed tortor vestibulum, cursus
						imperdiet urna faucibus. Maecenas tristique tellus nec consectetur efficitur. Etiam tincidunt
						commodo interdum. Aliquam a urna ac neque ultrices imperdiet. Integer sed mi hendrerit,
						ultricies massa ut, ullamcorper nunc. Nam sit amet convallis orci. Proin rutrum sem nec dapibus
						vehicula. Pellentesque erat est, pulvinar at sem quis, elementum tempus quam. Aenean commodo
						nulla a augue ultrices, eget tempor quam sagittis. Morbi tincidunt hendrerit vestibulum.
						<br />
						<br />
						Ut nec commodo mauris. Donec fermentum dictum libero vel ultrices. Donec ac porta urna. Cras
						vulputate mattis bibendum. Curabitur nibh ipsum, dignissim vel mauris vitae, sollicitudin
						aliquet urna. Nulla sit amet nunc nibh. Praesent quis purus non orci lacinia tristique. Nulla
						quis urna sollicitudin, rhoncus mauris eget, eleifend justo. In tristique dignissim quam ut
						malesuada. Aliquam iaculis vestibulum nisl, eget imperdiet lectus ultrices at. Phasellus mattis,
						quam vitae consectetur tincidunt, ipsum nibh viverra eros, lobortis ullamcorper velit mi quis
						lectus. Vestibulum lacus urna, rutrum at bibendum at, faucibus non nunc.
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
					container
					justifyContent={matchesTablet ? 'center' : 'flex-end'}
					style={matchesMobile ? { order: 0 } : matchesTablet ? { margin: '5% 0' } : null}
				>
					<img
						src={process.env.PUBLIC_URL + '/Assets/Img/speakers4.png'}
						alt=""
						style={matchesTablet ? { width: 450 } : null}
					/>
				</Grid>
			</Grid>
			<Grid
				container
				className={`${matchesMobile || matchesTablet ? classes.sectionMobile : classes.section}`}
			>
				<Grid
					item
					xs={12}
					md={6}
				>
					<Typography
						align="left"
						variant="h1"
						className={`${classes.title} residencia gradient-text title-home`}
					>
						Assim seguimos adiante
					</Typography>
					<Typography
						align="left"
						color="#DCDCDC"
						className={`${classes.greyText} home-page-text-content`}
					>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ac finibus eros. Aliquam sit amet
						augue non augue ornare pellentesque. Aenean euismod suscipit tortor dignissim aliquam.
					</Typography>
				</Grid>
				{matchesMobile ? null : (
					<Grid
						item
						xs={12}
						container
						justifyContent="space-between"
						style={{ margin: '5% 0 0' }}
						rowSpacing={matchesTablet ? 2 : 0}
					>
						<Grid
							item
							xs={6}
							md={3}
							container
							justifyContent="center"
						>
							<OurHistoryCard
								title="2014"
								description={`O início de tudo. Dois médicos e uma ideia fixa: desenvolver uma metodologia de ensino prazerosa e altamente eficaz baseada em suas próprias dificuldades de aprendizado à época de suas próprias clínicas médicas. Acertos, ajustes, resilíência e muito suor até a primeira turma e a primeira aula.`}
							/>
						</Grid>
						<Grid
							item
							xs={6}
							md={3}
							container
							justifyContent="center"
						>
							<OurHistoryCard
								title="2015"
								description="Os primeiros frutos. Com uma taxa de sucesso surpreendente de quase 100% de aprovação se sua turma inicial, os feedbacks positivos trouxeram consigo uma nova necessidade: investir maciçamente em tecnologia e comunicação. Novos formatos foram criados, juntamente com a nova plataforma online da Hardwork."
							/>
						</Grid>
						<Grid
							item
							xs={6}
							md={3}
							container
							justifyContent="center"
						>
							<OurHistoryCard
								title="2016"
								description="Lorem ipsum dolor sit amet. Sed nec accumsan erat, sit amet consectetur nisl. Donec vitae dui quis ex posuere blandit id non turpis. Vivamus molestie lectus quis massa pretium, eu aliquet ligula vestibulum. Donec ac magna iaculis, sollicitudin libero sit amet, elementum augue. Donec facilisis, magna non convallis faucibus.
"
							/>
						</Grid>
						<Grid
							item
							xs={6}
							md={3}
							container
							justifyContent="center"
						>
							<OurHistoryCard
								title="2017"
								description="Lorem ipsum dolor sit amet. Sed nec accumsan erat, sit amet consectetur nisl. Donec vitae dui quis ex posuere blandit id non turpis. Vivamus molestie lectus quis massa pretium, eu aliquet ligula vestibulum. Donec ac magna iaculis, sollicitudin libero sit amet, elementum augue. Donec facilisis, magna non convallis faucibus.
"
							/>
						</Grid>
					</Grid>
				)}
			</Grid>
			{/* <Newsletter type="default-hardwork" /> */}
			<Footer />
		</Container>
	);
}
