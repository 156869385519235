import React from 'react';
import { Container, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Header } from '../../../../Components/Header';
import Footer from '../../../../Components/Footer';
import DefaultButton from '../../../../Components/Buttons/DefaultButton';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Link } from 'react-router-dom';

export default function SubscriptionSouHardwork() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const photoGallery = [
		{
			id: 1,
			img: require('../../../../Assets/Arena/gallery-arena-2022-1.png'),
		},
		{
			id: 2,
			img: require('../../../../Assets/Arena/gallery-arena-2022-2.png'),
		},
		{
			id: 3,
			img: require('../../../../Assets/Arena/gallery-arena-2022-3.png'),
		},
		{
			id: 4,
			img: require('../../../../Assets/Arena/gallery-arena-2022-4.png'),
		},
		{
			id: 5,
			img: require('../../../../Assets/Arena/gallery-arena-2022-5.png'),
		},
		{
			id: 6,
			img: require('../../../../Assets/Arena/gallery-arena-2022-6.png'),
		},
		{
			id: 7,
			img: require('../../../../Assets/Arena/gallery-arena-2022-7.png'),
		},
		{
			id: 8,
			img: require('../../../../Assets/Arena/gallery-arena-2022-8.png'),
		},
		{
			id: 9,
			img: require('../../../../Assets/Arena/gallery-arena-2022-9.png'),
		},
		{
			id: 10,
			img: require('../../../../Assets/Arena/gallery-arena-2022-10.png'),
		},
		{
			id: 11,
			img: require('../../../../Assets/Arena/gallery-arena-2022-11.png'),
		},
		{
			id: 12,
			img: require('../../../../Assets/Arena/gallery-arena-2022-12.png'),
		},
		{
			id: 13,
			img: require('../../../../Assets/Arena/gallery-arena-2022-13.png'),
		},
		{
			id: 14,
			img: require('../../../../Assets/Arena/gallery-arena-2022-14.png'),
		},
		{
			id: 15,
			img: require('../../../../Assets/Arena/gallery-arena-2022-15.png'),
		},
		{
			id: 16,
			img: require('../../../../Assets/Arena/gallery-arena-2022-16.png'),
		},
		{
			id: 17,
			img: require('../../../../Assets/Arena/gallery-arena-2022-17.png'),
		},
		{
			id: 18,
			img: require('../../../../Assets/Arena/gallery-arena-2022-18.png'),
		},
		{
			id: 19,
			img: require('../../../../Assets/Arena/gallery-arena-2022-19.png'),
		},
	];

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					className="section residencia"
					style={{ margin: '80px auto 40px' }}
				>
					<Grid
						item
						xs={12}
						md={6}
						container
						gap={2}
						style={{ paddingBottom: '2rem' }}
					>
						<Typography className={`${classes.title} gradient-text roboto-condensed`}>
							#SOUHARDWORK
						</Typography>
						<Typography
							color="secondary"
							className={`${classes.subtitle} poppins`}
						>
							Uma experiência gratuita, apenas para estudantes de medicina selecionados que viverão uma
							imersão na Cultura Hardwork!
						</Typography>
						<Grid
							item
							xs={12}
						>
							<Divider className={classes.divider} />
						</Grid>
						<Typography
							color="secondary"
							className={`${classes.text} poppins`}
						>
							Você se considera um <strong>Hardworker</strong>?
							<br />
							<br />
							Você é capaz de ver o mundo, sua vida, a medicina, com um olhar crítico, fora da caixa e
							fugindo de padrões pré-estabelecidos?
							<br />
							<br />
							Você tem o inconformismo necessário para mudar e desafiar o Status Quo?
							<br />
							<br />
							Se sim, nós queremos te <strong>impulsionar</strong>.
							<br />
							<br />
							Preparamos um final de semana imersivo na Cultura Hardwork, para você potencializar essas
							características.
							<br />
							<br />
							Vamos falar sobre vida, estudos, profissão. Vai ser eletrizante!
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
						container
						alignContent={'flex-end'}
						style={matchesTablet ? null : { padding: '4rem 1rem 0 5rem' }}
					>
						<Grid
							item
							container
							justifyContent={'center'}
							className="background-residencia"
							style={{
								borderRadius: 15,
								padding: matchesMobile ? '1rem 1.25rem' : '1rem 3rem',
								height: 'fit-content',
							}}
							gap={1}
						>
							<Typography
								color="secondary"
								align={matchesTablet ? 'left' : 'center'}
								className={`${classes.containerTitle} poppins`}
							>
								Veja como participar!
							</Typography>
							<Typography
								color="secondary"
								className={`${classes.text2} poppins`}
								align="left"
							>
								O processo seletivo dos participantes terá algumas etapas. Na primeira queremos te
								conhecer um pouco, por isso clique no botão abaixo para acessar e preencher um
								formulário.
								<br />
								<br />
								Depois disso nosso time analisará as candidaturas e contactará os alunos que avançarem
								para as próximas etapas.
							</Typography>
							<Typography
								color="secondary"
								className={`${classes.containerSubtitle} poppins`}
								align={matchesTablet ? 'left' : 'center'}
							>
								SOU HARDWORK!
							</Typography>
							<DefaultButton
								variant="outlined"
								color="secondary"
								className={`${classes.btn} btn btn-secondary`}
								component={Link}
								to="/sou-hardwork-inscricao"
							>
								QUERO ME INSCREVER!
							</DefaultButton>
						</Grid>
					</Grid>
				</Grid>
			</Container>
			<Grid
				container
				className="residencia"
				style={{ backgroundColor: '#33333380', padding: matchesMobile ? '0 1.5rem' : '0 1rem' }}
			>
				<Grid
					container
					justifyContent={'center'}
					className={`${classes.gridSplide} section`}
					style={{ margin: matchesMobile ? '10px auto 20px' : '10px auto' }}
				>
					<Typography className={`${classes.splideTitle} gradient-text roboto-condensed						`}>
						Confira como foram nossos últimos eventos:
					</Typography>
					<Splide
						className={classes.splideMainContainer}
						options={matchesMobile && { arrows: false, autoplay: true, rewind: true }}
					>
						{!matchesMobile ? (
							<>
								<SplideSlide>
									<Grid
										item
										container
										justifyContent={'space-around'}
									>
										{photoGallery.map((item, index) => {
											if (item.id < 11) {
												return (
													<Grid
														item
														key={item.id}
														className={classes.gridImage}
														// onClick={() => handleOpen(index)}
													>
														<img
															src={item.img}
															alt=""
															className={classes.image}
														/>
													</Grid>
												);
											} else {
												return null;
											}
										})}
									</Grid>
								</SplideSlide>
								<SplideSlide>
									<Grid
										item
										container
										justifyContent={'space-around'}
									>
										{photoGallery.map((item, index) => {
											if (item.id >= 7 && item.id < 17) {
												return (
													<Grid
														item
														key={item.id}
														className={classes.gridImage}
														// onClick={() => handleOpen(index)}
													>
														<img
															src={item.img}
															alt=""
															className={classes.image}
														/>
													</Grid>
												);
											} else {
												return null;
											}
										})}
									</Grid>
								</SplideSlide>
								<SplideSlide>
									<Grid
										item
										container
										justifyContent={'space-around'}
									>
										{photoGallery.map((item, index) => {
											if (item.id >= 9 && item.id < 19) {
												return (
													<Grid
														item
														key={item.id}
														className={classes.gridImage}
														// onClick={() => handleOpen(index)}
													>
														<img
															src={item.img}
															alt=""
															className={classes.image}
														/>
													</Grid>
												);
											} else {
												return null;
											}
										})}
									</Grid>
								</SplideSlide>
							</>
						) : (
							<>
								{photoGallery.map((item, index) => {
									return (
										<SplideSlide
											key={item.id}
											style={{ width: '100%', height: '100%' }}
										>
											<img
												src={item.img}
												alt=""
												style={{ borderRadius: 15 }}
											/>
										</SplideSlide>
									);
								})}
							</>
						)}
					</Splide>
				</Grid>
			</Grid>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '4rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
			marginBottom: '8px !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.5rem !important',
		},
	},
	subtitle: {
		fontSize: '1.3rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
			marginBottom: '8px !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	text: {
		fontSize: '1rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.75rem !important',
		},
	},
	text2: {
		fontSize: '1.05rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	divider: {
		margin: 'unset !important',
		backgroundColor: 'grey',
		minHeight: 1,
	},
	btn: {
		fontSize: '1rem !important',
		width: '62%',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.75rem !important',
			minHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			width: '85%',
		},
	},
	gridImage: {
		borderRadius: 15,
		width: '19%',
		margin: '1rem 0 !important',
		backgroundColor: '#ffffff !important',
	},
	image: {
		borderRadius: 15,
	},
	splideMainContainer: {
		marginBottom: '1rem !important',
		'& .splide__arrow': {
			width: '4em',
			height: '4em',
			marginTop: '15%',
		},
		'& .splide__arrow svg': {
			height: '2em',
			width: '2em',
		},
		'& .splide__pagination': {
			display: 'flex !important',
			bottom: '-0.5em !important',
		},
		'& .splide__track--draggable': {
			margin: '.5em 0 !important',
		},
		[theme.breakpoints.down('sm')]: {
			'& .splide__pagination': {
				bottom: '-1.25em !important',
			},
		},
	},
	splideContainer: {
		'& .splide__arrow': {
			width: '4em',
			height: '4em',
			marginTop: '30%',
		},
		'& .splide__arrow svg': {
			height: '2em',
			width: '2em',
		},
	},
	containerTitle: {
		fontWeight: 'bold !important',
		fontSize: '2.5rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem !important',
		},
	},
	containerSubtitle: {
		fontWeight: 'bold !important',
		fontSize: '2rem !important',
		marginTop: '1rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
		},
	},
	splideTitle: {
		fontSize: '1.85rem !important',
		fontWeight: 'bold !important',
		marginTop: '0.5rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
			margin: '0.5rem 0 0.5rem !important',
		},
	},
	gridSplide: {
		[theme.breakpoints.down('sm')]: {
			padding: 'unset !important',
		},
	},
}));
