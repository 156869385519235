import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import DefaultButton from '../Buttons/DefaultButton';

export default function ProductCardWhite({ img, logo, description, titleButton, url }) {
	const classes = useStyles();

	return (
		<Paper
			className={`${classes.productPaper} rounded-card`}
			color="secondary"
			elevation={4}
			component={Link}
			to={url || null}
			target="_blank"
		>
			<Grid
				container
				justifyContent="center"
				className={classes.gridImage}
				style={{
					backgroundImage: `url(${img})`,
				}}
			>
				<img
                    className={classes.logoProduct} 
					src={logo}
					alt="Logo"
				/>
			</Grid>
			<Grid
				container
				alignContent="center"
				className={classes.gridDescriptionScriptCard}
			>
				<Typography
					align="left"
					color="primary"
					className={`${classes.descriptionTextProductCard} poppins`}
				>
					{description}
				</Typography>
			</Grid>
			<Grid
				container
				className={`${classes.gridButton} revalida`}
				justifyContent="flex-end"
			>
				<Grid
					item
					xs={7}
					md={6}
					style={{ right: 20 }}
					className="revalida"
				>
					<DefaultButton
						variant="outlined"
						color="primary"
						className={`${classes.btn} white-btn white-btn-secondary`}
					>
						{titleButton}
					</DefaultButton>
				</Grid>
			</Grid>
		</Paper>
	);
}

const useStyles = makeStyles((theme) => ({
	productPaper: {
		height: 399,
		width: 446,
		textDecoration: 'none',
		[theme.breakpoints.down('xl')]: {
			height: 349,
		},
		[theme.breakpoints.down('lg')]: {
			width: 435,
		},
		[theme.breakpoints.down('md')]: {
			height: 393,
		},
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
			width: 'unset',
			margin: '24px 0px !important',
		},
	},
	gridImage: {
		height: '60%',
		backgroundColor: '#000000',
		padding: '0px 25px',
		borderTopRightRadius: 20,
		borderTopLeftRadius: 20,
		cursor: 'pointer',
        [theme.breakpoints.up('lg')]: {
			height: '54%',
			// padding: 20,
		},
        [theme.breakpoints.down('md')]: {
			height: '54%',
			padding: 20,
		},
		[theme.breakpoints.down('sm')]: {
			height: '55%',
			padding: 20,
		},
	},
    logoProduct: {
        width: '80%',
        [theme.breakpoints.down('md')]: {
			width: '80%'
		},
		[theme.breakpoints.down('sm')]: {
			height: '115%',
			// padding: 20,
		},
	},
	gridDescriptionScriptCard: {
		backgroundColor: '#FFFFFF !important',
		padding: '1em 2em',
		borderRadius: 16,
		[theme.breakpoints.down('sm')]: {
			padding: '1em',
		},
	},
	descriptionTextProductCard: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	gridButton: {
		position: 'absolute !important',
		bottom: '-15px !important',
		[theme.breakpoints.down('sm')]: {
			position: 'relative !important',
			bottom: 0,
		},
	},
	btn: {
		backgroundColor: '#FFFFFF !important',
		fontSize: '1.15rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		'&:hover': {
			scale: 1.05,
		},
	},
}));
