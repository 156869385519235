import React, { useRef } from 'react';
import { Button, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import LearningVersion02Card from '../Cards/LearningVersion02Card';

import ArrowForward from '../../Assets/Svg/arrowForward.svg';
import ArrowBack from '../../Assets/Svg/arrowBack.svg';
import { makeStyles } from '@mui/styles';
import SimpleArrow from '../../Assets/Svg/simpleArrow.svg';

export default function LearningVersion02({ title, cardsInfo, cardsInfoTwo, cardsInfoThree, background }) {
	const classes = useStyles();
	const theme = useTheme();
	const carousel = useRef(null);
	const carousel2 = useRef(null);
	const carousel3 = useRef(null);
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesDown738 = useMediaQuery(theme.breakpoints.down(738));

	//handleClickUm
	const handleLeftClick = (e) => {
		e.preventDefault();

		carousel.current.scrollLeft -= carousel.current.offsetWidth;
	};

	const handleRightClick = (e) => {
		e.preventDefault();

		carousel.current.scrollLeft += 700;
	};

	//handleClickDois
	const handleLeftClickDois = (e) => {
		e.preventDefault();

		carousel2.current.scrollLeft -= carousel.current.offsetWidth;
	};

	const handleRightClickDois = (e) => {
		e.preventDefault();

		carousel2.current.scrollLeft += 700;
	};

	//handleClickTres
	const handleLeftClickTres = (e) => {
		e.preventDefault();

		carousel3.current.scrollLeft -= carousel.current.offsetWidth;
	};

	const handleRightClickTres = (e) => {
		e.preventDefault();

		carousel3.current.scrollLeft += 700;
	};

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
			className={`${background}`}
		>
			<Grid
				container
				alignContent="center"
				justifyContent="center"
				className={`${classes.backgroundWaves} ${classes.carouselSection}`}
			>
				<Container
					maxWidth={false}
					disableGutters={true}
				>
					<Container
						fixed
						maxWidth="xl"
						disableGutters={true}
					>
						<Grid
							item
							xs={12}
							container
							className={classes.gridTitle}
						>
							<Typography
								className={`${classes.subtitle1} roboto-condensed`}
								color="#000000"
							>
								{title}
							</Typography>
						</Grid>
					</Container>
					<Grid
						item
						className={classes.carouselGrid}
						xs={12}
					>
						{cardsInfo?.length > 3 ? (
							<Button
								onClick={handleRightClick}
								className={`${classes.arrowBtn} ${classes.arrowBtnRight}`}
							>
								<img
									src={ArrowForward}
									alt=""
									className={classes.arrowIcon}
								/>
							</Button>
						) : null}

						{matchesDown738 ? (
							<Grid
								item
								container
								spacing={3}
								className={classes.gridCarouselContainer}
								style={matchesMobile ? { height: 420 } : null}
								ref={carousel}
							>
								{cardsInfo.map((item, index) => {
									return (
										<Grid
											item
											xs={12}
											md={4}
											key={index}
											className={cardsInfo.length - index === 1 ? classes.gridFeedbackCard : null}
										>
											<LearningVersion02Card
												key={index}
												{...item}
											/>
										</Grid>
									);
								})}
							</Grid>
						) : (
							<Grid
								item
								className={classes.gridLearningPathStatic}
							>
								{cardsInfo?.length > 3 ? (
									<Button
										onClick={handleLeftClick}
										className={`${classes.arrowBtn} ${classes.leftArrowBtn}`}
									>
										<img
											src={ArrowBack}
											alt=""
											className={classes.arrowIcon}
										/>
									</Button>
								) : null}

								<LearningVersion02Card
									key={0}
									title={cardsInfo[0].title}
									description={cardsInfo[0].description}
									bgColor={cardsInfo[0].bgColor}
									titleColor={cardsInfo[0].titleColor}
									descriptionColor={cardsInfo[0].descriptionColor}
									url={cardsInfo[0].url}
								/>
								{/* <Grid
									container
									justifyContent="center"
									alignContent="center"
									style={{ transform: 'rotate(90deg)' }}
								>
									<img src={SimpleArrow} />
								</Grid> */}
							</Grid>
						)}
						{matchesDown738 ? null : (
							<Grid
								item
								container
								spacing={3}
								className={classes.gridCarouselContainer}
								ref={carousel}
							>
								{cardsInfo.map((item, index) => {
									if (index > 0) {
										return (
											<Grid
												item
												xs={12}
												key={index}
												className={
													cardsInfo.length - index === 1 ? classes.gridFeedbackCard : null
												}
											>
												<LearningVersion02Card
													key={index}
													{...item}
												/>
											</Grid>
										);
									} else {
										return null;
									}
								})}
							</Grid>
						)}
					</Grid>
					<Grid
						container
						justifyContent="center"
						alignContent="center"
						style={{ transform: 'rotate(90deg)', marginBottom: '25px' }}
					>
						<img
							src={SimpleArrow}
							alt=""
							style={matchesMobile ? { width: '10%' } : null}
						/>
					</Grid>
					<Grid
						item
						className={classes.carouselGrid}
						xs={12}
					>
						{cardsInfoTwo?.length > 3 ? (
							<Button
								onClick={handleRightClickDois}
								className={`${classes.arrowBtn} ${classes.arrowBtnRight}`}
							>
								<img
									src={ArrowForward}
									alt=""
									className={classes.arrowIcon}
								/>
							</Button>
						) : null}

						{matchesDown738 ? (
							<Grid
								item
								container
								spacing={3}
								className={classes.gridCarouselContainer}
								style={matchesMobile ? { height: 420 } : null}
								ref={carousel2}
							>
								{cardsInfoTwo.map((item, index) => {
									return (
										<Grid
											item
											xs={12}
											md={4}
											key={index}
											className={
												cardsInfoTwo.length - index === 1 ? classes.gridFeedbackCard : null
											}
										>
											<LearningVersion02Card
												key={index}
												{...item}
											/>
										</Grid>
									);
								})}
							</Grid>
						) : (
							<Grid
								item
								className={classes.gridLearningPathStatic}
							>
								{cardsInfoTwo?.length > 3 ? (
									<Button
										onClick={handleLeftClickDois}
										className={`${classes.arrowBtn} ${classes.leftArrowBtn}`}
									>
										<img
											src={ArrowBack}
											alt=""
											className={classes.arrowIcon}
										/>
									</Button>
								) : null}

								<LearningVersion02Card
									key={0}
									title={cardsInfoTwo[0].title}
									description={cardsInfoTwo[0].description}
									bgColor={cardsInfoTwo[0].bgColor}
									titleColor={cardsInfoTwo[0].titleColor}
									descriptionColor={cardsInfoTwo[0].descriptionColor}
									url={cardsInfoTwo[0].url}
								/>
							</Grid>
						)}
						{matchesDown738 ? null : (
							<Grid
								item
								container
								spacing={3}
								className={classes.gridCarouselContainer}
								ref={carousel2}
							>
								{cardsInfoTwo.map((item, index) => {
									if (index > 0) {
										return (
											<Grid
												item
												xs={12}
												key={index}
												className={
													cardsInfoTwo.length - index === 1 ? classes.gridFeedbackCard : null
												}
											>
												<LearningVersion02Card
													key={index}
													{...item}
												/>
											</Grid>
										);
									} else {
										return null;
									}
								})}
							</Grid>
						)}
					</Grid>
					{cardsInfoThree ? (
						<Grid
							container
							justifyContent="center"
							alignContent="center"
							style={{ transform: 'rotate(90deg)', marginBottom: '25px' }}
						>
							<img
								src={SimpleArrow}
								alt=""
								style={matchesMobile ? { width: '10%' } : null}
							/>
						</Grid>
					) : null}
					<Grid
						item
						className={classes.carouselGrid}
						xs={12}
					>
						{cardsInfoThree?.length > 3 ? (
							<Button
								onClick={handleRightClickTres}
								className={`${classes.arrowBtn} ${classes.arrowBtnRight}`}
							>
								<img
									src={ArrowForward}
									alt=""
									className={classes.arrowIcon}
								/>
							</Button>
						) : null}

						{matchesDown738 && cardsInfoThree ? (
							<Grid
								item
								container
								spacing={3}
								className={classes.gridCarouselContainer}
								style={matchesMobile ? { height: 420 } : null}
								ref={carousel3}
							>
								{cardsInfoThree
									? cardsInfoThree?.map((item, index) => {
											return (
												<Grid
													item
													xs={12}
													md={4}
													key={index}
													className={
														cardsInfoThree?.length - index === 1
															? classes.gridFeedbackCard
															: null
													}
												>
													<LearningVersion02Card
														key={index}
														{...item}
													/>
												</Grid>
											);
									  })
									: null}
							</Grid>
						) : (
							<Grid
								item
								className={classes.gridLearningPathStatic}
							>
								{cardsInfoThree?.length > 3 ? (
									<Button
										onClick={handleLeftClickTres}
										className={`${classes.arrowBtn} ${classes.leftArrowBtn}`}
									>
										<img
											src={ArrowBack}
											alt=""
											className={classes.arrowIcon}
										/>
									</Button>
								) : null}

								{cardsInfoThree && (
									<LearningVersion02Card
										key={0}
										title={cardsInfoThree[0].title}
										description={cardsInfoThree[0].description}
										bgColor={cardsInfoThree[0].bgColor}
										titleColor={cardsInfoThree[0].titleColor}
										descriptionColor={cardsInfoThree[0].descriptionColor}
										url={cardsInfoThree[0].url}
									/>
								)}
							</Grid>
						)}
						{matchesDown738 && !cardsInfoThree ? null : (
							<Grid
								item
								container
								spacing={3}
								className={classes.gridCarouselContainer}
								ref={carousel3}
							>
								{cardsInfoThree
									? cardsInfoThree?.map((item, index) => {
											if (index > 0) {
												return (
													<Grid
														item
														xs={12}
														key={index}
														className={
															cardsInfoThree?.length - index === 1
																? classes.gridFeedbackCard
																: null
														}
													>
														<LearningVersion02Card
															key={index}
															{...item}
														/>
													</Grid>
												);
											} else {
												return null;
											}
									  })
									: null}
							</Grid>
						)}
					</Grid>
				</Container>
			</Grid>
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	backgroundWaves: {
		backgroundImage: `url(${process.env.PUBLIC_URL + '/Assets/Img/bgWave2.png'})`,
		width: '100vw !important',
	},
	carouselSection: {
		padding: '2.5%',
		paddingLeft: '10%',
		[theme.breakpoints.down(1656)]: {
			paddingLeft: '8%',
		},
		[theme.breakpoints.down(1407)]: {
			paddingLeft: '6%',
		},
		[theme.breakpoints.down(1311)]: {
			paddingLeft: '2%',
		},
		[theme.breakpoints.down(1069)]: {
			paddingLeft: '6% !important',
		},
		[theme.breakpoints.down(933)]: {
			paddingLeft: '0% !important',
		},
		[theme.breakpoints.down(821)]: {
			paddingLeft: '10% !important',
		},
		[theme.breakpoints.down('sm')]: {
			height: 'unset !important',
			paddingLeft: 'unset !important',
			padding: '20px 2.5%',
		},
	},
	gridTitle: {
		padding: '0 0 2em 1em',
		[theme.breakpoints.down('sm')]: {
			margin: '20px 0px 20px 20px',
		},
	},
	subtitle1: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('md')]: {
			lineHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
			lineHeight: 'unset !important',
		},
	},
	carouselGrid: {
		display: 'flex',
		left: 64,
		[theme.breakpoints.down(1311)]: {
			left: 20,
		},
		[theme.breakpoints.down(933)]: {
			left: 60,
		},
		[theme.breakpoints.down(821)]: {
			left: 10,
		},
	},
	arrowBtn: {
		position: 'absolute !important',
		zIndex: 1000,
		borderRadius: '50% !important',
		width: 80,
		height: 80,
		backgroundColor: '#2D2D2D !important',
		opacity: 0.75,
		'&:hover': { opacity: 1, transition: '0.2s' },
		[theme.breakpoints.down('sm')]: {
			display: 'none !important',
		},
	},
	arrowIcon: {
		width: '47%',
	},
	gridCarouselContainer: {
		display: 'flex !important',
		overflowX: 'hidden',
		scrollBehavior: 'smooth',
		flexWrap: 'nowrap !important',
		minWidth: '100vw',
		left: '22%',
		position: 'absolute',
		[theme.breakpoints.down(1751)]: {
			left: '24%',
		},
		[theme.breakpoints.down(1601)]: {
			left: '27%',
		},
		[theme.breakpoints.down('xl')]: {
			left: '29%',
		},
		[theme.breakpoints.down(1409)]: {
			left: '32%',
		},
		[theme.breakpoints.down('lg')]: {
			left: '36%',
		},
		[theme.breakpoints.down(1075)]: {
			left: '41%',
		},
		[theme.breakpoints.down(1005)]: {
			left: '44%',
		},
		[theme.breakpoints.down(929)]: {
			left: '47%',
		},
		[theme.breakpoints.down(821)]: {
			left: '52%',
		},
		[theme.breakpoints.down(797)]: {
			left: '50%',
		},
		[theme.breakpoints.down(738)]: {
			position: 'relative',
			left: '20px !important',
		},
		[theme.breakpoints.down('sm')]: {
			height: 400,
			overflowX: 'auto',
			overflowY: 'hidden',
			paddingLeft: '7%',
			left: 'unset !important',
		},
	},
	gridFeedbackCard: {
		paddingRight: 1150,
		[theme.breakpoints.down('lg')]: {
			paddingRight: 850,
		},
		[theme.breakpoints.down('sm')]: {
			paddingRight: 20,
		},
	},
	gridLearningPathStatic: {
		position: 'relative',
		zIndex: 2,
		[theme.breakpoints.down('xl')]: {
			left: 20,
		},
		[theme.breakpoints.down(1311)]: {
			left: 40,
		},
		[theme.breakpoints.down(1075)]: {
			left: 20,
		},
		[theme.breakpoints.down(802)]: {
			left: 0,
		},
		[theme.breakpoints.down(772)]: {
			left: '-10px',
		},
	},
	leftArrowBtn: {
		left: -90,
		top: 150,
		[theme.breakpoints.down(1311)]: {
			left: -62,
		},
	},
	arrowBtnRight: {
		right: 80,
		top: 150,
		[theme.breakpoints.down('xl')]: {
			right: 60,
		},
		[theme.breakpoints.down(1311)]: {
			right: 40,
		},
		[theme.breakpoints.down('lg')]: {
			right: 0,
		},
	},
}));
