import React, { useEffect, useState } from 'react';
import { Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import { Header } from '../../../../Components/Header';

import Form from '../../../../Components/Utils/Forms/PreRegistration';

export default function EditalLinkRevalida20241() {
	const classes = useStyles();
	const theme = useTheme();
	const jsonMock_page_data = useState([
		// {
		// 	imagem: require('../../../../Assets/SprintFinal/logo-edital-revalida-24-1.svg').default,
		// 	agrupamento: 'EDITAL REVALIDA 24.1',
		// 	produto_color: 'revalida',
		// 	titulo_formulario: ['Preencha o formulário', <br />, 'para acessar o edital!'],
		// 	titulo_botao_submit: 'Acessar edital',
		// 	url_de_redirecionamento: 'https://www.in.gov.br/web/dou/-/edital-n-2-de-16-de-janeiro-de-2024-538047724',
		// 	mensagem_popup:
		// 		'Sua inscrição está confirmada! Em breve você também receberá uma confirmação pelo e-mail cadastrado.',
		// },
        {
			imagem: require('../../../../Assets/SprintFinal/logo-edital-revalida-24-2.svg').default,
			agrupamento: 'EDITAL REVALIDA 24.2',
			produto_color: 'revalida',
			titulo_formulario: ['Preencha o formulário', <br />, 'para acessar o edital!'],
			titulo_botao_submit: 'Acessar edital',
			url_de_redirecionamento: 'https://www.in.gov.br/en/web/dou/-/edital-n-102-de-21-de-junho-de-2024-567232083',
			mensagem_popup:
				'Sua inscrição está confirmada! Em breve você também receberá uma confirmação pelo e-mail cadastrado.',
		},
	]);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					alignContent="center"
					justifyContent={'center'}
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
				>
					<Grid
						item
						xs={12}
						md={5}
						container
						justifyContent="center"
						alignContent="flex-end"
						style={{ paddingBottom: 50 }}
						rowGap={4}
					>
						<img
							src={jsonMock_page_data[0]?.[0]?.imagem}
							alt=""
							style={{ width: matchesMobile ? '90%' : null }}
						/>
						<Form
							group={jsonMock_page_data[0]?.[0]?.agrupamento}
							title={jsonMock_page_data[0]?.[0]?.titulo_formulario}
							titleButton={jsonMock_page_data[0]?.[0]?.titulo_botao_submit}
							type={jsonMock_page_data[0]?.[0]?.produto_color}
							urlToRedirect={jsonMock_page_data[0]?.[0]?.url_de_redirecionamento}
							confirmationMessage={jsonMock_page_data[0]?.[0]?.mensagem_popup}
							noCellphone
						/>
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	mainTitle: {
		fontSize: '3.95rem !important',
		lineHeight: 'unset !important',
		fontWeight: 'bold !important',
		alignSelf: 'center',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.7rem !important',
		},
	},
	text: {
		fontSize: '1rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
			marginBottom: '1rem !important',
		},
	},
	textImage: {
		[theme.breakpoints.down('lg')]: {
			width: '60%',
		},
	},
}));
