import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
	Container,
	Grid,
	Typography,
	useMediaQuery,
	useTheme,
	TextField,
	FormControl,
	InputLabel,
	Select,
	FormGroup,
	FormControlLabel,
	Checkbox,
} from '@mui/material';
import IntlTelInput from 'react-intl-tel-input';
import { trackPromise } from 'react-promise-tracker';
import Api from '../../Services/Api';
import Swal from 'sweetalert2';
import { makeStyles } from '@mui/styles';

// Components
import { Header } from '../../Components/Header';
import Footer from '../../Components/Footer';
import DefaultButton from '../../Components/Buttons/DefaultButton';
import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

const contests = [
	'AMP',
	'AMRIGS',
	'EINSTEIN',
	'ENARE',
	'FAMEMA',
	'FAMERP',
	'SÍRIO',

	'PSU-GO',
	'PSU-MG',
	'SANTA CASA-SP',
	'SES-DF',
	'SES-GO',
	'SES-PE',
	'SUS-BA',
	'SUS-SP',
	'UFES',
	'UFRJ',
	'UNESP BOTUCATU',
	'UNICAMP',
	'UNIFESP',
	'USP',
	'USP RIBEIRÃO',
	'OUTROS',
];

const Form = ({ type, matchesMobile, urlToRedirect }) => {
	const classes = useStyles();
	const [searchParams] = useSearchParams();
	const inputRef = useRef();

	const [showOutras, setShowOutras] = useState(false);
	const [outrasLength, setOutrasLength] = useState('');

	const [preInscricao, setPreInscricao] = useState({
		agrupamento: 'Live das Lives do sonhos 2024',
		nome: '',
		email: '',
		telefone: '',
		provas: '',
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
	});

	useEffect(() => {
		setPreInscricao({
			...preInscricao,
			utm_source: searchParams.get('utm_source'),
			utm_medium: searchParams.get('utm_medium'),
			utm_campaign: searchParams.get('utm_campaign'),
		});
	}, [preInscricao, searchParams]);

	let sendPreInscricao = function (event) {
		event.preventDefault();
		const nomeCompleto = preInscricao.nome.trim();
		const nomeSobrenome = nomeCompleto.split(' ');

		if (!preInscricao.nome || nomeSobrenome.length < 2) {
			alert('Ops... Por favor informe seu nome e sobrenome');
			return;
		}
		if (!preInscricao.email || !preInscricao.email.includes('@') || !preInscricao.email.includes('.')) {
			alert('Ops... Por favor preencha seu email');
			return;
		}
		if (!preInscricao.telefone) {
			alert('Ops... Por favor preencha seu telefone');
			return;
		}
		if (showOutras && outrasLength.length <= 0) {
			alert('Ops... Por favor preencha o campo "outras provas"');
			return;
		}

		trackPromise(
			Api.post('PreInscricao', preInscricao).then((res) => {
				if (res) {
					Swal.fire({
						title: 'Massa! ',
						text: `${'O cadastro está confirmado, avisaremos das novidades em breve.'}`,
						icon: 'success',
						confirmButtonText: 'Fechar',
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then((result) => {
						if (result.isConfirmed) {
							setTimeout(() => {
								document.location.reload();
							}, 1000);
						}
					});
				}
			})
		);
	};

	const handlePhone = (valid, phoneNumber) => {
		if (valid) {
			setPreInscricao({
				...preInscricao,
				telefone: phoneNumber,
			});
		} else if (preInscricao.telefone.length) {
			setPreInscricao({
				...preInscricao,
				telefone: '',
			});
		}
	};

	const handleChangeGroup = (event) => {
		const contest = event.target.value;
		let selectedProvas = preInscricao.provas.split(',').filter(Boolean);

		if (contest === 'OUTROS') {
			setShowOutras(true);
		}

		if (event)
			if (selectedProvas.includes(contest)) {
				// Se a opção já estiver selecionada, remove
				selectedProvas = selectedProvas.filter((item) => item !== contest);
			} else {
				// Caso contrário, adiciona
				selectedProvas.push(contest);
			}

		// Converte o array de volta para uma string CSV
		const updatedProvasCSV = selectedProvas.join(',');

		setPreInscricao({
			...preInscricao,
			provas: updatedProvasCSV, // Atualiza o campo de provas como uma string CSV
		});
	};

	return (
		<Grid
			container
			alignContent="center"
			justifyContent="center"
			className={classes.section}
		>
			<Grid
				item
				xs={12}
				container
				className={`${classes.container} newsletter-background-residencia`}
			>
				<Grid
					item
					xs={12}
					md={6}
					container
					alignContent={'flex-start'}
					rowGap={1.5}
					className={classes.gridNewsletterText}
				>
					<Grid
						item
						xs={12}
						container
						style={{ height: 'fit-content' }}
					>
						<Typography
							// className={`${classes.title} newsletter-title roboto-condensed`}
							className={`${classes.noticeContainerTitle} roboto-condensed`}
							color="secondary"
						>
							Quais as Lives das Lives dos teus sonhos?
						</Typography>
					</Grid>
					<Grid
						item
						xs={11}
					>
						<Typography
							align="left"
							color="secondary"
							className={`${classes.noticeContainerText} poppins`}
						>
							Cadastre-se para saber de tudo em primeira mão e aumentar as chances de garantir a
							inscrição.
						</Typography>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
					container
					className={classes.containerTextField}
				>
					<form
						style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}
						className="form-control"
						onSubmit={sendPreInscricao}
					>
						<Grid
							item
							xs={12}
						>
							<TextField
								fullWidth
								placeholder="Seu nome completo"
								type="string"
								name="nome"
								className={classes.textField}
								required={true}
								InputProps={{
									className: classes.form,
								}}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										nome: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
						>
							<TextField
								fullWidth
								placeholder="Seu melhor e-mail"
								type="email"
								name="email"
								className={classes.textField}
								required={true}
								InputProps={{
									className: classes.form,
								}}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										email: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							style={{ padding: matchesMobile ? '0.25rem 0.5rem' : '0.25rem 0' }}
						>
							<IntlTelInput // Componente Input de telefones internacionais
								name="mobile"
								type="mobile"
								preferredCountries={['BR', 'PY', 'AR', 'CL', 'BO', 'UY', 'CU', 'PT']}
								style={{ width: '100%', border: 'unset' }}
								inputClassName={`${classes.phoneNumberForm} ${classes.form} ${classes.formHeight}`}
								nationalMode={true}
								onPhoneNumberChange={(valid, value, selectedCountryData, fullNumber) =>
									handlePhone(valid, fullNumber)
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							style={{ padding: matchesMobile ? '0.25rem 0.5rem' : null }}
						>
							<FormControl style={{ width: '100%' }}>
								<InputLabel id="test-select-label">
									Escolha as provas que deseja ter revisão de véspera:
								</InputLabel>
								<input
									type="hidden"
									name="provas"
									ref={inputRef}
								/>

								<Select
									value={preInscricao?.provas}
									labelId="test-select-label"
									renderValue={(selected) => selected.toLocaleLowerCase()}
									className={`${classes.form} ${classes.selectMenu} poppins`}
								>
									<FormGroup className={classes.formGroupStyle}>
										{contests.map((item, index) => {
											return (
												<FormControlLabel
													key={index}
													control={
														<Checkbox
															value={item}
															checked={
																preInscricao.provas.includes(item) &&
																preInscricao.provas.split(',').includes(item)
															}
															className={classes.checkbox}
															onChange={(e) => handleChangeGroup(e)}
														/>
													}
													label={
														<span className={`${classes.spanStyle} poppins`}>{item}</span>
													}
													style={{
														color: '#000000',
														margin: 0,
														borderBottom:
															index === contests.length - 1 ? null : '1px solid #AEAEAE',
													}}
												/>
											);
										})}
									</FormGroup>
								</Select>
								{showOutras && (
									<Grid
										item
										xs={12}
									>
										<TextField
											fullWidth
											type="text"
											name="outros"
											placeholder="Para qual prova?"
											required={true}
											InputProps={{
												className: classes.form,
											}}
											InputLabelProps={{
												shrink: true,
											}}
											onChange={(e) =>
												setOutrasLength(e) &&
												setPreInscricao({
													...preInscricao,
													provas: e.target.value,
												})
											}
										/>
									</Grid>
								)}
							</FormControl>
						</Grid>
						<Grid
							item
							xs={12}
							container
							justifyContent={matchesMobile ? 'center' : 'flex-start'}
							className={classes.gridBtn}
						>
							<DefaultButton
								type="submit"
								variant="contained"
								color="primary"
								className={`${classes.btn} poppins`}
								onClick={(e) => sendPreInscricao(e)}
							>
								{/* Confirmar cadastro */}
								Confirmar escolha
							</DefaultButton>
						</Grid>
					</form>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default function LiveDasLivesSubscription() {
	const theme = useTheme();
	const classes = useStyles();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			<Helmet>
				<title>Live das Lives 2024</title>
				<meta
					name="description"
					content={''}
				/>
			</Helmet>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className="residencia"
			>
				{matchesMobile || matchesTablet ? null : <Header />}
			</Container>
			<Grid
				container
				alignContent={'center'}
				style={matchesMobile ? { marginTop: 100 } : null}
			>
				<img
					src={require('../../Assets/LDL/bg-ldl-pesquisa.png')}
					alt=""
				/>
				<Grid
					item
					xs={12}
					container
					alignContent={'center'}
					justifyContent={'center'}
					className={`${classes.gridLogoContainer} residencia`}
					rowGap={1}
				>
					<img
						src={require('../../Assets/LDL/liveDasLivesResidencia.svg').default}
						alt=""
						className={classes.ldlLogo}
						style={matchesMobile ? { margin: '1rem 0', width: '72%' } : { margin: '3rem 0' }}
					/>
					<Grid
						item
						xs={12}
						container
						alignContent={'center'}
						justifyContent={'center'}
						style={{
							padding: matchesMobile ? '1rem' : '1rem 0',
							border: '1px solid #FF005D',
							borderLeft: 'unset',
							borderRight: 'unset',
							margin: '1rem 0',
							background:
								'linear-gradient(90deg, rgba(19,19,19,0) 0%, rgba(15,15,15,1) 20%, rgba(15,15,15,1) 80%, rgba(19,19,19,0) 100%)',
						}}
					>
						<Typography
							align="center"
							color="secondary"
							className="poppins"
							style={{ fontSize: matchesMobile ? '1rem' : '2rem', fontWeight: 'bold' }}
						>
							REVISÃO DE VÉSPERA ESPECÍFICA PARA CADA PROVA
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					container
					className="section"
					style={{ margin: matchesMobile ? '150px auto 0px' : '0px auto' }}
				>
					<Form
						type="residencia"
						matchesMobile={matchesMobile}
					/>
				</Grid>
			</Container>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	videoBackground: {
		objectFit: 'cover',
		height: 676,
		width: '100%',
		[theme.breakpoints.down('xl')]: {
			height: 480,
		},
		[theme.breakpoints.down('md')]: {
			height: 332,
		},
		[theme.breakpoints.down('sm')]: {
			height: 283,
		},
	},
	ldlLogo: {
		alignSelf: 'center',
		[theme.breakpoints.down(1509)]: {
			width: '36%',
		},
		[theme.breakpoints.down('sm')]: {
			alignSelf: 'center',
			width: '62%',
		},
	},
	gridLogoContainer: {
		position: 'absolute',
		height: '100%',
		[theme.breakpoints.down('sm')]: {
			top: 90,
		},
	},
	rootCard: {
		display: 'flex !important',
	},
	dayDateText: {
		fontSize: '3.85rem !important',
		fontWeight: 'bold !important',
		lineHeight: '0.9 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.85rem !important',
		},
	},
	monthDateText: {
		fontSize: '2.25rem !important',
		fontWeight: 'bold !important',
		lineHeight: '0.9 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	gridBackground: {
		backgroundSize: 'cover',
		padding: '1.5rem 1rem',
		height: '100%',
		borderTopRightRadius: 20,
		borderBottomRightRadius: 20,
		[theme.breakpoints.down('sm')]: {
			padding: '1.5rem 0.5rem',
		},
	},
	gridInformation: {
		backgroundColor: '#000000bf',
		borderRadius: '10px',
		padding: '0.5rem 1rem',
		marginTop: '1rem !important',
		[theme.breakpoints.down('sm')]: {
			padding: '0.5rem',
		},
	},
	subscribeBtn: {
		width: '30% !important',
		padding: 'unset !important',
		transition: 'ease 0.2s !important',
		alignSelf: 'center',
		border: '1px transparent !important',
		fontWeight: 'bold !important',
		fontSize: '0.95rem !important',
		'&:hover': {
			width: '35% !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
			width: '40% !important',
		},
	},
	modeText: {
		alignSelf: 'center',
		fontSize: '1.25rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.75rem !important',
		},
	},
	divider: {
		alignSelf: 'center',
		backgroundColor: '#ffffff',
		minHeight: 1,
		width: '43%',
		[theme.breakpoints.down('sm')]: {
			margin: '1rem 0 !important',
			width: '35%',
		},
	},
	borderGrid: {
		width: '2.5%',
		borderTopLeftRadius: 15,
		borderBottomLeftRadius: 15,
		[theme.breakpoints.down('sm')]: {
			width: '5%',
		},
	},
	containerWaitList: {
		// width: '100% !important',
		backgroundColor: '#d6cece',
		zIndex: 1,
		borderRadius: 15,
		padding: '0.5rem 1.25rem',
		boxShadow: '0px 0px 30px 0px #000000',
		border: '2px solid #000000',
		// position: 'absolute',
		// bottom: 0,
		[theme.breakpoints.down('sm')]: {
			padding: '1rem 1.25rem',
		},
	},
	textWaitList: {
		fontSize: '1rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.95rem !important',
		},
	},
	form: {
		borderRadius: '8px !important',
		backgroundColor: '#E1E1E1 !important',
		margin: '5px 0 !important',
		'& .MuiInputBase-input': {
			padding: '0.5rem !important',
		},
	},
	formHeight: {
		maxHeight: '2.5em',
	},
	btnWaitList: {
		fontSize: '0.9rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		backgroundColor: '#00AB55 !important',
		border: '1px solid #707070 !important',
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				// minWidth: '72% !important',
				// fontSize: '1.025rem !important',
				// borderWidth: '3px !important',
				backgroundColor: '#00543b !important',
				transition: '0.1s',
			},
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	cancelIcon: {
		color: '#000000',
		right: '-15px',
		top: '-5px',
		'&:hover': {
			cursor: 'pointer',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'absolute',
			top: '-15px',
			right: '-18px',
		},
	},
	phoneNumberForm: {
		height: '2.5em !important',
		borderRadius: 8,
		width: '100%',
		border: 'unset',
	},
	section: {
		maxWidth: '1410px',
		marginRight: 'auto',
		marginLeft: 'auto',
		marginBottom: '4%',
		[theme.breakpoints.down('sm')]: {
			padding: '2vh 0 !important',
		},
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.75rem !important',
		lineHeight: '1 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.75rem !important',
			padding: '10px 0',
		},
	},
	text: {
		fontSize: '1.315rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.95rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	container: {
		padding: '1.75em',
		borderRadius: 30,
		background:
			window.location.pathname === '/live-das-lives/revalida'
				? 'linear-gradient(90deg, #58E1AA 10%, #4BD1C7 50%, #3FC1E3 90%)'
				: 'linear-gradient(90deg, rgba(255,72,0,0.8) 10%, rgba(255,0,66,0.8) 90%)',
		[theme.breakpoints.down('xl')]: {
			padding: '1.5em',
		},
		[theme.breakpoints.down('lg')]: {
			padding: '1em',
		},
		[theme.breakpoints.down('md')]: {
			padding: '1.8em !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '1.25em 0.5em !important',
		},
	},
	textField: {
		[theme.breakpoints.down('sm')]: {
			padding: '0 0.5rem !important',
		},
	},
	btn: {
		fontSize: '1rem !important',
		width: '50% !important',
		backgroundColor: '#0D0D0D !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
			width: '197px !important',
		},
	},
	gridNewsletterText: {
		paddingLeft: '2rem',
		[theme.breakpoints.down('md')]: {
			padding: '0 1em 1em',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0 0.5em 0',
		},
	},
	containerTextField: {
		[theme.breakpoints.down('sm')]: {
			marginTop: '10px !important',
		},
	},
	gridBtn: {
		margin: '5px !important',
		[theme.breakpoints.down('sm')]: {
			margin: '10px 5px 5px !important',
		},
	},
	noticeContainerTitle: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.7rem !important',
		},
	},
	noticeContainerText: {
		fontSize: '1.15rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	selectMenu: {
		width: '100%',
		borderRadius: '8px !important',
		backgroundColor: '#E1E1E1',
		maxHeight: 53,
		[theme.breakpoints.down('sm')]: {
			height: 40,
		},
	},
	checkbox: {
		padding: '5px !important',
	},
	formGroupStyle: {
		padding: '0 0.5rem',
		'& .MuiPaper-root MuiPaper-elevation MuiPaper-rounded': {
			height: '100px',
		},
	},
}));
