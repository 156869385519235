import { Box, Modal, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import DefaultButton from '../../../../../Components/Buttons/DefaultButton';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

import { makeStyles } from '@mui/styles';

export const UploadModal = ({ uploadPopup, idTurma, setUploadPopup }) => {
	const [file, setFile] = useState(null);
	const inputFileRef = useRef(null);

	const classes = useStyles();

	const handleButtonClick = () => {
		inputFileRef.current.click(); // dispara o clique no input
	};

	const handleFileChange = (event) => {
		const selectedFile = event.target.files[0];
		setFile(selectedFile); // Atualiza o estado com o arquivo selecionado
	};

	// const handleFileUpload = () => {
	// 		// Redireciona para o link com o idTurma
	// 		window.location.href = `https://hardworkmedicina.com.br/inscricao/${idTurma}`;
	// };

	const handleClose = () => {
		setUploadPopup(false);
	};

	return (
		<Modal
			open={uploadPopup}
			onClose={() => handleClose()}
		>
			<Box className={classes.box}>
				<Typography
					id="modal-modal-title"
					fontWeight={700}
					className={`${classes.title} poppins`}
					color={'#FFFFFF'}
				>
					Atenção!
				</Typography>
				<Typography
					id="modal-modal-title"
					fontWeight={400}
					className={`${classes.subtitle} poppins`}
					color={'#FFFFFF'}
					whiteSpace={'pre-wrap'}
				>{`A efetivação da inscrição neste lote especial dependerá da apresentação de documento que comprove a formatura em medicina em data posterior a maio de 2025.\nO Documento poderá ser enviado por e-mail ou apresentado no evento.`}</Typography>
				<div>
					<DefaultButton
						variant="outlined"
						color={'secondary'}
						className={`${classes.subscribeBtn} poppins`}
						onClick={() => window.open(`https://hardworkmedicina.com.br/inscricao/${idTurma}`, '_blank')}
						style={{ backgroundColor: '#43AB5C', border: 0 }}
					>
						{'Continuar inscrição'}
					</DefaultButton>
					{/*<input*/}
					{/*	type="file"*/}
					{/*	ref={inputFileRef}*/}
					{/*	onChange={handleFileChange}*/}
					{/*	style={{ display: 'none' }}*/}
					{/*/>*/}
					{/*{file && <Typography style={{ color: '#FFFFFF', marginTop: '1rem' }}>{file.name}</Typography>}*/}
				</div>
				<CancelRoundedIcon
					onClick={() => handleClose()}
					className={classes.cancelIcon}
				/>
			</Box>
		</Modal>
	);
};

const useStyles = makeStyles((theme) => ({
	subscribeBtn: {
		width: 'fit-content',
		padding: '0.5rem 5rem !important',
		alignSelf: 'center',
		fontSize: '1.35rem !important',
		fontWeight: 'bold !important',
		marginTop: '4rem !important',
		transition: 'ease 0.2s',
		'&:hover': {
			scale: 1.025,
			color: '#ffffff !important',
			backgroundColor: '#43ab5cd9 !important',
			'& $logoBtn': {
				filter: 'invert(0)',
			},
		},
		[theme.breakpoints.down('xl')]: {
			padding: '0.25rem 2.5rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '0.85rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
			padding: '0.25rem 1.25rem !important',
			border: '2px solid transparent !important',
			marginTop: '2rem !important',
		},
	},
	title: {
		fontSize: '3rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
		},
	},
	subtitle: {
		fontSize: '1.30rem !important',
		padding: ' 20px 0 0 0 !important ',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	box: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '35%',
		backgroundColor: '#373737',
		boxShadow: 24,
		padding: '2rem 4rem',
		borderRadius: 24,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		[theme.breakpoints.down('xl')]: {
			width: '50%',
		},
		[theme.breakpoints.down('sm')]: {
			width: '91%',
			padding: '2rem',
		},
	},
	cancelIcon: {
		position: 'absolute',
		// color: '#000000',
		right: '10px',
		top: '10px',
		'&:hover': {
			cursor: 'pointer',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'absolute',
			top: '8px',
			right: '8px',
		},
	},
}));
