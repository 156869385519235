import { useEffect } from 'react';
import { redirectAPP } from './redirect-app-stores';
import { useMediaQuery } from '@mui/system';
import { useTheme } from '@mui/material';

const ButtonStore = ({ matchesMobile, urlRedirect, title }) => {
	return (
		<a
			href={urlRedirect}
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				rowGap: 8,
				lineHeight: 0,
				border: '1px solid #fcfcfc',
				padding: matchesMobile ? '16px 4px' : 16,
				borderRadius: 8,
				textDecoration: 'none',
			}}
		>
			<p
				style={{
					color: '#fff',
					fontSize: matchesMobile ? 10 : 16,
					lineHeight: 1,
					textAlign: 'center',
				}}
				className="poppins"
			>
				{title}
			</p>
			<img
				src={
					title?.includes('Apple')
						? require('./Assets/logo-app-store.png')
						: require('./Assets/logo-google-play.png')
				}
				alt=""
				style={{
					width: 150,
				}}
			/>
		</a>
	);
};

const HardworqDownloadRedirect = () => {
	const theme = useTheme();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	useEffect(() => {
		redirectAPP({
			playStore: 'https://play.google.com/store/apps/details?id=br.com.hardworkmedicina.app.hardworq&hl=pt_BR',
			appleStore: 'https://apps.apple.com/app/hardworq/id6470359505',
		});
	}, []);

	return (
		<main>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
					rowGap: 16,
					padding: '1rem',
				}}
			>
				{/* <h1>Redirect to APP Stores</h1> */}
				<div style={{ display: 'flex', columnGap: 16 }}>
					<ButtonStore
						matchesMobile={matchesMobile}
						urlRedirect={
							'https://play.google.com/store/apps/details?id=br.com.hardworkmedicina.app.hardworq&hl=pt_BR'
						}
						title={'Download na Play Store'}
					/>
					<ButtonStore
						matchesMobile={matchesMobile}
						urlRedirect={'https://apps.apple.com/app/hardworq/id6470359505'}
						title={'Download na Apple Store'}
					/>
				</div>
			</div>
		</main>
	);
};

export default HardworqDownloadRedirect;
