import React, { useEffect, useState } from 'react';
import { Divider, Grid, Paper, /*Tooltip,*/ Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';
import { Link } from 'react-router-dom';
import DefaultButton from '../Buttons/DefaultButton';
// import HelpIcon from '@mui/icons-material/Help';
import Api from '../../Services/Api';
import PropTypes from 'prop-types';

// const benefits = [
// 	{ title: 'Acesso a aulas e lives Hardwork', tooltipInfo: 'Lorem ipsum dolor siamet' },
// 	{ title: 'Acesso ao estudo por questões', tooltipInfo: 'Lorem ipsum dolor siamet' },
// 	{ title: 'Banco de questões', tooltipInfo: 'Lorem ipsum dolor siamet' },
// 	{ title: 'Questões comentadas', tooltipInfo: 'Lorem ipsum dolor siamet' },
// 	{ title: 'Acesso antecipado ao Sprint', tooltipInfo: 'Lorem ipsum dolor siamet' },
// ];

const ExtensivoR1Card = ({
	description,
	info,
	image,
	/*resums,*/ benefits,
	ahead,
	idTurma,
	discount,
	showDiscount,
	handleOpen,
	selectedAgrupamento,
	enableText,
	enableButton,
	enablePrice,
}) => {
	const classes = useStyles();
	const theme = useTheme();
	const [turma, setTurma] = useState([]);

	const [message, setMessage] = useState('');
	const [buttonMessage, setButtonMessage] = useState('');
	// console.log(idTurma, buttonMessage);
	const [triggerUseEffect, setTriggerUserEffect] = useState(false);
	// console.log(turma);
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		const fetchData = async () => {
			await Api.get(`turmasabertas/${idTurma}`).then((res) => {
				if (!triggerUseEffect) setTriggerUserEffect(true);
				if (res) setTurma(res[0]);
				// console.log(res[0]);
				if (turma && turma.data_fim_inscricoes) {
					const dateNow = new Date();
					const endDate = new Date(turma.data_fim_inscricoes);
					if (dateNow >= endDate || idTurma === '1337') {
						setMessage('INSCRIÇÕES ENCERRADAS');
					} else {
						setMessage('');
					}
				}
				if (turma && turma.data_inicio_inscricoes) {
					const dateNow = new Date();
					const startDate = new Date(turma.data_inicio_inscricoes);
					// console.log('data inicial: ', startDate, "data de hoje: ", dateNow);
					if (dateNow >= startDate) {
						setButtonMessage('Quero este!');
					} else {
						setButtonMessage('Tenho interesse!');
					}
				}
			});
		};

		fetchData();
	}, [triggerUseEffect]);

	return (
		<Paper
			className={`${classes.root} box-primary `}
			elevation={5}
			style={
				idTurma == 1332
					? { boxShadow: '0px 0px 60px rgba(0, 255, 168, 1)' }
					: { boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.5)' }
			}
			// style={isSelected ? { scale: '1.05 !important' } : null}
			// target="_blank"
		>
			<Grid
				container
				justifyContent="center"
				alignContent="center"
			>
				<Grid
					item
					xs={12}
					container
					alignContent="center"
					justifyContent="center"
					className={classes.gridImage}
					style={{
						backgroundImage: `url(${require('../../Assets/Extensivo/R1/Express/bg-red-card.png')})`,
					}}
				>
					<img
						src={image}
						alt=""
					/>
				</Grid>
				<Grid
					item
					xs={12}
					container
					justifyContent="flex-start"
					className={classes.bodyCard}
				>
					<Typography
						color="primary"
						className={`${classes.text} ${classes.marginGrid} poppins`}
					>
						{description}
						{/* Ideal para a primeira etapa da 1ª edição do Revalida 2024 –{' '}
						<strong>Prova prevista para fevereiro de 2024.</strong> */}
					</Typography>
					{info?.map((item, index) => {
						if (index === 0) {
							return (
								<Grid
									key={index}
									item
									container
									style={{ borderBottom: '1px solid #707070', paddingBottom: 5, marginBottom: 5 }}
								>
									<Typography
										className={`${classes.text} poppins`}
										style={{ color: '#FF003D', fontWeight: 500 }}
									>
										<strong style={{ color: '#000000' }}>{item.title}: </strong>
										<br />
									</Typography>
									<Typography
										className={`${classes.text2} poppins`}
										style={{ color: '#FF003D' }}
									>
										{' '}
										<strong>{item.info}</strong>
									</Typography>
								</Grid>
							);
						} else {
							return (
								<Grid
									key={index}
									item
									container
									style={{ borderBottom: '1px solid #707070', paddingBottom: 5, marginBottom: 5 }}
								>
									<Typography
										className={`${classes.text} poppins`}
										style={{ color: '#FF003D', fontWeight: 500 }}
									>
										<strong style={{ color: '#000000' }}>{item.title}: </strong> {item.info}{' '}
									</Typography>
								</Grid>
							);
						}
					})}
					{/* {resums && (
						<Grid
							item
							xs={12}
							container
							style={{ marginTop: 8 }}
						>
							<CheckIcon className={classes.checkIcon} />
							<Typography
								color="primary"
								className={`${classes.text} poppins`}
							>
								&nbsp;Resumos + Questões temáticas
							</Typography>
							<Tooltip title="Lorem ipsum dolor siamet">
								<HelpIcon className={classes.helpIcon} />
							</Tooltip>
						</Grid>
					)} */}
					<Grid
						item
						xs={12}
						container
						style={{ borderRadius: 15, border: '1px solid #000000', margin: '0.5rem 0' }}
					>
						<Grid
							item
							xs={12}
							container
							justifyContent={'center'}
							style={{
								backgroundColor: '#000000',
								borderTopRightRadius: 15,
								borderTopLeftRadius: 15,
								padding: '0.5rem 0',
							}}
						>
							<Typography
								color="secondary"
								className={`${classes.text} poppins`}
								style={{ fontWeight: 'bold' }}
							>
								TENHA ACESSO LOGO DE INÍCIO
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							container
							justifyContent={'center'}
							style={{
								padding: '0.5rem',
								backgroundColor: '#D6D6D6',
								borderBottomLeftRadius: 15,
								borderBottomRightRadius: 15,
							}}
						>
							{benefits.map((item, index) => {
								return (
									<Grid
										item
										xs={12}
										container
										alignContent={'center'}
										wrap={'nowrap'}
										style={
											matchesTablet ? { marginTop: index === 0 ? 6 : 6 } : { marginTop: 'unset' }
										}
									>
										<CheckIcon className={classes.checkIcon} />
										<Typography
											color="primary"
											className={`${classes.text3} poppins`}
										>
											&nbsp;{item.title}
										</Typography>
										{/* <Tooltip title={item.tooltipInfo}>
									<HelpIcon className={classes.helpIcon} />
								</Tooltip> */}
									</Grid>
								);
							})}
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						container
						style={{ borderRadius: 15, border: '1px solid #000000' }}
					>
						<Grid
							item
							xs={12}
							container
							justifyContent={'center'}
							style={{
								backgroundColor: '#000000',
								borderTopRightRadius: 15,
								borderTopLeftRadius: 15,
								padding: '0.5rem 0',
							}}
						>
							<Typography
								color="secondary"
								className={`${classes.text} poppins`}
								style={{ fontWeight: 'bold' }}
							>
								E TEM MAIS DURANTE O TRILHA
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							container
							justifyContent={'center'}
							style={{
								padding: '0.5rem',
								backgroundColor: '#D6D6D6',
								borderBottomLeftRadius: 15,
								borderBottomRightRadius: 15,
								minHeight: '15rem',
							}}
						>
							{ahead.map((item, index) => {
								return (
									<Grid
										item
										xs={12}
										container
										alignContent={'center'}
										wrap={'nowrap'}
										style={
											matchesTablet ? { marginTop: index === 0 ? 6 : 6 } : { marginTop: 'unset' }
										}
									>
										<CheckIcon className={classes.checkIcon} />
										<Typography
											color="primary"
											className={`${classes.text3} poppins`}
										>
											&nbsp;{item.title}
										</Typography>
										{/* <Tooltip title={item.tooltipInfo}>
									<HelpIcon className={classes.helpIcon} />
								</Tooltip> */}
									</Grid>
								);
							})}
						</Grid>
					</Grid>
				</Grid>

				<Grid
					item
					xs={12}
					container
					direction="column"
					justifyContent={'center'}
					alignContent={'center'}
					className={`${classes.gridButton} residencia`}
				>
					<Grid
						item
						container
						justifyContent={'center'}
						alignContent={'center'}
						gap={1}
						className={classes.marginGrid}
						style={{ maxWidth: '80%' }}
					>
						<Grid
							item
							xs={12}
							container
							justifyContent={'center'}
							alignContent={'center'}
							gap={1}
							wrap={'nowrap'}
							style={showDiscount ? null : { display: 'none' }}
						>
							<Grid
								item
								style={{
									border: '1px solid #FFFFFF',
									borderRadius: 10,
									padding: '0.25rem 0.5rem',
									backgroundColor: '#f93232',
									// backgroundColor: '#007bff',
								}}
							>
								<Typography
									align="center"
									color="secondary"
									className={`${classes.discountText} poppins`}
								>
									<strong className={classes.yellowDiscount}>R${discount}</strong>
									<br />
									de desconto
								</Typography>
							</Grid>
							<Typography
								align="center"
								color="secondary"
								className={`${classes.greyText2} poppins`}
							>
								{turma.valor?.toLocaleString('pt-BR')},
								<sub style={{ fontSize: '75%', bottom: '-0.15em' }}>00</sub>
								<span className={classes.line}></span>
							</Typography>
						</Grid>
						{buttonMessage && !message && (
							<Grid
								container
								xs={12}
								flexDirection={'row'}
								justifyContent={'space-between'}
								flexWrap={'nowrap'}
								gap={4}
							>
								<Grid
									item
									container
									flexDirection={'column'}
									xs={6}
									alignItems={'flex-start'}
								>
									<Typography
										align="center"
										color="secondary"
										className={`roboto-condensed`}
										style={{ lineHeight: 'unset', color: '#CCCCCC' }}
									>
										Até{' '}
										<span style={{ fontWeight: 'bold', color: '#FF003D' }}>
											{turma?.max_parcelas}x
										</span>{' '}
										de
									</Typography>
									<Typography
										align="justify"
										color="secondary"
										className={`roboto-condensed`}
										style={{
											lineHeight: 'unset',
											fontSize: '28px',
											fontWeight: 'bold',
										}}
									>
										R$
										{turma?.parcelas[turma?.parcelas.length - 1].valor.toLocaleString('pt-BR', {
											minimumFractionDigits: 2,
										})}
									</Typography>
								</Grid>
								{window.location.pathname === '/trilha-revalida-express' && (
									<Grid
										item
										container
										xs={6}
										alignItems={'flex-end'}
										justifyItems={'end'}
										alignSelf={'flex-end'}
									>
										<Typography
											color="secondary"
											noWrap
											className={`roboto-condensed`}
											style={{ lineHeight: 'unset', fontSize: '13px', color: '#CCCCCC' }}
										>
											ou até 8x de R$623,75
										</Typography>
									</Grid>
								)}
							</Grid>
						)}
						{buttonMessage && !message && (
							<Grid
								item
								xs={12}
								container
								justifyContent={'center'}
							>
								<Divider
									variant="middle"
									className={classes.divider}
								/>
							</Grid>
						)}
						{buttonMessage && !message && (
							<Grid
								item
								container
								flexDirection={'column'}
								xs={12}
								alignItems={'flex-start'}
							>
								<Typography
									align="center"
									color="secondary"
									className={`roboto-condensed`}
									style={{ lineHeight: 'unset', color: '#CCCCCC' }}
								>
									À <span style={{ fontWeight: 'bold', color: '#FF003D' }}>vista</span> por
								</Typography>
								<Typography
									align="justify"
									color="secondary"
									className={`roboto-condensed`}
									style={{
										lineHeight: 'unset',
										fontSize: '28px',
										fontWeight: 'bold',
									}}
								>
									R$ {turma.valor?.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
									<br />
								</Typography>
							</Grid>
						)}
						{message && (
							<Grid
								item
								xs={12}
								container
								flexDirection={'column'}
								justifyContent={'center'}
							>
								{idTurma == 1190 && window.location.pathname === '/trilha-revalida' ? (
									<Typography
										color="#ffffff"
										align="center"
										style={{
											fontWeight: 'bolder',
											fontSize: '1.7rem',
											paddingTop: 10,
										}}
										className={`${classes.textRed}poppins`}
									>
										Nova Turma
									</Typography>
								) : (
									<Typography
										color="#f93232"
										align="center"
										style={{
											fontWeight: 'bolder',
											fontSize: '1.7rem',
											paddingTop: 10,
										}}
										className={`${classes.textRed}poppins`}
									>
										{message}
									</Typography>
								)}
								{buttonMessage &&
									message &&
									idTurma == 1190 &&
									window.location.pathname === '/trilha-revalida' && (
										<Typography
											color="#FFFFFF"
											align="center"
											style={{
												fontWeight: 'bolder',
												fontSize: '0.8rem',
												paddingTop: 10,
											}}
											className={`poppins`}
										>
											Trilha Express
										</Typography>
									)}
							</Grid>
						)}
					</Grid>

					{buttonMessage && !message && (
						<DefaultButton
							variant="outlined"
							color="secondary"
							className={`${classes.btn} btn btn-primary`}
							component={Link}
							onClick={() => {
								if (buttonMessage === 'Tenho interesse!') {
									handleOpen('TRILHA R1 EXPRESS - LISTA DE ESPERA'); //here
								} else {
									window.location.href = `//hardworkmedicina.com.br/inscricao/${idTurma}`;
								}
							}}
						>
							{buttonMessage}
						</DefaultButton>
					)}
					{buttonMessage && message && idTurma == 1190 && window.location.pathname === '/trilha-revalida' && (
						<DefaultButton
							variant="outlined"
							color="primary"
							className={`${classes.btn} btn white-btn-secondary`}
							component={Link}
							onClick={() => {
								if (buttonMessage === 'Tenho interesse!') {
									handleOpen('TRILHA R1 EXPRESS - LISTA DE ESPERA');
								} else {
									window.location.href = `https://home.hardworkmedicina.com.br/trilha-r1-express-2024`;
								}
							}}
						>
							{'Ver mais'}
						</DefaultButton>
					)}
				</Grid>
			</Grid>
		</Paper>
	);
};

ExtensivoR1Card.propTypes = {
	handleOpen: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius: '15px !important',
		alignItems: 'flex-start',
		justifyContent: 'center',
		textDecoration: 'none',
		cursor: 'auto',
		backgroundColor: '#000000 !important',
	},
	text: {
		fontSize: '0.95rem !important',
		alignSelf: 'center',
		[theme.breakpoints.down(1400)]: {
			fontSize: '.85rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '.55rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '.7rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '.9rem !important',
		},
	},
	text2: {
		fontSize: '1.05rem !important',
		alignSelf: 'center',
		lineHeight: 'unset !important',
		marginLeft: '0.5rem !important',
		[theme.breakpoints.down(1400)]: {
			fontSize: '.85rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '.55rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '.7rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '.9rem !important',
		},
	},
	text3: {
		fontSize: '0.8rem !important',
		alignSelf: 'center',
		[theme.breakpoints.down(1400)]: {
			fontSize: '.85rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '.55rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '.7rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '.9rem !important',
		},
	},
	textRed: {
		lineHeight: 'unset !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down(1400)]: {
			fontSize: '.85rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '.5rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '.6rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '.8rem !important',
		},
	},
	gridButton: {
		padding: '1rem 0 1.5rem !important',
		backgroundColor: '#000000 !important',
		borderBottomLeftRadius: 15,
		borderBottomRightRadius: 15,
		minHeight: '180px',
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
			padding: '1rem 1.25rem 2rem !important',
		},
	},
	btn: {
		bottom: -20,
		fontSize: '1rem !important',
		width: '63%',
		fontWeight: 'bold !important',
		border: '4px solid !important',
		transition: 'ease-in',
		position: 'absolute !important',
		alignSelf: 'center !important',
		'&:hover': {
			scale: 1.075,
		},
		[theme.breakpoints.down('xl')]: {
			bottom: -20,
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.85rem !important',
			minHeight: 'unset !important',
			width: '70%',
			height: 'unset',
			bottom: -17,
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
			width: '65%',
			bottom: -25,
		},
	},
	checkIcon: {
		color: '#FF003D',
		fontSize: '1.75rem !important',
	},
	bodyCard: {
		backgroundColor: '#ffffff !important',
		padding: '1rem !important',
		height: 'auto',
		minHeight: '800px',
		// height: 'unset',
		[theme.breakpoints.down('xl')]: {
			height: 'auto',
		},
		[theme.breakpoints.down('lg')]: {
			height: 'auto',
		},
		[theme.breakpoints.down('md')]: {
			height: 'unset',
		},
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
		},
	},
	gridImage: {
		minHeight: '15.5rem',
		padding: '2rem 1rem',
		backgroundColor: '#000000',
		borderTopLeftRadius: 15,
		borderTopRightRadius: 15,
		[theme.breakpoints.down('lg')]: {
			padding: '1.5rem 1rem',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '1.5rem 1rem',
			backgroundSize: 'contain',
		},
	},
	priceText: {
		fontSize: '2.2rem !important',
		fontWeight: 'bold !important',
		color: '#FFF460 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.8rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.5rem !important',
		},
	},
	priceTextBold: {
		fontSize: '1.65rem',
		color: '#ffffff !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.45rem',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.75rem !important',
		},
	},
	greyText: {
		fontSize: '1.1rem !important',
		color: '#DEDEDE !important',
		lineHeight: '1.25 !important',
		marginBottom: '1rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.9rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.15rem !important',
		},
	},
	greyText2: {
		fontSize: '1.5rem !important',
		color: '#DEDEDE !important',
		lineHeight: 'unset !important',
		// textDecoration: 'line-through',
		alignSelf: 'center',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.65rem !important',
		},
	},
	helpIcon: {
		alignSelf: 'center',
		marginLeft: 2,
		fontSize: '1.35rem !important',
		color: '#CACCD6',
	},
	divider: {
		minHeight: 1,
		backgroundColor: '#6D6D6D',
		width: '115%',
		margin: '0.25rem 0 !important',
	},
	discountText: {
		fontSize: '0.6rem !important',
		// color: '#FFF460 !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.65rem !important',
		},
	},
	marginGrid: {
		marginBottom: '8px !important',
		// [theme.breakpoints.down('lg')]: {
		// 	marginBottom: '8px !important',
		// },
	},
	yellowDiscount: {
		fontSize: '0.95rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.8rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.1rem !important',
		},
	},
	line: {
		content: "''",
		position: 'absolute',
		bottom: '50%',
		left: '50%',
		right: 0,
		height: '1.75px',
		backgroundColor: 'gray',
		zIndex: 1,
		width: '105%',
		transform: 'translateX(-50%)',
	},
	tooltip: {
		fontSize: '18px !important',
	},
}));

export default ExtensivoR1Card;
