import React, { useRef } from 'react';
import { Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import { Header } from '../../../../Components/Header';

import Form from '../../../../Components/Utils/Forms/NewRegistrationForm';

export default function ProductsTemplate({ children, group, formLogo, backgroundImg, themeColor, title }) {
	const classes = useStyles();
	const theme = useTheme();
	// const [scroll, setScroll] = useState();
	const fixedGridRef = useRef(null);
	const footerRef = useRef(null);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	// const matchesLaptop = useMediaQuery(theme.breakpoints.down('lg'));

	// useEffect(() => {
	// 	const handleScroll = () => {
	// 		const fixedGrid = fixedGridRef.current;
	// 		const footer = footerRef.current;

	// 		if (fixedGrid && footer) {
	// 			const footerTop = footer.getBoundingClientRect().top;
	// 			const windowHeight = window.innerHeight;
	// 			const scrollPosition = window.scrollY;
	// 			const maxTop = 140; // Valor máximo para `top`

	// 			if (footerTop < windowHeight) {
	// 				fixedGrid.style.position = 'absolute';
	// 				fixedGrid.style.bottom = `0px`;
	// 				fixedGrid.style.top = 'unset';
	// 			} else {
	// 				fixedGrid.style.position = 'fixed';
	// 				fixedGrid.style.bottom = '0px';
	// 				fixedGrid.style.top = `${Math.max(maxTop - scrollPosition, 0)}px`; // Reduz o `top` conforme a rolagem
	// 			}
	// 		}
	// 	};

	// 	window.addEventListener('scroll', handleScroll);
	// 	return () => window.removeEventListener('scroll', handleScroll);
	// }, []);

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
			style={{
				backgroundColor: themeColor === 'dark' ? '#09090A' : '#F5F5F5',
			}}
		>
			{matchesMobile ? null : matchesTablet ? (
				<Header whitePattern={themeColor === 'dark' ? false : true} />
			) : null}
			{matchesMobile || matchesTablet ? null : <Header whitePattern={themeColor === 'dark' ? false : true} />}
			<Grid
				container
				alignItems="center"
				// className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
				style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
			>
				<Grid
					item
					xs={12}
					md={7}
					container
					className={classes.textGrid}
				>
					{children}
				</Grid>
				<Grid
					item
					xs={12}
					md={5}
					container
					ref={fixedGridRef}
					// justifyContent={matchesTablet ? 'center' : 'flex-start'}
					justifyContent={'center'}
					alignContent="center"
					rowGap={3}
					className={classes.gridContainer}
					// position={!matchesLaptop && scroll ? 'fixed' : 'relative'}
					style={{
						paddingBottom: 50,
						zIndex: 1,
						backgroundImage: `url(${backgroundImg})`,
						backgroundSize: 'cover',
						// position: 'fixed',
						// paddingLeft: !matchesTablet && '5rem',
						// right: 0,
						// height: '100vh',
						paddingLeft: '1rem',
					}}
				>
					{!matchesTablet && (
						<img
							src={formLogo}
							alt=""
						/>
					)}
					<Grid
						item
						className={classes.formGrid}
					>
						<Form
							group={group}
							title={title ? title : ['Inscreva-se gratuitamente!']}
							titleButton="Confirmar inscrição"
							color={'#ffffffcc'}
							textColor={'primary'}
							confirmationMessage={
								'Sua inscrição está confirmada! Em alguns dias te enviaremos um email com mais informações.'
							}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid container>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					<Container
						fixed
						maxWidth="xl"
						disableGutters={true}
						ref={footerRef}
					>
						{matchesTablet ? null : (
							<Footer
								noMarginBottom
								whitePattern={themeColor === 'dark' ? false : true}
							/>
						)}
					</Container>
				</Container>
			</Grid>
			{matchesTablet ? (
				<Footer
					noMarginBottom
					whitePattern={themeColor === 'dark' ? false : true}
				/>
			) : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '0 auto',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	formGrid: {
		width: '70%',
		[theme.breakpoints.down('xl')]: {
			width: '80%',
		},
		[theme.breakpoints.down('sm')]: {
			width: '90%',
		},
	},
	textGrid: {
		height: 'fit-content',
		padding: '4rem 5rem 4rem 15.5rem',
		[theme.breakpoints.up('2060')]: {
			padding: '4rem 5rem 4rem 20.5rem',
		},
		[theme.breakpoints.down('xl')]: {
			padding: '4rem 5rem 4rem 2rem',
		},
		[theme.breakpoints.down('md')]: {
			padding: '2rem 1rem 2rem 1rem',
		},
	},
	gridContainer: {
		padding: '3rem 8rem 3rem 0',
		[theme.breakpoints.down('xl')]: {
			padding: '3rem 0 3rem 0',
		},
	},
}));
