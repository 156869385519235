import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default function TrilhaR12025({ themeColor }) {
	const classes = useStyles();
	const theme = useTheme();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Grid
			container
			direction={'column'}
			wrap={'nowrap'}
			rowGap={2}
			className="residencia"
		>
			<Grid
				item
				xs={12}
				container
				direction={'column'}
			>
				<Typography
					color={'secondary'}
					fontFamily={'Manrope'}
					fontSize={'2rem'}
					fontWeight={600}
					style={{
						lineHeight: 'unset',
					}}
				>
					O NOVO
				</Typography>
				<Typography
					align="left"
					color={themeColor ? '#FCFCFC' : 'primary'}
					className={`gradient-text-enare-2`}
					style={{
						fontSize: matchesTablet ? '2rem' : '3rem',
						lineHeight: 'unset',
					}}
					fontWeight={800}
					fontFamily={'Manrope'}
				>
					Extensivo Hardwork R1
				</Typography>
			</Grid>
			<img
				src={
					require('../../../Pages/LandingPages/Subscription/Residencia/assets/logo-trilha-r1-25.svg').default
				}
				alt=""
				style={{ width: matchesMobile ? '100%' : 'fit-content', margin: matchesTablet && '0 auto' }}
			/>
			<Typography
				align="left"
				color={themeColor ? '#FCFCFC' : 'primary'}
				className={`${classes.text}`}
				fontFamily={'Manrope'}
			>
				Inscreva-se para acompanhar a
				<br />
				<strong>live de lançamento!</strong>
			</Typography>
			<Typography
				align="left"
				color={themeColor ? '#FCFCFC' : 'primary'}
				className={`${classes.subtitle} gradient-text-enare-2`}
				fontWeight={600}
				fontFamily={'Manrope'}
			>
				Save the date!
			</Typography>
			<Grid
				item
				container
				justifyContent={'center'}
				className="background-central-concursos-horizontal"
				style={{
					// backgroundColor: '#303030',
					height: 'fit-content',
					padding: '1rem 2rem',
					borderRadius: '1rem',
					width: 'fit-content',
				}}
			>
				<Typography
					align="left"
					color={themeColor ? '#FCFCFC' : 'primary'}
					className={`${classes.impactText}`}
					fontFamily={'Manrope'}
				>
					11NOV • 19:00H
				</Typography>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: '800 !important',
		fontSize: '2rem !important',
		lineHeight: '38.4px !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
			lineHeight: '1.25 !important',
		},
	},
	subtitle: {
		fontSize: '3rem !important',
		lineHeight: '38.4px !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.75rem !important',
		},
	},
	text: {
		fontSize: '1.5rem !important',
		lineHeight: '28.8px !important',
		marginBottom: '1rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
			lineHeight: '1.5 !important',
		},
	},
	impactText: {
		fontWeight: '800 !important',
		fontSize: '3rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.5rem !important',
		},
	},
}));
