import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Chart } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import { Grid } from '@mui/material';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function StackedBarChart() {
	const [searchParams] = useSearchParams();
	const [gradientAcertos, setGradientAcertos] = useState(null);
	const [gradientFeitos, setGradientFeitos] = useState(null);

	const chartRef = useRef(null);

	useEffect(() => {
		const chart = chartRef.current;

		if (!chart) {
			return;
		}

		chart.canvas.parentNode.style.height = '330px';

		const gradientAcertos = chart.ctx.createLinearGradient(0, chart.chartArea.bottom, 0, chart.chartArea.top);
		gradientAcertos.addColorStop(1, '#5DE203');
		gradientAcertos.addColorStop(0, '#087D00');

		const gradientFeitos = chart.ctx.createLinearGradient(0, chart.chartArea.bottom, 0, chart.chartArea.top);
		gradientFeitos.addColorStop(1, '#AA9DEB');
		gradientFeitos.addColorStop(0, '#7979795C');

		setGradientAcertos(gradientAcertos);
		setGradientFeitos(gradientFeitos);
	}, []);

	const labels = JSON.parse(searchParams.get('labels'));
	const acertos = JSON.parse(searchParams.get('acertos'));
	const feitas = JSON.parse(searchParams.get('respondidas'));
	const metas = JSON.parse(searchParams.get('metas'));

	const maxTickValue = Math.max(...metas);

	const options = {
		barPercentage: 1.1,
		maintainAspectRatio: false,
		borderRadius: 3,
		plugins: {
			title: {
				display: false,
				text: 'Chart.js Bar Chart - Stacked',
			},
			legend: {
				align: 'end',
				labels: {
					color: '#000000',
					useBorderRadius: true,
					boxWidth: 19,
					borderRadius: 3,
					boxHeight: 11,
					font: {
						size: 9,
						weight: 'bold',
						family: 'Poppins',
					},
				},
			},
		},
		responsive: true,
		scales: {
			x: {
				stacked: true,
				title: {
					display: true,
					text: 'PERÍODO',
					color: '#000000',
					font: {
						family: 'Poppins',
						size: 10,
						weight: 'bold',
						lineHeight: 1.2,
					},
					align: 'end',
				},
				ticks: {
					color: '#000000',
					font: {
						family: 'Poppins',
						size: 8,
						weight: 'bold',
					},
				},
				grid: { tickColor: 'black', color: 'transparent', tickWidth: 3, offset: false },
				border: {
					color: 'black',
					width: 3,
				},
			},

			y: {
				min: 0,
				title: {
					display: true,
					text: 'QUESTÕES',
					color: '#000000',
					font: {
						family: 'Poppins',
						size: 10,
						weight: 'bold',
						lineHeight: 1.2,
					},
					align: 'end',
				},
				ticks: {
					stepSize: maxTickValue / 10,
					color: '#000000',
					font: { size: 8, weight: 'bold', family: 'Poppins' },
				},
				grid: {
					tickColor: 'black',
					tickWidth: 3,
					color: 'transparent',
				},
				border: {
					color: 'black',
					width: 3,
				},
			},
		},
	};

	const data = {
		labels,
		datasets: [
			{
				label: 'Acertos',
				data: acertos,
				backgroundColor: gradientAcertos,
				borderWidth: 1,
				borderColor: '#000000',
			},
			{
				label: 'Feitas',
				data: feitas,
				backgroundColor: gradientFeitos,
				borderWidth: 1,
				borderColor: '#000000',
			},
			{
				label: 'Meta',
				data: metas,
				backgroundColor: '#BCBCBC5C',
				borderColor: '#676767',
				borderWidth: 2,
				datasetstrokestyle: 'dashed',
			},
		],
	};

	return (
		<Grid
			style={{
				display: 'flex',
				width: '100%',
				height: '100vh',
				backgroundColor: '#EBEBEB',
				justifyContent: 'center',
			}}
		>
			<Grid style={{ width: '100vw', height: '100vh' }}>
				<Bar
					options={options}
					data={data}
					ref={chartRef}
				/>
			</Grid>
		</Grid>
	);
}
