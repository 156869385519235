import React, { useEffect, useRef, useState } from 'react';
import { Container, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Header } from '../../../../Components/Header';
import Footer from '../../../../Components/Footer';

// import BgWave2 from '../../../../Assets/Img/bgWave2.png';
import NewBgSprintFinal from '../../../../Assets/SprintFinal/bg-new-sprint-final-r3.png';
import FlipCard from '../Arena/components/FlipCard';
import ProductCard from './components/ProductCard';
import DefaultButton from '../../../../Components/Buttons/DefaultButton';

import { Helmet } from 'react-helmet';
import Api from '../../../../Services/Api';

const sprintBenefits = [
	{
		id: 1,
		img: require('./assets/icon-last-tests-white-residencia-r3.svg').default,
		title: ['Provas dos últimos anos comentadas'],
	},
	{
		id: 2,
		img: require('./assets/icon-dot-white-residencia-r3.svg').default,
		title: ['Cerca de 70 blocos de revisões por prova baseados em análise minuciosa da banca'],
	},
	{
		id: 3,
		img: require('./assets/icon-teacher-orientation-white-residencia-r3.svg').default,
		title: ['Vídeos dos professores Hardwork com mensagens mais relevantes de cada tema revisado para cada prova'],
	},
	{
		id: 4,
		img: require('./assets/icon-cyclic-revisions-white-residencia-r3.svg').default,
		title: [
			'Revisão ativa com uso de trívias criadas pelos professores Hardwork, e acesso às mensagens mais importantes de cada banca',
		],
	},
	{
		id: 5,
		img: require('./assets/icon-brain-engine-white-residencia-r3.svg').default,
		title: ['Algoritmo personalizado de revisões cíclicas, garantindo aprendizado até a data da prova'],
	},
	{
		id: 6,
		img: require('./assets/icon-sf-benefits-r3.svg').default,
		title: [
			'Treinamento final com mescla de questões da banca e outras similares identificadas pela Inteligência Hardwork',
		],
	},
	{
		id: 7,
		img: require('./assets/icon-mock-test-residencia-r3.svg').default,
		title: ['Acesso ao banco de questões Hardwork'],
	},
	{
		id: 8,
		img: require('./assets/icon-direct-contact-residencia-r3.svg').default,
		title: ['Acesso direto ao professor pelo whatsapp pessoal para dúvidas de conteúdo'],
	},
	// {
	// 	id: 9,
	// 	img: require('./assets/icon-support-questions-r3.svg').default,
	// 	title: ['Suporte para recursos de questões da banca'],
	// },
	{
		id: 9,
		img: require('./assets/icon-study-patterns-r3.svg').default,
		title: ['Treinamento de padrões de imagem com o Hardwork Multimídia'],
	},
];

const exams = [
	{
		id: '1210',
		imageUrl: require('./assets/logo-usp-cg-r3.svg').default,
	},
	{
		id: '1211',
		imageUrl: require('./assets/logo-usp-cm-r3.svg').default,
	},
	{
		id: '1212',
		imageUrl: require('./assets/logo-enare-cg-r3.svg').default,
	},
	{
		id: '1213',
		imageUrl: require('./assets/logo-enare-cm-r3.svg').default,
	},
];

const TestCard = ({ imageUrl, classes, selected }) => {
	return (
		<Grid
			container
			justifyContent={'flex-end'}
			className={`${classes.cardRoot} ${classes.testCardStyle} ${selected ? classes.testCardSelected : ''} ${
				selected ? 'background-purple' : null
			}`}
			style={!selected ? { backgroundColor: '#191818', opacity: 0.9, cursor: 'pointer' } : null}
		>
			<Grid
				item
				container
				justifyContent="center"
				className={classes.gridImgTestCard}
				style={{ backgroundColor: selected ? '#333333' : '#191818' }}
			>
				<img
					src={imageUrl}
					alt=""
					className={classes.img}
					style={{ opacity: selected ? 1 : 0.5 }}
				/>
			</Grid>
		</Grid>
	);
};

export default function SprintFinalR32023() {
	const classes = useStyles();
	const theme = useTheme();
	const subscribe_card = useRef(null);
	const [turma, setTurma] = useState([]);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesDownLg = useMediaQuery(theme.breakpoints.down('lg'));

	useEffect(() => {
		Api.get(`turmasabertas/1251`).then((res) => {
			if (res) setTurma(res[0]);
		});
	}, []);

	const executeScroll = () =>
		subscribe_card.current.scrollIntoView({ behavior: 'smooth', block: matchesMobile ? 'start' : 'center' });

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			<Helmet>
				<title>Sprint Final R3 Hardwork 2023</title>
				<meta
					name="description"
					content={''}
				/>
			</Helmet>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className="residencia"
			>
				{matchesMobile || matchesTablet ? null : <Header />}
			</Container>
			<Grid
				container
				alignContent={'center'}
				style={matchesMobile ? { marginTop: 80 } : null}
			>
				<img
					src={
						matchesMobile
							? require('./assets/bg-new-sf-mobile-r3.png')
							: require('../../../../Assets/SprintFinal/bg-banner-sf-r3.png')
					}
					alt=""
				/>
				<Grid
					item
					xs={12}
					container
					direction={'column'}
					alignContent={'center'}
					justifyContent={'center'}
					className={`${classes.gridLogoContainer}`}
					rowGap={2}
				>
					<img
						src={
							require(matchesMobile
								? '../../../LandingPages/Sale/SprintFinal/assets/logo-sf-usp-r3.svg'
								: '../../../LandingPages/Sale/SprintFinal/assets/logo-sf-usp-r3.svg').default
						}
						alt=""
						className={classes.sfBackground}
					/>
					<DefaultButton
						variant="outlined"
						color={'primary'}
						className={`${classes.subscribeBtn} white-btn-secondary poppins`}
						onClick={() => executeScroll()}
					>
						<img
							src={require('../../../../Assets/Svg/Icons/bagIcon.svg').default}
							alt=""
							className={classes.logoBtn}
						/>
						&nbsp;&nbsp;Inscrever-se
					</DefaultButton>
				</Grid>
			</Grid>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className="section"
					style={matchesMobile ? { marginTop: 0 } : { paddingBottom: '1rem 0 3rem' }}
				>
					<Grid
						item
						xs={12}
						container
						className={classes.container}
						gap={matchesTablet ? 1 : 2}
					>
						<Grid
							item
							xs={12}
							container
							style={matchesMobile ? null : { padding: '2em 0' }}
						>
							<Grid
								item
								container
								style={matchesDownLg ? { order: 1 } : { paddingRight: '2rem' }}
							>
								{!matchesTablet && (
									<img
										src={require('./assets/text-focus-r3.svg').default}
										alt=""
										className={classes.focusImg}
									/>
								)}
								<Grid
									item
									xs={12}
									md={3}
									container
									justifyContent={'flex-end'}
									alignContent={'center'}
								>
									<img
										src={
											matchesTablet
												? require('./assets/mobile-fabricio-focus-r3.png')
												: require('./assets/fabricio-from-the-left.png')
										}
										alt=""
									/>
								</Grid>
								<Grid
									item
									xs={12}
									md={9}
									container
									alignContent={'center'}
									gap={1}
									style={{
										backgroundImage: `url(${require('./assets/bg-glitch-1.png')})`,
										paddingLeft: matchesTablet ? null : '1rem',
									}}
								>
									<Grid
										item
										container
										alignContent="center"
										style={{
											height: 'fit-content',
										}}
									>
										<Typography
											align={'left'}
											color="secondary"
											className={`${classes.subtitle} gradient-text roboto-condensed`}
										>
											Treinamento focado no R3 da USP
										</Typography>
									</Grid>
									<Grid
										item
										container
										alignContent="flex-start"
										style={{
											height: 'fit-content',
										}}
									>
										{matchesMobile ? (
											<Typography
												align="left"
												color="secondary"
												className={`${classes.text} poppins`}
											>
												A inteligência Hardwork te faz concentrar os esforços no que é
												importante.
												<br />
												<br />
												Vamos explorar as questões dos últimos anos de R3 Clínica Médica nos
												mínimos detalhes: temas principais, alternativas erradas, questões
												anuladas...
												<br />
												<br />
												Enfim, o Sprint retrata a mente da banca!
											</Typography>
										) : (
											<Typography
												align={'left'}
												color="secondary"
												className={`${classes.text} poppins`}
											>
												{matchesDownLg ? (
													<>
														A inteligência Hardwork te faz concentrar os esforços no que é
														importante.
														<br />
														<br />
														Vamos explorar as questões dos últimos anos de R3 Clínica Médica
														da USP nos mínimos detalhes:
														<br />
														temas principais, alternativas erradas, questões anuladas...
														<br />
														<br />
														Enfim, o Sprint retrata a mente da banca!
													</>
												) : (
													<>
														A inteligência Hardwork te faz concentrar os esforços no que é
														importante.
														<br />
														<br />
														Vamos explorar as questões dos últimos anos de R3 Clínica Médica
														da USP nos mínimos detalhes:
														<br />
														temas principais, alternativas erradas, questões anuladas...
														<br />
														<br />
														Enfim, o Sprint retrata a mente da banca!
													</>
												)}
											</Typography>
										)}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							container
							alignContent={'center'}
							style={{ padding: '2em 0', marginBottom: matchesTablet ? 0 : '2rem' }}
						>
							<Grid
								item
								container
							>
								{!matchesTablet && (
									<img
										src={require('./assets/text-strategy-r3.svg').default}
										alt=""
										className={classes.strategyImg}
									/>
								)}
								<Grid
									item
									container
									justifyContent={matchesMobile ? 'center' : 'flex-start'}
									alignContent={'center'}
								>
									<Grid
										item
										xs={12}
										md={9}
										container
										justifyContent={'flex-end'}
										alignContent={'center'}
										style={{
											paddingRight: matchesTablet ? null : '1rem',
											order: matchesTablet ? 2 : 1,
											backgroundImage: `url(${require('./assets/bg-glitch-2.png')})`,
										}}
									>
										<Grid
											item
											style={{ height: 'fit-content' }}
										>
											<Typography
												align={matchesTablet ? 'left' : 'right'}
												color="secondary"
												className={`${classes.subtitle} gradient-text roboto-condensed`}
											>
												A revisão precisa ser intensa e personalizada
											</Typography>
										</Grid>
										<Grid
											item
											style={{ height: 'fit-content' }}
										>
											<Typography
												align={matchesTablet ? 'left' : 'right'}
												color="secondary"
												className={`${classes.text} poppins`}
											>
												{matchesMobile ? (
													<>
														No Sprint a revisão é intensa e dinâmica, sempre com o professor
														Hardwork
														<br />
														mostrando como dominar os temas como a USP gosta.
														<br />
														<br />
														As mensagens importantes para a prova são apresentadas num ritmo
														progressivo com um
														<br />
														algoritmo personalizado, que identifica o que cada um mais
														precisa revisar.
													</>
												) : (
													<>
														No Sprint a revisão é intensa e dinâmica, sempre com o professor
														Hardwork
														<br />
														mostrando como dominar os temas como a USP gosta.
														<br />
														<br />
														As mensagens importantes para a prova são apresentadas num ritmo
														progressivo com um
														<br />
														algoritmo personalizado, que identifica o que cada um mais
														precisa revisar.
													</>
												)}
											</Typography>
										</Grid>
									</Grid>
									<Grid
										item
										xs={12}
										md={3}
										container
										justifyContent={matchesTablet ? 'flex-end' : 'flex-start'}
										alignContent={'center'}
										style={{ order: matchesTablet ? 1 : 2 }}
									>
										<img
											src={
												matchesTablet
													? require('./assets/mobile-strategy-r3.png')
													: require('./assets/decarthon-from-the-right.png')
											}
											alt=""
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							container
							justifyContent={matchesTablet ? 'flex-start' : 'flex-end'}
							alignContent="center"
							style={{ padding: '2em 0' }}
						>
							<Grid
								item
								container
							>
								{!matchesTablet && (
									<img
										src={require('./assets/text-sweat-r3.svg').default}
										alt=""
										className={classes.sweatImg}
									/>
								)}
								<Grid
									item
									container
									style={matchesDownLg ? { order: 1 } : null}
								>
									<Grid
										item
										xs={12}
										md={3}
										container
										justifyContent={'flex-end'}
										alignContent={'center'}
									>
										<img
											src={
												matchesTablet
													? require('./assets/mobile-sweat-r3.png')
													: require('./assets/fabricio-sweat-from-the-left.png')
											}
											alt=""
										/>
									</Grid>
									<Grid
										item
										xs={12}
										md={9}
										container
										alignContent={'center'}
										style={{
											paddingLeft: matchesTablet ? null : '1rem',
											backgroundImage: `url(${require('./assets/bg-glitch-3.png')})`,
										}}
									>
										<Grid
											item
											container
											alignContent="flex-start"
											style={{ height: 'fit-content' }}
										>
											{matchesMobile ? (
												<Typography
													align={'left'}
													color="secondary"
													className={`${classes.subtitle} gradient-text roboto-condensed`}
												>
													A melhor forma de aprender é com estudo ativo e revisões
													personalizadas
												</Typography>
											) : (
												<Typography
													align="left"
													color="secondary"
													className={`${classes.subtitle} gradient-text roboto-condensed`}
												>
													A melhor forma de aprender é com estudo ativo
													<br />e revisões personalizadas
												</Typography>
											)}
										</Grid>
										<Grid
											item
											container
											alignContent="flex-end"
											style={{ height: 'fit-content' }}
										>
											{matchesMobile ? (
												<Typography
													align="left"
													color="secondary"
													className={`${classes.text} poppins`}
												>
													Chega de nos limitarmos a assistir a aulas ou de estudar pelo mesmo
													roteiro que todo mundo. As notas de corte só aumentam!
													<br />
													<br />O Sprint usa modelo ativo de aprendizado e tem revisões
													cíclicas automáticas e personalizadas, que são preparadas pela
													plataforma de acordo com a necessidade de cada um de nós...
													<br />
													<br />
													Cada um tem o seu próprio SPRINT!
												</Typography>
											) : (
												<Typography
													align="left"
													color="secondary"
													className={`${classes.text} poppins`}
												>
													Chega de nos limitarmos a assistir a aulas ou de estudar pelo mesmo
													roteiro que todo mundo. As notas
													<br />
													de corte só aumentam!
													<br />
													<br />O Sprint usa modelo ativo de aprendizado e tem revisões
													cíclicas automáticas e personalizadas, que
													<br />
													são preparadas pela plataforma de acordo com a necessidade de cada
													um.
													<br />
													<br />
													Cada um tem o seu próprio SPRINT!
												</Typography>
											)}
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Container>
			<Grid container>
				<Grid
					container
					className={classes.backgroundWaves}
				>
					<Container
						fixed
						maxWidth="xl"
						disableGutters={true}
					>
						<Grid
							container
							alignContent="center"
							className="section"
						>
							<Grid
								item
								xs={12}
								container
								style={{ marginBottom: 16 }}
							>
								<Typography
									className={`${classes.subtitle} roboto-condensed`}
									color="secondary"
								>
									O que você terá no Sprint R3
								</Typography>
							</Grid>
							<Grid
								item
								xs={12}
								container
								justifyContent={'center'}
								style={{ marginTop: matchesMobile ? 0 : '2rem' }}
							>
								{sprintBenefits.map((item, index) => {
									return (
										<Grid
											key={item.id}
											item
											xs={6}
											md={4}
											container
											justifyContent={'center'}
											className={`${classes.gridFlipCard} revalida`}
										>
											<FlipCard
												key={item.id}
												image={item.img}
												title={item.title}
												description={item.description}
												color="secondary"
												mobile={matchesMobile}
												size={4}
												classes={classes}
												noFlip
											/>
										</Grid>
									);
								})}
								{/* <Grid
									item
									xs={12}
									container
									justifyContent={'center'}
								>
									<Divider
										variant="middle"
										className={classes.divider}
									/>
								</Grid> */}
							</Grid>
							{/* <Grid
								container
								justifyContent={matchesTablet ? 'center' : 'space-evenly'}
								alignContent={'center'}
								flexWrap={matchesTablet ? 'wrap' : 'nowrap'}
								gap={2}
								style={{
									margin: matchesMobile ? '1rem auto 2rem' : '3rem auto 2rem',
									padding: matchesTablet ? 0 : '0 2rem',
								}}
							>
								<ProductCard
									imgUrl={require('./assets/logo-multimidia-residencia-r3.svg').default}
									description={'Ganhe acesso ao treinamento multimídia 2023 do HWM'}
									classes={classes}
									color="#ffffffcc"
								/>
							</Grid> */}
						</Grid>
					</Container>
				</Grid>
			</Grid>
			<Grid
				container
				className={classes.techBackground}
			>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					<Grid
						item
						container
						// justifyContent={'center'}
						className={'section'}
						style={{ margin: matchesMobile ? '1rem auto 2rem' : `0 auto 0` }}
						ref={subscribe_card}
					>
						<Grid container>
							<Grid
								item
								xs={12}
								container
							>
								<Grid
									item
									container
									justifyContent={'center'}
									alignContent={'flex-end'}
									xs={12}
									className={'background-purple'}
									style={{
										borderTopLeftRadius: 15,
										borderTopRightRadius: 15,
										paddingTop: matchesMobile ? '0.75rem' : '1rem',
										cursor: 'pointer',
									}}
								>
									<Grid
										item
										xs={12}
										container
										justifyContent={'center'}
										className={classes.nonSelectedOption}
										style={{ borderTopRightRadius: 15 }}
									></Grid>
								</Grid>
							</Grid>
							<Grid
								item
								xs={12}
								container
								style={{
									backgroundColor: '#000000',
									backgroundImage: `url(${require('./assets/bg-tech-sf-r3.png')})`,
									backgroundSize: 'cover',
									borderBottomLeftRadius: 15,
									borderBottomRightRadius: 15,
									padding: '2.5rem  2rem',
								}}
							>
								<Grid
									container
									alignContent={matchesTablet ? 'space-around' : 'center'}
									justifyContent="space-between"
									className={classes.extensivoR1Card}
								>
									<Grid
										item
										xs={12}
										md={7}
										container
										justifyContent="center"
										alignContent="center"
										className={`${classes.gridImg} residencia`}
										style={{
											// backgroundImage: `url(${bgImageUrl})`,
											backgroundSize: 'cover',
										}}
									>
										<img
											src={require('./assets/logo-sf-usp-r3.svg').default}
											alt="Logo Sprint Final"
											className={classes.extensivoLogoImg}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										md={5}
										container
										justifyContent="center"
										alignContent="center"
										// className={color ? classes.gridPriceInfoBlack : classes.gridPriceInfo}
										// style={{ padding: matchesTablet ? 0 : '1.5rem 0' }}
										gap={
											window.location.pathname === '/sprint-final-revalida'
												? 1
												: matchesTablet
												? 1
												: 0
										}
									>
										<Grid
											item
											container
											// justifyContent={turma.encerrada ? 'center' : 'flex-start'}
											// alignContent="center"
											direction={'column'}
											style={
												matchesMobile
													? // ? { width: turma.encerrada ? null : '75%' }
													  // : turma.encerrada
													  { width: '90%', margin: '2rem 0 1.5rem' }
													: { width: '70%', marginBottom: 20 }
											}
										>
											{turma.encerrada ? (
												<>
													<Typography
														color={'secondary'}
														align="left"
														className={`${classes.subscriptionInactiveText} roboto-condensed`}
													>
														INSCRIÇÕES{!matchesTablet || !matchesMobile ? <br /> : null}{' '}
														ENCERRADAS
													</Typography>
												</>
											) : (
												<>
													{/* {promotionalValueIsOver ? null : (
														<Typography
															color={'secondary'}
															className={classes.priceTextStrikeThrough}
														>
															R$ {turma?.valor?.toLocaleString('pt-BR')}
															<sup style={{ fontSize: '61%', top: '-0.75em' }}>,00</sup>
														</Typography>
													)} */}
													<Typography
														color="secondary"
														className={classes.priceTextWhite}
													>
														R${' '}
														{turma?.valor?.toLocaleString('pt-BR', {
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
														})}
													</Typography>
													<Typography className={classes.priceTextGrey}>
														ou até{' '}
														{turma?.parcelas && turma?.parcelas?.length > 0
															? turma?.parcelas[turma?.parcelas?.length - 1]?.qtd
															: ''}
														x de R${' '}
														{turma?.parcelas && turma?.parcelas?.length > 0
															? turma?.parcelas[
																	turma?.parcelas?.length - 1
															  ]?.valor.toLocaleString('pt-BR', {
																	minimumFractionDigits: 2,
															  })
															: ''}{' '}
														no cartão de crédito*
													</Typography>
												</>
											)}
										</Grid>
										{turma.encerrada ? null : (
											<Grid
												item
												container
												alignContent={'center'}
												justifyContent={'center'}
												style={matchesTablet ? null : { height: '4rem' }}
												rowGap={1.5}
											>
												<DefaultButton
													variant="outlined"
													color="secondary"
													className={`${classes.btn} btn button-info-card ${
														// !allProductsModeOption && selectedTests.length < 1
														// 	? null
														// 	:
														'btn-primary'
													}`}
													href={`https://hardworkmedicina.com.br/inscricao/${turma?.id}`}
												>
													{matchesMobile ? null : (
														<img
															src={
																require('../../../../Assets/Svg/Icons/bagIcon.svg')
																	.default
															}
															alt=""
															style={{ height: '85%' }}
														/>
													)}
													&nbsp; Inscrever-se
												</DefaultButton>
												<Grid
													item
													container
													justifyContent={'center'}
												>
													<Typography
														color="secondary"
														className={`${classes.discountText} poppins`}
														style={{ color: 'grey' }}
													>
														30% de desconto para alunos Hardwork*
													</Typography>
												</Grid>
											</Grid>
										)}
									</Grid>
								</Grid>
								{/* <Grid
									item
									xs={12}
									container
									justifyContent={'center'}
									alignItems={'center'}
									style={{ margin: '1rem 0', padding: matchesMobile ? '0 0.5rem' : null }}
								>
									<Typography
										color="secondary"
										align={matchesMobile ? 'center' : 'left'}
										className={'poppins'}
										style={{ fontSize: matchesMobile ? '0.8rem' : '1.2rem', fontWeight: 500 }}
									>
										Clique no Sprint R3 que deseja para selecioná-lo
									</Typography>
								</Grid>
								<Grid
									item
									xs={12}
									container
									alignContent="center"
									justifyContent="center"
									style={{ margin: `0px 0px ${matchesMobile ? '1rem' : '2rem'}` }}
									columnGap={2}
								>
									{exams.map((item, index) => (
										<Grid
											item
											xs={6}
											md={2.4}
											lg={2.4}
											key={index}
											container
											justifyContent="center"
											style={{ margin: '8px 0' }}
											// onClick={() => !allProductsModeOption && handleTestSelection(item.id)}
										>
											<TestCard
												key={index}
												imageUrl={item.imageUrl}
												classes={classes}
												selected={true}
												// selected={
												// 	allProductsModeOption ? true : selectedTests.includes(item.id)
												// }
											/>
										</Grid>
									))}
								</Grid> */}
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							lg={6}
							container
							justifyContent={'center'}
							alignContent={'center'}
							style={{
								padding: '0.5rem',
								backgroundColor: '#ffffffcc',
								borderRadius: 17,
								marginTop: '1.5rem',
							}}
						>
							<Typography
								color="primary"
								className={`${classes.disclaimerText2} poppins`}
							>
								{' '}
								*Válido para alunos Extensivo Hardwork R3 Clínica sem pendências financeiras.
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</Grid>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					{matchesTablet ? null : <Footer />}
				</Container>
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	text: {
		fontSize: '1.15rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '0.8rem !important',
		},
	},
	subtitle: {
		fontSize: '2.85rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		marginBottom: '1rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
			marginBottom: '0.5rem !important',
		},
	},
	backgroundWaves: {
		backgroundImage: `url(${NewBgSprintFinal})`,
		width: '100vw !important',
		backgroundSize: 'cover',
	},
	gridFlipCard: {
		margin: '15px 0 !important',
		height: 320,
		padding: '0 3rem',
		[theme.breakpoints.down('sm')]: {
			padding: 'unset',
			height: 225,
		},
	},
	divider: {
		minHeight: 1,
		backgroundColor: '#ffffff',
		width: '100%',
		margin: '2rem 0 !important',
		[theme.breakpoints.down('sm')]: {
			margin: '1rem 0 !important',
		},
	},
	logoBtn: {
		filter: 'invert(1)',
		transition: 'ease-in-out 0.2s',
		[theme.breakpoints.down('xl')]: {
			height: 30,
		},
		[theme.breakpoints.down('sm')]: {
			height: 17,
		},
	},
	techBackground: {
		backgroundImage: `url(${require('../../../../Assets/SprintFinal/bg-tech-grey.png')})`,
		backgroundSize: 'cover',
		padding: '8rem 0',
		[theme.breakpoints.down('sm')]: {
			padding: 'unset',
		},
	},
	subscribeBtn: {
		width: 'fit-content',
		padding: '0.5rem 5rem !important',
		alignSelf: 'center',
		fontSize: '1.35rem !important',
		fontWeight: 'bold !important',
		marginTop: '1rem !important',
		'&:hover': {
			scale: 1.05,
			color: '#ffffff !important',
			'& $logoBtn': {
				filter: 'invert(0)',
			},
		},
		[theme.breakpoints.down('xl')]: {
			padding: '0.25rem 2.5rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '0.85rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.75rem !important',
			padding: '0.25rem 1.25rem !important',
			border: '2px solid transparent !important',
			marginTop: 'unset !important',
		},
	},
	textStyle: {
		fontWeight: '450 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	sfBackground: {
		alignSelf: 'center',
		// [theme.breakpoints.down('xl')]: {
		// 	width: '70%',
		// },
		[theme.breakpoints.down(1509)]: {
			width: '36%',
		},
		[theme.breakpoints.down('sm')]: {
			alignSelf: 'center',
			width: '60%',
		},
	},
	gridBannerImage: {
		backgroundSize: 'cover',
		height: '74vh',
		[theme.breakpoints.down('xl')]: {
			height: '61vh',
		},
		[theme.breakpoints.down('lg')]: {
			height: '49vh',
		},
		[theme.breakpoints.down('md')]: {
			height: '36vh',
		},
		[theme.breakpoints.down('sm')]: {
			height: '34vh',
			marginTop: '65px',
		},
		[theme.breakpoints.down(376)]: {
			height: '42vh',
		},
		[theme.breakpoints.down(361)]: {
			height: '36vh',
		},
	},
	focusImg: {
		position: 'absolute !important',
		top: -20,
		zIndex: 1,
		left: 215,
		width: '35%',
		// [theme.breakpoints.down('xl')]: {
		// 	bottom: -35,
		// },
		[theme.breakpoints.down('lg')]: {
			left: 130,
			top: -60,
			width: '45%',
		},
		[theme.breakpoints.down('md')]: {
			left: 60,
			// bottom: -45,
		},
	},
	strategyImg: {
		position: 'absolute',
		top: -30,
		zIndex: 1,
		right: 258,
		width: '35%',
		// [theme.breakpoints.down('xl')]: {
		// 	bottom: -35,
		// },
		[theme.breakpoints.down('lg')]: {
			right: 130,
			top: -60,
			width: '45%',
			[theme.breakpoints.down('md')]: {
				right: 130,
				// bottom: -55,
			},
		},
	},
	sweatImg: {
		position: 'absolute',
		top: -45,
		zIndex: 1,
		left: 315,
		width: '35%',
		// [theme.breakpoints.down('xl')]: {
		// 	bottom: -35,
		// },
		[theme.breakpoints.down('lg')]: {
			left: 130,
			top: -50,
			width: '45%',
		},
	},
	gridLogoContainer: {
		position: 'absolute',
		height: '100%',
	},
	subtitleGift: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		marginBottom: '1rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
			marginBottom: '0.5rem !important',
		},
	},
	gridImg: {
		height: '100%',
		[theme.breakpoints.down('md')]: {
			height: 'unset',
		},
	},
	extensivoLogoImg: {
		width: '85%',
		[theme.breakpoints.down('xl')]: {
			width: '70%',
		},
		[theme.breakpoints.down('md')]: {
			padding: '0px',
			maxHeight: 'unset',
		},
		[theme.breakpoints.down('sm')]: {
			width: '85%',
		},
	},
	subscriptionInactiveText: {
		fontSize: '3.25rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
			margin: '1rem !important',
		},
	},
	priceTextWhite: {
		fontSize: '4.5rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '4.2rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '3.25rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '4.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.5rem !important',
		},
		[theme.breakpoints.down(376)]: {
			fontSize: '2.5rem !important',
		},
	},
	priceTextGrey: {
		fontSize: '1.25rem !important',
		color: 'grey !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.25rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.95rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.7rem !important',
		},
		[theme.breakpoints.down(376)]: {
			fontSize: '0.7rem !important',
		},
	},
	btn: {
		width: '70%',
		fontSize: '1.25rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		borderColor: 'transparent',
		border: '2px solid #707070 !important',
		transition: '0.2s !important',
		backgroundColor: '#777777 !important',
		color: '#ffffff !important',
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				fontSize: '1.35rem !important',
				borderWidth: '3px !important',
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			width: '90%',
		},
		[theme.breakpoints.down(361)]: {
			paddding: '5px 4px !important',
		},
	},
	priceTextStrikeThrough: {
		fontSize: '2.2rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		textDecoration: 'line-through',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
		},
	},
	cardRoot: {
		width: '90% !important',
		height: 80,
		borderRadius: 15,
		[theme.breakpoints.down('xl')]: {
			height: 70,
		},
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
			borderRadius: 5,
		},
	},
	testCardSelected: {
		opacity: 1,
	},
	gridImgTestCard: {
		width: '90% !important',
		borderTopRightRadius: 15,
		borderBottomRightRadius: 15,
		[theme.breakpoints.down('sm')]: {
			padding: '0.75rem 0 !important',
			borderTopRightRadius: 5,
			borderBottomRightRadius: 5,
		},
	},
	discountText: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.65rem !important',
		},
	},
	disclaimerText2: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.6rem !important',
		},
	},
}));
