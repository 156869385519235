import React, { useEffect } from 'react';
import { Container, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../../Components/Footer';
import { Header } from '../../../../../Components/Header';

import FabricioValois from '../../../../../Assets/LDL/fabricioValois.png';
import FabioColagrossi from '../../../../../Assets/LDL/fabioColagrossi.png';
import AnaSouza from '../Assets/anaSouza-revalida.png';

import Form from '../../../../../Components/Utils/Forms/PreRegistration';
import HostsCarrousel from '../../../../../Components/Sections/HostsCarrousel';
import GalleryImage from '../../../../../Components/Utils/GalleryImage';
import Api from '../../../../../Services/Api';
import { useState } from 'react';

const Hosts = [
	{
		ig_username: '@fabricio.valois',
		img: FabricioValois,
		specialty: 'Clínica Médica',
	},
	{
		ig_username: '@drfabiocolagrossi',
		img: FabioColagrossi,
		specialty: 'Cirurgia Geral',
	},
	{
		ig_username: '@profa.anasouza',
		img: AnaSouza,
		specialty: 'Ginecologia e Obstetrícia',
	},
	// {
	// 	ig_username: 'Claudio Lima',
	// 	img: ClaudioLima,
	// 	specialty: 'Pediatria',
	// },
	// {
	// 	ig_username: '@fabricio.valois',
	// 	img: FabricioValois,
	// 	specialty: 'Clínica Médica',
	// },
	// {
	// 	ig_username: '@drfabiocolagrossi',
	// 	img: FabioColagrossi,
	// 	specialty: 'Cirurgia Geral',
	// },
	// {
	// 	ig_username: '@fabio.cabar',
	// 	img: FabioCabar,
	// 	specialty: 'Ginecologia e Obstetrícia',
	// },
	// {
	// 	ig_username: 'Claudio Lima',
	// 	img: ClaudioLima,
	// 	specialty: 'Pediatria',
	// },
	// {
	// 	ig_username: '@fabricio.valois',
	// 	img: FabricioValois,
	// 	specialty: 'Clínica Médica',
	// },
	// {
	// 	ig_username: '@drfabiocolagrossi',
	// 	img: FabioColagrossi,
	// 	specialty: 'Cirurgia Geral',
	// },
	// {
	// 	ig_username: '@fabio.cabar',
	// 	img: FabioCabar,
	// 	specialty: 'Ginecologia e Obstetrícia',
	// },
	// {
	// 	ig_username: 'Claudio Lima',
	// 	img: ClaudioLima,
	// 	specialty: 'Pediatria',
	// },
];

const photoGallery = [
	{
		id: 1,
		img: require('../Assets/gallery-hardwork-foz.png'),
	},
	{
		id: 2,
		img: require('../Assets/gallery-hardwork-foz-2.png'),
	},
	{
		id: 3,
		img: require('../Assets/gallery-hardwork-foz-3.png'),
	},
	{
		id: 4,
		img: require('../Assets/gallery-hardwork-foz-4.png'),
	},
	{
		id: 5,
		img: require('../Assets/gallery-hardwork-foz-5.png'),
	},
	{
		id: 6,
		img: require('../Assets/gallery-hardwork-foz-6.png'),
	},
	{
		id: 7,
		img: require('../Assets/gallery-hardwork-foz-7.png'),
	},
	{
		id: 8,
		img: require('../Assets/gallery-hardwork-foz-8.png'),
	},
	{
		id: 9,
		img: require('../Assets/gallery-hardwork-foz-9.png'),
	},
	{
		id: 10,
		img: require('../Assets/gallery-hardwork-foz-10.png'),
	},
	{
		id: 11,
		img: require('../Assets/gallery-hardwork-foz-11.png'),
	},
	{
		id: 12,
		img: require('../Assets/gallery-hardwork-foz-12.png'),
	},
	{
		id: 13,
		img: require('../Assets/gallery-hardwork-foz-13.png'),
	},
	{
		id: 14,
		img: require('../Assets/gallery-hardwork-foz-14.png'),
	},
	{
		id: 15,
		img: require('../Assets/gallery-hardwork-foz-15.png'),
	},
	{
		id: 16,
		img: require('../Assets/gallery-hardwork-foz-16.png'),
	},
	{
		id: 17,
		img: require('../Assets/gallery-hardwork-foz-17.png'),
	},
	{
		id: 18,
		img: require('../Assets/gallery-hardwork-foz-18.png'),
	},
	{
		id: 19,
		img: require('../Assets/gallery-hardwork-foz-19.png'),
	},
	{
		id: 20,
		img: require('../Assets/gallery-hardwork-foz-20.png'),
	},
];

export default function EventFozRevalida() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesDownXl = useMediaQuery(theme.breakpoints.down('xl'));

	const [closeGroup, setCloseGroup] = useState(false);

	useEffect(() => {
		const group = 'REVALIDA INEP 2023(PRESENCIAL FOZ)';
		Api.get(`preinscricao/?agrupamento=${group}`).then((res) => {
			setCloseGroup(res >= 2500);
		});
	}, []);

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					justifyContent={'space-between'}
					alignContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
				>
					<Grid
						item
						xs={12}
						md={6}
						container
						justifyContent={'flex-start'}
						className={`${classes.gridTextContainer} revalida`}
						gap={1}
					>
						{matchesDownXl ? (
							<img
								src={require('../../../../../Assets/Extensivo/Revalida/logo-revalida-foz.svg').default}
								alt="Logo Revalida Foz do Iguaçu"
								style={matchesDownXl ? null : matchesTablet ? { height: 120 } : null}
							/>
						) : null}
						<Typography
							className={`${classes.title} gradient-text roboto-condensed`}
							color="secondary"
						>
							Os temas mais quentes das provas do Revalida
						</Typography>
						<Typography
							className={`${classes.text} poppins`}
							color="secondary"
						>
							Vamos te mostrar os segredos dos temas mais abordados no Revalida e também falar sobre a
							preparação para a prova.
							<br />
							<br />
						</Typography>
						<Typography
							align="left"
							color="secondary"
							className={`${classes.impactText} poppins`}
							style={{ fontWeight: 'bold' }}
						>
							CONFIRA O CRONOGRAMA:
						</Typography>
						<Grid
							item
							container
							style={{ marginBottom: '3em' }}
						>
							<Divider
								variant="middle"
								orientation="vertical"
								className={classes.divider}
							/>
							<Grid
								item
								container
								wrap="nowrap"
								gap={2}
							>
								<img
									src={require('../Assets/19.svg').default}
									alt=""
									style={{ height: 30, top: 5 }}
								/>
								<Grid
									item
									container
								>
									<Typography className={`${classes.timelineText} gradient-text poppins`}>
										Aulão Hardwork
									</Typography>
									<Typography
										color="secondary"
										className={`${classes.text} poppins`}
									>
										Um aula de um tema escolhido por você. Escolha o tema de predileção no
										formulário ao lado.
										<br />
										<br />
									</Typography>
								</Grid>
							</Grid>
							<Grid
								item
								container
								wrap="nowrap"
								gap={2}
							>
								<img
									src={require('../Assets/20.svg').default}
									alt=""
									style={{ height: 30, top: 5 }}
								/>
								<Grid
									item
									container
								>
									<Typography className={`${classes.timelineText} gradient-text poppins`}>
										Temas mais quentes do Revalida INEP
									</Typography>
									<Typography
										color="secondary"
										className={`${classes.text} poppins`}
									>
										Discussão dos temas mais comuns na prova do INEP.
										<br />
										<br />
									</Typography>
								</Grid>
							</Grid>
							<Grid
								item
								container
								wrap="nowrap"
								gap={2}
							>
								<img
									src={require('../Assets/21.svg').default}
									alt=""
									style={{ height: 30, top: 5 }}
								/>
								<Grid
									item
									container
								>
									<Typography className={`${classes.timelineText} gradient-text poppins`}>
										Sorteios e coffee de encerramento
									</Typography>
									<Typography
										color="secondary"
										className={`${classes.text} poppins`}
									>
										Iremos repor as energias ao fim do evento com um incrível lanche.
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Typography
							color="secondary"
							className={`${classes.text} poppins`}
						>
							Marque na agenda, as vagas são limitadas.
						</Typography>
						<Typography
							align="left"
							color="secondary"
							className={`${classes.impactText} poppins`}
						>
							SÁBADO
							<span className="bullet"> &#8226; </span>
							03JUN
							<span className="bullet"> &#8226; </span>
							19H
						</Typography>
						<Typography
							align="left"
							color="secondary"
							className={`${classes.impactText2} poppins`}
						>
							ONDE SERÁ O EVENTO?
						</Typography>
						<Typography
							color="secondary"
							className={`${classes.text} poppins`}
							style={{ marginBottom: '1.5rem' }}
						>
							Endereço: Av. Costa e Silva, 3500 - Parque Pres. 1, Foz do Iguaçu - PR, 85863-000.
						</Typography>
						<HostsCarrousel
							Hosts={Hosts}
							type="revalida"
						/>
						<GalleryImage
							src={photoGallery}
							title="Confira como foi o último #Hardwork em Foz do Iguaçu:"
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={5}
						container
						justifyContent="center"
						className={classes.gridWithBackgroundImage}
					>
						<Grid
							item
							xs={12}
							className={classes.gridForm}
							gap={1}
						>
							{matchesDownXl ? null : (
								<img
									src={
										require('../../../../../Assets/Extensivo/Revalida/logo-revalida-foz.svg')
											.default
									}
									alt="Logo Revalida Foz do Iguaçu"
									className={classes.logo}
								/>
							)}
							{closeGroup ? (
								<Form
									closeGroup={closeGroup}
									group="REVALIDA INEP 2023(PRESENCIAL FOZ)"
									title={[
										'VAGAS ESGOTADAS!',
										<br />,
										'Cadastre-se para entrar',
										<br />,
										'na lista de espera.',
									]}
									titleButton="Entrar na lista de espera"
									type="revalida"
									urlToRedirect="/materiais/revalida-foz"
									confirmationMessage={'Seu cadastro na lista de espera está confirmado!'}
								/>
							) : (
								<Form
									group="REVALIDA INEP 2023(PRESENCIAL FOZ)"
									title={['Inscreva-se para garantir sua vaga e acompanhar o evento']}
									titleButton="Confirmar minha vaga"
									type="revalida"
									urlToRedirect="/"
									selectField
									confirmationMessage={'Sua inscrição está confirmada!'}
								/>
							)}
						</Grid>
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.35rem !important',
		lineHeight: 'unset !important',
		marginBottom: '2rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
			marginBottom: '8px !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.18rem !important',
			marginBottom: '8px !important',
			lineHeight: 'unset !important',
		},
	},
	text: {
		fontSize: '1.3125rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	gridForm: {
		width: 567,
		position: 'fixed',
		bottom: 20,
		zIndex: 10,
		display: 'flex',
		flexDirection: 'column !important',
		alignItems: 'center !important',
		[theme.breakpoints.down(1300)]: {
			width: 467,
			right: 30,
			bottom: 10,
		},
		[theme.breakpoints.down('lg')]: {
			right: 50,
		},
		[theme.breakpoints.down(1010)]: {
			right: 20,
		},
		[theme.breakpoints.down('md')]: {
			position: 'unset',
			right: 'unset',
			top: 'unset',
			zIndex: 0,
		},
	},
	gridTextContainer: {
		paddingBottom: '1em',
		[theme.breakpoints.down('lg')]: {
			paddingRight: '2em',
		},
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
	},
	gridWithBackgroundImage: {
		paddingLeft: 20,
		backgroundImage: `url(${require('../../../../../Assets/Extensivo/Revalida/live-react-bg.png')})`,
		[theme.breakpoints.down('md')]: {
			paddingLeft: 'unset',
		},
	},
	gridTitle: {
		backgroundColor: '#000000',
		padding: '0px 25px',
		borderTopRightRadius: 20,
		borderTopLeftRadius: 20,
		[theme.breakpoints.down('sm')]: {
			padding: '0px 15px',
		},
	},
	impactText: {
		margin: '0 0 2rem !important',
		fontSize: '2.2rem !important',
		lineHeight: 'unset !important',
		background: 'linear-gradient(90deg, rgba(0,145,166,1) 10%, rgba(0,194,110,1) 100%)',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.45rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1rem !important',
		},
	},
	logo: {
		[theme.breakpoints.down('xl')]: {
			height: 'unset',
			width: 250,
		},
	},
	divider: {
		position: 'absolute',
		border: '1.5px solid #fff !important',
		left: '4.85em',
		backgroundColor: '#fff',
		height: '230px !important',
		zIndex: -1,
		[theme.breakpoints.down('xl')]: {
			height: '200px !important',
		},
		[theme.breakpoints.down('lg')]: {
			height: '280px !important',
		},
		[theme.breakpoints.down('md')]: {
			height: '295px !important',
		},
		[theme.breakpoints.down('sm')]: {
			height: '240px !important',
		},
	},
	imgTime: {
		height: 30,
		top: 5,
	},
	timelineText: {
		fontSize: '1.5rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.2rem !important',
		},
	},
	impactText2: {
		margin: '1.5rem 0 0 !important',
		fontSize: '1.75rem !important',
		lineHeight: 'unset !important',
		fontWeight: 'bold !important',
		background: 'linear-gradient(90deg, rgba(0,145,166,1) 10%, rgba(0,194,110,1) 100%)',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.45rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1rem !important',
		},
	},
}));
