import { useEffect, useState } from 'react';
import { Button, Grid, Paper, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import DefaultButton from '../../../../Components/Buttons/DefaultButton';
import NewForm from '../../../../Components/Utils/Forms/NewRegistrationForm';
import greenChecked from '../../Assets/login-green-checked.svg';

import Api from '../../../../Services/Api';

export default function BlurredAccessPaper({ handleClose }) {
	const classes = useStyles();
	const theme = useTheme();

	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const [isOpenSignUp, setIsOpenSignUp] = useState('acompanhe');

	//Campos para o payload
	const [userEmail, setUserEmail] = useState('');

	const [userResponse, setUserResponse] = useState(''); //tenho certeza, preciso de ajuda e algo deu errado

	//verificar email
	useEffect(() => {
		const correcaoEmail = localStorage.getItem('email-correcao');

		if (correcaoEmail) {
			setUserEmail(correcaoEmail);
			setIsOpenSignUp('comofoi');
		}
	}, []);

	const agrupamento = 'LIVE CORRECAO REVALIDA 2024.2';

	const fetchAluno = async () => {
		try {
			const res = await Api.get(
				`PreInscricao/${encodeURIComponent(agrupamento)}/${encodeURIComponent(userEmail)}/`
			);
			console.log(res);

			if (res <= 0) {
				alert('Você precisa se inscrever antes!');
				setIsOpenSignUp('inscreva');
			} else {
				setIsOpenSignUp('comofoi');
			}

			console.log(res);
		} catch (error) {
			console.log(error);
		}
	};

	const submitAlunoResponse = async () => {
		try {
			localStorage.setItem('resposta-correcao', userResponse);
			await Api.put('PreInscricao', { agrupamento: agrupamento, email: userEmail, pesquisa1: userResponse });
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Grid
			item
			container
			alignContent="flex-start"
			justifyContent="center"
			className={`${classes.glassContainer} ${classes.root}`}
		>
			<Paper
				className={`${classes.paper2} rounded-card`}
				elevation={4}
			>
				{isOpenSignUp === 'acompanhe' && (
					<>
						<Grid
							container
							rowGap={2}
							style={{ backgroundColor: '#EBEBEB', padding: '2rem', borderRadius: 24 }}
						>
							<Grid
								item
								container
								xs={12}
								direction={'column'}
								alignItems={'center'}
							>
								<Typography
									color="primary"
									// className="poppins"
									fontFamily={'Manrope'}
									align="center"
									style={{ fontWeight: '700', fontSize: matchesTablet ? '1.2rem' : '2rem' }}
								>
									Acompanhe a correção da prova!
								</Typography>
								<Typography
									color="primary"
									fontFamily={'Manrope'}
									align="center"
									style={{ fontSize: matchesTablet ? '0.8rem' : '1.25rem' }}
								>
									Utilize seu email de inscrição para assistir a correção da prova e ter acesso ao
									sorteio e materiais gratuitos!
								</Typography>
							</Grid>
							<Grid
								item
								xs={12}
							>
								<TextField
									fullWidth
									type="email"
									name="email"
									style={{ fontSize: '0.8rem' }}
									placeholder="E-mail utilizado na sua inscrição"
									required={true}
									InputProps={{
										className: `${classes.form}`,
										style: { fontSize: matchesTablet && 12 },
									}}
									InputLabelProps={{
										shrink: true,
									}}
									onChange={(e) => setUserEmail(e.target.value)} // Colocar o endpoint de validação do email
								/>
							</Grid>
							<Grid
								container
								justifyContent={'center'}
								alignContent={'center'}
								direction={'column'}
								rowGap={1}
								className="revalida"
								style={{ marginTop: matchesMobile ? null : '1rem' }}
							>
								<Button
									className="btn btn-primary"
									style={{
										width: 'fit-content',
										borderRadius: 40,
										margin: '0 auto',
										padding: '0.25rem 2.5rem',
									}}
									onClick={() => {
										setIsOpenSignUp('comofoi');
										fetchAluno();
									}}
								>
									<Typography
										color="secondary"
										className="poppins"
										style={{
											fontSize: matchesTablet ? '0.7rem' : '1.25rem',
											fontWeight: 'bold',
											textTransform: 'none',
										}}
									>
										Ver a correção da prova
									</Typography>
								</Button>
							</Grid>
						</Grid>
						<DefaultButton
							variant="contained"
							color="primary"
							className={`${classes.btn2} poppins`}
							style={{ margin: '2rem auto 1rem' }}
							onClick={() => setIsOpenSignUp('inscreva')}
						>
							Ainda não está inscrito?
						</DefaultButton>
					</>
				)}
				{isOpenSignUp === 'comofoi' && (
					<>
						<Grid
							className="poppins"
							style={{
								display: 'flex',
								justifyContent: 'center',
								paddingBottom: '1rem',
								alignItems: 'center',
							}}
						>
							<Typography
								style={{
									fontWeight: 'bolder',
									fontSize: matchesTablet ? '22px' : '28px',
									textAlign: 'center',
								}}
							>
								Antes de começar, responda a pesquisa para ver a correção de prova e participar do
								sorteio.
							</Typography>
						</Grid>
						<Grid
							container
							rowGap={2}
							style={{
								backgroundColor: '#EBEBEB',
								padding: matchesTablet ? '0.5rem' : '2rem',
								borderRadius: 24,
							}}
						>
							<Grid
								item
								container
								xs={12}
								direction={'column'}
								alignItems={'center'}
							>
								<Typography
									color="primary"
									// className="poppins"
									fontFamily={'Manrope'}
									align="center"
									style={{ fontWeight: '700', fontSize: matchesTablet ? '1.1rem' : '1.5rem' }}
								>
									Como foi teu desempenho na prova da primeira fase do Revalida INEP?
								</Typography>
							</Grid>
							<Grid
								item
								xs={12}
							>
								<Grid style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
									<button
										onClick={() => {
											setIsOpenSignUp('enviada');
											setUserResponse('tenho certeza');
										}}
										style={{
											backgroundColor: 'white',
											padding: '10px',
											border: '2px solid #CCC',
											borderRadius: '32px',
											cursor: 'pointer',
											fontSize: matchesTablet && '0.9rem',
											flexWrap: 'nowrap',
										}}
									>
										Tenho certeza que fui bem na prova
									</button>
									<button
										onClick={() => {
											setIsOpenSignUp('enviada');
											setUserResponse('preciso de ajuda');
										}}
										style={{
											backgroundColor: 'white',
											padding: '10px',
											border: '2px solid #CCC',
											borderRadius: '32px',
											fontSize: matchesTablet && '0.9rem',
											cursor: 'pointer',
										}}
									>
										Preciso de ajuda com os recursos
									</button>
									<button
										onClick={() => {
											setIsOpenSignUp('enviada');
											setUserResponse('algo deu errado');
										}}
										style={{
											backgroundColor: 'white',
											padding: '10px',
											border: '2px solid #CCC',
											borderRadius: '32px',
											fontSize: matchesTablet && '0.9rem',
											cursor: 'pointer',
										}}
									>
										Algo deu errado e não fui tão bem
									</button>
								</Grid>
							</Grid>
						</Grid>
					</>
				)}
				{isOpenSignUp === 'enviada' && (
					<>
						<Grid
							className="poppins"
							style={{
								display: 'flex',
								justifyContent: 'center',
								paddingBottom: '1rem',
								alignItems: 'center',
							}}
						>
							<Typography
								style={{
									fontWeight: 'bolder',
									fontSize: matchesTablet ? '22px' : '28px',
									textAlign: 'center',
								}}
							>
								Antes de começar, responda a pesquisa para ver a correção de prova e participar do
								sorteio.
							</Typography>
						</Grid>
						<Grid
							container
							rowGap={2}
							style={{ backgroundColor: '#EBEBEB', padding: '2rem', borderRadius: 24 }}
						>
							<Grid
								item
								container
								xs={12}
								direction={'column'}
								alignItems={'center'}
							>
								<img
									width={100}
									height={100}
									src={greenChecked}
									alt=""
								/>
								<Grid
									style={{
										flex: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<Typography
										style={{
											fontWeight: 'bold',
											fontSize: matchesTablet ? '20px' : '24px',
											paddingBottom: '1rem',
											textAlign: 'center',
										}}
									>
										Respostas enviadas!
									</Typography>
									<Typography style={{ fontSize: '18px', textAlign: 'center' }}>
										Você já está concorrendo ao sorteio.
									</Typography>
									<Typography
										style={{ fontSize: '18px', paddingBottom: '1.5rem', textAlign: 'center' }}
									>
										Acompanhe até o final da live de correção.
									</Typography>
								</Grid>
								<button
									onClick={() => {
										handleClose();
										submitAlunoResponse();
									}}
									style={{
										padding: '10px',
										paddingInline: '22px',
										backgroundColor: 'black',
										color: 'white',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										borderRadius: '36px',
									}}
								>
									<p style={{ fontWeight: 'bold', fontSize: '14px' }}>Ir para live de correção! </p>
								</button>
							</Grid>
						</Grid>
					</>
				)}
				{isOpenSignUp === 'inscreva' && (
					<Grid
						container
						style={{ marginBottom: '2rem', rowGap: 16 }}
					>
						<DefaultButton
							type={'button'}
							variant="contained"
							color={'primary'}
							className={`btn`}
							onClick={() => setIsOpenSignUp('acompanhe')}
							style={{ backgroundColor: '#000000', width: 'fit-content', columnGap: 16 }}
						>
							<img
								src={require('../../Assets/icon-go-back-white.svg').default}
								alt=""
							/>
							Voltar
						</DefaultButton>
						<NewForm
							group={'LIVE CORRECAO REVALIDA 2024.2'}
							title={['Inscreva-se gratuitamente!']}
							subtitle={'Para acessar a live, o sorteio e os materiais gratuitos!'}
							titleButton="Inscreva-se"
							color={'#EBEBEB'}
							textColor={'primary'}
							confirmationMessage={
								'Sua inscrição está confirmada! Em alguns dias te enviaremos um email com mais informações.'
							}
							liveCorrecaoOptions
						/>
					</Grid>
				)}
			</Paper>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		fontFamily: 'Poppins, sans-serif',
	},
	glassContainer: {
		backdropFilter: 'blur(5px)',
		boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.37)',
		height: '100%',
		width: '100%',
		position: 'absolute',
		fontFamily: 'poppins',
		paddingTop: '40px',
		zIndex: 1,
		borderRadius: 15,
	},
	paper2: {
		borderRadius: '21px !important',
		padding: '2rem',
		width: '60%',
		fontFamily: 'poppins',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	form: {
		backgroundColor: '#ffffff',
		borderRadius: '10px !important',
		'& .MuiOutlinedInput-input': {
			color: '#75858D',
		},
	},
	btn2: {
		fontSize: '1.5rem !important',
		backgroundColor: '#000000 !important',
		fontWeight: '400 !important',
		lineHeight: 'unset !important',
		padding: '0.5rem 0 !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '1rem !important',
		},
	},
}));
