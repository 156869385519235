import React, { useState } from 'react';
import {
	Button,
	Grid,
	IconButton,
	InputBase,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import Logo from '../Assets/Svg/HardworkLogo.svg';
// import SearchIcon from '@mui/icons-material/Search';
// import ClearIcon from '@mui/icons-material/Clear';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

export default function HeaderMobile(props) {
	const classes = useStyles();

	const [search, setSearch] = useState(false);
	// const [anchorEl, setAnchorEl] = useState(null);
	const [menuOpen, setMenuOpen] = useState(false);
	// const open = Boolean(anchorEl);

	// const handleOpenSearch = () => {
	// 	setSearch(true);
	// };

	// const handleCloseSearch = () => {
	// 	setSearch(false);
	// };

	const handleCloseSearchBar = () => {
		setSearch(false);
	};

	const handleMenuClick = () => {
		setMenuOpen(!menuOpen);
	};

	return (
		<Grid
			container
			className={classes.root}
			style={{
				padding: '2px',
			}}
		>
			<Grid
				item
				className={search ? classes.displayNone : classes.gridLogo}
			>
				<IconButton
					component={Link}
					to="/"
					className="default-icon-button"
				>
					<img
						src={Logo}
						alt="Hardwork Medicina Logo"
						className={`${classes.logo} logo`}
					/>
				</IconButton>
			</Grid>

			<Grid
				item
				container
				alignContent={'center'}
				className={classes.gridBtnContestCentral}
			>
				<Button
					variant="contained"
					color="secondary"
					size="large"
					classes={{
						root: classes.btnMenuOpened,
					}}
					className={` background-central-concursos-horizontal`}
					component={Link}
					to="/materiais/central-de-concursos"
					style={{
						minWidth: '40px',
						height: '45px',
						padding: '0',
						borderRadius: '5px',
					}}
				>
					<Grid
						item
						container
						xs={12}
						wrap="nowrap"
						alignItems="center"
						style={{
							padding: '0.5rem',
						}}
					>
						<img
							src={require('../Assets/Svg/icon-book.svg').default}
							alt=""
							style={{
								width: '1.5rem',
								height: '1.5rem',
							}}
						/>

						<Typography
							className="poppins"
							color="secondary"
							align="center"
							mt={0}
							style={{
								textTransform: 'none',
								lineHeight: '1.1',
								fontSize: '0.7rem',
							}}
						>
							Central de concursos
						</Typography>
					</Grid>
				</Button>
			</Grid>
			{/*<Grid
				item
				container
				className={classes.searchTransition}
				style={search ? { flex: 12, width: '12ch' } : { flex: 12, width: '0.05ch', opacity: 0 }}
			>
				<Grid
					item
					xs={12}
					style={{
						display: 'flex',
						alignSelf: 'center',
						backgroundColor: '#fff',
						borderRadius: '5px',
					}}
				>
					<InputBase
						placeholder="Pesquise seu curso..."
						classes={{ root: classes.inputRoot, input: classes.inputInput }}
						color="#000000"
						onBlur={handleCloseSearchBar}
					/>
					<Button
						style={{
							flex: 1,
							display: 'flex',
							justifyContent: 'center',
							backgroundColor: '#d7d0d0',
							borderTopRightRadius: 5,
							borderBottomRightRadius: 5,
						}}
					>
						<ArrowRightIcon
							// color="secondary"
							style={{ fontSize: '2.5rem' }}
						/>
					</Button>
				</Grid>
			</Grid>
                    */}
			{/* <Grid
				item
				container
				justifyContent="center"
				alignContent="center"
				className={search ? classes.displayNone : classes.flex1}
			>
				<IconButton
					size="large"
					aria-label="search"
					color="inherit"
					onClick={handleOpenSearch}
					style={{ padding: 6 }}
				>
					<SearchIcon
						color="secondary"
						style={{ fontSize: '2.5rem' }}
					/>
				</IconButton>
			</Grid>
			{search ? (
				<Grid
					item
					container
					justifyContent="center"
					alignContent="center"
					className={classes.flex1}
					style={{ minWidth: '50px !important', height: 30, padding: '0 8px !important' }}
				>
					<ClearIcon
						color="secondary"
						style={{ fontSize: '3rem' }}
						onClick={handleCloseSearch}
					/>
				</Grid>
			) : (
				<Grid
					item
					container
					justifyContent="center"
					alignContent="center"
					className={`${classes.flex1} ${classes.menuToggle}`}
					style={menuOpen ? { right: 8 } : null}
					onClick={handleMenuClick}
				>
					<div
						className={classes.menuStripe}
						style={menuOpen ? { transform: 'rotate(45deg) translate(12px, 7px)' } : null}
					></div>
					<div
						className={classes.menuStripe}
						style={menuOpen ? { opacity: 0 } : null}
					></div>
					<div
						className={classes.menuStripe}
						style={menuOpen ? { transform: 'rotate(-45deg) translate(13px, -8px)' } : null}
					></div>
				</Grid>
			)} */}
			<Grid
				item
				container
				justifyContent="center"
				alignContent="center"
				className={`${classes.flex1} ${classes.menuToggle}`}
				style={menuOpen ? { right: 8 } : null}
				onClick={handleMenuClick}
			>
				<div
					className={classes.menuStripe}
					style={menuOpen ? { transform: 'rotate(45deg) translate(12px, 7px)' } : null}
				></div>
				<div
					className={classes.menuStripe}
					style={menuOpen ? { opacity: 0 } : null}
				></div>
				<div
					className={classes.menuStripe}
					style={menuOpen ? { transform: 'rotate(-45deg) translate(13px, -8px)' } : null}
				></div>
			</Grid>
			{menuOpen ? (
				<nav
					style={{
						top: 65,
						width: '100vw',
						backgroundColor: '#0D0D0D',
						zIndex: 9999,
						position: 'fixed',
						right: 0,
						padding: 20,
						bottom: 0,
						left: 0,
						overflowY: 'auto',
					}}
				>
					<List style={{ marginBottom: 50 }}>
						<ListItem
							disablePadding
							style={{ flexDirection: 'column' }}
						>
							<ListItemButton
								className={`${classes.productsCards}`}
								component={Link}
								to="/residencia-medica"
								onClick={() => setMenuOpen(false)}
							>
								<ListItemText
									primary={
										<Typography
											className="poppins"
											color="secondary"
										>
											Residência Médica
										</Typography>
									}
								/>
							</ListItemButton>
							<ListItemButton
								className={`${classes.productsCards}`}
								component={Link}
								to="/revalida-inep"
								onClick={() => setMenuOpen(false)}
							>
								<ListItemText
									primary={
										<Typography
											className="poppins"
											color="secondary"
										>
											Revalida INEP
										</Typography>
									}
								/>
							</ListItemButton>
						</ListItem>
					</List>
					<List style={{ marginBottom: 50 }}>
						<ListItem
							disablePadding
							style={{ flexDirection: 'column' }}
						>
							<ListItemButton
								component={Link}
								to="/"
								className={classes.navigationBtn}
								onClick={() => setMenuOpen(false)}
							>
								<ListItemText
									primary={
										<Typography
											className="poppins"
											color="secondary"
										>
											Home
										</Typography>
									}
								/>
							</ListItemButton>
							{/* <ListItemButton
								component={Link}
								to="//hardworkmedicina.com.br/turmas"
								className={classes.navigationBtn}
								onClick={() => setMenuOpen(false)}
							>
								<ListItemText
									primary={
										<Typography
											className="poppins"
											color="secondary"
										>
											Inscrições
										</Typography>
									}
								/>
							</ListItemButton> */}
							{/* <ListItemButton
								component={Link}
								to="/faq"
								className={classes.navigationBtn}
								onClick={() => setMenuOpen(false)}
							>
								<ListItemText
									primary="FAQ"
									className="poppins"
								/>
							</ListItemButton> */}
							<ListItemButton
								component={Link}
								to="/fale-conosco"
								className={classes.navigationBtn}
								onClick={() => setMenuOpen(false)}
							>
								<ListItemText
									primary={
										<Typography
											className="poppins"
											color="secondary"
										>
											Contato
										</Typography>
									}
								/>
							</ListItemButton>
							<ListItemButton
								component={Link}
								to="//blog.hardworkmedicina.com.br/"
								className={classes.navigationBtn}
								onClick={() => setMenuOpen(false)}
							>
								<ListItemText
									primary={
										<Typography
											className="poppins"
											color="secondary"
										>
											Blog
										</Typography>
									}
								/>
							</ListItemButton>
							{/* <ListItemButton
								component={Link}
								to="/materiais/central-de-concursos"
								className={`${classes.navigationBtn} background-central-concursos-horizontal`}
								onClick={() => setMenuOpen(false)}
							>
								<ListItemText
									primary={
										<Typography
											className="poppins"
											color="secondary"
										>
											Central de concursos
										</Typography>
									}
								/>
                                </ListItemButton> */}

							{/* <ListItemButton
								component={Link}
								to="/sobre-nos"
								className={classes.navigationBtn}
								onClick={() => setMenuOpen(false)}
							>
								<ListItemText
									primary="Sobre nós"
									className="poppins"
								/>
							</ListItemButton> */}
						</ListItem>
					</List>
					<Grid
						container
						justifyContent="space-around"
						columnSpacing={2}
					>
						<Grid
							item
							xs={6}
						>
							<Button
								variant="outlined"
								color="secondary"
								size="medium"
								classes={{
									root: classes.btnMenuOpened,
								}}
								className="poppins"
								href="//hardworkmedicina.com.br/cadastro"
							>
								Cadastre-se
							</Button>
						</Grid>
						<Grid
							item
							xs={6}
						>
							<Button
								variant="outlined"
								color="secondary"
								size="medium"
								classes={{
									root: classes.btnMenuOpened,
								}}
								className="poppins"
								href="//hardworkmedicina.com.br/login"
							>
								Login
							</Button>
						</Grid>
					</Grid>
				</nav>
			) : null}
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		top: 0,
		backgroundColor: '#0D0D0D !important',
		zIndex: 5555,
		padding: '0.5em 1em',
		width: '100vw !important',
		left: 0,
		borderBottom: '0.3px solid #4A4A4A',
		height: 75,
	},
	logo: {
		width: '5em',
	},
	gridLogo: {
		paddingLeft: '0.5rem',
		display: 'flex',
		alignItems: 'center',
		flex: 11,
	},
	inputRoot: {
		color: 'inherit !important',
		flex: 4,
	},
	inputInput: {
		padding: '8px 12px !important',
		transition: theme.transitions.create('width'),
	},
	flex1: {
		flex: 1,
		alignSelf: 'center',
	},
	btnMenuOpened: {
		border: '2px solid !important',
		borderRadius: '8px !important',
		width: '100%',
		height: 48,
		fontWeight: 'bold !important',
		backgroundColor: '#ffffff !important',
		color: 'black !important',
	},
	productsCards: {
		width: '100%',
		height: 70,
		backgroundColor: '#202024 !important',
		borderRadius: '5px !important',
		marginTop: '16px !important',
	},
	navigationBtn: {
		width: '100%',
		height: 57,
		backgroundColor: '#202024 !important',
		borderRadius: '5px !important',
		marginTop: '8px !important',
	},
	menuStripe: {
		backgroundColor: '#fff',
		height: '5px',
		width: '100%',
		margin: '4.5px auto',
		transitionDuration: '0.3s',
		borderRadius: 3,
	},
	menuToggle: {
		minWidth: '50px !important',
		height: 30,
		padding: '0 8px !important',
	},
	displayNone: {
		display: 'none !important',
	},
	searchTransition: {
		transition: '0.2s',
		opacity: 1,
	},
	gridBtnContestCentral: {
		flex: 6,
		height: '100%',
		opacity: 100,
		[theme.breakpoints.down(390)]: {
			flex: 7,
		},
	},
}));
