import { useState } from 'react';
import { Accordion, AccordionSummary, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';

export default function AccordionNews() {
	const classes = useStyles();

	const [expanded] = useState(false);

	const text = 'Edital e data da prova liberados IC-RS Instituto de Cardiologia';

	// Defina um limite para o comprimento do texto antes de adicionar "..."
	const maxLength = 50;
	const truncatedText = text.length > maxLength ? text.slice(0, maxLength) + '...' : text;

	return (
		<Accordion
			classes={{
				root: classes.accordionFaq,
				expanded: classes.accordionExpanded,
			}}
			// key={index}
			// expanded={expanded === index}
			// onChange={handleChange(index)}
		>
			{/* <AccordionSummary
				expandIcon={<ExpandMoreIcon className={expanded ? classes.expandMoreBlack : classes.expandMore} />}
				aria-controls="panel1a-content"
				// id={index}
				className={classes.accordionContent}
			>
				<Grid
					container
					wrap="nowrap"
				>
					<Grid
						item
						xs={12}
						container
						alignContent="center"
						rowGap={0.5}
					>
						<Typography
							align="left"
							color="secondary"
							className={`${classes.dateText} poppins`}
						>
							13:52h
						</Typography>
						<Typography
							align="left"
							color="secondary"
							className={`${classes.text} poppins`}
						>
							Edital e data da prova liberados IC-RS Instituto de Cardiologia
						</Typography>
					</Grid>
				</Grid>
			</AccordionSummary> */}
			<AccordionSummary
				expandIcon={<ExpandMoreIcon className={expanded ? classes.expandMoreBlack : classes.expandMore} />}
				aria-controls="panel1a-content"
				// id={index}
				className={classes.accordionContent}
			>
				<Grid
					item
					xs={12}
					container
					direction={'column'}
					rowGap={0.5}
				>
					<Typography
						color="secondary"
						className={`${classes.dateText} poppins`}
					>
						13:52h
					</Typography>
					<Typography
						color="secondary"
						className={`${classes.text} poppins`}
					>
						{truncatedText}
					</Typography>
				</Grid>
			</AccordionSummary>
			{expanded && (
				<div>
					<Typography color="secondary">{text}</Typography>
				</div>
			)}
		</Accordion>
	);
}

const useStyles = makeStyles((theme) => ({
	accordionFaq: {
		backgroundColor: '#ffffff30 !important',
		borderRadius: '8px !important',
	},
	accordionExpanded: {
		margin: '0px !important',
		backgroundColor: '#707070 !important',
	},
	textNumber: {
		fontWeight: 'bold !important',
		fontSize: '2.5em !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
		},
	},
	text: {
		fontSize: '0.75rem !important',
		lineHeight: '1.25 !important',
		textDecoration: 'underline',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	dateText: {
		fontSize: '0.6rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.6rem !important',
		},
	},
	expandMore: {
		color: '#ffffff !important',
		fontSize: '2.5rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.5rem !important',
		},
	},
	expandMoreBlack: {
		color: 'rgba(0, 0, 0, 0.54) !important',
		fontSize: '4.5rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.5rem !important',
		},
	},
	accordionDetails: {
		padding: '0px 7.5em 2em !important',
		[theme.breakpoints.down('xl')]: {
			padding: '0px 6.5em 2em !important',
		},
		[theme.breakpoints.down('lg')]: {
			padding: '0px 6em 2em !important',
		},
		[theme.breakpoints.down('md')]: {
			padding: '0px 2em 2em !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: 0,
		},
	},
	accordionContent: {
		'& .MuiAccordionSummary-content': {
			margin: '6px 0 !important',
		},
	},
}));
