import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import DefaultButton from '../../../../../Components/Buttons/DefaultButton';

const BoxResumeSprintV2 = ({ icon, title, text, color, buttonFunction }) => {
	const classes = useStyles();

	return (
		<Grid
			item
			container
			direction={'column'}
			justifyContent={'space-between'}
			rowGap={4}
			className="residencia"
			style={{
				backgroundColor: color ? '#101010' : '#fff',
				borderRadius: '24px',
				padding: '2rem 1.5rem',
			}}
		>
			<Grid
				item
				container
				flexDirection="column"
				alignItems={'center'}
				className={classes.boxtitle}
				// onMouseEnter={handleMouseEnter}
				// onMouseLeave={handleMouseLeave}
				rowGap={2}
			>
				<Grid
					item
					container
					direction={'column'}
					rowGap={1}
				>
					<img
						src={icon}
						className={classes.moreButton}
						alt="icone"
					/>
					<Typography
						color={color ? '#FCFCFC' : '#000'}
						fontFamily={'Manrope'}
						fontWeight={800}
						fontSize={24}
						className={classes.title}
					>
						<span style={{ display: 'block' }}>{title}</span>
					</Typography>
				</Grid>
				<Typography
					color={color ? '#F5F5F5' : '#000s'}
					fontFamily={'Manrope'}
					fontWeight={400}
					fontSize={14}
					className={classes.title}
				>
					{text}
				</Typography>
			</Grid>
			{buttonFunction && (
				<DefaultButton
					variant="contained"
					color="primary"
					className={`${classes.btn} btn btn-secondary-invert-gradient`}
					onClick={() => buttonFunction()}
					// component={Link}
					// onClick={() => {
					// 	if (buttonMessage === 'Tenho interesse!') {
					// 		handleOpen('TRILHA R1 EXPRESS - LISTA DE ESPERA'); //here
					// 	} else {
					// 		window.location.href = `//hardworkmedicina.com.br/inscricao/${idTurma}`;
					// 	}
					// }}
				>
					Ver temas
				</DefaultButton>
			)}
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	boxtitle: {
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		overflow: 'hidden',
		transition: 'height 0.3s ease-in-out',
		'&:hover': {
			cursor: 'pointer',
		},
	},
	title: {
		lineHeight: '28px',
		letterSpacing: '-0.4px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'normal',
	},
	titleExpanded: {
		lineHeight: '28px',
		letterSpacing: '-0.4px',
		whiteSpace: 'normal',
	},
	imageContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		width: '100%',
	},
	moreButton: {
		width: 'fit-content',
		color: '#000 !important',
		textTransform: 'none',
		height: 40,
		'&:hover': {
			transform: 'scale(1.1)',
		},
	},
	name: {
		alignSelf: 'flex-start',
	},
	btn: {
		height: 'fit-content',
		width: 'fit-content',
		padding: '0 2rem !important',
		minHeight: 'unset',
	},
}));

export default BoxResumeSprintV2;
