import React, { useRef, useState, useEffect } from 'react';
import { Checkbox, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import Api from '../../../Services/Api';
import DefaultButton from '../../Buttons/DefaultButton';
import { makeStyles } from '@mui/styles';
import { Link, useSearchParams } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { color } from 'chart.js/helpers';
// import { validateCPF } from '../validateCpf';

export default function Form({
	type,
	group,
	title,
	rgField,
	closeGroup,
	titleButton,
	urlToRedirect,
	confirmationMessage,
	testDateField,
	cpfField,
	selectField,
	titleConfirmationMessage,
	noCellphone,
	raioX,
	raioX_id,
	exam,
}) {
	const classes = useStyles();
	const [searchParams] = useSearchParams();

	const [typeSubmit, setTypeSubmit] = useState(false);

	const [sabadoCMCheck, setSabadoCMCheck] = useState('');
	const [sabadoCGCheck, setSabadoCGCheck] = useState('');
	const [sabadoPECheck, setSabadoPECheck] = useState('');
	const [sabadoGOCheck, setSabadoGOCheck] = useState('');
	const [sabadoMPCheck, setSabadoMPCheck] = useState('');

	const [sabadoCMDescription, setSabadoCMDescription] = useState('');
	const [sabadoCGDescription, setSabadoCGDescription] = useState('');
	const [sabadoPEDescription, setSabadoPEDescription] = useState('');
	const [sabadoGODescription, setSabadoGODescription] = useState('');
	const [sabadoMPDescription, setSabadoMPDescription] = useState('');

	const [domingoCMCheck, setDomingoCMCheck] = useState('');
	const [domingoCGCheck, setDomingoCGCheck] = useState('');
	const [domingoPECheck, setDomingoPECheck] = useState('');
	const [domingoGOCheck, setDomingoGOCheck] = useState('');
	const [domingoMPCheck, setDomingoMPCheck] = useState('');

	const [domingoCMDescription, setDomingoCMDescription] = useState('');
	const [domingoCGDescription, setDomingoCGDescription] = useState('');
	const [domingoPEDescription, setDomingoPEDescription] = useState('');
	const [domingoGODescription, setDomingoGODescription] = useState('');
	const [domingoMPDescription, setDomingoMPDescription] = useState('');

	const [preInscricao, setPreInscricao] = useState({
		agrupamento: group,
		nome: '',
		email: '',
		telefone: '',
		rg: '',
		provas: '',
		provas_outras: '',
		optin: false,
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
		pesquisa1:
			'SÁBADO:' +
			sabadoCMCheck +
			' || ' +
			sabadoCGCheck +
			' || ' +
			sabadoPECheck +
			' || ' +
			sabadoGOCheck +
			' || ' +
			sabadoMPCheck +
			' || ' +
			'DOMINGO:' +
			domingoCMCheck +
			' || ' +
			domingoCGCheck +
			' || ' +
			domingoPECheck +
			' || ' +
			domingoGOCheck +
			' || ' +
			domingoMPCheck,
		pesquisa2:
			'SÁBADO:' +
			sabadoCMDescription +
			' || ' +
			sabadoCGDescription +
			' || ' +
			sabadoPEDescription +
			' || ' +
			sabadoGODescription +
			' || ' +
			sabadoMPDescription +
			' || ' +
			'DOMINGO:' +
			domingoCMDescription +
			' || ' +
			domingoCGDescription +
			' || ' +
			domingoPEDescription +
			' || ' +
			domingoGODescription +
			' || ' +
			domingoMPDescription,
	});

	useEffect(() => {
		setPreInscricao({
			...preInscricao,
			utm_source: searchParams.get('utm_source'),
			utm_medium: searchParams.get('utm_medium'),
			utm_campaign: searchParams.get('utm_campaign'),
		});
	}, []);

	useEffect(() => {
		if (raioX) {
			setPreInscricao({
				...preInscricao,
				provas: raioX,
			});
		}
	}, [raioX]);

	useEffect(() => {
		if (exam) {
			setPreInscricao({
				...preInscricao,
				provas: exam,
			});
		}
	}, [exam]);

	useEffect(() => {
		setPreInscricao((prevState) => ({
			...prevState,
			agrupamento: closeGroup ? `${group} LISTA DE ESPERA` : group,
		}));
	}, [closeGroup, group]);

	useEffect(() => {
		setPreInscricao({
			...preInscricao,
			pesquisa1:
				'SÁBADO:' +
				sabadoCMCheck +
				' || ' +
				sabadoCGCheck +
				' || ' +
				sabadoPECheck +
				' || ' +
				sabadoGOCheck +
				' || ' +
				sabadoMPCheck +
				' || ' +
				'DOMINGO:' +
				domingoCMCheck +
				' || ' +
				domingoCGCheck +
				' || ' +
				domingoPECheck +
				' || ' +
				domingoGOCheck +
				' || ' +
				domingoMPCheck,
			pesquisa2:
				'SÁBADO:' +
				'CM:' +
				sabadoCMDescription +
				' || ' +
				'CG:' +
				sabadoCGDescription +
				' || ' +
				'PE:' +
				sabadoPEDescription +
				' || ' +
				'GO:' +
				sabadoGODescription +
				' || ' +
				'MP:' +
				sabadoMPDescription +
				' || ' +
				'DOMINGO:' +
				'CM:' +
				domingoCMDescription +
				' || ' +
				'CG:' +
				domingoCGDescription +
				' || ' +
				'PE:' +
				domingoPEDescription +
				' || ' +
				'GO:' +
				domingoGODescription +
				' || ' +
				'MP:' +
				domingoMPDescription,
		});
	}, [
		sabadoCMCheck,
		sabadoCGCheck,
		sabadoPECheck,
		sabadoGOCheck,
		sabadoMPCheck,
		sabadoCMDescription,
		sabadoCGDescription,
		sabadoPEDescription,
		sabadoGODescription,
		sabadoMPDescription,
		domingoCMCheck,
		domingoCGCheck,
		domingoPECheck,
		domingoGOCheck,
		domingoMPCheck,
		domingoCMDescription,
		domingoCGDescription,
		domingoPEDescription,
		domingoGODescription,
		domingoMPDescription,
	]);

	const isArena = window.location.pathname.includes('react-revalida');

	let sendPreInscricao = function (event) {
		event.preventDefault();
		const nomeCompleto = preInscricao.nome.trim();
		const nomeSobrenome = nomeCompleto.split(' ');

		if (!preInscricao.nome || nomeSobrenome.length < 2) {
			alert('Ops... Por favor informe seu nome e sobrenome');
			return;
		}
		if (!preInscricao.email || !preInscricao.email.includes('@') || !preInscricao.email.includes('.')) {
			alert('Ops... Por favor preencha seu email');
			return;
		}
		if (!preInscricao.telefone && !noCellphone) {
			alert('Ops... Por favor preencha seu telefone com um número válido');
			return;
		}
		if (selectField && !preInscricao.provas) {
			alert('Ops... Por favor informe o tema preferido');
			return;
		}
		if (rgField && !preInscricao.rg) {
			alert('Ops... Por favor informe seu RG');
			return;
		}
		if (cpfField && !preInscricao.cpf) {
			alert('Ops... Por favor informe seu CPF');
			return;
		}
		if (testDateField && !preInscricao.provas) {
			alert('Ops... Por favor informe a data que vai fazer a prova');
			return;
		}
		if (!preInscricao.optin) {
			alert('Ops... Você precisa ler e aceitar os termos de uso acima para se pré-inscrever');
			return;
		}

		setTypeSubmit(true);

		trackPromise(
			Api.post('PreInscricao', preInscricao).then((res) => {
				if (res) {
					if (raioX) {
						handleDownloadPdf(raioX_id);
					} else {
						if (window.location.pathname === '/materiais/edital-revalida-2024-1') {
							document.location.href = `${urlToRedirect}`;
						} else {
							Swal.fire({
								title: `${titleConfirmationMessage ? titleConfirmationMessage : 'Massa!'} `,
								text: `${
									confirmationMessage
										? confirmationMessage
										: 'Sua pré-inscrição está confirmada! Avisaremos assim que a nova turma estiver liberada.'
								}`,
								icon: 'success',
								confirmButtonText: 'Fechar',
								allowOutsideClick: false,
								allowEscapeKey: false,
							}).then((result) => {
								if (result.isConfirmed) {
									if (urlToRedirect) {
										document.location.href = `${urlToRedirect}`;
									} else {
										document.location.href = '/';
									}
								}
							});
						}
					}
				}
			})
		);
	};

	const handlePhone = (valid, phoneNumber) => {
		if (valid) {
			setPreInscricao({
				...preInscricao,
				telefone: phoneNumber,
			});
		} else if (preInscricao.telefone.length) {
			setPreInscricao({
				...preInscricao,
				telefone: '',
			});
		}
	};

	const handleDownloadPdf = (id) => {
		const pdfPath = `https://hardworkmedicina.com.br/app_uploads/public/concursos_raiox/raio-x-provas-impressao-concurso-${id}.pdf`;
		const link = document.createElement('a');
		link.href = pdfPath;
		link.target = '_blank';
		link.click();
		document.location.reload();
	};

	return (
		<Paper
			elevation={4}
			className={`${isArena ? classes.paperArena : classes.paper} rounded-card ${
				window.location.pathname === '/live/r3-clinica-usp'
					? 'background-gradient-purple'
					: `background-${type}`
			}`}
		>
			<Grid container>
				<form
					className="form-control"
					onSubmit={sendPreInscricao}
				>
					<Grid
						item
						xs={12}
						container
						rowGap={1}
					>
						<Grid
							item
							container
							justifyContent="center"
						>
							<Typography
								className={`${classes.signInCardTitle}`}
								color={
									window.location.pathname === '/pre-inscricao/sprint-final-2024'
										? 'primary'
										: 'secondary'
								}
								align="center"
								fontFamily={
									window.location.pathname === '/pre-inscricao/sprint-final-2024'
										? 'Manrope'
										: 'Poppins'
								}
							>
								{title}
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
						>
							<TextField
								fullWidth
								type="string"
								name="nome"
								placeholder="Seu nome completo"
								required={true}
								InputProps={{
									className: `${classes.form} ${
										rgField ? classes.formHeightShort : classes.formHeight
									}`,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										nome: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
						>
							<TextField
								fullWidth
								type="email"
								name="email"
								placeholder="Seu melhor e-mail"
								required={true}
								InputProps={{
									className: `${classes.form} ${
										rgField ? classes.formHeightShort : classes.formHeight
									}`,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										email: e.target.value,
									})
								}
							/>
						</Grid>

						<Grid
							item
							xs={12}
						>
							<IntlTelInput // Componente Input de telefones internacionais
								name="telefone"
								type="telefone"
								preferredCountries={['BR', 'PY', 'AR', 'CL', 'BO', 'UY', 'CU', 'PT']}
								style={{ width: '100%', border: 'unset' }}
								inputClassName={`${classes.phoneNumberForm} ${
									rgField ? classes.formHeightShort : classes.formHeight
								}`}
								nationalMode={true}
								onPhoneNumberChange={(valid, value, selectedCountryData, fullNumber) =>
									handlePhone(valid, fullNumber)
								}
							/>
						</Grid>

						<Grid
							item
							xs
							marginTop={2}
						>
							<Grid
								item
								container
								marginBottom={4}
							>
								<Typography
									className={`${classes.signInCardTitle}`}
									color={'secondary'}
									align="left"
									fontFamily={'Poppins'}
								>
									QUAIS TUAS DÚVIDAS NAS ESTAÇÕES?
								</Typography>
								<Typography
									color={'secondary'}
									align="left"
									fontFamily={'Poppins'}
								>
									Vamos analisar e responder na Live de discussão.
								</Typography>
							</Grid>
							<Grid
								item
								container
								xs
								marginTop={1}
							>
								<Grid
									item
									xs={6}
								>
									<Typography
										fontFamily={'Poppins'}
										color={'secondary'}
										fontWeight={500}
									>
										Estações de <strong>SÁBADO</strong>:
									</Typography>
									<Grid
										item
										xs={12}
										marginTop={2}
									>
										<Checkbox
											style={{
												padding: '0px',
												color: 'white',
											}}
											name="sabadoCMCheck"
											checked={sabadoCMCheck.length > 0}
											value={'Clínica Médica'}
											onChange={(e) => {
												if (sabadoCMCheck.length > 0) {
													return setSabadoCMCheck('');
												} else {
													return setSabadoCMCheck(e.target.value);
												}
											}}
										/>
										<Typography
											variant="caption"
											color={'secondary'}
											fontFamily={'Poppins'}
											fontSize={16}
											marginLeft={1}
										>
											Clínica Médica
										</Typography>
									</Grid>
									<Grid
										item
										xs={12}
										marginTop={1}
									>
										<Checkbox
											style={{
												padding: '0px',
												color: 'white',
											}}
											name="sabadoCGCheck"
											checked={sabadoCGCheck.length > 0}
											value={'Cirurgia Geral'}
											onChange={(e) => {
												if (sabadoCGCheck.length > 0) {
													return setSabadoCGCheck('');
												} else {
													return setSabadoCGCheck(e.target.value);
												}
											}}
										/>
										<Typography
											variant="caption"
											color={'secondary'}
											fontFamily={'Poppins'}
											fontSize={16}
											marginLeft={1}
										>
											Cirurgia Geral
										</Typography>
									</Grid>
									<Grid
										item
										xs={12}
										marginTop={1}
									>
										<Checkbox
											style={{
												padding: '0px',
												color: 'white',
											}}
											name="sabadoPECheck"
											checked={sabadoPECheck.length > 0}
											value={'Pediatria'}
											onChange={(e) => {
												if (sabadoPECheck.length > 0) {
													return setSabadoPECheck('');
												} else {
													return setSabadoPECheck(e.target.value);
												}
											}}
										/>
										<Typography
											variant="caption"
											color={'secondary'}
											fontFamily={'Poppins'}
											fontSize={16}
											marginLeft={1}
										>
											Pediatria
										</Typography>
									</Grid>
									<Grid
										item
										xs={12}
										marginTop={1}
									>
										<Checkbox
											style={{
												padding: '0px',
												color: 'white',
											}}
											name="sabadoGOCheck"
											checked={sabadoGOCheck.length > 0}
											value={'Ginecologia e Obstetrícia'}
											onChange={(e) => {
												if (sabadoGOCheck.length > 0) {
													return setSabadoGOCheck('');
												} else {
													return setSabadoGOCheck(e.target.value);
												}
											}}
										/>
										<Typography
											variant="caption"
											color={'secondary'}
											fontFamily={'Poppins'}
											fontSize={16}
											marginLeft={1}
										>
											Ginecologia e Obstetrícia
										</Typography>
									</Grid>
									<Grid
										item
										xs={12}
										marginTop={1}
									>
										<Checkbox
											style={{
												padding: '0px',
												color: 'white',
											}}
											name="sabadoMPCheck"
											checked={sabadoMPCheck.length > 0}
											value={'Medicina Preventiva'}
											onChange={(e) => {
												if (sabadoMPCheck.length > 0) {
													return setSabadoMPCheck('');
												} else {
													return setSabadoMPCheck(e.target.value);
												}
											}}
										/>
										<Typography
											variant="caption"
											color={'secondary'}
											fontFamily={'Poppins'}
											fontSize={16}
											marginLeft={1}
										>
											Medicina Preventiva
										</Typography>
									</Grid>
								</Grid>
								<Grid
									item
									xs={6}
								>
									<Typography
										fontFamily={'Poppins'}
										color={'secondary'}
										fontWeight={500}
									>
										Estações de <strong>DOMINGO</strong>:
									</Typography>
									<Grid
										item
										xs={12}
										marginTop={2}
									>
										<Checkbox
											style={{
												padding: '0px',
												color: 'white',
											}}
											name="domingoCMCheck"
											checked={domingoCMCheck.length > 0}
											value={'Clínica Médica'}
											onChange={(e) => {
												if (domingoCMCheck.length > 0) {
													return setDomingoCMCheck('');
												} else {
													return setDomingoCMCheck(e.target.value);
												}
											}}
										/>
										<Typography
											variant="caption"
											color={'secondary'}
											fontFamily={'Poppins'}
											fontSize={16}
											marginLeft={1}
										>
											Clínica Médica
										</Typography>
									</Grid>
									<Grid
										item
										xs={12}
										marginTop={1}
									>
										<Checkbox
											style={{
												padding: '0px',
												color: 'white',
											}}
											name="domingoCGCheck"
											checked={domingoCGCheck.length > 0}
											value={'Cirurgia Geral'}
											onChange={(e) => {
												if (domingoCGCheck.length > 0) {
													return setDomingoCGCheck('');
												} else {
													return setDomingoCGCheck(e.target.value);
												}
											}}
										/>
										<Typography
											variant="caption"
											color={'secondary'}
											fontFamily={'Poppins'}
											fontSize={16}
											marginLeft={1}
										>
											Cirurgia Geral
										</Typography>
									</Grid>
									<Grid
										item
										xs={12}
										marginTop={1}
									>
										<Checkbox
											style={{
												padding: '0px',
												color: 'white',
											}}
											name="domingoPECheck"
											checked={domingoPECheck.length > 0}
											value={'Pediatria'}
											onChange={(e) => {
												if (domingoPECheck.length > 0) {
													return setDomingoPECheck('');
												} else {
													return setDomingoPECheck(e.target.value);
												}
											}}
										/>
										<Typography
											variant="caption"
											color={'secondary'}
											fontFamily={'Poppins'}
											fontSize={16}
											marginLeft={1}
										>
											Pediatria
										</Typography>
									</Grid>
									<Grid
										item
										xs={12}
										marginTop={1}
									>
										<Checkbox
											style={{
												padding: '0px',
												color: 'white',
											}}
											name="domingoGOCheck"
											checked={domingoGOCheck.length > 0}
											value={'Ginecologia e Obstetrícia'}
											onChange={(e) => {
												if (domingoGOCheck.length > 0) {
													return setDomingoGOCheck('');
												} else {
													return setDomingoGOCheck(e.target.value);
												}
											}}
										/>
										<Typography
											variant="caption"
											color={'secondary'}
											fontFamily={'Poppins'}
											fontSize={16}
											marginLeft={1}
										>
											Ginecologia e Obstetrícia
										</Typography>
									</Grid>
									<Grid
										item
										xs={12}
										marginTop={1}
									>
										<Checkbox
											style={{
												padding: '0px',
												color: 'white',
											}}
											name="domingoMPCheck"
											checked={domingoMPCheck.length > 0}
											value={'Medicina Preventiva'}
											onChange={(e) => {
												if (domingoMPCheck.length > 0) {
													return setDomingoMPCheck('');
												} else {
													return setDomingoMPCheck(e.target.value);
												}
											}}
										/>
										<Typography
											variant="caption"
											color={'secondary'}
											fontFamily={'Poppins'}
											fontSize={16}
											marginLeft={1}
										>
											Medicina Preventiva
										</Typography>
									</Grid>
								</Grid>
							</Grid>
							<Typography
								fontSize={16}
								marginTop={3}
								color={'secondary'}
								align="left"
								fontFamily={'Poppins'}
							>
								Agora, conta pra gente, o que tu achou da estação
							</Typography>
							<Grid
								item
								xs
							>
								<Typography
									fontFamily={'Poppins'}
									color={'secondary'}
									fontWeight={500}
									marginTop={2}
								>
									Clínica Médica, <strong>SÁBADO</strong>:
								</Typography>

								<TextField
									fullWidth
									type="text"
									placeholder="Descreva aqui suas dúvidas e/ou no que teve dificuldade..."
									InputProps={{
										className: classes.formArena,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									onChange={(e) => setSabadoCMDescription(e.target.value)}
								/>
							</Grid>
							<Grid
								item
								xs
							>
								<Typography
									fontFamily={'Poppins'}
									color={'secondary'}
									fontWeight={500}
									marginTop={2}
								>
									Cirurgia Geral, <strong>SÁBADO</strong>:
								</Typography>

								<TextField
									fullWidth
									type="text"
									placeholder="Descreva aqui suas dúvidas e/ou no que teve dificuldade..."
									InputProps={{
										className: classes.formArena,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									onChange={(e) => setSabadoCGDescription(e.target.value)}
								/>
							</Grid>
							<Grid
								item
								xs
							>
								<Typography
									fontFamily={'Poppins'}
									color={'secondary'}
									fontWeight={500}
									marginTop={2}
								>
									Pediatria, <strong>SÁBADO</strong>:
								</Typography>

								<TextField
									fullWidth
									type="text"
									placeholder="Descreva aqui suas dúvidas e/ou no que teve dificuldade..."
									InputProps={{
										className: classes.formArena,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									onChange={(e) => setSabadoPEDescription(e.target.value)}
								/>
							</Grid>
							<Grid
								item
								xs
							>
								<Typography
									fontFamily={'Poppins'}
									color={'secondary'}
									fontWeight={500}
									marginTop={2}
								>
									Ginecologia e Obstetrícia, <strong>SÁBADO</strong>:
								</Typography>

								<TextField
									fullWidth
									type="text"
									placeholder="Descreva aqui suas dúvidas e/ou no que teve dificuldade..."
									InputProps={{
										className: classes.formArena,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									onChange={(e) => setSabadoGODescription(e.target.value)}
								/>
							</Grid>
							<Grid
								item
								xs
							>
								<Typography
									fontFamily={'Poppins'}
									color={'secondary'}
									fontWeight={500}
									marginTop={2}
								>
									Medicina Preventiva, <strong>SÁBADO</strong>:
								</Typography>

								<TextField
									fullWidth
									type="text"
									placeholder="Descreva aqui suas dúvidas e/ou no que teve dificuldade..."
									InputProps={{
										className: classes.formArena,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									onChange={(e) => setSabadoMPDescription(e.target.value)}
								/>
							</Grid>
							<Grid
								item
								xs
							>
								<Typography
									fontFamily={'Poppins'}
									color={'secondary'}
									fontWeight={500}
									marginTop={2}
								>
									Clínica Médica, <strong>DOMINGO</strong>:
								</Typography>

								<TextField
									fullWidth
									type="text"
									placeholder="Descreva aqui suas dúvidas e/ou no que teve dificuldade..."
									InputProps={{
										className: classes.formArena,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									onChange={(e) => setDomingoCMDescription(e.target.value)}
								/>
							</Grid>
							<Grid
								item
								xs
							>
								<Typography
									fontFamily={'Poppins'}
									color={'secondary'}
									fontWeight={500}
									marginTop={2}
								>
									Cirurgia Geral, <strong>DOMINGO</strong>:
								</Typography>

								<TextField
									fullWidth
									type="text"
									placeholder="Descreva aqui suas dúvidas e/ou no que teve dificuldade..."
									InputProps={{
										className: classes.formArena,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									onChange={(e) => setDomingoCGDescription(e.target.value)}
								/>
							</Grid>
							<Grid
								item
								xs
							>
								<Typography
									fontFamily={'Poppins'}
									color={'secondary'}
									fontWeight={500}
									marginTop={2}
								>
									Pediatria, <strong>DOMINGO</strong>:
								</Typography>

								<TextField
									fullWidth
									type="text"
									placeholder="Descreva aqui suas dúvidas e/ou no que teve dificuldade..."
									InputProps={{
										className: classes.formArena,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									onChange={(e) => setDomingoPEDescription(e.target.value)}
								/>
							</Grid>
							<Grid
								item
								xs
							>
								<Typography
									fontFamily={'Poppins'}
									color={'secondary'}
									fontWeight={500}
									marginTop={2}
								>
									Ginecologia e Obstetrícia, <strong>DOMINGO</strong>:
								</Typography>

								<TextField
									fullWidth
									type="text"
									placeholder="Descreva aqui suas dúvidas e/ou no que teve dificuldade..."
									InputProps={{
										className: classes.formArena,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									onChange={(e) => setDomingoGODescription(e.target.value)}
								/>
							</Grid>
							<Grid
								item
								xs
							>
								<Typography
									fontFamily={'Poppins'}
									color={'secondary'}
									fontWeight={500}
									marginTop={2}
								>
									Medicina Preventiva, <strong>DOMINGO</strong>:
								</Typography>

								<TextField
									fullWidth
									type="text"
									placeholder="Descreva aqui suas dúvidas e/ou no que teve dificuldade..."
									InputProps={{
										className: classes.formArena,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									onChange={(e) => setDomingoMPDescription(e.target.value)}
								/>
							</Grid>
						</Grid>

						<Grid
							item
							xs={12}
						>
							<Checkbox
								style={{
									padding: '0px',
									color: 'white',
								}}
								checked={preInscricao.optin}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										optin: !preInscricao.optin,
									})
								}
							/>
							<Typography
								variant="caption"
								color={'secondary'}
								className={classes.captionText}
								fontFamily={'Poppins'}
							>
								Estou de acordo com o tratamento dos meus dados pessoais para fins de inscrição na
								plataforma Hardwork Medicina, bem como para envio de comunicações relativas aos serviços
								educacionais que se adequem ao meu perfil, de acordo com a nossa{' '}
								<Link
									// color="secondary"
									href="https://hardworkmedicina.com.br/politica-de-privacidade"
									target="_blank"
									underline="hover"
									variant="caption"
									align="right"
									className={`${classes.copyrightText} poppins`}
								>
									Politica de privacidade
								</Link>
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							container
							justifyContent="center"
							className="residencia"
						>
							<DefaultButton
								type={typeSubmit ? 'submit' : 'button'}
								variant="outlined"
								color="secondary"
								className={`${classes.btn} btn`}
								onClick={(e) => sendPreInscricao(e)}
							>
								{titleButton}
							</DefaultButton>
						</Grid>
						{selectField && (
							<Typography
								color="secondary"
								className={classes.selectFieldText}
							>
								*Qual tema você gostaria de ver em uma aula Hardwork?
							</Typography>
						)}
					</Grid>
				</form>
			</Grid>
		</Paper>
	);
}

const useStyles = makeStyles((theme) => ({
	paper: {
		margin: '0 auto',
		padding: '1.5rem',
		[theme.breakpoints.up('lg')]: {},
		[theme.breakpoints.down('sm')]: {
			padding: '1rem',
		},
	},
	paperArena: {
		margin: '0 auto',
		padding: '1.5rem',
		[theme.breakpoints.down('lg')]: {
			width: '294px !important',
			top: '-68px !important',
			left: 38,
		},
		[theme.breakpoints.down('md')]: {
			width: 'unset !important',
			top: 'unset !important',
			left: 'unset !important',
		},
	},
	form: {
		borderRadius: '8px !important',
		backgroundColor: theme.palette.secondary.main,
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'transparent !important',
		},
		'& .MuiOutlinedInput-input': {
			height: '0.75em',
		},
	},
	formArena: {
		borderRadius: '8px !important',
		backgroundColor: theme.palette.secondary.main,
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'transparent !important',
		},
		'& .MuiOutlinedInput-input': {
			height: '80px',
		},
	},
	formHeight: {
		maxHeight: '48px !important',
		[theme.breakpoints.down('xl')]: {
			maxHeight: '40.8px !important',
		},
		[theme.breakpoints.down('sm')]: {
			maxHeight: '45px',
		},
	},
	formHeightShort: {
		maxHeight: '2.5em',
	},
	phoneNumberForm: {
		height: '3em',
		borderRadius: 8,
		width: '100%',
		border: 'unset',
	},
	signInCardTitle: {
		fontSize: '22px !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	btn: {
		fontSize: '1.15rem !important',
		width: '75%',
		fontWeight: 'bold !important',
		textTransform: 'uppercase !important',
		backgroundColor: '#000000 !important',
		border: '1px solid transparent !important',
		'&:hover': {
			border: '1px solid #ffffff !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	copyrightText: {
		color:
			window.location.pathname === '/materiais/semana-tep' ||
			window.location.pathname === '/pre-inscricao/sprint-final-2024'
				? theme.palette.primary.main
				: theme.palette.secondary.main,
	},
	captionText: {
		[theme.breakpoints.down('xl')]: {
			fontSize: '0.65rem !important',
		},
	},
	selectMenu: {
		width: '100%',
		borderRadius: '8px !important',
		backgroundColor: '#FFFFFF',
		maxHeight: '3em',
	},
	selectMenuUf: {
		width: '100%',
		borderRadius: '8px !important',
		backgroundColor: '#FFFFFF',
		maxHeight: '3em',
	},
	selectFieldText: {
		fontSize: '0.9rem !important',
		// marginTop: '1rem !important',
	},
	foodRequirementText: {
		fontSize: '1.1rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '0.9rem !important',
		},
	},
}));
