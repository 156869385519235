import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default function LiveRmaisCg() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Grid
			container
			rowGap={0}
		>
			<Grid
				item
				xs={12}
				container
				rowGap={0}
				justifyContent={'center'}
			>
				{matchesTablet && (
					<img
						src={require('../Assets/logo-rmais-cg-mobile.svg').default}
						alt=""
					/>
				)}
				<img
					src={require('../Assets/img-teachers-cg.png')}
					alt=""
				/>
			</Grid>
			<Grid
				item
				xs={12}
				container
				alignItems={'center'}
				columnGap={matchesTablet ? 1 : 1.5}
				className="residencia"
				backgroundColor={'#1E1E1E'}
				justifyContent={'center'}
				borderRadius={5}
				padding={2}
			>
				<Grid
					item
					xs={12}
					container
					alignItems={'center'}
					columnGap={matchesTablet ? 1 : 1.5}
					className="residencia"
				>
					<img
						src={require('../Assets/live-icon-white.png')}
						alt=""
					/>
					<Typography
						align="left"
						color="secondary"
						className={`${classes.title} `}
						fontFamily={'Manrope'}
					>
						Dissecando as imagens da <br /> prova do R+ Cirurgia USP
					</Typography>
				</Grid>
				<Typography
					align="left"
					color="secondary"
					className={`${classes.text}`}
					fontFamily={'Manrope'}
					marginTop={3}
				>
					O grande diferencial da prova de R+ Cirurgia da USP são as imagens.
				</Typography>
				<Typography
					align="left"
					color="secondary"
					className={`${classes.text}`}
					fontFamily={'Manrope'}
					marginTop={3}
				>
					Chegou a hora de discutirmos com detalhes os padrões de imagem classicamente explorados pela banca.
				</Typography>
				<Grid
					item
					style={{
						backgroundColor: '#585858',
						width: 'fit-content',
						borderRadius: 16,
						padding: '1rem',
						margin: matchesTablet ? '1rem auto 0px' : '2rem 0 0 0',
					}}
				>
					<Typography
						align="left"
						color="secondary"
						className={`${classes.impactText}`}
						fontFamily={'Manrope'}
					>
						01OUT • TERÇA-FEIRA • 18H00
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: '700 !important',
		fontSize: '2rem !important',
		fontFamily: 'Manrope !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	subtitle: {
		fontSize: '1.25rem !important',
		lineHeight: '1.25 !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	text: {
		fontSize: '1.70rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	impactText: {
		fontWeight: 'bold !important',
		fontSize: '1.75rem !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.125rem !important',
		},
	},
}));
