import { useEffect } from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Grid, Paper, TextField, Typography, useMediaQuery, useTheme, Divider } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

// Components
import { Header } from '../../Components/Header';
import { makeStyles } from '@mui/styles';
import DefaultButton from '../../Components/Buttons/DefaultButton';
import Footer from '../../Components/Footer';
import Api from '../../Services/Api';
import IntlTelInput from 'react-intl-tel-input';

const CardLiveDasLives = ({ id, test, time, url, matchesMobile }) => {
	const [preInscricao, setPreInscricao] = useState({
		agrupamento: `AVISE ME - LIVE DE CORREÇÃO ${getProva(test)}`,
		nome: '',
		email: '',
		telefone: '',
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
	});
	const [waitListVisibility, setWaitListVisibility] = useState(false);
	const classes = useStyles();
	const currentTime = new Date();
	const timeDifference = new Date(time).getTime() - currentTime.getTime();

	// 1 dia possui 24 horas e 1 hora possui 3600000 milissegundos
	const horasRestantes = timeDifference / 3600000;
	const isActive = currentTime.getTime() >= new Date(time).getTime() || horasRestantes < 24;

	const handleCloseWaitList = () => {
		setWaitListVisibility(false);
	};

	const handleOpenWaitList = () => {
		setWaitListVisibility(true);
	};

	let sendPreInscricao = function (event) {
		event.preventDefault();
		const nomeCompleto = preInscricao.nome.trim();
		const nomeSobrenome = nomeCompleto.split(' ');
		if (!preInscricao.nome || nomeSobrenome.length < 2) {
			alert('Ops... Por favor informe seu nome e sobrenome');
			return;
		}
		if (!preInscricao.email || !preInscricao.email.includes('@') || !preInscricao.email.includes('.')) {
			alert('Ops... Por favor preencha seu email');
			return;
		}
		if (!preInscricao.telefone) {
			alert('Ops... Por favor preencha seu telefone');
			return;
		}

		trackPromise(
			Api.post('PreInscricao', preInscricao).then((res) => {
				if (res) {
					Swal.fire({
						title: 'Massa! ',
						text: `${'O cadastro está confirmado, avisaremos das novidades em breve.'}`,
						icon: 'success',
						confirmButtonText: 'Fechar',
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then((result) => {
						if (result.isConfirmed) {
							setTimeout(() => {
								document.location.reload();
							}, 500);
						}
					});
				}
			})
		);
	};

	const handlePhone = (valid, phoneNumber) => {
		if (valid) {
			setPreInscricao({
				...preInscricao,
				telefone: phoneNumber,
			});
		} else if (preInscricao.telefone.length) {
			setPreInscricao({
				...preInscricao,
				telefone: '',
			});
		}
	};

	function getLogoUrl(liveName) {
		const nomeDaProva = liveName?.split(' - ')[1].trim();

		switch (nomeDaProva) {
			case 'SURCE':
				return require('../../Assets/LDL/correcao-surce.svg').default;
			case 'ENARE':
				return require('../../Assets/LDL/correcao-enare.svg').default;
			case 'PSU-GO':
				return require('../../Assets/LDL/correcao-psugo.svg').default;
			case 'EINSTEIN':
				return require('../../Assets/LDL/correcao-einstein.svg').default;
			case 'AMP':
				return require('../../Assets/LDL/correcao-amp.svg').default;
			case 'USP-SP':
				return require('../../Assets/LDL/correcao-uspsp.svg').default;
			case 'USP-RP':
				return require('../../Assets/LDL/correcao_usprp.svg').default;
			case 'SUS-BA':
				return require('../../Assets/LDL/correcao-susba.svg').default;
			case 'UFRJ':
				return require('../../Assets/LDL/correcao-ufrj.svg').default;
			case 'UERJ':
				return require('../../Assets/LDL/correcao_uerj.svg').default;
			case 'AMRIGS':
				return require('../../Assets/LDL/correcao-amrigs.svg').default;
			case 'UNICAMP':
				return require('../../Assets/LDL/correcao-unicamp.svg').default;
			case 'ABC':
				return require('../../Assets/LDL/correcao-abc.svg').default;
			case 'UNESP':
				return require('../../Assets/LDL/correcao-unesp.svg').default;
			case 'PSU-MG':
				return require('../../Assets/LDL/correcao-psu-mg.svg').default;
			case 'HSL':
				return require('../../Assets/LDL/correcao-hsl.svg').default;
			case 'FMJ':
				return require('../../Assets/LDL/correcao-fmj.svg').default;
			case 'UNIFESP':
				return require('../../Assets/LDL/correcao-unifesp.svg').default;
			case 'UFES':
				return require('../../Assets/LDL/correcao-ufes.svg').default;
			case 'FAMERP':
				return require('../../Assets/LDL/correcao-famerp.svg').default;
			case 'Santa Casa SP':
				return require('../../Assets/LDL/correcao-scsp.svg').default;
			case 'SUS-SP':
				return require('../../Assets/LDL/correcao-sus.svg').default;
			default:
				return null;
		}
	}

	function getProva(liveName) {
		const nomeDaProva = liveName?.split(' - ')[1].trim();

		switch (nomeDaProva) {
			case 'FAMERP':
				return 'FAMERP';
			case 'SURCE':
				return 'SURCE';
			case 'ENARE':
				return 'ENARE';
			case 'PSU-GO':
				return 'PSU-GO';
			case 'EINSTEIN':
				return 'EINSTEIN';
			case 'AMP':
				return 'AMP';
			case 'USP-SP':
				return 'USP-SP';
			case 'USP-RP':
				return 'USP-RP';
			case 'SUS-BA':
				return 'SUS-BA';
			case 'UFRJ':
				return 'UFRJ';
			case 'UERJ':
				return 'UERJ';
			case 'AMRIGS':
				return 'AMRIGS';
			case 'UNICAMP':
				return 'UNICAMP';
			case 'ABC':
				return 'ABC';
			case 'UNESP':
				return 'UNESP';
			case 'PSU-MG':
				return 'PSU-MG';
			case 'HSL':
				return 'HSL';
			case 'FMJ':
				return 'FMJ';
			case 'UNIFESP':
				return 'UNIFESP';
			case 'UFES':
				return 'UFES';
			case 'Santa Casa SP':
				return 'SANTA-CASA-SP';
			case 'SUS-SP':
				return 'SUS-SP';
			default:
				return null;
		}
	}

	function dateFormatWithTime(date) {
		const data = new Date(date);
		const dia = data.getDate();
		const mesesEmPortugues = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];
		const mes = mesesEmPortugues[data.getMonth()];
		const hora = data.getHours();

		// Formate o dia para adicionar um "0" à esquerda, se for menor que 10
		const diaFormatado = dia < 10 ? `0${dia}` : dia;

		// Formate a hora para adicionar um "0" à esquerda, se for menor que 10
		const horaFormatada = hora < 10 ? `0${hora}` : hora;

		// Crie a string final no formato "dia mes\nhora"
		const dataHoraFormatada = `${diaFormatado}${mes} • ${horaFormatada}h`;

		return dataHoraFormatada;
	}

	return (
		<Paper
			className={`${classes.rootCard} rounded-card `}
			elevation={4}
			style={{
				backgroundColor: url ? null : '#0000004d',
				// border: shortly === true ? '1px solid #FF4A60' : '1px solid #ff4a604d',
				border: '1px solid #FF4A60',
				height: 215,
			}}
		>
			<Grid
				// style={{
				// 	opacity: shortly ? null : 0.3,
				// }}
				className={`${classes.borderGrid} background-central-concursos-vertical`}
			></Grid>
			<Grid
				container
				className={classes.gridBackground}
				style={{
					backgroundImage: url
						? `url(${require('../../Assets/LDL/bg-ldl-card.png')})`
						: `url(${require('../../Assets/LDL/bg-ldl-soon.png')})`,
				}}
			>
				<Grid
					item
					xs={12}
					container
					wrap="nowrap"
					style={{
						justifyContent: 'center',
						alignItems: 'center',
						paddingTop: 20,
					}}
				>
					<Grid
						item
						xs={12}
						container
						// justifyContent={'center'}
						style={{
							paddingLeft: '2rem',
							padding: '1.5rem',
							borderRadius: '10px',
							backgroundColor: '#000000bf',
						}}
					>
						<img
							src={getLogoUrl(test)}
							alt=""
							style={
								{
									/*opacity: shortly ? 1 : 0.3 width: '80%',*/
								}
							}
						/>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					container
					alignContent="center"
					justifyContent={'space-between'}
					wrap="nowrap"
					className={classes.gridInformation}
				>
					<Grid
						item
						xs={5}
						container
						direction={matchesMobile ? 'column' : 'row'}
						alignContent={'center'}
						justifyContent={'center'}
					>
						<img
							src={require('../../Assets/LDL/calendar.svg').default}
							alt=""
							style={{ width: 20, marginRight: 8 }}
						/>
						<Typography
							align="center"
							color="secondary"
							className={`${classes.dateText} poppins`}
						>
							{dateFormatWithTime(time)}
						</Typography>
					</Grid>
					{isActive ? (
						<DefaultButton
							variant="outlined"
							color={'secondary'}
							className={`${classes.subscribeBtn} poppins background-central-concursos-horizontal`}
							component={Link}
							to={`/live/correcao-${getProva(test)?.toLowerCase()}-${id}`}
						>
							Acessar
						</DefaultButton>
					) : (
						<DefaultButton
							variant="outlined"
							color={'secondary'}
							className={`${classes.subscribeBtn} poppins background-central-concursos-horizontal`}
							onClick={() => handleOpenWaitList()}
						>
							<img
								src={require('../../Assets/Svg/icon-white-bell.svg').default}
								alt=""
								style={{ width: 12 }}
							/>
							&nbsp;Avise-me
						</DefaultButton>
					)}
				</Grid>
				{test && (
					<form
						className="form-control"
						onSubmit={sendPreInscricao}
						action="javascript:;"
						style={
							waitListVisibility
								? {
										overflow: 'hidden',
										transition: 'opacity 1s ease, height 1s ease',
										marginTop: '0.5rem',
								  }
								: { width: '100%', visibility: 'hidden', height: 0 }
						}
					>
						<Grid
							item
							container
							justifyContent={'space-between'}
							className={classes.containerWaitList}
							rowGap={matchesMobile ? 1 : 0}
						>
							<Grid
								item
								container
								justifyContent="space-between"
							>
								<Typography
									color="primary"
									className={`${classes.textWaitList} poppins`}
								>
									Cadastre-se para receber as novidades
								</Typography>
								<CancelRoundedIcon
									onClick={() => handleCloseWaitList()}
									className={classes.cancelIcon}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								md={5.75}
								container
							>
								<TextField
									fullWidth
									type="string"
									name="nome"
									placeholder="Seu nome completo"
									required={true}
									InputProps={{
										className: `${classes.form} ${classes.formHeight}`,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									onChange={(e) =>
										setPreInscricao({
											...preInscricao,
											nome: e.target.value,
										})
									}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								md={5.75}
								container
								alignContent={'center'}
							>
								<IntlTelInput // Componente Input de telefones internacionais
									name="mobile"
									type="mobile"
									preferredCountries={['BR', 'PY', 'AR', 'CL', 'BO', 'UY', 'CU', 'PT']}
									style={{ width: '100%', border: 'unset' }}
									inputClassName={`${classes.phoneNumberForm} ${classes.form} ${classes.formHeight}`}
									nationalMode={true}
									onPhoneNumberChange={(valid, value, selectedCountryData, fullNumber) =>
										handlePhone(valid, fullNumber)
									}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								container
							>
								<TextField
									fullWidth
									type="email"
									name="email"
									placeholder="Seu melhor e-mail"
									required={true}
									InputProps={{
										className: `${classes.form} ${classes.formHeight}`,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									style={{ width: '100%' }}
									onChange={(e) =>
										setPreInscricao({
											...preInscricao,
											email: e.target.value,
										})
									}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								md={10}
								lg={5}
								container
								className="residencia"
								style={{ padding: '4px 0px', margin: '0 auto' }}
							>
								<DefaultButton
									type="submit"
									variant="outlined"
									color="secondary"
									className={`${classes.btnWaitList} btn`}
									onClick={(e) => sendPreInscricao(e)}
								>
									<img
										src={require('../../Assets/Arena/icon-waitList.svg').default}
										alt=""
										style={{ width: 20 }}
									/>{' '}
									&nbsp; Confirmar
								</DefaultButton>
							</Grid>
						</Grid>
					</form>
				)}
			</Grid>
		</Paper>
	);
};

export default function LiveDeCorrecao() {
	const theme = useTheme();
	const classes = useStyles();

	const [livesOptions, setLivesOptions] = useState([]);
	const currentDate = new Date();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		Api.get(`provasCorrecao`).then((res) => {
			setLivesOptions(res);
		});
	}, []);

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			<Helmet>
				<title>Live de corrreção</title>
				<meta
					name="description"
					content={''}
				/>
			</Helmet>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className="residencia"
			>
				{matchesMobile || matchesTablet ? null : <Header />}
			</Container>
			<Grid
				container
				alignContent={'center'}
				style={matchesMobile ? { marginTop: 80 } : null}
			>
				<video
					src={process.env.PUBLIC_URL + '/Assets/bg-video-ldl.mp4'}
					autoPlay
					loop
					muted
					playsInline
					className={classes.videoBackground}
				/>
				<Grid
					item
					xs={12}
					container
					alignContent={'center'}
					justifyContent={'center'}
					className={`${classes.gridLogoContainer} residencia`}
					rowGap={1}
				>
					<img
						src={
							matchesMobile
								? require('../../Assets/Extensivo/R1/2023/logo-correction-live.svg').default
								: require('../../Assets/LDL/logo-correcao.svg').default
						}
						alt=""
						className={classes.ldlLogo}
						style={matchesMobile ? { margin: '1rem 0', width: '72%' } : null}
					/>
					<Grid
						item
						xs={12}
						container
						alignContent={'center'}
						justifyContent={'center'}
						style={{
							padding: matchesMobile ? '1rem' : '1rem 0',
							border: '1px solid #FF005D',
							borderLeft: 'unset',
							borderRight: 'unset',
							margin: '1rem 0',
							background:
								'linear-gradient(90deg, rgba(19,19,19,0) 0%, rgba(15,15,15,1) 20%, rgba(15,15,15,1) 80%, rgba(19,19,19,0) 100%)',
						}}
					>
						<Typography
							color="secondary"
							className="poppins"
							style={{ fontSize: matchesMobile ? '1rem' : '2rem', fontWeight: 'bold' }}
						>
							CORREÇÃO AO VIVO ESPECÍFICA PARA CADA PROVA DE RESIDÊNCIA
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					container
					className="section"
					style={{ margin: matchesMobile ? '60px auto 80px' : '20px auto 80px' }}
				>
					<Grid
						item
						xs={12}
						container
						justifyContent={'space-between'}
						rowGap={2}
					>
						{livesOptions.map((item, index) => {
							const correctionDate = new Date(item?.data_correcao);
							const timeDifference = correctionDate.getTime() - currentDate.getTime();

							// 1 dia possui 24 horas e 1 hora possui 3600000 milissegundos
							const horasRestantes = timeDifference / 3600000;

							return currentDate.getTime() >= correctionDate.getTime() || horasRestantes < 24 ? (
								<Grid
									item
									xs={12}
									md={5.8}
									className={classes.borderRed}
								>
									<CardLiveDasLives
										id={item?.id}
										key={index}
										test={item?.nome}
										contest={item.contest}
										time={item.data_correcao}
										matchesMobile={matchesMobile}
									/>
								</Grid>
							) : null;
						})}
					</Grid>
					<Grid
						item
						xs={12}
						container
						justifyContent={'space-between'}
						alignItems={'center'}
						wrap="nowrap"
						style={{ padding: '2.5rem 0' }}
					>
						<Divider className={classes.divider} />
						<Typography
							color="secondary"
							className="poppins"
							style={{ fontSize: matchesMobile ? '1rem' : '2rem', fontWeight: '600' }}
						>
							EM BREVE
						</Typography>
						<Divider className={classes.divider} />
					</Grid>
					<Grid
						item
						xs={12}
						container
						justifyContent={'space-between'}
						rowGap={2}
					>
						{livesOptions.map((item, index) => {
							const correctionDate = new Date(item?.data_correcao);
							const timeDifference = correctionDate.getTime() - currentDate.getTime();

							// 1 dia possui 24 horas e 1 hora possui 3600000 milissegundos
							const horasRestantes = timeDifference / 3600000;

							return (
								correctionDate.getTime() >= currentDate.getTime() &&
								horasRestantes > 24 && (
									<Grid
										item
										xs={12}
										md={5.8}
									>
										<CardLiveDasLives
											shortly={item?.shortly}
											id={item?.id}
											key={index}
											test={item?.nome}
											contest={item.contest}
											logo={item.logo}
											time={item.data_correcao}
											url={item.url}
											matchesMobile={matchesMobile}
										/>
									</Grid>
								)
							);
						})}
					</Grid>

					{/* <Grid
						item
						xs={12}
						container
						justifyContent={'space-between'}
						alignItems={'center'}
						wrap="nowrap"
						style={{ padding: '2.5rem 0' }}
					>
						<Divider className={classes.divider} />
						<Typography
							color="secondary"
							className="poppins"
							style={{ fontSize: matchesMobile ? '1rem' : '2rem', fontWeight: '600' }}
						>
							REALIZADAS
						</Typography>
						<Divider className={classes.divider} />
					</Grid> */}
					{/* <Grid
						item
						xs={12}
						container
						justifyContent={'space-between'}
						rowGap={4}
					>
						{livesOptions.map((item, index) => {
							return !item.active ? (
								<Grid
									item
									xs={12}
									md={5.8}
								>
									<CardLiveDasLives
										key={index}
										contest={item.contest}
										logo={item.logo}
										classes={classes}
										matchesMobile={matchesMobile}
									/>
								</Grid>
							) : null;
						})}
					</Grid> */}
				</Grid>
			</Container>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	videoBackground: {
		objectFit: 'cover',
		height: 676,
		width: '100%',
		[theme.breakpoints.down('xl')]: {
			height: 480,
		},
		[theme.breakpoints.down('md')]: {
			height: 332,
		},
		[theme.breakpoints.down('sm')]: {
			height: 283,
		},
	},
	ldlLogo: {
		alignSelf: 'center',
		[theme.breakpoints.down(1509)]: {
			width: '36%',
		},
		[theme.breakpoints.down('sm')]: {
			alignSelf: 'center',
			width: '62%',
		},
	},
	gridLogoContainer: {
		position: 'absolute',
		height: '100%',
		[theme.breakpoints.down('sm')]: {
			top: 50,
		},
	},
	rootCard: {
		display: 'flex !important',
		padding: 0,
	},
	dateText: {
		fontSize: '1.6rem !important',
		fontWeight: 'bold !important',
		lineHeight: '0.9 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
			marginTop: '0.37rem !important',
		},
	},
	monthDateText: {
		fontSize: '1.6rem !important',
		fontWeight: 'bold !important',
		lineHeight: '0.9 !important',
		marginTop: '1rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
			marginTop: '0.37rem !important',
		},
	},

	copyrightText: {
		color: '#ffffff !important',
		fontSize: '2rem !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.6rem !important',
			marginTop: '0.37rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.65rem !important',
		},
	},
	gridBackground: {
		backgroundSize: 'cover',
		padding: '0.8rem 1rem 0.5rem',
		height: '100%',
		width: '100%',
		borderTopRightRadius: 20,
		borderBottomRightRadius: 20,
		[theme.breakpoints.down('sm')]: {
			padding: '1.5rem 0.5rem 1rem',
		},
	},
	gridInformation: {
		backgroundColor: '#000000bf',
		height: 45,
		width: '100% !important',
		borderRadius: '10px',
		padding: '0.5rem 1rem',
		marginTop: '1rem !important',
		[theme.breakpoints.down('sm')]: {
			padding: '0.5rem',
		},
	},
	subscribeBtn: {
		width: '30% !important',
		padding: 'unset !important',
		transition: 'ease 0.2s !important',
		alignSelf: 'center',
		border: '1px transparent !important',
		fontWeight: 'bold !important',
		fontSize: '0.95rem !important',
		'&:hover': {
			width: '35% !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.75rem !important',
			width: '35% !important',
		},
		[theme.breakpoints.down(376)]: {
			fontSize: '0.7rem !important',
		},
		[theme.breakpoints.down(361)]: {
			width: '37% !important',
		},
	},
	lotGrid: {
		borderRadius: '10px',
		padding: 2,
		[theme.breakpoints.down('sm')]: {
			padding: '0.3rem',
			fontSize: '0.5rem !important',
		},
	},

	modeText: {
		alignSelf: 'center',
		fontSize: '1.25rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.75rem !important',
		},
		[theme.breakpoints.down(376)]: {
			fontSize: '0.7rem !important',
		},
		[theme.breakpoints.down(361)]: {
			fontSize: '0.65rem !important',
		},
	},
	lotText: {
		alignSelf: 'center',
		fontSize: '1rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.75rem !important',
		},
	},
	divider: {
		alignSelf: 'center',
		backgroundColor: '#ffffff',
		minHeight: 1,
		width: '43%',
		[theme.breakpoints.down('sm')]: {
			margin: '1rem 0 !important',
			width: '35%',
		},
	},
	borderGrid: {
		width: '2.5%',
		borderTopLeftRadius: 15,
		borderBottomLeftRadius: 15,
		[theme.breakpoints.down('sm')]: {
			width: '5%',
		},
	},
	containerWaitList: {
		// width: '100% !important',
		backgroundColor: '#d6cece',
		zIndex: 1,
		borderRadius: 15,
		padding: '0.5rem 1.25rem',
		boxShadow: '0px 0px 30px 0px #000000',
		border: '2px solid #000000',
		// position: 'absolute',
		// bottom: 0,
		[theme.breakpoints.down('sm')]: {
			padding: '1rem 1.25rem',
		},
	},
	textWaitList: {
		fontSize: '1rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.95rem !important',
		},
	},
	form: {
		borderRadius: '8px !important',
		backgroundColor: '#E1E1E1 !important',
		margin: '5px 0 !important',
		'& .MuiInputBase-input': {
			padding: '0.5rem !important',
		},
	},
	formHeight: {
		maxHeight: '2.5em',
	},
	btnWaitList: {
		fontSize: '0.9rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		backgroundColor: '#00AB55 !important',
		border: '1px solid #707070 !important',
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				// minWidth: '72% !important',
				// fontSize: '1.025rem !important',
				// borderWidth: '3px !important',
				backgroundColor: '#00543b !important',
				transition: '0.1s',
			},
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	cancelIcon: {
		color: '#000000',
		right: '-15px',
		top: '-5px',
		'&:hover': {
			cursor: 'pointer',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'absolute',
			top: '-15px',
			right: '-18px',
		},
	},
	phoneNumberForm: {
		height: '2.5em !important',
		borderRadius: 8,
		width: '100%',
		border: 'unset',
	},
}));
