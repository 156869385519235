import React from 'react';
import { Container, Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import { Header } from '../../../../Components/Header';
import { useEffect } from 'react';
import Api from '../../../../Services/Api';
import { useState } from 'react';

const PdfCard = ({ pdfUrl, classes, index, examYear, examBoard, objectiveQuestions, discursiveQuestions }) => {
	// Array com os caminhos das imagens de fundo
	const backgroundImagePaths = [
		require('./Assets/bg-test-card-1.png'),
		require('./Assets/bg-test-card-2.png'),
		require('./Assets/bg-test-card-3.png'),
		require('./Assets/bg-test-card-4.png'),
		require('./Assets/bg-test-card-5.png'),
	];

	// Obtém o índice válido para o array de caminhos
	const backgroundImageIndex = index % backgroundImagePaths.length;

	return (
		<Paper
			elevation={12}
			style={{
				width: '100%',
				backgroundImage: `url(${backgroundImagePaths[backgroundImageIndex]})`,
				backgroundSize: 'cover',
				borderRadius: 25,
				cursor: 'pointer',
			}}
		>
			<a
				href={pdfUrl}
				download // Adiciona o atributo "download" para o download direto do PDF
				style={{ textDecoration: 'none', color: 'inherit' }} // Estilo para remover sublinhado do link
			>
				<Grid
					container
					justifyContent={'space-between'}
					className={classes.mainGridPdfCard}
				>
					<Grid
						item
						xs={8}
						md={9}
						container
						direction={'column'}
						gap={1}
					>
						<Typography
							color="secondary"
							className={`${classes.yearText} poppins`}
						>
							{examYear}
						</Typography>
						<Grid
							item
							container
							className={classes.gridInfomation}
							gap={1}
						>
							<img
								src={require('./Assets/icon-banca.svg').default}
								alt=""
								className={classes.imgPdfCard}
							/>
							<Typography
								color="primary"
								className={`${classes.infoPdfCard} poppins`}
							>
								Banca {examBoard}
							</Typography>
						</Grid>
						{objectiveQuestions !== 0 && (
							<Grid
								item
								container
								className={classes.gridInfomation}
								gap={1}
							>
								<img
									src={require('./Assets/icon-question.svg').default}
									alt=""
									className={classes.imgPdfCard}
								/>
								<Typography
									color="primary"
									className={`${classes.infoPdfCard} poppins`}
								>
									{objectiveQuestions} objetivas
								</Typography>
							</Grid>
						)}
						{discursiveQuestions !== 0 && (
							<Grid
								item
								container
								className={classes.gridInfomation}
								gap={1}
							>
								<img
									src={require('./Assets/icon-question.svg').default}
									alt=""
									className={classes.imgPdfCard}
								/>
								<Typography
									color="primary"
									className={`${classes.infoPdfCard} poppins`}
								>
									{discursiveQuestions} discursivas
								</Typography>
							</Grid>
						)}
					</Grid>
					<Grid
						item
						xs={4}
						md={3}
						container
						alignContent={'center'}
						className={classes.gridPdf}
					>
						<Grid
							item
							container
							direction={'column'}
							justifyContent={'center'}
							alignContent={'center'}
							style={{ backgroundColor: '#ffffffcc', padding: '0.75rem 0.5rem', borderRadius: 15 }}
							gap={0.5}
						>
							<img
								src={require('./Assets/icon-download.svg').default}
								alt=""
								className={classes.pdfImg}
							/>
							<Typography
								color="primary"
								className={`${classes.downloadText} poppins`}
							>
								Download
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</a>
		</Paper>
	);
};

export default function TestsTemplatePage() {
	const classes = useStyles();
	const theme = useTheme();
	const [institutionTests, setInstitutionTests] = useState([]);
	const [code, setCode] = useState([]);
	// const { code } = useParams();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		const institutionCode = getCodeFromURL();
		setCode(institutionCode);

		Api.get(`/provasPDF/${institutionCode}`).then((res) => {
			if (res) {
				// console.log(res);
				setInstitutionTests(res);
			}
		});
	}, []);

	function getCodeFromURL() {
		const path = window.location.pathname;
		const regex = /\/materiais\/cadernos-prova-(.*?)-download/;
		const match = path.match(regex);

		if (match && match[1]) {
			return match[1].toUpperCase(); // Retorna o código em letras maiúsculas
		}

		return null; // Retorna null caso o código não seja encontrado
	}

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					className={`${classes.sectionGrid} section residencia`}
				>
					<Typography
						color="secondary"
						className={`${classes.title} roboto-condensed`}
					>
						Provas de residência médica da{' '}
						<strong
							className="gradient-text"
							style={{ display: 'inline' }}
						>
							{code === 'USP' ? code + '-SP' : decodeURI(code).replace(/\+/g, ' ').toUpperCase()}
						</strong>
					</Typography>
					<Grid
						item
						container
						rowGap={3}
						style={{ marginTop: '1rem' }}
					>
						{institutionTests.map((item, index) => {
							return (
								<Grid
									item
									xs={12}
									md={6}
									container
									style={
										index === 1 || index === 3
											? { paddingLeft: !matchesMobile ? '1rem' : 'unset' }
											: { paddingRight: !matchesMobile ? '1rem' : 'unset' }
									}
								>
									<PdfCard
										pdfUrl={item.prova_pdf}
										classes={classes}
										index={index}
										examYear={item.ano}
										examBoard={item.banca}
										objectiveQuestions={item.qtd_questoes}
										discursiveQuestions={item.qtd_questoes_disc}
									/>
								</Grid>
							);
						})}
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	sectionGrid: {
		[theme.breakpoints.down('sm')]: {
			margin: '80px 0 !important',
		},
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.25rem !important',
		lineHeight: '1 !important',
		marginBottom: '2rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '3.2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.8rem !important',
			marginBottom: '1rem !important',
		},
	},
	text: {
		fontSize: '1.324rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
			marginBottom: '1rem !important',
		},
	},
	gridInfomation: {
		backgroundColor: '#ffffffcc',
		width: 'fit-content !important',
		padding: '2.5px 1.5rem !important',
		borderRadius: 15,
		[theme.breakpoints.down('sm')]: {
			padding: '2.5px 1rem !important',
		},
	},
	mainGridPdfCard: {
		padding: '1.5rem 2rem !important',
		[theme.breakpoints.down('sm')]: {
			padding: '0.5rem 1rem !important',
		},
	},
	imgPdfCard: {
		scale: '1.2',
		[theme.breakpoints.down('sm')]: {
			scale: 'unset',
		},
	},
	infoPdfCard: {
		fontSize: '0.85rem !important',
		fontWeight: 500,
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.7rem !important',
		},
	},
	gridPdf: {
		padding: '0.5rem 1rem !important',
		height: 'fit-content',
		alignSelf: 'center !important',
		[theme.breakpoints.down('sm')]: {
			padding: '0.5rem !important',
		},
	},
	pdfImg: {
		[theme.breakpoints.down('sm')]: {
			width: '50%',
			alignSelf: 'center',
		},
	},
	downloadText: {
		fontWeight: '500 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.7rem !important',
		},
	},
	yearText: {
		fontSize: '4rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		textShadow: '2px 2px 4px #000000',
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem !important',
		},
	},
}));
