import { Container, useTheme, useMediaQuery, Grid, Typography } from '@mui/material';
import { cardsData, cardsTeachers, resume } from './components/mockR3.js';
import React, { useRef } from 'react';

//Components

import BoxResumeSprint from '../SprintFinal/components/BoxResumeSprint';
//import NotebookSlider from '../componentes/NotebookSlider';
import PaymentCard from '../componentes/PaymentCard';
import { Header } from '../../../../Components/Header';
import { FirstSectionSaleR3 } from './components/FirstSectionSaleR3';
//import Form from '../../../../Components/Utils/Forms/PreRegistration';

import Footer from '../../../../Components/Footer';
import BoxTitle from '../SprintFinal/components/BoxTitle';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import SliderTeacher from '../componentes/SliderTeacher';
import CounterDown from '../componentes/CounterDown';
import CarouselWithButton from '../componentes/CarouselWithButton.jsx';

//Assets
import logo from '../R3/assets/logo-hwq-cg.svg';
import imgMobile from '../R3/assets/img-hwq-cg-mobile.png';
import logoUsp from '../R3/assets/logo-cg-usp.png';
import logoEnare from '../R3/assets/logo-cg-enare.png';
import logoUspEnare from '../R3/assets/logo-cg-usp-enare.png';

const R3CirurgiaGeral = () => {
	const theme = useTheme();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const paymentSectionRef = useRef(null);

	const scrollToPayment = () => {
		if (paymentSectionRef.current) {
			paymentSectionRef.current.scrollIntoView({
				behavior: 'smooth',
				block: matchesTablet ? 'start' : 'center',
			});
		}
	};

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
			style={{
				backgroundColor: '#F5F5F5',
			}}
		>
			{matchesMobile ? null : matchesTablet ? <Header whitePattern /> : null}
			{matchesMobile || matchesTablet ? null : <Header whitePattern />}
			<Container
				maxWidth={false}
				disableGutters={true}
			>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					<Grid
						container
						justifyContent={'center'}
					>
						<FirstSectionSaleR3
							firstParagraph={'Hora de se aprofundar nos detalhes dos temas cirúrgicos da prova de R+.'}
							secondParagraph={
								<span>
									{' '}
									<b>ENARE, USP, ou ambas</b>? Que prova de R+ Cirurgia você vai dominar junto com o
									Hardwork?
								</span>
							}
							logoImg={logo}
							imageApp={imgMobile}
							scrollToPayment={scrollToPayment}
						/>
						<Grid
							container
							justifyContent={'center'}
							alignContent={'center'}
							paddingLeft={matchesMobile ? 0 : 5}
							marginBottom={10}
						>
							<BoxTitle title={'O que é o  HardworQ R+ Cirurgia? '}>
								<Grid
									item
									lg={11}
									sm={11}
									style={{ borderRadius: '10px' }}
								>
									<Splide
										options={{
											arrows: false,
											autoplay: true,
											type: 'loop',
											speed: 0.25,
											rewind: true,
										}}
									>
										{cardsData.map((item, index) => {
											return (
												<SplideSlide
													key={index}
													style={{
														display: 'flex',
														justifyContent: matchesMobile ? 'center' : 'end',
													}}
												>
													<img
														src={matchesMobile ? item?.bgMobile : item?.bg}
														alt=""
														style={{
															width: '100%',

															borderRadius: matchesMobile ? '0' : '36px',
														}}
													/>
													<Grid
														style={{
															position: 'absolute',
															top: matchesMobile ? 16 : 0,
															left: matchesMobile ? 40 : 100,
															width: matchesMobile ? '80%' : '50%',
															height: '100%',
															display: 'flex',
															flexDirection: 'column',
															justifyContent: 'center',
															alignItems: 'start',
															color: 'white',
															textAlign: 'start',
															padding: matchesMobile ? '0' : '35px',
														}}
													>
														<Typography
															fontFamily={'Manrope'}
															style={{
																marginBottom: '10px',
																fontWeight: 'bold',
																textAlign: 'start',
																fontSize: matchesMobile ? '21px' : null,
															}}
														>
															{item.title}
														</Typography>
														<Typography
															fontFamily={'Manrope'}
															variant="body1"
															style={{
																textAlign: 'start',
																fontSize: matchesMobile ? '16px' : null,
															}}
														>
															{item.text}
														</Typography>
													</Grid>
												</SplideSlide>
											);
										})}
									</Splide>
								</Grid>
							</BoxTitle>
						</Grid>
					</Grid>
				</Container>
			</Container>
			<Container
				maxWidth={false}
				disableGutters={true}
				style={{
					backgroundColor: '#EBEBEB',
				}}
			>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
					style={{ display: 'flex' }}
				>
					<Grid
						container
						justifyContent="start"
						alignItems="start"
						className="section"
						paddingLeft={matchesMobile ? 1 : 5}
						style={{
							paddingBottom: '2vh',
						}}
					>
						<BoxTitle title={'O que você terá no HardworQ R+ Cirurgia?'}>
							<Grid
								container
								spacing={1}
							>
								{resume.map((item, index) => (
									<Grid
										item
										xs={12}
										sm={6}
										md={4}
										key={index}
									>
										<BoxResumeSprint
											icon={item.icon}
											title={item.title}
											text={item.text}
										/>
									</Grid>
								))}
							</Grid>
						</BoxTitle>
					</Grid>
				</Container>
			</Container>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				style={{ display: 'flex', paddingTop: '30px' }}
			>
				<Grid
					item
					container
					paddingLeft={matchesMobile ? 1 : 5}
					ref={paymentSectionRef}
					marginBottom={10}
				>
					<BoxTitle
						title={'Qual prova de R+ Cirurgia quer dominar?'}
						percent
					>
						<Grid
							item
							container
							justifyContent={'space-between'}
							alignItems={'center'}
							flexDirection={matchesMobile ? 'column' : 'row'}
							flexWrap={'wrap'}
							columnGap={matchesMobile ? 5 : 3}
							padding={0}
							style={{ height: matchesTablet && 'fit-content' }}
						>
							{/* <NotebookSlider /> */}
							<Grid
								item
								xs={12}
								lg={3.7}
								container
								style={{ height: '100%' }}
							>
								<PaymentCard
									product={'R+ Cirurgia'}
									productColor={'gradient-text-cirurgia-default'}
									price={3990}
									logo={logoUsp}
									idTurma={'1353'}
									institutionName={'USP'}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								lg={3.7}
								container
								style={{ height: '100%' }}
							>
								<PaymentCard
									product={'R+ Cirurgia'}
									productColor={'gradient-text-cirurgia-default'}
									price={3900}
									logo={logoEnare}
									idTurma={'1369'}
									institutionName={'ENARE'}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								lg={3.7}
								container
								style={{ height: '100%' }}
							>
								<PaymentCard
									product={'R+ Cirurgia'}
									productColor={'gradient-text-cirurgia-default'}
									logo={logoUspEnare}
									price={4490}
									priceParcel={3490}
									shadow={'0px 0px 20px 0px rgba(55, 186, 221, 0.60)'}
									idTurma={'1353,1369'}
									institutionName={'USP + ENARE'}
								/>
							</Grid>
							{/* <Grid
								item
								xs
							>
								<Form
									group={'HARDWORQ R3 CIRURGIA GERAL 2024'}
									title={'Inscreva-se para ser avisado!'}
									titleButton={'Confirmar inscrição'}
									selectContest
									type={'sprint-gray'}
									urlToRedirect={'https://home.hardworkmedicina.com.br/hardworq/cirurgia'}
									confirmationMessage={'Sua pré-inscrição está confirmada!'}
								/>
							</Grid> */}
						</Grid>
					</BoxTitle>
				</Grid>
			</Container>

			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				style={{ display: 'flex', paddingTop: '30px' }}
			>
				<Grid
					item
					container
					paddingLeft={matchesMobile ? 0 : 1}
					marginBottom={2}
				>
					<BoxTitle title={'Quem vai te mostrar os segredos do R+ Cirurgia'} />
					<Grid
						item
						container
						paddingLeft={matchesMobile ? 0 : 1}
					>
						<CarouselWithButton isLoading={false}>
							{cardsTeachers.map((item, index) => (
								<div
									key={index}
									style={{ margin: '0 1rem' }}
								>
									<SliderTeacher
										background={matchesMobile ? item.bgMobile : item.bg}
										videoUrl={item.videoUrl}
										title={item.title}
										subtitle={item.subtitle}
									/>
								</div>
							))}
						</CarouselWithButton>
					</Grid>
				</Grid>
			</Container>
			<CounterDown
				date={'2024-08-16T23:59:00-03:00'}
				text={'Domine duas provas por um valor promocional!'}
				textMobile={'Domine duas provas por um valor promocional!'}
				scrollToPayment={scrollToPayment}
				colorBgButton={'linear-gradient(304deg, #36BADC 20.08%, #5B69E5 79.92%)'}
				colorStartButton={'#36BADC'}
				colorEndButton={'#5B69E5'}
			/>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					{matchesTablet ? null : (
						<Footer
							noMarginBottom
							whitePattern
						/>
					)}
				</Container>
			</Container>
			{matchesTablet ? (
				<Footer
					noMarginBottom
					whitePattern
				/>
			) : null}
		</Container>
	);
};

export default R3CirurgiaGeral;
