import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import { Header } from '../../../../Components/Header';
import Form from '../../../../Components/Utils/Forms/PreRegistration';
import Api from '../../../../Services/Api';

export default function Live261023DiscussionExam() {
	const classes = useStyles();
	const theme = useTheme();
	const [nextLive, setNextLive] = useState('2023-10-26T14:30:00');

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		Api.get('livesDiscussao').then((res) => {
			if (res && res.length >= 1) {
				const currentDate = new Date();
				for (let i = 0; i < res.length; i++) {
					const liveDate = new Date(res[i].data);
					if (liveDate > currentDate) {
						setNextLive(res[i].data);
						break; // Encontrou a próxima live, não é necessário continuar a busca
					}
				}
			}
		});
	}, []);

	// useEffect(() => {
	// 	console.log(dateFormatWithTime(nextLive));
	// }, [nextLive]);

	function dateFormatWithTime(date) {
		const data = new Date(date);
		const diasDaSemana = [
			'DOMINGO',
			'SEGUNDA-FEIRA',
			'TERÇA-FEIRA',
			'QUARTA-FEIRA',
			'QUINTA-FEIRA',
			'SEXTA-FEIRA',
			'SÁBADO',
		];
		const diaDaSemana = diasDaSemana[data.getDay()];
		const dia = data.getDate();
		const mesesEmPortugues = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];
		const mes = mesesEmPortugues[data.getMonth()];
		const hora = data.getHours();

		// Formate o dia para adicionar um "0" à esquerda, se for menor que 10
		const diaFormatado = dia < 10 ? `0${dia}` : dia;

		// Formate a hora para adicionar um "0" à esquerda, se for menor que 10
		const horaFormatada = hora < 10 ? `0${hora}` : hora;

		// Crie a string final no formato "dia da semana • dia mes • hora"
		const dataHoraFormatada = `${diaDaSemana} • ${diaFormatado}${mes} • ${horaFormatada}h`;

		return dataHoraFormatada;
	}

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
			</Container>
			<Grid container>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					<Grid
						container
						justifyContent={'space-between'}
						alignContent="center"
						className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
						style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
					>
						<Grid
							item
							xs={12}
							md={7}
							container
							justifyContent={'flex-start'}
							className={`${classes.gridTextContainer} revalida`}
							gap={3}
							style={{ height: 'fit-content', paddingRight: matchesTablet ? null : '3rem' }}
						>
							<Grid
								item
								container
							>
								<Grid
									item
									container
									wrap={matchesTablet ? 'wrap' : 'nowrap'}
									className="revalida"
									columnGap={1}
								>
									<img
										src={require('../../../../Assets/Arena/logo-live-revalida.svg').default}
										alt=""
										style={matchesMobile ? { width: '25%' } : { width: '10%' }}
									/>
									<Typography
										className={`${classes.title} gradient-text roboto-condensed`}
										color="secondary"
									>
										{matchesTablet
											? 'Discussão de estações da prova prática do Revalida INEP'
											: 'Discussão de estações da'}
									</Typography>
								</Grid>
								{!matchesTablet && (
									<Grid
										item
										container
										wrap={matchesTablet ? 'wrap' : 'nowrap'}
										className="revalida"
									>
										<Typography
											className={`${classes.title} gradient-text roboto-condensed`}
											color="secondary"
										>
											prova prática do Revalida INEP
										</Typography>
									</Grid>
								)}
							</Grid>
							<Typography
								className={`${classes.text} poppins`}
								color="secondary"
								style={{ marginBottom: matchesMobile ? null : '1rem' }}
							>
								Vamos dissecar contigo as estações antigas do INEP. Serão várias lives de discussão
								intensa até a data da prova! As inscrições são gratuitas.
							</Typography>
							<Grid
								item
								container
								direction={'column'}
								rowGap={matchesTablet ? 1 : 0}
								style={{ width: 'fit-content' }}
							>
								<Typography
									className={`${classes.text} poppins`}
									color="secondary"
									style={{ marginBottom: matchesMobile ? null : '1rem' }}
								>
									A próxima será:
								</Typography>
								<Typography
									align="left"
									color="secondary"
									className={`${classes.impactText} poppins`}
								>
									{dateFormatWithTime(nextLive)}
								</Typography>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							md={5}
							container
							justifyContent="center"
							className={classes.gridWithBackgroundImage}
							gap={matchesTablet ? 4 : 0}
						>
							<Grid
								item
								xs={12}
								className={classes.gridForm}
								gap={1}
							>
								<Form
									group="LIVE ESTAÇÕES REVALIDA INEP"
									title={['Inscreva-se para conseguir', <br />, 'participar da live']}
									titleButton="Confirmar Inscrição"
									type="revalida"
									urlToRedirect="/live-discussao-revalida-inep"
									titleConfirmationMessage={'Inscrição confirmada!'}
									confirmationMessage={
										'Tu serás avisado pelo email cadastrado momentos antes da live.'
									}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</Grid>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesTablet ? null : <Footer noMarginBottom />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto 0',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.2rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
			marginBottom: '8px !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem !important',
			marginBottom: 'unset !important',
		},
	},
	text: {
		fontSize: '1.325rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	gridForm: {
		width: 567,
		flexDirection: 'column !important',
		alignItems: 'center !important',
		[theme.breakpoints.down('xl')]: {
			bottom: 20,
		},
		[theme.breakpoints.down(1300)]: {
			width: 467,
		},
		[theme.breakpoints.down('md')]: {
			position: 'unset',
			right: 'unset',
			top: 'unset',
			zIndex: 0,
			marginBottom: '1.5rem !important',
		},
	},
	gridTextContainer: {
		[theme.breakpoints.down('lg')]: {
			paddingRight: '2em',
		},
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
	},
	gridWithBackgroundImage: {
		padding: '1rem 2rem 2.5rem',
		marginBottom: '2rem !important',
		[theme.breakpoints.down('lg')]: {
			padding: 'unset',
		},
		[theme.breakpoints.down('md')]: {
			backgroundImage: 'unset',
			marginBottom: 'unset !important',
		},
	},
	impactText: {
		margin: '0 0 2rem !important',
		fontSize: '2.25rem !important',
		lineHeight: 'unset !important',
		background: 'linear-gradient(90deg, rgba(0,145,166,1) 10%, rgba(0,194,110,1) 100%)',
		textShadow: '0 0 0.2em #121213, 0 0 0.2em #0c0c0d',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.75rem !important',
		},
		[theme.breakpoints.down('md')]: {
			marginBottom: '2.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.35rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.35rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1.35rem !important',
		},
	},
	logo: {
		marginBottom: '1.5rem',
		[theme.breakpoints.down('xl')]: {
			marginBottom: '2.5rem',
		},
		[theme.breakpoints.down('md')]: {
			marginBottom: '1rem',
		},
	},
}));
