import React, { useEffect, useState } from 'react';
import { Container, Divider, Grid, Modal, Paper, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { makeStyles } from '@mui/styles';
import { trackPromise } from 'react-promise-tracker';
import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import IntlTelInput from 'react-intl-tel-input';
import Swal from 'sweetalert2';

// COMPONENTS
import Footer from '../../Components/Footer';
import { Header } from '../../Components/Header';
import ExtensivoR1Card from '../../Components/Cards/ExtensivoR1Card';
import FirstSection from '../../Components/Sections/FirstSection';
import StudentsFeedbackSection from '../../Components/Sections/StudentsFeedback';
import DefaultButton from '../../Components/Buttons/DefaultButton';

// ASSETS
// import BgWave2 from '../../Assets/Img/bgWave2.png';
import bgTrilhaExpress from '../../Assets/Extensivo/R1/Express/bg-trilha-r1-express.png';
import logoTrilhaExpress from '../../Assets/Extensivo/R1/Express/logo-r1-express.svg';
import logoCard from '../../Assets/Extensivo/R1/Express/logo-express-all-sprints.png';
import logoCardUm from '../../Assets/Extensivo/R1/Express/logo-express-um-sprints.png';
import logoTrilhaR1 from '../../Assets/Extensivo/R1/Express/logo-trilha-r1-residencia.png';
import SprintFinalMobile from '../../Assets/SprintFinal/sprintFinalMobile.svg';
import SprintHwmLogoSection from '../../Assets/SprintFinal/sprintHwmLogoSection.svg';
import BgPlusCard from '../../Assets/Extensivo/R1/Express/bg-card-r1.png';
import SalasEstudo from '../../Assets/Extensivo/R1/Express/salas-de-estudo.png';
import Prioridade from '../../Assets/Extensivo/R1/Express/prioridade.png';
import LogoEnare from '../../Assets/Extensivo/R1/Express/logo-enare-new.png';

//Diferentials Assets
import lessionsIcon from '../../Assets/Extensivo/R1/2023-24/lessionsIcon.svg';
import iconResume from '../../Assets/Extensivo/R1/2023-24/iconResume.svg';
import iconQuestions from '../../Assets/Extensivo/R1/2023-24/iconQuestions.svg';
import iconTalkWithProfessor from '../../Assets/Extensivo/R1/2023-24/iconTalkWithProfessor.svg';
import iconLivesBank from '../../Assets/Extensivo/R1/2023-24/iconLivesBank.svg';
import iconBooks from '../../Assets/Extensivo/R1/2023-24/iconBooks.svg';
import iconMetrics from '../../Assets/Extensivo/R1/2023-24/iconMetrics.svg';

// SF PROVAS
import AmrigsLogo from '../../Assets/SprintFinal/amrigsLogo.svg';
import EinsteinLogo from '../../Assets/SprintFinal/einsteinLogo.svg';
import EnareLogo from '../../Assets/SprintFinal/enareLogo.svg';
import IamspeLogo from '../../Assets/SprintFinal/iamspeLogo.svg';
import FacMedicinaJundiaiLogo from '../../Assets/SprintFinal/facMedicinaJundiaiLogo.svg';
import PsuMgLogo from '../../Assets/SprintFinal/psuMgLogo.svg';
import SantaCasaLogo from '../../Assets/SprintFinal/santaCasaSpLogo.svg';
import SesDfLogo from '../../Assets/SprintFinal/sesDfLogo.svg';
import SesPeLogo from '../../Assets/SprintFinal/sesPeLogo.svg';
import UfrjLogo from '../../Assets/SprintFinal/ufrjLogo.svg';
import AbcLogo from '../../Assets/SprintFinal/abcLogo.svg';
import SirioLibanesLogo from '../../Assets/SprintFinal/sirioLibanesLogo.svg';
import SurceLogo from '../../Assets/SprintFinal/surceLogo.svg';
import SusBaLogo from '../../Assets/SprintFinal/susBaLogo.svg';
import UnicampLogo from '../../Assets/SprintFinal/unicampLogo.svg';
import SusSpLogo from '../../Assets/SprintFinal/susSpLogo.svg';
import UnespBotucatuLogo from '../../Assets/SprintFinal/unespBotucatuLogo.svg';
import AmpLogo from '../../Assets/SprintFinal/ampLogo.svg';
import UnifespLogo from '../../Assets/SprintFinal/unifestpLogo.svg';
import UspLogo from '../../Assets/SprintFinal/uspLogo.svg';
import UspRibeiraoLogo from '../../Assets/SprintFinal/uspRibeiraoLogo.svg';
import FamemaLogo from '../../Assets/SprintFinal/famemaLogo.svg';
import UerjLogo from '../../Assets/SprintFinal/uerjLogo.svg';
import UfesLogo from '../../Assets/SprintFinal/ufesLogo.svg';
import PsugoLogo from '../../Assets/SprintFinal/psugoLogo.svg';

// SERVICES
import Api from '../../Services/Api';
import { Helmet } from 'react-helmet';
import LearningVersion02 from '../../Components/Sections/LearningVersion02';
import DiferencialBoxV2 from '../../Components/Cards/DiferencialBoxV2';
import TestCardOpen from '../../Components/Cards/TestCardOpen';

const learningPathTemplate = [
	{
		title: 'Extensivo Hardwork',
		description: [
			'A trilha do aprendizado funciona por ligas de evolução. Começaremos por temas mais palpáveis, evoluindo para outros mais peculiares.',
			<>
				<br />
				<br />
			</>,
			'São 8 ligas no total, cada uma com temas diferentes. Para passar para a próxima, precisaremos dominar por completo a atual.',
			<>
				<br />
				<br />
			</>,
			'Veja como tudo vai funcionar em cada liga.',
		],
		bgColor: '#ffffff',
		titleColor: '#121212',
		descriptionColor: 'primary',
	},
	{
		title: 'Aula Hardwork',
		description: [
			'Tudo começa com a apresentação ao tema, que no Hardwork é feita com rigor em didática.',
			<>
				<br />
				<br />
			</>,
			'São as melhores aulas que você já viu sobre cada tema.',
		],
		bgColor: '#0D0D0D',
		titleColor: '#FF005D',
		descriptionColor: 'secondary',
		url: 'https://player.vimeo.com/video/571352809',
	},
	{
		title: 'Estudo por questões',
		description: [
			'Alguns dias após a apresentação ao tema, vamos te conduzir pelo estudo por questões.',
			<>
				<br />
				<br />
			</>,
			'Aqui você entenderá todas as formas em que cada parte do tema é explorado nas provas.',
			<>
				<br />
				<br />
			</>,
			'O professor Hardwork te mostrará tudo em detalhes, aprofundando em cada tema.',
		],
		bgColor: '#0D0D0D',
		titleColor: '#FF005D',
		descriptionColor: 'secondary',
	},
	{
		title: 'Revisões cíclicas e personalizadas',
		description: [
			'Questões, questões e questões! É assim que revisamos no Hardwork!',
			<>
				<br />
				<br />
			</>,
			'Toda a dinâmica de revisões é, na verdade, uma continuidade do estudo por questões, explorando com mais ênfase os pontos que você tiver mais dificuldade.',
			<>
				<br />
				<br />
			</>,
			'Todos os temas precisam ser dominados!',
		],
		bgColor: '#0D0D0D',
		titleColor: '#FF005D',
		descriptionColor: 'secondary',
	},
	{
		title: 'Métricas de desempenho',
		description: [
			'A avaliação de desempenho dos temas será diária, usando métricas do estudo por questões.',
			<>
				<br />
				<br />
			</>,
			'Assim que o grau de suficiência em um tema for atingido, ele estará dominado!',
			<>
				<br />
				<br />
			</>,
			'"E se eu tiver dificuldade"? Calma! Siga a trilha...',
		],
		bgColor: '#0D0D0D',
		titleColor: '#FF005D',
		descriptionColor: 'secondary',
	},
	{
		title: ' ',
		description: [' '],
		bgColor: 'transparent',
		titleColor: '#FF005D',
		descriptionColor: 'secondary',
	},
];

const learningPathTemplateTwo = [
	{
		title: 'Sprint Final',
		description: [
			'O Sprint te faz entrar na alma do concurso, na mente do examinador! É o primeiro e único modelo de revisão totalmente focado em cada uma das provas! Tudo que tu precisas na reta final de preparação.',
		],
		bgColor: '#ffffff',
		titleColor: '#121212',
		descriptionColor: 'primary',
		//url: 'https://player.vimeo.com/video/769930065',
	},
	{
		title: 'Mais de 23 Sprints diferentes',
		description: [
			'A Revisão que utiliza modelo ativo de aprendizado e tem revisões cíclicas automáticas e personalizadas, que são preparadas pela plataforma de acordo com a necessidade de cada um de nós.',
			<>
				<br />
				<br />
			</>,
			'Cada um tem o seu próprio SPRINT!',
		],
		bgColor: '#0D0D0D',
		titleColor: '#FF005D',
		descriptionColor: 'secondary',
		//url: 'https://player.vimeo.com/video/769929780',
	},
];

const infoTitlesMaio = [
	// { title: 'Inscrições', info: 'Até 30/04/2023' },
	{
		title: 'Duração de acesso à plataforma',
		info: 'De 15/01/2024 Até 28/02/2025',
		date: calculateMonthsFromToday('2025-02-28'),
	},
	{ title: 'Início do acesso', info: 'Imediato' },
	{ title: 'Início da trilha', info: 'Imediato' },
	{
		title: 'Liberação do Sprint Final',
		info: ['', <br />, 'Acesso garantido a TODOS os Sprints Finais 2024¹ para provas de residência'],
	},
	// { title: 'Acesso ao Sprint', info: 'Até a data da prova' },
];

const infoTitlesExpress = [
	// { title: 'Inscrições', info: 'Até 30/04/2023' },
	{
		title: 'Duração de acesso à plataforma',
		info: 'De 04/06/2024 Até 28/02/2025',
		date: calculateMonthsFromToday('2024-12-31'),
	},
	{ title: 'Início do acesso', info: 'Imediato' },
	{ title: 'Início da trilha', info: 'Imediato' },
	{
		title: 'Liberação do Sprint Final',
		info: ['', <br />, 'Acesso garantido a TODOS os Sprints Finais 2024¹ para provas de residência'],
	},
	// { title: 'Acesso ao Sprint', info: 'Até a data da prova' },
];

const infoTitlesExpressUm = [
	// { title: 'Inscrições', info: 'Até 30/04/2023' },
	{
		title: 'Duração de acesso à plataforma',
		info: 'De 04/06/2024 Até 28/02/2025',
		date: calculateMonthsFromToday('2024-12-31'),
	},
	{ title: 'Início do acesso', info: 'Imediato' },
	{ title: 'Início da trilha', info: 'Imediato' },
	{
		title: 'Liberação do Sprint Final',
		info: ['', <br />, 'Acesso a 1 (um) Sprint Final 2024¹ de sua escolha, para as provas de residência.'],
	},
	// { title: 'Acesso ao Sprint', info: 'Até a data da prova' },
];

const benefitsMaio = [
	{ title: 'Acesso a cerca de 600 blocos de estudo por questões na Trilha do aprendizado' },
	{ title: 'Acesso imediato a mais de 250 aulas Hardwork' },
	{ title: 'Mais de 190 resumos Hardwork' },
	{ title: 'Mais de 100 mil Questões de provas dos principais concursos de residência do Brasil.' },
	{ title: 'Contato direto com os professores.' },
	{ title: 'Acesso ao banco de lives Hardwork, com mais de 200 vídeos de discussão' },
	{ title: [<strong>BÔNUS:</strong>, ' Acesso à Minha Biblioteca™', <br />, '(acesso online a diversos livros)'] },
];

const benefitsExpress = [
	{ title: 'Acesso a cerca de 550 blocos de estudo por questões na Trilha do aprendizado' },
	{ title: 'Acesso imediato a mais de 250 aulas Hardwork' },
	{ title: 'Mais de 190 resumos Hardwork' },
	{ title: 'Mais de 100 mil Questões de provas dos principais concursos de residência do Brasil.' },
	{ title: 'Contato direto com os professores.' },
	{ title: 'Acesso ao banco de lives Hardwork, com mais de 200 vídeos de discussão' },
	{ title: [<strong>BÔNUS:</strong>, ' Acesso à Minha Biblioteca™', <br />, '(acesso online a diversos livros)'] },
];

const benefitsExoressUm = [
	{ title: 'Acesso a cerca de 550 blocos de estudo por questões na Trilha do aprendizado' },
	{ title: 'Acesso imediato a mais de 250 aulas Hardwork' },
	{ title: 'Mais de 190 resumos Hardwork' },
	{ title: 'Mais de 100 mil Questões de provas dos principais concursos de residência do Brasil.' },
	{ title: 'Contato direto com os professores.' },
	{ title: 'Acesso ao banco de lives Hardwork, com mais de 200 vídeos de discussão' },
	{ title: [<strong>BÔNUS:</strong>, ' Acesso à Minha Biblioteca™', <br />, '(acesso online a diversos livros)'] },
];

const aheadMaio = [
	{ title: 'Métricas de desempenho' },
	{ title: 'Salas de Estudo' },
	{ title: 'Simulados personalizados' },
	{ title: 'Flashcards' },
	{ title: 'Acesso ao app HardworQ' },
	{ title: 'Prioridade na inscrição da Live das Lives' },
	{ title: 'Mentorias' },
	{ title: 'Simulados Programados' },
];

const aheadExpress = [
	{ title: 'Métricas de desempenho' },
	{ title: 'Salas de Estudo' },
	{ title: 'Simulados personalizados' },
	{ title: 'Flashcards' },
	{ title: 'Acesso ao app HardworQ' },
	{ title: 'Prioridade na inscrição da Live das Lives' },
];

export const CustomModal = ({ open, handleClose, classes, matchesTablet, matchesMobile, agrupamento }) => {
	const [preInscricao, setPreInscricao] = useState({
		agrupamento: agrupamento,
		nome: '',
		email: '',
		telefone: '',
		provas: '',
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
	});
	const [testDate, setTestDate] = useState();
	const [searchParams] = useSearchParams();

	useEffect(() => {
		setPreInscricao({
			...preInscricao,
			utm_source: searchParams.get('utm_source'),
			utm_medium: searchParams.get('utm_medium'),
			utm_campaign: searchParams.get('utm_campaign'),
		});
	}, []);

	const handlePhone = (valid, phoneNumber) => {
		if (valid) {
			//console.log('ok', phoneNumber);
			setPreInscricao({
				...preInscricao,
				telefone: phoneNumber,
			});
		} else if (preInscricao.telefone.length) {
			setPreInscricao({
				...preInscricao,
				telefone: '',
			});
		}
	};

	let sendPreInscricao = function () {
		if (!preInscricao.nome || preInscricao.nome.trim().split(' ').length < 2) {
			alert('Ops... Por favor, informe seu nome completo');
			return;
		}
		if (!preInscricao.email || !preInscricao.email.includes('@') || !preInscricao.email.includes('.')) {
			alert('Ops... Por favor preencha seu email');
			return;
		}
		if (!preInscricao.telefone) {
			alert('Ops... Por favor preencha seu telefone com um número válido');
			return;
		}

		trackPromise(
			Api.post('PreInscricao', preInscricao).then((res) => {
				if (res) {
					Swal.fire({
						title: `${'Massa!'} `,
						text: `${'Sua pré-inscrição está confirmada! Avisaremos assim que a nova turma estiver liberada.'}`,
						icon: 'success',
						confirmButtonText: 'Fechar',
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then((result) => {
						if (result.isConfirmed) {
							document.location.href = '/residencia-medica';
						}
					});
				}
			})
		);
	};

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			className={classes.modalContent}
		>
			<Grid
				item
				container
				alignContent={'space-around'}
				className={classes.gridForm}
			>
				<Grid
					item
					container
					justifyContent="space-between"
					style={{ marginBottom: 16 }}
				>
					<Typography
						color="primary"
						className={`${classes.titleForm} poppins`}
					>
						Trilha R1 Express
					</Typography>
					<CancelRoundedIcon
						onClick={handleClose}
						className={classes.cancelIcon}
					/>
				</Grid>
				<form
					className="form-control"
					action="javascript:;"
				>
					<Grid
						item
						container
						alignContent={'center'}
						justifyContent={'center'}
						rowGap={1}
					>
						<Grid
							item
							xs={12}
							md={6}
							container
							justifyContent={matchesTablet ? 'center' : 'flex-start'}
						>
							<TextField
								fullWidth
								type="string"
								name="nome"
								placeholder="Seu nome completo"
								required={true}
								InputProps={{
									className: `${classes.form} ${classes.formHeight}`,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								style={matchesTablet ? { width: '100%' } : { width: '97%' }}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										nome: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							container
							justifyContent={matchesTablet ? 'center' : 'flex-end'}
						>
							<IntlTelInput // Componente Input de telefones internacionais
								preferredCountries={['BR', 'PY', 'AR', 'CL', 'BO', 'UY', 'CU', 'PT']}
								style={{
									width: '100%',
									margin: '5px 0',
									border: '1px solid #a2a2a2',
									borderRadius: 8,
								}}
								inputClassName={`${classes.phoneNumberForm} ${classes.form} ${
									/*rgField ? classes.formHeightShort :*/ classes.formHeight
								}`}
								nationalMode={true}
								onPhoneNumberChange={(valid, value, selectedCountryData, fullNumber) =>
									handlePhone(valid, fullNumber)
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							container
							justifyContent={matchesMobile ? 'center' : 'flex-start'}
						>
							<TextField
								fullWidth
								type="email"
								name="email"
								placeholder="Seu melhor e-mail"
								required={true}
								InputProps={{
									className: `${classes.form} ${classes.formHeight}`,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								style={{ width: '100%' }}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										email: e.target.value,
									})
								}
							/>
						</Grid>
						{/* <Grid
							item
							xs={12}
							container
							justifyContent={matchesMobile ? 'center' : 'flex-start'}
						>
							<FormControl style={{ width: '100%' }}>
								<InputLabel id="test-select-label">Data da prova INEP desejada</InputLabel>
								<Select
									value={testDate}
									labelId="test-select-label"
									onChange={handleChangeTestDate}
									className={classes.selectMenu}
								>
									<MenuItem
										value={null}
										className={classes.tableRowEven}
									>
										<em>Data da prova INEP desejada</em>
									</MenuItem>
									{testDates.map((item, index) => {
										const rowClass = index % 2 === 0 ? classes.tableRowOdd : classes.tableRowEven;
										return (
											<MenuItem
												key={index}
												value={item}
												className={`${rowClass} poppins`}
											>
												<Typography
													color="primary"
													className={`${classes.textSelectItem} poppins`}
												>
													{item}
												</Typography>
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid> */}
						<Grid
							item
							xs={12}
							md={10}
							lg={8}
							container
							justifyContent={'center'}
							className="residencia"
							style={{ padding: '4px 0px' }}
						>
							<DefaultButton
								type="submit"
								variant="contained"
								color="primary"
								className={`${classes.optionBtn} btn btn-primary`}
								onClick={() => sendPreInscricao()}
							>
								ENVIAR
							</DefaultButton>
						</Grid>
					</Grid>
				</form>
			</Grid>
		</Modal>
	);
};

export const Countdown = ({ startDate, endDate, matchesMobile, classes }) => {
	const [timeRemaining, setTimeRemaining] = useState(0);

	useEffect(() => {
		const startCountdownTime = startDate.getTime();
		const endCountdownTime = endDate.getTime();

		const currentTime = new Date().getTime();

		if (currentTime < startCountdownTime) {
			setTimeRemaining(0); // Define o tempo restante como zero antes do início do countdown
		} else if (currentTime >= startCountdownTime && currentTime < endCountdownTime) {
			setTimeRemaining(Math.ceil((endCountdownTime - currentTime) / 1000));
		} else {
			setTimeRemaining(0);
		}

		const timer = setInterval(() => {
			const currentTime = new Date().getTime();

			if (currentTime < startCountdownTime) {
				setTimeRemaining(0); // Define o tempo restante como zero antes do início do countdown
			} else if (currentTime >= startCountdownTime && currentTime < endCountdownTime) {
				setTimeRemaining(Math.ceil((endCountdownTime - currentTime) / 1000));
			} else {
				setTimeRemaining(0);
			}
		}, 1000);

		return () => clearInterval(timer);
	}, [startDate, endDate]);

	const formatTime = (time) => {
		const hours = Math.floor(time / 3600);
		const minutes = Math.floor((time % 3600) / 60);
		const seconds = time % 60;

		// Formatar os valores para ter sempre 2 dígitos
		const formattedHours = hours.toString().padStart(2, '0');
		const formattedMinutes = minutes.toString().padStart(2, '0');
		const formattedSeconds = seconds.toString().padStart(2, '0');

		return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
	};

	return (
		<>
			{timeRemaining ? (
				<Grid
					item
					xs={11}
					md={9}
					lg={6}
					container
					justifyContent={'space-around'}
					alignContent={'center'}
					className={classes.timerGrid}
					gap={matchesMobile ? 1 : 4}
				>
					<Typography
						color="secondary"
						className={`${classes.endOfDiscount} poppins`}
					>
						<strong style={{ color: '#FFF460' }}>Valores promocionais</strong>
						<br />
						se encerram em
					</Typography>
					<Typography
						color="secondary"
						className={`${classes.timeText} wallpoet`}
					>
						{formatTime(timeRemaining)}
					</Typography>
					<img
						// src={require('../../Assets/Extensivo/Revalida/icon-watch-timer.svg').default}
						src={require('../../Assets/Extensivo/Revalida/icon-watch-timer.png')}
						alt=""
						className={classes.timerIcon}
					/>
				</Grid>
			) : null}
		</>
	);
};

function calculateMonthsFromToday(date) {
	// Get the current date
	const currentDate = new Date();

	// Get the provided date as an argument
	const providedDate = new Date(date);

	// Calculate the difference in months
	const monthDifference =
		(currentDate.getFullYear() - providedDate.getFullYear()) * 12 +
		(currentDate.getMonth() - providedDate.getMonth());

	// Take the absolute value of the month difference
	const absoluteMonthDifference = Math.abs(monthDifference);

	return absoluteMonthDifference;
}

const DIFERENTIALS_TRILHA = [
	{
		diferential_title: '+ de 250 aulas',
		diferential_description: `Acesso imediato a mais de 250 aulas Hardwork, elaboradas com rigor em didática.`,
		diferential_icon: lessionsIcon,
	},
	{
		diferential_title: '+ de 190 resumos Hardwork',
		diferential_description: `Resumos dos principais temas explorados nas provas de residência médica.`,
		diferential_icon: iconResume,
	},
	{
		diferential_title: '+ de 100 mil questões de provas',
		diferential_description: `Mais de 100 mil questões de provas dos principais concursos de residência do Brasil.`,
		diferential_icon: iconQuestions,
	},
	{
		diferential_title: 'Contato direto com os professores',
		diferential_description: `Não existe escola em que o aluno não fale com o professor! Aqui o contato é direto, todos os alunos têm acesso aos professores, tanto nos times, quanto via whatsapp.`,
		diferential_icon: iconTalkWithProfessor,
	},
	{
		diferential_title: '+ de 200 lives de discussão ',
		diferential_description: `Acesso imediato ao banco de lives do Hardwork Medicina, com discussões de mais de 200 dos principais temas voltados para provas de residência médica.`,
		diferential_icon: iconLivesBank,
	},
	{
		diferential_title: 'Acesso aos principais livros',
		diferential_description: `Sim! No extensivo tu terás acesso a milhares de obras consagradas...Vamos estudar pelas principais referências. Tudo JUNTO com o professor! Vamos evoluir frase por frase dentro dos problemas explorados pelas questões. Nada de se restringir a textos resumidos! Vamos dominar as principais fontes! Vamos te mostrar como.`,
		diferential_icon: iconBooks,
	},
	{
		diferential_title: 'Métricas de desempenho',
		diferential_description: `Vamos te mostrar toda a tua evolução de acordo com as provas que farás.. Afinal, cada prova tem seus temas de predileção, sua forma de abordar cada assunto.`,
		diferential_icon: iconMetrics,
	},
];

const exams = [
	{
		id: '1210',
		imageUrl: AbcLogo,
	},
	{
		id: '1211',
		imageUrl: AmpLogo,
	},
	{
		id: '1212',
		imageUrl: AmrigsLogo,
	},
	{
		id: '1213',
		imageUrl: EinsteinLogo,
	},
	{
		id: '1214',
		imageUrl: EnareLogo,
	},
	{
		id: '1216',
		imageUrl: FamemaLogo,
	},
	{
		id: '1215',
		imageUrl: FacMedicinaJundiaiLogo,
	},
	{
		id: '1223',
		imageUrl: SirioLibanesLogo,
	},
	{
		id: '1217',
		imageUrl: IamspeLogo,
	},
	{
		id: '1221',
		imageUrl: PsugoLogo,
	},
	{
		id: '1218',
		imageUrl: PsuMgLogo,
	},
	{
		id: '1219',
		imageUrl: SantaCasaLogo,
	},
	{
		id: '1220',
		imageUrl: SesDfLogo,
	},
	{
		id: '1222',
		imageUrl: SesPeLogo,
	},
	{
		id: '1224',
		imageUrl: SurceLogo,
	},
	{
		id: '1226',
		imageUrl: SusBaLogo,
	},
	{
		id: '1225',
		imageUrl: SusSpLogo,
	},
	{
		id: '1236',
		imageUrl: UerjLogo,
	},
	{
		id: '1227',
		imageUrl: UfesLogo,
	},
	{
		id: '1235',
		imageUrl: UfrjLogo,
	},
	{
		id: '1231',
		imageUrl: UnespBotucatuLogo,
	},
	{
		id: '1228',
		imageUrl: UnicampLogo,
	},
	{
		id: '1230',
		imageUrl: UnifespLogo,
	},
	{
		id: '1232',
		imageUrl: UspLogo,
	},
	{
		id: '1234',
		imageUrl: UspRibeiraoLogo,
	},
];

const ProductCard = ({ backgroundImage, logo, description, details, buttonText, urlRedirect }) => {
	const classes = useStyles();

	// const [openDetails, setOpenDetails] = useState(false);

	// const showDetails = () => {
	// 	setOpenDetails(!openDetails);
	// };

	return (
		<Paper
			className={`${classes.scriptPaper} rounded-card`}
			color="secondary"
			elevation={4}
		>
			<Grid
				container
				justifyContent={'center'}
				alignContent={'center'}
				className={classes.gridImage}
				style={{
					background: `url(${require('../../Assets/Extensivo/R1/Express/bg-card-r1.png')}) center/cover no-repeat`,
					height: '200px',
				}}
			>
				<img
					src={logo}
					alt=""
					style={{ visibility: 'visible' }}
				/>
			</Grid>
			<Grid
				container
				justifyContent={'center'}
				className={classes.gridDescriptionScriptCard}
			>
				<Typography
					align="left"
					color="secondary"
					className={`${classes.scriptText} poppins`}
				>
					{description}
				</Typography>
			</Grid>
		</Paper>
	);
};

export default function TrilhaR1Express2024() {
	const classes = useStyles();
	const theme = useTheme();
	// const [isSelected, setIsSelected] = useState(false);
	const subscribe_card = useRef(null);
	const startDate = new Date('2023-06-12T11:16:00'); // Data Para Começar
	// const startDate = new Date('2023-06-13T20:00:00'); // Data Para Começar
	const endDate = new Date('2023-06-14T21:00:00');

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesDown1400 = useMediaQuery(theme.breakpoints.down(1400));

	const executeScroll = () =>
		subscribe_card.current.scrollIntoView({ behavior: 'smooth', block: matchesTablet ? 'start' : 'center' });

	const [open, setOpen] = useState(false);

	const showDiscount = startDate <= new Date() && endDate >= new Date();

	const handleOpen = (agrupamento) => {
		setSelectedAgrupamento(agrupamento);
		setOpen(true);
	};
	const handleClose = () => setOpen(false);

	const [selectedAgrupamento, setSelectedAgrupamento] = useState('TRILHA R1 EXPRESS 2024');

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			<Helmet>
				<title>Trilha R1 Express - Hardwork Medicina</title>
				<meta
					name="description"
					content=""
				/>
			</Helmet>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className="residencia"
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<FirstSection
					buttonDisabled={true}
					backgroundImageUrl={bgTrilhaExpress}
					logoUrl={logoTrilhaExpress}
					type="red"
					buttonText="Inscrições Encerradas!"
					executeScroll={executeScroll}
					firstTextPaper={[
						<Typography
							align="left"
							color="secondary"
							className={`${classes.firstText} roboto-condensed`}
						>
							Ainda dá tempo! O Trilha R1 Express vai contigo de junho até as provas do fim do ano.​
						</Typography>,
						<Grid
							item
							container
						>
							<Divider
								variant="middle"
								className={classes.divider2}
							/>
						</Grid>,
						<Typography
							align="left"
							color="secondary"
							className={`${classes.text} poppins`}
						>
							<strong
								className={`${classes.gradientText} gradient-text roboto-condensed`}
								style={matchesMobile ? { display: 'block' } : { display: 'inline' }}
							>
								Estudo por questões + Revisões focadas em bancas juntos​
							</strong>
						</Typography>,

						<Typography
							align="left"
							color="secondary"
							className={`${classes.text2} poppins`}
						>
							{matchesMobile ? (
								<>
									O Trilha Express alinha as maiores revoluções Hardwork: o estudo por questões do
									Extensivo R1 e o Sprint Final, a revisão focada em cada banca.​
								</>
							) : (
								<>
									O Trilha Express alinha as maiores revoluções Hardwork: o estudo por questões do
									Extensivo R1 e o Sprint Final, a revisão focada em cada banca.​
								</>
							)}
						</Typography>,
					]}
					buttonProps={{
						color: 'secondary',
						buttonLogoUrl: require('../../Assets/Svg/Icons/bagIcon.svg').default,
					}}
				/>
			</Container>

			<LearningVersion02
				title={'A Revolução: A Trilha do aprendizado definitivo'}
				cardsInfo={learningPathTemplate}
				cardsInfoTwo={learningPathTemplateTwo}
				background={'white-background'}
			/>

			<Grid
				container
				className={classes.backgroundArrows}
			>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					<Grid
						item
						container
						alignContent="center"
						justifyContent="space-around"
						className="section"
						rowGap={matchesDown1400 ? 1 : 2}
						style={{
							margin: `${matchesMobile ? '0 auto 8px' : matchesTablet ? '20px auto' : '60px auto 0'}`,
						}}
					>
						<Grid
							item
							xs={12}
							container
							alignContent={'flex-end'}
							justifyContent={'space-between'}
						>
							{open && (
								<>
									<div className={classes.backdropBlur}></div>
									<CustomModal
										open={open}
										handleClose={handleClose}
										classes={classes}
										matchesTablet={matchesTablet}
										matchesMobile={matchesMobile}
										agrupamento={selectedAgrupamento}
									/>
								</>
							)}
						</Grid>
					</Grid>
				</Container>
				<Grid
					item
					xs={12}
					container
				></Grid>
				{/* container dos cards */}
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					<Grid
						item
						container
						justifyContent={'center'}
					>
						<Grid
							item
							container
							alignContent="center"
							justifyContent="space-around"
							className="section"
							rowGap={matchesDown1400 ? 1 : 2}
							style={{
								margin: `${matchesTablet ? '20px auto 40px' : '70px auto 60px'}`,
							}}
							ref={subscribe_card}
						>
							<Grid
								item
								container
								justifyContent="center"
								className={classes.gridExtensivoCard}
							>
								<ExtensivoR1Card
									idTurma={'1337'}
									// description={descriptionMaio}
									info={infoTitlesMaio}
									image={logoTrilhaR1}
									// discount={'1.000'}
									benefits={benefitsMaio}
									ahead={aheadMaio}
									// showDiscount={showDiscount}
									resums
									handleOpen={handleOpen}
									selectedAgrupamento={'TRILHA R1 EXPRESS - LISTA DE ESPERA'}
									//enableText={false}
									//enableButton={true}
									//enablePrice={false}
								/>
								{open && (
									<>
										<div className={classes.backdropBlur}></div>
										<CustomModal
											open={open}
											handleClose={handleClose}
											classes={classes}
											matchesTablet={matchesTablet}
											matchesMobile={matchesMobile}
											agrupamento={selectedAgrupamento}
										/>
									</>
								)}
							</Grid>
							{/* terceiro card */}
							<Grid
								item
								container
								justifyContent="center"
								className={classes.gridExtensivoCard}
							>
								<ExtensivoR1Card
									idTurma={'1343'}
									// description={descriptionExpress}
									info={infoTitlesExpress}
									image={logoCard}
									// discount={'1.000'}
									benefits={benefitsExpress}
									ahead={aheadExpress}
									// showDiscount={showDiscount}
									resums
									handleOpen={handleOpen}
									selectedAgrupamento={'TRILHA R1 EXPRESS - LISTA DE ESPERA'}
									//enableText={false}
									//enableButton={true}
									//enablePrice={true}
								/>
								{open && (
									<>
										<div className={classes.backdropBlur}></div>
										<CustomModal
											open={open}
											handleClose={handleClose}
											classes={classes}
											matchesTablet={matchesTablet}
											matchesMobile={matchesMobile}
											agrupamento={selectedAgrupamento}
										/>
									</>
								)}
							</Grid>
							{/* quarto card */}
							<Grid
								item
								container
								justifyContent="center"
								className={classes.gridExtensivoCard}
							>
								<ExtensivoR1Card
									idTurma={'1346'}
									// description={descriptionExpressUm}
									info={infoTitlesExpressUm}
									image={logoCardUm}
									// discount={'1.000'}
									benefits={benefitsExoressUm}
									ahead={aheadExpress}
									// showDiscount={showDiscount}
									resums
									handleOpen={handleOpen}
									selectedAgrupamento={'TRILHA R1 EXPRESS - LISTA DE ESPERA'}
									//enableText={false}
									//enableButton={true}
									//enablePrice={true}
								/>
								{open && (
									<>
										<div className={classes.backdropBlur}></div>
										<CustomModal
											open={open}
											handleClose={handleClose}
											classes={classes}
											matchesTablet={matchesTablet}
											matchesMobile={matchesMobile}
											agrupamento={selectedAgrupamento}
										/>
									</>
								)}
							</Grid>
							{/* {matchesTablet && (
								<DefaultButton
									variant="outlined"
									color="secondary"
									className={`${classes.btn} btn`}
									onClick={handleOpen}
									selectedAgrupamento={'TRILHA R1 EXPRESS 2024'}
								>
									Outra edição futura?
								</DefaultButton>
							)} */}
						</Grid>
						<Countdown
							startDate={startDate}
							endDate={endDate}
							matchesMobile={matchesMobile}
							classes={classes}
						/>
					</Grid>
					<Grid
						container
						alignSelf={'center'}
						justifyContent={'center'}
						style={{
							width: matchesMobile ? '95%' : '30%',
							background: 'linear-gradient(0.25turn, #FF5F46, #FF357C)',
							padding: matchesMobile ? '8px 0' : '8px 16px 8px 16px',
							borderRadius: '4px',
							alignSelf: 'center',
							margin: '0 auto',
						}}
					>
						<Typography
							color="secondary"
							style={{ fontFamily: 'poppins' }}
						>
							Desconto de até 30% para ex-alunos Hardwork.
						</Typography>
					</Grid>
					<Grid
						style={{ marginTop: '2rem', marginLeft: '2rem' }}
						container
					>
						<Typography
							color="secondary"
							justifyContent="flex-start"
							style={{ fontSize: '0.8rem' }}
							className={`${classes.fourthSectionText} poppins`}
						>
							¹O lançamento dos cursos de Sprint Final 2024, que consta em nosso site, está sujeito a
							alterações para mais ou para menos, sem obrigação de aviso prévio.
						</Typography>
					</Grid>
				</Container>
			</Grid>

			{/* o que mais tem no trilha */}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className="section"
				>
					<Grid
						item
						xs={12}
						container
					>
						<Grid
							item
							xs={12}
							container
						>
							<Typography
								className={`${classes.subtitle1} roboto-condensed`}
								color="secondary"
							>
								O que mais tem no Trilha
							</Typography>
						</Grid>
						<Grid
							container
							item
							xs={12}
							rowGap={2}
							style={{ margin: '1rem 0rem 3rem 0rem' }}
						>
							{DIFERENTIALS_TRILHA.map((elm, index) => (
								<DiferencialBoxV2
									key={index}
									title={elm.diferential_title}
									description={elm.diferential_description}
									icon={elm.diferential_icon}
								/>
							))}
						</Grid>
						<Typography
							color="secondary"
							className={`${classes.thirdSectionText} poppins`}
							style={{ marginTop: '-1rem', marginBottom: '2rem' }}
						>
							{/* *Acesso liberado a partir de 15/01/2024. */}
						</Typography>
					</Grid>
				</Grid>

				<Grid
					item
					xs={12}
					container
					style={{ marginBottom: '40px' }}
				>
					<Grid
						item
						xs={12}
						container
					>
						<Typography
							className={`${classes.subtitle1} roboto-condensed`}
							color="secondary"
						>
							E tem mais...
						</Typography>
					</Grid>
					<Grid
						container
						direction={matchesMobile ? 'column' : 'row'}
						style={{ gap: '4rem', marginBottom: '4rem' }}
						justifyContent="flex-start"
					>
						<Grid
							item
							md={3.75}
							lg={3.75}
						>
							<ProductCard
								imgUrl={BgPlusCard}
								logo={SalasEstudo}
								description={
									'Nos três primeiros meses do ano, salas de estudos online para plantão de dúvidas, em um período do dia.'
								}
							/>
						</Grid>
						<Grid
							item
							md={3.75}
							lg={3.75}
						>
							<ProductCard
								imgUrl={BgPlusCard}
								logo={Prioridade}
								description={
									'Tu terás prioridade nas inscrições das concorridas revisões de véspera Hardwork, no fim do ano.'
								}
							/>
						</Grid>
					</Grid>
				</Grid>
				{/* e tem mais */}

				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className="section"
				>
					<Grid
						item
						xs={12}
						container
						className="residencia"
						style={matchesMobile ? { paddingBottom: '1em' } : { paddingBottom: '2em' }}
					>
						<Typography
							className={`${classes.subtitle1} roboto-condensed`}
							color="secondary"
						>
							E no segundo semestre de 2024
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						container
					>
						<Grid
							item
							xs={4}
							container
							alignContent={matchesMobile ? 'center' : null}
						>
							{matchesMobile ? (
								<img
									src={SprintFinalMobile}
									alt=""
								/>
							) : (
								<img
									src={SprintHwmLogoSection}
									alt=""
								/>
							)}
						</Grid>
						<Grid
							item
							xs={8}
							container
							alignContent="center"
							style={{ paddingLeft: '2em' }}
						>
							<Typography
								color="secondary"
								className={`${classes.thirdSectionText} poppins`}
							>
								Caso opte pelo pacote completo, tu terás acesso garantido a todos os Sprints Finais
								2024² para provas de residência: A única revisão realmente focada em provas.
							</Typography>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						container
						alignContent="center"
						justifyContent="flex-start"
						style={{ margin: '40px 0px 20px' }}
					>
						{exams.map((item, index) => (
							<Grid
								item
								xs={6}
								md={2.4}
								lg={2.4}
								key={index}
								container
								justifyContent="center"
								style={{ margin: '8px 0' }}
							>
								<TestCardOpen
									imageUrl={item.imageUrl}
									key={index}
									selected={true}
								/>
							</Grid>
						))}
					</Grid>
					<Grid container>
						<Typography
							color="secondary"
							justifyContent="flex-start"
							className={`${classes.thirdSectionText} poppins`}
						>
							²Sprints previstos para 2024. Listagem sujeita a alterações para mais ou para menos.
						</Typography>
					</Grid>
				</Grid>
			</Container>

			<Grid
				container
				justifyContent={matchesMobile ? 'center' : 'flex-start'}
				alignItems={'center'}
				style={{
					width: '100%',
					opacity: '90%',
					padding: '2rem',
					paddingLeft: !matchesMobile && '6rem',
					// marginLeft: '3rem',
					gap: '2rem',
					backgroundColor: 'rgba(153, 153, 153, 0.15)',
				}}
			>
				<img
					src={LogoEnare}
					alt=""
				/>

				<Grid>
					<Typography
						color="secondary"
						className={`${classes.text} residencia poppins`}
					>
						<strong className={`${classes.gradientTextSecond} residencia gradient-text roboto-condensed`}>
							Bônus exclusivo
						</strong>
					</Typography>
					<Typography
						color="secondary"
						className={`${classes.thirdSectionText} poppins`}
					>
						Tenha acesso ao Enare Dominado e simulados.
					</Typography>
				</Grid>
			</Grid>

			{/* container dos cards */}

			<StudentsFeedbackSection type="extensivo" />
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	gradientText: {
		fontSize: '2.75rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '3rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.75rem !important',
		},
		[theme.breakpoints.down(901)]: {
			fontSize: '2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.75rem !important',
		},
	},
	gradientTextSecond: {
		fontSize: '2.75rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '4rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.75rem !important',
		},
		[theme.breakpoints.down(901)]: {
			fontSize: '2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.75rem !important',
		},
	},
	firstText: {
		fontSize: '2.65rem !important',
		fontWeight: '600 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '3rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down(361)]: {
			fontSize: '1.4rem !important',
		},
	},
	text: {
		fontSize: '1.85rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.35rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.05rem !important',
		},
		[theme.breakpoints.down(361)]: {
			fontSize: '1rem !important',
		},
	},
	text2: {
		fontSize: '1.45rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.35rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.05rem !important',
		},
	},
	subtitle1: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('md')]: {
			lineHeight: 'unset !important',
			fontSize: '1.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem !important',
			lineHeight: 'unset !important',
		},
	},
	textMultimidiaCard: {
		fontSize: '1.2rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	containerBackgroundColor: {
		backgroundColor: '#33333380',
		margin: '60px auto 30px',
		borderRadius: 15,
		maxWidth: '1410px !important',
		padding: '2em 2.5% 3vh',
		[theme.breakpoints.down('sm')]: {
			padding: '3vh 2.5% 0',
			borderRadius: 'unset',
		},
	},
	gridFlipCard: {
		margin: '15px 0 !important',
		height: 270,
		padding: '0 1rem',
		[theme.breakpoints.down('sm')]: {
			padding: 'unset',
			height: 160,
		},
	},
	divider: {
		minHeight: 1,
		backgroundColor: '#6D6D6D',
		width: '98%',
		margin: '2rem 0 !important',
	},
	divider2: {
		minHeight: 1,
		backgroundColor: '#6D6D6D',
		width: '98%',
		margin: '1rem 0 !important',
	},
	gridExtensivoCard: {
		width: '337px !important',
		marginBottom: '2rem !important',
		[theme.breakpoints.down('xl')]: {
			width: '285px !important',
		},
		[theme.breakpoints.down(1400)]: {
			width: '337px !important',
		},
		[theme.breakpoints.down('lg')]: {
			width: '205px !important',
		},
		[theme.breakpoints.down('md')]: {
			width: '287px !important',
			marginBottom: '32px !important',
		},
		[theme.breakpoints.down('sm')]: {
			width: 'unset !important',
			marginBottom: '3rem !important',
		},
	},
	btn: {
		height: 50,
		fontSize: '1rem !important',
		width: 'unset',
		fontWeight: 'bold !important',
		border: '4px solid !important',
		transition: 'ease-in',
		alignSelf: 'center',
		borderRadius: '10px !important',
		backgroundColor: '#000000 !important',
		'&:hover': {
			scale: 1.05,
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.75rem !important',
			minHeight: 'unset !important',
		},
		[theme.breakpoints.down('md')]: {
			marginTop: '3rem !important',
			height: 'unset',
			fontSize: '0.95rem !important',
			width: '90%',
		},
	},
	modalContent: {
		margin: 'auto',
		borderRadius: 12,
		width: '697px', // Define a largura do modal
		height: 'fit-content',
		border: '2px solid #000000',
		backgroundColor: '#DCDCDC !important',
		zIndex: '1000 !important',
		boxShadow: theme.shadows[5], // Adiciona uma sombra ao modal
		'& .MuiBackdrop-root': {
			backgroundColor: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			width: 'unset',
			margin: 'auto 1rem',
		},
	},
	form: {
		borderRadius: '8px !important',
		backgroundColor: '#E1E1E1 !important',
		margin: '5px 0 !important',
	},
	formHeight: {
		maxHeight: '3em',
	},
	cancelIcon: {
		color: '#000000',
		right: '-20px',
		top: '-10px',
		fontSize: '2rem !important',
		'&:hover': {
			cursor: 'pointer',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'absolute',
			top: '-20px',
			right: '-18px',
		},
	},
	optionBtn: {
		fontSize: '1.35rem !important',
		fontWeight: 'bold !important',
		width: '80%',
	},
	gridForm: {
		padding: '1.5rem 2rem',
		backgroundImage: `url(${process.env.PUBLIC_URL + '/Assets/Img/bgWave2.png'})`,
		height: '100%',
		[theme.breakpoints.down('sm')]: {
			padding: '1.5rem',
		},
	},
	titleForm: {
		fontSize: '2.25rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem !important',
		},
	},
	backdropBlur: {
		position: 'fixed',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		backgroundColor: '#00000080',
		zIndex: 999,
	},
	tableRowEven: {
		backgroundColor: '#CCCCCC !important',
	},
	whiteDivider: {
		minHeight: 1,
		backgroundColor: '#ffffff',
		width: '57%',
		margin: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			width: '95%',
		},
	},
	sprintText: {
		fontSize: '1.35rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.75rem !important',
		},
	},
	timerGrid: {
		position: 'fixed',
		bottom: '2rem',
		zIndex: 3,
		border: '3px solid #ffffff',
		margin: '0 auto !important',
		backgroundColor: '#000000',
		padding: '0.75rem 9rem 0.75rem 4rem',
		borderRadius: '25px 50px 50px 25px !important',
		[theme.breakpoints.down('md')]: {
			padding: '0.75rem 2rem 0.75rem 3rem',
			borderRadius: '15px !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0.75rem 1rem',
		},
	},
	backgroundArrows: {
		backgroundImage: `url(${require('../../Assets/Extensivo/R1/Express/bg-arrows-red.png')})`,
		backgroundSize: 'cover',
		padding: '0 0 3rem',
		[theme.breakpoints.down('md')]: {
			paddingTop: '3rem',
		},
		[theme.breakpoints.down('sm')]: {
			backgroundImage: `url(${require('../../Assets/Extensivo/R1/Express/bg-arrows-red.png')})`,
			paddingTop: 'unset',
			marginTop: '3rem',
		},
	},
	flashCardsTitle: {
		fontSize: '1.8em !important',
		fontWeight: 'bold !important',
	},
	thirdSectionText: {
		fontSize: '1.25rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	fourthSectionText: {
		fontSize: '0.9rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	timerIcon: {
		position: 'absolute',
		right: -14,
		top: -29,
		[theme.breakpoints.down('xl')]: {
			top: -20,
			right: -11,
			width: '10%',
		},
		[theme.breakpoints.down(1450)]: {
			width: '15%',
		},
		[theme.breakpoints.down('lg')]: {
			top: -32,
			right: -13,
			scale: 0.85,
		},
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	timeText: {
		fontSize: '3.85rem !important',
		lineHeight: 'unset !important',
		color: 'yellow !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '2.5rem !important',
			alignSelf: 'center',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '3rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
		},
	},
	endOfDiscount: {
		fontSize: '1.25rem !important',
		alignSelf: 'center',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	phoneNumberForm: {
		height: '100%',
		borderRadius: 10,
		width: '100%',
		border: 'unset',
		'&:hover': {
			border: '1px solid #000000de',
		},
		[theme.breakpoints.down('md')]: {
			height: '3em',
		},
	},
	discountGrid: {
		backgroundColor: '#000000',
		borderRadius: 15,
		padding: '0.5rem 2rem',
		width: 'fit-content !important',
		margin: '0.5rem 0 !important',
	},
	bonusGrid: {
		backgroundColor: '#ffffffff',
		borderRadius: 15,
		padding: '0.5rem 1rem',
		width: 'fit-content !important',
		margin: '0.5rem 0 !important',
	},
	bonusText: {
		fontWeight: 'bolder !important',
		fontSize: '1.2rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.35rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.05rem !important',
		},
		iconGift: {
			padding: '2rem',
		},
	},
	scriptPaper: {
		backgroundColor: 'transparent !important',
		height: '100%',
		[theme.breakpoints.down('xl')]: {
			minHeight: 'unset',
		},
		[theme.breakpoints.down('lg')]: {
			height: 'unset',
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
			width: 'unset',
			minHeight: 'unset',
			margin: '16px 0px',
		},
	},
	gridImage: {
		borderTopRightRadius: 15,
		borderTopLeftRadius: 15,
		padding: '2rem',
		height: '50%',
	},
	gridDescriptionScriptCard: {
		backgroundColor: '#63636366 !important',
		padding: '1.25rem',
		borderBottomLeftRadius: 16,
		borderBottomRightRadius: 16,
		height: '50%',
		[theme.breakpoints.down('lg')]: {
			padding: '1em 1.5em',
		},
		[theme.breakpoints.down('md')]: {
			height: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '1em',
			borderTopRightRadius: 0,
			borderTopLeftRadius: 0,
		},
	},
	scriptText: {
		fontSize: '1.4rem !important',
		overflowY: 'auto',
		paddingRight: 20,
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
			maxHeight: 175,
			minHeight: 'unset !important',
		},
	},
}));
