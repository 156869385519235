import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';

export default function BoxInfoOqueTemMais({ title, icon, description }) {
	const theme = useTheme();
	const classes = useStyles();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const [isClicked, setIsClicked] = useState(false);

	const handleClick = () => {
		setIsClicked(!isClicked);
	};

	return (
		<Grid
			item
			xs={12}
			md={5.9}
			container
			className={`${classes.face} ${isClicked && classes.active}`}
			onClick={handleClick}
		>
			<Grid
				item
				container
				direction={'column'}
				wrap={'nowrap'}
				rowGap={2}
				columnGap={2}
				className={classes.front}
			>
				<img
					src={icon}
					alt=""
					style={{ width: 'fit-content' }}
				/>
				<Typography
					color={'#959595'}
					fontFamily={'Bebas Neue'}
					style={{ fontSize: matchesMobile ? '1.75rem' : '3rem', lineHeight: 'unset' }}
				>
					{title}
				</Typography>
			</Grid>
			<Grid
				item
				container
				alignContent={'center'}
				className={classes.back}
			>
				<Typography
					color={'#FCFCFC'}
					align="center"
					fontFamily={'Manrope'}
					fontSize={matchesMobile ? '0.875rem' : '1.5rem'}
					style={{
						lineHeight: 1.25,
					}}
				>
					{description}
				</Typography>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	face: {
		perspective: '700px',
		transformStyle: 'preserve-3d',
		height: 200,
		'& $front': {
			height: '100%',
		},
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				'& $front': {
					transform: 'rotateY(180deg)',
					zIndex: 0,
				},
				'& $back': {
					transform: 'rotateY(0)',
					zIndex: 1,
				},
			},
		},
		[theme.breakpoints.down('xl')]: {
			width: '95% !important',
		},
		[theme.breakpoints.down('sm')]: {
			width: '90% !important',
			height: 180,
		},
	},
	noFlip: {
		perspective: '700px',
		'& $front': {
			height: '100%',
		},
		[theme.breakpoints.down('xl')]: {
			width: '95% !important',
		},
		[theme.breakpoints.down('sm')]: {
			width: '90% !important',
		},
	},
	active: {
		'& $front': {
			transform: 'rotateY(180deg)',
			zIndex: 0,
		},
		'& $back': {
			transform: 'rotateY(0)',
			zIndex: 1,
		},
	},
	front: {
		width: '100% !important',
		position: 'absolute !important',
		zIndex: 1,
		transition: '700ms',
		border: '1px solid #9E9E9E',
		borderRadius: 12,
		padding: '1.5rem',
		background: `linear-gradient(135deg, #101010 0%, #161616 100%)`,
	},
	back: {
		height: '100% !important',
		width: '100% !important',
		position: 'absolute !important',
		transform: 'rotateY(-180deg)',
		backfaceVisibility: 'hidden',
		transition: '700ms',
		border: '1px solid #9E9E9E',
		borderRadius: 12,
		padding: '1.5rem',
		background: '#161616',
		[theme.breakpoints.down('sm')]: {
			padding: '15px !important',
		},
	},
}));
