import React, { useRef, useState, useEffect } from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import GlitchClip from 'react-glitch-effect/core/GlitchClip';

// COMPONENTS
import Footer from '../../Components/Footer';
import { Header } from '../../Components/Header';
import StudentsFeedbackSection from '../../Components/Sections/StudentsFeedback';
import DiferencialBox from '../../Components/Cards/DiferencialBox';

// TO IMPLEMENT
// import FaqSection from '../../Components/Sections/FaqSection';
// import Newsletter from '../../Components/Utils/Newsletter';

// ASSETS
import BackgroundImage from '../../Assets/Extensivo/R1/2023-24/background-image.png';
import StudyingImage from '../../Assets/Extensivo/R1/2023-24/R1-23-24-studying.png';
import TeachersBanner from '../../Assets/Extensivo/R1/2023-24/teachersBanner.png';
import SprintHwmLogoSection from '../../Assets/SprintFinal/sprintHwmLogoSection.svg';
import SprintFinalMobile from '../../Assets/SprintFinal/sprintFinalMobile.svg';
import ExtensivoR12324Logo from '../../Assets/Extensivo/R1/2023-24/extensivoR12324Logo.svg';
import BagIcon from '../../Assets/Svg/Icons/bagIcon.svg';

// SF PROVAS
import AmrigsLogo from '../../Assets/SprintFinal/amrigsLogo.svg';
import EinsteinLogo from '../../Assets/SprintFinal/einsteinLogo.svg';
import EnareLogo from '../../Assets/SprintFinal/enareLogo.svg';
import IamspeLogo from '../../Assets/SprintFinal/iamspeLogo.svg';
import PsugoLogo from '../../Assets/SprintFinal/psugoLogo.svg';
import FacMedicinaJundiaiLogo from '../../Assets/SprintFinal/facMedicinaJundiaiLogo.svg';
import PsuMgLogo from '../../Assets/SprintFinal/psuMgLogo.svg';
import SantaCasaLogo from '../../Assets/SprintFinal/santaCasaSpLogo.svg';
import SesDfLogo from '../../Assets/SprintFinal/sesDfLogo.svg';
import SesPeLogo from '../../Assets/SprintFinal/sesPeLogo.svg';
import UfrjLogo from '../../Assets/SprintFinal/ufrjLogo.svg';
import AbcLogo from '../../Assets/SprintFinal/abcLogo.svg';
import SirioLibanesLogo from '../../Assets/SprintFinal/sirioLibanesLogo.svg';
import SurceLogo from '../../Assets/SprintFinal/surceLogo.svg';
import SusBaLogo from '../../Assets/SprintFinal/susBaLogo.svg';
import UnicampLogo from '../../Assets/SprintFinal/unicampLogo.svg';
import SusSpLogo from '../../Assets/SprintFinal/susSpLogo.svg';
import UnespBotucatuLogo from '../../Assets/SprintFinal/unespBotucatuLogo.svg';
import AmpLogo from '../../Assets/SprintFinal/ampLogo.svg';
import UnifespLogo from '../../Assets/SprintFinal/unifestpLogo.svg';
import UspLogo from '../../Assets/SprintFinal/uspLogo.svg';
import UspRibeiraoLogo from '../../Assets/SprintFinal/uspRibeiraoLogo.svg';
import FamemaLogo from '../../Assets/SprintFinal/famemaLogo.svg';
import UerjLogo from '../../Assets/SprintFinal/uerjLogo.svg';
import UfesLogo from '../../Assets/SprintFinal/ufesLogo.svg';

// TO IMPLEMENT
// import DoctorCard from '../../Components/Cards/DoctorCard';

// import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import TestCard from '../../Components/Cards/TestCard';
//import PriceCard from '../../Components/Cards/PriceCard';
import FirstSection from '../../Components/Sections/FirstSection';
import LearningPath from '../../Components/Sections/LearningPath';

import PriceCardVersion2 from '../../Components/Cards/PriceCardVersion2';

const exams = [
	{
		imageUrl: AbcLogo,
	},
	{
		imageUrl: AmpLogo,
	},
	{
		imageUrl: AmrigsLogo,
	},
	{
		imageUrl: EinsteinLogo,
	},
	{
		imageUrl: EnareLogo,
	},
	{
		imageUrl: FamemaLogo,
	},
	{
		imageUrl: FacMedicinaJundiaiLogo,
	},
	{
		imageUrl: SirioLibanesLogo,
	},
	{
		imageUrl: IamspeLogo,
	},
	{
		imageUrl: PsugoLogo,
	},
	{
		imageUrl: PsuMgLogo,
	},
	{
		imageUrl: SantaCasaLogo,
	},
	{
		imageUrl: SesDfLogo,
	},
	{
		imageUrl: SesPeLogo,
	},
	{
		imageUrl: SurceLogo,
	},
	{
		imageUrl: SusBaLogo,
	},
	{
		imageUrl: SusSpLogo,
	},
	{
		imageUrl: UerjLogo,
	},
	{
		imageUrl: UfesLogo,
	},
	{
		imageUrl: UfrjLogo,
	},
	{
		imageUrl: UnespBotucatuLogo,
	},
	{
		imageUrl: UnicampLogo,
	},
	{
		imageUrl: UnifespLogo,
	},
	{
		imageUrl: UspLogo,
	},
	{
		imageUrl: UspRibeiraoLogo,
	},
];

const learningPathTemplate = [
	{
		title: 'Primeira liga',
		description: [
			'A trilha do aprendizado funciona por ligas de evolução. Começaremos por temas mais palpáveis, evoluindo para outros mais peculiares.',
			<>
				<br />
				<br />
			</>,
			'São 12 ligas no total, cada uma com temas diferentes. Para passar para a próxima, precisaremos dominar por completo a atual. Veja como tudo vai funcionar em cada liga:',
		],
		bgColor: '#ffffff',
		titleColor: '#121212',
		descriptionColor: 'primary',
		// url: 'https://player.vimeo.com/video/769930065',
	},
	{
		title: 'Aula Hardwork',
		description: [
			'Tudo começa com a apresentação ao tema, que no Hardwork é feito com rigor em didática.',
			<>
				<br />
				<br />
			</>,
			'São as melhores aulas que você já viu sobre cada tema.',
		],
		bgColor: '#0D0D0D',
		titleColor: '#FF005D',
		descriptionColor: 'secondary',
		// url: 'https://player.vimeo.com/video/769929590',
	},
	{
		title: 'Estudo por questões',
		description: [
			'Alguns dias após a apresentação ao tema, vamos te conduzir pelo estudo por questões.',
			<>
				<br />
				<br />
			</>,
			'Aqui você entenderá todas as formas em que cada parte do tema é explorado nas provas.',
			<>
				<br />
				<br />
			</>,
			'O professor Hardwork te mostrará tudo em detalhes, aprofundando em cada tema.',
		],
		bgColor: '#0D0D0D',
		titleColor: '#FF005D',
		descriptionColor: 'secondary',
		// url: 'https://player.vimeo.com/video/769929662',
	},
	{
		title: 'Revisões cíclicas e personalizadas',
		description: [
			'Questões, questões e questões! É assim que revisamos no Hardwork!',
			<>
				<br />
				<br />
			</>,
			'Toda a dinâmica de revisões é, na verdade, uma continuidade do estudo por questões, explorando com mais ênfase os pontos que você tiver mais dificuldade.',
			<>
				<br />
				<br />
			</>,
			'Todos os temas precisam ser dominados!',
		],
		bgColor: '#0D0D0D',
		titleColor: '#FF005D',
		descriptionColor: 'secondary',
		// url: 'https://player.vimeo.com/video/769929881',
	},
	{
		title: 'Avaliação de desempenho',
		description: [
			'A regra é dominar temas! A avaliação de desempenho e domínio dos temas será diária, usando métricas do estudo por questões.',
			<>
				<br />
				<br />
			</>,
			'Assim que o grau de suficiência em um tema for atingido, ele estará dominado! Domine todos e migre para a próxima liga de aprendizado. "E se eu tiver dificuldade"? Calma! Siga a trilha...',
		],
		bgColor: '#0D0D0D',
		titleColor: '#FF005D',
		descriptionColor: 'secondary',
		// url: 'https://player.vimeo.com/video/769929824',
	},
	{
		title: 'Estude com professor',
		description: [
			'Teve dificuldade? O professor estudará contigo!',
			<>
				<br />
				<br />
			</>,
			'Isso mesmo... vamos abrir o livro juntos, entender frase por frase, parágrafo por parágrafo, o tema ou seu fragmento!',
			<>
				<br />
				<br />
			</>,
			'Vamos fazer isso usando a principal referência disponível, que estará à sua disposição na biblioteca virtual do Extensivo. Após o estudo em conjunto, nova avaliação de desempenho!',
		],
		bgColor: '#0D0D0D',
		titleColor: '#FF005D',
		descriptionColor: 'secondary',
		// url: 'https://player.vimeo.com/video/769929949',
	},
	{
		title: 'Próxima liga',
		description: [
			'A evolução é contínua, são 12 ligas no total. Na liga do aprendizado seguinte teremos novos temas, ou fragmentos complementares de temas anteriores.',
			<>
				<br />
				<br />
			</>,
			'Mas não pense que os já vistos serão esquecidos... jamais! As revisões serão contínuas, até o fim!',
		],
		bgColor: '#ffffff',
		titleColor: '#121212',
		descriptionColor: 'primary',
		// url: 'https://player.vimeo.com/video/769929780',
	},
];

const useInterval = function (callback, delay) {
	const savedCallback = useRef();
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);
	useEffect(() => {
		let id = setInterval(() => {
			savedCallback.current();
		}, delay);
		return () => clearInterval(id);
	}, [delay]);
};

export default function ExtensivoR120232024() {
	const classes = useStyles();
	const theme = useTheme();
	const subscribe_card_extensivo_r1_23_24 = useRef(null);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const [glitchArea] = useState([
		'Clínica Médica',
		'Cirurgia Geral',
		'Ginecologia e Obstetrícia',
		'Pediatria',
		// 'Medicina Preventiva',
	]);
	const [glitchIndex, setGlitchIndex] = useState(0);
	useInterval(() => {
		setGlitchIndex(glitchIndex >= 3 ? 0 : glitchIndex + 1);
	}, 3000);

	const executeScroll = () =>
		subscribe_card_extensivo_r1_23_24.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className="residencia"
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<FirstSection
					backgroundImageUrl={BackgroundImage}
					logoUrl={ExtensivoR12324Logo}
					type="red"
					buttonText="Quero me inscrever"
					executeScroll={executeScroll}
					firstTextPaper={[
						<Typography
							key={0}
							variant="h1"
							className={`${classes.gradientText} gradient-text roboto-condensed`}
						>
							Hora de se preparar para
							<br />
							as provas em sintonia
							<br />
							com o internato
						</Typography>,
						<Typography
							key={1}
							align="left"
							color="secondary"
							className={`${classes.text} poppins`}
						>
							O Extensivo de 2 anos do Hardwork traz a revolução do estudo por questões acoplada à rotina
							do internato!
						</Typography>,
					]}
				/>
				<Grid
					container
					direction={matchesMobile ? 'column-reverse' : 'row'}
					className={`${classes.containerSecondSection} section`}
				>
					<Grid
						item
						container
						alignContent="flex-end"
						xs={7}
						className={classes.gridTextSecondSection}
					>
						<Typography
							align="left"
							color="secondary"
							className={`${classes.textSecondSection} poppins`}
						>
							Estás no rodízio de{' '}
							<span className={'inlineChildren nowrapChildren'}>
								<GlitchClip duration={3000}>
									<strong>{glitchArea[glitchIndex]}</strong>
								</GlitchClip>
							</span>
							?{''}
							<br />O estudo por questões do Extensivo se adapta para
							aumentar o foco na{' '}
							<span className={'inlineChildren nowrapChildren'}>
								<GlitchClip duration={3000}>
									<strong>{glitchArea[glitchIndex]}</strong>
								</GlitchClip>
							</span>
						</Typography>
					</Grid>
					<Grid
						item
						xs={5}
					>
						<img
							src={StudyingImage}
							alt=""
						/>
					</Grid>
				</Grid>
			</Container>

			{/* Trilha do Aprendizado */}
			<LearningPath
				title={'A Revolução: A Trilha do aprendizado definitivo'}
				cardsInfo={learningPathTemplate}
				background={'background-gradient-residencia'}
			/>

			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					container
					alignContent="center"
					justifyContent="flex-start"
					className="section residencia"
					style={{ backgroundImage: `url(${TeachersBanner})`, height: 212, paddingLeft: 30 }}
				>
					<Typography
						align="left"
						variant="h1"
						className={`${classes.gradientText} gradient-text roboto-condensed`}
					>
						12 Ligas de Aprendizado
					</Typography>
					<Typography
						align="left"
						color="secondary"
						className={`${classes.text} poppins`}
					>
						Os temas são organizados conforme complexidade e frequência nas provas,
						<br />
						respeitando o foco que tens no internato.
						<br />
						Tu defines as áreas que deseja priorizar na trilha do aprendizado.
					</Typography>
				</Grid>
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className="section"
				>
					<Grid
						item
						xs={12}
						container
						style={matchesMobile ? { marginBottom: 16 } : null}
					>
						<Typography
							className={`${classes.subtitle1} roboto-condensed`}
							color="secondary"
						>
							Além da trilha do aprendizado você também ganha
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
					>
						<DiferencialBox
							title="Banco de lives Hardwork"
							description="Acesso imediato ao banco de lives do Hardwork Medicina, com discussões de mais de 200 dos  principais temas voltados para provas de residência médica."
							details="Acesso ao banco de lives com temas das diversas áreas, numa conversa informal e olho-no-olho, no padrão consagrado do Hardwork Medicina."
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
					>
						<DiferencialBox
							title="Acesso aos principais livros"
							description={[
								'Apostila? De jeito nenhum!',
								<>
									<br />
								</>,
								`Tu vais estudar junto com o professor pelas grandes obras!`,
							]}
							details="Sim! No extensivo tu terás acesso a milhares de obras consagradas...Vamos estudar pelas principais referências. Tudo JUNTO com o professor! Vamos evoluir frase por frase dentro dos problemas explorados pelas questões. Nada de se restringir a textos resumidos! Vamos dominar as principais fontes! Vamos te mostrar como."
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
					>
						<DiferencialBox
							title="Fala professor!"
							description={[
								'A base do aprendizado é a relação mestre-aprendiz!',
								<>
									<br />
								</>,
								'No Hardwork o seu contato com o professor será direto!',
							]}
							details="Não existe escola em que o aluno não fale com o professor! Aqui o contato é direto, todos os alunos têm acesso aos professores, tanto nos times, quanto via whatsapp."
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
					>
						<DiferencialBox
							title="Banco de questões"
							description="Mais de 100 mil questões de provas dos principais concursos de residência do Brasil."
						/>
					</Grid>
				</Grid>
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className="section"
				>
					<Grid
						item
						xs={12}
						container
					>
						<Grid
							item
							xs={12}
							container
						>
							<Typography
								className={`${classes.subtitle1} roboto-condensed`}
								color="secondary"
							>
								Receba também
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
						>
							<DiferencialBox
								title="Simulados com comentários em vídeo"
								description="Simulados mensais a partir de abril de 2024, com análise de desempenho e comentários em vídeo."
								details="A partir de abril de 2024 teremos simulados mensais, com questões de provas antigas, com objetivo único de identificar lacunas na preparação e permitir ajustes no estudo enquanto há tempo. Nada de ranking! Aqui fazemos análise de desempenho questão por questão...além disso, todos os simulados trazem consigo questões comentadas em texto e em vídeos pelos professores. Ah, mas se tu quiseres, podes configurar um simulado para fazer a qualquer momento!"
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
						>
							<DiferencialBox
								title="Desempenho por concurso"
								description="Cada prova tem sua alma."
								details="Vamos te mostrar toda a tua evolução de acordo com as provas que farás.. Afinal, cada prova tem seus temas de predileção, sua forma de abordar cada assunto."
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
						>
							<DiferencialBox
								title="Grupos de discussão"
								description="Grupos com professores, mentores e alunos Hardwork, para discussão de tudo relacionado às provas!
Seguiremos juntos, sempre!"
								details="Tu farás parte de um time com um grupo de hardworkers, capitaneado por um tutor HWM... o objetivo? Aprender com os pares! Troca de informações, de opiniões, auxílio na orientação dos estudos, na resolução daquelas questões aparentemente espinhosas...Ah, no grupo também estão todos os professores do HWM."
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
						>
							<DiferencialBox
								title="Resumos Hardwork"
								description="Resumos dos principais temas explorados nas provas de residência médica."
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className="section"
				>
					<Grid
						item
						xs={12}
						container
						className="residencia"
						style={matchesMobile ? { paddingBottom: '1em' } : { paddingBottom: '2em' }}
					>
						<Typography
							className={`${classes.subtitle1} roboto-condensed`}
							color="secondary"
						>
							E no segundo semestre de 2024, na hora da revisão
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						container
					>
						<Grid
							item
							xs={4}
							container
							alignContent={matchesMobile ? 'center' : null}
						>
							{matchesMobile ? (
								<img
									src={SprintFinalMobile}
									alt=""
								/>
							) : (
								<img
									src={SprintHwmLogoSection}
									alt=""
								/>
							)}
						</Grid>
						<Grid
							item
							xs={8}
							container
							alignContent="center"
							style={{ paddingLeft: '2em' }}
						>
							<Typography
								color="secondary"
								className={`${classes.thirdSectionText} poppins`}
							>
								Acesso garantido a todos os <strong>Sprints Finais 2024*</strong> para provas de
								residência: a única revisão realmente focada em provas
							</Typography>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						container
						alignContent="center"
						justifyContent="flex-start"
						style={{ margin: '40px 0px 20px' }}
					>
						{exams.map((item, index) => (
							<Grid
								item
								xs={6}
								md={2.4}
								lg={2.4}
								key={index}
								container
								justifyContent="center"
								style={{ margin: '8px 0' }}
							>
								<TestCard imageUrl={item.imageUrl} />
							</Grid>
						))}
					</Grid>
					<Grid
						item
						xs={12}
						container
						alignContent="center"
						justifyContent="flex-start"
					>
						<Typography
							color="secondary"
							className={`${classes.thirdSectionText} poppins`}
						>
							*Sprints previstos para 2024. Listagem sujeita a alterações para mais ou para menos.
						</Typography>
					</Grid>
				</Grid>
			</Container>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className="section"
					ref={subscribe_card_extensivo_r1_23_24}
				>
					<PriceCardVersion2
						idTurma={'1174'}
						// imageBackground={'../../Assets/Extensivo/R3CM/cardBackground.png'}
						logo={ExtensivoR12324Logo}
						iconBtn={BagIcon}
						// parcel={'30'}
						// valuePerParcel={'536,04'}
					/>
				</Grid>
				{/* <Grid
					container
					alignContent="center"
					justifyContent="center"
					className="section"
				>
					<Grid
						item
						xs={12}
						container
						className="residencia"
						style={matchesMobile ? { paddingBottom: '1em' } : { paddingBottom: '2em' }}
					>
						<Typography
							className={`roboto-condensed`}
							color="secondary"
							style={
								matchesMobile
									? { fontSize: '1.6rem', fontWeight: 'bold' }
									: { fontSize: '3.5rem', fontWeight: 'bold', lineHeight: 'unset' }
							}
						>
							Ainda não se convenceu?
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						container
						spacing={matchesMobile ? 0 : 3}
						rowSpacing={matchesMobile ? 2 : 0}
					>
						{MigrationTexts.map((item, index) => {
							return (
								<Grid
									item
									xs={12}
									md={6}
									key={index}
								>
									<MigrationCard
										id={index}
										key={index}
										title={item.title}
										description={item.description}
										matchesMobile={matchesMobile}
										matchesTablet={matchesTablet}
									/>
								</Grid>
							);
						})}
					</Grid>
				</Grid> */}
				{/* <Grid
					container
					alignContent="center"
					justifyContent="center"
					className="section"
				>
					<Grid
						container
						alignContent="center"
						justifyContent="flex-start"
						className="section residencia"
					>
						<Typography
							align="left"
							variant="h1"
							className={`${classes.gradientText} gradient-text roboto-condensed`}
						>
							O quê já estão falando sobre o novo Extensivo R1?
						</Typography>
					</Grid>
				</Grid> */}
			</Container>

			<StudentsFeedbackSection
				type="extensivo"
				title="O que andam falando do Hardwork..."
			/>

			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{/* <Grid
					container
					alignContent="center"
					justifyContent="center"
					className={classes.section}
				>
					<Grid
						item
						xs={12}
						style={{ paddingTop: '2em' }}
						container
						spacing={3}
					>
						{data.doctors.map((item, index) => (
							<Grid
								item
								xs={3}
								key={index}
							>
								<DoctorCard
									name={item.name}
									specialty={item.specialty}
									type="residencia"
									insta={item.insta}
								/>
							</Grid>
						))}
					</Grid>
				</Grid> */}
				{/* TO DO DEPOIS
				<FaqSection /> */}
				{/* <StudentsFeedbackSection type="residencia" /> */}
				{/* <Newsletter type="residencia" /> */}
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.5em !important',
		lineHeight: '1 !important',
	},
	gradientText: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '2.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.68rem !important',
		},
	},
	firstText: {
		fontSize: '2.75rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
		},
	},
	text: {
		fontSize: '1.75rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
			marginTop: '8px !important',
		},
	},
	img: {
		width: '100%',
	},
	firstSectionPaper: {
		color: 'unset !important',
		height: '100%',
		borderRadius: '30px !important',
		padding: '2.5rem 1.5em',
		paddingTop: 20,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			padding: '1rem !important',
		},
	},
	firstSectionCard: {
		height: '100%',
		position: 'absolute',
		right: '0',
		[theme.breakpoints.down('md')]: {
			position: 'unset !important',
			right: 'unset !important',
			height: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'unset !important',
			top: 'unset !important',
		},
	},
	gridItem: {
		padding: '.5em 0',
	},
	subtitle1: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('md')]: {
			lineHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
			lineHeight: 'unset !important',
		},
	},
	thirdSectionText: {
		fontSize: '1.12rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	gridTextSecondSection: {
		paddingBottom: 70,
		[theme.breakpoints.down('lg')]: {
			paddingBottom: 35,
		},
	},
	textSecondSection: {
		fontSize: '1.85rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.4rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	containerSecondSection: {
		margin: '0 auto',
		position: 'relative',
		bottom: 109,
		[theme.breakpoints.down('md')]: {
			position: 'unset',
		},
	},
}));
