import React from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import { Header } from '../../../../Components/Header';

import InstagramLive from '../../../../Assets/Extensivo/R1/2023/instagramLive.png';
import LiveLogo from '../../../../Assets/Extensivo/R1/2023/liveLogo.svg';

import Form from '../../../../Components/Utils/Forms/PreRegistration';

export default function LiveResidencia2023() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					alignContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
				>
					<Grid
						item
						xs={12}
						container
						direction={matchesMobile ? 'column' : 'row'}
						alignContent="center"
						className="residencia"
						style={matchesMobile ? { marginBottom: 8 } : { marginBottom: 16 }}
					>
						<img
							src={LiveLogo}
							alt="Live logo"
							style={matchesMobile ? { width: '40%' } : null}
						/>
						<Typography
							className={`${classes.title} gradient-text roboto-condensed`}
							color="secondary"
							style={matchesTablet ? { marginTop: 16, paddingLeft: 0 } : { paddingLeft: 15 }}
						>
							Quando e como começar a<br />
							preparação para prova de residência?
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						md={7}
						container
						style={{ paddingBottom: '1em' }}
					>
						<Typography
							align="left"
							color="secondary"
							className={`${classes.text} poppins`}
						>
							1 ou 2 anos de preparação? Como organizar a rotina? Essas são questões muito importantes que
							exigem um papo sério! Vamos ter uma conversa crítica sobre tudo que envolve a preparação
							para as provas.
							{/* O professor <strong>Fabrício Valois</strong> vai desmistificar as grandes dúvidas sobre o
							início da preparação para as provas de Residência, quebrar os mitos que muitos têm te
							contado e oferecer uma visão crítica sobre o assunto. Tudo para que você direcione da melhor
							maneira os seus esforços àquilo que realmente importa no momento certo! */}
						</Typography>
						<img
							src={InstagramLive}
							alt="Live"
							className={classes.imgExtensivo}
						/>
						<Grid
							item
							xs={12}
							container
							justifyContent={matchesTablet ? 'center' : 'flex-start'}
							style={matchesMobile ? null : { position: 'absolute', bottom: 10, paddingLeft: 16 }}
						>
							<Typography
								align="left"
								color="secondary"
								className={`${classes.impactText} poppins`}
							>
								13FEV às 19h30H
							</Typography>
							{matchesTablet ? null : (
								<Grid item>
									<Typography
										align="left"
										color="secondary"
										className={`${classes.impactText} poppins`}
										style={
											matchesMobile
												? {
														backgroundColor: '#FF4800',
														fontWeight: 'bold',
														background:
															'linear-gradient(transparent 9%, rgb(255, 35, 34) 9%, transparent 150%, rgba(0, 212, 255, 0) 64%)',
												  }
												: {
														backgroundColor: '#FF4800',
														fontWeight: 'bold',
														background:
															'linear-gradient(transparent 25%, rgb(255, 35, 34) 25%, transparent 90%, rgba(0, 212, 255, 0) 64%)',
												  }
										}
									>
										Inscreva-se para acompanhar AO VIVO!
									</Typography>
								</Grid>
							)}
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						md={5}
						container
						justifyContent="center"
						alignContent="flex-end"
						style={{ paddingBottom: 50 }}
					>
						<Form
							group="Live Extensivo-2023-2024"
							title={['Inscreva-se para', <br />, 'acompanhar AO VIVO!']}
							titleButton="Confirmar inscrição"
							type="residencia"
						/>
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.8rem !important',
		lineHeight: '1 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '3.2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.8rem !important',
		},
	},
	text: {
		fontSize: '1.324rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	bottomSignInCardText: {
		fontSize: '1.15rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
	},
	divider: {
		margin: 'unset !important',
		backgroundColor: 'grey',
		minHeight: 1,
	},
	workHourTitle: {
		fontSize: '1.7em !important',
		lineHeight: 1.25,
		fontWeight: 'bold !important',
	},
	containerWorkHours: {
		padding: '2em 0 2em 2em',
		[theme.breakpoints.down('lg')]: {
			padding: 0,
		},
	},
	workHourPaper: {
		height: '100%',
		[theme.breakpoints.down('lg')]: {
			height: 'unset !important',
		},
	},
	imgExtensivo: {
		[theme.breakpoints.down('md')]: {
			margin: '40px 0 !important',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '0px !important',
		},
	},
	impactText: {
		fontSize: '2.25rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.6rem !important',
		},
	},
}));
