import React, { useEffect, useRef, useState } from 'react';
import { Container, Divider, Grid, Paper, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

// COMPONENTS
import Footer from '../../../Components/Footer';
import { Header } from '../../../Components/Header';
import DefaultButton from '../../../Components/Buttons/DefaultButton';
// import Form from '../../../Components/Utils/Forms/PreRegistration';
import { Link } from 'react-router-dom';
import Api from '../../../Services/Api';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';
import IntlTelInput from 'react-intl-tel-input';

const BlurContainer = ({ matchesTablet, setIsOpenModal, group, setEmbedUrl, liveId }) => {
	const classes = useStyles();
	const [typeSubmit, setTypeSubmit] = useState(false);
	const [subscription, setSubscription] = useState({
		agrupamento: group ? group : '',
		nome: '',
		email: '',
		telefone: '',
		pesquisa1: '',
		pesquisa2: '',
	});

	let sendPreInscricao = function (event) {
		event.preventDefault();
		const nomeCompleto = subscription.nome.trim();
		const nomeSobrenome = nomeCompleto.split(' ');

		if (!subscription.nome || nomeSobrenome.length < 2) {
			alert('Ops... Por favor informe seu nome e sobrenome');
			return;
		}
		if (!subscription.email || !subscription.email.includes('@') || !subscription.email.includes('.')) {
			alert('Ops... Por favor preencha seu email');
			return;
		}
		if (!subscription.telefone) {
			alert('Ops... Por favor preencha seu telefone com um número válido');
			return;
		}

		setTypeSubmit(true);

		trackPromise(
			Api.post('PreInscricao', subscription).then((res) => {
				if (res) {
					Swal.fire({
						title: `${'Massa!'} `,
						text: ``,
						icon: 'success',
						confirmButtonText: 'Fechar',
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then(() => {
						setIsOpenModal(false);
						setEmbedUrl(
							`https://hardworkmedicina.com.br/correcao-prova/${liveId}?email=${subscription.email}`
						);
					});
				}
			})
		);
	};

	const handlePhone = (valid, phoneNumber) => {
		if (valid) {
			setSubscription({
				...subscription,
				telefone: phoneNumber,
			});
		} else if (subscription.telefone.length) {
			setSubscription({
				...subscription,
				telefone: '',
			});
		}
	};

	return (
		<Grid
			item
			xs={12}
			container
			alignContent="center"
			justifyContent="center"
			className={classes.glassContainer}
		>
			<Paper
				className={`${classes.paper} rounded-card`}
				elevation={4}
				style={{
					rowGap: matchesTablet ? 24 : 32,
					padding: matchesTablet ? '1rem 0.75rem' : '3.75rem 2.5rem',
					margin: matchesTablet ? '0 1rem' : '',
				}}
			>
				<DefaultButton
					variant="contained"
					color="primary"
					className={`poppins`}
					style={{
						width: 'fit-content',
						backgroundColor: '#000000',
						fontSize: '1rem',
						fontWeight: 400,
						padding: '0.25rem 1.75rem',
						lineHeight: 'unset',
					}}
					onClick={() => setIsOpenModal(false)}
				>
					Já estou cadastrado
				</DefaultButton>
				<form
					className="form-control"
					onSubmit={sendPreInscricao}
				>
					<Grid
						item
						container
						direction={'column'}
						justifyContent={'center'}
						alignItems={'center'}
						className="residencia"
						style={{
							backgroundColor: '#EBEBEB',
							padding: matchesTablet ? '2.5rem 1rem' : '2.5rem 2rem',
							rowGap: 32,
							borderRadius: 24,
						}}
					>
						<Grid
							item
							container
							direction={'column'}
							justifyContent={'center'}
							alignItems={'center'}
							rowGap={1}
						>
							<Typography
								color={'#1D1D1D'}
								fontSize={matchesTablet ? '1.75rem' : '2rem'}
								fontWeight={700}
								fontFamily={'Manrope'}
								lineHeight={matchesTablet ? 1 : '38.4px'}
							>
								Inscreva-se gratuitamente
							</Typography>
							<Typography
								color={'#303030'}
								fontSize={matchesTablet ? '1rem' : '1.5rem'}
								fontFamily={'Manrope'}
								lineHeight={matchesTablet ? 1.2 : '28.8px'}
							>
								Para acompanhar a transmissão ao vivo!
							</Typography>
						</Grid>
						<Grid
							item
							container
							justifyContent={'center'}
							alignItems={'center'}
							rowGap={2}
						>
							<Grid
								item
								xs={12}
								container
							>
								<TextField
									fullWidth
									type="string"
									name="nome"
									placeholder="Seu nome completo"
									required={true}
									InputProps={{
										className: `${classes.form2} ${classes.formHeight}`,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									onChange={(e) =>
										setSubscription({
											...subscription,
											nome: e.target.value,
										})
									}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								container
							>
								<TextField
									fullWidth
									type="email"
									name="email"
									placeholder="E-mail utilizado na sua inscrição"
									required={true}
									InputProps={{
										className: `${classes.form2} ${classes.formHeight}`,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									onChange={(e) =>
										setSubscription({
											...subscription,
											email: e.target.value,
										})
									}
								/>
							</Grid>
							<Grid
								item
								xs={12}
							>
								<IntlTelInput // Componente Input de telefones internacionais
									name="telefone"
									type="telefone"
									preferredCountries={['BR', 'PY', 'AR', 'CL', 'BO', 'UY', 'CU', 'PT']}
									style={{ width: '100%', border: 'unset' }}
									inputClassName={`${classes.phoneNumberForm} ${classes.formHeight}`}
									nationalMode={true}
									onPhoneNumberChange={(valid, value, selectedCountryData, fullNumber) =>
										handlePhone(valid, fullNumber)
									}
								/>
							</Grid>
						</Grid>
						<DefaultButton
							type={typeSubmit ? 'submit' : 'button'}
							variant="contained"
							color="secondary"
							className={`${classes.downloadBtn} btn-secondary-invert-gradient poppins`}
							onClick={(e) => sendPreInscricao(e)}
						>
							Inscrever-se
						</DefaultButton>
					</Grid>
				</form>
			</Paper>
		</Grid>
	);
};

const ProductsCard = ({ pathImg, url }) => {
	const classes = useStyles();

	return (
		<Grid
			container
			direction={'column'}
			justifyContent={'center'}
			alignItems={'center'}
			className={classes.gridProductsBtn}
			component={Link}
			to={url}
		>
			<img
				src={pathImg}
				alt=""
				width={'100%'}
			/>
			<DefaultButton
				variant={'outlined'}
				fontWeight={500}
				className={`${classes.productsBtn} btn poppins`}
			>
				Saiba mais
			</DefaultButton>
		</Grid>
	);
};

export default function CorrectionLiveResidencia2024() {
	const classes = useStyles();
	const theme = useTheme();
	const embeddedContent = useRef(null);
	const [exam, setExam] = useState('');
	const [id, setId] = useState(0);
	const [embedUrl, setEmbedUrl] = useState('');
	const [correctionGroupInfo, setCorrectionGroupInfo] = useState({});
	const [isOpenModal, setIsOpenModal] = useState(true);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		const path = window.location.pathname;
		const parts = path.split('-');
		const currentId = parts[parts.length - 1];
		setId(currentId);
		setEmbedUrl(`https://hardworkmedicina.com.br/correcao-prova/${currentId}`);

		if (currentId > 0) {
			Api.get(`provasCorrecao`).then((res) => {
				const groups = res;
				const currentGroup = groups.find((group) => {
					return group?.id.toString() === currentId.toString();
				});
				setCorrectionGroupInfo({ ...currentGroup });
			});
		}

		let lastPart;

		if (
			path.includes('psu-go') ||
			path.includes('psu-mg') ||
			path.includes('usp-sp') ||
			path.includes('sus-ba') ||
			path.includes('usp-rp')
		) {
			const parts = path.split('-');
			lastPart = parts[parts.length - 3] + '-' + parts[parts.length - 2];
		} else if (path.includes('santa-casa-sp')) {
			lastPart = 'Santa Casa SP';
		} else {
			const parts = path.split('-');
			lastPart = parts[parts.length - 2];
		}
		setExam(lastPart);
	}, []);

	function dateFormat(data) {
		if (data) {
			const dataObjeto = new Date(data);
			const dia = String(dataObjeto.getDate()).padStart(2, '0');

			// Meses abreviados
			const mesesAbreviados = [
				'JAN',
				'FEV',
				'MAR',
				'ABR',
				'MAI',
				'JUN',
				'JUL',
				'AGO',
				'SET',
				'OUT',
				'NOV',
				'DEZ',
			];
			const mes = mesesAbreviados[dataObjeto.getMonth()];

			// Hora formatada
			const horas = String(dataObjeto.getHours()).padStart(2, '0');
			const minutos = String(dataObjeto.getMinutes()).padStart(2, '0');

			return `${dia}${mes} • A partir das ${horas}H${minutos}`;
		} else {
			return null;
		}
	}

	return (
		<Container
			fixed
			maxWidth="xl"
			disableGutters={true}
		>
			{matchesMobile || matchesTablet ? null : <Header />}
			<Grid
				container
				className="section"
				style={matchesMobile ? { marginTop: 80 } : { marginBottom: 0 }}
			>
				<Grid
					item
					xs={12}
					md={4}
					container
					direction={'column'}
					rowGap={matchesTablet ? 2 : 3.5}
				>
					<img
						src={
							require(correctionGroupInfo?.nome?.includes('SUS-BA')
								? './Assets/logo-ldl-correcao-sus-ba.svg'
								: correctionGroupInfo?.nome?.includes('PSU-MG')
								? './Assets/logo-ldl-correcao-psu-mg.svg'
								: './Assets/logo-ldl-correcao-sus-sp.svg').default
						}
						// src={require('./Assets/logo-ldl-correcao-enare.svg').default}
						alt=""
						style={{ width: 'fit-content' }}
					/>
					<Typography
						fontFamily={'Manrope'}
						color={'#F1F1F1'}
						className={classes.text}
					>
						Correção ao vivo da prova, com comentários das questões mais polêmicas
						{correctionGroupInfo?.nome?.includes('SUS-BA')
							? ' do SUS-BA'
							: correctionGroupInfo?.nome?.includes('PSU-MG')
							? ' do PSU-MG'
							: correctionGroupInfo?.nome?.includes('USP')
							? ' da USP'
							: ' do SUS-SP'}{' '}
						e download de recursos em PDF!
					</Typography>
					<Grid
						item
						container
						alignItems={matchesTablet && 'center'}
						direction={'column'}
						width={matchesTablet ? '100%' : 'fit-content'}
						style={{
							marginTop: matchesTablet && '1rem',
							borderRadius: matchesTablet ? '8px' : '16px',
							padding: '0.5rem 0',
						}}
					>
						<Typography
							fontFamily={'Manrope'}
							fontSize={matchesTablet ? '1.25rem' : '1.5rem'}
							fontWeight={800}
							color={'#FCFCFC'}
						>
							{dateFormat(correctionGroupInfo?.data_correcao)}
						</Typography>
						<Typography
							fontFamily={'Manrope'}
							fontSize={matchesTablet ? '1rem' : '1.25rem'}
							fontWeight={800}
							color={'#FCFCFC'}
						>
							Evento online e gratuito
						</Typography>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					md={8}
					container
					style={{
						backgroundImage: `url(${require('./Assets/ldl-teachers.png')})`,
						backgroundSize: 'cover',
						height: matchesTablet && 250,
					}}
				></Grid>
				<Grid
					item
					xs={12}
				>
					<Divider
						variant="middle"
						className={classes.divider}
					/>
				</Grid>
				{id > 0 && (
					<Grid
						item
						xs={12}
						ref={embeddedContent}
					>
						{isOpenModal && correctionGroupInfo?.agrupamento_pre_inscricao && (
							<BlurContainer
								matchesMobile={matchesMobile}
								// handleSubscription={handleSubscription}
								// setUserEmail={setUserEmail}
								// setLiveIsOpen={setLiveIsOpen}
								group={correctionGroupInfo?.agrupamento_pre_inscricao}
								setEmbedUrl={setEmbedUrl}
								liveId={id}
								matchesTablet={matchesTablet}
								setIsOpenModal={setIsOpenModal}
							/>
						)}
						<iframe
							id={`iframe-correcao`}
							title="correcao"
							src={embedUrl}
							style={{
								border: 0,
								width: '100%',
								height: matchesMobile ? '600px' : '830px',
								backgroundColor: 'transparent',
							}}
						></iframe>
					</Grid>
				)}
			</Grid>
			<Grid
				container
				justifyContent={'center'}
				className="section"
				style={{ marginTop: 0 }}
			>
				<Grid
					item
					xs={12}
				>
					<Divider
						variant="middle"
						className={classes.divider}
					/>
				</Grid>
				{/* <Grid
					item
					xs={12}
					md={5}
					container
					justifyContent="center"
					style={{ margin: '2rem 0 3rem' }}
				>
					<Form
						group={'LIVE DE CORREÇÃO'}
						title={['Inscreva-se para ter acesso ao', <br />, 'gabarito e live de correção!']}
						titleButton="Confirmar inscrição"
						titleConfirmationMessage={'Massa!'}
						confirmationMessage={
							'Sua inscrição está confirmada! Acesse o player da live acima com o e-mail cadastrado.'
						}
						type="residencia"
						urlToRedirect={`/live/correcao-${
							window.location.pathname === '/live/correcao-santa-casa-sp-123' ? 'santa-casa-sp' : exam
						}-${id}`}
						exam={exam}
					/>
				</Grid> */}
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					<Grid
						container
						className="section"
						rowGap={4}
						style={{
							marginTop: matchesTablet && '0',
						}}
					>
						<Typography
							color={'secondary'}
							fontFamily={'Manrope'}
							style={{
								fontWeight: 'bold',
								fontSize: matchesTablet ? '1.5rem' : '2.75rem',
								lineHeight: matchesTablet && 1.25,
							}}
						>
							Conheça outros produtos Hardwork
						</Typography>
						<Grid
							item
							xs={12}
							container
							justifyContent={'space-between'}
							rowGap={2}
						>
							<Grid
								item
								xs={12}
								md={5.75}
							>
								<ProductsCard
									pathImg={require('../HandsOn/Assets/logo-ldl-residencia.svg').default}
									url={'/live-das-lives'}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								md={5.75}
							>
								<ProductsCard
									pathImg={require('../HandsOn/Assets/logo-sf-2024.svg').default}
									url={'/sprint-final'}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</Grid>
			<Footer />
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	paper: {
		borderRadius: '21px !important',
		width: '50%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			padding: '1rem 0.75rem',
			borderRadius: '1rem !important',
		},
	},
	text: {
		fontSize: '1.5rem !important',
		lineHeight: '32.78px !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '1rem !important',
			lineHeight: '21.86px !important',
		},
	},
	divider: {
		margin: '24px 0px !important',
		backgroundColor: 'grey',
		minHeight: 1,
	},
	gridProductsBtn: {
		backgroundColor: '#1d1d1d',
		padding: '1rem',
		borderRadius: 16,
		height: '100%',
		rowGap: '8px',
		[theme.breakpoints.down('md')]: {
			rowGap: '14.76px',
		},
	},
	productsBtn: {
		padding: '0 3rem !important',
		border: '1.5px solid #fff !important',
		width: 'fit-content',
		fontSize: '1.125rem !important',
		fontWeight: '500 !important',
		color: '#ffffff !important',
		[theme.breakpoints.down('md')]: {
			color: '#040404 !important',
			fontSize: '1rem !important',
			backgroundColor: '#ffffff !important',
			padding: '0 2rem !important',
		},
	},
	glassContainer: {
		backdropFilter: 'blur(5px)',
		height: '100%',
		width: '100%',
		position: 'absolute',
		zIndex: 1,
	},
	form2: {
		borderRadius: '8px !important',
		backgroundColor: theme.palette.secondary.main,
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'transparent !important',
		},
		'& .MuiOutlinedInput-input': {
			height: '0.75em',
		},
	},
	formHeight: {
		maxHeight: '48px !important',
		[theme.breakpoints.down('xl')]: {
			maxHeight: '40.8px !important',
		},
		[theme.breakpoints.down('sm')]: {
			maxHeight: '45px',
		},
	},
	downloadBtn: {
		fontSize: '1.5rem !important',
		padding: '0.25rem 4rem !important',
		color: '#fff !important',
		fontWeight: '700 !important',
		width: 'fit-content !important',
		columnGap: 8,
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.75rem !important',
			minHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
			padding: '0.5rem 1.6rem !important',
			width: '100%',
		},
	},
	phoneNumberForm: {
		height: '2.75rem',
		borderRadius: 8,
		width: '100%',
		border: 'unset',
	},
}));
