import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default function SprintFinalRevalida() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Grid
			container
			rowGap={2}
			style={{ padding: '0 0 0 2rem' }}
		>
			<Grid
				item
				xs={12}
				container
				rowGap={4}
				justifyContent={'center'}
			>
				<img
					src={require('../Assets/logotipo-trilha-R1-2025.png')}
					alt=""
				/>
			</Grid>
			{/* <Grid
				item
				xs={12}
				container
				alignItems={'center'}
				columnGap={matchesTablet ? 1 : 1.5}
				className="residencia"
			>
				<Typography
					align="left"
					color="primary"
					className={`${classes.title}`}
					style={{ color: 'white' }}
					fontFamily={'Manrope'}
				>
					Revisão com foco total na
				</Typography>
				<Typography
					align="left"
					color="primary"
					className={`${classes.title} gradient-text-revalida`}
					fontFamily={'Manrope'}
					style={{ fontWeight: 600 }}
				>
					prova do Revalida INEP.
				</Typography>
			</Grid> */}
			<Typography
				align="left"
				color="secondary"
				className={`${classes.text}`}
				fontFamily={'Manrope'}
				style={{ fontWeight: 600 }}
			>
				Inscreva-se e fique por dentro de todas as novidades do Sprint Final Revalida 25.1, a revisão focada na
				primeira fase da prova do Revalida INEP 25.1.
			</Typography>

			{/* <Typography
				align="left"
				color="secondary"
				className={`${classes.text}`}
				fontFamily={'Manrope'}
				style={{ fontWeight: 600 }}
			>
				Iremos dominar o Revalida INEP mais uma vez.
			</Typography> */}
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: '2.5rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.375rem !important',
		},
	},
	subtitle: {
		fontSize: '1.25rem !important',
		lineHeight: '1.25 !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	text: {
		fontSize: '1.75rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	impactText: {
		fontWeight: 'bold !important',
		fontSize: '1.75rem !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.125rem !important',
		},
	},
}));
