import React from 'react';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Api from '../../../../../Services/Api';
import DefaultButton from '../../../../../Components/Buttons/DefaultButton';
import { useState } from 'react';

import R3CMCardBackground from '../../../../../Assets/Extensivo/R3CM/cardBackground.png';
import EXTR120232024CardBackground from '../../../../../Assets/Extensivo/R1/2023-24/cardBackground.png';

// import R3CGCardBackground from '../../Assets/Extensivo/R3CG/cardBackground.png';

export default function PriceCardSprintTemplate({
	idTurma,
	/* imageBackground */ logo,
	iconBtn,
	idomedStudents,
	bgImageUrlLeft,
	bgImageUrlRight,
	color,
	discount,
}) {
	const classes = useStyles(idTurma);
	const theme = useTheme();
	const [turma, setTurma] = useState([]);
	// const [turmaMock] = useState(true);

	const matchesDown361 = useMediaQuery(theme.breakpoints.down(361));
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		Api.get(`turmasabertas/${idTurma}`).then((res) => {
			if (res) setTurma(res[0]);
		});
	}, [idTurma]);

	return (
		<Grid
			container
			alignContent={matchesTablet ? 'space-around' : 'center'}
			justifyContent="space-between"
			className={classes.extensivoR1Card}
			style={
				color
					? {
							border: '2px solid black',
							padding: matchesTablet ? '1rem 0' : 0,
							height: discount ? 'unset' : null,
					  }
					: null
			}
		>
			<Grid
				item
				xs={12}
				md={7}
				container
				justifyContent="center"
				alignContent="center"
				className={classes.gridImg}
				// FIX this
				style={
					bgImageUrlLeft
						? { backgroundImage: `url(${bgImageUrlLeft})`, backgroundSize: 'cover' }
						: {
								backgroundImage: `url(${
									idTurma === '1174' || idTurma === '1157' || idTurma === '1187' || idTurma === '1203'
										? EXTR120232024CardBackground
										: idTurma === '1175'
										? R3CMCardBackground
										: null
									// AINDA NÃO TEM CÓDIGO DE R3CG
									// : idTurma === '1176'
									// ? R3CGCardBackground
									// : null
								})`,
						  }
				}
			>
				<img
					src={logo}
					alt="Logo Extensivo R1 2023/2024"
					className={classes.extensivoLogoImg}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				md={5}
				container
				justifyContent="center"
				alignContent="center"
				className={classes.gridPriceInfoRight}
				style={
					bgImageUrlRight
						? { backgroundImage: `url(${bgImageUrlRight})`, backgroundSize: 'cover' }
						: {
								backgroundImage: `url(${
									idTurma === '1174' || idTurma === '1157' || idTurma === '1187' || idTurma === '1203'
										? EXTR120232024CardBackground
										: idTurma === '1175'
										? R3CMCardBackground
										: null
									// AINDA NÃO TEM CÓDIGO DE R3CG
									// : idTurma === '1176'
									// ? R3CGCardBackground
									// : null
								})`,
						  }
				}
				gap={window.location.pathname === '/sprint-final-revalida' ? 1 : 0}
			>
				<Grid
					item
					container
					justifyContent={turma.encerrada ? 'center' : 'flex-start'}
					alignContent="center"
					style={
						matchesMobile
							? { width: turma.encerrada ? null : '75%' }
							: turma.encerrada
							? { width: '70%', margin: '2rem 0' }
							: { width: '70%', marginBottom: 20 }
					}
				>
					{turma.encerrada ? (
						<>
							{/* <img
									src={require('../../Assets/Img/img-soon-stripe.svg').default}
									alt=""
									className={classes.soonStripeImg}
								/> */}
							<Typography
								color={'secondary'}
								align="left"
								className={`${classes.subscriptionInactiveText} roboto-condensed`}
							>
								{/* INSCRIÇÕES{!matchesTablet || !matchesMobile ? <br /> : null} ENCERRADAS */}
								INSCRIÇÕES ENCERRADAS
							</Typography>
							{/* <Typography
									color={'primary'}
									align="left"
									className={`${classes.text} poppins`}
									style={{ margin: '0.25rem 0' }}
								>
									Quer ter informações quando novas turmas
									<br />
									estiverem disponíveis? Inscreva-se aqui.
								</Typography> */}
						</>
					) : (
						<>
							{/* {promotionalValueIsOver ? null : (
								<Typography
									color={color ? 'secondary' : 'primary'}
									className={classes.priceTextStrikeThrough}
								>
									R$ {turma.valor?.toLocaleString('pt-BR')} <sup style={{ fontSize: '61%' }}>,00</sup>
								</Typography>
							)} */}
							<Typography
								color={color ? 'primary' : 'secondary'}
								className={classes.priceTextWhite}
							>
								R$ {turma.valor?.toLocaleString('pt-BR')}
								<sup style={{ fontSize: '61%' }}>,00</sup>
							</Typography>
							<Typography className={classes.priceTextGrey}>
								ou até{' '}
								{turma?.parcelas && turma.parcelas.length > 0
									? turma.parcelas[turma.parcelas.length - 1].qtd
									: ''}
								x de R$
								{turma?.parcelas && turma.parcelas.length > 0
									? turma.parcelas[turma.parcelas.length - 1].valor
									: ''}
								{' no cartão de crédito*'}
							</Typography>
						</>
					)}
				</Grid>
				{turma.encerrada ? null : (
					<Grid
						item
						container
						alignContent={'center'}
						justifyContent={'center'}
						className="residencia"
						style={matchesTablet ? null : { height: '4rem' }}
					>
						<DefaultButton
							variant="outlined"
							color="secondary"
							className={`${classes.btn} btn button-info-card btn-primary`}
							component={Link}
							to={`//hardworkmedicina.com.br/inscricao/${idTurma}`}
						>
							{matchesMobile ? null : (
								<img
									src={iconBtn}
									alt=""
								/>
							)}
							&nbsp; Prosseguir para inscrição
						</DefaultButton>
					</Grid>
				)}
				{discount && !turma.encerrada ? (
					<Grid
						item
						container
						// justifyContent={matchesTablet ? 'center' : 'flex-start'}
						alignContent={'center'}
						style={matchesMobile ? { width: '75%' } : { width: '70%' }}
					>
						<Typography
							style={{
								color: '#ffffff',
								fontSize: matchesDown361 ? '0.65rem' : matchesMobile ? '0.7rem' : '0.8rem',
							}}
						>
							Desconto de 20% para ex-alunos Extensivo Revalida, Sprint Revalida e/ou Arena Revalida.*
						</Typography>
					</Grid>
				) : null}
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	extensivoR1Card: {
		borderRadius: 13,
		border: '2px solid white !important',
		height: 252,
		[theme.breakpoints.down('md')]: {
			height: 'unset',
		},
		[theme.breakpoints.down('sm')]: {
			// width: '90% !important',
		},
	},
	gridImg: {
		height: '100%',
		borderBottomLeftRadius: 15,
		[theme.breakpoints.down('md')]: {
			height: 'unset',
			backgroundColor: '#0D0D0D !important',
			borderTopRightRadius: 15,
			borderTopLeftRadius: 15,
			borderBottomRightRadius: 0,
			borderBottomLeftRadius: 0,
		},
		[theme.breakpoints.down('sm')]: {
			padding: 25,
			borderTopRightRadius: 15,
			borderBottomRightRadius: 0,
			borderBottomLeftRadius: 0,
		},
	},
	extensivoLogoImg: {
		maxHeight: '60%',
		[theme.breakpoints.down('md')]: {
			width: 350,
			padding: '0px',
			maxHeight: '80%',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0px 10px',
		},
	},
	gridPriceInfo: {
		backgroundColor: '#0D0D0D !important',
		borderTopLeftRadius: 15,
		[theme.breakpoints.down('sm')]: {
			padding: '8px 0 ',
			borderTopLeftRadius: 15,
		},
		[theme.breakpoints.down('md')]: {
			height: 'unset',
			borderTopLeftRadius: 15,
			borderBottomRightRadius: 0,
			borderBottomLeftRadius: 0,
		},
	},
	gridPriceInfoRight: {
		backgroundColor: '#0D0D0D !important',
		borderBottomRightRadius: 15,
		borderTopRightRadius: 15,

		[theme.breakpoints.down('sm')]: {
			padding: '8px 0 ',
			borderBottomRightRadius: 15,
			borderBottomLeftRadius: 15,
			borderTopRightRadius: 0,
		},
		[theme.breakpoints.down('md')]: {
			borderBottomRightRadius: 15,
			borderBottomLeftRadius: 15,
			borderTopRightRadius: 0,
		},
	},

	priceTextStrikeThrough: {
		fontSize: '2.5rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		textDecoration: 'line-through',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.15rem !important',
		},
	},
	priceTextWhite: {
		fontSize: '4.5rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '3.25rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '4.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.5rem !important',
		},
		[theme.breakpoints.down(376)]: {
			fontSize: '2.25rem !important',
		},
	},
	priceTextGrey: {
		fontSize: '1.2rem !important',
		color: '#ffffff !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.25rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down(376)]: {
			fontSize: '1rem !important',
		},
	},
	btn: {
		width: '70%',
		fontSize: '1.35rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		borderColor: 'transparent',
		border: '2px solid #fff !important',
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				minWidth: '72% !important',
				fontSize: '1.5rem !important',
				borderWidth: '3px !important',
				backgroundColor: '#00543b !important',
				transition: '0.1s',
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1rem !important',
			width: '80%',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			width: '90%',
		},
	},
	discount: {
		fontSize: '1.1rem',
		color: 'red',
		alignSelf: 'center',
		marginTop: '0.5em',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			margin: '0.5em 0',
		},
	},
	disclaimerText: {
		fontSize: '1rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	subscriptionInactiveText: {
		fontSize: '3.25rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
			margin: '1rem !important',
		},
	},
	soonStripeImg: {
		position: 'absolute',
		top: -18,
		right: -90,
		[theme.breakpoints.down('xl')]: {
			right: -77,
		},
	},
}));
