import React, { useEffect, useRef, useState } from 'react';
import { Container, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

// COMPONENTS
import Footer from '../../Components/Footer';
import { Header } from '../../Components/Header';
import FirstSectionHardworq from '../../Components/Sections/FirstSectionHardworq';

// ASSETss
import hardworqCellphone2 from '../../Assets/HardworQ/hardworq-cellphone-version2.png';
import bancoQuestoes from '../../Assets/HardworQ/banco-questoes.svg';
import metricasIcon from '../../Assets/HardworQ/metricas.svg';
import livesIcon from '../../Assets/HardworQ/lives-icon.svg';
import featuresIcon from '../../Assets/HardworQ/icon-features.svg';
import flashIcon from '../../Assets/HardworQ/icon-flashcards.svg';
import challengeIcon from '../../Assets/HardworQ/challenge-icon.svg';
import FirstSectionHardworqTrial from '../../Components/Sections/FirstSectionHQTrial';
import PriceCardVersion6 from '../../Components/Cards/PriceCardVersion6';
import PriceCardVersion4 from '../../Components/Cards/PriceCardVersion4';
import PriceCardHQTrial from '../../Components/Cards/PriceCardHQTrial';
import DefaultButton from '../../Components/Buttons/DefaultButton';
import saleIcon from '../../Assets/HardworQ/sale-icon.svg';

export default function HardworkQ2024Trial() {
	const classes = useStyles();
	const theme = useTheme();
	const subscribe_card_trilha_r3 = useRef(null);
	const [scroll, setScroll] = useState();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesLg = useMediaQuery(theme.breakpoints.down('xl'));

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.scrollY;
			if (scrollPosition > 500) {
				setScroll(true);
			} else {
				setScroll(false);
			}
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const hasOnApp = [
		{
			img: bancoQuestoes,
			subtitle: 'Mais de 150 mil questões',
			textContent: 'Acesso a mais de 150 mil questões, sendo mais de 40 mil comentadas.',
		},
		{
			img: featuresIcon,
			subtitle: '180 Aulas com a didática Hardwork',
			textContent: 'Acesso imediato a mais de 180 aulas, dos temas mais frequentes em provas.',
		},
		{
			img: livesIcon,
			subtitle: 'Mais de 200 Lives Hardwork',
			textContent:
				'Acesso imediato ao banco de lives do Hardwork Medicina, com discussões de mais de 200 dos principais temas de medicina.',
		},
		{
			img: flashIcon,
			subtitle: 'Flashcards',
			textContent:
				'Milhares de Flashcards Hardwork para auxiliar na rotina de estudos e revisões. No HardworQ você também cria e armazena os seus próprios flashcards!* ',
		},
		{
			img: challengeIcon,
			subtitle: 'Desafio diário',
			textContent:
				'Ajuste sua meta diária, de acordo com sua realidade e seu objetivo. Quantas questões podes fazer hoje?',
		},
		{
			img: metricasIcon,
			subtitle: 'Métricas exclusivas',
			textContent: 'Monitore seu desempenho e sua evolução.',
		},
	];

	const executeScroll = () =>
		subscribe_card_trilha_r3.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
			</Container>
			{scroll ? (
				<DefaultButton
					id="myBtn"
					variant="outlined"
					color={'secondary'}
					className={`${classes.btnFirstSection} poppins`}
					onClick={() => executeScroll()}
				>
					<img
						src={saleIcon}
						alt=""
					/>
					&nbsp;Teste por 90 dias!
				</DefaultButton>
			) : null}
			<FirstSectionHardworqTrial
				backgroundImageUrl={require('../../Assets/HardworQ/hardworq-colorful-bg.png')}
				logoUrl={require('../../Assets/HardworQ/logo-hq-premium-trial.svg').default}
				googleLogo={require('../../Assets/HardworQ/badges-google.svg').default}
				appleLogo={require('../../Assets/HardworQ/badges-apple.svg').default}
				browserLogo={require('../../Assets/HardworQ/badges-browser.svg').default}
				hqLogo={require('../../Assets/HardworQ/logo-hardworq.svg').default}
				// buttonText="Quero me inscrever"
				type="purple"
				executeScroll={executeScroll}
				firstTextPaper={[
					<Typography
						align="left"
						color="secondary"
						className={classes.firstText}
					>
						O aplicativo que revoluciona o estudo por questões
					</Typography>,
					<Divider
						variant="middle"
						className={classes.divider}
					/>,
					<Typography
						variant="h1"
						className={`${classes.gradientText} cirurgia-geral gradient-text-hardworkq roboto-condensed`}
					>
						HardworQ Premium
					</Typography>,
					<Typography
						align="left"
						color="secondary"
						className={`${classes.text} poppins`}
					>
						Eleve o estudo por questões para outro nível com desafios diários, métricas de desempenho,
						flashcards e muito mais.
					</Typography>,
					<Typography
						align="left"
						color="secondary"
						style={{ flexWrap: 'nowrap' }}
						className={`${classes.text} poppins`}
					>
						Cadastre-se ou acesse sua conta e{' '}
						<span
							style={{ display: 'inline', fontWeight: 'bold' }}
							className={`${classes.text} gradient-text-hardworkq poppins`}
						>
							Teste grátis
						</span>{' '}
						por 90 dias!
					</Typography>,
				]}
				imageProps={{ left: 0, top: '9.75em', width: '55%' }}
			/>

			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className={classes.featuresSection}
					style={{ padding: '0 3rem' }}
				>
					<Grid
						item
						xs={12}
						container
						className={classes.featureItem}
						direction={matchesMobile ? '' : 'row-reverse'}
					>
						<Grid
							item
							lg={6}
							xs={12}
							container
							className={classes.imgContainer}
						>
							<Grid
								item
								sm={12}
								container
								justifyContent={'flex-end'}
							>
								<img
									src={hardworqCellphone2}
									alt=""
									className={classes.focusImg}
								/>
							</Grid>
						</Grid>
						<Grid
							item
							lg={6}
							xs={12}
							container
						>
							<Typography
								className={`${classes.subtitle1} roboto-condensed`}
								color="secondary"
							>
								O que é o HardworQ?
							</Typography>
							<Typography
								className={`${classes.textDescription} poppins`}
								color="secondary"
							>
								O HardworQ é muito mais que um banco de questões!
								<br />
								<br />
								O HardworQ te desafia diariamente, e tu podes definir uma meta de questões, filtrar por
								temas ou concursos específicos e provas-irmãs.
								<br />
								<br />
								E se cansar de questões, que tal transitar por Flashcards? Sim, o HardworQ não tem
								limites…
								<br />
								<br />O HardworQ te faz ir além de questões e comentários…tenha acesso a um banco com
								centenas de aulas e lives dos temas mais importantes para provas, todos com o rigor
								didático do Hardwork.
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Container>
			<Container
				maxWidth={false}
				disableGutters={true}
				style={{ padding: '0 3rem', marginTop: '4rem' }}
			>
				<Typography
					className={`${classes.subtitle1} roboto-condensed`}
					color="secondary"
					style={{ paddingLeft: matchesMobile ? '1%' : matchesLg ? '5%' : '10%' }}
				>
					O que tem no HardworQ
				</Typography>
				<Grid
					container
					className={classes.section}
					style={{
						padding: matchesTablet ? '' : matchesLg ? '0 4rem' : '  ',
					}}
				>
					{hasOnApp.map((e, index) => (
						<Grid
							item
							key={index}
							xs={matchesMobile ? 12 : 6}
							style={{ marginTop: '3rem' }}
							className={classes.flexCenter}
						>
							<Grid
								container
								direction="column"
								alignItems="flex-start"
							>
								<Grid
									item
									maxWidth={'70%'}
									style={
										!matchesMobile
											? { display: 'flex', gap: '1rem', alignItems: 'center' }
											: { display: 'flex', flexDirection: 'column', gap: '1rem' }
									}
								>
									<Grid item>
										<img
											src={e.img}
											alt=""
										/>
									</Grid>
									<Typography
										style={{ fontWeight: 'bold' }}
										className={`${classes.tituloHq} poppins`}
										color={'secondary'}
									>
										{e.subtitle}
									</Typography>
								</Grid>
								<Grid
									maxWidth={'80%'}
									style={{ display: 'flex' }}
								>
									<Typography
										className={`${classes.text} poppins`}
										color={'secondary'}
									>
										{e.textContent}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					))}
					<Grid
						container
						alignContent={'end'}
						style={{ margin: '3rem 0' }}
					>
						<Typography style={{ fontStyle: 'italic', color: 'grey' }}>
							*Liberação progressiva entre julho e setembro de 2024.
						</Typography>
					</Grid>
				</Grid>
			</Container>
			<Grid
				container
				className="white-background"
			>
				<Grid
					container
					style={{ padding: '3rem 5%' }}
					className={classes.backgroundWaves}
				>
					<Container
						fixed
						maxWidth="xl"
						disableGutters={true}
					>
						<Grid
							className={classes.section}
							container
							rowGap={2}
						>
							<Grid
								container
								alignContent="center"
								justifyContent="center"
								className={`hardworq-price-card`}
								style={{
									backgroundColor: 'black',
									borderRadius: 15,
									// margin: matchesTablet ? '40px auto' : '80px auto',
								}}
								ref={subscribe_card_trilha_r3}
							>
								<PriceCardHQTrial
									idTurma={1209}
									bgImageUrl={require('../../Assets/HardworQ/bg-hardworq-premium.png')}
									logo={require('../../Assets/HardworQ/logo-hq-premium-trial.svg').default}
									playLogo={require('../../Assets/HardworQ/badges-google-new.svg').default}
									iosLogo={require('../../Assets/HardworQ/badges-apple-new.svg').default}
									pcLogo={require('../../Assets/HardworQ/badges-browser-new.svg').default}
									color
									saleIcon={saleIcon}
								/>
							</Grid>
						</Grid>
					</Container>
				</Grid>
			</Grid>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesTablet ? null : <Footer />}
			</Container>

			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '0 auto',
	},
	subtitle1: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('md')]: {
			lineHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
			lineHeight: 'unset !important',
		},
	},
	subtitle2: {
		fontWeight: 'bold !important',
		fontSize: '3.75rem !important',
		lineHeight: '1 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
		},
	},
	tituloHq: {
		fontSize: '1.75rem !important',

		[theme.breakpoints.down('lg')]: {
			fontSize: '2.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	aboutSection: {
		// display: 'flex',
		// flexDirection: 'row',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column !important',
			// marginBottom: '-3rem !important',
			// maxWidth: 'fit-content !important'
		},
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column !important',
			// marginBottom: '-17rem !important',
			// maxWidth: 'fit-content !important'
		},
		[theme.breakpoints.down('xs')]: {
			// marginBottom: '4rem !important'
		},
	},
	featuresSection: {
		// marginBottom: '4rem !important',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column !important',
			// marginBottom: '-3rem !important',
			// maxWidth: 'fit-content !important'
		},
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column !important',
			// marginBottom: '-17rem !important',
			// maxWidth: 'fit-content !important'
		},
		[theme.breakpoints.down('xs')]: {
			// marginBottom: '4rem !important'
		},
	},
	featureItem: {
		marginRight: 'auto !important',
		[theme.breakpoints.down('md')]: {
			maxWidth: 'fit-content !important',
			// marginBottom: '1rem !important',
			// top: '30rem',
			// maxWidth: 'fit-content !important'
		},
		[theme.breakpoints.down('sm')]: {
			// flexDirection: 'column !important',
			maxWidth: 'fit-content !important',
			// marginBottom: '1rem !important',
			// top: '22rem',
		},
	},
	imgContainer: {
		// marginBottom: '8rem !important',
		// [theme.breakpoints.down('sm')]: {
		// 	top: -440,
		// 	right: 100,
		// 	marginBottom: '-8rem !important',
		// },
	},
	focusImg: {
		// position: 'absolute !important',
		// top: -538,
		// zIndex: 1,
		// right: -24,
		// width: '60%',
		// [theme.breakpoints.up('xl')]: {
		// 	top: -850,
		// 	right: -150,
		// 	width: '70%',
		// },
		// [theme.breakpoints.down('lg')]: {
		// 	left: 350,
		// 	top: -430,
		// 	width: '75%',
		// },
		// [theme.breakpoints.down('md')]: {
		// 	left: -144,
		// 	top: -450,
		// 	width: '100%',
		// 	maxWidth: 'unset !important',
		// 	// position: 'unset !important',
		// },
		// [theme.breakpoints.down('sm')]: {
		// 	position: 'unset !important',
		// 	left: '-8rem',
		// 	top: '-16rem',
		// 	marginRight: '4rem !important',
		// 	maxWidth: 'unset !important',
		// },
		// [theme.breakpoints.down('sm')]: {
		//     position: 'unset !important',
		//     left: 0,
		//     top: 'auto',
		//     margin: '0 auto',
		//     width: '100% !important',
		//     maxWidth: '100% !important',
		//     height: 'auto',
		//   },
	},

	comparision: {
		marginTop: '8rem !important',
		[theme.breakpoints.down('md')]: {
			marginTop: '30rem !important',
			gap: '0 !important',
			margin: '15px !important',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '-4rem',
			gap: '0 !important',
			margin: '15px !important',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '0px !important',
			margin: '5rem 0 !important',
			// marginTop: '5rem !important'
		},
	},
	comparisionItens: {
		// [theme.breakpoints.down('sm')]: {
		//     marginTop: '2rem !important',
		// },

		[theme.breakpoints.up('sm')]: {
			maxWidth: '300px !important',
		},
		// [theme.breakpoints.up('xs')]: {
		// 	maxWidth: 'unset !important',
		// },
	},
	firstItem: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	contentBeneftItens: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
			// padding: '1rem',
		},
	},
	liteContent: {
		[theme.breakpoints.down('md')]: {
			width: '75% !important',
			padding: '1rem !important',
			left: 10,
		},
		[theme.breakpoints.down('sm')]: {
			width: '75% !important',
			padding: '1rem !important',
			left: 10,
		},
	},
	hqContent: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.5rem !important',
			padding: '1rem !important',
		},
	},
	gradientText: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		lineHeight: '1.5 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '2.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.68rem !important',
		},
	},
	text: {
		fontSize: '1.3rem !important',
		lineHeight: '1.25 !important',
		marginTop: '8px !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	textDescription: {
		fontSize: '1.8rem !important',
		lineHeight: '1.25 !important',
		marginTop: '8px !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.35rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	firstText: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '2.1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
		},
	},
	textMultimidiaCard: {
		fontSize: '1rem !important',
		fontWeight: 'bold !important',
		alignSelf: 'center',
	},
	backgroundWaves: {
		backgroundImage: `url(${process.env.PUBLIC_URL + '/Assets/Img/bgWave2.png'})`,
		width: '100vw !important',
	},
	divider: {
		backgroundColor: '#6D6D6D',
		minHeight: 1,
		width: '100%',
		margin: '1.5rem 0 1rem !important',
		[theme.breakpoints.down('sm')]: {
			margin: '0.5rem 0 0.5rem !important',
		},
	},
	thirdSectionText: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.75rem !important',
		},
	},
	timerGrid: {
		position: 'fixed',
		bottom: '2rem',
		zIndex: 3,
		border: '3px solid #8F4AE6',
		margin: '0 auto !important',
		backgroundColor: '#000000d9',
		padding: '0.75rem 8rem 0.75rem 4rem',
		borderRadius: '25px 50px 50px 25px !important',
		[theme.breakpoints.down(1770)]: {
			padding: '0.75rem 8rem 0.75rem 4rem',
		},
		[theme.breakpoints.down(1240)]: {
			padding: '0.75rem 9rem 0.75rem 4rem',
		},
		[theme.breakpoints.down('md')]: {
			padding: '0.75rem 2rem 0.75rem 3rem',
			borderRadius: '15px !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0.75rem 1rem',
		},
	},
	btnFirstSection: {
		// display: 'none',
		position: 'fixed !important',
		zIndex: '1500',
		border: '5px solid black !important',
		width: 'fit-content',
		padding: '0 3rem !important',
		bottom: '3rem',
		right: '3rem',
		fontSize: '1.425rem !important',
		fontWeight: 'bold !important',
		background: 'linear-gradient(90deg, #FF005D 0%, #D52A8A 50%, #9F38B9 100%)',
		'&:hover': {
			scale: 1.05,
			color: '#ffffff',
			'& $logoBtn': {
				filter: 'invert(0%) !important',
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
			minHeight: 'unset !important',
			width: 'fit-content',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
			width: 'fit-content',
		},
	},
	flexCenter: {
		[theme.breakpoints.down('sm')]: {
			padding: '0 !important',
		},
	},
}));
