import React from 'react';
import { CircularProgress, useMediaQuery, useTheme } from '@mui/material';

export default function LoadingProgress() {
	const theme = useTheme();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<div
			style={{
				width: '100vw',
				height: '100vh',
				backgroundColor: '#00000066',
				zIndex: 9999,
				top: 0,
				position: 'absolute',
			}}
		>
			<CircularProgress
				size={150}
				sx={{
					color: '#4caf50',
					left: matchesMobile ? '30%' : '45%',
					top: matchesMobile ? '20%' : '35%',
				}}
			/>
		</div>
	);
}
