import React, { useRef, useState } from 'react';

//LIBRARIES
import { makeStyles } from '@mui/styles';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Splide, SplideSlide } from '@splidejs/react-splide';

//COMPONENTS
import LearningSpliterCard from '../Cards/LearningSpliterCard';

//ASSETS
// import BgWave2 from '../../Assets/Img/bgWave2.png';
import SimpleArrow from '../../Assets/Svg/simpleArrow.svg'; 

export default function LearningVersion03({ title, cardsInfo, cardsInfoTwo, cardsInfoThree, background }) {
	const classes = useStyles();
	const theme = useTheme();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
	const matchesXl = useMediaQuery(theme.breakpoints.up('xl'));

	const [isCardCollapse, setIsCardCollapse] = useState([]);

	const splideRef = useRef();
	const splideRefTwo = useRef();
	const splideRefThree = useRef();

	const handleCollapsedCards = (id) => {
		if (isCardCollapse.includes(id)) {
			const arrayFiltered = isCardCollapse.filter((i) => i !== id);
			setIsCardCollapse(arrayFiltered);
		} else {
			const newArr = [...isCardCollapse, id];
			setIsCardCollapse(newArr);
		}
	};

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
			className={`${background}`}
		>
			<Grid
				container
				alignContent="center"
				justifyContent="center"
				className={`${classes.backgroundWaves} ${classes.carouselSection}`}
			>
				<Container
					maxWidth={false}
					disableGutters={true}
				>
					<Container
						fixed
						maxWidth="xl"
						disableGutters={true}
					>
						<Grid
							item
							xs={12}
							container
							className={classes.gridTitle}
						>
							<Typography
								className={`${classes.subtitle1} roboto-condensed`}
								color="#fff"
								style={matchesMobile ? { marginLeft: '2rem' } : null}
							>
								{title}
							</Typography>
						</Grid>
					</Container>
					<Grid
						item
						container
						className={classes.carouselGrid}
						justifyContent={'center'}
						xs={12}
					>
						<Grid
							item
							xs={12}
							sm={6}
							md={3}
						>
							{cardsInfo.obj.length > 0 && (
								<>
									<Splide
										options={{ arrows: false, autoplay: false, type: 'loop', speed: 20 }}
										ref={splideRef}
									>
										{cardsInfo.obj.map((item, index) => {
											return (
												<SplideSlide key={index}>
													<Grid
														item
														container
														justifyContent={'space-around'}
													>
														<Grid
															item
															key={index}
														>
															<LearningSpliterCard
																key={index}
																handleShow={() => handleCollapsedCards(cardsInfo?.id)}
																isMany={cardsInfo.obj.length > 1 ? true : false}
																isOpenCard={
																	matchesMobile
																		? isCardCollapse?.includes(cardsInfo?.id)
																		: true
																}
																nextSlide={() => splideRef.current.splide.go('+1')}
																{...item}
															/>
														</Grid>
													</Grid>
												</SplideSlide>
											);
										})}
									</Splide>
								</>
							)}
						</Grid>
						{!matchesMd ? (
							<img
								src={SimpleArrow}
								alt=""
								style={matchesXl ? { margin: '0 0.5rem 0 0.5rem' } : { margin: '0 1rem 0 1rem' }}
							/>
						) : (
							<Grid
								container
								justifyContent="center"
								alignContent="center"
								style={{ transform: 'rotate(90deg)', marginBottom: '25px' }}
							>
								<img
									src={SimpleArrow}
									alt=""
									style={matchesMobile ? { width: '10%' } : null}
								/>
							</Grid>
						)}

						<Grid
							item
							xs={12}
							sm={6}
							md={3}
						>
							{cardsInfoTwo.obj.length > 0 && (
								<Splide
									options={{ arrows: false, autoplay: false, type: 'loop', speed: 20 }}
									ref={splideRefTwo}
								>
									{cardsInfoTwo.obj.map((item, index) => {
										return (
											<SplideSlide>
												<Grid
													item
													container
													justifyContent={'space-around'}
												>
													<Grid
														item
														key={index}
													>
														<LearningSpliterCard
															key={index}
															handleShow={() => handleCollapsedCards(cardsInfoTwo?.id)}
															isMany={cardsInfoTwo.obj.length > 1 ? true : false}
															isOpenCard={
																matchesMobile
																	? isCardCollapse?.includes(cardsInfoTwo?.id)
																	: true
															}
															nextSlide={() => splideRefTwo.current.splide.go('+1')}
															{...item}
														/>
													</Grid>
												</Grid>
											</SplideSlide>
										);
									})}
								</Splide>
							)}
						</Grid>
						{!matchesMd ? (
							<img
								src={SimpleArrow}
								alt=""
								style={matchesXl ? { margin: '0 0.5rem 0 0.5rem' } : { margin: '0 1rem 0 1rem' }}
							/>
						) : (
							<Grid
								container
								justifyContent="center"
								alignContent="center"
								style={{ transform: 'rotate(90deg)', marginBottom: '25px' }}
							>
								<img
									src={SimpleArrow}
									alt=""
									style={matchesMobile ? { width: '10%' } : null}
								/>
							</Grid>
						)}
						<Grid
							item
							xs={12}
							sm={6}
							md={3}
						>
							{cardsInfoThree.obj.length > 0 && (
								<Splide
									options={{ arrows: false, autoplay: false, type: 'loop', speed: 20 }}
									ref={splideRefThree}
								>
									{cardsInfoThree.obj.map((item, index) => {
										return (
											<SplideSlide>
												<Grid
													item
													container
													justifyContent={'space-around'}
												>
													<Grid
														item
														key={index}
													>
														<LearningSpliterCard
															key={index}
															handleShow={() => handleCollapsedCards(cardsInfoThree?.id)}
															isMany={cardsInfoThree.obj.length > 1 ? true : false}
															isOpenCard={
																matchesMobile
																	? isCardCollapse?.includes(cardsInfoThree?.id)
																	: true
															}
															nextSlide={() => splideRefThree.current.splide.go('+1')}
															{...item}
														/>
													</Grid>
												</Grid>
											</SplideSlide>
										);
									})}
								</Splide>
							)}
						</Grid>
					</Grid>
				</Container>
			</Grid>
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	backgroundWaves: {
		backgroundImage: `url(${process.env.PUBLIC_URL + '/Assets/Img/bgWave2.png'})`,
		width: '100vw !important',
	},
	carouselSection: {
		padding: '2.5%',
		paddingLeft: '10%',
		[theme.breakpoints.down(1656)]: {
			paddingLeft: '8%',
		},
		[theme.breakpoints.down(1407)]: {
			paddingLeft: '6%',
		},
		[theme.breakpoints.down(1311)]: {
			paddingLeft: '2%',
		},
		[theme.breakpoints.down(1069)]: {
			paddingLeft: '6% !important',
		},
		[theme.breakpoints.down(933)]: {
			paddingLeft: '0% !important',
		},
		[theme.breakpoints.down(821)]: {
			paddingLeft: '10% !important',
		},
		[theme.breakpoints.down('sm')]: {
			height: 'unset !important',
			paddingLeft: 'unset !important',
			padding: '20px 2.5%',
		},
	},
	gridTitle: {
		padding: '0 0 2em 1em',
		[theme.breakpoints.down('sm')]: {
			margin: '20px 0px 20px 20px',
		},
	},
	subtitle1: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('md')]: {
			lineHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
			lineHeight: 'unset !important',
		},
	},
	carouselGrid: {
		display: 'flex',
	},
	arrowBtn: {
		position: 'absolute !important',
		zIndex: 1000,
		borderRadius: '50% !important',
		width: 80,
		height: 80,
		backgroundColor: '#2D2D2D !important',
		opacity: 0.75,
		'&:hover': { opacity: 1, transition: '0.2s' },
		[theme.breakpoints.down('sm')]: {
			display: 'none !important',
		},
	},
}));
