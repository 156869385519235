import React, { useEffect, useState } from 'react';
import { Button, Container, Divider, Grid, Paper, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Header } from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import Api from '../../../Services/Api';
import Form from '../../../Components/Utils/Forms/PreRegistration';
import { useRef } from 'react';

const PdfBox = ({ title, pdfUrl }) => {
	const classes = useStyles();

	const handleDownloadPdf = () => {
		const pdfPath = pdfUrl;
		const link = document.createElement('a');
		link.href = pdfPath;
		link.target = '_blank';
		link.click();
	};

	return (
		<Grid
			item
			container
			direction={'column'}
			alignContent={'center'}
			className={classes.pdfBox}
			gap={1}
			onClick={() => handleDownloadPdf()}
		>
			<img
				src={require('../../../Assets/Arena/img-pdf.svg').default}
				alt=""
				style={{ width: 40, alignSelf: 'center' }}
			/>
			<Typography
				color="secondary"
				className={`${classes.titlePdfBox} poppins`}
				align="center"
			>
				{title}
			</Typography>
		</Grid>
	);
};

const LiveCard = ({ id, liveId, date, handleCurrentLive }) => {
	const classes = useStyles();
	const currentDate = new Date();

	const isActive = currentDate >= new Date(date);

	function dateFormat(data) {
		if (data) {
			const dataObjeto = new Date(data);
			const dia = String(dataObjeto.getDate()).padStart(2, '0');
			const mes = String(dataObjeto.getMonth() + 1).padStart(2, '0'); // Adicionar +1 pois o mês começa em zero (janeiro é 0)
			return `${dia}/${mes}`;
		} else {
			return null;
		}
	}

	const handleLiveOption = () => {
		handleCurrentLive(id);
	};

	return (
		<Grid
			item
			xs={5}
			lg={1.5}
			container
			onClick={isActive ? () => handleLiveOption(liveId) : null}
			className={`${classes.liveCard} ${isActive ? 'background-revalida' : null}`}
			style={{ cursor: isActive ? 'pointer' : null, opacity: isActive ? null : 0.5 }}
		>
			<Grid
				item
				container
				alignContent={'center'}
				columnGap={1}
				style={{ backgroundColor: '#17171A', borderRadius: 6, padding: '0.2rem 0.5rem' }}
			>
				<img
					src={require('../../../Assets/Svg/Icons/Revalida/icon-player-gray.svg').default}
					alt=""
				/>
				<Typography
					color="#C4C4CB"
					className={`${classes.discussText} poppins`}
				>
					DISCUSSÃO 0{id + 1}
				</Typography>
			</Grid>
			<Typography
				color="secondary"
				className="poppins"
				style={{ fontWeight: 'bold', fontSize: '2rem', marginLeft: '0.5rem' }}
			>
				{dateFormat(date)}
			</Typography>
		</Grid>
	);
};

export default function LiveDiscussionRevalida() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));

	const [idCurrentLive, setIdCurrentLive] = useState(1);

	const [lives, setLives] = useState([]);
	const [liveIsOpen, setLiveIsOpen] = useState(false);
	const [userEmail, setUserEmail] = useState('');
	const formRef = useRef(null);
	// const [pdfCount, setPdfCount] = useState(0);

	// useEffect(() => {

	// 	const currentLive = livesDiscussionRevalida.find((live) => live.id === livesDiscussionRevalida.id_live_vigente);

	// 	if (currentLive) {
	// 		let totalPdfs = 0;

	// 		currentLive.estacoes.forEach((estacao) => {
	// 			totalPdfs += estacao.pdfs.length;
	// 		});

	// 		setPdfCount(totalPdfs);
	// 	}
	// }, [liveId]);

	// useEffect(() => {
	// 	console.log(liveIsOpen);
	// }, [liveIsOpen]);

	useEffect(() => {
		Api.get('livesDiscussao').then((res) => {
			if (res) {
				setLives(res);
			}
		});
	}, []);

	const handleSubscription = () => {
		if (userEmail) {
			Api.get(
				`preInscricao/${encodeURIComponent('LIVE ESTAÇÕES REVALIDA INEP')}/${encodeURIComponent(userEmail)}/`
			).then((res) => {
				if (res > 0) {
					setLiveIsOpen(true);
				} else {
					alert('Insira o e-mail cadastrado para acessar a live.');
				}
			});
		} else {
			alert('Insira o e-mail cadastrado para acessar a live.');
		}
	};

	const executeScroll = () => formRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					justifyContent="center"
					alignContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
				>
					<Grid
						item
						xs={12}
						container
						className={`${classes.gridTextContainer}`}
						gap={2}
					>
						<img
							src={require('../../../Assets/Arena/logo-live-revalida.svg').default}
							alt="Logo React Revalida"
							style={matchesMobile ? { width: '25%' } : null}
						/>
						<Typography
							className={`${classes.title} roboto-condensed`}
							color="secondary"
						>
							Discussão de estações da prova prática Revalida
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<Divider
							variant="middle"
							className={classes.divider}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						container
						style={{ padding: '1rem 0' }}
					>
						<Typography
							color="secondary"
							className="poppins"
						>
							Inscreva-se para acompanhar a live e visualizar os cards das estações que serão utilizados.
						</Typography>
					</Grid>
					<Grid
						item
						container
						className={matchesMobile ? null : 'section'}
						alignContent={'flex-end'}
						justifyContent={'space-between'}
						style={matchesMobile ? { margin: '20px auto' } : { margin: '60px auto' }}
					>
						{!liveIsOpen && (
							<Grid
								item
								container
								alignContent="center"
								justifyContent="center"
								className={classes.glassContainer}
							>
								<Paper
									className={`${classes.paper} rounded-card background-revalida`}
									elevation={4}
								>
									<Grid
										container
										rowGap={1}
									>
										<Grid
											item
											xs={12}
										>
											<Typography
												color="secondary"
												className="poppins"
												style={{ fontWeight: '600' }}
											>
												Para assistir à live e visualizar os cards, identifique-se com seu
												e-mail utilizado na inscrição.
											</Typography>
										</Grid>
										<Grid
											item
											xs={12}
										>
											<TextField
												fullWidth
												type="email"
												name="email"
												placeholder="E-mail utilizado na sua inscrição"
												required={true}
												InputProps={{
													className: `${classes.form}`,
												}}
												InputLabelProps={{
													shrink: true,
												}}
												onChange={(e) => setUserEmail(e.target.value)}
											/>
										</Grid>
										<Grid
											container
											justifyContent={'center'}
											alignContent={'center'}
											direction={'column'}
											rowGap={1}
											style={{ marginTop: matchesMobile ? null : '1rem' }}
										>
											<Typography
												color="secondary"
												className={`${classes.smallText} poppins`}
											>
												Ainda não se cadastrou?{' '}
												<span
													style={{ textDecoration: 'underline', cursor: 'pointer' }}
													onClick={() => executeScroll()}
												>
													&nbsp;Clique aqui&nbsp;
												</span>{' '}
												e cadastre-se rapidamente
											</Typography>
											<Button
												style={{
													backgroundColor: '#FFFFFF',
													borderRadius: 5,
													width: 'fit-content',
													margin: '0 auto',
													padding: '0.25rem 1.5rem',
												}}
												onClick={() => handleSubscription()}
											>
												<Typography
													color="primary"
													className="poppins"
													style={{ fontSize: '1.25rem', fontWeight: 'bold' }}
												>
													Entrar
												</Typography>
											</Button>
										</Grid>
									</Grid>
								</Paper>
							</Grid>
						)}
						<Grid
							item
							xs={12}
							md={8}
							container
							justifyContent={matchesMobile ? 'center' : 'flex-start'}
						>
							<iframe
								width={matchesLg ? 600 : '1000'}
								height={matchesMobile ? '250' : matchesLg ? '337.5' : '562.5'}
								loading="lazy"
								src={lives[idCurrentLive]?.url_video}
								title="Live stream video player"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media;"
								style={{ borderRadius: '20px' }}
							></iframe>
						</Grid>
						<Grid
							item
							xs={12}
							md={4}
							container
							justifyContent="center"
							style={
								matchesLg ? { margin: '15px 0 0' } : { height: 'fit-content', paddingLeft: '1.5rem' }
							}
						>
							<Grid
								item
								container
								justifyContent={'center'}
								style={{
									backgroundColor: 'green',
									borderTopRightRadius: 15,
									borderTopLeftRadius: 15,
									padding: '0.75rem 1rem',
									height: 'fit-content',
								}}
							>
								<Typography
									className={`${classes.pdfTitleContainer} poppins`}
									color="secondary"
								>
									Clique<strong> APENAS</strong> durante a resolução das estações
								</Typography>
							</Grid>
							<Grid
								item
								container
								rowGap={3}
								className={classes.backgroundContainerPdfs}
							>
								{lives[idCurrentLive]?.estacoes.map((item, index) => {
									return (
										<Grid
											key={index}
											item
											xs={12}
											container
											alignContent={'center'}
											gap={3}
											style={{
												borderBottom:
													lives[idCurrentLive]?.estacoes?.length === index + 1
														? null
														: '2px solid #707070',
												paddingBottom:
													lives[idCurrentLive]?.estacoes?.length === index + 1
														? null
														: '1.5rem',
											}}
										>
											<Grid
												item
												container
												className={classes.gridTitleBoxes}
												alignContent={'center'}
											>
												<Typography
													className={`${classes.subtitlePdfContainer} poppins`}
													color="primary"
												>
													{item?.nome}
												</Typography>
											</Grid>
											<Grid
												item
												container
												xs={12}
												direction={'row'}
												alignContent={'center'}
												rowGap={matchesTablet ? 2 : 1}
												columnGap={!matchesTablet ? 1 : 0}
											>
												{item?.pdfs?.map((item, index) => {
													return (
														<PdfBox
															key={index}
															title={item?.nome}
															pdfUrl={item?.url_pdf}
														/>
													);
												})}
											</Grid>
										</Grid>
									);
								})}
							</Grid>
						</Grid>
					</Grid>
					<Grid
						item
						container
						rowGap={2}
					>
						<Typography
							color="secondary"
							style={{ fontSize: '1.75rem', fontWeight: 'bold' }}
							className="poppins"
						>
							Histórico de discussões
						</Typography>
						<Grid
							item
							container
							justifyContent={matchesTablet ? 'space-evenly' : null}
							rowGap={matchesTablet ? 2 : 0}
						>
							{lives.map((item, index) => {
								return (
									<>
										{index !== 0 && !matchesTablet && (
											<Grid
												item
												xs={0.6}
												container
												justifyContent={'center'}
												alignContent={'center'}
											>
												<Typography
													color="secondary"
													style={{ fontSize: '3rem' }}
												>
													••
												</Typography>
											</Grid>
										)}
										<LiveCard
											key={index}
											id={index}
											liveId={item.id}
											date={item.data}
											handleCurrentLive={setIdCurrentLive}
										/>
									</>
								);
							})}
						</Grid>
					</Grid>
					<Grid
						item
						container
						justifyContent={'center'}
						style={{ marginTop: '3rem' }}
					>
						<Grid
							item
							xs={12}
							lg={10}
							container
							justifyContent={'center'}
							style={matchesTablet ? { margin: '20px 0 40px' } : { marginBottom: 60 }}
						>
							<Button
								className={classes.btn}
								href="/arena-hardwork"
								target="_blank"
							>
								<img
									src={require('../../../Assets/Svg/Icons/bagIcon.svg').default}
									alt="Logo React Revalida"
									style={matchesTablet ? null : { width: 50 }}
								/>
								<Typography
									className={`${classes.titleButton} poppins`}
									color="secondary"
								>
									Garantir vaga no Arena
								</Typography>
							</Button>
						</Grid>
						<Grid
							item
							xs={12}
							lg={5}
							container
							justifyContent={'center'}
							ref={formRef}
						>
							<Form
								group="LIVE ESTAÇÕES REVALIDA INEP"
								title={['Inscreva-se para participar da', <br />, 'live acima']}
								titleButton="Confirmar Inscrição"
								type="revalida"
								urlToRedirect="/live-discussao-revalida-inep"
								titleConfirmationMessage={'Inscrição confirmada!'}
								confirmationMessage={'Tu serás avisado pelo email cadastrado momentos antes da live.'}
							/>
						</Grid>
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.35rem !important',
		alignSelf: 'flex-end',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
			lineHeight: 'unset !important',
			marginBottom: '8px !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
			marginBottom: '8px !important',
			lineHeight: 'unset !important',
		},
	},
	divider: {
		margin: 'unset !important',
		backgroundColor: 'grey',
		minHeight: 1,
	},
	gridTextContainer: {
		paddingBottom: '1em',
		[theme.breakpoints.down('lg')]: {
			paddingRight: '2em',
		},
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
	},
	pdfTitleContainer: {
		fontSize: '1rem !important',
	},
	titlePdfBox: {
		fontSize: '0.8rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.75rem !important',
		},
	},
	subtitlePdfContainer: {
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	gridTitleBoxes: {
		backgroundColor: '#ffffff !important',
		padding: '0.25rem 0.5rem',
		borderRadius: 5,
		width: 'fit-content !important',
	},
	backgroundContainerPdfs: {
		backgroundColor: '#33333380',
		borderBottomLeftRadius: 15,
		borderBottomRightRadius: 15,
		padding: '1.25rem',
	},
	pdfBox: {
		width: '25% !important',
		cursor: 'pointer',
		[theme.breakpoints.down('sm')]: {
			width: '33% !important',
		},
	},
	liveCard: {
		border: '2px solid #FFFFFF',
		borderRadius: 8,
		padding: '0.5rem',
		transition: '0.2s ease-in-out',
		backgroundColor: '#494952',
		'&:hover': {
			scale: 1.075,
		},
	},
	titleButton: {
		fontSize: '1.5rem !important',
		fontWeight: 'bold !important',
		marginLeft: '0.5em !important',
		textTransform: 'none',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			marginLeft: 'unset !important',
		},
	},
	btn: {
		backgroundImage: `url(${require('../../../Assets/Arena/bg-button-live-arena.png')})`,
		backgroundSize: 'cover',
		// height: 76,
		border: '2px solid #ffffff !important',
		borderRadius: '15px !important',
		width: '100%',
		transition: '0.2s !important',
		'&:hover': {
			scale: 1.025,
		},
	},
	glassContainer: {
		backdropFilter: 'blur(5px)',
		boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.37)',
		height: '100%',
		width: '100%',
		position: 'absolute',
		zIndex: 1,
		borderRadius: 15,
	},
	paper: {
		borderRadius: '21px !important',
		padding: '2rem',
		width: '50%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	form: {
		backgroundColor: '#343A40',
		borderRadius: '10px !important',
		'& .MuiOutlinedInput-input': {
			color: '#75858D',
		},
	},
	smallText: {
		fontSize: '0.8rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.7rem !important',
		},
	},
	discussText: {
		fontWeight: 'bold !important',
		fontSize: '0.8rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.7rem !important',
		},
	},
}));
