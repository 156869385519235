import { Grid, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

export default function BoxInfoWithCheckIcon({ classes, title, benefits }) {
	return (
		<Grid container>
			<Grid
				item
				xs={12}
				container
				justifyContent={'center'}
				style={{
					backgroundColor: '#303030',
					borderTopRightRadius: 15,
					borderTopLeftRadius: 15,
					padding: '0.5rem 0',
				}}
			>
				<Typography
					color="secondary"
					fontFamily={'Manrope'}
					fontWeight={800}
					fontSize={'0.875rem'}
				>
					{title}
				</Typography>
			</Grid>
			<Grid
				item
				xs={12}
				container
				justifyContent={'center'}
				rowGap={0.5}
				style={{
					padding: '0.5rem',
					backgroundColor: '#1D1D1D',
					borderBottomLeftRadius: 15,
					borderBottomRightRadius: 15,
				}}
			>
				{benefits?.map((item, index) => {
					return (
						<Grid
							key={index}
							item
							xs={12}
							container
							alignItems={'center'}
							wrap={'nowrap'}
							columnGap={0.5}
							// style={
							// 	matchesTablet
							// 		? { marginTop: index === 0 ? 6 : 6 }
							// 		: { marginTop: 'unset' }
							// }
						>
							<CheckIcon className={classes.checkIcon} />
							<Typography
								color="#CCCCCC"
								fontFamily={'Manrope'}
								fontSize={'0.75rem'}
							>
								&nbsp;{item.title}
							</Typography>
						</Grid>
					);
				})}
			</Grid>
		</Grid>
	);
}
