import { Grid, Paper, Typography } from '@mui/material';

export default function MigrationCard({ id, title, description, matchesMobile, matchesTablet, logo, color }) {
	let orderNumber = id + 1;
	return (
		<Paper
			className={`rounded-card`}
			color="secondary"
			elevation={4}
			style={
				matchesMobile
					? { minWidth: 'unset', maxWidth: '100%', padding: '1rem' }
					: matchesTablet
					? { minWidth: 'unset', maxWidth: '100%', padding: '1.2rem' }
					: {
							width: '100%',
							height: 265,
							minWidth: 420,
							padding: '1.5rem',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
					  }
			}
		>
			<Grid
				container
				style={matchesMobile ? { marginBottom: 5 } : { marginBottom: 10 }}
			>
				<Typography
					color="primary"
					style={
						matchesMobile
							? { fontSize: '1.5rem', fontWeight: 'bold', lineHeight: 'unset' }
							: { fontSize: '2.2rem', fontWeight: 'bold', lineHeight: 'unset' }
					}
					className="roboto-condensed"
				>
					{<span style={{ color: color }}>{orderNumber + '.'}</span>}
					&nbsp;{title}&nbsp;
				</Typography>
				{logo && id === 3 ? (
					<img
						src={logo}
						alt=""
						style={matchesMobile ? { width: '35%' } : null}
					/>
				) : null}
			</Grid>
			<Grid style={{ overflowY: 'auto' }}>
				<Typography
					color="primary"
					className="poppins"
					style={matchesMobile ? { fontSize: '0.8rem' } : null}
				>
					{description}
				</Typography>
			</Grid>
		</Paper>
	);
}
