import React, { useEffect, useState } from 'react';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

// Components
import DefaultButton from '../../../../../Components/Buttons/DefaultButton';
import { makeStyles } from '@mui/styles';
import CountDownTimer from '../../../Sale/LDL/components/CountDownTimer';

export default function MockCard({ mockInfo }) {
	const classes = useStyles();
	const theme = useTheme();
	const [isPerformanceOpen, setIsPerformanceOpen] = useState(false);
	const [currentDate, setCurrentDate] = useState(new Date());
	const mockTestDate = new Date(mockInfo?.data_hora_liberacao);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const [mockTestState, setMockTestState] = useState('');

	function dateFormat(data) {
		if (data) {
			const dataObjeto = new Date(data);
			const dia = String(dataObjeto.getDate()).padStart(2, '0');
			const mes = String(dataObjeto.getMonth() + 1).padStart(2, '0'); // Adicionar +1 pois o mês começa em zero (janeiro é 0)
			const ano = String(dataObjeto.getFullYear());
			return `${dia}/${mes}/${ano}`;
		} else {
			return null;
		}
	}

	useEffect(() => {
		const timer = setInterval(() => {
			setCurrentDate(new Date());
		}, 1000);

		return () => {
			clearInterval(timer);
		};
	}, []);

	useEffect(() => {
		const dateDifference = mockTestDate - currentDate; // Define a diferença entre a data de liberação e a data atual
		const data_resultado = new Date(mockInfo?.data_hora_resultados);
		const data_encerramento_simulado = new Date(mockInfo?.data_hora_encerramento);
		const data_liberacao = new Date(mockInfo?.data_hora_liberacao);
		const hoursDifference = dateDifference / (1000 * 60 * 60); // Transforma a diferença em horas

		if (currentDate >= data_resultado) {
			if (currentDate === data_resultado) {
				window.location.reload();
			}
			setIsPerformanceOpen(true);
			setMockTestState('Resultado Liberado');
		} else if (currentDate >= data_encerramento_simulado) {
			// Verifica se já passou da data de encerramento
			setMockTestState('Simulado Finalizado');
		} else if (hoursDifference > 24) {
			// Verifica se a diferença é maior que 24 horas
			setMockTestState('Mais de 24 horas');
		} else if (currentDate >= data_liberacao) {
			if (currentDate === data_liberacao) {
				window.location.reload();
			}
			// Verifica se atual é maior que a data de liberação do simulado
			setMockTestState('Simulado Liberado');
		} else if (currentDate < data_liberacao && mockTestState !== 'Simulado Liberado') {
			// Verifica se a data atual é menor que a data de liberação e o estado não é 'Simulado Liberado'
			setMockTestState('Menos de 24 horas');
		}
	}, [
		mockInfo?.data_hora_liberacao,
		mockInfo?.data_hora_encerramento,
		mockTestState,
		currentDate,
		mockInfo?.data_hora_resultados,
		mockTestDate,
	]);

	return (
		<Grid
			item
			container
			style={{
				borderRadius: 14,
				padding: '1.5rem',
				boxShadow: '0px 0px 8px 0px #ff005d',
				backgroundImage: `url(${require('../Assets/bg-mock-card.png')})`,
				backgroundSize: 'cover',
			}}
		>
			<Grid
				item
				container
				justifyContent={'space-between'}
				rowGap={2}
			>
				<Grid
					item
					xs={12}
					md={4}
					container
					rowGap={1}
				>
					<Grid
						item
						container
						wrap={'nowrap'}
						columnGap={1}
						style={{ borderBottom: '1px solid #FFFFFF', paddingBottom: '0.5rem' }}
					>
						<img
							src={require('../Assets/icon-file-signature-solid.svg').default}
							alt=""
						/>
						<Grid
							item
							container
							direction={'column'}
							className="residencia"
						>
							<Typography
								color="secondary"
								className={`${classes.title} poppins`}
							>
								{mockInfo?.nome}
							</Typography>
							<Typography
								color="secondary"
								className={`${classes.exclusiveText} poppins`}
							>
								{mockInfo?.exclusivo_sprint ? (
									<>
										Exclusivo para alunos{' '}
										<strong className="gradient-text">&nbsp;Sprint Final</strong>
									</>
								) : (
									'Aberto para público geral'
								)}
							</Typography>
						</Grid>
					</Grid>
					<Typography
						color="secondary"
						className="poppins"
						style={{ fontSize: matchesMobile ? '0.9rem' : null }}
					>
						{mockInfo?.texto}
					</Typography>
					<Grid
						item
						container
						columnGap={1}
						wrap={'nowrap'}
						style={{ marginTop: matchesMobile ? '0.5rem' : '1.5rem' }}
					>
						<Grid
							item
							container
							alignItems={'center'}
							className={classes.gridInformation}
							wrap={'nowrap'}
							columnGap={1}
						>
							<img
								src={require('../Assets/icon-black-square-question-solid.svg').default}
								alt=""
								className={classes.imgPdfCard}
							/>
							<Typography
								color="primary"
								className={`${classes.infoPdfCard} poppins`}
							>
								<p style={{ fontWeight: '600' }}>{mockInfo?.qtd_questoes}</p>&nbsp;Questões
							</Typography>
						</Grid>
						<Grid
							item
							container
							className={classes.gridInformation}
							alignItems={'center'}
							wrap={'nowrap'}
							columnGap={1}
						>
							<img
								src={require('../Assets/icon-black-clock-solid.svg').default}
								alt=""
								className={classes.imgPdfCard}
							/>
							<Typography
								color="primary"
								className={`${classes.infoPdfCard} poppins`}
							>
								<p style={{ fontWeight: '600' }}>
									{Number.isInteger(mockInfo?.minutos / 60)
										? (mockInfo?.minutos / 60).toFixed(0)
										: (mockInfo?.minutos / 60).toFixed(1)}
									h
								</p>
								&nbsp;Tempo limite
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
					container
					justifyContent={isPerformanceOpen ? 'space-between' : 'flex-end'}
					rowGap={1.5}

                    flexWrap={false}
                    style={{ flexWrap: 'nowrap' }}
                    columnGap={1}
				>

                    {/* BOTÃO FORÇADO */}
                    <Grid
						item
						xs={12}
						sm={5.75}
						md={5.5}
						container
						justifyContent={'center'}
						rowGap={matchesMobile ? 1.5 : 1}
						style={{ borderRadius: 14, backgroundColor: '#0d0d0de6', padding: '1rem' }}
					>
						<Grid
							item
							container
							direction={'column'}
							alignContent={'center'}
							rowGap={1}
							style={{ alignSelf: 'flex-end' }}
						>
							<img
								src={require('../Assets/icon-white-redirect.svg').default}
								alt=""
							/>
							{mockTestState !== 'Simulado Liberado' && (
								<Typography
									color="secondary"
									align="center"
									className={`${classes.mockTestStatus} poppins`}
								>
									{'\n'}
								</Typography>
							)}
						</Grid>
						{mockTestState === 'Menos de 24 horas' || mockTestState === 'Simulado Finalizado' ? (
							<CountDownTimer
								targetDate={
									mockTestState === 'Simulado Finalizado'
										? new Date(mockInfo?.data_hora_resultados)
										: new Date(mockInfo?.data_hora_liberacao)
								}
								textColor="secondary"
							/>
						) : (
							<DefaultButton
								className={`${classes.btn} poppins`}
								style={{
									backgroundColor: '#FFFFFF',
									color: '#000000',
									border: null,
								}}
								href={mockInfo?.url_execucao}
							>
								{mockTestState === 'Mais de 24 horas'
									? dateFormat(mockTestDate)
									: mockTestState === 'Resultado Liberado'
									? 'Ver detalhes'
									: 'Acessar'}
							</DefaultButton>
						)}
					</Grid>
                    
					{isPerformanceOpen && (
						<Grid
							item
							xs={12}
							sm={5.75}
							md={5.5}
							container
							justifyContent={'center'}
							rowGap={matchesMobile ? 1.5 : 1}
							style={{ borderRadius: 14, backgroundColor: '#0d0d0de6', padding: '1rem' }}
						>
							<Grid
								item
								container
								direction={'column'}
								alignContent={'center'}
								rowGap={1}
								style={{ alignSelf: 'flex-end' }}
							>
								<img
									src={require('../Assets/icon-white-award-solid.svg').default}
									alt=""
								/>
								<Typography
									color="secondary"
									className={`${classes.mockTestStatus} poppins`}
								>
									Performance
								</Typography>
							</Grid>
							<DefaultButton
								className={`${classes.btn} poppins`}
								style={{
									backgroundColor: mockTestState === 'Mais de 24 horas' ? 'transparent' : '#FFFFFF',
									color: mockTestState === 'Mais de 24 horas' ? '#FFFFFF' : '#000000',
									border: mockTestState === 'Mais de 24 horas' ? '2px solid #FFFFFF' : null,
								}}
								disabled={mockTestState === 'Mais de 24 horas'}
								href={mockInfo?.url_resultado}
							>
								Ver detalhes
							</DefaultButton>
						</Grid>
					)}
					<Grid
						item
						xs={12}
						sm={5.75}
						md={5.5}
						container
						justifyContent={'center'}
						rowGap={matchesMobile ? 1.5 : 1}
						style={{ borderRadius: 14, backgroundColor: '#0d0d0de6', padding: '1rem' }}
					>
						<Grid
							item
							container
							direction={'column'}
							alignContent={'center'}
							rowGap={1}
							style={{ alignSelf: 'flex-end' }}
						>
							<img
								src={
									mockTestState === 'Mais de 24 horas'
										? require('../Assets/icon-outlined-calendar-white.svg').default
										: mockTestState === 'Menos de 24 horas'
										? require('../Assets/icon-watch-timer-sf.svg').default
										: mockTestState === 'Simulado Liberado'
										? require('../Assets/icon-white-redirect.svg').default
										: require('../Assets/icon-outlined-white-file-check-light.svg').default
								}
								alt=""
							/>
							{mockTestState !== 'Simulado Liberado' && (
								<Typography
									color="secondary"
									align="center"
									className={`${classes.mockTestStatus} poppins`}
								>
									{mockTestState === 'Mais de 24 horas' ? (
										'Disponível em'
									) : mockTestState === 'Menos de 24 horas' ? (
										'Em breve'
									) : mockTestState === 'Simulado Finalizado' ? (
										<>
											Simulado Finalizado
											<br />
											Veja o resultado em:
										</>
									) : (
										'Simulado Finalizado'
									)}
								</Typography>
							)}
						</Grid>
						{mockTestState === 'Menos de 24 horas' || mockTestState === 'Simulado Finalizado' ? (
							<CountDownTimer
								targetDate={
									mockTestState === 'Simulado Finalizado'
										? new Date(mockInfo?.data_hora_resultados)
										: new Date(mockInfo?.data_hora_liberacao)
								}
								textColor="secondary"
							/>
						) : (
							<DefaultButton
								className={`${classes.btn} poppins`}
								style={{
									backgroundColor: mockTestState === 'Mais de 24 horas' ? 'transparent' : '#FFFFFF',
									color: mockTestState === 'Mais de 24 horas' ? '#FFFFFF' : '#000000',
									border: mockTestState === 'Mais de 24 horas' ? '2px solid #FFFFFF' : null,
								}}
								disabled={mockTestState === 'Mais de 24 horas'}
								href={
									mockTestState === 'Resultado Liberado'
										? mockInfo?.url_gabarito
										: mockInfo?.url_execucao
								}
							>
								{mockTestState === 'Mais de 24 horas'
									? dateFormat(mockTestDate)
									: mockTestState === 'Resultado Liberado'
									? 'Ver detalhes'
									: 'Acessar'}
							</DefaultButton>
						)}
					</Grid>
                    
				</Grid>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	gridInformation: {
		backgroundColor: '#ffffffcc',
		width: 'fit-content !important',
		padding: '2.5px 1.5rem !important',
		borderRadius: 6,
		height: 'fit-content !important',
		[theme.breakpoints.down('lg')]: {
			padding: '0.25rem 0.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0.25rem 0.75rem !important',
		},
	},
	infoPdfCard: {
		fontSize: '0.85rem !important',
		display: 'inline-flex !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.7rem !important',
		},
	},
	exclusiveText: {
		fontSize: '1rem !important',
		fontWeight: '600 !important',
		display: 'inline-flex !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.85rem !important',
		},
		[theme.breakpoints.down(400)]: {
			fontSize: '0.75rem !important',
		},
		[theme.breakpoints.down(365)]: {
			fontSize: '0.7rem !important',
		},
		[theme.breakpoints.down(350)]: {
			fontSize: '0.65rem !important',
		},
	},
	title: {
		fontSize: '2rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
		},
	},
	btn: {
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		height: 'fit-content',
		alignSelf: 'flex-end',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.95rem !important',
			width: '80%',
		},
	},
	mockTestStatus: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.95rem !important',
		},
	},
}));
