import { Grid, Typography} from "@mui/material";



export const ListOfThemesContainer = ({ institutionName, temas, matchesTablet }) => {

	return (
		<Grid
			item
			xs={5.9}
			container
			rowGap={2}
			style={{ display: 'inline' }}
		>
			<Typography
				color="#FCFCFC"
				fontFamily="Manrope"
				style={{
					fontSize: matchesTablet ? '1.25rem' : '1.7rem',
					fontWeight: 700,
					lineHeight: 'unset',
					marginBottom: '1rem',
				}}
			>
				{institutionName}
			</Typography>
			<Grid
				item
				xs={12}
				container
				style={{
					overflowY: 'auto',
					height: matchesTablet ? 700 : 480,
					scrollbarWidth: 'thin',
					scrollbarColor: '#F1F1F1 #9E9E9E',
				}}
			>
				{temas.map((tema, i) => {
					return (
						<Grid
							key={i}
							item
							xs={12}
							container
							alignItems="center"
							style={{
								padding: '0.5rem 0.5rem',
								backgroundColor: i % 2 === 0 ? '#303030' : '#3F3F3F',
							}}
						>
							<Typography
								color="secondary"
								className="poppins"
								style={{
									fontSize: matchesTablet ? '0.85rem' : '1rem',
								}}
							>
								{tema}
							</Typography>
						</Grid>
					);
				})}
			</Grid>
		</Grid>
	);
};