import React from 'react';
import { makeStyles } from '@mui/styles';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Grid,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import data from '../../data.js';

export default function Faq() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const [expanded, setExpanded] = React.useState(false);

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	return (
		<div>
			{data.faq.map((item, index) => (
				<Accordion
					classes={{
						root: classes.accordionFaq,
						expanded: classes.accordionExpanded,
					}}
					key={index}
					expanded={expanded === index}
					onChange={handleChange(index)}
				>
					<AccordionSummary
						expandIcon={
							<ExpandMoreIcon className={expanded ? classes.expandMoreBlack : classes.expandMore} />
						}
						aria-controls="panel1a-content"
						id={index}
					>
						<Grid
							container
							wrap="nowrap"
						>
							<Grid
								item
								xs={2}
								md={1}
								style={{ width: '5%' }}
								container
								justifyContent="center"
								alignContent="center"
							>
								<Typography
									align="left"
									color="secondary"
									className={classes.textNumber}
								>
									{item.id < 10 ? '0' + item.id + '.' : item.id + '.'}
								</Typography>
							</Grid>
							<Grid
								item
								xs={12}
								md={9}
								container
								alignContent="center"
								style={matchesTablet ? { paddingLeft: '1em' } : null}
							>
								<Typography
									align="left"
									color="secondary"
									className={`${classes.text} poppins`}
								>
									{item.question}
								</Typography>
							</Grid>
						</Grid>
					</AccordionSummary>
					<AccordionDetails className={classes.accordionDetails}>
						<Typography
							align="left"
							variant="h4"
							color="secondary"
							className={`${classes.text} poppins`}
						>
							{item.answer}
						</Typography>
					</AccordionDetails>
				</Accordion>
			))}
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	accordionFaq: {
		backgroundColor: '#0D0D0D !important',
		borderRadius: '20px !important',
	},
	accordionExpanded: {
		margin: '0px !important',
		backgroundColor: '#707070 !important',
	},
	textNumber: {
		fontWeight: 'bold !important',
		fontSize: '2.5em !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
		},
	},
	text: {
		fontSize: '1.25rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	expandMore: {
		color: '#707070 !important',
		fontSize: '4.5rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.5rem !important',
		},
	},
	expandMoreBlack: {
		color: 'rgba(0, 0, 0, 0.54) !important',
		fontSize: '4.5rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.5rem !important',
		},
	},
	accordionDetails: {
		padding: '0px 7.5em 2em !important',
		[theme.breakpoints.down('xl')]: {
			padding: '0px 6.5em 2em !important',
		},
		[theme.breakpoints.down('lg')]: {
			padding: '0px 6em 2em !important',
		},
		[theme.breakpoints.down('md')]: {
			padding: '0px 2em 2em !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: 0,
		},
	},
}));
