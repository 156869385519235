import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

export default function KitHardwork({ img2, title, text }) {
	const theme = useTheme();

	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<div
			style={{
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				marginBottom: '2rem',
				backgroundColor: '#2B2B2B',
				borderRadius: '18px',
				padding: matchesMobile ? '1rem' : '2rem',
				marginTop: '2rem',
				flexDirection: matchesTablet && 'column',
			}}
		>
			<Grid
				item
				xs={12}
				md={4.5}
				container
				alignItems={'center'}
				justifyContent={'space-around'}
			>
				{/* <img
					src={require('../Assets/plus-white.png')}
					alt=""
					style={{
						width: 128,
						height: 136,
					}}
				/> */}
				<img
					src={img2}
					alt=""
					style={{ width: matchesMobile ? '75%' : null, marginBottom: matchesMobile ? '20px' : null }}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				md={7.5}
				container
				direction={'column'}
				rowGap={matchesMobile ? 2 : 4}
			>
				<Typography
					color={'#fff'}
					className="roboto-condensed"
					fontSize={matchesMobile ? '16px' : matchesTablet ? '25px' : '38px'}
					fontWeight={700}
					style={{ lineHeight: 'unset' }}
				>
					{title}
				</Typography>
				<Typography
					color={'#fff'}
					fontSize={24}
					className="poppins"
					style={{ fontSize: matchesMobile ? '13px' : matchesTablet ? '19px' : '24px' }}
				>
					{text}
				</Typography>
			</Grid>
		</div>
	);
}
