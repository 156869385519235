import React from 'react';
import { Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles  } from '@mui/styles';

export default function InformationalWhiteCard({icon, img, title, description, textDate }) {
	const classes = useStyles();
	const theme = useTheme();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
    
	return (
		<Paper
			className={`${classes.paper} rounded-card`}
			color="primary"
			elevation={4}
		>
			<Grid
				container
				alignContent="center"
				className={classes.gridTitle}
                wrap='nowrap'
				style={{
					backgroundImage: `url(${img})`,
				}}
			>
                <img src={icon} alt="" style={{width: matchesMobile ? '12%' : '8%', marginRight: '10px'}} />
				<Typography
					align="left"
					color="secondary"
					className={`${classes.titleInformationalCard} roboto-condensed`}
				>
					{title}
				</Typography>
			</Grid>
			<Grid
				container
				alignContent="center"
				className={classes.gridDescriptionCard}
			>
				<Typography
					align="left"
					color="primary"
					className={`${classes.paperDescription} poppins`}
				>
					{description}
				</Typography>
			</Grid>
			{textDate ? (
				<Grid
					item
					// xs={3}
					container
					justifyContent={'center'}
					className={`${classes.textDateStyle} section-hwm-background`}
				>
					<Typography
						align="left"
						color="secondary"
						className={`${classes.ctaTextCard} poppins`}
					>
						{textDate}
					</Typography>
				</Grid>
			) : null}
		</Paper>
	);
}

const useStyles = makeStyles((theme) => ({
	paper: {
		width: 777,
		marginBottom: 50,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		[theme.breakpoints.down('xl')]: {
			width: 585,
		},
		[theme.breakpoints.down('lg')]: {
			height: 'unset',
			width: 435,
			marginBottom: 30,
		},
		[theme.breakpoints.down('md')]: {
			width: 585,
		},
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
			width: 'unset',
		},
	},
	gridTitle: {
		backgroundColor: '#000000',
		padding: '20px 25px',
		borderTopRightRadius: 20,
		borderTopLeftRadius: 20,
		border: '1px solid #09D785',
		[theme.breakpoints.down('sm')]: {
			padding: '0px 15px',
		},
	},
	titleInformationalCard: {
		fontSize: '3.2rem !important',
		fontWeight: 'bold !important',
		color: '#09D785 !important',
		textShadow: '0px 0px 5px #09D785',
		margin: '8px 0 !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '16px 0 !important',
			lineHeight: 'unset !important',
		},
	},
	gridDescriptionCard: {
		backgroundColor: '#FFFFFF !important',
		padding: '1em 2em',
		borderRadius: 16,
		[theme.breakpoints.down('sm')]: {
			padding: '1em',
		},
	},
	paperDescription: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	textDateStyle: {
		borderTopLeftRadius: 20,
		borderBottomRightRadius: 20,
		width: '240px !important',
		[theme.breakpoints.down('sm')]: {
			width: '200px !important',
		},
	},
	ctaTextCard: {
		fontSize: '1.3rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
			fontWeight: '500 !important',
			margin: '5px 0 !important',
		},
	},
}));
