import React from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../Components/Footer';
import { Header } from '../../Components/Header';
import Form from '../../Components/Utils/Forms/PreRegistration';

export default function EsquentaLdl() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					justifyContent={'space-between'}
					alignContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
				>
					<Grid
						item
						xs={12}
						md={6}
						container
						justifyContent={'flex-start'}
						className={`${classes.gridTextContainer} revalida`}
						gap={3}
						style={{ height: 'fit-content' }}
					>
						<img
							src={require('../../Assets/LDL/text-logo-esquenta-ldl-usp.svg').default}
							alt=""
						/>

						<Typography
							className={`${classes.subtitle} poppins`}
							color="secondary"
						>
							Quatro MiniLives de temas quentíssimos para a prova da USP-SP.
						</Typography>
						<Typography
							className={`${classes.text} poppins`}
							color="secondary"
						>
							As MiniLives terão transmissão ao vivo e gratuita. Os vídeos ficarão disponíveis até a data
							da prova APENAS para inscritos na Live das Lives USP.
						</Typography>

						<img
							src={require('../../Assets/LDL/img-cronograma-mini-live-usp-qui.png')}
							alt=""
							style={{ marginTop: '1rem', marginBottom: matchesMobile ? '1.5rem' : null }}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={5}
						container
						justifyContent="center"
						gap={matchesTablet ? 4 : 0}
					>
						<Grid
							item
							xs={12}
							className={classes.gridForm}
							gap={1}
						>
							<Form
								group="ESQUENTA LDL USP"
								title={[
									'Inscreva-se gratuitamente',
									<br />,
									'para participar do Esquenta da',
									<br />,
									'Live das Lives da USP-SP!',
								]}
								titleButton="Confirmar Inscrição"
								type="residencia"
								urlToRedirect="/esquenta-ldl-usp"
								titleConfirmationMessage={'Inscrição confirmada!'}
								confirmationMessage={'Tu serás avisado pelo email cadastrado momentos antes da live.'}
							/>
						</Grid>
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto 0',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.2rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
			marginBottom: '8px !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.18rem !important',
			marginBottom: '8px !important',
		},
	},
	subtitle: {
		fontSize: '1.25rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	text: {
		fontSize: '1rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	gridForm: {
		width: 567,
		flexDirection: 'column !important',
		alignItems: 'center !important',
		[theme.breakpoints.down(1300)]: {
			width: 467,
		},
		[theme.breakpoints.down('md')]: {
			margin: '2rem 0 !important',
		},
	},
	gridTextContainer: {
		[theme.breakpoints.down('lg')]: {
			paddingRight: '2em',
		},
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
	},
}));
