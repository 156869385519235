import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default function TestCardOpen({ imageUrl, selected, type }) {
	const classes = useStyles();

	return (
		<Grid
			container
			justifyContent={'flex-end'}
			className={`${classes.cardRoot} ${classes.testCardStyle} ${selected ? classes.testCardSelected : ''} ${
				type === 'cm' ? 'background-gradient-purple' : 'background-central-concursos-vertical'
			}`}
			style={!selected ? { backgroundColor: '#191818', opacity: 0.9, cursor: 'pointer' } : null}
		>
			<Grid
				item
				container
				justifyContent="center"
				className={classes.gridImgTestCard}
				style={{ backgroundColor: selected ? '#333333' : '#191818' }}
			>
				<img
					src={imageUrl}
					alt=""
					className={classes.img}
					style={{ opacity: selected ? 1 : 0.5 }}
				/>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	cardRoot: {
		width: '90% !important',
		height: 80,
		borderRadius: 15,
		[theme.breakpoints.down('xl')]: {
			height: 70,
		},
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
			borderRadius: 5,
		},
	},
	img: {
		width: '70%',
		right: '4%',
		[theme.breakpoints.down('sm')]: {
			width: '85%',
		},
	},
	testCardStyle: {
		transition: 'opacity 0.2s',
	},
	testCardSelected: {
		opacity: 1,
	},
	gridImgTestCard: {
		width: '90% !important',
		borderTopRightRadius: 15,
		borderBottomRightRadius: 15,
		[theme.breakpoints.down('sm')]: {
			padding: '0.75rem 0 !important',
			borderTopRightRadius: 5,
			borderBottomRightRadius: 5,
		},
	},
}));
