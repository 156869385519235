import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

const AnchorButton = ({ url, img }) => {
	return (
		<a
			href={url}
			target="_blank"
			rel="noopener noreferrer"
			// style={{ flex: 1 }}
		>
			<img
				src={img}
				alt=""
			/>
		</a>
	);
};

export default function BoxApp({ LogoLdLHQ, CellphoneImg, IconApple, IconGoogle, IconBrowser }) {
	const theme = useTheme();

	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const texts = [
		{ icon: require('../Assets/icon-question-hq.png'), text: 'Todas as questões da banca comentadas;' },
		{ icon: require('../Assets/icon-video-hq.png'), text: 'Vídeos de 25 temas importantes para a prova;' },
		{ icon: require('../Assets/icon-statistics.png'), text: 'Estatísticas da banca.' },
	];

	return !matchesMobile ? (
		<div
			className="section"
			style={{
				display: 'flex',
				width: '100%',
				flexDirection: matchesTablet && 'column',
			}}
		>
			<div style={{ marginRight: '2rem' }}>
				<img
					src={require('../Assets/plus-white.png')}
					alt=""
				/>
			</div>
			<Grid
				item
				xs={6.75}
				container
				rowGap={2}
			>
				<img
					src={LogoLdLHQ}
					alt=""
					width={matchesTablet ? '100%' : '75%'}
				/>
				<Typography
					color={'#DFDFDF'}
					fontSize={32}
					fontWeight={500}
					className="poppins"
				>
					Acesso gratuito e exclusivo
				</Typography>
				<Grid
					item
					container
					rowGap={1}
				>
					{texts?.map((e, index) => {
						return (
							<Grid
								key={index}
								container
								alignItems={'center'}
								columnGap={1.5}
								wrap="nowrap"
							>
								<img
									src={e?.icon}
									alt=""
									width={26}
									style={{ height: 26 }}
								/>
								<Typography
									color={'#DFDFDF'}
									fontSize={24}
									fontWeight={500}
									className="poppins"
								>
									{e?.text}
								</Typography>
							</Grid>
						);
					})}
				</Grid>
			</Grid>
			<Grid
				item
				xs={5.25}
				style={{
					width: matchesTablet ? '100%' : '50%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					gap: '1rem',
				}}
			>
				<img
					src={CellphoneImg}
					alt=""
					width={matchesTablet ? '100%' : '80%'}
				/>
				<div style={{ display: 'flex', justifyContent: 'space-around', columnGap: 8, width: '100%' }}>
					<AnchorButton
						url={'https://play.google.com/store/apps/details?id=br.com.hardworkmedicina.app.hardworq'}
						img={IconGoogle}
					/>
					<AnchorButton
						url={'https://apps.apple.com/br/app/hardworq/id6470359505'}
						img={IconApple}
					/>
					<AnchorButton
						url={'https://hardworq.com.br/signin'}
						img={IconBrowser}
					/>
				</div>
			</Grid>
		</div>
	) : (
		<div
			className="section"
			style={{
				display: 'flex',
				width: '100%',
				flexDirection: 'column',
			}}
		>
			<div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
				<div style={{ marginRight: '1rem' }}>
					<img
						src={require('../Assets/plus-white.png')}
						alt=""
						style={{ height: 90 }}
					/>
				</div>
				<Grid
					item
					xs={6.75}
					container
					rowGap={2}
				>
					<img
						src={LogoLdLHQ}
						alt=""
						//width={matchesTablet ? '100%' : '75%'}
						style={{
							maxWidth: 200,
						}}
					/>
					<Typography
						color={'#DFDFDF'}
						fontSize={12}
						fontWeight={500}
						className="poppins"
					>
						Acesso gratuito e exclusivo
					</Typography>
				</Grid>
			</div>
			<Grid
				item
				container
				rowGap={1}
			>
				{texts?.map((e, index) => {
					return (
						<Grid
							key={index}
							container
							alignItems={'center'}
							columnGap={1.5}
							wrap="nowrap"
						>
							<img
								src={e?.icon}
								alt=""
								width={22}
								style={{ height: 22 }}
							/>
							<Typography
								color={'#DFDFDF'}
								fontSize={11}
								fontWeight={500}
								className="poppins"
							>
								{e?.text}
							</Typography>
						</Grid>
					);
				})}
			</Grid>
			<Grid
				item
				xs={12}
				style={{
					width: matchesTablet ? '100%' : '50%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					gap: '1rem',
				}}
			>
				<img
					src={CellphoneImg}
					alt=""
					width={matchesTablet ? '100%' : '80%'}
					style={{ marginTop: 20, marginBottom: 20 }}
				/>
				<div style={{ display: 'flex', justifyContent: 'space-around', columnGap: 8, width: '100%' }}>
					<AnchorButton
						url={'https://play.google.com/store/apps/details?id=br.com.hardworkmedicina.app.hardworq'}
						img={IconGoogle}
					/>
					<AnchorButton
						url={'https://apps.apple.com/br/app/hardworq/id6470359505'}
						img={IconApple}
					/>
					<AnchorButton
						url={'https://hardworq.com.br/signin'}
						img={IconBrowser}
					/>
				</div>
			</Grid>
		</div>
	);
}
