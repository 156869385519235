import * as React from 'react';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';

//assets
import appleIcon from '../Assets/apple-icon.svg';
import googleIcon from '../Assets/google-icon.svg';
import Icon365 from '../Assets/365-icon.svg';
import outlookIcon from '../Assets/outlook-icon.svg';
import goldOutlookIcon from '../Assets/goldOutlook-icon.svg';
import yahooIcon from '../Assets/yahoo-icon.svg';

export default function CalendarButton({url}) {
  return (
    <Paper sx={{ width: 320, maxWidth: '100%' }}>
      <MenuList>
        <MenuItem>
            <img src={appleIcon} style={{paddingRight: '10px'}} alt="" />
          <ListItemText>
            <a href={`${url}apple`} style={{textDecoration: 'none', color: 'inherit'}} target="_blank" rel="noopener noreferrer">Apple</a> 
          </ListItemText>
        </MenuItem>

        <MenuItem>
            <img src={googleIcon} style={{paddingRight: '10px'}} alt="" />
          <ListItemText>
            <a href={`${url}google`} style={{textDecoration: 'none', color: 'inherit'}} target="_blank" rel="noopener noreferrer">Google</a> 
          </ListItemText>
        </MenuItem>

        <MenuItem>
            <img src={Icon365} style={{paddingRight: '10px'}} alt="" />
          <ListItemText>
            <a href={`${url}office365`} style={{textDecoration: 'none', color: 'inherit'}} target="_blank" rel="noopener noreferrer">Office 365</a> 
          </ListItemText>
        </MenuItem>

        <MenuItem>
            <img src={outlookIcon} style={{paddingRight: '10px'}} alt="" />
          <ListItemText>
            <a href={`${url}outlookcom`} style={{textDecoration: 'none', color: 'inherit'}} target="_blank" rel="noopener noreferrer">Outlook.com</a> 
          </ListItemText>
        </MenuItem>

        <MenuItem>
            <img src={goldOutlookIcon} style={{paddingRight: '10px'}} alt="" />
          <ListItemText>
            <a href={`${url}outlook`} style={{textDecoration: 'none', color: 'inherit'}} target="_blank" rel="noopener noreferrer">Outlook</a> 
          </ListItemText>
        </MenuItem>

        <MenuItem>
            <img src={yahooIcon} style={{paddingRight: '10px'}} alt="" />
          <ListItemText>
            <a href={`${url}yahoo`} style={{textDecoration: 'none', color: 'inherit'}} target="_blank" rel="noopener noreferrer">Yahoo</a> 
          </ListItemText>
        </MenuItem>
      </MenuList>
    </Paper>
  );
}