import React, { useRef } from 'react';
import { Grid, Typography, useTheme, useMediaQuery, Button, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

// Assets
// import iconArrowDownGradient from '../assets/icon-arrow-down-gradient.svg';

const StyledFirstSectionSprintR1 = styled(Grid)(({ theme }) => ({
	gap: theme.spacing(8),
	paddingTop: '5px',
	[theme.breakpoints.down('md')]: {
		paddingTop: '80px',
	},
}));

export const FirstSectionSaleR3 = ({ scrollToPayment, firstParagraph, secondParagraph, logoImg, imageApp }) => {
	const theme = useTheme();
	const classes = useStyles({});

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));
	const matchesXl = useMediaQuery(theme.breakpoints.down('xl'));

	return (
		<StyledFirstSectionSprintR1>
			<Grid
				container
				className="section"
			>
				<Grid
					item
					container
					alignItems={'center'}
					justifyContent={'space-around'}
					flexWrap={'nowrap'}
					flexDirection={matchesTablet ? 'column' : 'row'}
				>
					<Grid
						item
						xs={12}
						md={5}
						container
						rowGap={4}
					>
						<Grid
							item
							container
						>
							<Grid>
								<img
									src={logoImg}
									alt={''}
								/>
							</Grid>
							<Grid className={classes.containerSubtitle}>
								<Typography
									color={'primary'}
									fontFamily={'Manrope'}
									fontSize={matchesTablet ? '16px' : '19px'}
								>
									<span> {firstParagraph}</span>
									<br />
                                    <br/>
									{secondParagraph}
								</Typography>
							</Grid>
						</Grid>
						{!matchesTablet && (
							<Grid item>
								<Box
									className={classes.button}
									onClick={() => scrollToPayment()}
								>
									<Typography
										className={classes.buttonText}
										fontFamily={'Manrope'}
									>
										<span>Inscreva-se</span>
									</Typography>
								</Box>
							</Grid>
						)}
					</Grid>
					<Grid
						item
						xs={12}
						md={5}
					>
						<img
							src={imageApp}
							alt={''}
							className={classes.imgPhone}
						/>
					</Grid>
					{matchesTablet && (
						<Button
							variant="contained"
							className={classes.btnMobile}
							fontFamily={'Manrope'}
							onClick={() => scrollToPayment()}
						>
							Inscreva-se
						</Button>
					)}
				</Grid>
				{/* {!matchesTablet && (
          <Grid
            container
            alignItems={'center'}
            justifyContent={'center'}
          >
            <img
              src={iconArrowDownGradient}
              width={35}
              className={classes.arrowNextSection}
              alt=""
            />
          </Grid>
        )} */}
			</Grid>
		</StyledFirstSectionSprintR1>
	);
};

const useStyles = makeStyles((theme) => ({
	btnMobile: {
		border: '2px solid #36BADC !important',
		borderColor: '#36BADC',
		width: '100%',
		borderRadius: '40px !important',
		textTransform: 'none !important',
		fontSize: '20px !important',
		marginTop: '32px !important',
		marginBottom: '40px !important',
		transition: 'all 0.2s ease-in-out !important',
		background: 'linear-gradient(225deg, #36BADC 24.36%, #5B69E5 75.41%)',
		'-webkit-background-clip': 'text',
		'-webkit-text-fill-color': 'transparent',
		'&:hover': {
			transform: 'scale(1.10)',
		},
	},
	btnLargeScreens: {
		width: '40%',
		borderRadius: '40px !important',
		fontFamily: 'Poppins !important',
		textTransform: 'none !important',
		fontSize: '18px !important',
		marginTop: '64px !important',
		transition: 'all 0.2s ease-in-out !important',
		'&:hover': {
			transform: 'scale(1.10)',
		},
	},
	containerSubtitle: {
		width: '100%',
		marginTop: '30px',
		[theme.breakpoints.down('xl')]: {
			marginTop: '40px !important',
		},
	},
	imgPhone: {
		maxWidth: '700px',
		[theme.breakpoints.down('lg')]: {
			maxWidth: '100%',
		},
	},
	arrowNextSection: {
		transition: 'all 0.2s ease-in-out',
		marginTop: '20px',
		paddingBottom: '30px',
		cursor: 'pointer',
		'&:hover': {
			transform: 'scale(1.10)',
		},
	},
	boldText: {
		fontFamily: 'Manrope',
		fontWeight: 600,
	},
	button: {
		width: '200px',
		height: '50px',
		borderRadius: '25px',
		border: '2px solid #36BADC',
		position: 'relative',
		overflow: 'hidden',

		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
		cursor: 'pointer',
		transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
		'&::before': {
			content: '""',
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			background: 'linear-gradient(225deg, #36BADC 24.36%, #5B69E5 75.41%)',
			zIndex: -1,
		},
		'&:hover': {
			transform: 'scale(1.05)',
			boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.3)',
			color: '#fff !important',
		},
	},
	buttonText: {
		padding: '42px',
		textTransform: 'none !important',
		fontSize: '1.25rem !important',
		fontWeight: '500 !important',
		color: '#36BADC',
		'&:hover': {
			color: '#fff !important',
		},
	},
}));
