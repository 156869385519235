import React, { useRef } from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Header } from '../../../../Components/Header';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import FirstSection from '../../../../Components/Sections/FirstSection';
import ArenaHwmCard from '../../../../Components/Cards/ArenaHwmCard';
import StudentsFeedbackSection from '../../../../Components/Sections/StudentsFeedback';
// import ScriptCardArena from '../../../../Components/Cards/ScriptCard-Arena';

// const presencialTraining = [
// 	{
// 		img: require('../../../../Assets/Arena/icon-presencial-training.svg').default,
// 		title: ['Treinamento presencial', <br />, 'em grupo'],
// 	},
// 	{
// 		img: require('../../../../Assets/Arena/icon-handshake.svg').default,
// 		title: ['+ de 20 estações para treinamento presencial ', <br />, '+ de 60 estações para treinamento online'],
// 	},
// 	{
// 		img: require('../../../../Assets/Arena/icon-training-skills.svg').default,
// 		title: ['Treinamento de habilidades ', <br />, 'com professores '],
// 	},
// 	{
// 		img: require('../../../../Assets/Arena/icon-discussion-checklist.svg').default,
// 		title: ['Discussões de checklist'],
// 	},
// 	{
// 		img: require('../../../../Assets/Arena/icon-personal-guidelines.svg').default,
// 		title: ['Orientações personalizadas'],
// 	},
// 	{
// 		img: require('../../../../Assets/Arena/icon-food.svg').default,
// 		title: ['Café da manhã, almoço ', <br />, 'e lanche'],
// 	},
// ];

// const onlineTraining = [
// 	{
// 		img: require('../../../../Assets/Arena/icon-learning-at-different-stations.svg').default,
// 		title: ['Aprenda em estações', <br />, 'interativas'],
// 	},
// 	{
// 		img: require('../../../../Assets/Arena/icon-lung.svg').default,
// 		title: ['+ de 150 casos para', <br />, 'aprendizado teórico'],
// 	},
// 	{
// 		img: require('../../../../Assets/Arena/icon-cyclic-revisions.svg').default,
// 		title: ['Revisões cíclicas', <br />, 'automáticas '],
// 	},
// 	{
// 		img: require('../../../../Assets/Arena/icon-60-stations.svg').default,
// 		title: ['+ de 60 estações', <br />, 'de aprendizagem'],
// 	},
// 	{
// 		img: require('../../../../Assets/Arena/icon-learn.svg').default,
// 		title: ['Aprenda avaliando', <br />, 'outros alunos'],
// 	},
// 	{
// 		img: require('../../../../Assets/Arena/icon-teacher-orientation.svg').default,
// 		title: ['Para cada caso, uma orientação ', <br />, 'em vídeo do professor'],
// 	},
// 	{
// 		img: require('../../../../Assets/Arena/icon-discussions-by-teachers.svg').default,
// 		title: ['Discussões de estações ', <br />, 'pelos professores'],
// 	},
// 	{
// 		img: require('../../../../Assets/Arena/icon-dm-teachers.svg').default,
// 		title: ['Contato direto', <br />, 'com o professor'],
// 	},
// 	{
// 		img: require('../../../../Assets/Arena/icon-backstage-info.svg').default,
// 		title: ['Informações de ', <br />, 'bastidores'],
// 	},
// 	{
// 		img: require('../../../../Assets/Arena/icon-mentor-discussion.svg').default,
// 		title: ['Discussões', <br />, 'de mentoria'],
// 	},
// 	{
// 		img: require('../../../../Assets/Arena/icon-exam-resources.svg').default,
// 		title: ['Orientações para', <br />, 'recursos de prova'],
// 	},
// ];

// const scriptMock = [
// 	{
// 		id: 1,
// 		img: require('../../../../Assets/Arena/bg-teoria-card.png'),
// 		title: 'Teoria',
// 		description: [
// 			'A base fundamental para a prova prática!',
// 			<br />,
// 			<br />,
// 			'Um dos maiores erros na preparação é se esquecer que os cenários são apenas uma encenação da prova teórica!',
// 			<br />,
// 			'Por isso, entender conceitos fundamentais de temas possíveis na prática é obrigatório!',
// 			<br />,
// 			<br />,
// 			'Por isso, parte do treinamento é focado no aprendizado teórico e revisões até a data da prova.',
// 			<br />,
// 			<br />,
// 			'E como tudo no Hardwork envolve métodos ativos, faremos isso mesclando desafios com pequenos casos clínicos e revisões periódicas no modelo do Sprint Final.',
// 		],
// 	},
// 	{
// 		id: 2,
// 		img: require('../../../../Assets/Arena/bg-pratica-card.png'),
// 		title: 'Prática',
// 		description: [
// 			'Não é possível dominar um jogo sem conhecer todas as suas regras!',
// 			<br />,
// 			<br />,
// 			'Entender a dinâmica da prova, objetos, movimentos, semblantes, ações; o que pode ser feito, o que é proibido…',
// 			<br />,
// 			<br />,
// 			'É preciso entrar pra valer no Universo da nova prova prática do Inep!',
// 		],
// 	},
// 	{
// 		id: 3,
// 		img: require('../../../../Assets/Arena/bg-treinamento-card.png'),
// 		title: 'Treinamento',
// 		description: [
// 			'Jogo se vence treinando! Vamos treinar muito!',
// 			<br />,
// 			'Entender a dinâmica dos checklists é obrigatório num treinamento prático.',
// 			<br />,
// 			<br />,
// 			'O que precisa ser feito?',
// 			<br />,
// 			'O que precisa ser perguntado? Afinal, o que querem saber?',
// 			<br />,
// 			<br />,
// 			'Bem, faremos isso com intensidade tanto na modalidade Presencial quanto na online!',
// 		],
// 	},
// ];

// const DiferencialBox = ({ image, title, color, classes, size }) => {
// 	return (
// 		<Grid
// 			item
// 			xs={4}
// 			md={4}
// 			lg={size}
// 			className={classes.gridDiferencialBox}
// 		>
// 			<Grid
// 				item
// 				xs={12}
// 				container
// 				justifyContent="center"
// 				alignContent="center"
// 			>
// 				<img
// 					src={image}
// 					alt=""
// 					className={classes.imageDiferencialBox}
// 				/>
// 			</Grid>
// 			<Grid
// 				item
// 				xs={12}
// 				container
// 				justifyContent="center"
// 				alignContent="center"
// 				style={{ marginTop: 24 }}
// 			>
// 				<Typography
// 					color={color}
// 					align="center"
// 					className={`${classes.titleDiferencialBox} poppins`}
// 				>
// 					{title}
// 				</Typography>
// 			</Grid>
// 		</Grid>
// 	);
// };

export default function Arena() {
	const classes = useStyles();
	const theme = useTheme();
	const arena_card = useRef(null);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const executeScroll = () => arena_card.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className="revalida"
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<FirstSection
					backgroundImageUrl={require('../../../../Assets/Arena/arenaBackgroundLp.png')}
					logoUrl={require('../../../../Assets/Arena/logo-arena-24.svg').default}
					type="green"
					buttonText="Realizar pré-inscrição"
					executeScroll={executeScroll}
					firstTextPaper={[
						<Typography
							variant="h1"
							className={`${classes.gradientText} revalida gradient-text roboto-condensed`}
						>
							Um treinamento focado na nova prova prática do Inep.
						</Typography>,
						<Typography
							align="left"
							color="secondary"
							className={`${classes.text} poppins`}
						>
							O Arena é a preparação Hardwork para a prova prática do Revalida Inep. O Arena DOMINA todas
							as estações da prova, com um treinamento intenso e personalizado.
							<br />
							<br />
						</Typography>,
						// <Typography
						// 	align="center"
						// 	color="secondary"
						// 	className={`${classes.text} poppins`}
						// 	style={matchesMobile ? { fontWeight: 'bold', width: '100%' } : { fontWeight: 'bold' }}
						// >
						// 	Inscrições abertas a partir de 12/04
						// </Typography>,
					]}
					buttonProps={{ bottom: '7.5em', left: '5em', type: 'white-btn' }}
					imageProps={{ width: '37%', left: '10em', top: '5.75em' }}
				/>
				{/* <Grid
					container
					justifyContent={'space-around'}
					className="section"
					style={{
						transition: 'height 0.5s ease-in-out',
					}}
				>
					{scriptMock.map((item, index) => {
						return (
							<Grid
								item
								xs={12}
								md={4}
								key={item.id}
								container
								justifyContent="center"
								className={classes.gridScriptCard}
								style={{ margin: `${matchesMobile ? 'unset' : '1em'} 0` }}
							>
								<ScriptCardArena
									img={item.img}
									title={item.title}
									description={item.description}
									key={item.id}
									classes={classes}
									mobile={matchesMobile}
									tablet={matchesTablet}
									size={3}
								/>
							</Grid>
						);
					})}
				</Grid> */}
			</Container>
			{/* <Grid
				container
				className="section-revalida-background"
			>
				<Grid className={classes.backgroundWaves}>
					<Container
						fixed
						maxWidth="xl"
						disableGutters={true}
					>
						<Grid
							container
							alignContent="center"
							justifyContent="center"
							className="section"
							style={{ margin: '20px auto' }}
						>
							<Grid
								item
								xs={12}
								container
								style={matchesMobile ? { marginBottom: 16 } : { marginTop: 20 }}
							>
								<Typography
									className={`${classes.subtitle1} roboto-condensed`}
									color="primary"
								>
									Receba no treinamento presencial
								</Typography>
							</Grid>
							<Grid
								item
								xs={12}
								container
								justifyContent={'space-around'}
							>
								{presencialTraining.map((item, index) => {
									return (
										<DiferencialBox
											key={index}
											image={item.img}
											title={item.title}
											color="primary"
											mobile={matchesMobile}
											size={4}
											classes={classes}
										/>
									);
								})}
							</Grid>
						</Grid>
					</Container>
				</Grid>
			</Grid> */}
			<Grid container>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					{/* <Grid
						container
						alignContent="center"
						justifyContent="center"
						className="section"
						style={{ margin: '20px auto' }}
					>
						<Grid
							item
							xs={12}
							container
							style={matchesMobile ? { marginBottom: 16 } : { marginTop: 20 }}
						>
							<Typography
								className={`${classes.subtitle1} roboto-condensed`}
								color="secondary"
							>
								Receba no treinamento online
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							container
							justifyContent={'center'}
						>
							{onlineTraining.map((item, index) => {
								return (
									<DiferencialBox
										key={index}
										image={item.img}
										title={item.title}
										color="secondary"
										size={3}
										classes={classes}
									/>
								);
							})}
						</Grid>
						<Grid
							item
							xs={12}
							container
							justifyContent="center"
						>
							<Grid
								item
								container
								justifyContent="space-around"
								style={{
									width: 386,
									backgroundColor: '#333333',
									borderRadius: 10,
									padding: 8,
									height: 148,
								}}
							>
								<img
									src={require('../../../../Assets/Extensivo/Revalida/multimidiaHwm.svg').default}
									alt="Multimídia Hardwork"
								/>
								<Typography
									color="secondary"
									className={`${classes.textMultimidiaCard} poppins`}
								>
									Ganhe acesso ao treinamento
									<br />
									multimídia 2022 do HWM
								</Typography>
							</Grid>
						</Grid>
					</Grid> */}
					<Grid
						className="section"
						container
						justifyContent={'center'}
						ref={arena_card}
					>
						<ArenaHwmCard
							group="INSCRICAO ARENA - REVALIDA 2024.1"
							title="Cadastre e receba as novidades em primeira mão "
							titleButton={'confirmar pré-inscrição'}
							urlToRedirect={'/pre-inscricao/arena-hardwork'}
						/>
					</Grid>
				</Container>
			</Grid>
			<StudentsFeedbackSection type="arena 2022" />
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Footer />
			</Container>
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	text: {
		fontSize: '1.75rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.35rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	subtitle1: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('md')]: {
			lineHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
			lineHeight: 'unset !important',
		},
	},
	backgroundWaves: {
		backgroundImage: `url(${process.env.PUBLIC_URL + '/Assets/Img/bgWave2.png)'}`,
		width: '100vw !important',
	},
	gradientText: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		marginBottom: '16px !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '2.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.8rem !important',
		},
	},
	textMultimidiaCard: {
		fontSize: '1rem !important',
		fontWeight: 'bold !important',
		alignSelf: 'center',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	imageDiferencialBox: {
		height: 120,
		[theme.breakpoints.down('lg')]: {
			height: 100,
		},
		[theme.breakpoints.down('sm')]: {
			height: 50,
		},
	},
	titleDiferencialBox: {
		fontSize: '1rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	gridDiferencialBox: {
		margin: '50px 0 !important',
		[theme.breakpoints.down('sm')]: {
			margin: '25px 0 !important',
		},
	},
}));
