import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import { makeStyles } from '@mui/styles';

export default function MultimidiaCard({ imgUrl, description }) {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Grid
			item
			xs={12}
			lg={6}
			container
			justifyContent={'space-around'}
			flexWrap={'nowrap'}
			gap={2}
			className={classes.gridMultimidia}
		>
			<Grid
				item
				xs={4}
				container
				justifyContent={'center'}
				alignContent={'center'}
				// style={{ flex: 1 }}
			>
				<img
					src={imgUrl}
					alt=""
				/>
			</Grid>
			<Grid
				item
				xs={8}
				container
				alignContent={'center'}
				// style={{ flex: 2 }}
			>
				<Typography
					color="primary"
					className={classes.textStyle}
				>
					{description}
				</Typography>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	textStyle: {
        fontWeight: 'normal !important', 
		fontSize: '1.2rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	gridMultimidia: {
		padding: '0.5rem 2rem',
		maxWidth: '450px !important',
		borderRadius: 15,
		backgroundColor: '#ffffff',
		height: 150,
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
		},
	},
}));
