import React from 'react';
import {
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
	// Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import { useState } from 'react';
import DefaultButton from '../../../../../Components/Buttons/DefaultButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import { Link } from 'react-router-dom';

// import { Container } from './styles';

export default function TableSpecialtyAndInstitution({ headerRows, contest, onShowPastContest }) {
	const classes = useStyles();
	const theme = useTheme();
	const [showInstituicao, setShowInstituicao] = useState(false);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));
	const matchesXl = useMediaQuery(theme.breakpoints.down('xl'));

	const [contestInfo, setContestInfo] = useState([]);
	const [expandedRows, setExpandedRows] = useState([]);
	const [uniqueInstitutions, setUniqueInstitutions] = useState([]);
	const [uniqueSpecialties, setUniqueSpecialties] = useState([]);

	useEffect(() => {
		setContestInfo(contest);
	}, [contest]);

	useEffect(() => {
		const uniqueInstituicoes = Array.from(new Set(contestInfo?.instituicoes?.map((item) => item.instituicao))).map(
			(instituicao) => contestInfo.instituicoes.find((item) => item.instituicao === instituicao)
		);
		setUniqueInstitutions(uniqueInstituicoes);
		// console.log(uniqueInstituicoes);

		const uniqueEspecialidades = Array.from(
			new Set(contestInfo?.especialidades?.map((item) => item.especialidade.trim()))
		).map((especialidade) => {
			return contestInfo.especialidades.find((item) => item.especialidade.trim() === especialidade);
		});

		setUniqueSpecialties(uniqueEspecialidades);
	}, [contestInfo]);

	const handleRowClick = (index) => {
		if (expandedRows.includes(index)) {
			setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index));
		} else {
			setExpandedRows([...expandedRows, index]);
		}
	};

	return (
		<Grid
			item
			xs={12}
			style={{ overflowX: matchesMobile ? (!contest?.arquivo_edital_1 ? 'clip' : 'auto') : null }}
		>
			<TableContainer
				component={Paper}
				style={!contest?.arquivo_edital_1 ? { overflowX: 'clip', borderRadius: 15 } : { borderRadius: 15 }}
			>
				<Table
					// sx={{ minWidth: 650 }}
					aria-label="simple table"
				>
					<TableHead
						className={classes.stickyTable}
						style={{
							backgroundColor: '#FFFFFF',
							borderTopRightRadius: 15,
							borderTopLeftRadius: 15,
							width: '100%',
						}}
					>
						<TableRow>
							{headerRows.map((item, index) => {
								const isFirstCell = index === 0;
								const isSecondCell = index === 2;

								const cellClassName = `${
									isFirstCell
										? classes.firstCellTableHeader
										: isSecondCell
										? classes.headerCell90
										: classes.headerCell
								} poppins`;

								const handleCellClick = isFirstCell ? () => setShowInstituicao(!showInstituicao) : null;

								return (
									<TableCell
										key={index}
										align="center"
										className={cellClassName}
										onClick={handleCellClick}
										style={
											index === headerRows.length - 1 && matchesMobile ? { minWidth: 167 } : null
										}
									>
										<Grid
											item
											container
											justifyContent={isFirstCell ? 'flex-start' : 'center'}
											style={{ alignItems: 'center' }}
										>
											{item}
											{isFirstCell && (
												<Tooltip
													title="Inverter visualização"
													placement="top"
												>
													<img
														src={require('../Assets/icon-toggle-btn.svg').default}
														alt=""
														className={classes.toggleInstitutionSpecialty}
													/>
												</Tooltip>
											)}
										</Grid>
									</TableCell>
								);
							})}
						</TableRow>
					</TableHead>

					{contestInfo?.instituicoes?.length > 0 && (contest?.arquivo_edital_1 || contest?.link_edital) ? (
						<TableBody>
							{showInstituicao
								? uniqueInstitutions?.map((item, index) => {
										const isExpanded = expandedRows.includes(index);
										const rowClass = index % 2 === 0 ? classes.tableRowEven : classes.tableRowOdd;

										const specialtyRows = item.especialidades.map((specialty, specialtyIndex) => {
											const especialidade = contestInfo?.especialidades_instituicoes.find(
												(especialidade) =>
													especialidade?.especialidade === specialty &&
													especialidade?.instituicao === item?.instituicao
											);

											if (!especialidade) {
												return null; // Ignora especialidades que não correspondem a specialty
											}

											const qtd_candidatos_vaga_string =
												especialidade?.qtd_candidatos_vaga_str.split(',');

											const resultado_qtd_candidatos_vaga_str =
												qtd_candidatos_vaga_string[1] === '00'
													? qtd_candidatos_vaga_string[0]
													: especialidade?.qtd_candidatos_vaga_str;

											return (
												<TableRow
													key={`${index}-${specialtyIndex}`}
													style={{ backgroundColor: '#808080' }}
												>
													<TableCell
														component="th"
														align="center"
														scope="row"
														className={`${classes.firstCellTableBodyWhite} poppins`}
													>
														{specialty}
													</TableCell>
													<TableCell
														align="center"
														className={`${classes.tableCellWhite} poppins`}
													>
														{especialidade?.qtd_vagas}
													</TableCell>
													<TableCell
														align="center"
														className={`${classes.tableCellWhite} poppins`}
													>
														{especialidade?.qtd_candidatos_vaga_dec
															? Math.round(
																	especialidade.qtd_candidatos_vaga_dec *
																		especialidade.qtd_vagas
															  ).toFixed(0)
															: '-'}
													</TableCell>
													<TableCell
														align="center"
														className={`${classes.tableCellWhite} poppins`}
													>
														{resultado_qtd_candidatos_vaga_str
															? resultado_qtd_candidatos_vaga_str
															: '-'}
													</TableCell>
													<TableCell
														align="center"
														className={`${classes.tableCellWhite} poppins`}
													>
														{especialidade?.nota_corte_fase_final
															? especialidade?.nota_corte_fase_final
															: '-'}
													</TableCell>
												</TableRow>
											);
										});

										// Filtra os objetos com a mesma instituição
										// const filteredObjects = contestInfo?.especialidades_instituicoes.filter(
										// 	(obj) => obj.instituicao === item.instituicao
										// );

										// Calcula a soma das vagas para a instituição atual
										// const totalQtdVagas = filteredObjects.reduce(
										// 	(sum, obj) => sum + obj.qtd_vagas,
										// 	0
										// );

										const allSpecialties = contestInfo?.especialidades_instituicoes;
										const currentInstitution = item.instituicao;

										const totalVacancies = allSpecialties.reduce((sum, institution) => {
											if (institution.instituicao === currentInstitution) {
												return sum + institution.qtd_vagas;
											} else {
												return sum;
											}
										}, 0);

										return (
											<React.Fragment key={index}>
												<TableRow className={`${rowClass}`}>
													<TableCell
														component="th"
														align="center"
														scope="row"
														className={`${classes.firstCellTableBody} poppins`}
														onClick={() => handleRowClick(index)}
													>
														<Grid
															container
															alignItems={'center'}
															wrap="nowrap"
														>
															<ArrowDropDownIcon className={classes.arrowDownIcon} />
															{item.instituicao}
														</Grid>
													</TableCell>
													<TableCell
														align="center"
														className={`${classes.tableCell} poppins`}
														style={{ width: matchesLg ? 72 : 90 }}
													>
														{totalVacancies}
													</TableCell>
													<TableCell
														align="center"
														className={`${classes.tableCell} poppins`}
														style={{
															width: matchesLg ? 95 : matchesXl ? 146 : 185,
															cursor: 'pointer',
														}}
														onClick={() => handleRowClick(index)}
													>
														<Grid
															container
															justifyContent={'center'}
															alignItems={'center'}
														>
															<ArrowDropDownIcon className={classes.arrowDownIcon} />
														</Grid>
														{/* {contestInfo?.especialidades_instituicoes[0]
															?.qtd_candidatos_vaga
															? contestInfo?.especialidades_instituicoes[0]
																	?.qtd_candidatos_vaga * totalQtdVagas
															: 'Aguarde o edital'} */}
													</TableCell>
													<TableCell
														align="center"
														className={`${classes.tableCell} poppins`}
														onClick={() => handleRowClick(index)}
														style={{
															width: matchesLg ? 142 : matchesXl ? 175 : 183,
															cursor: 'pointer',
														}}
													>
														<Grid
															container
															justifyContent={'center'}
															alignItems={'center'}
														>
															<ArrowDropDownIcon className={classes.arrowDownIcon} />
														</Grid>
													</TableCell>
													<TableCell
														align="center"
														className={`${classes.tableCell} poppins`}
														style={{ cursor: 'pointer' }}
														onClick={() => handleRowClick(index)}
													>
														<Grid
															container
															justifyContent={'center'}
															alignItems={'center'}
														>
															<ArrowDropDownIcon className={classes.arrowDownIcon} />
														</Grid>
													</TableCell>
												</TableRow>
												{isExpanded && specialtyRows}
											</React.Fragment>
										);
								  })
								: uniqueSpecialties?.map((item, index) => {
										const isExpanded = expandedRows.includes(index);
										const rowClass = index % 2 === 0 ? classes.tableRowEven : classes.tableRowOdd;

										const concursosMesmaEspecialidade =
											contestInfo?.especialidades_instituicoes?.filter(
												(instituicao) => instituicao.especialidade === item.especialidade
											);

										concursosMesmaEspecialidade.sort((a, b) => a.estado.localeCompare(b.estado));

										const institutionsRows = [];
										let currentState = null;
										let currentGroup = [];

										concursosMesmaEspecialidade.forEach(
											(concursoOrdenado, concursoOrdenadoIndex) => {
												const qtd_candidatos_vaga_string =
													concursoOrdenado.qtd_candidatos_vaga_str.split(',');
												const resultado_qtd_candidatos_vaga_str =
													qtd_candidatos_vaga_string[1] === '00'
														? qtd_candidatos_vaga_string[0]
														: concursoOrdenado.qtd_candidatos_vaga_str;

												if (concursoOrdenado.estado !== currentState) {
													if (currentGroup.length > 0) {
														institutionsRows.push(
															<TableRow>
																<TableCell
																	colSpan={5}
																	style={{ padding: '0px', borderWidth: '0px' }}
																>
																	<Grid
																		container
																		style={{
																			padding: '0.5rem 0px 0.5rem 1.5rem',
																			backgroundColor: '#b0b0b0',
																			minWidth: '100%',
																		}}
																	>
																		<Typography
																			align="center"
																			color="primary"
																			className="poppins"
																			style={{ fontWeight: '500' }}
																		>
																			• {currentState}
																		</Typography>
																	</Grid>
																</TableCell>
															</TableRow>,
															...currentGroup
														);
														currentGroup = [];
													}
													currentState = concursoOrdenado.estado;
												}

												currentGroup.push(
													<TableRow
														key={`${index}-${concursoOrdenadoIndex}`}
														style={{ backgroundColor: '#808080' }}
													>
														<TableCell
															component="th"
															align="center"
															scope="row"
															className={`${classes.firstCellTableBodyWhite} poppins`}
														>
															{concursoOrdenado.instituicao}
														</TableCell>
														<TableCell
															align="center"
															className={`${classes.tableCellWhite} poppins`}
														>
															{concursoOrdenado.qtd_vagas}
														</TableCell>
														<TableCell
															align="center"
															className={`${classes.tableCellWhite} poppins`}
														>
															{concursoOrdenado.qtd_candidatos_vaga_dec
																? Math.round(
																		concursoOrdenado.qtd_candidatos_vaga_dec *
																			concursoOrdenado.qtd_vagas
																  ).toFixed(0)
																: '-'}
														</TableCell>
														<TableCell
															align="center"
															className={`${classes.tableCellWhite} poppins`}
														>
															{resultado_qtd_candidatos_vaga_str
																? resultado_qtd_candidatos_vaga_str
																: '-'}
														</TableCell>
														<TableCell
															align="center"
															className={`${classes.tableCellWhite} poppins`}
														>
															{concursoOrdenado?.nota_corte_fase_final
																? concursoOrdenado?.nota_corte_fase_final
																: '-'}
														</TableCell>
													</TableRow>
												);
											}
										);

										if (currentGroup.length > 0) {
											institutionsRows.push(
												<TableRow>
													<TableCell
														colSpan={5}
														style={{ padding: '0px', borderWidth: '0px' }}
													>
														<Grid
															container
															style={{
																padding: '0.5rem 0px 0.5rem 1.5rem',
																backgroundColor: '#b0b0b0',
																minWidth: '100%',
															}}
														>
															<Typography
																align="center"
																color="primary"
																className="poppins"
																style={{ fontWeight: '500' }}
															>
																• {currentState}
															</Typography>
														</Grid>
													</TableCell>
												</TableRow>,
												...currentGroup
											);
										}

										const especialidade = contestInfo?.especialidades_instituicoes?.find(
											(especialidade) => especialidade.especialidade === item.especialidade
										);

										const allSpecialties = contestInfo?.especialidades_instituicoes;
										const currentSpecialty = item.especialidade; // Especialidade atual dentro do map

										const totalVacancies = allSpecialties.reduce((sum, institution) => {
											if (institution.especialidade === currentSpecialty) {
												return sum + institution.qtd_vagas;
											} else {
												return sum;
											}
										}, 0);

										return (
											<React.Fragment key={index}>
												<TableRow
													sx={{
														'&:last-child td, &:last-child th': {
															border: 0,
														},
													}}
													style={
														index === contestInfo.instituicoes.length - 1
															? { borderBottom: '1px solid black' }
															: null
													}
													className={rowClass}
												>
													<TableCell
														component="th"
														align="center"
														scope="row"
														className={`${classes.firstCellTableBody} poppins`}
														onClick={() => handleRowClick(index)}
													>
														<Grid
															container
															alignItems={'center'}
															wrap={'nowrap'}
														>
															<ArrowDropDownIcon className={classes.arrowDownIcon} />
															{especialidade?.especialidade}
														</Grid>
													</TableCell>
													<TableCell
														align="center"
														className={`${classes.tableCell} poppins`}
														style={{ width: matchesLg ? 72 : 90 }}
													>
														{totalVacancies}
													</TableCell>
													<TableCell
														align="center"
														className={`${classes.tableCell} poppins`}
														style={{
															width: matchesLg ? 95 : matchesXl ? 146 : 185,
															cursor: 'pointer',
														}}
														onClick={() => handleRowClick(index)}
													>
														<Grid
															container
															justifyContent={'center'}
															alignItems={'center'}
														>
															<ArrowDropDownIcon className={classes.arrowDownIcon} />
														</Grid>
														{/* {especialidade?.qtd_candidatos_vaga_dec
															? (
																	parseFloat(especialidade?.qtd_candidatos_vaga_dec) *
																	especialidade?.qtd_vagas
															  ).toLocaleString()
															: 'Aguarde o edital'} */}
													</TableCell>
													<TableCell
														align="center"
														className={`${classes.tableCell} poppins`}
														style={{
															width: matchesLg ? 142 : matchesXl ? 175 : 183,
															cursor: 'pointer',
														}}
														onClick={() => handleRowClick(index)}
													>
														{/* {totalCandidatesPerVacancies} */}
														<Grid
															container
															justifyContent={'center'}
															alignItems={'center'}
														>
															<ArrowDropDownIcon className={classes.arrowDownIcon} />
														</Grid>
													</TableCell>
													<TableCell
														align="center"
														className={`${classes.tableCell} poppins`}
														style={{ cursor: 'pointer' }}
														onClick={() => handleRowClick(index)}
													>
														{/* {especialidade.nota_corte_fase_final
															? especialidade.nota_corte_fase_final
															: 'Aguarde o edital'} */}
														<Grid
															container
															justifyContent={'center'}
															alignItems={'center'}
														>
															<ArrowDropDownIcon className={classes.arrowDownIcon} />
														</Grid>
													</TableCell>
												</TableRow>
												{isExpanded && institutionsRows}
												{/* {isExpanded && stateRows} */}
											</React.Fragment>
										);
								  })}
						</TableBody>
					) : (
						<>
							<TableBody>
								<Grid
									item
									container
									alignContent="center"
									justifyContent="center"
									className={classes.glassContainer}
									gap={1}
								>
									<Grid
										item
										container
										alignContent="center"
										justifyContent="center"
										style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', height: '100%' }}
									>
										<Grid
											item
											container
											alignContent="center"
											justifyContent={matchesMobile ? 'flex-start' : 'center'}
											className={classes.gridImg}
										>
											<img
												src={require('../Assets/img-hourglass.svg').default}
												alt=""
												style={{ width: matchesMobile ? 80 : null }}
											/>
										</Grid>
										<Grid
											item
											container
											alignContent="center"
											justifyContent={matchesMobile ? 'flex-start' : 'center'}
											className={classes.gridText}
										>
											<Typography
												align="center"
												color="secondary"
												className={`${classes.blurBoxTitle} roboto-condensed`}
											>
												Aguarda divulgação do edital
											</Typography>
										</Grid>
										<Grid
											item
											container
											alignContent="center"
											justifyContent="center"
											className={classes.gridBtn}
										>
											<DefaultButton
												variant="contained"
												color={'secondary'}
												className={`${classes.blurBtn} btn poppins`}
												onClick={() => onShowPastContest()}
											>
												Ver dados da edição anterior
											</DefaultButton>
										</Grid>
									</Grid>
								</Grid>
								{Array.from(Array(10)).map((_, index) => (
									<TableRow
										key={index}
										sx={{
											'&:last-child td, &:last-child th': {
												border: 0,
											},
										}}
										className={index % 2 === 0 ? classes.tableRowEven : classes.tableRowOdd}
									>
										<TableCell
											component="th"
											align="center"
											scope="row"
											className={`${classes.firstCellTableBody} poppins`}
										>
											<Grid
												container
												alignContent={'center'}
											>
												Aguarde o edital
											</Grid>
										</TableCell>
										<TableCell
											align="center"
											className={`${classes.tableCell} poppins`}
											style={{ width: matchesLg ? 95 : matchesXl ? 113 : 119 }}
										>
											Aguarde o edital
										</TableCell>
										<TableCell
											align="center"
											className={`${classes.tableCell} poppins`}
											style={{ width: matchesLg ? 72 : 90 }}
										>
											Aguarde o edital
										</TableCell>
										<TableCell
											align="center"
											className={`${classes.tableCell} poppins`}
											style={{ width: matchesLg ? 142 : matchesXl ? 175 : 183 }}
										>
											Aguarde o edital
										</TableCell>
										<TableCell
											align="center"
											className={`${classes.tableCell} poppins`}
											style={{ width: matchesLg ? 153 : matchesXl ? 188 : 199 }}
										>
											Aguarde o edital
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</>
					)}
				</Table>
			</TableContainer>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	tableHeader: {
		display: 'flex !important',
		// height: 62,
		// height: '10%',
		background: 'linear-gradient(90deg, rgba(255,72,0) 10%, rgba(255,0,66) 90%)',
		borderTopLeftRadius: 15,
		borderTopRightRadius: 15,
		padding: '1em',
	},
	filterText: {
		fontSize: '1.75rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.2rem !important',
		},
	},
	firstCellTableHeader: {
		borderRight: '1px solid black !important',
		padding: '1.25rem 14px !important',
		fontSize: '1rem !important',
		// width: '590px !important',
		paddingLeft: '1.5rem !important',
		fontWeight: 'bold !important',
		cursor: 'pointer',
		[theme.breakpoints.down('xl')]: {
			width: '420px !important',
			fontSize: '1.1rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			width: '265px !important',
			fontSize: '0.8rem !important',
			paddingLeft: '14px !important',
		},
		[theme.breakpoints.down('sm')]: {
			minWidth: '265px !important',
		},
	},
	firstCellTableBody: {
		borderRight: '1px solid black !important',
		padding: '0 14px !important',
		fontSize: '0.85rem !important',
		// width: '590px !important',
		width: '730px !important',
		paddingLeft: '0.5rem !important',
		fontWeight: '600 !important',
		marginRight: 0,
		cursor: 'pointer',
		textAlign: 'left !important',
		[theme.breakpoints.down('xl')]: {
			width: '540px !important',
			fontSize: '0.9rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			width: '264px !important',
			fontSize: '0.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			width: '475px !important',
			fontSize: '0.7rem !important',
			padding: '1rem 14px !important',
			textAlign: 'left !important',
		},
	},
	firstCellTableBodyWhite: {
		borderRight: '1px solid black !important',
		padding: '8px 14px !important',
		fontSize: '0.85rem !important',
		width: '590px !important',
		textAlign: 'left !important',
		paddingLeft: '2.5rem !important',
		color: '#ffffff !important',
		borderBottom: 'unset !important',
		verticalAlign: 'middle !important',
		[theme.breakpoints.down('xl')]: {
			width: '419px !important',
		},
		[theme.breakpoints.down('lg')]: {
			width: '264px !important',
			fontSize: '0.75rem !important',
			paddingLeft: '14px !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.65rem !important',
			paddingLeft: '21px !important',
		},
	},
	headerCell: {
		fontSize: '1rem !important',
		fontWeight: 'bold !important',
		borderRight: '1px solid black !important',
		// display: 'flex !important',
		// width: 'fit-content', // Defina o tamanho mínimo desejado para a célula do header
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.1rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.8rem !important',
		},
	},
	headerCell90: {
		fontSize: '1rem !important',
		fontWeight: 'bold !important',
		borderRight: '1px solid black !important',
		width: '90px', // Defina o tamanho mínimo desejado para a célula do header
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.1rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.8rem !important',
			width: 'fit-content',
		},
	},
	tableCell: {
		borderRight: '1px solid black !important',
		fontSize: '0.9rem !important',
		padding: '8px 14px !important',
		width: 'fit-content',
		fontWeight: '600 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.8rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.7rem !important',
		},
	},
	tableCellWhite: {
		borderRight: '1px solid black !important',
		fontSize: '0.85rem !important',
		padding: '8px 14px !important',
		width: 'fit-content',
		color: '#ffffff !important',
		borderBottom: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.8rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.65rem !important',
		},
	},
	tableRowEven: {
		backgroundColor: '#CCCCCC !important',
	},
	toggleInstitutionSpecialty: {
		height: '80%',
		marginLeft: '0.5rem',
		alignSelf: 'center',
		top: 0,
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
		},
	},
	glassContainer: {
		backdropFilter: 'blur(5px)',
		boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.37)',
		height: '100%',
		width: '100%',
		position: 'absolute',
		zIndex: 1,
		borderRadius: 15,
		// [theme.breakpoints.down('sm')]: {
		// 	padding: '0 1.5rem ',
		// },
	},
	blurBoxTitle: {
		fontSize: '2.5rem !important',
		textShadow: '0 0 0.2em #121213, 0 0 0.2em #0c0c0d',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
		},
	},
	blurBtn: {
		fontSize: '1rem !important',
		padding: '0.5rem 1.5rem !important',
		border: '2px solid #000000 !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	gridImg: {
		[theme.breakpoints.down('sm')]: {
			left: 125,
		},
	},
	gridText: {
		[theme.breakpoints.down('sm')]: {
			left: 46,
		},
	},
	gridBtn: {
		width: 'fit-content !important',
		[theme.breakpoints.down('sm')]: {
			left: -217,
		},
	},
	arrowDownIcon: {
		fontSize: 'xxx-large !important',
		color: '#726e6e',
		[theme.breakpoints.down('sm')]: {
			fontSize: 'xx-large !important',
		},
	},
	stickyTable: {
		position: 'sticky',
		top: 0,
		zIndex: 1,
	},
}));
