import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Grid, Paper, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

// COMPONENTS
import Footer from '../../../Components/Footer';
import { Header } from '../../../Components/Header';
import DefaultButton from '../../../Components/Buttons/DefaultButton';
import { Link } from 'react-router-dom';
import Api from '../../../Services/Api';

//Assets
import bgBlack from './Assets/bg-black.png';
import IntlTelInput from 'react-intl-tel-input';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';

const urls = [
	{ endpoint: 'conteudo/handsOn1', videoTitle: 'POCUS' },
	{ endpoint: 'conteudo/handsOn2', videoTitle: 'Suporte Ventilatório' },
];

urls.forEach(({ endpoint, videoTitle }) => {
	Api.get(endpoint)
		.then((response) => {
			const videoUrl = response.data;
			// Procura o vídeo no array de vídeos pelo título e atualiza a urlVideo
			const videoToUpdate = videos.find((video) => video.title === videoTitle);
			//console.log('Testar', response)
			if (videoToUpdate) {
				videoToUpdate.urlVideo = videoUrl;
			}
		})
		.catch((error) => {
			console.error(`Erro ao obter URL de vídeo para ${endpoint}: ${error}`);
		});
});

const videos = [
	{
		title: 'AO VIVO',
		urlVideo: 'https://www.youtube.com/embed/gmz5zsbRkww?si=pqy9WI3-yrY0JucF',
	},
	{
		title: 'POCUS',
		urlVideo: '',
	},
	{
		title: 'Suporte Ventilatório',
		urlVideo: '',
	},
];

const BlurContainer = ({ matchesMobile, handleSubscription, setUserEmail, setLiveIsOpen, matchesTablet }) => {
	const classes = useStyles();
	const [isOpenSignUpForm, setIsOpenSignUpForm] = useState(false);
	const [typeSubmit, setTypeSubmit] = useState(false);
	const [subscription, setSubscription] = useState({
		agrupamento: 'HANDS ON LIVE 2024',
		nome: '',
		email: '',
		telefone: '',
		pesquisa1: '',
		pesquisa2: '',
	});

	let sendPreInscricao = function (event) {
		event.preventDefault();
		const nomeCompleto = subscription.nome.trim();
		const nomeSobrenome = nomeCompleto.split(' ');

		if (!subscription.nome || nomeSobrenome.length < 2) {
			alert('Ops... Por favor informe seu nome e sobrenome');
			return;
		}
		if (!subscription.email || !subscription.email.includes('@') || !subscription.email.includes('.')) {
			alert('Ops... Por favor preencha seu email');
			return;
		}
		if (!subscription.telefone) {
			alert('Ops... Por favor preencha seu telefone com um número válido');
			return;
		}
		if (!subscription.pesquisa1) {
			alert('Ops... Por favor informe a sua faculdade');
			return;
		}
		if (!subscription.pesquisa2) {
			alert('Ops... Por favor informe o seu ano de formatura');
			return;
		}

		setTypeSubmit(true);

		trackPromise(
			Api.post('PreInscricao', subscription).then((res) => {
				if (res) {
					Swal.fire({
						title: `${'Massa!'} `,
						text: ``,
						icon: 'success',
						confirmButtonText: 'Fechar',
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then(() => {
						setLiveIsOpen(false);
					});
				}
			})
		);
	};

	const handlePhone = (valid, phoneNumber) => {
		if (valid) {
			setSubscription({
				...subscription,
				telefone: phoneNumber,
			});
		} else if (subscription.telefone.length) {
			setSubscription({
				...subscription,
				telefone: '',
			});
		}
	};

	return (
		<Grid
			item
			xs={12}
			container
			alignContent="center"
			justifyContent="center"
			className={classes.glassContainer}
		>
			<Paper
				className={`${classes.paper} rounded-card`}
				elevation={4}
				style={{
					rowGap: isOpenSignUpForm ? 16 : matchesTablet ? 24 : 48,
					padding: isOpenSignUpForm ? '2.5rem' : matchesTablet ? '1rem 0.75rem' : '3.75rem 2.5rem',
					margin: matchesTablet ? '0 1rem' : '',
				}}
			>
				{isOpenSignUpForm && (
					<DefaultButton
						variant="contained"
						color="primary"
						className={`poppins`}
						style={{
							width: 'fit-content',
							backgroundColor: '#000000',
							fontSize: '1rem',
							fontWeight: 400,
							padding: '0.25rem 1.75rem',
							lineHeight: 'unset',
						}}
						onClick={() => setIsOpenSignUpForm(false)}
					>
						<img
							src={require('../Assets/icon-go-back-white.svg').default}
							alt=""
							style={{ marginRight: '0.5rem' }}
						/>
						Voltar
					</DefaultButton>
				)}
				{isOpenSignUpForm ? (
					<form
						className="form-control"
						onSubmit={sendPreInscricao}
					>
						<Grid
							item
							container
							direction={'column'}
							justifyContent={'center'}
							alignItems={'center'}
							className="residencia"
							style={{
								backgroundColor: '#EBEBEB',
								padding: '2.5rem 2rem',
								rowGap: 32,
								borderRadius: 24,
							}}
						>
							<Grid
								item
								container
								direction={'column'}
								justifyContent={'center'}
								alignItems={'center'}
								rowGap={1}
							>
								<Typography
									color={'#1D1D1D'}
									fontSize={'2rem'}
									fontWeight={700}
									fontFamily={'Manrope'}
									lineHeight={'38.4px'}
								>
									Inscreva-se gratuitamente
								</Typography>
								<Typography
									color={'#303030'}
									fontSize={'1.5rem'}
									fontFamily={'Manrope'}
									lineHeight={'28.8px'}
								>
									Para acompanhar a transmissão ao vivo!
								</Typography>
							</Grid>
							<Grid
								item
								container
								justifyContent={'center'}
								alignItems={'center'}
								rowGap={2}
							>
								<Grid
									item
									xs={12}
									container
								>
									<TextField
										fullWidth
										type="string"
										name="nome"
										placeholder="Seu nome completo"
										required={true}
										InputProps={{
											className: `${classes.form2} ${classes.formHeight}`,
										}}
										InputLabelProps={{
											shrink: true,
										}}
										onChange={(e) =>
											setSubscription({
												...subscription,
												nome: e.target.value,
											})
										}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									container
								>
									<TextField
										fullWidth
										type="email"
										name="email"
										placeholder="E-mail utilizado na sua inscrição"
										required={true}
										InputProps={{
											className: `${classes.form2} ${classes.formHeight}`,
										}}
										InputLabelProps={{
											shrink: true,
										}}
										onChange={(e) =>
											setSubscription({
												...subscription,
												email: e.target.value,
											})
										}
									/>
								</Grid>
								<Grid
									item
									xs={12}
								>
									<IntlTelInput // Componente Input de telefones internacionais
										name="telefone"
										type="telefone"
										preferredCountries={['BR', 'PY', 'AR', 'CL', 'BO', 'UY', 'CU', 'PT']}
										style={{ width: '100%', border: 'unset' }}
										inputClassName={`${classes.phoneNumberForm} ${classes.formHeight}`}
										nationalMode={true}
										onPhoneNumberChange={(valid, value, selectedCountryData, fullNumber) =>
											handlePhone(valid, fullNumber)
										}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									container
								>
									<TextField
										fullWidth
										type="string"
										name="faculdade"
										placeholder="Sua faculdade"
										required={true}
										InputProps={{
											className: `${classes.form2} ${classes.formHeight}`,
										}}
										InputLabelProps={{
											shrink: true,
										}}
										onChange={(e) =>
											setSubscription({
												...subscription,
												pesquisa1: e.target.value,
											})
										}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									container
								>
									<TextField
										fullWidth
										type="string"
										name="formacao"
										placeholder="Ano de formação"
										required={true}
										InputProps={{
											className: `${classes.form2} ${classes.formHeight}`,
										}}
										InputLabelProps={{
											shrink: true,
										}}
										onChange={(e) =>
											setSubscription({
												...subscription,
												pesquisa2: e.target.value,
											})
										}
									/>
								</Grid>
							</Grid>
							<DefaultButton
								type={typeSubmit ? 'submit' : 'button'}
								variant="contained"
								color="secondary"
								className={`${classes.downloadBtn} btn-secondary-invert-gradient poppins`}
								onClick={(e) => sendPreInscricao(e)}
							>
								Inscrever-se
							</DefaultButton>
						</Grid>
					</form>
				) : (
					<>
						<Grid
							container
							rowGap={2}
							style={{
								backgroundColor: '#EBEBEB',
								borderRadius: 24,
								padding: matchesTablet ? '1.5rem 1rem' : '2.5rem 2rem',
							}}
						>
							<Grid
								item
								container
								justifyContent={'center'}
								xs={12}
								rowGap={matchesTablet && 1}
							>
								<Typography
									color="primary"
									align="center"
									fontFamily={'Manrope'}
									style={{
										fontWeight: matchesTablet ? 800 : '700',
										fontSize: matchesMobile ? '1.5rem' : '2rem',
										lineHeight: matchesMobile ? '24px' : '38.4px',
									}}
								>
									Identifique-se com o seu e-mail de inscrição
								</Typography>
								<Typography
									color="primary"
									align="center"
									fontFamily={'Manrope'}
									style={{
										fontSize: matchesMobile ? '1rem' : '1.5rem',
										lineHeight: matchesMobile ? '16px' : '28.8px',
									}}
								>
									{matchesTablet
										? 'e acompanhe a transmissão ao vivo!'
										: 'Utilize seu e-mail de inscrição para assistir a live e ter acesso aos materiais gratuitos!'}
								</Typography>
							</Grid>
							<Grid
								item
								xs={12}
							>
								<TextField
									fullWidth
									type="email"
									name="email"
									placeholder="E-mail utilizado na sua inscrição"
									required={true}
									InputProps={{
										className: `${classes.form}`,
										style: {
											// COMO ESTILIZAR TEXTFIELD
											color: '#959595',
											fontSize: '1.25rem',
										},
									}}
									InputLabelProps={{
										shrink: true,
									}}
									onChange={(e) => setUserEmail(e.target.value)}
								/>
							</Grid>
							<Grid
								container
								justifyContent={'center'}
								alignContent={'center'}
								direction={'column'}
								rowGap={1}
								className="residencia"
								style={{ marginTop: matchesMobile ? null : '1rem' }}
							>
								<DefaultButton
									variant="contained"
									color="secondary"
									className={`${classes.downloadBtn} btn-secondary-invert-gradient poppins`}
									style={{ width: matchesTablet ? '70% !important' : '45%' }}
									onClick={() => handleSubscription()}
								>
									Entrar
								</DefaultButton>
							</Grid>
						</Grid>
						<DefaultButton
							variant="contained"
							color="secondary"
							className={`${classes.signUpBtn} poppins`}
							style={{ width: matchesTablet ? '80%' : '100%' }}
							onClick={() => setIsOpenSignUpForm(true)}
						>
							Ainda não está inscrito?
						</DefaultButton>
					</>
				)}
			</Paper>
		</Grid>
	);
};

const DownloadMaterialCard = ({ link }) => {
	const classes = useStyles();
	const theme = useTheme();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Grid
			item
			xs={12}
			container
			justifyContent={'space-between'}
			rowGap={2}
		>
			<Grid
				item
				xs={12}
				md={5.9}
				container
				direction={'column'}
				rowGap={4}
				className="residencia"
				style={{
					padding: matchesMobile ? '1rem' : '3rem',
					paddingTop: matchesMobile ? '1.5rem' : '3rem',
					backgroundColor: '#1D1D1D',
					borderRadius: 24,
					marginTop: 10,
				}}
			>
				<Typography
					color="secondary"
					fontFamily={'Manrope'}
					style={{
						fontSize: matchesMobile ? '1rem' : '1.6rem',
						fontWeight: 'bold',
						lineHeight: 'unset',
					}}
				>
					{matchesMobile
						? ['Baixe aqui questões de', <br />, 'POCUS e Suporte Ventilatório']
						: ['Baixe aqui questões de POCUS', <br />, 'e Suporte Ventilatório']}
				</Typography>
				<DefaultButton
					variant="contained"
					color="secondary"
					className={`${classes.downloadBtn} btn-secondary-invert-gradient poppins`}
					href={
						'https://hardworkmedicina.com.br/app_uploads/public/docs/material-completo-pocus-ventilacao-28-09-2024.pdf'
					}
					target={'_blank'}
				>
					<img
						src={require('./Assets/icon-download-pocus.png')}
						alt=""
					/>
					Baixar material
				</DefaultButton>
				{matchesMobile ? (
					<img
						src={require('./Assets/img-download-pocus.png')}
						alt=""
						style={{ position: 'absolute', right: 2, bottom: 0, height: '80%' }}
					/>
				) : (
					<img
						src={require('./Assets/img-download-pocus.png')}
						alt=""
						style={{ position: 'absolute', right: 2, bottom: 0, height: '98%' }}
					/>
				)}
			</Grid>
			<Grid
				item
				xs={12}
				md={5.9}
				container
				direction={'column'}
				rowGap={5}
				className="residencia"
				style={{
					padding: matchesMobile ? '1rem' : '3rem',
					paddingTop: matchesMobile ? '1.5rem' : '3rem',
					backgroundColor: '#1D1D1D',
					borderRadius: 24,
				}}
			>
				<Typography
					color="secondary"
					fontFamily={'Manrope'}
					style={{
						fontSize: matchesMobile ? '1rem' : '1.6rem',
						fontWeight: 'bold',
						lineHeight: 'unset',
					}}
				>
					{matchesMobile
						? ['Baixe aqui seu certificado', <br />, 'de participação no', <br />, ' treinamento']
						: ['Baixe aqui seu certificado de', <br />, 'participação no treinamento']}
				</Typography>
				<DefaultButton
					variant="contained"
					color="secondary"
					className={`${classes.downloadBtn} btn-secondary-invert-gradient poppins`}
					onClick={link}
				>
					<img
						src={require('./Assets/icon-certificate.png')}
						alt=""
					/>
					Baixar certificado
				</DefaultButton>
				{matchesMobile ? (
					<img
						src={require('./Assets/img-certificado-mobile.png')}
						alt=""
						style={{ position: 'absolute', right: 2, bottom: 0, height: '88%' }}
					/>
				) : (
					<img
						src={require('./Assets/img-certificado.png')}
						alt=""
						style={{ position: 'absolute', right: 2, bottom: 0, height: '98%' }}
					/>
				)}
			</Grid>
		</Grid>
	);
};

const ProductsCard = ({ pathImg, url }) => {
	const classes = useStyles();

	return (
		<Grid
			container
			direction={'column'}
			justifyContent={'center'}
			alignItems={'center'}
			className={classes.gridProductsBtn}
			component={Link}
			to={url}
		>
			<img
				src={pathImg}
				alt=""
				width={'100%'}
			/>
			<DefaultButton
				variant={'outlined'}
				fontWeight={500}
				className={`${classes.productsBtn} btn poppins`}
				// onClick={() => (document.location.href = `${url}`)}
			>
				Saiba mais
			</DefaultButton>
		</Grid>
	);
};

export default function HandsOn2024() {
	const classes = useStyles();
	const theme = useTheme();
	// const embeddedContent = useRef(null);
	const [liveIsOpen, setLiveIsOpen] = useState(false);
	const [userEmail, setUserEmail] = useState('');
	// const [exam, setExam] = useState('');
	// const [id, setId] = useState(0);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const handleSubscription = () => {
		if (userEmail) {
			// Primeiro, verificamos em 'Hands On Live 2024'
			Api.get(`preInscricao/${encodeURIComponent('HANDS ON LIVE 2024')}/${encodeURIComponent(userEmail)}/`).then(
				(resLive) => {
					if (resLive > 0) {
						setLiveIsOpen(true);
					} else {
						// Se não achou em 'Hands On Live 2024', vamos verificar em 'Hands On'
						Api.get(
							`preInscricao/${encodeURIComponent('HANDS ON')}/${encodeURIComponent(userEmail)}/`
						).then((resHandsOn) => {
							if (resHandsOn > 0) {
								setLiveIsOpen(true);
							} else {
								alert('Insira o e-mail cadastrado para acessar a live.');
							}
						});
					}
				}
			);
		} else {
			alert('Insira o e-mail cadastrado para acessar a live.');
		}
	};

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
			style={{
				backgroundColor: '#000',
			}}
		>
			<Helmet>
				<title>Hands On</title>
				<meta
					name="description"
					content="Inspire-se. Hardwork-se. Medicine-se. Aprove-se"
				/>
			</Helmet>
			{matchesMobile ? null : matchesTablet ? <Header blackPattern /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header blackPattern />}
			</Container>
			<Grid
				container
				style={{
					backgroundImage: matchesTablet ? '' : `url(${bgBlack})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
				}}
			>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					{matchesTablet && (
						<Grid
							item
							xs={12}
							container
						>
							<Grid
								item
								xs={12}
								container
								justifyContent={'center'}
								alignItems={'center'}
								style={{
									backgroundImage: `url(${require('./Assets/bg-hands-on-pocus.png')})`,
									backgroundSize: 'cover',
									marginTop: 75,
									padding: '2rem 0 ',
								}}
							>
								<img
									src={require('./Assets/logo-hands-on.svg').default}
									alt=""
									width={266}
								/>
							</Grid>
						</Grid>
					)}
					<Grid
						item
						container
						alignItems={'center'}
						className="section"
						wrap={matchesTablet ? 'nowrap' : 'wrap'}
						direction={matchesTablet ? 'column-reverse' : 'row'}
						rowGap={matchesTablet ? 4 : 0}
						style={{
							margin: '0 auto',
							padding: matchesTablet ? '1.5rem 1rem 2.5rem' : '3rem 0',
						}}
					>
						<Grid
							item
							xs={12}
							md={6}
							container
						>
							<Grid
								item
								container
								direction={'column'}
								rowGap={matchesTablet ? 2 : 3.5}
								maxWidth={538}
							>
								<Typography
									fontFamily={'Manrope'}
									color={'#FCFCFC'}
									fontWeight={800}
									className={classes.firstTitle}
								>
									Hardwork Hands-on Online
									<br /> POCUS e Ventilação
								</Typography>
								<Typography
									fontFamily={'Manrope'}
									color={'#F1F1F1'}
									className={classes.text}
								>
									Treinamento intenso com foco em POCUS (ultrassonografia Point of Care) e suporte
									ventilatório, explorando todos os conceitos essenciais para a vida prática e para as
									provas.
								</Typography>
								<Grid
									item
									container
									alignItems={matchesTablet && 'center'}
									direction={'column'}
									backgroundColor={'#303030'}
									width={matchesTablet ? '100%' : 'fit-content'}
									style={{
										marginTop: matchesTablet && '1rem',
										borderRadius: matchesTablet ? '8px' : '16px',
										padding: '0.5rem 2rem',
									}}
								>
									<Typography
										fontFamily={'Manrope'}
										fontSize={matchesTablet ? '1.25rem' : '1.5rem'}
										fontWeight={800}
										color={'#FCFCFC'}
									>
										28 SET • A partir das 9H00
									</Typography>
									<Typography
										fontFamily={'Manrope'}
										fontSize={matchesTablet ? '1rem' : '1.25rem'}
										color={'#FCFCFC'}
									>
										Evento online e gratuito
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						{!matchesTablet && (
							<Grid
								item
								xs={12}
								md={6}
								container
								justifyContent={'center'}
								alignItems={'center'}
								style={{
									backgroundImage: `url(${require('./Assets/bg-hands-on-pocus.png')})`,
									backgroundSize: 'cover',
									minHeight: 480,
									borderRadius: 24,
									padding: '5rem 0',
								}}
							>
								<img
									src={require('./Assets/logo-hands-on.svg').default}
									alt=""
								/>
							</Grid>
						)}
					</Grid>
				</Container>
				<Grid
					item
					xs={12}
					container
				>
					<Container
						fixed
						maxWidth="xl"
						disableGutters={true}
					>
						<Grid
							item
							container
							className="section"
							style={
								matchesTablet
									? { padding: '1rem', backgroundColor: '#1D1D1D' }
									: { margin: '40px auto 0px' }
							}
						>
							<Grid
								item
								xs={12}
								container
								direction={'column'}
							>
								<Typography
									className={`${classes.subtitle1}`}
									fontFamily={'Manrope'}
									color={'#FCFCFC'}
								>
									Assista a transmissão ao vivo do Hardwork Hands-on
								</Typography>
								<Typography
									className={`${classes.subtitle2}`}
									fontFamily={'Manrope'}
									color={matchesTablet ? '#FCFCFC' : '#CCCCCC'}
								>
									Identifique-se abaixo e acompanhe o treinamento intenso de habilidades clínicas na
									prática!
								</Typography>
							</Grid>
						</Grid>
					</Container>
					<Grid
						item
						container
					>
						{!liveIsOpen && (
							<BlurContainer
								matchesMobile={matchesMobile}
								handleSubscription={handleSubscription}
								setUserEmail={setUserEmail}
								setLiveIsOpen={setLiveIsOpen}
								matchesTablet={matchesTablet}
							/>
						)}
						<Grid
							item
							container
							className="section"
							style={{ margin: '0 auto' }}
						>
							<Grid
								item
								xs={12}
								container
								justifyContent={'center'}
								rowGap={3}
								style={{
									backgroundColor: '#1d1d1',
									padding: '2rem 0',
									borderRadius: matchesTablet ? 0 : 32,
								}}
							>
								<iframe
									width="100%"
									height={matchesTablet ? '187px' : '632px'}
									loading="lazy"
									src="https://hardworkmedicina.com.br/player/vm/1013861897"
									title="YouTube video player"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									referrerpolicy="strict-origin-when-cross-origin"
									allowfullscreen
									style={{ borderRadius: '20px' }}
								></iframe>
								{/* {!matchesTablet && <DownloadMaterialCard />} */}
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							container
							justifyContent={'center'}
							rowGap={2}
							marginX={matchesMobile ? 2 : 3}
							marginTop={2}
						>
							<Grid
								item
								xs={12}
								xl={9.5}
								lg={12}
							>
								{userEmail ? (
									<DownloadMaterialCard
										link={() =>
											window.open(
												`https://hardworkmedicina.com.br/certificado/hands-on-2024?email=${encodeURIComponent(
													userEmail
												)}`,
												'_blank'
											)
										}
									/>
								) : null}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					<Grid
						container
						className="section"
						rowGap={4}
						style={{
							marginTop: matchesTablet && '0',
						}}
					>
						<Typography
							color={'secondary'}
							fontFamily={'Manrope'}
							style={{
								fontWeight: 'bold',
								fontSize: matchesTablet ? '1.5rem' : '2.75rem',
								lineHeight: matchesTablet && 1.25,
							}}
						>
							Conheça nossos cursos
						</Typography>
						<Grid
							item
							xs={12}
							container
							justifyContent={'space-between'}
							rowGap={2}
						>
							<Grid
								item
								xs={12}
								md={5.75}
							>
								<ProductsCard
									pathImg={require('./Assets/logo-sf-2024.svg').default}
									url={'/sprint-final'}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								md={5.75}
							>
								<ProductsCard
									pathImg={require('./Assets/logo-trilha-r1-24-25.png')}
									url={'/trilha-r1-2024-2025'}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</Grid>
			{matchesTablet ? null : <Footer noMarginBottom />}
			{matchesTablet ? <Footer noMarginBottom /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	firstTitle: {
		fontSize: '2.5rem !important',
		// lineHeight: '54.64px !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '1.75rem !important',
			lineHeight: 'unset !important',
		},
	},
	text: {
		fontSize: '1.5rem !important',
		lineHeight: '32.78px !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '1rem !important',
			lineHeight: '21.86px !important',
		},
	},
	paper: {
		borderRadius: '21px !important',
		width: '50%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			padding: '1rem 0.75rem',
			borderRadius: '1rem !important',
		},
	},
	glassContainer: {
		backdropFilter: 'blur(5px)',
		// boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.37)',
		height: '100%',
		width: '100%',
		position: 'absolute',
		zIndex: 1,
	},
	divider: {
		margin: '24px 0px !important',
		backgroundColor: 'grey',
		minHeight: 1,
	},
	subtitle1: {
		fontSize: '2.5rem !important',
		fontWeight: 'bold !important',
		lineHeight: '40px !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '1.25rem !important',
			lineHeight: 'unset !important',
			marginBottom: '8px !important',
		},
	},
	subtitle2: {
		fontSize: '1.5rem !important',
		lineHeight: '40px !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
			lineHeight: '16px !important',
		},
	},
	downloadBtn: {
		fontSize: '1.5rem !important',
		padding: '0.25rem 4rem !important',
		color: '#fff !important',
		fontWeight: '700 !important',
		width: 'fit-content !important',
		columnGap: 8,
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.75rem !important',
			minHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
			padding: '0.5rem 1.6rem !important',
			width: '100%',
		},
	},
	signUpBtn: {
		backgroundColor: '#101010 !important',
		fontSize: '1.25rem !important',
		padding: '0.5rem 6rem !important',
		color: '#fff !important',
		fontWeight: '400 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.75rem !important',
			minHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
			padding: '0.5rem 2rem !important',
			width: '100%',
		},
	},
	productsBtn: {
		padding: '0 3rem !important',
		border: '1.5px solid #fff !important',
		width: 'fit-content',
		fontSize: '1.125rem !important',
		fontWeight: '500 !important',
		color: '#ffffff !important',
		[theme.breakpoints.down('md')]: {
			color: '#040404 !important',
			fontSize: '1rem !important',
			backgroundColor: '#ffffff !important',
			padding: '0 2rem !important',
		},
	},
	gridProductsBtn: {
		backgroundColor: '#1d1d1d',
		padding: '1rem',
		borderRadius: 16,
		height: '100%',
		rowGap: '8px',
		[theme.breakpoints.down('md')]: {
			rowGap: '14.76px',
		},
	},
	form: {
		'& .MuiInputBase-root MuiOutlinedInput-root': {
			fontSize: '1.25rem !important',
		},
	},
	formHeight: {
		maxHeight: '48px !important',
		[theme.breakpoints.down('xl')]: {
			maxHeight: '40.8px !important',
		},
		[theme.breakpoints.down('sm')]: {
			maxHeight: '45px',
		},
	},
	form2: {
		borderRadius: '8px !important',
		backgroundColor: theme.palette.secondary.main,
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'transparent !important',
		},
		'& .MuiOutlinedInput-input': {
			height: '0.75em',
		},
	},
	phoneNumberForm: {
		height: '2.75rem',
		borderRadius: 8,
		width: '100%',
		border: 'unset',
	},
}));
