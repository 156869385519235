import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

// -- Assets
import iconSubscribe from '../../Assets/icon-subscribe.svg';
import Api from '../../../../Services/Api';

// -- Icons
import ABC from './assets/icon-abc.png';
import AMP from './assets/icon-amp.png';
import AMRIGS from './assets/icon-amrgs.png';
import EINSTEIN from './assets/icon-einstein.png';
import ENARE from './assets/icon-enare.png';
import PSUMG from './assets/icon-psumg.png';
import SANTACASASP from './assets/icon-santacasasp.png';
import SESDF from './assets/icon-sesdf.png';
import SESPE from './assets/icon-sespe.png';
import SIRIOLIBANES from './assets/icon-siriolibanes.png';
import SUSBA from './assets/icon-susba.png';
import SUSSP from './assets/icon-sussp.png';
import UERJ from './assets/icon-uerj.png';
import UFES from './assets/icon-ufes.png';
import UNESPBOTO from './assets/icon-unespbocutacu.png';
import UNICAMP from './assets/icon-unicamp.png';
import UNIFESP from './assets/icon-unifesp.png';
import URFJ from './assets/icon-urfj.png';
import USP from './assets/icon-usp.png';
import USPRIBEIRAO from './assets/icon-uspribeirao.png';
import FAMERP from './assets/icon-famerp.png';
import PSUGO from './assets/icon-psugo.png';

// -- Constants

import * as API from '../../../../Services/Api';

//alterar e colocar logos
const exams = [
	{ name: 'ABC', img: ABC, id: '1383', encerrado: false },
	{ name: 'AMP', img: AMP, id: '1384', encerrado: true },
	{ name: 'AMRIGS', img: AMRIGS, id: '1385', encerrado: false },
	{ name: 'EINSTEIN', img: AMRIGS, id: '1386', encerrado: true },
	{ name: 'ENARE', img: ENARE, id: '1387', encerrado: true },
	// { name: 'FAMEMA', id: '1388', encerrado: true },
	{ name: 'FAMERP', img: FAMERP, id: '1388', encerrado: false },
	// { name: 'F.MED. JUNDIAÍ', id: '1305', encerrado: true },
	{ name: 'SÍRIO LIBANES', img: SIRIOLIBANES, id: '1394' },
	// { name: 'IAMSPE', id: '1307', encerrado: true },
	{ name: 'PSU GO', img: PSUGO, id: '1389', encerrado: false },
	{ name: 'PSU MG', img: PSUMG, id: '1390', encerrado: false },
	{ name: 'SANTACASA-SP', img: SANTACASASP, id: '1391', encerrado: false },
	{ name: 'SES-DF', img: SESDF, id: '1392', encerrado: false },
	{ name: 'SES-PE', img: SESPE, id: '1393', encerrado: false },
	// { name: 'SURCE', id: '1314', encerrado: true },
	{ name: 'SUS BA', img: SUSBA, id: '1395', encerrado: false },
	{ name: 'SUS SP', img: SUSSP, id: '1396', encerrado: false },
	{ name: 'UERJ', img: UERJ, id: '1397', encerrado: false },
	{ name: 'UFES', img: UFES, id: '1398', encerrado: true },
	{ name: 'URFJ', img: URFJ, id: '1399', encerrado: false },
	{ name: 'UNESP BOTUCATU', img: UNESPBOTO, id: '1400', encerrado: false },
	{ name: 'UNICAMP', img: UNICAMP, id: '1401', encerrado: false },
	{ name: 'UNIFESP', img: UNIFESP, id: '1UNICAMP402', encerrado: false },
	{ name: 'USP', img: USP, id: '1403', encerrado: false },
	{ name: 'USP RIBEIRAO', img: USPRIBEIRAO, id: '1404', encerrado: false },
];

export function CardPaymentContainer({
	colorTheme, // -- Theme dark or light
	title, // -- Title of card in array, with two properties blackText and gradientText
	valorDe, // -- Receive a value of original value of the product
	valorPor, // -- Value with discount
	plusInfo, // -- More information appears on the pink card above the prices
	qParcelas, // -- Quantity of installments (Parcelas)
	vParcelas, // -- Price of each installments (Parcelas)
	vTotalParcelas, // -- Total price of all installments (Parcelas)
	bestChoice, // -- Props that indentifies card as a best choice, putting a pink border and a tag "Mais Escolhido"
	subtitleArr, // -- Informations that appears below title
	arrOfSprints, // -- Receives array with all products
	informationAboutSelection, // -- Receives information about sprint selection
	discountInformation, // -- Receives information about dicount
	handleSubscribeFn, // -- Receives a function that handles subscription
	handleSelectSprint, // -- Receives a function that handles sprints selection when exists, if not necessary pass a non-action function like () => {}
	arrOfSelectedSprints, // -- Receives a array that contains all products selected
	limitOfSelectedSprints, // -- If has a limit of selected products, passing as number
	type,
}) {
	const styles = useStyles();
	const theme = useTheme();

	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const [isHover, setIsHover] = useState(false);

	const [isVisibleSprints, setIsVisibleSprints] = useState(false);

	const [hoveredIndex, setHoveredIndex] = useState(null);

	const [trueExams, setTrueExams] = useState();

	//here chris
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await Api.get(`turmasabertas/${exams?.map((e) => e?.id).join(',')}`);

				const updatedExams = exams.map((exam) => {
					// Verifica se o id do exame da API corresponde ao id do exame local
					const matchingExam = response?.find((i) => i.id == exam.id);

					if (matchingExam) {
						// Se encontrar o exame correspondente, atualiza o encerrado
						return { ...exam, encerrado: matchingExam?.encerrada };
					}
					return exam; // Caso contrário, retorna o antigão
				});

				setTrueExams(updatedExams);
			} catch (error) {
				console.error('Erro ao buscar os exames: ', error);
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		if (plusInfo === 'Sprint unitário') {
			setIsVisibleSprints(true);
		}
	}, []);

	const handleRedirect = (e) => {
		handleSubscribeFn(e?.id);
	};

	return (
		<>
			<div
				className={styles.container}
				style={
					isVisibleSprints
						? {
								border: bestChoice ? '1px solid #FD2C78' : '1px solid #CCC',
								borderRadius: 16,
								flexDirection: 'column',
								boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
						  }
						: {
								border: bestChoice ? '1px solid #FD2C78' : '1px solid transparent',
								borderRadius: 16,
								flexDirection: 'column',
						  }
				}
			>
				<div
					className={styles.container}
					style={{ padding: '32px 32px 32px 32px', borderRadius: 16 }}
				>
					<div
						onClick={() => setIsVisibleSprints(!isVisibleSprints)}
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							gap: 15,
						}}
					>
						{bestChoice && (
							<div className={styles.bestChoice}>
								<h3
									className={styles.gradientText}
									style={{ fontSize: 14 }}
								>
									{bestChoice}
								</h3>
							</div>
						)}
						<div style={{ width: '100%' }}>
							<h2 style={{ fontSize: matchesTablet ? 24 : 32, whiteSpace: 'pre-wrap' }}>
								<span style={{ color: colorTheme === 'dark' ? '#FCFCFC' : '#303030', fontWeight: 600 }}>
									{title?.blackText || ''}
								</span>
								<span className={styles.gradientText}>{title?.gradientText || ''}</span>
							</h2>
						</div>
						<div style={{ width: '100%' }}>
							<h3
								className={styles.subInfoText}
								style={{
									marginBottom: 10,
									whiteSpace: 'pre-wrap',
									color: colorTheme === 'dark' ? '#FCFCFC' : '#303030',
								}}
							>
								{subtitleArr?.first || ''}{' '}
								<span style={{ fontWeight: 'bold' }}>{subtitleArr?.bold || ''}</span>{' '}
								{subtitleArr?.second || ''}
							</h3>
						</div>
					</div>
					<div
						onClick={() => setIsVisibleSprints(!isVisibleSprints)}
						style={{
							width: matchesTablet ? '100%' : 'fit-content',
							display: 'flex',
							flexDirection: matchesTablet ? 'column' : 'row',
							alignItems: 'center',
							justifyContent: 'flex-end',
						}}
					>
						<div
							style={{
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: matchesTablet ? 'start' : 'end',
								textAlign: matchesTablet ? 'start' : 'end',
							}}
						>
							{plusInfo && (
								<div className={styles.plusInfo}>
									<h3
										style={{
											fontSize: matchesTablet ? 12 : 14,
											fontWeight: 300,
											color: 'white',
											textWrap: 'nowrap',
										}}
									>
										{plusInfo}
									</h3>
								</div>
							)}
							<div style={{ marginBottom: 16 }}>
								<h4 className={styles.priceTextDe}>De: R$ {valorDe}</h4>
								<h3
									className={styles.priceTextPor}
									style={{
										marginTop: 5,
										fontSize: 20,
										textWrap: 'nowrap',
										fontFamily: 'Manrope',
										color: colorTheme === 'dark' ? '#FCFCFC' : '#303030',
									}}
								>
									Por:{' '}
									<span
										className={styles.gradientText}
										style={{ fontWeight: 800, fontSize: 28 }}
									>
										R$ {valorPor}
									</span>{' '}
									à vista
								</h3>
								<h2
									className={styles.priceTextPor}
									style={{
										fontSize: 16,
										marginTop: 5,
										fontFamily: 'Manrope',
										color: colorTheme === 'dark' ? '#FCFCFC' : '#303030',
									}}
								>
									(<strong>5%</strong> de desconto)
								</h2>
							</div>
							<h2
								className={styles.priceTextPor}
								style={{
									fontSize: 24,
									textWrap: 'nowrap',
									color: colorTheme === 'dark' ? '#FCFCFC' : '#303030',
								}}
							>
								ou <strong>{qParcelas}x</strong> de{' '}
								<span
									className={styles.gradientText}
									style={{ fontSize: matchesTablet ? 32 : 40, fontWeight: 800 }}
								>
									R$ {vParcelas}
								</span>
							</h2>
							<h3
								className={styles.priceTextPor}
								style={{
									color: colorTheme === 'dark' ? '#656565' : '#959595',
									fontSize: 14,
									marginTop: 5,
									fontFamily: 'Manrope',
								}}
							>
								{`Valor total: R$ ` + vTotalParcelas}
							</h3>
						</div>
						{isVisibleSprints && type === 2 && (
							<div
								onClick={
									!limitOfSelectedSprints
										? () => handleSubscribeFn()
										: arrOfSelectedSprints?.length === limitOfSelectedSprints
										? () => handleSubscribeFn()
										: () => {}
								}
								className={styles.btnSubscribe}
								style={{
									backgroundColor: !limitOfSelectedSprints
										? '#33C96F'
										: arrOfSelectedSprints?.length === limitOfSelectedSprints
										? '#33C96F'
										: colorTheme === 'dark'
										? '#656565'
										: '#9E9E9E',
								}}
							>
								<img
									src={iconSubscribe}
									alt=""
								/>
								<p style={{ color: 'white', marginTop: matchesTablet ? 0 : 10 }}>Inscreva-se</p>
							</div>
						)}
					</div>
				</div>
				{isVisibleSprints && (
					<div
						style={{
							width: '100%',
							height: matchesTablet ? 300 : 'auto',
							backgroundColor: colorTheme === 'dark' ? '#656565' : '#FCFCFC',
							borderBottomLeftRadius: 16,
							borderBottomRightRadius: 16,
							padding: matchesTablet ? 24 : 32,
							overflow: matchesTablet ? 'scroll' : 'hidden',
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: matchesTablet ? 'column' : 'row',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							{informationAboutSelection && (
								<p
									style={{
										color: colorTheme === 'dark' ? '#FCFCFC' : 'black',
										fontFamily: 'Manrope',
										fontWeight: 'bold',
										fontSize: matchesTablet ? 16 : 20,
									}}
								>
									{informationAboutSelection}
								</p>
							)}

							<div></div>
							{discountInformation && (
								<div className={styles.bestChoice}>
									<h3
										className={styles.whiteText}
										style={{ fontSize: 14 }}
									>
										{`Economia de R$${discountInformation}`}
									</h3>
								</div>
							)}
						</div>
						<div
							style={{
								display: 'grid',
								gap: 10,
								gridTemplateColumns: 'repeat(auto-fill, minmax(125px, 1fr))',
								marginTop: 15,
								transition: 'all 150ms ease-in-out',
							}}
						>
							{/* {
                                arrOfSprints?.map((e, index) => (
                                    <div onClick={() => handleSelectSprint(e?.id)} key={index}>
                                        <img src={e.name} alt="" />
                                    </div>
                                ))
                            } */}
							{trueExams?.map((elm, index) => {
								// console.log(elm); // Adiciona o console.log para verificar o conteúdo de elm
								return elm?.encerrado ? (
									<div
										key={index}
										onMouseEnter={() => setHoveredIndex(index)}
										onMouseLeave={() => setHoveredIndex(null)}
										style={{
											width: 115,
											minWidth: 115,
											height: 60,
											borderRadius: 8,
											backgroundColor: hoveredIndex === index ? '#959595' : '#30303080',
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											justifyContent: hoveredIndex === index ? 'center' : 'space-between',
											textAlign: 'center',
											color: '#B3B3B3',
											fontFamily: 'Manrope',
											fontSize: 16,
										}}
									>
										{hoveredIndex === index ? (
											<p style={{ color: '#EBEBEB' }}>Inscrições Encerradas</p>
										) : (
											<span style={{ textAlign: 'center', paddingTop: 15 }}>{elm?.name}</span>
										)}

										{hoveredIndex !== index && (
											<div
												style={{
													backgroundColor: '#C0323270',
													width: '100%',
													borderEndStartRadius: 8,
													borderEndEndRadius: 8,
												}}
											>
												<span style={{ color: '#EBEBEB', fontWeight: 700, fontSize: 14 }}>
													Encerrado
												</span>
											</div>
										)}
									</div>
								) : (
									<div
										key={index}
										onClick={() => {
											console.log(elm); // Verifique o conteúdo do objeto antes de redirecionar
											handleRedirect(elm); // Passa o próprio elm para a função
										}}
										className={styles.hoverCard}
										style={{
											width: 115,
											minWidth: 115,
											height: 60,
											borderRadius: 8,
											backgroundColor: arrOfSelectedSprints?.includes(elm?.id) && '#303030',
											border: arrOfSelectedSprints?.includes(elm?.id) && '1px solid red',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											textAlign: 'center',
											padding: 10,
											color: arrOfSelectedSprints?.includes(elm?.id)
												? colorTheme === 'dark'
													? 'white'
													: '#303030'
												: colorTheme === 'dark'
												? '#FCFCFC'
												: '#303030',
											fontFamily: 'Manrope',
											fontSize: 16,
										}}
									>
										{arrOfSelectedSprints?.includes(elm?.id) ? (
											colorTheme === 'dark' ? (
												<img
													src={elm.img}
													alt=""
													width={'100%'}
												/>
											) : (
												elm?.name
											)
										) : (
											<img
												src={elm.img}
												alt=""
											/>
										)}
									</div>
								);
							})}
						</div>
					</div>
				)}
			</div>
		</>
	);
}

export default function PaymentContainerFlex({ theme }) {
	const styles = useStyles();

	const [arrOneSprint, setArrOneSprint] = useState([]);
	const [arrtwoSprint, setArrTwoSprints] = useState([]);
	const [arrThreeSprint, setArrThreeSprints] = useState([]);

	const [produtos, setProdutos] = useState();

	const cursosFormatados = produtos?.map((curso) => ({
		name: curso.nome.split(' ').slice(2).join(' '),
		id: String(curso.id),
	}));

	const handleSelectThreeSprint = (sprintId) => {
		if (arrThreeSprint?.includes(sprintId)) {
			const newArr = arrThreeSprint?.filter((sprint) => sprint !== sprintId);
			setArrThreeSprints(newArr);
		} else {
			if (arrThreeSprint?.length === 3) return;
			setArrThreeSprints([...arrThreeSprint, sprintId]);
		}
	};

	const handleSelectTwoSprint = (sprintId) => {
		if (arrtwoSprint?.includes(sprintId)) {
			const newArr = arrtwoSprint?.filter((sprint) => sprint !== sprintId);
			setArrTwoSprints(newArr);
		} else {
			if (arrtwoSprint?.length === 2) return;
			setArrTwoSprints([...arrtwoSprint, sprintId]);
		}
	};

	const handleSelectOneSprint = (sprintId) => {
		setArrOneSprint([sprintId]);
	};

	const getData = async () => {
		const url =
			'turmasabertas/1383,1384,1385,1386,1387,1388,1389,1390,1391,1392,1393,1394,1395,1396,1397,1398,1399,1400,1401,1402,1403,1404';

		await Api.get(url).then((res) => {
			if (res) {
				setProdutos(res);
			}
		});
	};

	useEffect(() => {
		getData();
	}, []);

	const handleRoute = (id) => {
		switch (id) {
			case '1385':
				return '/sprint-final/AMRIGS';
			case '1383':
				return '/sprint-final/ABC';
			case '1388':
				return '/sprint-final/FAMERP';
			case '1394':
				return '/sprint-final/SIRIO-LIBANES';
			case '1395':
				return '/sprint-final/SUS-BA';
			case '1389':
				return '/sprint-final/PSU-GO';
			case '1390':
				return '/sprint-final/PSU-MG';
			case '1391':
				return '/sprint-final/SANTACASA-SP';
			case '1392':
				return '/sprint-final/SES-DF';
			case '1393':
				return '/sprint-final/SES-PE';
			case '1396':
				return '/sprint-final/SUS-SP';
			case '1397':
				return '/sprint-final/UERJ';
			case '1399':
				return '/sprint-final/URFJ';
			case '1400':
				return '/sprint-final/UNESP-BOTUCATU';
			case '1401':
				return '/sprint-final/UNICAMP';
			case '1UNICAMP402':
				return '/sprint-final/UNIFESP';
			case '1403':
				return '/sprint-final/USP';
			case '1404':
				return '/sprint-final/USP-RIBEIRAO';
			default:
				break;
		}
	};

	return (
		<section className={styles.sectionContainer}>
			<CardPaymentContainer
				type={1}
				colorTheme={theme}
				title={{ blackText: 'Quero fazer', gradientText: ' 01 Sprint' }}
				subtitleArr={{
					first: 'Vou focar toda energia em',
					bold: ' 01 prova.',
					second: '',
				}}
				valorDe={'1.990,00'}
				valorPor={'1.660,60'}
				qParcelas={'12'}
				vParcelas={'145,66'}
				vTotalParcelas={'1.748,00'}
				plusInfo={'Sprint unitário'}
				informationAboutSelection={'Selecione o Sprint que deseja'}
				arrOfSprints={exams}
				arrOfSelectedSprints={arrOneSprint}
				// handleSubscribeFn={(id) =>
				// 	(window.location.href = `https://home.hardworkmedicina.com.br${handleRoute(id)}`)
				// }
				handleSubscribeFn={(id) => {
					const route = handleRoute(id); // Obtém o caminho da rota
					// Redireciona apenas com o path, sem usar o domínio completo
					window.location.pathname = route;
				}}
				// handleSelectSprint={handleSelectOneSprint}
				limitOfSelectedSprints={1}
			/>
			{/* <CardPaymentContainer
				colorTheme={theme}
				title={{ blackText: 'Quero fazer', gradientText: ' 02 Sprints' }}
				subtitleArr={{
					first: 'Tenho',
					bold: ' 02 instituições',
					second: ' dos sonhos.',
				}}
				valorDe={'3.184,00'}
				valorPor={'2.610,95'}
				qParcelas={'12'}
				vParcelas={'233,20'}
				vTotalParcelas={'2.798,40'}
				plusInfo={'Pacote 02 sprints'}
				informationAboutSelection={'Selecione os 02 Sprints que deseja'}
				discountInformation={'1.181,60'}
				arrOfSprints={exams}
				arrOfSelectedSprints={arrtwoSprint}
				handleSelectSprint={handleSelectTwoSprint}
				handleSubscribeFn={() =>
					window.open(`https://hardworkmedicina.com.br/inscricao/${arrtwoSprint.join(',')}`)
				}
				limitOfSelectedSprints={2}
			/> */}
			{/* <CardPaymentContainer
				colorTheme={theme}
				bestChoice={'Opção mais escolhida'}
				title={{ blackText: 'Quero fazer', gradientText: ' 03 provas' }}
				subtitleArr={{
					first: 'Preciso de foco e intensidade em pelo menos',
					bold: ' 03 provas.',
					second: '',
				}}
				valorDe={'4.378,00'}
				valorPor={'2.990,79'}
				qParcelas={'12'}
				vParcelas={'262,35'}
				vTotalParcelas={'3.148,20'}
				plusInfo={'Pacote 03 sprints'}
				limitOfSelectedSprints={3}
				informationAboutSelection={'Selecione os 03 Sprints que deseja'}
				discountInformation={'1.398,80'}
				arrOfSprints={exams}
				arrOfSelectedSprints={arrThreeSprint}
				handleSelectSprint={handleSelectThreeSprint}
				handleSubscribeFn={() =>
					window.open(`https://hardworkmedicina.com.br/inscricao/${arrThreeSprint.join(',')}`)
				}
			/>
			<CardPaymentContainer
				colorTheme={theme}
				title={{ blackText: 'Quero fazer várias ', gradientText: 'provas de São Paulo' }}
				subtitleArr={{
					first: 'Preciso de direcionamento total para as',
					bold: ' bancas de São Paulo.',
					second: '',
				}}
				valorDe={'13.521,00'}
				valorPor={'3.790.50'}
				qParcelas={'12'}
				vParcelas={'332,50'}
				vTotalParcelas={'3.990,00'}
				plusInfo={'Pacote provas de São Paulo'}
				discountInformation={'1.398,80'}
				arrOfSprints={exams}
				arrOfSelectedSprints={saoPauloSprints}
				handleSelectSprint={() => {}}
				handleSubscribeFn={() => window.open(`https://hardworkmedicina.com.br/inscricao/sprintsSP`)}
			/>
			<CardPaymentContainer
				colorTheme={theme}
				title={{ blackText: 'Quero fazer', gradientText: ' ENARE e várias provas\n de São Paulo' }}
				subtitleArr={{
					first: 'Tenho foco no',
					bold: ' ENARE, ',
					second: 'mas também preciso de direcionamento para provas\n de São Paulo.',
				}}
				valorDe={'15.551,60'}
				valorPor={'3.980,50'}
				qParcelas={'12'}
				vParcelas={'349,16'}
				vTotalParcelas={'4.190,00'}
				plusInfo={'Pacote ENARE + provas de São Paulo'}
				discountInformation={'1.398,80'}
				arrOfSprints={exams}
				arrOfSelectedSprints={saoPauloSprintsEnare}
				handleSelectSprint={() => {}}
				handleSubscribeFn={() => window.open(`https://hardworkmedicina.com.br/inscricao/SPRINTR12024SP_ENARE`)}
			/> */}

			<CardPaymentContainer
				type={2}
				colorTheme={theme}
				title={{ gradientText: 'Quero acesso completo!' }}
				subtitleArr={{
					first: 'Tenho foco em',
					bold: ' várias provas ',
					second: 'e quero acesso integral a todas\n as ferramentas do Sprint.',
				}}
				valorDe={'19.577,60'}
				valorPor={'2.555,50'}
				qParcelas={'12'}
				vParcelas={'224,16'}
				vTotalParcelas={'2.690,00'}
				plusInfo={'Pacote todos os Sprints'}
				discountInformation={'17.022,10'}
				arrOfSprints={exams}
				arrOfSelectedSprints={exams?.map((e) => e?.id)}
				handleSelectSprint={() => {}}
				handleSubscribeFn={() => window.open(`https://hardworkmedicina.com.br/inscricao/todosSprints`)}
			/>

			<div className={styles.warningText}>
				*Os Sprints estão sujeitos a alteração ou incorporação em caso de mudança de banca.
			</div>
		</section>
	);
}

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		minHeight: 200,
		justifyContent: 'space-between',
		backgroundColor: '#1E1E1E',
		transition: 'all 150ms ease-in-out',
		cursor: 'pointer',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
		},
	},
	gradientText: {
		background: 'linear-gradient(304deg, #FF8251 20.08%, #FD2C78 79.92%)',
		WebkitBackgroundClip: 'text',
		WebkitTextFillColor: 'transparent',
		fontFamily: 'Manrope',
		textAlign: 'start',
	},
	whiteText: {
		background: 'white',
		WebkitBackgroundClip: 'text',
		WebkitTextFillColor: 'transparent',
		fontFamily: 'Manrope',
		textAlign: 'start',
	},
	hoverCard: {
		backgroundColor: '#303030',
		'&:hover': {
			backgroundColor: '#1E1E1E',
		},
	},
	subInfoText: {
		fontFamily: 'Manrope',
		fontSize: '16px',
		fontWeight: 'initial',
		[theme.breakpoints.down('md')]: {
			fontSize: '14px',
		},
	},
	bestChoice: {
		width: 'fit-content',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '10px',
		height: '27px',
		border: '1px solid #FD2C78',
		borderRadius: '4px',
		marginTop: 10,

		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	plusInfo: {
		background: 'linear-gradient(304deg, #FF8251 20.08%, #FD2C78 79.92%)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '10px',
		width: 'fit-content',
		height: '27px',
		borderRadius: '4px',
		marginBottom: 10,

		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	priceTextDe: {
		color: '#959595',
		fontSize: '16px',
		fontWeight: 'initial',
		textDecorationLine: 'line-through',
	},
	priceTextPor: {
		fontSize: '16px',
		fontWeight: 'initial',
	},
	btnSubscribe: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		width: 130,
		minWidth: 130,
		height: 130,
		borderRadius: 8,
		marginLeft: 40,
		transition: 'all 150ms ease-in-out',

		'&:hover': {
			transform: 'scale(1.015)',
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: 60,
			marginLeft: 0,
			marginTop: 10,
			flexDirection: 'row-reverse',
			gap: 20,
		},
	},
	warningText: {
		width: '100%',
		height: 34,
		borderRadius: 4,
		color: '#B3B3B3',
		fontSize: 16,
		fontFamily: 'Manrope',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		[theme.breakpoints.down('md')]: {
			fontSize: 12,
		},
	},
	sectionContainer: {
		display: 'flex !important',
		flexDirection: 'column !important',
		gap: 20,
		width: '100%',
		marginBottom: '4rem',
	},
}));
