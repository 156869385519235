/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import { Container, Divider, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../../Components/Footer';
import { Header } from '../../../../../Components/Header';
// import Form from '../../../../../Components/Utils/Forms/PreRegistration';
// import { useState } from 'react';
import CalendarIcon from '../Assets/calendar-vector.svg';
import PinIcon from '../Assets/pin-vector.svg';
import Form from '../../../../../Components/Utils/Forms/PreRegistration-Revaliday';

export default function Revaliday() {
    const classes = useStyles();
    const theme = useTheme();

    const matchesMobile = useMediaQuery(theme.breakpoints.down('sm')); //600 down
    const matchesTablet = useMediaQuery(theme.breakpoints.down('md')); //900 down
    const matchesLg = useMediaQuery(theme.breakpoints.down('lg')); //1200 down
    const matchesDownXl = useMediaQuery(theme.breakpoints.down('xl')); //1536 down

    // const [closeGroup, setCloseGroup] = useState(false);

    return (
        <Container
            maxWidth={false}
            disableGutters={true}
        >
            {matchesMobile ? null : matchesTablet ? <Header /> : null}

            <Container
                fixed
                maxWidth="xl"
                disableGutters={true}
                className={classes.background}
            >
                {matchesMobile || matchesTablet ? null : <Header />}
            </Container>
            <Grid
                style={{
                    backgroundImage: matchesMobile
                        ? `url(${require('../../../../LandingPages/FreeContent/HardworkEm/Assets/bg-pagina-mob.jpg')})`
                        : `url(${require('../../../../LandingPages/FreeContent/HardworkEm/Assets/bg-pagina-full.jpg')})`,
                    height: '100%',
                    backgroundSize: 'cover',
                }}
            >
                <Container
                    fixed
                    maxWidth="xl"
                    disableGutters={true}
                    className={classes.background}
                >
                    <Grid
                        container
                        justifyContent={'space-between'}
                        alignContent="center"
                        className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
                    >
                        <Grid
                            item
                            xs={12}
                            md={6}
                            container
                            justifyContent={'flex-start'}
                            className={`${classes.gridTextContainer} revalida`}
                            gap={1}
                        >
                            {matchesDownXl ? (
                                <img
                                    src={
                                        require('../../../../../Assets/Extensivo/Revalida/logo-revalida-santa-cruz.svg')
                                            .default
                                    }
                                    alt="Logo Revalida Santa Cruz"
                                    style={matchesDownXl ? null : matchesTablet ? { height: 120 } : null}
                                    className={classes.logoSecond}
                                />
                            ) : null}
                            <Grid className={classes.firstGrid}>
                                <Typography
                                    className={`${classes.title} roboto-condensed`}
                                    color="secondary"
                                >
                                    Uma noite com um show de aulas presenciais em Santa Cruz!
                                </Typography>
                                <Typography
                                    className={`${classes.text} poppins`}
                                    color="secondary"
                                >
                                    O Hardwork estará contigo no dia 28/06! Vamos discutir dezenas de temas importantes
                                    para a vida e para o Revalida, tudo com a cara da Live das Lives, a revisão
                                    presencial do Hardwork!
                                    <br />
                                    <br />
                                    E além de tudo, sorteios de brindes e cursos Hardwork!
                                    <br />
                                    <br />
                                    <span style={{ fontWeight: 600 }}>
                                        As inscrições são GRATUITAS, mas as VAGAS SÃO LIMITADAS!
                                    </span>
                                    <br />
                                    <br />
                                </Typography>
                            </Grid>

                            <Grid className={classes.secondGrid}>
                                <Grid
                                    container
                                    alignItems={'center'}
                                    gap={2}
                                >
                                    {matchesMobile ? null : (
                                        <img
                                            style={{ alignSelf: 'flex-start' }}
                                            src={CalendarIcon}
                                            alt=""
                                        />
                                    )}
                                    <Typography
                                        align="left"
                                        color="secondary"
                                        className={`${classes.impactText} poppins`}
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        PROGRAMAÇÃO DE 28 DE JUNHO
                                    </Typography>
                                </Grid>
                                <img
                                    src={
                                        require('../../../../LandingPages/FreeContent/HardworkEm/Assets/bloco-cronograma.svg')
                                            .default
                                    }
                                    alt=""
                                />
                                <Grid
                                    item
                                    container
                                >
                                    {/* <Grid
										item
										container
										wrap="nowrap"
										gap={2}
									>
										<img
											src={require('../Assets/17H.svg').default}
											alt=""
											style={{ height: 30, top: 5, paddingLeft: 2 }}
										/>
										<Grid
											item
											container
										>
											<Typography className={`${classes.timelineText} poppins`}>
												Credenciamento
											</Typography>
											<Typography
												color="secondary"
												className={`${classes.text} poppins`}
											>
												
												<br />
												<br />
											</Typography>
										</Grid>
									</Grid> */}
                                    {/* <Divider
										variant="middle"
										orientation="vertical"
										className={classes.divider}
									/> */}
                                    {/* <Grid
										item
										container
										wrap="nowrap"
										gap={2}
									>
										<img
											src={require('../Assets/18H.svg').default}
											alt=""
											style={{ height: 30, top: 5, zIndex: 2 }}
										/>
										<Grid
											item
											container
										>
											<Typography className={`${classes.timelineText} poppins`}>
												Logo LDL
											</Typography>
											<Typography
												color="secondary"
												className={`${classes.text} poppins`}
											>
												Discussão de temas da clínica, cirurgia, pediatria,
												ginecologia/obstetrícia e preventiva.
												<br />
												<br />
											</Typography>
										</Grid>
									</Grid> */}

                                    {/* <Grid
										item
										container
										wrap="nowrap"
										gap={2}
									>
										<img
											src={require('../Assets/21H.svg').default}
											alt=""
											style={{ height: 30, top: 5 }}
										/>
										<Grid
											item
											container
										>
											<Typography className={`${classes.timelineText} poppins`}>
												Sorteios para os presentes no evento
											</Typography>
											<Typography
												color="secondary"
												className={`${classes.text} poppins`}
											></Typography>
										</Grid>
									</Grid> */}
                                    <Grid
                                        container
                                        justifyContent={'center'}
                                        marginTop={5}
                                    >
                                        <Grid
                                            container
                                            className={classes.borderdGrid}
                                            style={{ flexWrap: 'nowrap' }}
                                        >
                                            <Typography
                                                align="left"
                                                color="secondary"
                                                className={`${classes.impactTextTitle} poppins`}
                                            >
                                                SEXTA-FEIRA
                                                <span className="bullet"> &#8226; </span>
                                                28JUN
                                                <span className="bullet"> &#8226; </span>
                                                17H
                                            </Typography>
                                        </Grid>
                                        {/* <Typography>*Conforme horário local (UTC -04:00)</Typography> */}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid className={classes.thirdGrid}>
                                <Grid
                                    container
                                    alignItems={'center'}
                                    gap={3}
                                >
                                    <img
                                        style={{ alignSelf: 'flex-start' }}
                                        src={PinIcon}
                                        alt=""
                                    />
                                    <Typography
                                        align="left"
                                        color="secondary"
                                        className={`${classes.impactText} poppins`}
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        ONDE SERÁ O EVENTO?
                                    </Typography>
                                </Grid>

                                <Typography
                                    color="secondary"
                                    className={`${classes.text} poppins`}
                                    style={{ marginBottom: '1.5rem' }}
                                >
                                    Los Tajibos, a Tribute Portfolio Hotel
                                    <br />
                                    Av. San Martín 455, Santa Cruz de la Sierra, Bolívia
                                    <br />
                                    +591 3 3421000
                                </Typography>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1343.3530172396313!2d-63.19777817876402!3d-17.7646011970645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x93f1e81ca095f563%3A0x1b46cfe16901bf9f!2sLos%20Tajibos%2C%20a%20Tribute%20Portfolio%20Hotel!5e0!3m2!1spt-BR!2sbr!4v1718319515035!5m2!1spt-BR!2sbr"
                                    width={
                                        matchesMobile
                                            ? '345'
                                            : matchesTablet
                                                ? '580'
                                                : matchesLg
                                                    ? '390'
                                                    : matchesDownXl
                                                        ? '550'
                                                        : '660'
                                    }
                                    height="233"
                                    allowfullscreen=""
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"
                                    style={{ borderRadius: '12px' }}
                                ></iframe>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={5}
                            container
                            justifyContent="center"
                            className={classes.gridWithBackgroundImage}
                            gap={matchesTablet ? 4 : 0}
                        >
                            <Grid
                                item
                                xs={12}
                                className={classes.gridForm}
                                gap={1}
                            >
                                {matchesDownXl ? null : (
                                    <img
                                        src={require('../../../../../Assets/Extensivo/Revalida/logo-revalida-santa-cruz.svg').default}
                                        alt="Logo Hardwork em Foz do Iguaçu"
                                        className={classes.logo}
                                    />
                                )}
                                <Grid
                                    item
                                    container
                                    justifyContent={'center'}
                                    className="background-gradient-revalida"
                                    style={{ borderRadius: 15, padding: '1rem 1.5rem' }}
                                    gap={1}
                                >
                                    <Typography
                                        color="secondary"
                                        className="poppins"
                                        align={matchesTablet ? 'left' : 'center'}
                                        style={{ fontWeight: 'bold', fontSize: '2.5rem' }}
                                    >
                                        VAGAS ESGOTADAS!
                                    </Typography>
                                    <Typography
                                        color="secondary"
                                        className="poppins"
                                        align={matchesTablet ? 'left' : 'center'}
                                        style={{ fontSize: '1.65rem' }}
                                    >
                                        Fique ligado nas redes sociais para
                                        <br />
                                        não perder os próximos eventos!
                                    </Typography>
                                    <Grid
                                        item
                                        container
                                        justifyContent={matchesMobile ? 'flex-start' : 'center'}
                                        alignContent={'center'}
                                        style={{ marginTop: '1.5rem' }}
                                    >
                                        <IconButton
                                            href="https://www.instagram.com/hardwork.revalida/"
                                            className="default-icon-button"
                                            style={{
                                                paddingLeft: '0',
                                            }}
                                        >
                                            <img
                                                src={require('../Assets/icon-ig.svg').default}
                                                alt=""
                                            />
                                        </IconButton>

                                        <a
                                            color="secondary"
                                            className="poppins"
                                            style={{
                                                alignSelf: 'center',
                                                fontSize: '1.25rem',
                                                color: '#ffffff',
                                                textDecoration: 'unset',
                                            }}
                                            href="https://www.instagram.com/hardwork.revalida/"
                                        >
                                            @hardwork.revalida
                                        </a>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid
							item
							xs={12}
							md={5}
							container
							justifyContent="center"
							style={{ height: 'auto', paddingTop: matchesTablet ? '' : 110 }}
						>
							<Grid
								item
								xs={12}
								className={classes.gridForm}
								gap={1}
							>
								{matchesDownXl ? null : (
									<img
										src={
											require('../../../../../Assets/Extensivo/Revalida/logo-revalida-santa-cruz.svg')
												.default
										}
										alt="Logo Revalida Santa Cruz"
										className={classes.logo}
									/>
								)}
								<Form
									group="REVALIDA INEP 2024 (PRESENCIAL SANTA CRUZ)"
									title={['Inscreva-se gratuitamente! E garanta sua vaga para o evento']}
									titleButton="Garantir minha vaga"
									type="revalida"
									hasName
									hasAdvice
									urlToRedirect="/revaliday/santa-cruz-de-la-sierra-obrigado"
									confirmationMessage={'Sua inscrição está confirmada!'}
								/>
							</Grid>
						</Grid> */}
                    </Grid>
                </Container>
            </Grid>
            {matchesTablet ? null : <Footer />}

            {matchesTablet ? <Footer /> : null}
        </Container>
    );
}

const useStyles = makeStyles((theme) => ({
    section: {
        maxWidth: '1410px',
        margin: '1% 5% auto',
    },
    sectionMobile: {
        padding: '2vh 5% 0',
    },
    title: {
        fontWeight: 'bold !important',
        fontSize: '3rem !important',
        lineHeight: 'unset !important',
        marginBottom: '2rem !important',
        [theme.breakpoints.down('lg')]: {
            fontSize: '2.25rem !important',
            marginBottom: '8px !important',
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: '2.45rem !important',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '2.18rem !important',
            marginBottom: '8px !important',
            lineHeight: 'unset !important',
        },
    },
    gridWithBackgroundImage: {
        paddingTop: '100px !important',
        [theme.breakpoints.down('xl')]: {
            paddingTop: '360px !important',
        },
        [theme.breakpoints.down('lg')]: {
            paddingTop: '340px !important',
        },
        [theme.breakpoints.down('md')]: {
            paddingTop: '34px !important',
        },
    },
    text: {
        fontSize: '1.3125rem !important',
        color: 'rgba(255, 255, 255, 1)',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8rem !important',
        },
    },
    firstGrid: {
        backgroundColor: 'black',
        borderRadius: '15px',
        marginTop: '6rem',
        padding: '20px',
    },
    secondGrid: {
        backgroundColor: 'black',
        borderRadius: '15px',
        marginTop: '2rem',
        padding: '20px',
    },
    thirdGrid: {
        backgroundColor: 'black',
        borderRadius: '15px',
        marginTop: '2rem',
        padding: '20px',
    },
    borderdGrid: {
        backgroundColor: 'rgba(9, 215, 133, 0.2)',
        border: '2px solid #09D785',
        borderRadius: '8px',
        justifyContent: 'center',
        width: '80% !important',
        height: '65% !important',
        padding: '8px',
        [theme.breakpoints.down('md')]: {
            padding: '6px',
        },
    },
    gridForm: {
        width: 567,
        position: 'sticky',
        // bottom: 40,
        right: 0,
        top: 40,
        zIndex: 10,
        display: 'flex',
        flexDirection: 'column !important',
        alignItems: 'center !important',
        alignSelf: 'flex-start',
        [theme.breakpoints.down(1300)]: {
            width: 467,
            right: 30,
            bottom: 10,
        },
        [theme.breakpoints.down('lg')]: {
            right: 50,
        },
        [theme.breakpoints.down(1010)]: {
            right: 20,
        },
        [theme.breakpoints.down('md')]: {
            position: 'unset',
            right: 'unset',
            top: 'unset',
            zIndex: 0,
        },
    },
    gridTextContainer: {
        paddingBottom: '1em',
        [theme.breakpoints.down('lg')]: {
            paddingRight: '2em',
        },
        [theme.breakpoints.down('md')]: {
            paddingRight: 0,
        },
    },

    gridTitle: {
        backgroundColor: '#000000',
        padding: '0px 25px',
        borderTopRightRadius: 20,
        borderTopLeftRadius: 20,
        [theme.breakpoints.down('sm')]: {
            padding: '0px 15px',
        },
    },
    impactText: {
        margin: '0 0 2rem !important',
        fontSize: '2.2rem !important',
        lineHeight: 'unset !important',
        [theme.breakpoints.down('lg')]: {
            fontSize: '1.75rem !important',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.3rem !important',
        },
        [theme.breakpoints.down(395)]: {
            fontSize: '1.2rem !important',
        },
        [theme.breakpoints.down(380)]: {
            fontSize: '1rem !important',
        },
    },
    impactTextTitle: {
        margin: '0 0 2rem !important',
        fontSize: '2.2rem !important',
        lineHeight: 'unset !important',
        padding: 10,
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
            fontSize: '1.75rem !important',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem !important',
        },
        [theme.breakpoints.down(395)]: {
            fontSize: '1.2rem !important',
        },
        [theme.breakpoints.down(380)]: {
            fontSize: '0.9rem !important',
        },
    },
    logo: {
        width: '120%',
        [theme.breakpoints.down('xl')]: {
            height: 'unset',
            width: 250,
        },
    },
    logoSecond: {
        marginTop: '6rem',
    },
    divider: {
        position: 'absolute',
        border: '1.5px solid #fff !important',
        left: '5.2em',
        backgroundColor: '#fff',
        height: '100%',
        // zIndex: 1,
        [theme.breakpoints.down('xl')]: {
            height: '200px !important',
        },
        [theme.breakpoints.down('lg')]: {
            height: '280px !important',
        },
        [theme.breakpoints.down('md')]: {
            height: '295px !important',
        },
        [theme.breakpoints.down('sm')]: {
            height: '190px !important',
        },
    },
    imgTime: {
        height: 30,
        top: 5,
    },
    timelineText: {
        fontSize: '1.5rem !important',
        color: 'white',
        fontWeight: 'bold !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.2rem !important',
        },
    },
    impactText2: {
        margin: '1.5rem 0 0 !important',
        fontSize: '1.75rem !important',
        lineHeight: 'unset !important',
        fontWeight: 'bold !important',

        [theme.breakpoints.down('lg')]: {
            fontSize: '1.5rem !important',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.45rem !important',
        },
        [theme.breakpoints.down(395)]: {
            fontSize: '1.2rem !important',
        },
        [theme.breakpoints.down(380)]: {
            fontSize: '1rem !important',
        },
    },
}));
