import { useState } from 'react';
import { Divider, Grid, Modal, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CountDownTimer from './CountDownTimer';
import RemainingVacancyBarRevalida from './RemainingVacancyBarRevalida';
import DefaultButton from '../../../../../Components/Buttons/DefaultButton';
import { Link } from 'react-router-dom';
import WaitListForm from '../../../../../Components/Utils/Forms/WaitListForm';

export default function DateInfoCardRevalidaTwo({
	lot,
	openingDateSPRINTStudents,
	closeDateSPRINTStudents,
	openingDateGeneralPublic,
	targetDate,
	numParticipants,
	contest,
	modality,
	executeScroll,
	fullLots,
	currentId,
}) {
	const classes = useStyles();
	const theme = useTheme();
	const [openModal, setOpenModal] = useState(false);
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));
	const matchesXl = useMediaQuery(theme.breakpoints.down('xl'));

	const lotsDate = new Date(targetDate).getTime();
	const startDate = new Date(lot?.data_inicio).getTime();
	const endDate = new Date(lot?.data_limite).getTime();
	const releaseLotDate = new Date(lot?.data_inicio);
	const currentDate = new Date();
	const initialDate = new Date(lot?.data_inicio);
	const finalDate = new Date(lot?.data_limite);

	// Aqui, estamos convertendo a hora local do usuário em Tempo Universal Coordenado (UTC)
	// para garantir que a liberação do lote seja simultânea, independentemente da localização geográfica.
	const currentDateUTC = new Date(
		currentDate.getUTCFullYear(),
		currentDate.getUTCMonth(),
		currentDate.getUTCDate(),
		currentDate.getUTCHours(),
		currentDate.getUTCMinutes(),
		currentDate.getUTCSeconds(),
		currentDate.getUTCMilliseconds()
	);

	// Como recebemos o horário de Brasília (-3 GMT) da API, adicionamos 3 horas para alinhá-lo com o UTC (Tempo Universal Coordenado)
	// Isso garante que a comparação das datas seja no fuso horário UTC.
	let initialDateUTC = new Date(initialDate);
	initialDateUTC = new Date(initialDateUTC.setTime(initialDateUTC.getTime() + 3 * 60 * 60 * 1000));

	console.log(initialDateUTC);

	let finalDateUTC = new Date(finalDate);
	finalDateUTC = new Date(finalDateUTC.setTime(finalDateUTC.getTime() + 3 * 60 * 60 * 1000));

	const handleOpen = () => {
		setOpenModal(true);
	};

	const handleClose = () => {
		setOpenModal(false);
	};
	// const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	// function formatDateWithTimeZone(date, timeZone) {
	// 	return new Intl.DateTimeFormat('en-US', {
	// 		timeZone: timeZone,
	// 		year: 'numeric',
	// 		month: '2-digit',
	// 		day: '2-digit',
	// 		hour: '2-digit',
	// 		minute: '2-digit',
	// 		second: '2-digit',
	// 	}).format(date);
	// }

	// const date = new Date();
	// const formattedDate = formatDateWithTimeZone(date, timeZone);

	// console.log(formattedDate);

	function dateFormatWithTime(date) {
		const data = new Date(date);
		const dia = data.getDate();
		const hora = data.getHours();
		const minutos = data.getMinutes();
		const mesesEmPortugues = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];
		const mes = mesesEmPortugues[data.getMonth()];

		const diaFormatado = 10 > dia ? `0${dia}` : dia;

		const dataHoraFormatada = `${diaFormatado}${mes} às ${hora}:${10 > minutos ? `0${minutos}` : minutos}h*`;

		return dataHoraFormatada;
	}

	const getLotMonth = fullLots?.map((e) => {
		let dataInicio = new Date(e?.data_inicio);
		return dateFormatWithTime(dataInicio);
	});

	// Usa as datas em UTC para verificar se o lote está ativo
	const isActive = initialDateUTC <= currentDateUTC && currentDateUTC <= finalDateUTC;

	const timeDifference = startDate - currentDateUTC.getTime();

	return (
		<Grid
			container
			style={{
				backgroundColor: '#ffffff',
				backgroundImage: `url(${require('../Assets/BG-gray-line.png')})`,
				borderRadius: 25,
				padding: matchesTablet ? '1rem' : '1.5rem',
			}}
		>
			<Modal
				open={openModal}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				style={{ zIndex: 1000 }}
			>
				<WaitListForm
					group={`LDL REVALIDA 2024.2 ${contest?.name?.toUpperCase()}`}
					onHandleClose={handleClose}
					lot={`${lot?.nome}`}
				/>
			</Modal>
			<Grid
				item
				xs={12}
				md={6}
				container
				style={{ paddingRight: '2rem' }}
			>
				<Grid
					item
					xs={12}
					container
					rowGap={1}
					style={{ height: 'fit-content', marginBottom: matchesTablet ? '0.5rem' : null }}
				>
					<Typography
						color="primary"
						className={`${classes.titleInfoCard} roboto-condensed`}
					>
						Como serão as inscrições para alunos TRILHA Revalida?
					</Typography>
					<Typography
						color="primary"
						className={`${classes.text} poppins`}
					>
						Alunos e alunas TRILHA Revalida poderão se inscrever no lote EXCLUSIVO TRILHA, que ficará
						disponível enquanto houver vagas.
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					container
					rowGap={1}
					style={{
						paddingRight: matchesTablet ? '0.5rem' : null,
						height: 'fit-content',
						marginBottom: '0.5rem',
					}}
				>
					<Typography
						color="primary"
						className={`${classes.titleInfoCard} roboto-condensed`}
					>
						Lote SPRINT: exclusivo para alunos SPRINT
					</Typography>
					<Typography
						color="primary"
						className={`${classes.text} poppins`}
					>
						Alunos e alunas SPRINT poderão se inscrever no lote EXCLUSIVO SPRINT, que ficará disponível
						enquanto houver vagas.
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					container
					alignContent={'center'}
					style={{ paddingRight: '2.5rem' }}
				>
					<Divider
						style={{
							margin: 'unset !important',
							backgroundColor: 'grey',
							minHeight: 0.75,
							width: '100%',
						}}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					container
					rowGap={1}
					style={{
						paddingRight: matchesTablet ? '0.5rem' : null,
						height: 'fit-content',
						margin: matchesTablet ? '0.5rem 0 2rem' : null,
					}}
				>
					<Typography
						color="primary"
						className={`${classes.titleInfoCard} roboto-condensed`}
					>
						Como serão as inscrições para o PÚBLICO GERAL?
					</Typography>
					<Typography
						color="primary"
						className={`${classes.text} poppins`}
					>
						As inscrições para o público geral poderão ser feitas nos lotes adicionais, na dependência de
						ainda existirem vagas disponíveis.
					</Typography>
				</Grid>
			</Grid>
			<Grid
				item
				xs={12}
				md={6}
				className={classes.innerCard}
			>
				<Grid
					item
					xs={12}
					container
					className={classes.gridDivider}
				>
					<Divider
						className={classes.divider}
						orientation="vertical"
						style={matchesMobile && lot?.nome === 'TRILHA' ? { top: 5, height: '75%' } : null}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					container
					className={'residencia'}
					rowGap={matchesTablet ? 1 : 3}
				>
					<Grid
						item
						xs={12}
						container
						direction={'row'}
						justifyContent={'flex-start'}
					>
						<Grid
							item
							xs={12}
							container
							rowGap={matchesTablet ? 3 : 4}
							alignContent={'space-between'}
						>
							<Grid
								item
								xs={12}
								container
								alignContent={'center'}
							>
								<Grid
									item
									xs={1.25}
									container
									justifyContent={matchesMobile ? 'flex-start' : 'center'}
								>
									<input
										type="radio"
										id="html"
										name="fav_language"
										value="HTML"
										color="green"
										checked={lot?.nome === 'TRILHA'}
										style={{ width: matchesLg ? 20 : 25, accentColor: 'green' }}
									></input>
								</Grid>

								<Grid
									item
									xs={10.75}
									container
									className={`${classes.rootInnerCard} ${
										currentId !== lot?.id ? classes.innerCardOpacity50 : null
									} background-central-concursos-vertical-revalida`}
									style={{ padding: matchesLg ? '0 0.4rem' : null }}
								>
									{/* {lot?.nome === 'TRILHA'  && (
										<Typography
											color="secondary"
											fontSize={matchesMobile ? '0.68rem' : matchesLg ? '1.1rem' : '1.2rem'}
											className={`poppins`}
											style={{
												position: 'absolute',
												zIndex: '100',
												top: matchesMobile ? '83%' : matchesLg ? '75%' : '83%',
												right: '3%',
												marginLeft: matchesLg ? '1rem' : null,
											}}
										>
											A partir de <strong>{getLotMonth ? getLotMonth[0] : null}</strong>
										</Typography>
									)} */}

									{(lot?.nome !== 'TRILHA' || lot === undefined) && (
										<img
											src={require('../Assets/mascara-encerrado.svg').default}
											alt=""
											style={{
												width: '100%',
												position: 'absolute',
												zIndex: '100',
												top: 0,
												right: 0,
												height: '100%',
											}}
										/>
									)}
									{matchesLg && lot?.nome === 'TRILHA' && (
										<Grid
											item
											xs={12}
											lg={4.5}
											container
											direction={'column'}
											justifyContent={'center'}
											style={{ padding: matchesLg ? '0 0.5rem' : null }}
										>
											<Grid
												item
												xs={12}
												lg={6.5}
												container
												justifyContent={matchesLg ? 'space-evenly' : 'center'}
												rowGap={0.25}
												style={{
													backgroundColor: '#FFFFFF',
													borderRadius: '0 0 8px 8px',
													marginBottom: '0.5rem',
													paddingTop: '0.5rem',
												}}
											>
												{30 > lot?.qtd_limite - numParticipants && isActive ? (
													<>
														<RemainingVacancyBarRevalida
															numParticipants={numParticipants}
															qtd_limite={lot?.qtd_limite}
														/>
														<Typography
															color="primary"
															className={`poppins`}
															style={{
																fontSize: matchesMobile ? '0.6rem' : '0.7rem',
																fontWeight: '500',
															}}
														>
															APENAS <strong>{lot?.qtd_limite - numParticipants}</strong>{' '}
															VAGAS RESTANTES
														</Typography>
													</>
												) : (
													<CountDownTimer
														targetDate={
															currentDateUTC < initialDateUTC ? startDate : lotsDate
														}
														textColor={'primary'}
													/>
												)}
											</Grid>
										</Grid>
									)}
									<Grid
										item
										xs={12}
										lg={5.8}
										container
										style={
											matchesLg
												? {
														padding:
															lot?.nome === 'TRILHA'
																? '0 0.25rem 0.5rem'
																: '0.5rem 0.25rem',
												  }
												: null
										}
									>
										<Typography
											color="secondary"
											className={`${classes.vipText} poppins`}
										>
											Lote TRILHA <br />{' '}
											<strong className={classes.lotsValueText}>
												R${fullLots ? fullLots[0].valor : null},00
											</strong>
										</Typography>
										<Grid
											item
											xs={12}
											container
											columnGap={1}
											style={{ marginBottom: '1rem' }}
										>
											<Typography
												color="secondary"
												className={`${classes.dateTextInnerCard} poppins`}
												style={{ alignSelf: 'end' }}
											>
												Exclusivo para alunos <strong>TRILHA Revalida</strong>
											</Typography>
										</Grid>
										<Typography
											color="secondary"
											className={`${classes.dateTextInnerCard} poppins`}
										>
											{(lot?.nome !== 'TRILHA' && currentDateUTC > initialDateUTC) ||
											lot === undefined
												? 'Lote encerrado'
												: `Até esgotarem as vagas`}
										</Typography>
									</Grid>

									{!matchesLg && lot?.nome === 'TRILHA' && currentDateUTC < initialDateUTC && (
										<Grid
											item
											xs={12}
											lg={timeDifference > 24 * 60 * 60 * 1000 ? 6 : 5}
											container
											direction={'column'}
											justifyContent={'center'}
											style={{ top: -43 }}
										>
											<Grid
												item
												xs={12}
												lg={6.5}
												container
												justifyContent={'center'}
												rowGap={0.5}
												style={{
													backgroundColor: '#FFFFFF',
													borderRadius: '0 0 8px 8px',
													paddingBottom: '0.5rem',
												}}
											>
												<Grid
													item
													xs={12}
													container
													justifyContent={matchesLg ? 'space-evenly' : 'center'}
													rowGap={0.5}
													style={{
														borderRadius: '0 0 8px 8px',
													}}
												>
													<Typography
														color="primary"
														className="poppins"
														style={{
															fontSize: matchesMobile ? '0.7rem' : '0.75rem',
															alignSelf: matchesLg ? 'center' : null,
															fontWeight: 'bold',
														}}
													>
														Lote TRILHA abre em
													</Typography>
													<CountDownTimer
														targetDate={startDate}
														textColor={'primary'}
													/>
													<DefaultButton
														variant="outlined"
														color={'secondary'}
														className={`${classes.subscribeBtn} poppins background-central-concursos-vertical-revalida`}
														component={Link}
														onClick={() => handleOpen()}
													>
														<img
															src={
																require('../../../../../Assets/Svg/icon-white-bell.svg')
																	.default
															}
															alt=""
															style={{ width: 12 }}
														/>
														&nbsp;{'Avise-me'}
													</DefaultButton>
												</Grid>
											</Grid>
										</Grid>
									)}
									{!matchesLg && lot?.nome === 'TRILHA' && currentDate > startDate && (
										<Grid
											item
											xs={12}
											lg={6}
											container
											direction={'column'}
											justifyContent={'top'}
											style={{ top: -8, height: 'fit-content' }}
										>
											<Grid
												item
												xs={12}
												lg={6.5}
												container
												justifyContent={'center'}
												rowGap={1}
												style={{
													padding: matchesMobile
														? '0.5rem 0.5rem 0.25rem'
														: '0.5rem 0.75rem 0.5rem',
													backgroundColor: '#FFFFFF',
													borderRadius: '0 0 8px 8px',
													marginBottom: '1rem',
												}}
											>
												{30 > lot?.qtd_limite - numParticipants ? (
													<>
														<RemainingVacancyBarRevalida
															numParticipants={numParticipants}
															qtd_limite={lot?.qtd_limite}
														/>
														<Typography
															color="primary"
															className={`poppins`}
															style={{
																fontSize: matchesMobile ? '0.6rem' : '0.7rem',
																fontWeight: '500',
															}}
														>
															APENAS <strong>{lot?.qtd_limite - numParticipants}</strong>{' '}
															VAGAS RESTANTES
														</Typography>
													</>
												) : (
													<CountDownTimer
														targetDate={
															currentDateUTC < initialDateUTC ? startDate : lotsDate
														}
														textColor={'primary'}
													/>
												)}
											</Grid>
										</Grid>
									)}
								</Grid>
							</Grid>
							<Grid
								item
								xs={12}
								container
								alignContent={'center'}
							>
								<Grid
									item
									xs={1.25}
									container
									justifyContent={matchesMobile ? 'flex-start' : 'center'}
								>
									<input
										type="radio"
										id="html"
										name="fav_language"
										value="HTML"
										color="green"
										checked={lot?.nome === 'SPRINT'}
										style={{ width: matchesLg ? 20 : 25, accentColor: 'green' }}
									></input>
								</Grid>

								<Grid
									item
									xs={10.75}
									container
									className={`${classes.rootInnerCard} ${
										lot?.nome !== 'SPRINT' ? classes.innerCardOpacity50 : null
									} background-central-concursos-vertical-revalida`}
									style={{ padding: matchesLg ? '0 0.4rem' : null }}
								>
									{lot?.nome === 'TRILHA' && (
										<Typography
											color="secondary"
											fontSize={matchesMobile ? '0.68rem' : matchesLg ? '1.1rem' : '1.2rem'}
											className={`poppins`}
											style={{
												position: 'absolute',
												zIndex: '100',
												top: matchesMobile ? '83%' : matchesLg ? '75%' : '83%',
												right: '3%',
												marginLeft: matchesLg ? '1rem' : null,
											}}
										>
											A partir de <strong>{getLotMonth ? getLotMonth[1] : null}</strong>
										</Typography>
									)}
									{(lot?.nome === 'Lote 03' ||
										lot?.nome === 'Lote 04' ||
										lot?.nome === 'Lote 05' ||
										lot === undefined) && (
										<img
											src={require('../Assets/mascara-encerrado.svg').default}
											alt=""
											style={{
												width: '100%',
												position: 'absolute',
												zIndex: '100',
												top: 0,
												right: 0,
												height: '100%',
											}}
										/>
									)}
									{matchesLg && lot?.nome === 'SPRINT' && isActive && (
										<Grid
											item
											xs={12}
											lg={4.5}
											container
											direction={'column'}
											justifyContent={'center'}
											style={{ padding: matchesLg ? '0 0.5rem' : null }}
										>
											<Grid
												item
												xs={12}
												lg={6.5}
												container
												justifyContent={matchesLg ? 'space-evenly' : 'center'}
												rowGap={0.25}
												style={{
													backgroundColor: '#FFFFFF',
													borderRadius: '0 0 8px 8px',
													marginBottom: '0.5rem',
													padding: '0.5rem 0',
												}}
											>
												{30 > lot?.qtd_limite - numParticipants && isActive ? (
													<>
														<RemainingVacancyBarRevalida
															numParticipants={numParticipants}
															qtd_limite={lot?.qtd_limite}
														/>
														<Typography
															color="primary"
															className={`poppins`}
															style={{
																fontSize: matchesMobile ? '0.6rem' : '0.7rem',
																fontWeight: '500',
															}}
														>
															APENAS <strong>{lot?.qtd_limite - numParticipants}</strong>{' '}
															VAGAS RESTANTES
														</Typography>
													</>
												) : (
													<>
														<Typography
															color="primary"
															className="poppins"
															style={{
																fontSize: matchesMobile ? '0.7rem' : '0.75rem',
																alignSelf: matchesMobile ? 'center' : null,
																fontWeight: 'bold',
															}}
														>
															LOTE {lot?.nome} se encerra em
														</Typography>
														<CountDownTimer
															targetDate={currentDate < startDate ? startDate : lotsDate}
															textColor={'primary'}
														/>
													</>
												)}
											</Grid>
										</Grid>
									)}
									<Grid
										item
										xs={12}
										lg={5.8}
										container
										style={
											matchesLg
												? {
														padding:
															lot?.nome === 'SPRINT'
																? '0 0.25rem 0.5rem'
																: '0.5rem 0.25rem',
												  }
												: null
										}
									>
										<Typography
											color="secondary"
											className={`${classes.vipText} poppins`}
										>
											Lote SPRINT <br />
											<strong className={classes.lotsValueText}>
												R${fullLots ? fullLots[1].valor : null},00
											</strong>
										</Typography>
										<Grid
											item
											xs={12}
											container
											columnGap={1}
											style={{ marginBottom: '1rem' }}
										>
											{matchesLg ? (
												<Typography
													color="secondary"
													className={`${classes.dateTextInnerCard} poppins`}
													style={{ alignSelf: 'end' }}
												>
													Exclusivo para alunos <strong>TRILHA e/ou SPRINT Revalida</strong>
												</Typography>
											) : (
												<Typography
													color="secondary"
													className={`${classes.dateTextInnerCard} poppins`}
													style={{ alignSelf: 'end' }}
												>
													Exclusivo para alunos{' '}
													<strong>
														TRILHA e/ou SPRINT <br /> Revalida
													</strong>
												</Typography>
											)}
										</Grid>
									</Grid>

									{!matchesLg && lot?.nome === 'SPRINT' && currentDateUTC < initialDateUTC && (
										<Grid
											item
											xs={12}
											lg={timeDifference > 24 * 60 * 60 * 1000 ? 6 : 5}
											container
											direction={'column'}
											style={{ top: -8, height: 'fit-content' }}
										>
											<Grid
												item
												xs={12}
												lg={6.5}
												container
												justifyContent={'center'}
												rowGap={0.5}
												style={{
													backgroundColor: '#FFFFFF',
													borderRadius: '0 0 8px 8px',
													paddingBottom: '0.5rem',
												}}
											>
												<Grid
													item
													xs={12}
													container
													justifyContent={matchesLg ? 'space-evenly' : 'center'}
													rowGap={0.5}
													style={{
														borderRadius: '0 0 8px 8px',
													}}
												>
													{lot?.nome === 'SPRINT' && (
														<>
															<Typography
																color="primary"
																className="poppins"
																style={{
																	fontSize: matchesMobile ? '0.7rem' : '0.75rem',
																	alignSelf: matchesLg ? 'center' : null,
																	fontWeight: 'bold',
																}}
															>
																LOTE SPRINT abre em
															</Typography>
															<CountDownTimer
																targetDate={startDate}
																textColor={'primary'}
															/>
														</>
													)}

													<DefaultButton
														variant="outlined"
														color={'secondary'}
														className={`${classes.subscribeBtn} poppins background-central-concursos-vertical-revalida`}
														component={Link}
														onClick={() => handleOpen()}
													>
														<img
															src={
																require('../../../../../Assets/Svg/icon-white-bell.svg')
																	.default
															}
															alt=""
															style={{ width: 12 }}
														/>
														&nbsp;{'Avise-me'}
													</DefaultButton>
												</Grid>
											</Grid>
										</Grid>
									)}
									{!matchesLg && lot?.nome === 'SPRINT' && isActive && (
										<Grid
											item
											xs={12}
											lg={6}
											container
											direction={'column'}
											justifyContent={'top'}
											style={{ top: -8, height: 'fit-content' }}
										>
											<Grid
												item
												xs={12}
												lg={6.5}
												container
												justifyContent={'center'}
												style={{
													backgroundColor: '#FFFFFF',
													borderRadius: '0 0 8px 8px',
													marginBottom: '1rem',
													padding: '0.5rem 0',
												}}
											>
												{30 > lot?.qtd_limite - numParticipants ? (
													<>
														<RemainingVacancyBarRevalida
															numParticipants={numParticipants}
															qtd_limite={lot?.qtd_limite}
														/>
														<Typography
															color="primary"
															className={`poppins`}
															style={{
																fontSize: matchesMobile ? '0.6rem' : '0.7rem',
																fontWeight: '500',
															}}
														>
															APENAS <strong>{lot?.qtd_limite - numParticipants}</strong>{' '}
															VAGAS RESTANTES
														</Typography>
													</>
												) : (
													<>
														<Typography
															color="primary"
															className="poppins"
															style={{
																fontSize: matchesMobile ? '0.7rem' : '0.75rem',
																alignSelf: matchesMobile ? 'center' : null,
																fontWeight: 'bold',
															}}
														>
															LOTE {lot?.nome} se encerra em
														</Typography>
														<CountDownTimer
															targetDate={currentDate < startDate ? startDate : lotsDate}
															textColor={'primary'}
														/>
													</>
												)}
											</Grid>
										</Grid>
									)}
								</Grid>
							</Grid>
							<Grid
								item
								xs={12}
								container
								alignContent={'center'}
							>
								<Grid
									item
									xs={1.25}
									container
									justifyContent={matchesMobile ? 'flex-start' : 'center'}
								>
									<input
										type="radio"
										id="html"
										name="fav_language"
										value="HTML"
										color="green"
										checked={lot?.nome === 'Lote 03'}
										style={{ width: matchesLg ? 20 : 25, accentColor: 'green' }}
									></input>
								</Grid>
								<Grid
									item
									xs={10.75}
									container
									direction={matchesLg ? 'row' : 'row-reverse'}
									className={`${classes.rootInnerCardLote} ${
										lot?.nome !== 'Lote 03' || lot === undefined ? classes.innerCardOpacity50 : null
									}`}
									style={{
										backgroundColor: '#ffffff',
									}}
								>
									{(lot?.nome === 'TRILHA' || lot?.nome === 'SPRINT') && (
										<Typography
											color="primary"
											fontSize={matchesMobile ? '0.68rem' : matchesLg ? '1.1rem' : '1.2rem'}
											className={`poppins`}
											style={{
												position: 'absolute',
												zIndex: '100',
												top: matchesMobile ? '70%' : matchesLg ? '70%' : '68%',
												right: '3%',
												marginLeft: matchesLg ? '1rem' : null,
											}}
										>
											A partir de <strong>{getLotMonth ? getLotMonth[2] : null}</strong>
										</Typography>
									)}

									{(lot?.nome === 'Lote 04' || lot?.nome === 'Lote 05' || lot === undefined) && (
										<img
											src={require('../Assets/mascara-encerrado.svg').default}
											alt=""
											style={{
												width: '100%',
												position: 'absolute',
												zIndex: '100',
												top: 0,
												right: 0,
												height: '100%',
											}}
										/>
									)}
									{matchesXl &&
										!isActive &&
										targetDate &&
										lot?.nome !== 'TRILHA' &&
										lot?.nome !== 'SPRINT' &&
										lot?.nome !== 'Lote 04' &&
										lot?.nome !== 'Lote 05' && (
											<Grid
												item
												xs={12}
												lg={6}
												container
												direction={'column'}
												justifyContent={'center'}
											>
												<Grid
													item
													xs={12}
													lg={4.5}
													container
													justifyContent={matchesXl ? 'space-evenly' : 'center'}
													rowGap={0.5}
													style={{
														borderRadius: '0 0 8px 8px',
													}}
												>
													<Typography
														color="primary"
														className="poppins"
														style={{
															fontSize: matchesMobile ? '0.7rem' : '0.75rem',
															alignSelf: matchesXl ? 'center' : null,
															fontWeight: 'bold',
														}}
													>
														LOTE 03 abre em
													</Typography>
													<CountDownTimer
														targetDate={startDate}
														textColor={'primary'}
													/>
													{matchesMobile && (
														<DefaultButton
															variant="outlined"
															color={'secondary'}
															className={`${classes.subscribeBtn} poppins background-central-concursos-vertical-revalida`}
															component={Link}
															onClick={() => handleOpen()}
														>
															<img
																src={
																	require('../../../../../Assets/Svg/icon-white-bell.svg')
																		.default
																}
																alt=""
																style={{ width: 12 }}
															/>
															&nbsp;{'Avise-me'}
														</DefaultButton>
													)}
												</Grid>
											</Grid>
										)}
									<Grid
										item
										xs={12}
										lg={isActive ? 6.5 : 6}
										container
										direction={'column'}
										justifyContent={'center'}
										style={
											matchesMobile && lot?.nome === 'Lote 03' && !isActive
												? { display: 'none' }
												: null
										}
									>
										<Typography
											color="primary"
											className={`${classes.vipText} ${
												lot?.nome === 'TRILHA' ||
												lot?.nome === 'SPRINT' ||
												lot?.nome === 'Lote 04' ||
												lot === undefined
													? classes.textOpacity
													: null
											} poppins`}
										>
											{fullLots ? fullLots[2].nome : 'Lote 03'} <br />{' '}
											<strong className={classes.lotsValueText}>
												R${fullLots ? fullLots[2].valor : null},00
											</strong>
										</Typography>
										{lot?.nome === 'Lote 03' && isActive && (
											<DefaultButton
												variant="outlined"
												color={'primary'}
												className={`${classes.subscribeBtn2} white-btn-third poppins`}
												onClick={() => executeScroll()}
											>
												<img
													src={require('../../../../../Assets/Svg/Icons/bagIcon.svg').default}
													alt=""
													className={classes.logoBtn}
												/>
												&nbsp;&nbsp;Garantir vaga
											</DefaultButton>
										)}
									</Grid>
									{isActive &&
										lot?.nome !== 'TRILHA' &&
										lot?.nome !== 'SPRINT' &&
										lot?.nome !== 'Lote 04' &&
										lot?.nome !== 'Lote 05' &&
										(contest?.modality !== 'online' ? (
											<Grid
												item
												xs={12}
												lg={5.5}
												container
												justifyContent={'center'}
												alignContent={'center'}
												rowGap={0.5}
												style={{
													marginTop: matchesMobile
														? lot?.nome === 'Lote 03' && isActive
															? '1rem'
															: '0.75rem'
														: null,
												}}
											>
												{30 > lot?.qtd_limite - numParticipants && (
													<RemainingVacancyBarRevalida
														numParticipants={numParticipants}
														qtd_limite={lot?.qtd_limite}
													/>
												)}

												{30 > lot?.qtd_limite - numParticipants && (
													<Typography
														color="primary"
														className={`poppins`}
														style={{
															fontSize: matchesMobile ? '0.6rem' : '0.7rem',
															fontWeight: '500',
														}}
													>
														APENAS <strong>{lot?.qtd_limite - numParticipants}</strong>{' '}
														VAGAS RESTANTES
													</Typography>
												)}
											</Grid>
										) : (
											<Grid
												item
												xs={12}
												lg={5.5}
												container
												direction={'column'}
												justifyContent={'center'}
											>
												<Grid
													item
													xs={12}
													lg={6.5}
													container
													justifyContent={'center'}
													rowGap={0.5}
													style={{
														borderRadius: '0 0 8px 8px',
													}}
												>
													<Typography
														color="primary"
														className="poppins"
														style={{
															fontSize: matchesMobile ? '0.7rem' : '0.75rem',
															alignSelf: matchesMobile ? 'center' : null,
															fontWeight: 'bold',
														}}
													>
														LOTE 03 SE ENCERRA EM
													</Typography>
													<CountDownTimer
														targetDate={endDate}
														textColor={'primary'}
													/>
												</Grid>
											</Grid>
										))}
									{!matchesLg &&
										!isActive &&
										lot?.nome !== 'TRILHA' &&
										lot?.nome !== 'SPRINT' &&
										lot?.nome !== 'Lote 04' &&
										lot?.nome !== 'Lote 05' &&
										lot !== undefined && (
											<Grid
												item
												xs={12}
												lg={isActive ? 5 : 6}
												container
												direction={'column'}
												justifyContent={'center'}
											>
												<Grid
													item
													xs={12}
													lg={6.5}
													container
													justifyContent={'center'}
													rowGap={0.5}
													style={{
														borderRadius: '0 0 8px 8px',
													}}
												>
													{currentId !== 0 && (
														<>
															<Typography
																color="primary"
																className="poppins"
																style={{
																	fontSize: matchesMobile ? '0.7rem' : '0.75rem',
																	alignSelf: matchesMobile ? 'center' : null,
																	fontWeight: 'bold',
																}}
															>
																LOTE 03 abre em
															</Typography>
															<CountDownTimer
																targetDate={startDate}
																textColor={'primary'}
															/>
														</>
													)}

													<DefaultButton
														variant="outlined"
														color={'secondary'}
														className={`${classes.subscribeBtn} poppins background-central-concursos-vertical-revalida`}
														component={Link}
														onClick={() => handleOpen()}
													>
														<img
															src={
																require('../../../../../Assets/Svg/icon-white-bell.svg')
																	.default
															}
															alt=""
															style={{ width: 12 }}
														/>
														&nbsp;{'Avise-me'}
													</DefaultButton>
												</Grid>
											</Grid>
										)}
								</Grid>
							</Grid>
							<Grid
								item
								xs={12}
								container
								alignContent={'center'}
							>
								<Grid
									item
									xs={1.25}
									container
									justifyContent={matchesMobile ? 'flex-start' : 'center'}
								>
									<input
										type="radio"
										id="html"
										name="fav_language"
										value="HTML"
										color="green"
										checked={lot?.nome === 'Lote 04'}
										style={{ width: matchesLg ? 20 : 25, accentColor: 'green' }}
									></input>
								</Grid>
								<Grid
									item
									xs={10.75}
									container
									rowGap={matchesTablet ? 1 : 0}
									className={`${classes.rootInnerCardLote} ${
										lot?.nome !== 'Lote 04' || lot === undefined ? classes.innerCardOpacity50 : null
									}`}
									style={{
										backgroundColor: '#ffffff',
									}}
								>
									{(lot?.nome === 'TRILHA' || lot?.nome === 'SPRINT' || lot?.nome === 'Lote 03') && (
										<Typography
											color="primary"
											fontSize={matchesMobile ? '0.68rem' : matchesLg ? '1.1rem' : '1.2rem'}
											className={`poppins`}
											style={{
												position: 'absolute',
												zIndex: '100',
												top: matchesMobile ? '70%' : matchesLg ? '70%' : '68%',
												right: '3%',
												marginLeft: matchesLg ? '1rem' : null,
											}}
										>
											A partir de <strong>{getLotMonth ? getLotMonth[3] : null}</strong>
										</Typography>
									)}
									{(lot?.nome === 'Lote 05' || lot === undefined) && (
										<img
											src={require('../Assets/mascara-encerrado.svg').default}
											alt=""
											style={{
												width: '100%',
												position: 'absolute',
												zIndex: '100',
												top: 0,
												right: 0,
												height: '100%',
											}}
										/>
									)}
									<Grid
										item
										xs={12}
										lg={isActive ? 6.5 : 6}
										container
										direction={'column'}
										justifyContent={'center'}
										// style={!isActive ? { display: 'none' } : null}
									>
										<Typography
											color="primary"
											className={`${classes.vipText} ${
												lot?.nome !== 'Lote 04' ? classes.textOpacity : null
											} poppins`}
										>
											{fullLots ? fullLots[3].nome : 'Lote 04'} <br />{' '}
											<strong className={classes.lotsValueText}>
												R${fullLots ? fullLots[3].valor : null},00
											</strong>
										</Typography>
										{lot?.nome === 'Lote 04' && isActive && (
											<DefaultButton
												variant="outlined"
												color={'primary'}
												className={`${classes.subscribeBtn2} white-btn-third poppins`}
												onClick={() => executeScroll()}
											>
												<img
													src={require('../../../../../Assets/Svg/Icons/bagIcon.svg').default}
													alt=""
													className={classes.logoBtn}
												/>
												&nbsp;&nbsp;Garantir vaga
											</DefaultButton>
										)}
									</Grid>
									{matchesLg &&
										!isActive &&
										targetDate &&
										lot?.nome !== 'TRILHA' &&
										lot?.nome !== 'SPRINT' &&
										lot?.nome !== 'Lote 03' &&
										lot?.nome !== 'Lote 05' && (
											<Grid
												item
												xs={12}
												lg={4.5}
												container
												direction={'column'}
												justifyContent={'center'}
											>
												<Grid
													item
													xs={12}
													lg={6.5}
													container
													justifyContent={matchesLg ? 'space-evenly' : 'center'}
													rowGap={0.5}
													style={{
														borderRadius: '0 0 8px 8px',
													}}
												>
													<DefaultButton
														variant="outlined"
														color={'secondary'}
														className={`${classes.subscribeBtn} poppins background-central-concursos-vertical-revalida`}
														component={Link}
														onClick={() => handleOpen()}
													>
														<img
															src={
																require('../../../../../Assets/Svg/icon-white-bell.svg')
																	.default
															}
															alt=""
															style={{ width: 12 }}
														/>
														&nbsp;{'Avise-me'}
													</DefaultButton>
													{lot?.nome === 'SPRINT' ||
														lot?.nome === 'Lote 03' ||
														(lot?.nome === 'Lote 04' && !isActive && (
															<Typography
																color="primary"
																fontSize={
																	matchesMobile
																		? '0.68rem'
																		: matchesLg
																		? '1.1rem'
																		: '1.2rem'
																}
																className={`poppins`}
															>
																A partir de{' '}
																<strong>{getLotMonth ? getLotMonth[3] : null}</strong>
															</Typography>
														))}
												</Grid>
											</Grid>
										)}
									{isActive &&
										lot?.nome !== 'TRILHA' &&
										lot?.nome !== 'SPRINT' &&
										lot?.nome !== 'Lote 03' &&
										lot?.nome !== 'Lote 05' &&
										(contest?.modality !== 'online' ? (
											<Grid
												item
												xs={12}
												lg={5.5}
												container
												justifyContent={'center'}
												alignContent={'center'}
												rowGap={0.5}
												style={{ marginTop: matchesMobile ? '0.75rem' : null }}
											>
												{30 > lot?.qtd_limite - numParticipants && (
													<RemainingVacancyBarRevalida
														numParticipants={numParticipants}
														qtd_limite={lot?.qtd_limite}
													/>
												)}
												{/* {50 > lot?.qtd_limite - numParticipants && (
													<RemainingVacancyBarRevalida
														numParticipants={numParticipants}
														qtd_limite={lot?.qtd_limite}
													/>
												)}
												{50 > lot?.qtd_limite - numParticipants &&
												lot?.qtd_limite - numParticipants > 30 ? (
													<Typography
														color="primary"
														className={`poppins`}
														style={{
															fontSize: matchesMobile ? '0.6rem' : '0.7rem',
															fontWeight: '500',
														}}
													>
														Últimas vagas
													</Typography>
												) : null} */}
												{30 > lot?.qtd_limite - numParticipants && (
													<Typography
														color="primary"
														className={`poppins`}
														style={{
															fontSize: matchesMobile ? '0.6rem' : '0.7rem',
															fontWeight: '500',
														}}
													>
														APENAS <strong>{lot?.qtd_limite - numParticipants}</strong>{' '}
														VAGAS RESTANTES
													</Typography>
												)}
											</Grid>
										) : (
											<Grid
												item
												xs={12}
												lg={5.5}
												container
												direction={'column'}
												justifyContent={'center'}
											>
												<Grid
													item
													xs={12}
													lg={6.5}
													container
													justifyContent={'center'}
													rowGap={0.5}
													style={{
														borderRadius: '0 0 8px 8px',
													}}
												>
													<Typography
														color="primary"
														className="poppins"
														style={{
															fontSize: matchesMobile ? '0.7rem' : '0.75rem',
															alignSelf: matchesMobile ? 'center' : null,
															fontWeight: 'bold',
														}}
													>
														LOTE 04 SE ENCERRA EM
													</Typography>
													<CountDownTimer
														targetDate={endDate}
														textColor={'primary'}
													/>
												</Grid>
											</Grid>
										))}
									{!matchesLg &&
										!isActive &&
										lot?.nome !== 'TRILHA' &&
										lot?.nome !== 'SPRINT' &&
										lot?.nome !== 'Lote 03' &&
										lot?.nome !== 'Lote 05' &&
										lot !== undefined && (
											<Grid
												item
												xs={12}
												lg={isActive ? 5 : 6}
												container
												direction={'column'}
												justifyContent={'center'}
											>
												<Grid
													item
													xs={12}
													lg={6.5}
													container
													justifyContent={'center'}
													rowGap={0.5}
													style={{
														borderRadius: '0 0 8px 8px',
													}}
												>
													<DefaultButton
														variant="outlined"
														color={'secondary'}
														className={`${classes.subscribeBtn} poppins background-central-concursos-vertical-revalida`}
														component={Link}
														onClick={() => handleOpen()}
													>
														<img
															src={
																require('../../../../../Assets/Svg/icon-white-bell.svg')
																	.default
															}
															alt=""
															style={{ width: 12 }}
														/>
														&nbsp;{'Avise-me'}
													</DefaultButton>
												</Grid>
												{lot?.nome === 'TRILHA' ||
													lot?.nome === 'SPRINT' ||
													lot?.nome === 'Lote 03' ||
													(lot?.nome === 'Lote 04' && !isActive && (
														<Typography
															color="primary"
															fontSize={
																matchesMobile
																	? '0.68rem'
																	: matchesLg
																	? '1.1rem'
																	: '1.2rem'
															}
															className={`poppins`}
														>
															A partir de{' '}
															<strong>{getLotMonth ? getLotMonth[3] : null}</strong>
														</Typography>
													))}
											</Grid>
										)}
								</Grid>
							</Grid>
							<Grid
								item
								xs={12}
								container
								alignContent={'center'}
							>
								<Grid
									item
									xs={1.25}
									container
									justifyContent={matchesMobile ? 'flex-start' : 'center'}
								>
									<input
										type="radio"
										id="html"
										name="fav_language"
										value="HTML"
										color="green"
										checked={lot?.nome === 'Lote 05'}
										style={{ width: matchesLg ? 20 : 25, accentColor: 'green' }}
									></input>
								</Grid>
								<Grid
									item
									xs={10.75}
									container
									rowGap={matchesTablet ? 1 : 0}
									className={`${classes.rootInnerCardLote} ${
										lot?.nome !== 'Lote 05' || lot === undefined ? classes.innerCardOpacity50 : null
									}`}
									style={{
										backgroundColor: '#ffffff',
									}}
								>
									{(numParticipants >= lot?.qtd_limite || lot === undefined) && (
										<img
											src={require('../Assets/mascara-encerrado.svg').default}
											alt=""
											style={{
												width: '100%',
												position: 'absolute',
												zIndex: '100',
												top: 0,
												right: 0,
												height: '100%',
											}}
										/>
									)}
									<Grid
										item
										xs={12}
										lg={isActive ? 6.5 : 6}
										container
										direction={'column'}
										justifyContent={'center'}
										style={
											matchesMobile && lot?.nome === 'Lote 05' && !isActive
												? { display: 'none' }
												: null
										}
									>
										<Typography
											color="primary"
											className={`${classes.vipText} ${
												lot?.nome !== 'Lote 05' ? classes.textOpacity : null
											} poppins`}
										>
											{fullLots ? fullLots[4].nome : 'Lote geral 05'} <br />{' '}
											<strong className={classes.lotsValueText}>
												R${fullLots ? fullLots[3].valor : null},00
											</strong>
										</Typography>
										{lot?.nome === 'Lote 05' && isActive && (
											<DefaultButton
												variant="outlined"
												color={'primary'}
												className={`${classes.subscribeBtn2} white-btn-third poppins`}
												onClick={() => executeScroll()}
											>
												<img
													src={require('../../../../../Assets/Svg/Icons/bagIcon.svg').default}
													alt=""
													className={classes.logoBtn}
												/>
												&nbsp;&nbsp;Garantir vaga
											</DefaultButton>
										)}
									</Grid>
									{/* {matchesLg &&
										!isActive &&
										lot?.nome !== 'TRILHA' &&
										lot?.nome !== 'SPRINT' &&
										lot?.nome !== 'Lote 03' &&
										lot?.nome !== 'Lote 04' && (
											<Grid
												item
												xs={12}
												lg={4.5}
												container
												direction={'column'}
												justifyContent={'center'}
											>
												<Grid
													item
													xs={12}
													lg={6.5}
													container
													justifyContent={matchesLg ? 'space-evenly' : 'center'}
													rowGap={0.5}
													style={{
														borderRadius: '0 0 8px 8px',
													}}
												>
													<DefaultButton
														variant="outlined"
														color={'secondary'}
														className={`${classes.subscribeBtn} poppins background-central-concursos-vertical-revalida`}
														component={Link}
														onClick={() => handleOpen()}
													>
														<img
															src={
																require('../../../../../Assets/Svg/icon-white-bell.svg')
																	.default
															}
															alt=""
															style={{ width: 12 }}
														/>
														&nbsp;{'Avise-me'}
													</DefaultButton>
													
													<Typography
														color="primary"
														fontSize={
															matchesMobile ? '0.68rem' : matchesLg ? '1.1rem' : '1.2rem'
														}
														className={`poppins`}
													>
														A partir de{' '}
														<strong>{getLotMonth ? getLotMonth[4] : null}</strong>
													</Typography>
													
												</Grid>
											</Grid>
										)} */}
									{isActive &&
										lot?.nome !== 'TRILHA' &&
										lot?.nome !== 'SPRINT' &&
										lot?.nome !== 'Lote 03' &&
										lot?.nome !== 'Lote 04' &&
										(contest?.modality !== 'online' ? (
											<Grid
												item
												xs={12}
												lg={5.5}
												container
												justifyContent={'center'}
												alignContent={'center'}
												rowGap={0.5}
												style={{ marginTop: matchesMobile ? '0.75rem' : null }}
											>
												{30 > lot?.qtd_limite - numParticipants && (
													<RemainingVacancyBarRevalida
														numParticipants={numParticipants}
														qtd_limite={lot?.qtd_limite}
													/>
												)}

												{30 > lot?.qtd_limite - numParticipants && (
													<Typography
														color="primary"
														className={`poppins`}
														style={{
															fontSize: matchesMobile ? '0.6rem' : '0.7rem',
															fontWeight: '500',
														}}
													>
														APENAS <strong>{lot?.qtd_limite - numParticipants}</strong>{' '}
														VAGAS RESTANTES
													</Typography>
												)}
											</Grid>
										) : (
											<Grid
												item
												xs={12}
												lg={5.5}
												container
												direction={'column'}
												justifyContent={'center'}
											>
												<Grid
													item
													xs={12}
													lg={6.5}
													container
													justifyContent={'center'}
													rowGap={0.5}
													style={{
														borderRadius: '0 0 8px 8px',
													}}
												>
													<Typography
														color="primary"
														className="poppins"
														style={{
															fontSize: matchesMobile ? '0.7rem' : '0.75rem',
															alignSelf: matchesMobile ? 'center' : null,
															fontWeight: 'bold',
														}}
													>
														LOTE 05 SE ENCERRA EM
													</Typography>
													<CountDownTimer
														targetDate={endDate}
														textColor={'primary'}
													/>
												</Grid>
											</Grid>
										))}
									{!matchesLg &&
										!isActive &&
										lot?.nome !== 'TRILHA' &&
										lot?.nome !== 'SPRINT' &&
										lot?.nome !== 'Lote 03' &&
										lot?.nome !== 'Lote 04' &&
										lot !== undefined && (
											<Grid
												item
												xs={12}
												lg={isActive ? 5 : 6}
												container
												direction={'column'}
												justifyContent={'center'}
											>
												<Grid
													item
													xs={12}
													lg={6.5}
													container
													justifyContent={'center'}
													rowGap={0.5}
													style={{
														borderRadius: '0 0 8px 8px',
													}}
												>
													<DefaultButton
														variant="outlined"
														color={'secondary'}
														className={`${classes.subscribeBtn} poppins background-central-concursos-vertical-revalida`}
														component={Link}
														onClick={() => handleOpen()}
													>
														<img
															src={
																require('../../../../../Assets/Svg/icon-white-bell.svg')
																	.default
															}
															alt=""
															style={{ width: 12 }}
														/>
														&nbsp;{'Avise-me'}
													</DefaultButton>
													{lot?.nome === 'TRILHA' ||
														lot?.nome === 'SPRINT' ||
														lot?.nome === 'Lote 03' ||
														(lot?.nome === 'Lote 05' && !isActive && (
															<Typography
																color="primary"
																fontSize={
																	matchesMobile
																		? '0.68rem'
																		: matchesLg
																		? '1.1rem'
																		: '1.2rem'
																}
																className={`poppins`}
															>
																A partir de{' '}
																<strong>{getLotMonth ? getLotMonth[4] : null}</strong>
															</Typography>
														))}
												</Grid>
											</Grid>
										)}
									{(lot?.nome === 'TRILHA' ||
										lot?.nome === 'SPRINT' ||
										lot?.nome === 'Lote 03' ||
										lot?.nome === 'Lote 04') && (
										<Typography
											color="primary"
											fontSize={matchesMobile ? '0.68rem' : matchesLg ? '1.1rem' : '1.2rem'}
											className={`poppins`}
											style={{ alignSelf: 'flex-end' }}
										>
											Apenas se restarem vagas do Lote 4
										</Typography>
									)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Typography
					color="secondary"
					style={{ fontSize: '0.9rem', marginTop: '2rem' }}
					className="poppins"
				>
					*Horário de Brasília
				</Typography>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	titleInfoCard: {
		fontSize: '1.8rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.25rem !important',
		},
	},
	text: {
		fontSize: '1.2rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '0.8rem !important',
		},
	},
	innerCard: {
		backgroundColor: '#0d0d0de6',
		borderRadius: 22,
		padding: '2rem 1rem 1.5rem',
		[theme.breakpoints.down('lg')]: {
			padding: '1rem 1rem 3rem',
		},
		[theme.breakpoints.down('md')]: {
			padding: '1rem 1rem 1.5rem',
		},
	},
	titleInner: {
		fontSize: '2.25rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.5rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.25rem !important',
		},
	},
	vipText: {
		fontWeight: 'bold !important',
		fontSize: '1.5rem !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.9rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			lineHeight: 'unset !important',
		},
	},
	dateTextInnerCard: {
		fontSize: '1.2rem !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.7rem !important',
		},
	},
	textOpacity: {
		color: '#464646 !important',
	},
	rootInnerCard: {
		padding: '0.5rem 1rem',
		borderRadius: '10px',
		[theme.breakpoints.down('md')]: {
			padding: '0.2rem 0.4rem',
		},
	},
	titleOpening: {
		[theme.breakpoints.down('md')]: {
			fontSize: '0.8rem !important',
		},
	},
	rootInnerCardLote: {
		padding: '0.5rem 1rem',
		borderRadius: '10px',
		[theme.breakpoints.down('md')]: {
			padding: '0.5rem 0.75rem',
		},
	},
	innerCardOpacity50: {
		opacity: 0.5,
	},
	elipseGroup: {
		top: '2.7rem',
		height: '24rem',
		[theme.breakpoints.down('xl')]: {
			height: '25rem',
		},
		[theme.breakpoints.down('md')]: {
			height: '17rem',
			top: '1.5rem',
		},
	},
	divider: {
		alignSelf: 'center',
		backgroundColor: '#ffffff',
		minHeight: 1,
		left: 33,
		maxHeight: '75%',
		top: -29,
		[theme.breakpoints.down('xl')]: {
			left: 28,
		},
		[theme.breakpoints.down('lg')]: {
			left: 21,
			top: -38,
		},
		[theme.breakpoints.down('md')]: {
			top: -43,
			left: 28,
		},
		[theme.breakpoints.down('sm')]: {
			margin: '1rem 0 !important',
			top: -37,
			left: 10,
		},
		[theme.breakpoints.down(361)]: {
			left: 10,
		},
	},
	subscribeBtn: {
		width: '80% !important',
		padding: 'unset !important',
		transition: 'ease 0.2s !important',
		alignSelf: 'center',
		border: '1px transparent !important',
		fontWeight: 'bold !important',
		fontSize: '0.95rem !important',
		'&:hover': {
			width: '85% !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
			width: '80% !important',
		},
	},
	subscribeBtn2: {
		width: 'fit-content',
		fontSize: '0.9rem !important',
		fontWeight: 'bold !important',
		'&:hover': {
			scale: 1.05,
			color: '#ffffff !important',
			'& $logoBtn': {
				filter: 'invert(0)',
			},
		},
		[theme.breakpoints.down('xl')]: {
			padding: '0.25rem 2.5rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '0.85rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.75rem !important',
			padding: '0.25rem 1.25rem !important',
			border: '2px solid transparent !important',
			marginTop: 'unset !important',
		},
	},
	logoBtn: {
		filter: 'invert(1)',
		transition: 'ease-in-out 0.2s',
		[theme.breakpoints.down('xl')]: {
			height: 30,
		},
		[theme.breakpoints.down('sm')]: {
			height: 17,
		},
	},
	gridDivider: {
		height: '100%',
		position: 'absolute',
		[theme.breakpoints.down('sm')]: {
			height: '100%',
			top: 'unset',
		},
	},
	lotsValueText: {
		fontSize: '2.35rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
		},
	},
}));
