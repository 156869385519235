/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from 'react';
import { Button, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../../Components/Footer';
import { Header } from '../../../../../Components/Header';
import calendarIcon from '../../../../LandingPages/FreeContent/ContestCentral/Assets/calendar-icon-new.svg';
import CalendarButton from '../../../../../Components/Buttons/CalendarButton';
import Form from '../../../../../Components/Utils/Forms/PreRegistration-Revaliday';
import { useParams } from 'react-router-dom';

// import { useState } from 'react';

export default function EmailConfirmation() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const { email } = useParams();

	// console.log('email é: ', email);

	const [open, setOpen] = useState(false);

	// const [closeGroup, setCloseGroup] = useState(false);

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}

			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className={classes.background}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
			</Container>
			<Grid
				style={{
					backgroundImage: `url(${require('../../../../LandingPages/FreeContent/HardworkEm/Assets/bg-pagina-full.jpg')})`,
					height: '100%',
					backgroundSize: 'cover',
				}}
			>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
					className={classes.background}
				>
					<Grid
						container
						justifyContent={'center'}
						alignContent="center"
						className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					>
						<Grid
							item
							xs={12}
							md={6}
							container
							justifyContent={'center'}
							className={`${classes.gridTextContainer} revalida`}
							gap={1}
						>
							<img
								src={
									require('../../../../../Assets/Extensivo/Revalida/logo-revalida-foz-day.svg')
										.default
								}
								alt="Logo Revalida Foz do Iguaçu"
								className={classes.logo}
							/>

							<Form
								group="REVALIDA INEP 2024(PRESENCIAL FOZ SORTEIO)"
								title={[
									'Inscrição confirmada!',
									<br />,
									<p style={{ fontSize: '1rem', marginTop: '0.7rem' }}>
										Preencha os dados abaixo para participar dos sorteios.
									</p>,
								]}
								titleButton="Enviar"
								hasName={false}
								type="revalida"
								noCellphone
								testDateField
								hasAdvice={false}
								mail={email.includes('@') ? email : ''}
								college
								confirmationMessage={'A gente te espera na terça-feira a partir das 18h.'}
							/>
							{/* <Grid
								container
								className={classes.firstGrid}
								justifyContent={'center'}
							>
								<Typography
									className={`${classes.title} roboto-condensed`}
									color="secondary"
								>
									INSCRIÇÃO RECEBIDA!
								</Typography>
								<Typography
									className={`${classes.text} poppins`}
									color="secondary"
								>
									Em breve te enviaremos um e-mail com mais informações sobre o evento.
									<br />
									Enquanto isso, adicione o evento ao seu calendário para não esquecer a data e o
									local!
								</Typography>
							</Grid> */}
							<Grid
								container
								justifyContent={'center'}
								alignContent={'center'}
								className={classes.caldendarBtn}
							>
								<img
									src={calendarIcon}
									alt=""
								/>
								<Button
									onClick={() => {
										setOpen(!open);
									}}
								>
									<Typography className={`${classes.caldendarBtnText} poppins`}>
										Adicionar ao calendário
									</Typography>
								</Button>
							</Grid>
							{open && (
								<Grid
									className={classes.menuItem}
									// style={{position: 'absolute'}}
								>
									<CalendarButton url={'https://calndr.link/e/civo99C63f?s='} />
								</Grid>
							)}
						</Grid>
					</Grid>
				</Container>
			</Grid>
			{matchesTablet ? null : <Footer />}

			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '1% 5% auto',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	caldendarBtn: {
		backgroundColor: '#FFFFFF33',
		borderRadius: '20px',
		height: '35px',
		width: '325px !important',
		padding: '0 10px',
		marginBottom: '16rem !important',
		[theme.breakpoints.down('xl')]: {
			marginBottom: '18.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			marginBottom: '18.5rem !important',
		},
		// color: '#FFFFFF33'
	},
	caldendarBtnText: {
		alignItems: 'center',
		justifyContent: 'center',
		color: 'white',
		fontWeight: 400,
		textTransform: 'none',
		fontSize: '16px !important',
	},
	menuItem: {
		position: 'absolute',
		bottom: '2rem',
		[theme.breakpoints.down('sm')]: {
			bottom: 0,
		},
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.35rem !important',
		lineHeight: 'unset !important',
		marginBottom: '2rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
			marginBottom: '8px !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.18rem !important',
			marginBottom: '8px !important',
			lineHeight: 'unset !important',
		},
	},
	text: {
		fontSize: '1.3125rem !important',
		color: 'rgba(255, 255, 255, 1)',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	firstGrid: {
		backgroundColor: 'black',
		borderRadius: '15px',
		marginTop: '6rem',
		padding: '20px',
		marginBottom: '2rem',
	},
	secondGrid: {
		backgroundColor: 'black',
		borderRadius: '15px',
		marginTop: '2rem',
		padding: '20px',
	},
	thirdGrid: {
		backgroundColor: 'black',
		borderRadius: '15px',
		marginTop: '2rem',
		padding: '20px',
	},
	borderdGrid: {
		backgroundColor: 'rgba(9, 215, 133, 0.2)',
		border: '2px solid #09D785',
		borderRadius: '8px',
		justifyContent: 'center',
		width: '80% !important',
		height: '50% !important',
		padding: '8px',
	},
	gridForm: {
		width: 567,
		position: 'fixed',
		top: '15rem',
		zIndex: 10,
		display: 'flex',
		flexDirection: 'column !important',
		alignItems: 'center !important',
		[theme.breakpoints.down(1300)]: {
			width: 467,
			right: 30,
			bottom: 10,
		},
		[theme.breakpoints.down('lg')]: {
			right: 50,
		},
		[theme.breakpoints.down(1010)]: {
			right: 20,
		},
		[theme.breakpoints.down('md')]: {
			position: 'unset',
			right: 'unset',
			top: 'unset',
			zIndex: 0,
		},
	},
	gridTextContainer: {
		paddingBottom: '1em',
		[theme.breakpoints.down('lg')]: {
			paddingRight: '2em',
		},
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
	},
	gridTitle: {
		backgroundColor: '#000000',
		padding: '0px 25px',
		borderTopRightRadius: 20,
		borderTopLeftRadius: 20,
		[theme.breakpoints.down('sm')]: {
			padding: '0px 15px',
		},
	},
	impactText: {
		margin: '0 0 2rem !important',
		fontSize: '2.2rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.45rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1rem !important',
		},
	},
	logo: {
		marginTop: '3rem',
		[theme.breakpoints.down('xl')]: {
			marginTop: '8rem',
		},
	},
	divider: {
		position: 'absolute',
		border: '1.5px solid #fff !important',
		left: '4.85em',
		alignContent: 'center',
		justifyContent: 'center',
		backgroundColor: '#fff',
		height: '230px !important',
		zIndex: -1,
		[theme.breakpoints.down('xl')]: {
			height: '200px !important',
		},
		[theme.breakpoints.down('lg')]: {
			height: '280px !important',
		},
		[theme.breakpoints.down('md')]: {
			height: '295px !important',
		},
		[theme.breakpoints.down('sm')]: {
			height: '240px !important',
		},
	},
	imgTime: {
		height: 30,
		top: 5,
	},
	timelineText: {
		fontSize: '1.5rem !important',
		color: 'white',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.2rem !important',
		},
	},
	impactText2: {
		margin: '1.5rem 0 0 !important',
		fontSize: '1.75rem !important',
		lineHeight: 'unset !important',
		fontWeight: 'bold !important',

		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.45rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1rem !important',
		},
	},
}));
