import React from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import { Header } from '../../../../Components/Header';
import ProductCardV2 from '../../../../Components/Cards/ProductCardV2';
import DefaultButton from '../../../../Components/Buttons/DefaultButton';
import { Link } from 'react-router-dom';
// import BgExtensivoR1 from '../../../../Assets/Extensivo/R1/2023/bg-product-card.png';

export default function CampinaGrande2023() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
			</Container>
			<Container
				maxWidth={false}
				disableGutters={true}
				style={{
					backgroundImage: `url(${require('./Assets/bg-emergency-courses.png')})`,
					backgroundSize: 'cover',
					padding: '1rem 0',
				}}
			>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					<Grid
						container
						alignContent="center"
						className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
						style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
						rowGap={3}
					>
						{matchesLg ? (
							<Grid
								item
								xs={12}
								lg={6}
								container
								justifyContent={'space-between'}
								alignContent={'flex-start'}
							>
								<img
									src={require('./Assets/logo-emergency-course.svg').default}
									alt=""
									style={{ width: '40%' }}
								/>
								<img
									src={require('../../../../Assets/Svg/HardworkLogo.svg').default}
									alt=""
									style={{ width: '55%' }}
								/>
							</Grid>
						) : null}
						<Grid
							item
							xs={12}
						>
							<Typography
								className={`${classes.title} roboto-condensed`}
								color="secondary"
								style={{ marginBottom: '1rem' }}
							>
								Um presente especial para o Curso de Emergências de Campina Grande: desconto exclusivo
								de R$1.000,00.
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							lg={6}
							container
							gap={1}
							className="residencia"
							style={matchesTablet ? null : { paddingRight: '2rem' }}
						>
							<Typography
								align="left"
								className={`${classes.subTitle} gradient-text title-card-home`}
							>
								Conheça a revolução
							</Typography>
							<Typography
								align="left"
								color="secondary"
								className={`${classes.text} poppins`}
							>
								{/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ut purus sed quam
								consequat sagittis. Sed dapibus purus et massa blandit, eget mattis neque ultricies.
								Nulla in nibh justo.
								<br />
								<br />
								Aliquam porttitor tempor lectus a viverra. Donec rhoncus hendrerit lorem, vitae viverra
								nisi semper sed. Donec eu suscipit est, a euismod felis. */}
								Nós estamos revolucionando a preparação para as provas de residência. O Hardwork tem a
								melhor didática e foi o pioneiro na metodologia do estudo por questões.
								<br />
								<br />
								E ainda tem mais: a inscrição no Extensivo R1 garante acesso a todos os Sprints Finais
								(revisões específicas para cada prova) do Hardwork.
								<br />
								<br />
								{/* *(<strong>Veja os detalhes na página de inscrição</strong>) */}
							</Typography>
							<Grid
								item
								xs={12}
								container
								// justifyContent="center"
								className={`${classes.gridButton} residencia`}
							>
								<DefaultButton
									variant="outlined"
									color={'secondary'}
									className={`${classes.btnFirstSection} 'grid-btn-subscribe btn btn-secondary poppins`}
									component={Link}
									to={'/extensivo-r1'}
								>
									Veja os detalhes na página de inscrição
								</DefaultButton>
							</Grid>
						</Grid>
						{matchesLg ? null : (
							<Grid
								item
								xs={12}
								lg={6}
								container
								alignContent={'flex-start'}
								gap={3}
								style={{ padding: '2.5rem 0 0 1.5rem' }}
							>
								<img
									src={require('./Assets/logo-emergency-course.svg').default}
									alt=""
								/>
								<img
									src={require('../../../../Assets/Svg/HardworkLogo.svg').default}
									alt=""
									style={{ flex: 1 }}
								/>
							</Grid>
						)}
						<Grid
							item
							xs={12}
							container
							className="residencia"
							gap={1}
							style={{ marginTop: '2rem' }}
						>
							<Typography
								align="left"
								className={`${classes.subTitle} gradient-text title-card-home`}
							>
								Extensivo R1 com desconto exclusivo
							</Typography>
							<Typography
								align="left"
								color="secondary"
								className={`${classes.text} poppins`}
							>
								Se tu fores fazer prova em 2023, ou mesmo em 2024, o Extensivo Hardwork vai contigo! Tu
								tens um desconto especial de R$1.000,00 para inscrição (válido até 07/05/2023).
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							lg={6}
							style={matchesLg ? null : { paddingRight: '1rem' }}
						>
							<ProductCardV2
								img={require('../../../../Assets/Extensivo/R1/2023/bg-product-card.png')}
								logo={require('../../../../Assets/Extensivo/R1/2023/logo-extensivoR1-v2.svg').default}
								buttonText={'Saiba mais'}
								url={'/extensivo-r1'}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							lg={6}
							style={matchesLg ? null : { paddingLeft: '1rem' }}
						>
							<ProductCardV2
								img={require('../../../../Assets/Extensivo/R1/2023-24/banner-extensivo-2-anos.png')}
								logo={
									require('../../../../Assets/Extensivo/R1/2023-24/logo-extensivo2324-v2.svg').default
								}
								buttonText={'Saiba mais'}
								url={'/extensivo-r1-2-anos'}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							container
						>
							<Typography
								align="left"
								color="secondary"
								className={`${classes.text} poppins`}
								style={{ fontStyle: 'italic' }}
							>
								*O cupom de desconto deve ser informado durante o checkout
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							container
							className="residencia"
							gap={1}
							style={{ marginTop: '2rem' }}
						>
							<Typography
								align="left"
								className={`${classes.subTitle} gradient-text title-card-home`}
							>
								Demonstração do Extensivo Hardwork
							</Typography>
							<Typography
								align="left"
								color="secondary"
								className={`${classes.text} poppins`}
							>
								Quer conhecer um pouco do Extensivo Hardwork? Teste grátis a demonstração clicando
								abaixo.
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							lg={6}
						>
							<ProductCardV2
								img={require('../../../../Assets/Extensivo/R1/2023/bg-free-trial-product.png')}
								logo={
									require('../../../../Assets/Extensivo/R1/2023/logo-free-trial-extensivo-r1.svg')
										.default
								}
								buttonText={'Teste grátis'}
								url={'//hardworkmedicina.com.br/dashboard?demo-extensivo-2023'}
							/>
						</Grid>
					</Grid>
				</Container>
			</Container>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.75rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.18rem !important',
			marginBottom: '8px !important',
		},
	},
	subTitle: {
		fontWeight: 'bold !important',
		fontSize: '3rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
			lineHeight: 'unset !important',
			marginBottom: '8px !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
			lineHeight: 'unset !important',
		},
	},
	text: {
		fontSize: '1.324rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	bottomSignInCardText: {
		fontSize: '1.15rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
	},
	divider: {
		margin: 'unset !important',
		backgroundColor: 'grey',
		minHeight: 1,
	},
	workHourTitle: {
		fontSize: '1.7em !important',
		lineHeight: 1.25,
		fontWeight: 'bold !important',
	},
	containerWorkHours: {
		padding: '2em 0 2em 2em',
		[theme.breakpoints.down('lg')]: {
			padding: 0,
		},
	},
	workHourPaper: {
		height: '100%',
		[theme.breakpoints.down('lg')]: {
			height: 'unset !important',
		},
	},
	img: {
		marginTop: '1.5em',
		width: '100%',
	},
	btnFirstSection: {
		width: '85%',
		fontSize: '1.25rem !important',
		fontWeight: 'bold !important',
		'&:hover': {
			scale: 1.05,
			color: '#ffffff',
			'& $logoBtn': {
				filter: 'invert(0%) !important' /* Linha para passar estilo do pai para o filho */,
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
			minHeight: 'unset !important',
			width: '80%',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
			width: '100%',
		},
	},
}));
