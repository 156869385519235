import ProductsTemplate from '../../../Pages/LandingPages/Subscription/Residencia/Products-Template-2024';
import ProductsTemplateBlack from '../../../Pages/LandingPages/Subscription/Residencia/Products-Template-2024-Black';
// import ProductsTemplateCidade from '../../../Pages/LandingPages/Subscription/Residencia/Product-Template-CIdade';

import HandsOn from '../TemplatesContent/Hands-on';
// import HandsOnCity from '../TemplatesContent/Hands-on-city';
import TrilhaR12025 from '../TemplatesContent/Trilha-r1-2025';
import LiveClinicaMedicaUsp from '../TemplatesContent/Live-CM-USP';

// import JN from '../../../Pages/LandingPages/Subscription/Residencia/assets/logo-juazeiro-norte.png';
// import JU from '../../../Pages/LandingPages/Subscription/Residencia/assets/logo-juazeiro.png';
// import AL from '../../../Pages/LandingPages/Subscription/Residencia/assets/logo-alagoinhas.png';
// import TE from '../../../Pages/LandingPages/Subscription/Residencia/assets/logo-teresina.png';

import SprintFinalR3CM from '../TemplatesContent/Sprint-final-r3-cm';
import LiveRmaisCg from '../TemplatesContent/Live-rmais-cg';
import InscricaoMentoria2024 from '../../../Pages/LandingPages/Subscription/Residencia/Inscricao-Mentoria-2024';
// import PreInscricaoArena20242 from '../../../Pages/LandingPages/Subscription/Arena/Pre-inscricao-area-2024-2';
import PreInscricaoArena from '../../../Pages/LandingPages/Subscription/Arena/Pre-inscricao-arena';
import SprintFinalRevalida from '../TemplatesContent/SprintFinalRevalida';

// Arquivo que configura as páginas de pré inscrição

const routesProductsTemplate = [
	{
		path: '/pre-inscricao/hardwork-hands-on', // Cria a url
		component: ProductsTemplate, // Usa o componente template como base
		props: {
			group: 'Hands On', // Define o agrupamento do formulário
			children: <HandsOn />, // Aqui passamos a parte dinâmica de cada página, ou seja, o conteúdo à esquerda
			formLogo: require('../../../Pages/LandingPages/Subscription/Residencia/assets/logo-hands-on.png'), // Logo acima do formulário
			backgroundImg: require('../../../Pages/LandingPages/Subscription/Residencia/assets/pocus-ultrassom-hands-on.png'), // Background
		},
	},

	{
		path: '/pre-inscricao/sprint-revalida-2025-1', // Cria a url
		component: ProductsTemplate, // Usa o componente template como base
		props: {
			group: 'pre-inscrição sprint-revalida-2025.1', // Define o agrupamento do formulário
			children: <SprintFinalRevalida themeColor={'dark'} />, // Aqui passamos a parte dinâmica de cada página, ou seja, o conteúdo à esquerda
			backgroundImg: require('../../../Pages/LandingPages/Subscription/Revalida/assets/sprint-final-revalida.png'), // Background
			themeColor: 'dark',
			title: 'Faça sua pré-inscrição!',
		},
	},
	// {
	// 	path: '/pre-inscricao/trilha-2025-r1', // Cria a url
	// 	component: ProductsTemplate, // Usa o componente template como base
	// 	props: {
	// 		group: 'Trilha R1 2025 - Pre inscrição', // Define o agrupamento do formulário
	// 		children: <TrilhaR12025 themeColor={'dark'} />, // Aqui passamos a parte dinâmica de cada página, ou seja, o conteúdo à esquerda
	// 		// formLogo: require('../../../Pages/LandingPages/Subscription/Residencia/assets/logo-hands-on.png'), // Logo acima do formulário
	// 		backgroundImg: require('../../../Pages/LandingPages/Subscription/Residencia/assets/pocus-ultrassom-hands-on.png'), // Background
	// 		themeColor: 'dark',
	// 	},
	// },
	// {
	// 	path: '/hardwork-juazeiro-do-norte',
	// 	component: ProductsTemplateCidade,
	// 	props: {
	// 		group: 'EVENTO-JUAZEIRO-DO-NORTE',
	// 		children: (
	// 			<HandsOnCity
	// 				logo={JN}
	// 				cidade={'Juazeiro do Norte'}
	// 			/>
	// 		),
	// 		formLogo: require('../../../Pages/LandingPages/Subscription/Residencia/assets/logo-juazeiro-norte.png'),
	// 		backgroundImg: require('../../../Pages/LandingPages/Subscription/Residencia/assets/new-bg-cidades.png'),
	// 	},
	// },
	// {
	// 	path: '/hardwork-juazeiro',
	// 	component: ProductsTemplateCidade,
	// 	props: {
	// 		group: 'EVENTO-JUAZEIRO',
	// 		children: (
	// 			<HandsOnCity
	// 				logo={JU}
	// 				cidade={'Juazeiro'}
	// 			/>
	// 		),
	// 		formLogo: require('../../../Pages/LandingPages/Subscription/Residencia/assets/logo-juazeiro.png'),
	// 		backgroundImg: require('../../../Pages/LandingPages/Subscription/Residencia/assets/new-bg-cidades.png'),
	// 	},
	// },
	// {
	// 	path: '/hardwork-alagoinhas',
	// 	component: ProductsTemplateCidade,
	// 	props: {
	// 		group: 'EVENTO-ALAGOINHAS',
	// 		children: (
	// 			<HandsOnCity
	// 				logo={AL}
	// 				cidade={'Alagoinhas'}
	// 			/>
	// 		),
	// 		formLogo: require('../../../Pages/LandingPages/Subscription/Residencia/assets/logo-alagoinhas.png'),
	// 		backgroundImg: require('../../../Pages/LandingPages/Subscription/Residencia/assets/new-bg-cidades.png'),
	// 	},
	// },
	// {
	// 	path: '/hardwork-teresina',
	// 	component: ProductsTemplateCidade,
	// 	props: {
	// 		group: 'EVENTO-TERESINA',
	// 		children: (
	// 			<HandsOnCity
	// 				logo={TE}
	// 				cidade={'Teresina'}
	// 			/>
	// 		),
	// 		formLogo: require('../../../Pages/LandingPages/Subscription/Residencia/assets/logo-teresina.png'),
	// 		backgroundImg: require('../../../Pages/LandingPages/Subscription/Residencia/assets/new-bg-cidades.png'),
	// 	},
	// },
	{
		path: '/pre-inscricao/sprint-r3-clinica-medica',
		component: ProductsTemplate,
		props: {
			group: 'Sprint R3 Clínica Médica 2024',
			children: <SprintFinalR3CM />,
			formLogo: require('../Assets/logo-sf-cm-2024.svg').default,
			backgroundImg: require('../Assets/bg-sprint-cm-2024.png'),
		},
	},
	{
		path: '/pre-inscricao/live-imagens-cirurgia',
		component: ProductsTemplate,
		props: {
			group: 'Live R+ Cirurgia Imagens',
			children: <LiveRmaisCg />,
			formLogo: require('../Assets/logo-rmais-cg.svg').default,
			backgroundImg: require('../Assets/bg-rmais-cg.png'),
		},
	},
	{
		path: '/pre-inscricao/mentoria-trilha-2025',
		component: ProductsTemplateBlack,
		props: {
			titleForm: 'Cadastre-se para conhecer os detalhes!',
			group: 'MENTORIA PRE-INSCRIÇÃO TRILHA 2025 - JUAZEIRO DO NORTE',
			children: <InscricaoMentoria2024 />,
			// formLogo: require('../Assets/logo-rmais-cg.svg').default,
			backgroundImg: require('../../../Pages/LandingPages/Assets/bg-inscricao-mentoria-2024.png'),
		},
	},
	{
		path: '/pre-inscricao/arena-hardwork-presencial-24-2',
		component: ProductsTemplateBlack,
		props: {
			titleForm: 'Cadastre-se gratuitamente!',
			group: 'Live pré-inscrição Arena Presencial 24.2',
			children: <PreInscricaoArena />,
			formLogo: require('../../../Pages/LandingPages/Subscription/Arena/assets/logo-arena-presencial.svg')
				.default,
			backgroundImg: require('../../../Pages/LandingPages/Subscription/Arena/assets/bg-area.png'),
			form: true,
		},
	},
	// Adicione mais rotas conforme necessário
];

export default routesProductsTemplate;
