import Logo25 from './assets/logo-trilha-25.svg';
import FabImg from './assets/fab-img.svg';

import { useMediaQuery, useTheme } from '@mui/material';
import Trilha25Survey from '../../../../Components/Utils/Forms/Trilha25Survey';

export default function VendasTrilha25() {
	const theme = useTheme();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesLaptop = useMediaQuery(theme.breakpoints.down('xl'));

	return (
		<main
			style={{
				backgroundImage: `url(${require('./assets/blur-background.png')})`,
				backgroundSize: 'cover',
				height: '10000px',
				padding: matchesMobile ? '2rem 1rem' : '4rem',
				marginTop: matchesMobile && '3rem',
				display: 'flex',
				justifyContent: 'center',
				position: 'relative',
			}}
		>
			<div
				style={{
					position: 'fixed',
					top: matchesMobile ? 85 : 16,
					display: 'flex',
					flexDirection: 'column',
					height: matchesMobile ? '85vh' : '95vh',
					width: matchesMobile ? '90%' : matchesLaptop ? '90%' : '70%',
					backgroundColor: '#101010',
					borderRadius: '1rem',
					padding: matchesMobile ? '1rem' : '32px',
					overflowY: 'scroll',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: matchesMobile ? 'column' : 'row',
					}}
				>
					<div style={{ width: matchesMobile ? '100%' : '55%' }}>
						<div style={{ display: 'flex', justifyContent: 'start', marginTop: matchesMobile && '2rem' }}>
							<img
								src={Logo25}
								alt="Logo Trilha 25"
								width={matchesMobile ? '110%' : '429px'}
							/>
						</div>
						<div
							style={{
								fontFamily: 'Manrope',
								display: 'flex',
								flexDirection: 'column',
								gap: '2rem',
								margin: '1.5rem 1rem',
							}}
						>
							<p
								style={{
									fontWeight: '600',
									color: '#FCFCFC',
									fontSize: matchesMobile ? '14px' : '20px',
								}}
							>
								O método que te faz aprender, não esquecer e dominar qualquer questão de prova de
								residência.
							</p>
							<p
								style={{
									fontWeight: '400',
									color: '#FCFCFC',
									fontSize: matchesMobile ? '16px' : '24px',
								}}
							>
								Sorteios de bolsas integrais do Trilha 25 +
								<span
									className="gradient-text-enare-2"
									style={{ fontWeight: '700', flexWrap: 'nowrap' }}
								>
									{' '}
									Mentoria Individual Exclusiva com Professor Fabrício Valois
								</span>
							</p>
							<p
								style={{
									fontWeight: '700',
									color: '#FCFCFC',
									fontSize: matchesMobile ? '16px' : '20px',
								}}
							>
								25/11 • LIVE de lançamento e abertura de inscrições
							</p>
						</div>
					</div>
					<div
						style={{
							width: matchesMobile ? '100%' : '40%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'flex-end',
						}}
					>
						<img
							src={FabImg}
							alt=""
						/>
					</div>
				</div>
				{/*<div style={{ backgroundColor: '#303030', height: '400px', width: '100%', borderRadius: '1rem' }}></div>*/}
				<div style={{ width: '100%', height: '100%' }}>
					<Trilha25Survey />
				</div>
			</div>
		</main>
	);
}
