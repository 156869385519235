import React from 'react';
import { Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import DefaultButton from '../Buttons/DefaultButton';

export default function FirstSectionHWSolidario({
	backgroundImageUrl,
	logoUrl,
	buttonText,
	executeScroll,
	firstTextPaper,
	type,
	buttonProps,
	imageProps,
}) {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesMobileUp = useMediaQuery(theme.breakpoints.up('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));
	const matchesXl = useMediaQuery(theme.breakpoints.down('xl'));

	return (
		<Grid
			container
			alignContent="center"
			justifyContent="center"
			className={classes.firstSection}
			style={
				matchesMobile
					? {
							marginTop: 60,
							backgroundImage: `url(${backgroundImageUrl})`,
							backgroundSize: 'cover',
							backgroundRepeat: 'no-repeat',
							// height: '100vh'
					  }
					: matchesTablet
					? {
							marginTop: '3em',
							backgroundImage: `url(${backgroundImageUrl})`,
							backgroundSize: 'cover',
							backgroundRepeat: 'no-repeat',
					  }
					: {
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'cover',
							backgroundImage: `url(${backgroundImageUrl})`,
					  }
			}
		>
			<Grid
				item
				xs={12}
				container
				alignContent="center"
				rowGap={matchesTablet ? 2 : 0}
				style={{
					maxWidth: '1410px',
					height: '100%',
					marginTop: matchesTablet ? '0rem' : matchesLg ? '40rem' : matchesXl ? '10rem' : '20rem',
				}}
			>
				<Grid
					item
					xs={12}
					md={6}
					container
					direction={'column'}
					justifyContent={'center'}
					alignContent={'center'}
					rowGap={3}
					style={{ height: 'fit-content' }}
				>
					<img
						src={logoUrl}
						alt=""
						className={classes.logo}
					/>
					{buttonText ? (
						<Grid
							item
							xs={8}
							sm={6}
							container
							justifyContent="center"
							className={`${type === 'red' ? 'residencia' : type === 'green' ? 'revalida' : null}`}
						>
							<DefaultButton
								variant="outlined"
								color={buttonProps ? 'primary' : 'secondary'}
								className={`${classes.btnFirstSection} ${
									buttonProps ? buttonProps.type : 'grid-btn-subscribe btn'
								} ${buttonProps ? 'white-btn-secondary' : 'btn-secondary'} poppins`}
								onClick={() => executeScroll()}
							>
								{buttonProps?.buttonLogoUrl ? (
									<img
										src={buttonProps.buttonLogoUrl}
										alt=""
										className={classes.logoBtn}
									/>
								) : null}
								&nbsp;{buttonText}
							</DefaultButton>
						</Grid>
					) : null}
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
					container
					justifyContent={window.location.pathname === '/migracao-trilha-revalida' ? 'center' : 'flex-start'}
					alignContent="center"
					className={classes.firstSectionCard}
				>
					{window.location.pathname === '/migracao-trilha-revalida' ? (
						<iframe
							// width="560"
							width="940"
							// height="315"
							height={matchesMobile ? '500' : matchesLg ? '400' : '529'}
							src="https://player.vimeo.com/video/857680008"
							title="Vimeo video player"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowfullscreen
							style={{ borderRadius: '20px', width: 'fit-content', border: '2px solid #5f5858' }}
						></iframe>
					) : (
						<Grid
							item
							xs={12}
							container
							// justifyContent={matchesTablet ? 'center' : 'flex-end'}
							justifyContent={'center'}
							// style={{}}
						>
							<Paper
								color="primary"
								className={`${classes.firstSectionPaper} box box-primary`}
								elevation={4}
							>
								<Grid
									item
									xs={12}
									className={classes.gridItem}
									container
									justifyContent="flex-start"
									alignContent="center"
								>
									{firstTextPaper}
								</Grid>
							</Paper>
						</Grid>
					)}
				</Grid>
			</Grid>
			<Grid
				item
				xs={12}
				position={'absolute'}
				top={!matchesMobile ? '95%' : '90%'}
			>
				<Typography
					color={'secondary'}
					style={{ padding: '0 15px 0 15px', fontSize: '0.9rem' }}
				>
					*Volume total de água doada. Este volume é composto de diferentes tipos de embalagens devido a
					restrições na compra.
				</Typography>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	firstSection: {
		height: 764,
		margin: '20px auto',

		[theme.breakpoints.down('sm')]: {
			padding: '2vh 5% !important',
			height: 'unset',
			margin: '80px auto 0',
		},
	},
	text: {
		color: 'white',
		fontSize: '1.75rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.35rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	focusText: {
		color: 'white',
		fontSize: '4rem !important',
		lineHeight: '1.25 !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	btnFirstSection: {
		width: 'fit-content',
		padding: '0.25rem 4rem !important',
		fontSize: '1.425rem !important',
		fontWeight: 'bold !important',
		'&:hover': {
			scale: 1.05,
			color: '#ffffff',
			'& $logoBtn': {
				filter: 'invert(0%) !important' /* Linha para passar estilo do pai para o filho */,
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
			minHeight: 'unset !important',
			width: '80%',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0.25rem 2rem !important',
			fontSize: '0.9rem !important',
		},
	},
	firstSectionCard: {
		height: '100%',
		position: 'absolute',
		right: '0',
		[theme.breakpoints.down('xl')]: {
			right: '1.5rem',
		},
		[theme.breakpoints.down('md')]: {
			position: 'unset !important',
			right: 'unset !important',
			height: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'unset !important',
			top: 'unset !important',
			margin: window.location.pathname === '/migracao-trilha-revalida' ? '1.5rem 0 !important' : 'unset',
		},
	},
	firstSectionPaper: {
		color: 'unset !important',
		width: '100%',
		borderRadius: '30px !important',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.up('xl')]: {
			top: '-10.75em',
		},
		[theme.breakpoints.down('xl')]: {
			// padding: '1rem !important',
			top: '-5em',
		},
		[theme.breakpoints.down('lg')]: {
			top: '-18em',
			height: '100%',
		},
		[theme.breakpoints.down('md')]: {
			top: '-7em',
			height: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '1rem !important',
			height: '100%',
		},
	},
	gridItem: {
		padding: '.5em 0',
	},
	firstText: {
		fontSize: '3rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
		},
	},
	logo: {
		// position: 'absolute',
		zIndex: 1,
		width: '80% !important',
		alignSelf: 'center',
		marginTop: '6rem',
		// top: window.location.pathname === '/migracao-trilha-revalida' ? '4.75em' : '12.75em',
		// left: window.location.pathname === '/sprint-final' ? '2em' : '6em',
		// maxWidth: 498,
		// cursor: 'pointer',
		[theme.breakpoints.up('xl')]: {
			top: '-10.75em',
			width: '100% !important',
		},
		[theme.breakpoints.down('xl')]: {
			top: '-4.75em',
			// width: '80% !important',
		},
		[theme.breakpoints.down('lg')]: {
			top: '-20em',
			width: '85% !important',
		},
		[theme.breakpoints.down('md')]: {
			top: '-3em',
			width: '45% !important',
		},
		[theme.breakpoints.down('sm')]: {
			top: '-6em',
			width: '100% !important',
			// top: '2.75em',
			// left: '.5em',
		},
	},
	logoBtn: {
		filter: 'invert(100%)',
		height: window.location.pathname === '/migracao-trilha-revalida' ? '1.75rem' : null,
		marginRight: window.location.pathname === '/migracao-trilha-revalida' ? '0.5rem' : null,
		[theme.breakpoints.down('sm')]: {
			width: 25,
		},
		// '&:hover': {
		// 	filter: 'invert(0%) !important',
		// },
	},
}));
