import React from 'react';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

export default function ProductCard({ imgUrl, description, classes, color }) {
	const theme = useTheme();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Grid
			item
			xs={12}
			lg={6}
			container
			justifyContent={'space-around'}
			flexWrap={'nowrap'}
			gap={2}
			style={{
				padding: window.location.pathname === '/sprint-final' && matchesMobile ? '1rem 2rem' : '0.5rem 2rem',
				maxWidth: 450,
				height: window.location.pathname === '/sprint-final' ? null : 150,
				borderRadius: 15,
				backgroundColor: color ? color : '#ffffff',
			}}
			// className="background-revalida"
		>
			<Grid
				item
				container
				justifyContent={'center'}
				alignContent={'center'}
				style={{ flex: 1 }}
			>
				<img
					src={imgUrl}
					alt=""
					style={{
						height: window.location.pathname === '/sprint-final' && matchesMobile ? 90 : null,
					}}
				/>
			</Grid>
			<Grid
				item
				container
				alignContent={'center'}
				style={{ flex: 2 }}
			>
				<Typography
					color="primary"
					className={`poppins`}
				>
					{description}
				</Typography>
			</Grid>
		</Grid>
	);
}
