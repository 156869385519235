import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default function InscricaoMentoria2024() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Grid
			container
			rowGap={0}
		>
			<Grid
				item
				xs={12}
				container
				rowGap={0}
			>
				<img
					src={require('../../Subscription/Residencia/assets/img-fabricio-logo.png')}
					alt=""
				/>
			</Grid>

			<Grid
				item
				xs={12}
				container
				alignItems={'center'}
				columnGap={matchesTablet ? 1 : 1.5}
				className="residencia"
				justifyContent={'center'}
				borderRadius={5}
				padding={2}
			>
				<Grid
					item
					xs={12}
					container
					alignItems={'center'}
					columnGap={matchesTablet ? 1 : 1.5}
					className="residencia"
				>
					<Typography
						align="left"
						color="secondary"
						lineHeight={0.95}
						className={`${classes.title} `}
						fontFamily={'Manrope'}
						whiteSpace={matchesTablet ? 'normal' : 'pre-wrap'}
					>
						{`Estudo por questões + Sprint Final`}
					</Typography>
				</Grid>
				<Grid xs={12}>
					<Typography
						align="left"
						color="secondary"
						className={`${classes.text}`}
						fontFamily={'Manrope'}
						marginTop={3}
						whiteSpace={matchesTablet ? 'normal' : 'pre-wrap'}
					>{`EXCLUSIVO para alunos presentes no evento Foco Enare Juazeiro do Norte.`}</Typography>
				</Grid>
				<Grid xs={12}>
					<Typography
						align="left"
						color="secondary"
						className={`${classes.text}`}
						fontFamily={'Manrope'}
						marginTop={3}
					>
						• Desconto EXCLUSIVO para o pré-lançamento
					</Typography>
				</Grid>
				<Grid xs={12}>
					<Typography
						align="left"
						color="secondary"
						className={`${classes.text}`}
						fontFamily={'Manrope'}
						marginTop={3}
						whiteSpace={matchesTablet ? 'normal' : 'pre-wrap'}
					>
						{`• Mentoria EXCLUSIVA com professor Fabrício Valois durante toda a jornada de preparação `}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: '800 !important',
		fontSize: '40px !important',
		fontFamily: 'Manrope !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '32px !important',
		},
	},
	subtitle: {
		fontSize: '1.25rem !important',
		lineHeight: '1.25 !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	text: {
		fontWeight: '300 !important',
		fontSize: '20px !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '16px !important',
		},
	},
	impactText: {
		fontSize: '32px !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '28px !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '24px !important',
		},
	},
}));
