const urlBase = 'https://hardworkmedicina.com.br/api/';

let headersParams = new Headers({
	Accept: 'application/json',
	'Content-Type': 'application/json',
	Authorization: 'Bearer MkhHdzQxMXRTV2pOJXdvdFAzUzU5QHp0',
});

export default class Api {
	static async get(endpoint) {
		return fetch(urlBase + endpoint.toLowerCase(), {
			method: 'GET',
			headers: headersParams,
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.ok) {
					return res.obj;
				} else {
					alert(res.msg);
					//throw Error(res.msg || res.Message || res);
				}
			})
			.catch((err) => {
				console.log('err', err);
				//throw Error(err.message || err);
			});
	}

	static async post(endpoint, payload) {
		return fetch(urlBase + endpoint.toLowerCase(), {
			method: 'POST',
			headers: headersParams,
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.ok) {
					return res.obj;
				} else {
					alert(res.msg);
					//throw Error(res.msg || res.Message || res);
				}
			})
			.catch((err) => {
				console.log('err', err);
				//throw Error(err.message || err);
			});
	}

	static async put(endpoint, payload) {
		return fetch(urlBase + endpoint.toLowerCase(), {
			method: 'PUT',
			headers: headersParams,
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.ok) {
					return res.obj;
				} else {
					alert(res.msg);
					//throw Error(res.msg || res.Message || res);
				}
			})
			.catch((err) => {
				console.log('err', err);
				//throw Error(err.message || err);
			});
	}

	static async delete(endpoint) {
		return fetch(urlBase + endpoint.toLowerCase(), {
			method: 'DELETE',
			headers: headersParams,
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.ok) {
					return res.obj;
				} else {
					alert(res.msg);
					//throw Error(res.msg || res.Message || res);
				}
			})
			.catch((err) => {
				console.log('err', err);
				//throw Error(err.message || err);
			});
	}
}
