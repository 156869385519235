import React, { useRef, useState, useEffect } from 'react';
import {
	Autocomplete,
	Checkbox,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	TextField,
	Typography,
} from '@mui/material';
import Api from '../../../Services/Api';
import DefaultButton from '../../Buttons/DefaultButton';
import { makeStyles } from '@mui/styles';
import { Link, useSearchParams } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

export default function Form({
	closeGroup,
	group,
	title,
	titleButton,
	type,
	urlToRedirect,
	confirmationMessage,
	testDateField,
	selectField,
	titleConfirmationMessage,
	noCellphone,
	exam,
	mail,
	hasAdvice,
	hasName,
	college,
}) {
	const classes = useStyles();
	const [searchParams] = useSearchParams();
	const [testDateSelected, setTestDateSelected] = useState('');

	const sataCruz = window.location.pathname.includes('santa-cruz-de-la-sierra');

	const [preInscricao, setPreInscricao] = useState({
		agrupamento: group,
		nome: '',
		email: '',
		telefone: '',
		rg: '',
		provas: '',
		provas_outras: '',
		optin: false,
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
	});
	const testDateRef = useRef();
	const [graduationYears] = useState([
		{
			year: 2020,
		},
		{
			year: 2021,
		},
		{
			year: 2022,
		},
		{
			year: 2023,
		},
		{
			year: 2024,
		},
		{
			year: 2025,
		},
		{
			year: 2026,
		},
		{
			year: 2027,
		},
		{
			year: 2028,
		},
		{
			year: 2029,
		},
		{
			year: 2030,
		},
		{
			year: 'outro',
		},
	]);
	const [typeSubmit, setTypeSubmit] = useState(false);

	useEffect(() => {
		setPreInscricao({
			...preInscricao,
			utm_source: searchParams.get('utm_source'),
			utm_medium: searchParams.get('utm_medium'),
			utm_campaign: searchParams.get('utm_campaign'),
		});
	}, []);

	useEffect(() => {
		if (exam) {
			setPreInscricao({
				...preInscricao,
				provas: exam,
			});
		}
	}, [exam]);

	useEffect(() => {
		if (mail) {
			setPreInscricao({
				...preInscricao,
				email: mail,
			});
		}
	}, [mail]);

	useEffect(() => {
		setPreInscricao((prevState) => ({
			...prevState,
			agrupamento: closeGroup ? `${group} LISTA DE ESPERA` : group,
		}));
	}, [closeGroup, group]);

	let sendPreInscricao = function (event) {
		event.preventDefault();
		const nomeCompleto = preInscricao.nome.trim();
		const nomeSobrenome = nomeCompleto.split(' ');

		if ((!preInscricao.nome && hasName) || (nomeSobrenome.length < 2 && hasName)) {
			alert('Ops... Por favor informe seu nome e sobrenome');
			return;
		}
		if (!preInscricao.email || !preInscricao.email.includes('@') || !preInscricao.email.includes('.')) {
			alert('Ops... Por favor preencha seu email');
			return;
		}
		if (!preInscricao.telefone && !noCellphone) {
			alert('Ops... Por favor preencha seu telefone com um número válido');
			return;
		}
		if (!preInscricao.optin && hasAdvice) {
			alert('Ops... Você precisa ler e aceitar os termos de uso acima para se pré-inscrever');
			return;
		}

		setTypeSubmit(true);

		trackPromise(
			Api.post('PreInscricao', preInscricao).then((res) => {
				if (res) {
					if (!hasName || window.location.pathname.includes('ponta-pora')) {
						Swal.fire({
							title: `${titleConfirmationMessage ? titleConfirmationMessage : 'Massa!'} `,
							text: `${
								confirmationMessage
									? confirmationMessage
									: 'Sua pré-inscrição está confirmada! Avisaremos assim que a nova turma estiver liberada.'
							}`,
							icon: 'success',
							confirmButtonText: 'Fechar',
							allowOutsideClick: false,
							allowEscapeKey: false,
						}).then((result) => {
							if (result.isConfirmed) {
								if (urlToRedirect) {
									document.location.href = `${urlToRedirect}-${preInscricao?.email}`;
								} else {
									document.location.href = '/';
								}
							}
						});
					} else {
						if (urlToRedirect) {
							document.location.href = `${urlToRedirect}-${preInscricao?.email}`;
						} else {
							document.location.href = '/';
						}
					}
				}
			})
		);
	};

	const handlePhone = (valid, phoneNumber) => {
		if (valid) {
			setPreInscricao({
				...preInscricao,
				telefone: phoneNumber,
			});
		} else if (preInscricao.telefone.length) {
			setPreInscricao({
				...preInscricao,
				telefone: '',
			});
		}
	};

	const handleChangeTestYear = (event) => {
		var year = event.target.value;
		if (year === null) {
			setTestDateSelected(null);
			return;
		}
		setTestDateSelected(year);
		setPreInscricao({
			...preInscricao,
			provas_outras: year,
		});
	};

	const institutions = [
		'Universidad Autónoma San Sebastián de San Lorenzo',
		"Universidad Católica 'Nuestra Señora de la Asunción'",
		'Universidad Católica Sede Regional del Guairá',
		'Universidad Central del Paraguay',
		'Universidad de la Integración de las Américas',
		'Universidad del Norte',
		'Universidad del Pacífico',
		"Universidad Internacional 'Tres Fronteras'",
		'Universidad María Auxiliadora',
		'Universidad Nacional de Asunción',
		'Universidad Nacional de Caaguazú',
		'Universidad Nacional de Concepción',
		'Universidad Nacional del Este',
		'Universidad Politécnica y Artística',
		'Universidad Privada del Este',
		'Universidad Privada del Guairá',
		'Universidad Privada María Serrana',
		"Universidad Santa Clara de Asís 'USCA'",
		'Universidad Sudamericana',
		'Outra',
		'Não Informado',
	];

	const institutionsSantaCruz = [
		'Universidad Amazónica De Pando',
		'Universidad Autónoma "Gabriel René Moreno"',
		'Universidad Autónoma "Juan Misael Saracho"',
		'Universidad Autónoma "Tomás Friás"',
		'Universidad Autónoma Del Beni',
		'Universidad Autónoma Del Beni "José Ballivián"',
		'Universidad Católica Boliviana "San Pablo"',
		'Universidad Central Unicen',
		'Universidad Cristiana De Bolivia',
		'Universidad De Aquino - Bolivia',
		'Universidad Evangélica Boliviana',
		'Universidad Latinoamericana',
		'Universidad Mayor De San Andrés',
		'Universidad Mayor De San Simón',
		'Universidad Mayor, Real Y Pontificia De San Francisco Xavier De Chuquisaca',
		'Universidad Nacional Del Oriente',
		'Universidad Nacional Ecológica',
		'Universidad Nuestra Señora De La Paz',
		'Universidad Privada Abierta Latinoamericana',
		'Universidad Privada Del Valle',
		'Outra',
		'Não Informado',
	];

	return (
		<Paper
			elevation={4}
			className={`${classes.paper} rounded-card ${`background-${type}`}`}
		>
			{}
			<Grid container>
				<form
					className="form-control"
					onSubmit={sendPreInscricao}
				>
					<Grid
						item
						xs={12}
						container
						rowGap={1}
					>
						<Grid
							item
							container
							justifyContent="center"
						>
							<Typography
								className={`${classes.signInCardTitle} poppins`}
								color="secondary"
								align="center"
							>
								{title}
							</Typography>
						</Grid>
						{hasName && (
							<Grid
								item
								xs={12}
							>
								<TextField
									fullWidth
									type="string"
									name="nome"
									placeholder="Seu nome completo"
									required={true}
									InputProps={{
										className: `${classes.form} ${classes.formHeight}`,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									onChange={(e) =>
										setPreInscricao({
											...preInscricao,
											nome: e.target.value,
										})
									}
								/>
							</Grid>
						)}
						{!mail && (
							<Grid
								item
								xs={12}
							>
								<TextField
									fullWidth
									type="email"
									name="email"
									placeholder={'Seu melhor e-mail'}
									required={true}
									InputProps={{
										className: `${classes.form} ${classes.formHeight}`,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									onChange={(e) =>
										setPreInscricao({
											...preInscricao,
											email: e.target.value,
										})
									}
								/>
							</Grid>
						)}
						{mail && (
							<Grid
								item
								xs={12}
							>
								<TextField
									fullWidth
									type="email"
									name="email"
									value={mail ? mail : ''}
									placeholder={'Seu melhor e-mail'}
									required={true}
									InputProps={{
										className: `${classes.form} ${classes.formHeight}`,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									onChange={(e) =>
										setPreInscricao({
											...preInscricao,
											email: e.target.value,
										})
									}
								/>
							</Grid>
						)}
						{noCellphone ? null : (
							<Grid
								item
								xs={12}
							>
								<IntlTelInput // Componente Input de telefones internacionais
									name="telefone"
									type="telefone"
									preferredCountries={['BR', 'PY', 'AR', 'CL', 'BO', 'UY', 'CU', 'PT']}
									style={{ width: '100%', border: 'unset' }}
									inputClassName={`${classes.phoneNumberForm} ${classes.formHeight}`}
									nationalMode={true}
									onPhoneNumberChange={(valid, value, selectedCountryData, fullNumber) =>
										handlePhone(valid, fullNumber)
									}
								/>
							</Grid>
						)}
						{college && (
							<Grid
								item
								xs={12}
							>
								<Autocomplete
									disablePortal
									id="combo-box-demo"
									options={sataCruz ? institutionsSantaCruz : institutions}
									onHighlightChange={(e, option) =>
										setPreInscricao({
											...preInscricao,
											provas: option,
										})
									}
									sx={{
										borderRadius: '8px !important',
										backgroundColor: '#ffffff',
										'& .MuiOutlinedInput-notchedOutline': {
											border: 'transparent !important',
										},
										'& .MuiOutlinedInput-input': {
											height: '0.75em',
										},
										'& .MuiFormLabel-root': {
											display: preInscricao?.provas === '' ? 'block' : 'none',
										},
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Sua faculdade"
										/>
									)}
								/>
							</Grid>
						)}
						{testDateField && (
							<Grid
								item
								xs={12}
							>
								<FormControl
									style={{ width: '100%', border: 'transparent' }}
									className={`${classes.form} ${classes.formHeight}`}
								>
									{testDateSelected ? null : (
										<InputLabel id="test-select-label">Ano de formação</InputLabel>
									)}
									<input
										type="hidden"
										name="data_prova"
										ref={testDateRef}
									/>
									<Select
										value={testDateSelected}
										labelId="test-select-label"
										onChange={handleChangeTestYear}
										className={classes.selectMenu}
									>
										{graduationYears.map((test) => {
											return (
												<MenuItem
													key={test.id}
													value={test.year}
													className="poppins"
												>
													<Typography
														color="primary"
														className={`${classes.textSelectItem} poppins`}
													>
														{test.year}
													</Typography>
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</Grid>
						)}
						{hasAdvice ? (
							<Grid
								item
								xs={12}
							>
								<Checkbox
									style={{ padding: '0px', color: 'white' }}
									checked={preInscricao.optin}
									onChange={(e) =>
										setPreInscricao({
											...preInscricao,
											optin: !preInscricao.optin,
										})
									}
								/>
								<Typography
									variant="caption"
									color="secondary"
									className={classes.captionText}
								>
									Estou de acordo com o tratamento dos meus dados pessoais para fins de inscrição na
									plataforma Hardwork Medicina, bem como para envio de comunicações relativas aos
									serviços educacionais que se adequem ao meu perfil, de acordo com a nossa{' '}
									<Link
										color="secondary"
										href="https://hardworkmedicina.com.br/politica-de-privacidade"
										target="_blank"
										underline="hover"
										variant="caption"
										align="right"
										className={`${classes.copyrightText} poppins`}
									>
										Politica de privacidade
									</Link>
								</Typography>
							</Grid>
						) : null}

						{!hasAdvice ? (
							<Grid>
								<Typography className={classes.advice}>
									*Lembrando que o sorteio valerá apenas para os presentes no evento.
								</Typography>
							</Grid>
						) : null}

						<Grid
							item
							xs={12}
							container
							justifyContent="center"
							className="residencia"
						>
							<DefaultButton
								type={typeSubmit ? 'submit' : 'button'}
								variant="outlined"
								color="secondary"
								className={`${classes.btn} btn`}
								onClick={(e) => sendPreInscricao(e)}
							>
								{titleButton}
							</DefaultButton>
						</Grid>
						{selectField && (
							<Typography
								color="secondary"
								className={classes.selectFieldText}
							>
								*Qual tema você gostaria de ver em uma aula Hardwork?
							</Typography>
						)}
					</Grid>
				</form>
			</Grid>
		</Paper>
	);
}

const useStyles = makeStyles((theme) => ({
	paper: {
		margin: '0 auto',
		padding: '1.5rem',
		[theme.breakpoints.up('lg')]: {},
		[theme.breakpoints.down('sm')]: {
			padding: '1rem',
		},
	},
	paperArena: {
		margin: '0 auto',
		padding: '1.5rem',
		[theme.breakpoints.down('lg')]: {
			width: '294px !important',
			top: '-68px !important',
			left: 38,
		},
		[theme.breakpoints.down('md')]: {
			width: 'unset !important',
			top: 'unset !important',
			left: 'unset !important',
		},
	},
	form: {
		borderRadius: '8px !important',
		backgroundColor: theme.palette.secondary.main,
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'transparent !important',
		},
		'& .MuiOutlinedInput-input': {
			height: '0.75em',
		},
	},
	formHeight: {
		maxHeight: '48px !important',
		[theme.breakpoints.down('xl')]: {
			maxHeight: '40.8px !important',
		},
		[theme.breakpoints.down('sm')]: {
			maxHeight: '45px',
		},
	},
	formHeightShort: {
		maxHeight: '2.5em',
	},
	advice: {
		fontSize: '0.9rem !important',
		color: 'white !important',
		fontWeight: 'bold !important',
	},
	phoneNumberForm: {
		height: '3em',
		borderRadius: 8,
		width: '100%',
		border: 'unset',
	},
	finishedTitle: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '3.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '3rem !important',
		},
	},
	signInCardTitle: {
		fontSize: '1.75rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	btn: {
		fontSize: '1.15rem !important',
		width: '75%',
		fontWeight: 'bold !important',
		textTransform: 'uppercase !important',
		backgroundColor: '#000000 !important',
		border: '1px solid transparent !important',
		'&:hover': {
			border: '1px solid #ffffff !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	copyrightText: {
		color: theme.palette.secondary.main,
	},
	captionText: {
		[theme.breakpoints.down('xl')]: {
			fontSize: '0.65rem !important',
		},
	},
	selectMenu: {
		width: '100%',
		borderRadius: '8px !important',
		backgroundColor: '#FFFFFF',
		maxHeight: '3em',
	},
	selectMenuUf: {
		width: '100%',
		borderRadius: '8px !important',
		backgroundColor: '#FFFFFF',
		maxHeight: '3em',
	},
	selectFieldText: {
		fontSize: '0.9rem !important',
		// marginTop: '1rem !important',
	},
	foodRequirementText: {
		fontSize: '1.1rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '0.9rem !important',
		},
	},
}));
