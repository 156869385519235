import React from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Helmet } from 'react-helmet';

// COMPONENTS
import Footer from '../Components/Footer';
import { Header } from '../Components/Header';
import SaibaMaisCard from '../Components/Cards/SaibaMaisCard';
import StudentsFeedbackSection from '../Components/Sections/StudentsFeedback';
// import { useEffect } from 'react';

// TO IMPLEMENT
// import Newsletter from '../Components/Utils/Newsletter';
// import DoctorCard from '../Components/Cards/DoctorCard';
// import BlogSection from '../Components/Sections/Blog';
// import TeachingMetodologySection from '../Components/Sections/TeachingMetodology';

export default function Revalida2023() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	// const startDate = new Date('2023-06-13T20:30:00');

	// const [openTrilhaRevalida, setOpenTrilhaRevalida] = useState(false);

	// useEffect(() => {
	// 	let currentDate = new Date();
	// 	if (currentDate >= startDate) {
	// 		setOpenTrilhaRevalida(true);
	// 	}
	// }, []);

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			<Helmet>
				<title>Revalida INEP - Hardwork Medicina</title>
				<meta
					name="description"
					content="Nada supera o Hardwork. Nós vamos contigo em todas as etapas do Revalida INEP."
				/>
			</Helmet>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				{/* <Grid
					container
					alignContent="center"
					justifyContent="center"
					className={matchesMobile ? classes.sectionMobile : classes.section}
					style={matchesMobile ? { marginTop: 80 } : null}
				>
					<Grid
						item
						xs={12}
						md={8}
						container
						justifyContent={matchesMobile || matchesTablet ? 'center' : 'flex-end'}
						alignContent="center"
					>
						<img
							src={SprintFinalRevalida}
							alt=""
							className={classes.sprintFinalRevalidaLogo}
						/>
						<img
							src={RevalidaImageBanner}
							alt=""
							className={classes.img}
						/>
						<Grid
							item
							xs={12}
							container
							className={`${classes.gridButton} grid-btn-subscribe revalida`}
						>
							<DefaultButton
								variant="outlined"
								color="secondary"
								className={`${classes.btn} btn btn-secondary`}
							>
								Inscreva-se agora!
							</DefaultButton>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						container
						justifyContent={matchesMobile || matchesTablet ? 'center' : 'flex-start'}
						alignContent="center"
					>
						<Paper
							color="primary"
							className={`${classes.firstSectionPaper} box box-primary`}
							elevation={4}
						>
							<Grid
								container
								justifyContent="center"
							>
								{matchesMobile || matchesTablet ? (
									<Typography
										align="left"
										color="secondary"
										className={`${classes.firstSectionCardText} revalida-texts poppins`}
									>
										A melhor forma de aprender é com estudo ativo e revisões personalizadas.
										<br />
										<br />
										Chega de nos limitarmos a assistir a aulas ou de
										<br />
										estudar pelo mesmo roteiro que todo mundo.
										<br />
										<br />
										O Sprint usa modelo ativo de aprendizado e tem
										<br />
										revisões cíclicas automáticas e personalizadas, que são preparadas pela
										plataforma de acordo com a<br />
										necessidade de cada um. Cada aluno tem o seu
										<br />
										próprio SPRINT!
									</Typography>
								) : (
									<Typography
										align="left"
										color="secondary"
										className={`${classes.firstSectionCardText} revalida-texts poppins`}
									>
										A melhor forma de aprender é<br />
										com estudo ativo e revisões
										<br />
										personalizadas.
										<br />
										<br />
										Chega de nos limitarmos a<br />
										assistir a aulas ou de estudar
										<br />
										pelo mesmo roteiro que todo
										<br />
										mundo.
										<br />
										<br />
										O Sprint usa modelo ativo
										<br />
										de aprendizado e tem revisões
										<br />
										cíclicas automáticas e<br />
										personalizadas, que são
										<br />
										preparadas pela plataforma de
										<br />
										acordo com a necessidade de
										<br />
										cada um. Cada aluno tem o seu
										<br />
										próprio SPRINT!
									</Typography>
								)}
							</Grid>
						</Paper>
					</Grid>
				</Grid> */}
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className={matchesMobile ? classes.sectionMobile : classes.section}
					style={
						matchesMobile
							? { marginBottom: '4em', padding: '2vh 1em', marginTop: 70 }
							: { marginBottom: '4em' }
					}
				>
					<Grid
						item
						xs={12}
						container
						className="revalida"
					>
						<Typography
							variant="h1"
							className={`${classes.title} revalida-title-text gradient-text roboto-condensed`}
						>
							O Time Hardwork domina o Revalida INEP por inteiro
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						md={9}
						container
					>
						<Grid
							item
							xs={12}
							style={{ padding: '10px 0 1em' }}
						>
							<Typography
								align="left"
								color="secondary"
								className={`${classes.text} revalida-texts poppins`}
							>
								Nada supera o Hardwork. Nós vamos contigo em todas as etapas do Revalida INEP.
								<br />
								Tudo que tu precisas para detonar a prova teórica e para dominar a prática está aqui.
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							container
							spacing={matchesMobile ? 1 : 2}
						>
							<Grid
								item
								xs={6}
								md={4}
								className={`${matchesMobile ? classes.gridInfoCardMobile : null} `}
							>
								<SaibaMaisCard
									type="revalida"
									name="Arena Hardwork"
									description="Experiência exclusiva e intensa! Estações interativas, realizadas em pequenos grupos, que resolvem lacunas no aprendizado e te preparam para a prova prática do revalida INEP. "
									url="/arena-hardwork"
								/>
							</Grid>
							<Grid
								item
								xs={6}
								md={4}
								className={`${matchesMobile ? classes.gridInfoCardMobile : null} `}
							>
								<SaibaMaisCard
									type="revalida"
									name="Live Revalida"
									description="Um SHOW de véspera de Prova. Sem desperdício de energia, sem blá blá blá. Uma revisão leve, prazerosa e intensa em um evento presencial com experiência Hardwork MULTISSENSORIAL! Conexão direta entre professores e alunos."
									url="/live-das-lives-revalida"
								/>
							</Grid>
							<Grid
								item
								xs={6}
								md={4}
								className={`${matchesMobile ? classes.gridInfoCardMobile : null} `}
							>
								<SaibaMaisCard
									type="revalida"
									name="Sprint Final Revalida"
									description="Hora de entrar na alma da prova do INEP! O Sprint Final é a ÚNICA revisão totalmente focada na banca do INEP! O Sprint domina as provas objetiva e discursiva do Revalida INEP."
									url="/sprint-final-revalida"
								/>
							</Grid>
							<Grid
								item
								xs={6}
								md={4}
								className={`${matchesMobile ? classes.gridInfoCardMobile : null} `}
								style={matchesTablet ? null : { marginTop: 50 }}
							>
								<SaibaMaisCard
									type="revalida"
									name="Trilha Revalida"
									description={
										'Vais fazer a prova do INEP quando? 2025? 2026? Hora de começar! O Trilha Revalida é a junção do Extensivo com o Sprint Final... Criamos a base sólida de conhecimento e de domínio de questões, além de ter acesso garantido ao Sprint Final!'
									}
									url={'/trilha-revalida'}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid
						item
						xs={3}
						container
						justifyContent="flex-end"
						style={{ position: 'relative', bottom: '2em' }}
						className={`${
							matchesMobile || matchesTablet ? classes.displayNone : null
						} fabricio-image-revalida`}
					>
						<img
							src={process.env.PUBLIC_URL + '/Assets/Img/fabricioImage2.png'}
							className={classes.fabricioImage}
							alt="Fabrício Valois"
						/>
					</Grid>
				</Grid>
			</Container>
			{/* TO DO DEPOIS
			<TeachingMetodologySection type="revalida" /> */}
			{/* <Grid
				container
				alignContent="center"
				justifyContent="center"
				className={classes.section}
			>
				<Grid
					item
					xs={12}
					container
				>
					<Grid
						item
						xs={12}
						container
						className="revalida"
					>
						<Typography className={`${classes.title} gradient-text roboto-condensed`}>
							Time Hardwork Medicina
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						style={{ paddingBottom: '2.5em' }}
					>
						<Typography
							align="left"
							color="secondary"
							className={`${classes.text} revalida-texts poppins`}
						>
							Aliquam sit amet augue non augue ornare pellentesque. Aenean
							<br />
							euismod suscipit tortor dignissim aliquam.
						</Typography>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					container
				>
					<Grid
						item
						xs={7}
						container
						justifyContent="flex-start"
					>
						<Grid
							item
							xs={5}
							style={{ margin: '0 2em 0 2.5em' }}
						>
							<DoctorCard
								name="Fabrício Valois"
								specialty="Pneumologia"
								insta="@fabricio.valois"
							/>
						</Grid>
						<Grid
							item
							xs={5}
						>
							<DoctorCard
								name="Fábio Colagrossi"
								specialty="Cirurgia Geral"
								insta="@drfabiocolagrossi"
							/>
						</Grid>
					</Grid>
					<Grid
						item
						xs={5}
					>
						<Paper
							className={`${classes.ourHistoryCard} rounded-card`}
							elevation={4}
						>
							<Grid
								container
								justifyContent="center"
								style={{
									height: '100%',
									padding: '1.5em 2em',
								}}
							>
								<Grid
									item
									xs={12}
									className="revalida"
								>
									<Typography
										align="left"
										className={`${classes.ourHistoryCardTitle} revalida gradient-text roboto-condensed`}
									>
										Nossa história
									</Typography>
								</Grid>
								<Grid
									item
									xs={12}
									style={{ backgroundColor: '#fff', height: '38%' }}
								></Grid>
								<Grid
									item
									xs={12}
								>
									<Typography
										className={`${classes.textFontSize18} poppins`}
										color="secondary"
										gutterBottom
									>
										Fusce accumsan nunc eget enim placerat, non molestie ipsum
										<br />
										ullamcorper. Phasellus at dictum nisl. Cras maximus pellentesque
										<br />
										quam, eget lobortis nunc iaculis non.
									</Typography>
								</Grid>
								<Grid
									item
									xs={4}
									container
									alignContent="flex-end"
									className="revalida"
								>
									<DefaultButton
										variant="outlined"
										color="secondary"
										className="btn btn-secondary"
									>
										Conheça
									</DefaultButton>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			</Grid> */}
			<StudentsFeedbackSection type="revalida" />
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{/* <BlogSection /> */}
				{/* <Newsletter /> */}
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.75rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.75rem !important',
		},
	},
	text: {
		fontSize: '1.315rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.95rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
			lineHeight: '1.2 !important',
		},
	},
	firstSectionCardText: {
		fontSize: '1.2rem !important',
		lineHeight: '1.3 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.95rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.75rem !important',
			lineHeight: '1.2 !important',
		},
	},
	// img: {
	// 	height: '96%',
	// 	width: '96%',
	// },
	// sprintFinalRevalidaLogo: {
	// 	position: 'absolute',
	// 	zIndex: 1,
	// 	top: '1em',
	// 	left: '1em',
	// 	width: '19vw',
	// 	[theme.breakpoints.down('lg')]: {
	// 		width: 235,
	// 		left: '2em',
	// 	},
	// 	[theme.breakpoints.down('sm')]: {
	// 		top: '0%',
	// 		width: '55%',
	// 	},
	// },
	// gridButton: {
	// 	position: 'absolute',
	// 	bottom: '3em',
	// 	[theme.breakpoints.down('sm')]: {
	// 		position: 'relative',
	// 		bottom: '2.5em',
	// 	},
	// },
	// btn: {
	// 	fontSize: '1.15rem !important',
	// 	width: '38%',
	// 	left: '4em',
	// 	textTransform: 'unset !important',
	// 	fontWeight: 'bold !important',
	// 	[theme.breakpoints.down('lg')]: {
	// 		fontSize: '0.75rem !important',
	// 	},
	// 	[theme.breakpoints.down('sm')]: {
	// 		fontSize: '0.9rem !important',
	// 		width: '197px',
	// 		left: '2em',
	// 	},
	// },
	// firstSectionPaper: {
	// 	width: '85%',
	// 	color: 'unset !important',
	// 	height: '498px',
	// 	borderRadius: '30px !important',
	// 	padding: '1.5rem 1rem',
	// 	paddingTop: 20,
	// 	display: 'flex',
	// 	alignItems: 'center',
	// 	justifyContent: 'center',
	// 	[theme.breakpoints.down('md')]: {
	// 		minHeight: '0',
	// 	},
	// 	[theme.breakpoints.down('sm')]: {
	// 		padding: '1rem !important',
	// 		height: 'unset !important',
	// 		width: '100%',
	// 	},
	// },
	// ourHistoryCard: {
	// 	backgroundColor: '#0D0D0D !important',
	// 	height: '100%',
	// },
	// ourHistoryCardTitle: {
	// 	fontWeight: 'bold !important',
	// 	fontSize: '2em !important',
	// 	lineHeight: '1 !important',
	// },
	// textFontSize18: {
	// 	fontSize: '.85rem !important',
	// },
	fabricioImage: {
		height: 564,
	},
	sectionMobile: {
		padding: '2vh 3%',
	},
	displayNone: {
		display: 'none !important',
	},
	gridInfoCardMobile: {
		height: 310,
	},
}));
