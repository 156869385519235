import React from 'react';
import { Radar } from 'react-chartjs-2';
import { Grid } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

export default function RadarChartPerformance() {
	const [searchParams] = useSearchParams();

	const dataInt = searchParams
		.get('data')
		.split(',')
		.map((elm) => parseInt(elm));

	const chartLabels = ['CM', 'PED', 'MP', 'GO', 'CG'];
	const chartData = dataInt;

	const chartOptions = {
		plugins: {
			legend: {
				display: false,
			},
		},
		scales: {
			r: {
				min: 0,
				max: 100,
				ticks: {
					stepSize: 20,
					display: false,
					color: '#13479F',
					font: { size: 14 },
					family: 'Poppins',
					callback: (value) => `${value} %`,
				},
				pointLabels: {
					color: '#13479F',
					font: { size: 14, weight: 'bold', family: 'Poppins' },
				},
				angleLines: { color: '#707070' },
				grid: { color: '#707070' },
			},
		},
	};

	const chartDataConfig = {
		labels: chartLabels.map((label, index) => [label, `${dataInt[index]}%`]),
		datasets: [
			{
				data: chartData,
				label: 'Perfomance por questões',
				backgroundColor: '#B2EEFE99',
				borderColor: '#8596FF',
				borderWidth: 3,
				pointBackgroundColor: '#13479F',
				pointBorderWidth: 5,
				pointBorderColor: '#13479F',
			},
		],
	};

	const chartStyle = {
		backgroundColor: 'white',
		justifyContent: 'center',
		alignItems: 'center',
	};

	return (
		<Grid style={chartStyle}>
			<Grid>
				<Radar
					data={chartDataConfig}
					options={chartOptions}
				/>
			</Grid>
		</Grid>
	);
}
