import React, { useState } from 'react';
import { Container, Divider, Grid, Paper, TextField, Typography, useMediaQuery, useTheme, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DefaultButton from '../Components/Buttons/DefaultButton';
import Footer from '../Components/Footer';
import { Header } from '../Components/Header';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';

import Api from '../Services/Api';
import { Helmet } from 'react-helmet';

export default function ContactPage() {
	const classes = useStyles();
	const theme = useTheme();
	const [btnEnable, setBtnEnable] = useState(true);
	const [contato, setContato] = useState({
		nome: '',
		email: '',
		mensagem: '',
	});

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	let sendContato = function () {
		if (!contato.nome) {
			alert('Ops... Por favor informe seu nome');
			return;
		}
		if (!contato.email || !contato.email.includes('@') || !contato.email.includes('.')) {
			alert('Ops... Por favor preencha seu email');
			return;
		}
		if (!contato.mensagem) {
			alert('Ops... Por favor preencha o campo mensagem');
			return;
		}
		setBtnEnable(false);

		trackPromise(
			Api.put('Contato', contato).then((res) => {
				if (res) {
					setTimeout(() => {
						document.location.reload();
					}, 5000);
					Swal.fire({
						title: 'Legal! ',
						text: 'Mensagem enviada, logo retornaremos seu contato.',
						icon: 'success',
						confirmButtonText: 'Fechar',
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then((result) => {
						if (result.isConfirmed) {
							document.location.reload();
						}
					});
				}
			})
		);
	};

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
            <Helmet>
				<title>Contato - Hardwork Medicina</title>
				<meta
					name="description"
					content="Dúvidas, sugestões, elogios e reclamações, mande uma mensagem pra gente."
				/>
			</Helmet>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					alignContent="center"
					// justifyContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					style={matchesMobile || matchesTablet ? { marginTop: 80 } : null}
				>
					<Grid
						item
						xs={12}
						container
						style={{ paddingBottom: '1em' }}
						className="residencia"
					>
						<Typography
							className={classes.title}
							color="secondary"
						>
							Fale Conosco
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						style={{ paddingBottom: '1.5em' }}
					>
						<Typography
							align="left"
							variant="h4"
							color="secondary"
							className={`${classes.text} poppins`}
						>
							Dúvidas, sugestões, elogios e reclamações, mande uma mensagem pra gente
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<Divider
							variant="middle"
							className={classes.divider}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						lg={8}
						container
						style={{ padding: '2em 0' }}
					>
						<Paper
							elevation={4}
							className={`${classes.paper} rounded-card`}
							sx={{
								bgcolor: '#333333',
							}}
						>
							<Grid
								container
								spacing={2}
							>
								<Grid
									item
									xs={12}
									md={6}
								>
									<TextField
										fullWidth
										type="string"
										placeholder="Nome"
										required={true}
										InputProps={{
											//disableUnderline: true,
											className: `${classes.form} ${classes.formHeight}`,
										}}
										InputLabelProps={{
											shrink: true,
										}}
										onChange={(e) =>
											setContato({
												...contato,
												nome: e.target.value,
											})
										}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									md={6}
								>
									<TextField
										fullWidth
										type="email"
										placeholder="Email"
										required={true}
										InputProps={{
											//disableUnderline: true,
											className: `${classes.form} ${classes.formHeight}`,
										}}
										InputLabelProps={{
											shrink: true,
										}}
										onChange={(e) =>
											setContato({
												...contato,
												email: e.target.value,
											})
										}
									/>
								</Grid>
								<Grid
									item
									xs={12}
								>
									<TextField
										fullWidth
										type="string"
										placeholder="Escreva sua mensagem..."
										required={true}
										multiline
										rows={10}
										InputProps={{
											//disableUnderline: true,
											className: classes.form,
										}}
										InputLabelProps={{
											shrink: true,
										}}
										onChange={(e) =>
											setContato({
												...contato,
												mensagem: e.target.value,
											})
										}
									/>
								</Grid>
								<Grid
									item
									container
									xs={12}
									md={4}
									className="residencia"
								>
									<DefaultButton
										variant="outlined"
										color="secondary"
										className={`${classes.btn} btn btn-primary`}
										onClick={() => sendContato()}
										disabled={!btnEnable}
									>
										Enviar mensagem
									</DefaultButton>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
					{matchesMobile ? null : (
						<Grid
							item
							xs={12}
							lg={4}
							className={classes.containerWorkHours}
						>
							<Paper
								elevation={4}
								className={`${classes.paper} ${classes.workHourPaper} rounded-card`}
								sx={{
									bgcolor: '#333333',
								}}
							>
								{/* <Grid
									item
									xs={12}
									container
								>
									<Typography
										className={`${classes.workHourTitle} poppins`}
										color="secondary"
									>
										Horário de atendimento:
									</Typography>
								</Grid>
								<Grid
									item
									xs={12}
									style={{ paddingBottom: '1.5em' }}
								>
									<Typography
										align="left"
										variant="h4"
										color="secondary"
										className={`${classes.text} poppins`}
									>
										De segunda a sexta, das 9h às 19h, aos sábados das 8h às 12h(exceto domingos e
										feriados)
									</Typography>
								</Grid> */}
								<Grid
									item
									xs={12}
									container
								>
									<Typography
										className={`${classes.workHourTitle} poppins`}
										color="secondary"
									>
										Email:
									</Typography>
								</Grid>
								<Grid
									item
									xs={12}
									style={{ paddingBottom: '1.5em' }}
								>
									<Link
										color="secondary"
										href="mailto:contato@hardworkmedicina.com.br"
										underline="hover"
										className={`${classes.text} poppins`}
									>
										{'contato@hardworkmedicina.com.br'}
									</Link>
								</Grid>
								<Grid
									item
									xs={12}
									container
								>
									<Typography
										className={`${classes.workHourTitle} poppins`}
										color="secondary"
									>
										WhatsApp
									</Typography>
								</Grid>
								<Grid
									item
									xs={12}
									style={{ paddingBottom: '1.5em' }}
								>
									<Link
										color="secondary"
										href="https://api.whatsapp.com/send?phone=5511932443698"
										underline="hover"
										align="right"
										className={`${classes.text} poppins`}
									>
										Tel: (11) 93244-3698
									</Link>
								</Grid>
								<Grid
									item
									xs={12}
								>
									<Link
										color="#6D6D6D"
										href="mailto:contato@hardworkmedicina.com.br"
										underline="always"
										align="right"
										className={classes.linkToFaq}
									>
										Dúvidas sobre treinamentos?
									</Link>
								</Grid>
							</Paper>
						</Grid>
					)}
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '2.75em !important',
		lineHeight: '1 !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '2.35rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.8rem !important',
		},
	},
	text: {
		fontSize: '1.324rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	divider: {
		margin: 'unset !important',
		backgroundColor: 'grey',
		minHeight: 1,
	},
	paper: {
		width: '100%',
		margin: '0 auto',
		padding: '1.5rem',
		[theme.breakpoints.down('sm')]: {
			padding: '1rem',
		},
	},
	form: {
		borderRadius: '8px !important',
		backgroundColor: theme.palette.secondary.main,
	},
	formHeight: {
		maxHeight: '3em',
	},
	btn: {
		fontSize: '1.15rem !important',
		width: 300,
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	workHourTitle: {
		fontSize: '1.7em !important',
		lineHeight: 1.25,
		fontWeight: 'bold !important',
	},
	linkToFaq: {
		fontSize: '1.25rem',
		fontWeight: 'bold',
	},
	containerWorkHours: {
		padding: '2em 0 2em 2em',
		[theme.breakpoints.down('lg')]: {
			padding: 0,
		},
	},
	workHourPaper: {
		height: '100%',
		[theme.breakpoints.down('lg')]: {
			height: 'unset !important',
		},
	},
}));
