import { useEffect, useState } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import moment from 'moment';

export default function Contador({ targetDate, title }) {
	const theme = useTheme();
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const calculateTimeLeft = (targetDate) => {
		const now = moment().utc();
		const difference = moment(targetDate).utc().diff(now);
		// console.log('consolada: ', isNaN(difference));

		if (difference <= 0 || isNaN(difference)) {
			// Contagem regressiva terminou
			return { days: 0, hours: 0, minutes: 0, seconds: 0 };
		}

		const days = Math.floor(difference / (1000 * 60 * 60 * 24));
		const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((difference % (1000 * 60)) / 1000);

		return { days, hours, minutes, seconds };
	};

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

	useEffect(() => {
		const timer = setInterval(() => {
			setTimeLeft(calculateTimeLeft(targetDate));
		}, 1000);
		return () => {
			clearInterval(timer);
		};
	}, [targetDate]);

	return (
		<div
			style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				fontFamily: 'Manrope',
				marginTop: '1rem',
			}}
		>
			{/* <div
				style={{
					width: '100%',
					backgroundColor: '#656565',
					height: '1px',
				}}
			/> */}
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					width: '100%',
					justifyContent: 'space-between',
					borderRadius: '0.25rem',
					padding: '0.5rem',
					backgroundColor: '#656565',
				}}
			>
				<div style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
					<p
						style={{
							fontSize: matchesTablet ? '12px' : '16px',
							fontWeight: '500',
							color: '#FFFFFF',
						}}
					>
						{title}
					</p>
				</div>
				<div
					style={{
						width: 'fit-content',
						display: 'flex',
						flexDirection: 'row',
						gap: matchesTablet ? '0.5rem' : '1.4rem',
						fontFamily: 'Manrope',
					}}
				>
					{/* DIAS */}
					{timeLeft?.days !== 0 && (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								rowGap: '0.5rem',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<p style={{ fontSize: '24px', fontWeight: '600', color: '#FFFFFF' }}>{timeLeft?.days}</p>
							<p
								style={{
									fontSize: matchesTablet ? '10px' : '12px',
									fontWeight: '600',
									color: '#FFFFFF',
								}}
							>
								Dias
							</p>
						</div>
					)}
					{/* HORAS */}
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							rowGap: '0.5rem',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<p style={{ fontSize: '24px', fontWeight: '600', color: '#FFFFFF' }}>{timeLeft?.hours}</p>
						<p
							style={{
								fontSize: matchesTablet ? '10px' : '12px',
								fontWeight: '600',
								color: '#FFFFFF',
							}}
						>
							Horas
						</p>
					</div>{' '}
					{/* MINUTOS */}
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							rowGap: '0.5rem',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<p style={{ fontSize: '24px', fontWeight: '600', color: '#FFFFFF' }}>{timeLeft?.minutes}</p>
						<p
							style={{
								fontSize: matchesTablet ? '10px' : '12px',
								fontWeight: '600',
								color: '#FFFFFF',
							}}
						>
							Minutos
						</p>
					</div>{' '}
					{/* SEGUNDOS */}
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							rowGap: '0.5rem',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<p style={{ fontSize: '24px', fontWeight: '600', color: '#FFFFFF' }}>{timeLeft?.seconds}</p>
						<p
							style={{
								fontSize: matchesTablet ? '10px' : '12px',
								fontWeight: '600',
								color: '#FFFFFF',
							}}
						>
							Segundos
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}
