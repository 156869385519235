import React from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import { Header } from '../../../../Components/Header';

import Form from '../../../../Components/Utils/Forms/PreRegistration';

export default function LiveMockTestUnicampSubscription() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					alignContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
				>
					<Grid
						item
						xs={12}
						md={7}
						container
						className="residencia"
					>
						<Grid
							item
							container
							alignContent={'flex-end'}
						>
							<Typography
								className={`${classes.title} poppins`}
								color="secondary"
								style={matchesTablet ? { marginTop: 16, paddingLeft: 0 } : { paddingLeft: 15 }}
							>
								MEGA SIMULADO DA PROVA DISCURSIVA DA{' '}
								<strong
									className={`${classes.title} gradient-text poppins`}
									style={{ display: 'inline' }}
								>
									UNICAMP
								</strong>
								{/* Simulado da prova discursiva */}
							</Typography>
							{/* <Typography
								className={`${classes.mainTitle} gradient-text roboto-condensed`}
								color="secondary"
								style={matchesTablet ? { marginTop: 8, paddingLeft: 0 } : { paddingLeft: 15 }}
							>
								UNICAMP
							</Typography> */}
							<Typography
								className={`${classes.title} poppins`}
								color="secondary"
								style={matchesTablet ? { marginTop: 16, paddingLeft: 0 } : { paddingLeft: 15 }}
							>
								+ Revisão gratuita!
							</Typography>
						</Grid>
						<Typography
							className={`${classes.text} poppins`}
							color="secondary"
							style={matchesTablet ? { marginTop: 16, paddingLeft: 0 } : { paddingLeft: 15 }}
						>
							<br />
							<Typography
								className={`${classes.text} poppins gradient-text`}
								color="secondary"
								style={{ fontWeight: 'bold' }}
							>
								DIA 11 DE NOVEMBRO!
							</Typography>
							<img
								src={process.env.PUBLIC_URL + '/Assets/Img/img-cronograma.svg'}
								alt=""
							/>
							<br />
							Duas provas com 50 questões. A primeira pela manhã, EXCLUSIVA para alunos Hardwork; a
							segunda pela tarde, aberta ao público geral.
							<br />
							<br />À noite, correção das provas e discussão dos temas com os professores Hardwork! As
							inscrições são gratuitas!
							<br />
							<br />
						</Typography>
						<video
							src={process.env.PUBLIC_URL + '/Assets/bg-video-mock-test-discursiva-unicamp.mp4'}
							autoPlay
							loop
							muted
							playsInline
							className={classes.imgExtensivo}
						/>
						{/* <Grid
							item
							xs={12}
							container
							justifyContent={matchesTablet ? 'center' : 'flex-start'}
							style={matchesMobile ? null : { position: 'absolute', bottom: 10, paddingLeft: 16 }}
						>
							<Typography
								align="left"
								color="secondary"
								className={`${classes.impactText} poppins`}
								style={{ padding: '0 10px' }}
							>
								SÁBADO
								<span className="bullet"> &#8226; </span>
								21OUT
								<span className="bullet"> &#8226; </span>
								20H
							</Typography>
						</Grid> */}
					</Grid>
					<Grid
						item
						xs={12}
						md={5}
						container
						rowGap={2}
						style={{ height: 'fit-content', marginTop: matchesMobile ? '2rem' : null }}
					>
						<Form
							group="SIMULADO PROVA DISCURSIVA UNICAMP"
							title={['Inscreva-se gratuitamente']}
							titleButton="Confirmar inscrição"
							titleConfirmationMessage={'Massa!'}
							confirmationMessage={
								'Sua inscrição está confirmada! Em breve você também receberá uma confirmação pelo e-mail cadastrado.'
							}
							urlToRedirect={'/simulado-discursiva-unicamp'}
							type="residencia"
						/>
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3rem !important',
		lineHeight: '1 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
		},
	},
	mainTitle: {
		fontWeight: 'bold !important',
		fontSize: '4rem !important',
		lineHeight: '1 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '3.2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.25rem !important',
		},
	},
	text: {
		fontSize: '1.324rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	bottomSignInCardText: {
		fontSize: '1.15rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
	},
	divider: {
		margin: 'unset !important',
		backgroundColor: 'grey',
		minHeight: 1,
	},
	workHourTitle: {
		fontSize: '1.7em !important',
		lineHeight: 1.25,
		fontWeight: 'bold !important',
	},
	containerWorkHours: {
		padding: '2em 0 2em 2em',
		[theme.breakpoints.down('lg')]: {
			padding: 0,
		},
	},
	workHourPaper: {
		height: '100%',
		[theme.breakpoints.down('lg')]: {
			height: 'unset !important',
		},
	},
	imgExtensivo: {
		width: '99%',
		[theme.breakpoints.down('md')]: {
			margin: '40px 0 !important',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '0px !important',
		},
	},
	impactText: {
		margin: '0 0 2rem !important',
		fontSize: '2rem !important',
		lineHeight: 'unset !important',
		background: 'linear-gradient(90deg, rgb(255 72 0) 10%, rgb(255 0 66) 100%)',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.45rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.25rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1.25rem !important',
		},
		[theme.breakpoints.down(361)]: {
			fontSize: '1.25rem !important',
		},
	},
}));
