import React, { useState } from 'react';
import { Grid, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import IntlTelInput from 'react-intl-tel-input';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';
import { makeStyles } from '@mui/styles';

import Api from '../../../Services/Api';
import DefaultButton from '../../Buttons/DefaultButton';

export default function WaitListForm({ group, onHandleClose, lot }) {
	const classes = useStyles();
	const theme = useTheme();

	const [preInscricao, setPreInscricao] = useState({
		agrupamento: group,
		nome: '',
		email: '',
		telefone: '',
		provas: lot,
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
	});

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	let sendPreInscricao = function (event) {
		event.preventDefault();
		const nomeCompleto = preInscricao.nome.trim();
		const nomeSobrenome = nomeCompleto.split(' ');
		if (!preInscricao.nome || nomeSobrenome.length < 2) {
			alert('Ops... Por favor informe seu nome e sobrenome');
			return;
		}
		if (!preInscricao.email || !preInscricao.email.includes('@') || !preInscricao.email.includes('.')) {
			alert('Ops... Por favor preencha seu email');
			return;
		}
		if (!preInscricao.telefone) {
			alert('Ops... Por favor preencha seu telefone');
			return;
		}

		trackPromise(
			Api.post('PreInscricao', preInscricao).then((res) => {
				if (res) {
					Swal.fire({
						title: 'Massa! ',
						text: `O cadastro está confirmado, avisaremos no email sobre a abertura do ${
							lot === 'Trilha' || lot === 'Sprint' ? `Lote ${lot}` : lot
						}.`,
						icon: 'success',
						confirmButtonText: 'Fechar',
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then((result) => {
						if (result.isConfirmed) {
							setTimeout(() => {
								document.location.reload();
							}, 500);
						}
					});
				}
			})
		);
	};

	const handlePhone = (valid, phoneNumber) => {
		if (valid) {
			setPreInscricao({
				...preInscricao,
				telefone: phoneNumber,
			});
		} else if (preInscricao.telefone.length) {
			setPreInscricao({
				...preInscricao,
				telefone: '',
			});
		}
	};

	return (
		<form
			className="form-control"
			onSubmit={sendPreInscricao}
			action="javascript:;"
			style={
				matchesMobile
					? { width: 'unset', margin: '60% 1rem' }
					: {
							overflow: 'hidden',
							transition: 'opacity 1s ease, height 1s ease',
							margin: '40vh auto',
							width: 800,
					  }
			}
		>
			<Grid
				item
				container
				justifyContent={'space-between'}
				className={classes.containerWaitList}
				rowGap={matchesMobile ? 1 : 0}
			>
				<Grid
					item
					container
					justifyContent="space-between"
				>
					<Typography
						color="primary"
						className={`${classes.textWaitList} poppins`}
					>
						Cadastre-se para receber as novidades
					</Typography>
					<CancelRoundedIcon
						onClick={() => onHandleClose()}
						className={classes.cancelIcon}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					md={5.75}
					container
				>
					<TextField
						fullWidth
						type="string"
						name="nome"
						placeholder="Seu nome completo"
						required={true}
						InputProps={{
							className: `${classes.form} ${classes.formHeight}`,
						}}
						InputLabelProps={{
							shrink: true,
						}}
						onChange={(e) =>
							setPreInscricao({
								...preInscricao,
								nome: e.target.value,
							})
						}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					md={5.75}
					container
					alignContent={'center'}
				>
					<IntlTelInput // Componente Input de telefones internacionais
						name="mobile"
						type="mobile"
						preferredCountries={['BR', 'PY', 'AR', 'CL', 'BO', 'UY', 'CU', 'PT']}
						style={{ width: '100%', border: 'unset' }}
						inputClassName={`${classes.phoneNumberForm} ${classes.form} ${classes.formHeight}`}
						nationalMode={true}
						onPhoneNumberChange={(valid, value, selectedCountryData, fullNumber) =>
							handlePhone(valid, fullNumber)
						}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					container
				>
					<TextField
						fullWidth
						type="email"
						name="email"
						placeholder="Seu melhor e-mail"
						required={true}
						InputProps={{
							className: `${classes.form} ${classes.formHeight}`,
						}}
						InputLabelProps={{
							shrink: true,
						}}
						style={{ width: '100%' }}
						onChange={(e) =>
							setPreInscricao({
								...preInscricao,
								email: e.target.value,
							})
						}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					md={10}
					lg={5}
					container
					className="residencia"
					style={{ padding: '4px 0px', margin: '0 auto' }}
				>
					<DefaultButton
						type="submit"
						variant="outlined"
						color="secondary"
						className={`${classes.btnWaitList} btn`}
						onClick={(e) => sendPreInscricao(e)}
					>
						<img
							src={require('../../../Assets/Arena/icon-waitList.svg').default}
							alt=""
							style={{ width: 20 }}
						/>{' '}
						&nbsp; Confirmar cadastro
					</DefaultButton>
				</Grid>
			</Grid>
		</form>
	);
}

const useStyles = makeStyles((theme) => ({
	containerWaitList: {
		// width: '100% !important',
		backgroundColor: '#d6cece',
		zIndex: 1,
		borderRadius: 15,
		padding: '0.5rem 1.25rem',
		// boxShadow: '0px 0px 30px 0px #000000',
		border: '2px solid #000000',
		// position: 'absolute',
		// bottom: 0,
		[theme.breakpoints.down('sm')]: {
			padding: '1rem 1.25rem',
		},
	},
	textWaitList: {
		fontSize: '1rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.95rem !important',
		},
	},
	cancelIcon: {
		color: '#000000',
		right: '-15px',
		top: '-5px',
		'&:hover': {
			cursor: 'pointer',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'absolute',
			top: '-15px',
			right: '-18px',
		},
	},
	form: {
		borderRadius: '8px !important',
		backgroundColor: '#E1E1E1 !important',
		margin: '5px 0 !important',
		'& .MuiInputBase-input': {
			padding: '0.5rem !important',
		},
	},
	formHeight: {
		maxHeight: '2.5em',
	},
	phoneNumberForm: {
		height: '2.5em !important',
		borderRadius: 8,
		width: '100%',
		border: 'unset',
	},
	btnWaitList: {
		fontSize: '0.9rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		backgroundColor: '#00AB55 !important',
		border: '1px solid #707070 !important',
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				// minWidth: '72% !important',
				// fontSize: '1.025rem !important',
				// borderWidth: '3px !important',
				backgroundColor: '#00543b !important',
				transition: '0.1s',
			},
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
}));
