import React from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import { Header } from '../../../../Components/Header';

import LogoReactRevalida from '../../../../Assets/Extensivo/Revalida/logo-react-revalida.svg';

import Form from '../../../../Components/Utils/Forms/PreRegistration';
import InformationalWhiteCard from '../../../../Components/Cards/InformationalWhiteCard';

export default function LiveReactRevalida() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					alignContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
				>
					<Grid
						item
						xs={12}
						md={7}
						container
						justifyContent={matchesMobile ? 'center' : 'flex-start'}
						className={`${classes.gridTextContainer} revalida`}
					>
						{matchesMobile ? (
							<img
								src={LogoReactRevalida}
								alt="Logo React Revalida"
								style={{ width: '60%' }}
							/>
						) : null}
						<Typography
							className={`${classes.text} poppins`}
							color="secondary"
						>
							Inscreva-se gratuitamente para ter acesso à live de correção da prova, download de recursos
							e sorteio de uma vaga e cupons de desconto para o Arena Hardwork!
						</Typography>
						<Grid
							item
							xs={12}
							container
							justifyContent={matchesTablet ? 'center' : 'flex-start'}
							style={
								matchesMobile
									? { marginTop: 30 }
									: matchesTablet
									? { marginTop: 50 }
									: { marginTop: 80 }
							}
						>
							<InformationalWhiteCard
								title="Correção da prova Revalida"
								description={
									'Correção AO VIVO da prova do Revalida Inep de Agosto 2023 no dia 10/08 às 10h, com gabarito interativo e comentários dos professores sobre as questões mais polêmicas da prova!'
								}
								img={require('../../../../Assets/Extensivo/Revalida/banner-bg-correcao-prova-revalida.png')}
								textDate="QUINTA 10AGO | 10H"
								classes={classes}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							container
							justifyContent={matchesTablet ? 'center' : 'flex-start'}
						>
							<InformationalWhiteCard
								title="Download de recursos"
								description={
									'Tenha acesso às orientações de recursos possíveis para a prova de acordo com o time Hardwork.'
								}
								img={require('../../../../Assets/Extensivo/Revalida/banner-bg-download-recursos-revalida.png')}
								classes={classes}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							container
						>
							<InformationalWhiteCard
								title="Sorteio de cupons e uma vaga do Arena"
								description={[
									'Após realizar sua inscrição, acompanhe a live de correção na íntegra para concorrer ao sorteio de ',
									<strong>1 vaga para o Arena Hardwork Online</strong>,
									' e ',
									<strong>cupons de desconto</strong>,
									' para o Arena Presencial e Online',
									', nosso treinamento para a prova prática do Revalida.',
								]}
								img={require('../../../../Assets/Extensivo/Revalida/banner-bg-sorteio-cupons-revalida.png')}
								textDate="QUINTA 10AGO | 10H"
								classes={classes}
							/>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						md={5}
						container
						justifyContent="center"
						className={classes.gridWithBackgroundImage}
					>
						<img
							src={LogoReactRevalida}
							alt="Logo React Revalida"
							className={classes.logoReactDownXl}
						/>
						<Grid
							item
							xs={12}
							className={classes.gridForm}
						>
							{matchesMobile ? null : (
								<img
									src={LogoReactRevalida}
									alt="Logo React Revalida"
									className={classes.logoReact}
								/>
							)}
							<Form
								group="CORREÇÃO INEP 2023.2"
								title={['Inscreva-se para acessar os conteúdos e participar do sorteio!']}
								titleButton="Confirmar inscrição"
								type="revalida"
								urlToRedirect="/live/react-revalida"
								confirmationMessage="Massa! Em breve enviaremos um email com as orientações para participar da correção da prova!"
							/>
						</Grid>
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.35rem !important',
		lineHeight: 'unset !important',
		marginBottom: '1rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
			lineHeight: 'unset !important',
			marginBottom: '8px !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.18rem !important',
			marginBottom: '8px !important',
			lineHeight: 'unset !important',
		},
	},
	text: {
		fontSize: '1.324rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	bottomSignInCardText: {
		fontSize: '1.15rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
	},
	divider: {
		margin: 'unset !important',
		backgroundColor: 'grey',
		minHeight: 1,
	},
	workHourTitle: {
		fontSize: '1.7em !important',
		lineHeight: 1.25,
		fontWeight: 'bold !important',
	},
	containerWorkHours: {
		padding: '2em 0 2em 2em',
		[theme.breakpoints.down('lg')]: {
			padding: 0,
		},
	},
	workHourPaper: {
		height: '100%',
		[theme.breakpoints.down('lg')]: {
			height: 'unset !important',
		},
	},
	imgExtensivo: {
		width: '100% !important',
		[theme.breakpoints.down('md')]: {
			margin: '40px 0 !important',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '0px !important',
		},
	},
	impactText: {
		fontSize: '2.25rem !important',
		margin: '24px 0 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.6rem !important',
			margin: '16px 0 !important',
		},
	},
	paper: {
		width: 777,
		marginBottom: 50,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		[theme.breakpoints.down('xl')]: {
			width: 585,
		},
		[theme.breakpoints.down('lg')]: {
			height: 'unset',
			width: 435,
			marginBottom: 30,
		},
		[theme.breakpoints.down('md')]: {
			width: 585,
		},
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
			width: 'unset',
		},
	},
	titleInformationalCard: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		textShadow: '2px 2px 4px #000000',
		margin: '8px 0 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '16px 0 !important',
			lineHeight: 'unset !important',
		},
	},
	gridDescriptionCard: {
		backgroundColor: '#FFFFFF !important',
		padding: '1em 2em',
		borderRadius: 16,
		[theme.breakpoints.down('sm')]: {
			padding: '1em',
		},
	},
	gridForm: {
		width: 567,
		position: 'fixed',
		// top: 385,
		bottom: 90,
		zIndex: 10,
		[theme.breakpoints.down(1300)]: {
			width: 467,
			right: 30,
			bottom: 50,
			// height: 500,
		},
		[theme.breakpoints.down('lg')]: {
			right: 50,
		},
		[theme.breakpoints.down(1010)]: {
			right: 20,
		},
		[theme.breakpoints.down('md')]: {
			position: 'unset',
			right: 'unset',
			top: 'unset',
			zIndex: 0,
		},
	},
	gridTextContainer: {
		paddingBottom: '1em',
		[theme.breakpoints.down('lg')]: {
			paddingRight: '2em',
		},
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
	},
	gridWithBackgroundImage: {
		paddingLeft: 20,
		backgroundImage: `url(${require('../../../../Assets/Extensivo/Revalida/live-react-bg.png')})`,
		[theme.breakpoints.down('md')]: {
			paddingLeft: 'unset',
		},
	},
	paperDescription: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	gridTitle: {
		backgroundColor: '#000000',
		padding: '0px 25px',
		borderTopRightRadius: 20,
		borderTopLeftRadius: 20,
		[theme.breakpoints.down('sm')]: {
			padding: '0px 15px',
		},
	},
	ctaTextCard: {
		fontSize: '1.3rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
			fontWeight: '500 !important',
			margin: '5px 0 !important',
		},
	},
	textDateStyle: {
		borderTopLeftRadius: 20,
		borderBottomRightRadius: 20,
		width: '192px !important',
		[theme.breakpoints.down('sm')]: {
			width: '162px !important',
		},
	},
	logoReact: {
		height: 205,
		margin: '0 auto',
		[theme.breakpoints.down('xl')]: {
			display: 'none',
		},
	},
	logoReactDownXl: {
		display: 'none',
		[theme.breakpoints.down('xl')]: {
			display: 'block',
			height: '200px',
		},
	},
}));
