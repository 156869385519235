import React from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import { Header } from '../../../../Components/Header';

export default function YduqsPage() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					justifyContent={'space-between'}
					alignContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
				>
					<Grid
						item
						xs={5}
						container
						justifyContent={'center'}
					>
						<Grid
							item
							xs={5}
							style={{ backgroundColor: 'cyan', minWidth: '70%' }}
						></Grid>
					</Grid>
					<Grid
						item
						xs={7}
						container
						className={`${classes.titleContainer} residencia`}
						gap={1}
					>
						<Typography
							className={`${classes.title} roboto-condensed`}
							color="secondary"
						>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit
						</Typography>
						<Typography
							className={`${classes.subTitle} gradient-text roboto-condensed`}
							color="secondary"
						>
							Fusce leo metus, commodo
						</Typography>
						<Typography
							align="left"
							color="secondary"
							className={`${classes.text} poppins`}
						>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vestibulum, sapien quis
							tempor vehicula, felis leo accumsan odio, vitae placerat eros tortor sit amet eros. Nulla
							vitae lectus ipsum. Ut rutrum nisi eu justo porttitor.
							<br />
							<br />
							id auctor dolor rhoncus. Quisque diam augue, tempor at urna eget, rhoncus fermentum magna.
							Praesent iaculis orci et risus molestie, vel suscipit massa ultrices.
						</Typography>
						<Typography
							className={`${classes.subTitle} gradient-text roboto-condensed`}
							color="secondary"
						>
							Fusce condimentum cursos enim, eu
						</Typography>
						<Typography
							align="left"
							color="secondary"
							className={`${classes.text} poppins`}
						>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vestibulum, sapien quis
							tempor vehicula, felis leo accumsan odio, vitae placerat eros tortor sit amet eros. Nulla
							vitae lectus ipsum. Ut rutrum nisi eu justo porttitor.
							<br />
							<br />
						</Typography>
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '2.8rem !important',
		lineHeight: '1 !important',
		marginBottom: '1em !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '3.2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.8rem !important',
		},
	},
	subTitle: {
		fontWeight: 'bold !important',
		fontSize: '2.5rem !important',
		lineHeight: '1 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
		},
	},
	text: {
		fontSize: '1rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	bottomSignInCardText: {
		fontSize: '1.15rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
	},
	divider: {
		margin: 'unset !important',
		backgroundColor: 'grey',
		minHeight: 1,
	},
	workHourTitle: {
		fontSize: '1.7em !important',
		lineHeight: 1.25,
		fontWeight: 'bold !important',
	},
	containerWorkHours: {
		padding: '2em 0 2em 2em',
		[theme.breakpoints.down('lg')]: {
			padding: 0,
		},
	},
	workHourPaper: {
		height: '100%',
		[theme.breakpoints.down('lg')]: {
			height: 'unset !important',
		},
	},
	img: {
		marginTop: '1.5em',
		width: '100%',
	},
	impactText: {
		fontSize: '2.25rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.6rem !important',
		},
	},
	titleContainer: {
		paddingBottom: '1em',
		paddingRight: '6em',
		[theme.breakpoints.down('sm')]: {
			paddingRight: 0,
		},
	},
	formContainer: {
		paddingBottom: 50,
		paddingLeft: '4em',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: 0,
		},
	},
}));
