import React from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default function TestCard({ imageUrl }) {
	const classes = useStyles();

	return (
		<Grid
			container
			justifyContent="center"
			className={classes.root}
		>
			<img
				src={imageUrl}
				alt=""
				className={classes.img}
			/>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '90% !important',
		border: '1px solid transparent',
		height: 80,
		backgroundColor: '#333333',
		borderRadius: 20,
	},
	img: {
		width: '70%',
		[theme.breakpoints.down('sm')]: {
			width: '85%',
		},
	},
}));
