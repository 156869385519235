import React, { useEffect, useState } from 'react';
import { Container, Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';

// Components
import { Header } from '../../../../Components/Header';
import Footer from '../../../../Components/Footer';
import { makeStyles } from '@mui/styles';
import MockCard from './components/Mock-Card';
import { Helmet } from 'react-helmet';

import Api from '../../../../Services/Api';
import DefaultButton from '../../../../Components/Buttons/DefaultButton';
import { Link } from 'react-router-dom';

const ProductCard = ({ backgroundImg, logo, description, buttonText, urlRedirect }) => {
	const classes = useStyles();

	return (
		<Paper
			className={`${classes.scriptPaper} rounded-card`}
			color="secondary"
			elevation={4}
		>
			<Grid
				container
				justifyContent={'center'}
				alignContent={'center'}
				className={classes.gridImage}
				style={{ backgroundImage: `url(${backgroundImg})`, backgroundSize: 'cover' }}
			>
				<img
					src={logo}
					alt=""
					style={{ height: '100%' }}
				/>
			</Grid>
			<Grid
				container
				justifyContent={'center'}
				className={classes.gridDescriptionScriptCard}
			>
				<Typography
					align="left"
					color="primary"
					className={`${classes.scriptText} poppins`}
				>
					{description}
				</Typography>
			</Grid>
			<Grid
				item
				xs={12}
				container
				justifyContent={'flex-end'}
				className={`${classes.gridButton} residencia`}
			>
				<DefaultButton
					variant="outlined"
					color="primary"
					className={`${classes.btn} white-btn-secondary poppins`}
					component={Link}
					to={urlRedirect}
				>
					{buttonText}
				</DefaultButton>
			</Grid>
		</Paper>
	);
};

export default function DiscursiveMockUnicamp() {
	const classes = useStyles();
	const theme = useTheme();
	const [mockInfo, setMockInfo] = useState();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesXl = useMediaQuery(theme.breakpoints.down('xl'));

	useEffect(() => {
        var id_instituicao = 119;
		Api.get(`simuladosDiscursivas/${id_instituicao}`).then((res) => {
			if (res) {
				// console.log(res);
				setMockInfo(res);
			}
		});
	}, []);

	const products = [
		{
			backgroundImage: require('../../../LandingPages/FreeContent/DiscursiveMockUnicamp/Assets/bg-trilha-r1.png'),
			logo: require('../../../LandingPages/FreeContent/DiscursiveMockUnicamp/Assets/logo-trilha-r1.svg').default,
			description:
				'O TRILHA R1 é o novo Extensivo Hardwork. É a maior revolução da história de preparação para as provas de residência. Aqui te conduzimos na trilha do aprendizado definitivo. Dominamos tema por tema, te preparando para qualquer prova do Brasil.',
			buttonText: 'Inscrições',
			urlRedirect: '/trilha-r1-2024',
		},
		{
			backgroundImage: require('../../../../Assets/SprintFinal/bg-sf-product-card.png'),
			logo: require('../../../../Assets/SprintFinal/sprintFinalMobile.svg').default,
			description:
				'O Sprint te faz entrar na alma do concurso, na mente do examinador! É o primeiro e único modelo de revisão totalmente focado em cada uma das provas! Tudo que tu precisas na reta final de preparação.',
			buttonText: 'Inscrições',
			urlRedirect: '/sprint-final',
		},
		{
			backgroundImage: require('../../../../Assets/Extensivo/R1/2023/bg-ldl-card.png'),
			logo: require('../../../../Assets/Extensivo/R1/2023/liveDasLivesResidencia-product-card.svg').default,
			description:
				'A revisão de véspera que só o Hardwork consegue fazer! Uma multidão de hardworkers conectados num evento presencial que é uma grande experiência!',
			buttonText: 'Inscrições',
			urlRedirect: '/live-das-lives',
		},
	];

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			<Helmet>
				<title>Simulado Unicamp</title>
				<meta
					name="description"
					content={''}
				/>
			</Helmet>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className="residencia"
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					className="section"
					style={{ marginTop: matchesMobile ? 80 : null }}
				>
					<Grid
						item
						container
						direction={'column'}
						rowGap={1}
						style={{ borderBottom: '2px solid #4A4A4A', paddingBottom: '1rem' }}
					>
						<img
							src={
								matchesMobile
									? require('./Assets/logo-discursive-mock-unicamp-mobile.svg').default
									: require('./Assets/logo-discursive-mock-unicamp.svg').default
							}
							alt=""
							style={{ width: 'fit-content' }}
						/>
						{/* <Typography
							color="secondary"
							className="poppins"
							style={{ fontSize: matchesMobile ? '0.9rem' : null }}
						>
							Lorem Ipsum is simply dummy text of the printing and typesetting industry.
						</Typography> */}
					</Grid>
					<Grid
						item
						container
						rowGap={3}
						style={{ marginTop: '1.5rem' }}
					>
						{/* <MockCard mockInfo={mockInfo} /> */}
						{mockInfo?.map((mockTest, index) => {
							return <MockCard mockInfo={mockTest} />;
						})}
					</Grid>
				</Grid>
				<Grid
					container
					className="section"
				>
					<Typography
						className={`${classes.subtitle} roboto-condensed`}
						color="secondary"
					>
						Correção da prova discursiva às 19:00h
					</Typography>
					<iframe
						width={matchesXl ? 1000 : '1500'}
						height={matchesMobile ? '300' : matchesTablet ? '300' : matchesXl ? 562.5 : '843.75'}
						loading="lazy"
						src={'https://www.youtube.com/embed/W2GEVGue0rs'}
						title="Live stream video player"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media;"
						style={{ borderRadius: '20px', margin: '0 auto' }}
					></iframe>
				</Grid>
				<Grid
					container
					className="section"
					justifyContent={'space-evenly'}
				>
					{products.map((item, index) => {
						return (
							<Grid
								key={index}
								item
								xs={12}
								lg={3.5}
								container
							>
								<ProductCard
									backgroundImg={item?.backgroundImage}
									logo={item?.logo}
									description={item?.description}
									buttonText={item?.buttonText}
									urlRedirect={item?.urlRedirect}
								/>
							</Grid>
						);
					})}
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	gridInfomation: {
		backgroundColor: '#ffffffcc',
		width: 'fit-content !important',
		padding: '2.5px 1.5rem !important',
		borderRadius: 6,
		[theme.breakpoints.down('sm')]: {
			padding: '2.5px 1rem !important',
		},
	},
	infoPdfCard: {
		fontSize: '0.85rem !important',
		display: 'inline-flex !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.7rem !important',
		},
	},
	subtitle: {
		fontSize: '2.85rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		marginBottom: '1rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
			marginBottom: '0.5rem !important',
		},
	},
	scriptPaper: {
		height: '100%',
		[theme.breakpoints.down('xl')]: {
			minHeight: 'unset',
		},
		[theme.breakpoints.down('lg')]: {
			height: 'unset',
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
			width: 'unset',
			minHeight: 'unset',
			margin: '16px 0px',
		},
	},
	gridImage: {
		backgroundColor: '#000000 !important',
		borderTopRightRadius: 15,
		borderTopLeftRadius: 15,
		padding: '2rem',
		height: '50%',
	},
	gridDescriptionScriptCard: {
		backgroundColor: '#FFFFFF !important',
		padding: '1.25rem',
		borderRadius: 16,
		height: '50%',
		[theme.breakpoints.down('lg')]: {
			padding: '1em 1.5em',
		},
		[theme.breakpoints.down('md')]: {
			height: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '1em',
			borderTopRightRadius: 0,
			borderTopLeftRadius: 0,
		},
	},
	scriptText: {
		fontSize: '0.95rem !important',
		overflowY: 'auto',
		paddingRight: 20,
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
			maxHeight: 175,
			minHeight: 'unset !important',
		},
	},
	gridButton: {
		bottom: 25,
		left: '-5%',
		[theme.breakpoints.down('lg')]: {
			bottom: -17,
		},
		[theme.breakpoints.down('sm')]: {
			left: '-7%',
			bottom: -15,
		},
	},
	btn: {
		fontSize: '1rem !important',
		width: '50%',
		fontWeight: 'bold !important',
		'&:hover': {
			scale: 1.05,
			color: '#ffffff !important',
			'& $logoBtn': {
				filter: 'invert(0)',
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.75rem !important',
			minHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.75rem !important',
			width: '50%',
		},
	},
}));
