import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Container, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import { Header } from '../../../../Components/Header';

import Form from '../../../../Components/Utils/Forms/PreRegistration';
import { useParams } from 'react-router-dom';

export default function RaioXTemplateSubscription() {
	const { id } = useParams();
	const classes = useStyles();
	const theme = useTheme();
	const [test, setTest] = useState('');

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		const path = window.location.pathname;

		let lastPart;

		if (
			path.includes('psu-go') ||
			path.includes('psu-mg') ||
			path.includes('ses-df') ||
			path.includes('ses-pe') ||
			path.includes('sus-ba') ||
			path.includes('unesp-botucatu') ||
			path.includes('usp-rp')
		) {
			const parts = path.split('-');
			lastPart = parts[parts.length - 3] + '-' + parts[parts.length - 2];
		} else if (path.includes('santa-casa-sp')) {
			const parts = path.split('-');
			lastPart = parts[parts.length - 4] + ' ' + parts[parts.length - 3] + '-' + parts[parts.length - 2];
		} else {
			const parts = path.split('-');
			lastPart = parts[parts.length - 2];
		}
		setTest(lastPart);
	}, []);

	function adjustArticle(contestName) {
		if (
			contestName === 'amp' ||
			contestName === 'amrigs' ||
			contestName === 'usp' ||
			contestName === 'famema' ||
			contestName === 'ufes' ||
			contestName === 'uerj' ||
			contestName === 'ufrj' ||
			contestName === 'unicamp' ||
			contestName === 'unifesp' ||
			contestName === 'santa-casa-sp' ||
			contestName === 'unesp-botucatu'
		) {
			return 'da';
		} else {
			return 'do';
		}
	}

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					alignContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
				>
					<Grid
						item
						xs={12}
						md={7}
						container
						style={{
							paddingRight: matchesTablet ? null : '5rem',
							height: 'fit-content',
							marginBottom: matchesTablet ? '1rem' : null,
						}}
						rowGap={matchesTablet ? 1 : 2}
					>
						<img
							src={require('../../FreeContent/ContestCentral/Assets/logo-x-ray-tests.svg').default}
							alt=""
							style={matchesTablet ? { width: '75%' } : null}
						/>
						<Divider
							variant="middle"
							className={classes.divider}
						/>
						<Typography
							className={`${classes.title} roboto-condensed`}
							color="secondary"
							style={matchesTablet ? { marginTop: 16, paddingLeft: 0 } : null}
						>
							Confira o Raio-X {adjustArticle(test)} {test?.toUpperCase()}
						</Typography>
						<Typography
							className={`${classes.text} poppins`}
							color="secondary"
							style={matchesTablet ? { paddingLeft: 0 } : null}
						>
							Inscreva-se no formulário e tenha acesso ao Raio-X de provas {test?.toUpperCase()}, uma
							análise minuciosa das últimos anos de provas do concurso {test?.toUpperCase()}.
							<br />
							<br />
							Download gratuito!
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						md={5}
						container
						justifyContent="center"
						style={{ height: 'fit-content', alignSelf: 'flex-end' }}
					>
						<Form
							group={`DOWNLOAD RAIO X`}
							title={['Inscreva-se para ter acesso aos', <br />, 'Raio-X de prova']}
							titleButton="Confirmar inscrição"
							titleConfirmationMessage={'Massa!'}
							confirmationMessage={
								'Sua inscrição está confirmada! Em breve você também receberá uma confirmação pelo e-mail cadastrado.'
							}
							urlToRedirect={`${test.toUpperCase()}`}
							type="residencia"
							raioX={test}
							raioX_id={id}
						/>
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3rem !important',
		lineHeight: '1 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
		},
	},
	text: {
		fontSize: '1.324rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.95rem !important',
		},
	},
	divider: {
		margin: 'unset !important',
		backgroundColor: '#6D6D6D',
		minHeight: 1,
		width: '100%',
	},
}));
