import React from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Helmet } from 'react-helmet';
import DemoLogo from '../../Assets/demo-campina-grande.png';
import DefaultButton from '../../../../Components/Buttons/DefaultButton';

export default function DemoPage() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
			style={{
				backgroundImage: matchesMobile
					? `url(${require('../../Assets/bg-demo-mobile.png')})`
					: `url(${require('../../Assets/bg-trilha-r1.png')})`,
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
			}}
		>
			<Helmet>
				<title>Demo Campina Grande</title>
				<meta
					name="description"
					content="Nada supera o Hardwork!"
				/>
			</Helmet>
			<Container
				fixed
				// maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					container
					alignContent="center"
					justifyContent={'center'}
					className={`${classes.section}`}
					// style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
				>
					<Grid
						item
						container
						className={classes.popup}
						rowGap={2}
					>
						<img
							src={DemoLogo}
							alt=""
						/>
						<Typography className={`${classes.title} poppins`}>Presente Hardwork!</Typography>
						<Grid
							container
							justifyContent={'center'}
							alignItems={'center'}
						>
							<Typography
								className={`${classes.text} poppins`}
								align="center"
							>
								Aulas e estudo por questões de temas da emergência liberadas para
								<br />
								Campina Grande até 16/06.
							</Typography>
						</Grid>
						<DefaultButton
							variant="outlined"
							color="secondary"
							className="btn"
							href={'https://hardworkmedicina.com.br/cadastro?demo-trilha-1342'}
							style={{ backgroundColor: '#FF2126', width: 'fit-content', fontSize: '1.1rem' }}
						>
							Cadastrar-se gratuitamente para acessar
						</DefaultButton>
					</Grid>
				</Grid>
			</Container>
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		height: '100vh',
		// maxWidth: '1410px',
		// margin: '5% auto',
		display: 'flex',
	},
	popup: {
		backgroundColor: '#1B1B1B',
		height: 'fit-content',
		padding: '15px',
		width: '604px !important',
		justifyContent: 'center !important',
		alignItems: 'center',
		flexDirection: 'column !important',
		borderRadius: '8px',
	},
	title: {
		fontSize: '24px !important',
		color: '#FFF',
	},
	text: {
		fontSize: '1.1rem !important',
		lineHeight: '1.25 !important',
		alignItems: 'center !important',
		color: '#FFF',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
			marginBottom: '1rem !important',
		},
	},
}));
