import React from 'react';
import { Grid, Modal, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';

export default function GalleryImage({ src, title }) {
	const classes = useStyles();
	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const [selectedImageIndex, setSelectedImageIndex] = useState(0);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const handleOpen = (index) => {
		setSelectedImageIndex(index);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Grid className={classes.imagesContainer}>
			<Grid
				item
				xs={12}
				container
				// style={matchesMobile ? { marginBottom: 16 } : { left: '2em', marginTop: 16 }}
				className="revalida"
			>
				<Typography
					className={`${classes.subtitle1} roboto-condensed gradient-text`}
					style={{ marginBottom: '1rem !important' }}
					color="primary"
				>
					{title}
				</Typography>
			</Grid>
			<Grid
				container
				justifyContent={'space-between'}
				// style={{ padding: matchesMobile ? 'unset' : '1rem 2rem' }}
			>
				<Splide
					className={classes.splideMainContainer}
					options={matchesMobile && { arrows: false, autoplay: true, rewind: true }}
				>
					{!matchesMobile ? (
						<>
							<SplideSlide>
								<Grid
									item
									container
									justifyContent={'space-around'}
								>
									{src.map((item, index) => {
										if (item.id < 7) {
											return (
												<Grid
													item
													key={item.id}
													className={classes.gridImage}
													onClick={() => handleOpen(index)}
												>
													<img
														src={item.img}
														alt=""
														className={classes.image}
													/>
												</Grid>
											);
										} else {
											return null;
										}
									})}
								</Grid>
							</SplideSlide>
							<SplideSlide>
								<Grid
									item
									container
									justifyContent={'space-around'}
								>
									{src.map((item, index) => {
										if (item.id >= 7 && item.id < 13) {
											return (
												<Grid
													item
													key={item.id}
													className={classes.gridImage}
													onClick={() => handleOpen(index)}
												>
													<img
														src={item.img}
														alt=""
														className={classes.image}
													/>
												</Grid>
											);
										} else {
											return null;
										}
									})}
								</Grid>
							</SplideSlide>
							<SplideSlide>
								<Grid
									item
									container
									justifyContent={'space-around'}
								>
									{src.map((item, index) => {
										if (item.id >= 13 && item.id < 19) {
											return (
												<Grid
													item
													key={item.id}
													className={classes.gridImage}
													onClick={() => handleOpen(index)}
												>
													<img
														src={item.img}
														alt=""
														className={classes.image}
													/>
												</Grid>
											);
										} else {
											return null;
										}
									})}
								</Grid>
							</SplideSlide>
						</>
					) : (
						<>
							{src.map((item, index) => {
								return (
									<SplideSlide
										key={item.id}
										style={{ width: '100%', height: '100%' }}
									>
										<img
											src={item.img}
											alt=""
											style={{ borderRadius: 15 }}
										/>
									</SplideSlide>
								);
							})}
						</>
					)}
				</Splide>
			</Grid>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Grid
					style={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: '70%',
					}}
				>
					<Splide
						options={{
							arrows: true,
							start: selectedImageIndex,
							paginationKeyboard: true,
							keyboard: 'focused',
							accessibility: true,
						}}
						className={classes.splideContainer}
					>
						{src.map((item) => {
							return (
								<SplideSlide
									key={item.id}
									style={{ width: '100%', height: '100%' }}
								>
									<img
										src={item.img}
										alt=""
										style={{ width: '100%' }}
									/>
								</SplideSlide>
							);
						})}
					</Splide>
				</Grid>
			</Modal>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	imagesContainer: {
		backgroundColor: '#33333380',
		marginTop: 0,
		borderRadius: 15,
		maxWidth: '1410px',
		margin: '60px auto !important',
		padding: '1rem',
		[theme.breakpoints.down('sm')]: {
			padding: '2vh 5% 4vh !important',
		},
	},
	subtitle1: {
		fontSize: '2rem !important',
		fontWeight: 'bold !important',
		paddingLeft: '0.5rem !important',
		[theme.breakpoints.down('md')]: {
			lineHeight: 'unset !important',
			paddingLeft: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
			lineHeight: 'unset !important',
		},
	},
	gridImage: {
		borderRadius: 15,
		width: '31%',
		margin: '1rem 0 !important',
		// backgroundColor: '#ffffff !important',
	},
	image: {
		borderRadius: 15,
		// transition: 'ease-in-out 2s',
		'&:hover': {
			opacity: 0.85,
			cursor: 'pointer',
		},
	},
	splideMainContainer: {
		'& .splide__arrow': {
			width: '4em',
			height: '4em',
			marginTop: '23%',
		},
		'& .splide__arrow svg': {
			height: '2em',
			width: '2em',
		},
		'& .splide__pagination': {
			display: 'flex !important',
			bottom: '-0.5em !important',
		},
		'& .splide__track--draggable': {
			margin: '.5em 0 !important',
		},
		[theme.breakpoints.down('md')]: {
			'& .splide__slide': {
				border: '1px solid #33333380',
				borderRadius: 15,
			},
		},
		[theme.breakpoints.down('sm')]: {
			'& .splide__pagination': {
				bottom: '-1.25em !important',
			},
		},
	},
	splideContainer: {
		'& .splide__arrow': {
			width: '4em',
			height: '4em',
			marginTop: '30%',
		},
		'& .splide__arrow svg': {
			height: '2em',
			width: '2em',
		},
	},
}));
