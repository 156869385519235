import React, { useState } from 'react';
import {
	Button,
	Container,
	Divider,
	Grid,
	Menu,
	MenuItem,
	Paper,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { Header } from '../../../../Components/Header';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import DefaultButton from '../../../../Components/Buttons/DefaultButton';
import { useEffect } from 'react';
import Api from '../../../../Services/Api';
import { Link, useParams } from 'react-router-dom';
import TableSpecialtyAndInstitution from './components/Table-Specialty-Institution';
// import { isValid, format } from 'date-fns';
import { useRef } from 'react';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const headerRows = [
	'Especialidade/Instituição',
	'Vagas',
	'Inscritos',
	'Candidatos/Vaga',
	// 'Nota de corte 1ºfase',
	'Nota de corte',
];

const contestInfo = [
	{
		img: require('./Assets/sheetImage.svg').default,
		title: 'Edital',
		content: 'Saiba mais',
		button: true,
	},
	{
		img: require('./Assets/magnifyingGlass.svg').default,
		title: 'Período de Inscrições',
		button: false,
	},
	{
		img: require('./Assets/icon-exam-phases.svg').default,
		title: 'Fases do concurso',
	},
	// {
	// 	img: require('./Assets/icon-pdfExam.svg').default,
	// 	title: 'Caderno de Prova',
	// 	button: true,
	// },
	{
		img: require('./Assets/calendarExam.svg').default,
		title: 'Data 1º fase',
		button: false,
	},
];

const contestPhases = ['Objetiva', 'Prática', 'Entrevista', 'Discursiva', 'Multimídia', 'Currículo'];

const MaterialCard = ({ item, classes }) => {
	return (
		<Paper
			// className={`${classes.root} box box-primary saiba-mais-card border-hwm`}
			// style={disabled || !url ? { cursor: 'not-allowed', opacity: 0.5 } : null}
			elevation={4}
			// component={Link}
			// to={item.url_hwm}
			// target="_blank"
			className={classes.productCardRoot}
		>
			<Grid
				container
				justifyContent={'center'}
				alignContent={'center'}
				className={classes.gridImage}
				style={{
					backgroundImage: `url('${item.imagem}')`,
					backgroundSize: 'cover',
				}}
			></Grid>
			<Grid
				item
				container
				justifyContent={'center'}
				style={{
					padding: '2.5px 0',
					backgroundColor: '#ffffff',
					borderBottomRightRadius: 15,
					borderBottomLeftRadius: 15,
				}}
			>
				<Button
					className={`${classes.btnProductCard} poppins`}
					href={item?.url_link}
				>
					Saiba mais
				</Button>
			</Grid>
		</Paper>
	);
};

const ProductCard = ({ item, classes }) => {
	return (
		<Paper
			// className={`${classes.root} box box-primary saiba-mais-card border-hwm`}
			// style={disabled || !url ? { cursor: 'not-allowed', opacity: 0.5 } : null}
			elevation={4}
			// component={Link}
			// to={item.url_hwm}
			// target="_blank"
			className={classes.productCardRoot}
		>
			<Grid
				container
				justifyContent={'center'}
				alignContent={'center'}
				className={classes.gridImage}
				style={{
					backgroundImage:
						item?.produto === 'Trilha R1'
							? `url(${require(`./Assets/bg-ext-r1-product-card.png`)})`
							: item?.produto === 'Trilha R1 2 anos'
							? `url(${require(`./Assets/bg-ext-r1-2-anos.png`)})`
							: item?.produto === 'Live das Lives Residência'
							? `url(${require(`./Assets/bg-ldl-product-card.png`)})`
							: item?.produto === 'Trilha R3 Cirurgia Geral'
							? `url(${require(`./Assets/bg-r3-cg-product-card.png`)})`
							: item?.produto === 'Trilha R3 Clínica Médica'
							? `url(${require(`./Assets/bg-r3-cm-product-card.png`)})`
							: `url(${require(`./Assets/bg-product-card-sf.png`)})`,
					backgroundSize: 'cover',
				}}
			>
				<img
					src={
						item?.img_logo
						// require(item?.produto === 'Extensivo R1'
						// 	? `./Assets/logo-ext-r1-product-card.svg`
						// 	: item?.produto === 'Extensivo R1 2 anos'
						// 	? `./Assets/logo-ext-r1-2-anos.svg`
						// 	: `./Assets/logo-sf-wide.svg`).default
					}
					alt=""
					// style={{ width: '80%' }}
				/>
			</Grid>
			<Grid
				item
				container
				justifyContent={'center'}
				style={{
					padding: '2.5px 0',
					backgroundColor: '#ffffff',
					borderBottomRightRadius: 15,
					borderBottomLeftRadius: 15,
				}}
			>
				<Button
					className={`${classes.btnProductCard} poppins`}
					href={item?.url_hwm}
				>
					Saiba mais
				</Button>
			</Grid>
		</Paper>
	);
};

const ProductsContainer = ({ title, description, children, classes }) => {
	return (
		<Grid
			container
			className={`${classes.productsContainerRoot} residencia`}
		>
			<Grid
				item
				container
				direction={'column'}
				style={{ height: 'fit-content' }}
			>
				<Typography className={`${classes.titleProductsContainer} gradient-text poppins`}>{title}</Typography>
				<Typography
					color="secondary"
					className={`${classes.descriptionProductContainer} poppins`}
				>
					{description}
				</Typography>
			</Grid>
			<Grid
				item
				container
				// justifyContent={'space-between'}
				rowGap={2}
				// style={{ alignSelf: 'flex-end' }}
			>
				{children}
			</Grid>
		</Grid>
	);
};

const NoticeContainer = ({ classes, matchesMobile }) => {
	const { id } = useParams();
	const [preInscricao, setPreInscricao] = useState({
		id_concurso: `${id}`,
		nome: '',
		email: '',
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
	});

	let sendPreInscricao = function (event) {
		event.preventDefault();
		const nomeCompleto = preInscricao.nome.trim();
		const nomeSobrenome = nomeCompleto.split(' ');

		if (!preInscricao.nome || nomeSobrenome.length < 2) {
			alert('Ops... Por favor informe seu nome e sobrenome');
			return;
		}
		if (!preInscricao.email || !preInscricao.email.includes('@') || !preInscricao.email.includes('.')) {
			alert('Ops... Por favor preencha seu email');
			return;
		}

		trackPromise(
			Api.post('ConcursosCadastro', preInscricao).then((res) => {
				if (res) {
					Swal.fire({
						title: 'Massa!',
						text: 'Assim que tivermos novidades sobre este concurso, vamos te avisar pelo e-mail cadastrado.',
						icon: 'success',
						confirmButtonText: 'Fechar',
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then((result) => {
						if (result.isConfirmed) {
							setTimeout(() => window.location.reload(), 1000);
						}
					});
				}
			})
		);
	};

	return (
		<Grid
			container
			alignContent="center"
			justifyContent="center"
			className={classes.section}
			// style={{ marginTop: '4%' }}
		>
			<Grid
				item
				xs={12}
				container
				className={`${classes.container}`}
			>
				<Grid
					item
					xs={12}
					md={6}
					container
					className={classes.gridNewsletterText}
					gap={1}
				>
					<Grid
						item
						xs={12}
						container
						alignContent="center"
					>
						<Typography
							// className={`${classes.title} newsletter-title roboto-condensed`}
							className={`${classes.noticeContainerTitle} roboto-condensed`}
							color="secondary"
						>
							Quer receber atualizações {!matchesMobile && <br />} desta prova?
						</Typography>
					</Grid>
					<Grid
						item
						xs={11}
					>
						<Typography
							align="left"
							color="secondary"
							className={`${classes.noticeContainerText} poppins`}
						>
							Inscreva-se e te avisaremos das novidades.
						</Typography>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
					container
					className={classes.containerTextField}
				>
					<form
						style={{ width: '100%' }}
						className="form-control"
						onSubmit={sendPreInscricao}
					>
						<Grid
							item
							xs={12}
						>
							<TextField
								fullWidth
								placeholder="Seu nome completo"
								type="string"
								name="nome"
								className={classes.textField}
								required={true}
								InputProps={{
									className: classes.form,
								}}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										nome: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
						>
							<TextField
								fullWidth
								placeholder="Seu melhor e-mail"
								type="email"
								name="email"
								className={classes.textField}
								required={true}
								InputProps={{
									className: classes.form,
								}}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										email: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							container
							justifyContent={matchesMobile ? 'center' : 'flex-start'}
							className={classes.gridBtn}
						>
							<DefaultButton
								type={'submit'}
								variant="contained"
								color="primary"
								className={`${classes.btn} poppins`}
							>
								Inscrever-se grátis
							</DefaultButton>
						</Grid>
					</form>
				</Grid>
			</Grid>
		</Grid>
	);
};

const InfoBox = ({ imageUrl, title, content, button, totalVacancies, classes, matchesMobile }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleButtonClickEdital = (pdfUrl) => {
		const formattedPdfUrl = pdfUrl.replace('D:\\', 'https://');
		window.open(formattedPdfUrl, '_blank');
	};

	const handleButtonClickProva = () => {
		const pdfUrl = content.arquivo_prova;
		const formattedPdfUrl = pdfUrl.replace('D:\\', 'https://');
		window.open(formattedPdfUrl, '_blank');
	};

	let editalItems = [];
	let errataCount = 1;

	if (content && typeof content === 'object') {
		editalItems = Object.entries(content)
			.filter(([key, value]) => key.startsWith('arquivo_edital') && value)
			.map(([key, value], index) => {
				if (value.includes('EDITAL-RETIFICAÇÃO') || value.includes('EDITAL-ERRATA')) {
					const errataLabel = `Errata ${errataCount}`;
					errataCount++;
					return (
						<MenuItem
							key={key}
							onClick={() => handleButtonClickEdital(value)} // Passa a URL do edital como argumento
							className="poppins"
							style={{ fontWeight: 'bold' }}
						>
							{errataLabel}
						</MenuItem>
					);
				} else {
					const editalLabel = `Edital ${index + 1}`;
					return (
						<MenuItem
							key={key}
							onClick={() => handleButtonClickEdital(value)} // Passa a URL do edital como argumento
							className="poppins"
							style={{ fontWeight: 'bold' }}
						>
							{window.location.pathname === '/materiais/residencia-medica/scmsp-2385'
								? index === 1
									? 'Retificação Edital Concurso 2024'
									: 'Retificação Nº 01 - Edital de Abertura'
								: window.location.pathname === '/materiais/residencia-medica/usp-sp-2488'
								? index === 3
									? 'Resultado preliminar USP-SP 2024'
									: editalLabel
								: window.location.pathname.includes('residencia-medica-sms-sp-2425')
								? index === 2
									? 'COMUNICADO'
									: editalLabel
								: editalLabel}
						</MenuItem>
					);
				}
			});
	}

	return (
		<Grid
			item
			container
			justifyContent={'center'}
			className={classes.infoBoxContainer}
		>
			<Grid
				item
				xs={2}
				md={3}
				container
				justifyContent={'center'}
				alignContent={'center'}
				className={classes.gridImgInfoBox}
			>
				<img
					src={imageUrl}
					alt=""
					className={classes.imgInfoBox}
				/>
			</Grid>
			<Grid
				item
				xs={10}
				md={9}
				container
				direction={title === 'Fases do concurso' ? 'row' : 'column'}
				justifyContent={title !== 'Fases do concurso' ? 'center' : 'flex-start'}
				gap={
					button ||
					(title === 'Período de Inscrições' &&
						content?.data_inscricoes_de !== 'Aguarda edital' &&
						!matchesMobile)
						? 0
						: 1
				}
				className={classes.gridTitleInfoBox}
			>
				<Typography
					align="left"
					color="secondary"
					className={`${classes.titleInfoBox} poppins`}
				>
					{title}
				</Typography>
				{button ? (
					<>
						{title === 'Caderno de Prova' ? (
							<DefaultButton
								color={'primary'}
								className={`${classes.btnTitle} poppins`}
								onClick={!content?.arquivo_prova ? null : handleButtonClickProva}
								style={
									!content?.arquivo_prova
										? {
												cursor: 'auto',
												fontSize: matchesMobile ? '0.7rem' : '0.9rem',
												fontStyle: 'italic',
												color: '#9f9f9f',
												backgroundColor: '#312d2d',
										  }
										: {
												fontWeight: 'bold',
												fontSize: matchesMobile ? '0.8rem' : '0.9rem',
												backgroundColor: '#ffffff',
										  }
								}
							>
								{content?.arquivo_prova ? 'Acesse' : 'Aguarda edital'}
							</DefaultButton>
						) : (
							<>
								<DefaultButton
									color={'primary'}
									className={`${classes.btnTitle} poppins`}
									onClick={
										content?.link_edital
											? () => handleButtonClickEdital(content?.link_edital)
											: editalItems?.length
											? editalItems?.length < 2
												? () => handleButtonClickEdital(content?.arquivo_edital_1)
												: handleClick
											: null
									}
									style={
										!content?.arquivo_edital_1 && !content?.link_edital
											? {
													cursor: 'auto',
													fontSize: matchesMobile ? '0.7rem' : '0.9rem',
													fontStyle: 'italic',
													color: '#9f9f9f',
													backgroundColor: '#312d2d',
											  }
											: {
													fontWeight: 'bold',
													fontSize: matchesMobile ? '0.8rem' : '0.9rem',
													backgroundColor: '#ffffff',
											  }
									}
									endIcon={editalItems?.length < 2 ? null : <KeyboardArrowDownIcon />}
								>
									{editalItems?.length || content?.link_edital ? 'Acesse' : 'Aguarda edital'}
								</DefaultButton>
								<Menu
									id="basic-menu"
									anchorEl={anchorEl}
									open={open}
									onClose={handleClose}
									MenuListProps={{
										'aria-labelledby': 'basic-button',
									}}
								>
									{editalItems}
								</Menu>
							</>
						)}{' '}
					</>
				) : (
					<>
						{title === 'Período de Inscrições' ? (
							content?.data_inscricoes_ate !== 'Aguarda edital' ? (
								<Typography
									align="left"
									color={
										content?.data_inscricoes_ate === 'Aguarda edital'
											? '#9f9f9f !important'
											: 'secondary'
									}
									className={`${classes.contentInfoBox} poppins`}
									style={{
										fontSize: matchesMobile ? '0.8rem' : '1rem',
										fontStyle: content?.data_inscricoes_de === 'Aguarda edital' ? 'italic' : null,
									}}
								>
									De: <strong>{dateFormat(content?.data_inscricoes_de)}</strong>
									{matchesMobile ? ' ' : <br />}
									Até: <strong>{dateFormat(content?.data_inscricoes_ate)}</strong>
								</Typography>
							) : (
								<Typography
									align="left"
									color={
										content?.data_inscricoes_de === 'Aguarda edital'
											? '#9f9f9f !important'
											: 'secondary'
									}
									className={`${classes.contentInfoBox} poppins`}
									style={
										content?.data_inscricoes_de === 'Aguarda edital'
											? {
													fontStyle: 'italic',
													fontSize: matchesMobile ? '0.8rem' : '0.9rem',
											  }
											: { fontSize: '1rem !important' }
									}
								>
									{content?.data_inscricoes_de === 'Aguarda edital'
										? content.data_inscricoes_de
										: dateFormat(content?.data_inscricoes_de)}
								</Typography>
							)
						) : null}
						{content?.data_prova_objetiva !== 'Aguarda edital' ? (
							<>
								{content?.data_prova_fase_1 === 'Aguarda edital' &&
								title !== 'Período de Inscrições' ? (
									<Typography
										align="left"
										color="secondary"
										className={` poppins`}
										style={{
											fontStyle: 'italic',
											fontSize: matchesMobile ? '0.8rem' : '0.9rem',
											color: '#9f9f9f',
										}}
									>
										Aguarda edital
									</Typography>
								) : (
									<strong
										className={`${classes.contentInfoBox} poppins`}
										style={{ fontSize: matchesMobile ? '0.8rem' : '1rem', color: '#ffffff' }}
									>
										{title === 'Data 1º fase'
											? content?.data_prova_objetiva
												? content?.data_prova_objetiva === 'Aguarda edital'
													? content?.data_prova_objetiva
													: dateFormat(content?.data_prova_objetiva)
												: content?.data_prova_fase_1
												? content?.data_prova_fase_1 === 'Aguarda edital'
													? content?.data_prova_fase_1
													: dateFormat(content?.data_prova_fase_1)
												: 'Aguarda edital'
											: null}
									</strong>
								)}
							</>
						) : (
							<>
								{title !== 'Fases do concurso' && (
									<>
										{title === 'Período de Inscrições' ? null : (
											<Typography
												align="left"
												color="secondary"
												className={` poppins`}
												style={{
													fontStyle: 'italic',
													fontSize: matchesMobile ? '0.8rem' : '0.9rem',
													color: '#9f9f9f',
												}}
											>
												Aguarda edital
											</Typography>
										)}
									</>
								)}
							</>
						)}
						{content?.fases?.length > 0 && title === 'Fases do concurso' ? (
							<Grid
								item
								xs={12}
								container
								justifyContent={'space-around'}
								gap={1}
								// style={{ marginTop: 5 }}
							>
								{contestPhases.map((name, index) => (
									<Grid
										item
										container
										justifyContent={'center'}
										alignContent={'center'}
										key={index}
										style={{
											backgroundColor:
												content?.fases?.includes(name) ||
												(content?.fases?.includes('Análise de Currículo') &&
													name === 'Currículo')
													? '#ffffff'
													: '#555555',
											padding: '0 0.5rem',
											height: 'fit-content',
											borderRadius: 5,
											width: '30%',
										}}
									>
										<Typography
											color={content?.fases?.includes(name) ? 'primary' : '#312f2f'}
											// className="poppins"
											style={{ fontSize: '0.625rem', fontWeight: 'bold' }}
										>
											{name.toUpperCase()}
										</Typography>
									</Grid>
								))}
							</Grid>
						) : null}
					</>
				)}
			</Grid>
		</Grid>
	);
};

export function dateFormat(data) {
	if (data) {
		const dataObjeto = new Date(data);
		const dia = String(dataObjeto.getDate()).padStart(2, '0');
		const mes = String(dataObjeto.getMonth() + 1).padStart(2, '0'); // Adicionar +1 pois o mês começa em zero (janeiro é 0)
		const ano = dataObjeto.getFullYear();
		return `${dia}/${mes}/${ano}`;
	} else {
		return null;
	}
}

export function generateAcronym(contestData) {
	// Divide o nome em palavras separadas por espaços
	const words = contestData?.nome.split(' ');

	// Obtém a primeira palavra do nome
	const firstWord = words[0];

	// Converte a primeira palavra para minúscula
	const acronym = firstWord.toLowerCase();

	// Retorna a URL formatada com a sigla em minúscula
	return acronym;
}

export default function ContestTemplate() {
	const { id, concurso } = useParams();
	const classes = useStyles();
	const theme = useTheme();
	const follow_up_btn = useRef(null);
	const [contestData, setContestData] = useState(null);
    console.log('DATA', contestData);
	const [totalVacancies, setTotalVacancies] = useState([]);
	const [currentContest, setCurrentContest] = useState(true);
	const [products, setProducts] = useState([]);
	const [materials, setMaterials] = useState([]);

	useEffect(() => {
		const endpoint = currentContest ? '/concursos' : '/concursosEncerrados';
		Api.get(`/${endpoint}/${id}`).then((res) => {
			if (endpoint === '/concursos') {
				setContestData(res);
				setProducts(res.produtos);
				setMaterials(res.materiais);
			} else {
				if (res.length > 0) {
					setContestData(res[0]);
					setProducts(res[0].produtos);
					setMaterials(res[0].materiais);
				} else {
					return null;
				}
			}
		});
	}, [id, currentContest]);

	useEffect(() => {
		calculateTotalVacancies();
	});

	// Função para calcular a soma das quantidades de vagas
	const calculateTotalVacancies = () => {
		let sum = 0;

		for (let i = 0; i < contestData?.especialidades_instituicoes?.length; i++) {
			const instituicao = contestData?.especialidades_instituicoes[i];

			sum += instituicao?.qtd_vagas;
		}

		setTotalVacancies(sum);
	};

	const executeScroll = () => follow_up_btn.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

	const showCurrentContest = () => {
		setCurrentContest(true);
	};

	const showPastContest = () => {
		setCurrentContest(false);
	};

	// const handleDownloadPdf = (id_concurso) => {
	// 	const pdfPath = `https://hardworkmedicina.com.br/app_uploads/public/concursos_raiox/raio-x-provas-impressao-concurso-${id_concurso}.pdf`; // Caminho para o seu arquivo PDF
	// 	const link = document.createElement('a');
	// 	link.href = pdfPath;
	// 	link.target = '_blank';
	// 	//link.download = `raio-x-provas-impressao-concurso-${id_concurso}.pdf`; // Especifique o nome do arquivo para download
	// 	link.click();
	// };

	const handleDownloadResources = (pdfUrl) => {
		const formattedPdfUrl = pdfUrl.replace('D:\\', 'https://');
		window.open(formattedPdfUrl, '_blank');
	};

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
        <>
        <Helmet>
            <title>{`Concurso Residência Médica ${contestData?.nome}`}</title>
            <meta
                name="description"
                content={contestData?.descricao1_pg ? contestData.descricao1_pg : ''}
            />
            <meta
                name="keywords"
                content='residencia, residência, concurso, edital, inscrição, medicina, médica'
            />
        </Helmet>
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					className="section"
					justifyContent={matchesTablet ? 'center' : 'flex-end'}
					style={matchesMobile ? { marginTop: 80 } : { marginTop: 20 }}
				>
					<Grid
						item
						xs={12}
						container
						justifyContent={'space-between'}
					>
						{matchesMobile && (
							<img
								src={require('./Assets/logo-contest-central.svg').default}
								alt=""
								className={classes.img}
							/>
						)}
						<DefaultButton
							variant="outlined"
							color={'secondary'}
							className={`${classes.goBackButton} poppins`}
							component={Link}
							to="/materiais/central-de-concursos"
						>
							Voltar
						</DefaultButton>
					</Grid>
					<Grid
						item
						xs={12}
						container
						gap={matchesMobile ? 2 : 3}
						style={matchesMobile ? { marginTop: '1rem' } : null}
					>
						<Grid
							item
							xs={12}
							container
							justifyContent={matchesMobile ? 'center' : 'space-between'}
							gap={matchesMobile ? 1 : 0}
						>
							{!matchesMobile && (
								<Grid
									item
									xs={12}
									md={4}
									container
								>
									<img
										src={require('./Assets/logo-contest-central.svg').default}
										alt=""
										className={classes.img}
									/>
								</Grid>
							)}
							{/* {!matchesMobile && (
								<Grid
									item
									xs={12}
									md={3}
									container
									className={'residencia'}
									justifyContent={'center'}
									gap={1}
									style={{
										height: 'fit-content',
										alignSelf: 'flex-end',
										padding: '1rem 1rem',
										backgroundColor: '#33333380',
										borderRadius: 15,
									}}
								>
									<Typography
										color="secondary"
										align="center"
										className={`${classes.followUpBtnText} poppins`}
									>
										Você está visualizando
										<br />
										os dados da <strong>edição {currentContest ? 'atual' : 'anterior'}.</strong>
									</Typography>
									<Grid
										item
										container
										justifyContent={'center'}
										className="residencia"
									>
										<DefaultButton
											variant={'outlined'}
											color={'secondary'}
											className={`${classes.contestsBtn} btn-secondary poppins`}
											onClick={() => (currentContest ? showPastContest() : showCurrentContest())}
										>
											{currentContest
												? 'Ver dados da edição anterior'
												: 'Ver dados da edição atual'}
										</DefaultButton>
									</Grid>
								</Grid>
							)} */}
						</Grid>
						<Grid
							item
							xs={12}
							container
							justifyContent={'center'}
						>
							<Grid
								item
								xs={12}
								container
							>
								<Grid
									item
									xs={5}
									md={3}
									lg={4}
									container
									justifyContent={'center'}
									alignContent={'center'}
									style={{
										padding: '0.5rem 0',
										borderTopLeftRadius: 5,
										position: 'relative',
										cursor: 'pointer',
										zIndex: currentContest ? 4 : 2,
										backgroundColor: currentContest ? null : '#3A3A3A',
										height: currentContest ? '100%' : '90%',
										alignSelf: 'flex-end',
									}}
									className={currentContest ? 'background-central-concursos-vertical' : null}
									onClick={() => (currentContest ? showPastContest() : showCurrentContest())}
								>
									<Typography
										align="center"
										color={currentContest ? 'secondary' : '#BEBEBE'}
										className={`${
											currentContest ? classes.boldEditionText : classes.regularEditionText
										} poppins`}
										style={{ left: currentContest ? 10 : 0 }}
									>
										{currentContest ? 'Edição atual' : 'Ver edição atual'}
									</Typography>
									<div
										className={currentContest ? 'background-central-concursos-vertical' : null}
										style={{
											content: '',
											position: 'absolute',
											top: 0,
											left: 7,
											width: '100%',
											height: '100%',
											transform: 'skew(225deg)',
											transformOrigin: 'top right',
											zIndex: currentContest ? 3 : 1,
											borderTopRightRadius: 5,
											background: !currentContest ? '#3A3A3A' : null,
										}}
									></div>
								</Grid>
								<Grid
									item
									xs={4}
									md={2}
									lg={3}
									container
									justifyContent={'center'}
									alignContent={'center'}
									className={`${classes.gridPastEdition} ${
										!currentContest ? 'background-central-concursos-vertical' : null
									}`}
									style={{
										backgroundColor: !currentContest ? null : '#3A3A3A',
										zIndex: !currentContest ? 2 : 3,
										height: !currentContest ? '100%' : '90%',
									}}
									onClick={() => (currentContest ? showPastContest() : showCurrentContest())}
								>
									<Typography
										align="center"
										color={currentContest ? '#BEBEBE' : 'secondary'}
										className={`${
											!currentContest ? classes.boldEditionText : classes.regularEditionText
										} poppins`}
										style={{ right: 10 }}
									>
										{currentContest ? 'Ver edição anterior' : 'Edição anterior'}
									</Typography>
									<div
										className={!currentContest ? 'background-central-concursos-vertical' : null}
										style={{
											content: '',
											position: 'absolute',
											top: 0,
											left: -7,
											width: '100%',
											height: '100%',
											background: !currentContest ? null : '#3A3A3A',
											transform: 'skew(-45deg)',
											transformOrigin: 'top left',
											zIndex: !currentContest ? 1 : 2,
											borderTopLeftRadius: 5,
										}}
									></div>
								</Grid>
							</Grid>
							<Grid
								item
								xs={12}
								container
								style={{ height: 5, borderTopRightRadius: 15, backgroundColor: '#FF357D' }}
							></Grid>
							<Grid
								item
								xs={12}
								container
								className={classes.gridTitle}
								style={{ padding: '1rem 2rem' }}
							>
								<Grid
									item
									xs={12}
									md={contestData?.status_sprint ? 8 : 12}
									container
									direction={'column'}
								>
									<Typography
										color="secondary"
										className={`${classes.title} poppins`}
									>
										{contestData?.nome}
									</Typography>
									{contestData?.headline && (
										<Typography
											color="secondary"
											className={`${classes.subtitle} poppins`}
										>
											{contestData?.headline}*
										</Typography>
									)}
									<Divider
										variant="middle"
										style={{
											backgroundColor: '#FFFFFF',
											margin: matchesTablet ? '0.5rem 0' : '1rem 0',
											width: '100%',
											minHeight: 1,
										}}
									/>
									<Grid
										item
										container
										direction={'row'}
										alignItems={'center'}
										justifyContent={matchesMobile ? 'center' : 'flex-start'}
										columnGap={2}
										rowGap={matchesTablet ? 1 : 0}
										style={matchesMobile ? { margin: '0.5rem 0' } : null}
									>
										<DefaultButton
											variant="contained"
											color={'primary'}
											className={`${classes.followUpBtn} poppins`}
											onClick={() => executeScroll()}
										>
											<NotificationsActiveIcon /> &nbsp;Notifique-me sobre esta prova
										</DefaultButton>
									</Grid>
								</Grid>
								{contestData?.status_sprint ? (
									<Grid
										item
										xs={4}
										container
										justifyContent={'center'}
										style={
											matchesMobile ? { display: 'none' } : { top: -15, height: 'fit-content' }
										}
										gap={3}
									>
										<Grid
											item
											container
											direction={'column'}
											justifyContent={'space-around'}
											className={classes.gridSprintFinalFlag}
										>
											<Grid
												item
												container
											>
												<Grid
													item
													container
													direction={'column'}
													className={classes.gridInnerSprintFinalFlag}
													component={Link}
													wrap="nowrap"
													to="/sprint-final"
												>
													<Typography
														color="primary"
														align="center"
														style={{ fontSize: '0.7rem', fontWeight: 'bold' }}
														className="poppins"
													>
														Esta prova tem
													</Typography>
													<Grid
														item
														xs={12}
														container
														alignContent={'center'}
														style={{ minHeight: 70, width: 100 }}
													>
														<img
															src={require('./Assets/logo-sf-final.svg').default}
															alt=""
															style={{ marginBottom: 8 }}
														/>
													</Grid>
													<Typography
														color="primary"
														align="center"
														style={{
															fontSize: '0.8rem',
															fontWeight: 'bold',
															textDecoration: 'underline',
														}}
														className="poppins"
													>
														Ver detalhes
													</Typography>
												</Grid>
											</Grid>
										</Grid>
										{/* {(window.location.pathname === '/materiais/residencia-medica-enare-2163' ||
											window.location.pathname === '/materiais/residencia-medica-surce-2447') && (
											<>
												<Grid
													item
													container
													direction={'column'}
													justifyContent={'space-around'}
													className={classes.gridSprintFinalFlag}
												>
													<Grid
														item
														container
													>
														<Grid
															item
															container
															direction={'column'}
															className={classes.gridInnerSprintFinalFlag}
															wrap="nowrap"
															component={Link}
															to={
																window.location.pathname ===
																'/materiais/residencia-medica-enare-2163'
																	? '/live-das-lives-enare'
																	: '/live-das-lives-surce'
															}
														>
															<Typography
																color="primary"
																align="center"
																style={{ fontSize: '0.7rem', fontWeight: 'bold' }}
																className="poppins"
															>
																Esta prova tem
															</Typography>
															<Grid
																item
																xs={12}
																container
																alignContent={'center'}
																style={{ minHeight: 70, width: 100 }}
															>
																<img
																	src={require('./Assets/logo-ldl-tag.svg').default}
																	alt=""
																	style={{ marginBottom: 8 }}
																/>
															</Grid>
															<Typography
																color="primary"
																align="center"
																style={{
																	fontSize: '0.8rem',
																	fontWeight: 'bold',
																	textDecoration: 'underline',
																}}
																className="poppins"
															>
																Ver detalhes
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid
													item
													container
													direction={'column'}
													justifyContent={'space-around'}
													className={classes.gridSprintFinalFlag}
												>
													<Grid
														item
														container
													>
														<Grid
															item
															container
															direction={'column'}
															className={classes.gridInnerSprintFinalFlag}
															wrap="nowrap"
															component={Link}
															to={`/download-raio-x-${depara(concurso)}-${id}`}
														>
															<Typography
																color="primary"
																align="center"
																style={{ fontSize: '0.7rem', fontWeight: 'bold' }}
																className="poppins"
															>
																Esta prova tem
															</Typography>
															<Grid
																item
																xs={12}
																container
																alignContent={'center'}
																style={{ minHeight: 70, width: 100 }}
															>
																<img
																	src={
																		require('./Assets/logo-raio-x-flag.svg').default
																	}
																	alt=""
																	style={{ marginBottom: 8 }}
																/>
															</Grid>
															<Typography
																color="primary"
																align="center"
																style={{
																	fontSize: '0.8rem',
																	fontWeight: 'bold',
																	textDecoration: 'underline',
																}}
																className="poppins"
															>
																Ver detalhes
															</Typography>
														</Grid>
													</Grid>
												</Grid>
											</>
										)} */}
										{contestData?.pdf_recursos && (
											<Grid
												item
												container
												direction={'column'}
												justifyContent={'space-around'}
												className={classes.gridSprintFinalFlag}
												onClick={() => handleDownloadResources(contestData?.pdf_recursos)}
											>
												<Grid
													item
													container
												>
													<Grid
														item
														container
														direction={'column'}
														className={classes.gridInnerSprintFinalFlag}
														wrap="nowrap"
													>
														<Typography
															color="primary"
															align="center"
															style={{ fontSize: '0.7rem', fontWeight: 'bold' }}
															className="poppins"
														>
															Esta prova tem
														</Typography>
														<Grid
															item
															xs={12}
															container
															alignContent={'center'}
															style={{ minHeight: 70, width: 100 }}
														>
															<img
																src={
																	require('./Assets/logo-download-de-recursos.svg')
																		.default
																}
																alt=""
																style={{ marginBottom: 8 }}
															/>
														</Grid>
														<Typography
															color="primary"
															align="center"
															style={{
																fontSize: '0.8rem',
																fontWeight: 'bold',
																textDecoration: 'underline',
															}}
															className="poppins"
														>
															Ver detalhes
														</Typography>
													</Grid>
												</Grid>
											</Grid>
										)}
										{/* <Grid
											item
											container
											alignContent={'center'}
											style={{
												width: 'fit-content',
											}}
										>
											<a
												href="https://api.whatsapp.com/send?phone=5511932443698"
												// FALTA
												// O
												// GRUPO
												target="_blank"
												rel="noopener noreferrer"
												style={{
													margin: '0 auto',
													display: 'flex',
													flexDirection: 'column',
													alignItems: 'center',
												}}
											>
												<img
													src={require('./Assets/logo-wpp.svg').default}
													alt=""
													style={{ cursor: 'pointer', width: '60%', marginBottom: 5 }}
												/>
												<Typography
													color="secondary"
													align="center"
													style={{
														fontSize: '0.75rem',
														fontWeight: 'bold',
														lineHeight: 'unset',
													}}
													className="poppins"
												>
													Acessar
													<br />
													grupo
												</Typography>
											</a>
										</Grid> */}
									</Grid>
								) : null}
							</Grid>
							<>
								{matchesMobile && contestData?.status_sprint ? (
									contestData?.pdf_recursos ? (
										<Grid
											item
											xs={12}
											container
											justifyContent={'center'}
											columnGap={1}
										>
											<Grid
												item
												xs={contestData?.pdf_recursos ? 5 : 12}
												container
												justifyContent={'center'}
												style={{
													padding: '0.25rem 0px 0.35rem',
													backgroundColor: '#e6e5e5',
													borderBottomLeftRadius: 15,
													borderBottomRightRadius: 15,
													textDecoration: 'none',
												}}
											>
												<Grid
													item
													xs={12}
													container
													direction={'column'}
													component={Link}
													to="/sprint-final"
													style={{ alignSelf: 'center', textDecoration: 'none' }}
												>
													<img
														src={require('./Assets/logo-sf-wide.svg').default}
														alt=""
														style={{ width: '80%', alignSelf: 'center' }}
													/>
												</Grid>
											</Grid>
											{contestData?.pdf_recursos && (
												<Grid
													item
													xs={5}
													container
													justifyContent={'center'}
													onClick={() => handleDownloadResources(contestData?.pdf_recursos)}
													style={{
														padding: '0.25rem 0px 0.35rem',
														backgroundColor: '#e6e5e5',
														borderBottomLeftRadius: 15,
														borderBottomRightRadius: 15,
														textDecoration: 'none',
													}}
												>
													<Grid
														item
														xs={12}
														container
														direction={'column'}
														style={{ alignSelf: 'center', textDecoration: 'none' }}
													>
														<img
															src={
																require('./Assets/logo-download-de-recursos.svg')
																	.default
															}
															alt=""
															style={{ width: '75%', alignSelf: 'center' }}
														/>
													</Grid>
												</Grid>
											)}
											{/* <Grid
												item
												xs={3.5}
												container
												justifyContent={'center'}
												style={{
													padding: '0.25rem 0px 0.35rem',
													backgroundColor: '#e6e5e5',
													borderBottomLeftRadius: 15,
													borderBottomRightRadius: 15,
													textDecoration: 'none',
												}}
											>
												<Grid
													item
													xs={12}
													container
													direction={'column'}
													component={Link}
													to={
														window.location.pathname ===
														'/materiais/residencia-medica-enare-2163'
															? '/live-das-lives-enare'
															: '/live-das-lives-surce'
													}
													style={{ alignSelf: 'center', textDecoration: 'none' }}
												>
													<img
														src={require('./Assets/logo-ldl-tag.svg').default}
														alt=""
														style={{ width: '75%', alignSelf: 'center' }}
													/>
												</Grid>
											</Grid>
											<Grid
												item
												xs={3.5}
												container
												justifyContent={'center'}
												style={{
													padding: '0.25rem 0px 0.35rem',
													backgroundColor: '#e6e5e5',
													borderBottomLeftRadius: 15,
													borderBottomRightRadius: 15,
													textDecoration: 'none',
												}}
											>
												<Grid
													item
													xs={12}
													container
													direction={'column'}
													component={Link}
													to={`/download-raio-x-${depara(concurso)}-${id}`}
													style={{ alignSelf: 'center', textDecoration: 'none' }}
												>
													<img
														src={require('./Assets/logo-raio-x-flag.svg').default}
														alt=""
														style={{ width: '60%', alignSelf: 'center' }}
													/>
												</Grid>
											</Grid> */}
										</Grid>
									) : (
										<Grid
											item
											xs={10}
											container
											// direction={'column'}
											justifyContent={'center'}
											style={{
												padding: '0.25rem 0px 0.35rem',
												backgroundColor: '#e6e5e5',
												borderBottomLeftRadius: 15,
												borderBottomRightRadius: 15,
												textDecoration: 'none',
											}}
											// gap={1}
										>
											<Grid
												item
												xs={9}
												container
												direction={'column'}
												component={Link}
												to="/sprint-final"
												style={{ alignSelf: 'center', textDecoration: 'none' }}
												gap={0.5}
											>
												<Typography
													color="primary"
													align="center"
													style={{
														fontSize: '0.7rem',
														fontWeight: 'bold',
													}}
													className="poppins"
												>
													Esta prova possui
												</Typography>
												<img
													src={require('./Assets/logo-sf-wide.svg').default}
													alt=""
													style={{ width: '60%', alignSelf: 'center' }}
												/>
												<Typography
													color="primary"
													align="center"
													style={{
														fontSize: '0.7rem',
														fontWeight: 'bold',
														textDecoration: 'underline',
													}}
													className="poppins"
												>
													Ver detalhes
												</Typography>
											</Grid>
											{/* <Grid
											item
											xs={3}
											container
											alignContent={'center'}
										>
											<a
												href="https://api.whatsapp.com/send?phone=5511932443698"
												// FALTA
												// O
												// GRUPO
												target="_blank"
												rel="noopener noreferrer"
											>
												<img
													src={require('./Assets/logo-wpp.svg').default}
													alt=""
													style={{ cursor: 'pointer', width: '70%', filter: 'invert(0.78)' }}
												/>
											</a>
										</Grid> */}
										</Grid>
									)
								) : null}
							</>
						</Grid>

						<Grid
							item
							xs={12}
							container
							rowGap={matchesMobile ? 2 : null}
						>
							{contestInfo.map((info, index) => {
								return (
									<Grid
										item
										xs={12}
										md={3}
										container
									>
										<InfoBox
											key={index}
											imageUrl={info.img}
											title={info.title}
											content={contestData}
											totalVacancies={totalVacancies}
											button={info.button}
											classes={classes}
											matchesMobile={matchesMobile}
										/>
									</Grid>
								);
							})}
						</Grid>
						{!contestData?.titulo1_pg ? null : (
							<Grid
								item
								container
								direction={'column'}
								className={classes.marginTop2rem}
								gap={1}
							>
								<Typography
									color="secondary"
									className={`${classes.titleH2} poppins`}
								>
									{contestData?.titulo1_pg ? contestData.titulo1_pg : null}
								</Typography>
								<Typography
									color="secondary"
									className={`${classes.text} poppins`}
								>
									{contestData?.descricao1_pg
										? contestData.descricao1_pg.map((item, index) =>
												item !== '' ? (
													<React.Fragment key={index}>
														{item}
														<br />
													</React.Fragment>
												) : (
													<br key={index} />
												)
										  )
										: null}
								</Typography>
							</Grid>
						)}
						<Grid
							item
							container
							direction={'column'}
							style={{
								backgroundColor: '#33333380',
								padding: `1rem ${matchesMobile ? '1rem' : '1.25rem'}`,
								borderRadius: 15,
							}}
							gap={1}
						>
							<Typography
								color="secondary"
								className={`${classes.infoText} poppins`}
								// style={!contestData.data_inscricoes_de ? { display: 'none' } : null}
							>
								<span className="bullet"> &#8226; </span>&nbsp;
								<strong style={{ color: '#ffffff', fontWeight: 600 }}>Período de Inscrições</strong>
								{matchesMobile ? <br /> : null}
								{contestData?.data_inscricoes_de === 'Aguarda edital'
									? ` - ${contestData?.data_inscricoes_de}`
									: contestData?.data_inscricoes_ate !== 'Aguarda edital'
									? ` - ${dateFormat(contestData?.data_inscricoes_de)} à ${dateFormat(
											contestData?.data_inscricoes_ate
									  )}`
									: ` - ${dateFormat(contestData?.data_inscricoes_de)}`}
							</Typography>
							{contestData?.taxa !== 0 && (
								<Typography
									color="secondary"
									className={`${classes.infoText} ${classes.textLine} poppins`}
									// style={!contestData.taxa ? { display: 'none' } : null}
								>
									<span className="bullet"> &#8226; </span>&nbsp;
									<strong style={{ color: '#ffffff', fontWeight: 600 }}>Taxa de inscrições</strong>
									{matchesMobile ? <br /> : null}
									{contestData?.taxa
										? ` - R$ ${contestData.taxa?.toLocaleString('pt-BR', {
												minimumFractionDigits: 2,
										  })}`
										: ' - Aguarda edital'}
								</Typography>
							)}
							{!contestData?.data_isencao_de ? null : (
								<Typography
									color="secondary"
									className={`${classes.infoText} ${classes.textLine} poppins`}
									// style={!contestData.data_isencao_de ? { display: 'none' } : null}
								>
									<span className="bullet"> &#8226; </span>&nbsp;
									<strong style={{ color: '#ffffff', fontWeight: 600 }}>
										Solicitação de isenção ou redução de 50% da taxa
									</strong>
									{matchesMobile ? <br /> : null}
									{contestData?.data_isencao_de === 'Aguarda edital'
										? ` - ${contestData?.data_isencao_de}`
										: contestData?.data_isencao_ate
										? ` - ${dateFormat(contestData.data_isencao_de)} à ${dateFormat(
												contestData.data_isencao_ate
										  )}`
										: ` - ${dateFormat(contestData?.data_isencao_de)}`}
								</Typography>
							)}
							{contestData?.url_externa ? (
								<Typography
									color="secondary"
									className={`${classes.infoText} ${classes.textLine} poppins`}
									// style={{ display: 'none' }}
								>
									<span className="bullet"> &#8226; </span>&nbsp;
									<strong style={{ color: '#ffffff', fontWeight: 600 }}>última notícia</strong> -{' '}
									{matchesMobile ? <br /> : null}
									{contestData.url_externa}
								</Typography>
							) : null}
							{!contestData?.data_gabarito_preliminar_de ? null : (
								<Typography
									color="secondary"
									className={`${classes.infoText} ${classes.textLine} poppins`}
									// style={!contestData.data_gabarito_preliminar_de ? { display: 'none' } : null}
								>
									<span className="bullet"> &#8226; </span>&nbsp;
									<strong style={{ color: '#ffffff', fontWeight: 600 }}>Gabarito preliminar </strong>
									{matchesMobile ? <br /> : null}
									{contestData?.data_gabarito_preliminar_de === 'Aguarda edital' ? (
										contestData?.data_gabarito_preliminar_de
									) : contestData?.arquivo_gabarito_preliminar ? (
										<>
											<a
												href={contestData.arquivo_gabarito_preliminar}
												target="_blank"
												rel="noopener noreferrer"
												style={{ color: '#1492E6' }}
											>
												&nbsp;Link para o arquivo
											</a>
										</>
									) : (
										<>&nbsp;- {dateFormat(contestData?.data_gabarito_preliminar_de)}</>
									)}
								</Typography>
							)}
							{!contestData?.data_gabarito_final_de ? null : (
								<Typography
									color="secondary"
									className={`${classes.infoText} ${classes.textLine} poppins`}
									// style={!contestData.data_gabarito_final_de ? { display: 'none' } : null}
									style={{}}
								>
									<span className="bullet"> &#8226; </span>&nbsp;
									<strong style={{ color: '#ffffff', fontWeight: 600 }}>Gabarito definitivo -</strong>
									{matchesMobile ? <br /> : null}
									{contestData?.data_gabarito_final_de === 'Aguarda edital' ? (
										` - ${contestData.data_gabarito_final_de}`
									) : new Date(contestData?.data_gabarito_final_ate) < new Date() &&
									  contestData?.arquivo_gabarito_final ? (
										<>
											<a
												href={contestData.arquivo_gabarito_final}
												target="_blank"
												rel="noopener noreferrer"
												style={{ color: '#1492E6' }}
											>
												&nbsp;Link para o arquivo
											</a>
										</>
									) : (
										<>
											&nbsp;-{' '}
											{contestData.data_gabarito_final_ate &&
											contestData.data_gabarito_final_ate !== 'Aguarda edital'
												? `${dateFormat(contestData.data_gabarito_final_de)} à ${dateFormat(
														contestData.data_gabarito_final_ate
												  )}`
												: `${dateFormat(contestData.data_gabarito_final_de)}`}
										</>
									)}
								</Typography>
							)}
							{matchesMobile && (
								<Grid
									item
									container
									justifyContent={'center'}
									className={`${classes.textLine} residencia poppins`}
								>
									<DefaultButton
										variant={'outlined'}
										color={'secondary'}
										className={`${classes.contestsBtn} btn-secondary poppins`}
										onClick={() => (currentContest ? showPastContest() : showCurrentContest())}
									>
										{currentContest ? 'Ver dados da edição anterior' : 'Ver dados da edição atual'}
									</DefaultButton>
								</Grid>
							)}
						</Grid>
						{!contestData?.titulo2_pg ? null : (
							<Grid
								item
								container
								direction={'column'}
								className={classes.marginTop2rem}
								gap={1}
							>
								<Typography
									color="secondary"
									className={`${classes.titleH2} poppins`}
								>
									{contestData?.titulo2_pg ? contestData.titulo2_pg : null}
								</Typography>
								<Typography
									color="secondary"
									className={`${classes.text} poppins`}
								>
									{contestData?.descricao2_pg
										? contestData.descricao2_pg.map((item, index) =>
												item !== '' ? (
													<React.Fragment key={index}>
														{item}
														<br />
													</React.Fragment>
												) : (
													<br key={index} />
												)
										  )
										: null}
								</Typography>
							</Grid>
						)}
						<Grid
							item
							container
						>
							<TableSpecialtyAndInstitution
								headerRows={headerRows}
								contest={contestData}
								onShowPastContest={showPastContest}
							/>
						</Grid>
						{!contestData?.titulo3_pg ? null : (
							<Grid
								item
								container
								direction={'column'}
								className={classes.marginTop2rem}
								gap={1}
							>
								<Typography
									color="secondary"
									className={`${classes.titleH2} poppins`}
								>
									{contestData?.titulo3_pg ? contestData.titulo3_pg : null}
								</Typography>
								<Typography
									color="secondary"
									className={`${classes.text} poppins`}
								>
									{contestData?.descricao3_pg
										? contestData.descricao3_pg.map((item, index) =>
												item !== '' ? (
													<React.Fragment key={index}>
														{item}
														<br />
													</React.Fragment>
												) : (
													<br key={index} />
												)
										  )
										: null}
								</Typography>
							</Grid>
						)}
						<Grid
							item
							container
							direction={'column'}
							style={{
								backgroundColor: '#33333380',
								padding: `1rem ${matchesMobile ? '1rem' : '1.25rem'}`,
								borderRadius: 15,
							}}
							gap={1}
						>
							{!contestData?.data_prova_objetiva && !contestData?.data_prova_fase_1 ? null : (
								<Typography
									color="secondary"
									className={`${classes.infoText} poppins`}
									// style={!contestData.data_prova_objetiva ? { display: 'none' } : null}
								>
									<span className="bullet"> &#8226; </span>&nbsp;
									<strong style={{ color: '#ffffff', fontWeight: 600 }}>Prova Objetiva </strong>
									{matchesMobile ? <br /> : null}
									{!contestData?.data_prova_objetiva
										? contestData?.data_prova_fase_1 &&
										  contestData?.data_prova_fase_1 !== 'Aguarde o edital'
											? ` - ${dateFormat(contestData?.data_prova_fase_1)}`
											: ` - ${contestData?.data_prova_fase_1}`
										: contestData?.data_prova_objetiva === 'Aguarda edital'
										? ` - ${contestData?.data_prova_objetiva}`
										: `- ${dateFormat(contestData?.data_prova_objetiva)}`}
								</Typography>
							)}
							{!contestData?.data_gabarito_pre_apelacao_de ? null : (
								<Typography
									color="secondary"
									className={`${classes.infoText} ${classes.textLine} poppins`}
									// style={!contestData.data_gabarito_pre_apelacao_de ? { display: 'none' } : null}
								>
									<span className="bullet"> &#8226; </span>&nbsp;
									<strong style={{ color: '#ffffff', fontWeight: 600 }}>
										Divulgação do Gabarito Pré-Recurso{' '}
									</strong>
									{matchesMobile ? <br /> : null}
									{`- ${dateFormat(contestData?.data_gabarito_pre_apelacao_de)}`}
								</Typography>
							)}
							{!contestData?.data_recursos_gabarito_de ? null : (
								<Typography
									color="secondary"
									className={`${classes.infoText} ${classes.textLine} poppins`}
									// style={!contestData.data_recursos_gabarito_de ? { display: 'none' } : null}
								>
									<span className="bullet"> &#8226; </span>&nbsp;
									<strong style={{ color: '#ffffff', fontWeight: 600 }}>
										Prazo final para recurso do gabarito{' '}
									</strong>
									{matchesMobile ? <br /> : null}
									{contestData?.data_recursos_gabarito_de === 'Aguarda edital'
										? ` - ${contestData?.data_recursos_gabarito_de}`
										: contestData?.data_recursos_gabarito_ate &&
										  contestData?.data_recursos_gabarito_ate !== 'Aguarda edital'
										? `- ${dateFormat(contestData.data_recursos_gabarito_de)} à ${dateFormat(
												contestData.data_recursos_gabarito_ate
										  )}`
										: `- ${dateFormat(contestData?.data_recursos_gabarito_de)}`}
								</Typography>
							)}
							{!contestData?.data_gabarito_final_de ? null : (
								<Typography
									color="secondary"
									className={`${classes.infoText} ${classes.textLine} poppins`}
									// style={!contestData.data_gabarito_final_de ? { display: 'none' } : null}
								>
									<span className="bullet"> &#8226; </span>&nbsp;
									<strong style={{ color: '#ffffff', fontWeight: 600 }}>
										Gabarito definitivo e divulgação da nota da 1ª fase{' '}
									</strong>
									{matchesMobile ? <br /> : null}
									{contestData?.data_gabarito_final_de === 'Aguarda edital'
										? ` - ${contestData?.data_gabarito_final_de}`
										: contestData?.data_gabarito_final_ate &&
										  contestData?.data_gabarito_final_ate !== 'Aguarda edital'
										? `- ${dateFormat(contestData.data_gabarito_final_de)} à ${dateFormat(
												contestData.data_gabarito_final_ate
										  )}`
										: `- ${dateFormat(contestData?.data_gabarito_final_de)}`}
								</Typography>
							)}
							{!contestData?.data_recursos_fase_1_de ? null : (
								<Typography
									color="secondary"
									className={`${classes.infoText} ${classes.textLine} poppins`}
									// style={!contestData.data_recursos_fase_1_de ? { display: 'none' } : null}
								>
									<span className="bullet"> &#8226; </span>&nbsp;
									<strong style={{ color: '#ffffff', fontWeight: 600 }}>
										Recursos contra nota da 1ª Fase{' '}
									</strong>
									{/* {dateFormat(contestData.info_recurso_fase_1)} */}
									{/* {dateFormat(contestData.data_recursos_fase_1_de)} */}
									{matchesMobile ? <br /> : null}
									{contestData?.data_recursos_fase_1_de === 'Aguarda edital'
										? ` - ${contestData.data_recursos_fase_1_de}`
										: contestData?.data_recursos_fase_1_ate &&
										  contestData?.data_recursos_fase_1_ate !== 'Aguarda edital'
										? `- ${dateFormat(contestData.data_recursos_fase_1_de)} à ${dateFormat(
												contestData.data_recursos_fase_1_ate
										  )}`
										: `- ${dateFormat(contestData?.data_recursos_fase_1_de)}`}
								</Typography>
							)}
							{!contestData?.data_convite_fase_2_de ? null : (
								<Typography
									color="secondary"
									className={`${classes.infoText} ${classes.textLine} poppins`}
									// style={!contestData.data_convite_fase_2_de ? { display: 'none' } : null}
								>
									<span className="bullet"> &#8226; </span>&nbsp;
									<strong style={{ color: '#ffffff', fontWeight: 600 }}>
										Convocados para a 2ª fase{' '}
									</strong>
									{matchesMobile ? <br /> : null}
									{contestData?.data_convite_fase_2_ate
										? `- ${dateFormat(contestData.data_convite_fase_2_de)} à ${dateFormat(
												contestData.data_convite_fase_2_ate
										  )}`
										: `- ${dateFormat(contestData?.data_convite_fase_2_de)}`}
								</Typography>
							)}
							{!contestData?.data_curriculo_fase_2_de ? null : (
								<Typography
									color="secondary"
									className={`${classes.infoText} ${classes.textLine} poppins`}
									// style={!contestData.data_curriculo_fase_2_de ? { display: 'none' } : null}
								>
									<span className="bullet"> &#8226; </span>&nbsp;
									<strong style={{ color: '#ffffff', fontWeight: 600 }}>
										Envio do currículo (2ª fase){' '}
									</strong>
									{matchesMobile ? <br /> : null}
									{contestData?.data_curriculo_fase_2_ate
										? `- ${dateFormat(contestData.data_curriculo_fase_2_de)} à ${dateFormat(
												contestData.data_curriculo_fase_2_ate
										  )}`
										: `- ${dateFormat(contestData?.data_curriculo_fase_2_de)}`}
								</Typography>
							)}
							{!contestData?.data_avaliacao_curricular ? null : (
								<Typography
									color="secondary"
									className={`${classes.infoText} ${classes.textLine} poppins`}
									// style={!contestData.data_avaliacao_curricular ? { display: 'none' } : null}
								>
									<span className="bullet"> &#8226; </span>&nbsp;
									<strong style={{ color: '#ffffff', fontWeight: 600 }}>
										Avaliação e Arguição Curricular{' '}
									</strong>
									{matchesMobile ? <br /> : null}
									{contestData?.data_avaliacao_curricular === 'Aguarda edital'
										? ` - ${contestData?.data_avaliacao_curricular}`
										: `- ${dateFormat(contestData?.data_avaliacao_curricular)}`}
								</Typography>
							)}
							{!contestData?.data_prova_habilidades_especificas ? null : (
								<Typography
									color="secondary"
									className={`${classes.infoText} ${classes.textLine} poppins`}
									// style={
									// 	!contestData.data_prova_habilidades_especificas ? { display: 'none' } : null
									// }
								>
									<span className="bullet"> &#8226; </span>&nbsp;
									<strong style={{ color: '#ffffff', fontWeight: 600 }}>Prova prática </strong>
									{matchesMobile ? <br /> : null}
									{`- ${dateFormat(contestData?.data_prova_habilidades_especificas)}`}
								</Typography>
							)}
							{!contestData?.data_nota_fase_2 ? null : (
								<Typography
									color="secondary"
									className={`${classes.infoText} ${classes.textLine} poppins`}
									// style={!contestData.data_nota_fase_2 ? { display: 'none' } : null}
								>
									<span className="bullet"> &#8226; </span>&nbsp;
									<strong style={{ color: '#ffffff', fontWeight: 600 }}>
										Divulgação da nota da 2ª fase{' '}
									</strong>
									{matchesMobile ? <br /> : null}
									{`- ${
										contestData?.data_nota_fase_2 === 'Aguarda edital'
											? contestData?.data_nota_fase_2
											: dateFormat(contestData?.data_nota_fase_2)
									}`}
								</Typography>
							)}
							{!contestData?.data_recursos_fase_2_de ? null : (
								<Typography
									color="secondary"
									className={`${classes.infoText} ${classes.textLine} poppins`}
									// style={!contestData.data_recursos_fase_2_de ? { display: 'none' } : null}
								>
									<span className="bullet"> &#8226; </span>&nbsp;
									<strong style={{ color: '#ffffff', fontWeight: 600 }}>
										Recursos contra nota 2º fase{' '}
									</strong>
									{/* {isValid(new Date(contestData.infos_recurso_fase_2))
                                                ? format(new Date(contestData.infos_recurso_fase_2), 'dd/MM/yyyy')
                                                : contestData.infos_recurso_fase_2} */}
									{matchesMobile ? <br /> : null}
									{contestData?.data_recursos_fase_2_ate
										? `- ${dateFormat(contestData.data_recursos_fase_2_de)} à ${dateFormat(
												contestData.data_recursos_fase_2_ate
										  )}`
										: contestData?.data_recursos_fase_2_de === 'Aguarda edital'
										? null
										: `- ${dateFormat(contestData?.data_recursos_fase_2_de)}`}
								</Typography>
							)}
							{!contestData?.data_resultado_final ? null : (
								<Typography
									color="secondary"
									className={`${classes.infoText} ${classes.textLine} poppins`}
									// style={!contestData.data_resultado_final ? { display: 'none' } : null}
								>
									<span className="bullet"> &#8226; </span>&nbsp;
									<strong style={{ color: '#ffffff', fontWeight: 600 }}>
										Resultado final e Convocação para matrículas{' '}
									</strong>
									{matchesMobile ? <br /> : null}
									{contestData?.data_resultado_final === 'Aguarda edital'
										? ` - ${contestData?.data_resultado_final}`
										: contestData?.data_resultado_final === 'Aguarda edital'
										? null
										: `- ${dateFormat(contestData?.data_resultado_final)}`}
								</Typography>
							)}
							{!contestData?.data_matriculas_de ? null : (
								<Typography
									color="secondary"
									className={`${classes.infoText} ${classes.textLine} poppins`}
									// style={!contestData.data_matriculas_de ? { display: 'none' } : null}
								>
									<span className="bullet"> &#8226; </span>&nbsp;
									<strong style={{ color: '#ffffff', fontWeight: 600 }}>Matrículas </strong>
									{matchesMobile ? <br /> : null}
									{contestData?.data_matriculas_de === 'Aguarda edital'
										? ` - ${contestData?.data_matriculas_de}`
										: contestData?.data_matriculas_ate
										? `- ${dateFormat(contestData.data_matriculas_de)} à ${dateFormat(
												contestData.data_matriculas_ate
										  )}`
										: contestData?.data_matriculas_de === 'Aguarda edital'
										? null
										: `- ${dateFormat(contestData?.data_matriculas_de)}`}
								</Typography>
							)}
						</Grid>
						<Grid
							item
							container
						>
							{materials.length > 0 && (
								<Grid
									item
									xs={12}
									md={6}
									style={matchesTablet ? { marginBottom: '1.5rem' } : { paddingRight: '0.75rem' }}
								>
									<ProductsContainer
										title={'Materiais gratuitos'}
										description={`Confira nossos materiais gratuitos para dominar a prova do ${contestData?.nome}.`}
										classes={classes}
									>
										{materials.map((item, index) => (
											<Grid
												key={index}
												item
												xs={12}
												sm={6}
												// lg={4}
												container
												justifyContent={'center'}
												alignContent={'center'}
											>
												<MaterialCard
													key={index}
													item={item}
													classes={classes}
												/>
											</Grid>
										))}
									</ProductsContainer>
								</Grid>
							)}
							<Grid
								item
								xs={12}
								md={6}
								style={matchesTablet ? null : { paddingLeft: materials.length < 1 ? 0 : '0.75rem' }}
							>
								<ProductsContainer
									title={'O Hardwork te prepara'}
									description={`Como o Hardwork Medicina pode te ajudar a dominar a prova do ${contestData?.nome}.`}
									classes={classes}
								>
									{products?.map((item, index) => (
										<Grid
											key={index}
											item
											xs={12}
											sm={6}
											// lg={4}
											container
											justifyContent={'center'}
											alignContent={'center'}
										>
											<ProductCard
												key={index}
												item={item}
												classes={classes}
											/>
										</Grid>
									))}
								</ProductsContainer>
							</Grid>
						</Grid>
						<Grid
							item
							container
							ref={follow_up_btn}
						>
							<NoticeContainer
								classes={classes}
								matchesMobile={matchesMobile}
							/>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						container
					>
						<Typography
							color="primary"
							style={{ fontSize: '0.8rem', color: 'grey' }}
						>
							As informações estão sujeitas a alterações - verifique o edital*
						</Typography>
					</Grid>
				</Grid>
			</Container>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Footer />
			</Container>
		</Container>
        </>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		marginRight: 'auto',
		marginLeft: 'auto',
		marginBottom: '3%',
	},
	title: {
		fontSize: '2.15rem !important',
		fontWeight: 'bold !important',
		textShadow: '0 0 0.2em #121213, 0 0 0.2em #0c0c0d',
		[theme.breakpoints.down('md')]: {
			fontSize: '2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			lineHeight: 'unset !important',
			marginBottom: '0.5rem !important',
			fontSize: '1.5rem !important',
		},
	},
	subtitle: {
		fontSize: '1.1rem !important',
		textShadow: '0 0 0.2em #121213, 0 0 0.2em #0c0c0d',
		fontWeight: '500 !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.95rem !important',
		},
	},
	img: {
		width: '50%',
		[theme.breakpoints.down('md')]: {
			width: '50%',
		},
		[theme.breakpoints.down('sm')]: {
			width: '40%',
		},
	},
	container: {
		padding: '1.75em',
		borderRadius: 30,
		background: 'linear-gradient(90deg, rgba(252,34, 34) 10%, rgba(255,72,0) 90%)',
		[theme.breakpoints.down('xl')]: {
			padding: '1.5em',
		},
		[theme.breakpoints.down('lg')]: {
			padding: '1em',
		},
		[theme.breakpoints.down('md')]: {
			padding: '1.8em !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '1.25em 0.5em !important',
		},
	},
	gridNewsletterText: {
		paddingLeft: '3rem',
		[theme.breakpoints.down('md')]: {
			padding: '0 1em 1em',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0 0.5em 0',
		},
	},
	textField: {
		padding: '0.5rem !important',
		[theme.breakpoints.down('sm')]: {
			padding: '0.35rem 0.5rem !important',
		},
	},
	form: {
		borderRadius: '8px !important',
		backgroundColor: theme.palette.secondary.main,
		maxHeight: '48px !important',
		'& .MuiInputBase-input': {
			padding: '0.75rem !important',
		},
		[theme.breakpoints.down('xl')]: {
			maxHeight: '40.8px !important',
		},
		[theme.breakpoints.down('md')]: {
			'& .MuiInputBase-input': {
				padding: '0.5rem !important',
			},
		},
		[theme.breakpoints.down('sm')]: {
			maxHeight: '38px !important',
			'& .MuiInputBase-input': {
				padding: '0.45rem !important',
			},
		},
	},
	btn: {
		fontSize: '1.2rem !important',
		width: '50% !important',
		backgroundColor: '#0D0D0D !important',
		fontWeight: 'bold !important',
		padding: '0.5rem !important',
		border: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
			width: '197px !important',
		},
	},
	gridBtn: {
		paddingLeft: '0.5rem',
		marginTop: '0.5rem !important',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: 'unset',
		},
	},
	infoText: {
		fontSize: '1.45rem !important',
		fontWeight: '280 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	textLine: {
		borderTop: '1px solid #716f6f !important',
		paddingTop: '0.5rem !important',
	},
	highlightedLine: {
		transition: 'font-size 0.3s ease',
	},
	fadedLine: {
		opacity: 0.5,
		transition: 'background-color 0.7s ease',
	},
	titleInfoBox: {
		fontSize: '1.2rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.15rem !important',
			textAlign: 'left !important',
		},
	},
	btnTitle: {
		width: 'fit-content',
		padding: '0.25rem 1.5rem !important',
		borderRadius: '5px !important',
		[theme.breakpoints.down('sm')]: {
			width: 'fit-content !important',
			padding: '0.25rem 0.75rem !important',
		},
	},
	contentInfoBox: {
		// color: '#ffffff !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('sm')]: {
			// fontSize: '1rem !important',
			textAlign: 'left !important',
		},
	},
	gridImgInfoBox: {
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'flex-start !important',
		},
	},
	gridTitle: {
		backgroundImage: `url(${require('./Assets/bg-contest-template.png')})`,
		backgroundSize: 'cover',
		// borderRadius: 15,
		borderBottomRightRadius: 15,
		borderBottomLeftRadius: 15,
		[theme.breakpoints.down('sm')]: {
			padding: '1rem !important',
		},
	},
	gridTitleInfoBox: {
		paddingLeft: '1rem !important',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '0.5rem !important',
		},
	},
	titleH2: {
		fontSize: '2rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
		},
	},
	text: {
		fontSize: '1.15rem !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.8rem !important',
		},
	},
	floatBtn: {
		width: 'fit-content',
		borderRadius: '15px !important',
		position: 'fixed !important',
		right: '10%',
		zIndex: 2,
		padding: '1rem !important',
		opacity: 0.5,
	},
	followUpBtnText: {
		fontSize: '1rem !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	followUpBtn: {
		fontSize: '1rem !important',
		fontWeight: 'bold !important',
		padding: '0.25rem 2rem !important',
		height: 'fit-content',
		backgroundColor: '#d2d2d233 !important',
		width: 'fit-content',
		border: '2px solid #ffffff !important',
		textShadow: '2px 2px 4px #000000',
		transition: 'ease 0.3s !important',
		'&:hover': {
			scale: 1.035,
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.72rem !important',
			padding: '0.5rem 1rem !important',
		},
		[theme.breakpoints.down(361)]: {
			fontSize: '0.69rem !important',
			padding: '0.5rem 0.75rem !important',
		},
	},
	divider: {
		margin: 'unset !important',
		backgroundColor: '#6D6D6D',
		minHeight: 1,
		width: '100%',
	},
	contestsBtn: {
		fontSize: '0.9rem !important',
		fontWeight: 'bold !important',
		padding: '0.25rem !important',
		height: 'fit-content',
		width: '80%',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.75rem !important',
			width: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			width: 'fit-content',
			padding: '0.25rem 0.75rem !important',
		},
	},
	marginTop2rem: {
		marginTop: '2rem !important',
		[theme.breakpoints.down('sm')]: {
			marginTop: 'unset !important',
		},
	},
	noticeContainerTitle: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.7rem !important',
		},
	},
	noticeContainerText: {
		fontSize: '1.15rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	infoBoxContainer: {
		padding: '1rem',
		backgroundColor: '#33333380 ',
		borderRadius: 15,
		width: '95% !important',
		[theme.breakpoints.down('sm')]: {
			width: '100% !important',
		},
	},
	goBackButton: {
		width: 'fit-content',
		padding: '0.25rem 1.5rem 0.25rem 1.5rem !important',
		border: 'solid 1px #ffffff !important',
		fontWeight: 'bold !important',
		backgroundColor: '#33333380 !important',
		alignSelf: 'center',
		marginBottom: '1rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.7rem !important',
			padding: '0.25rem 0.75rem !important',
			height: 'fit-content',
			minWidth: 'unset !important',
		},
	},
	imgInfoBox: {
		height: '80%',
		[theme.breakpoints.down('sm')]: {
			width: '80% !important',
		},
	},
	productsContainerRoot: {
		backgroundColor: '#33333380 !important',
		padding: '1rem 1rem 1.5rem !important',
		borderRadius: '15px !important',
		height: 'fit-content',
	},
	titleProductsContainer: {
		fontSize: '2.75rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.75rem !important',
			lineHeight: 'unset !important',
			marginBottom: '0.5rem !important',
		},
	},
	descriptionProductContainer: {
		fontSize: '1.1rem !important',
		margin: '0 0 1em !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	productCardRoot: {
		backgroundColor: 'unset !important',
		borderRadius: '15px !important',
		width: '90%',
		display: 'flex !important',
		alignItems: 'center !important',
		flexDirection: 'column !important',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	btnProductCard: {
		width: '65%',
		border: '3.5px solid #000000 !important',
		borderRadius: '40px !important',
		margin: '0.5em 0 !important',
		fontWeight: 'bold !important',
		padding: '0.15rem 0 !important',
		textTransform: 'unset !important',
		fontSize: '1rem !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.8rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			width: '45%',
			fontSize: '0.8rem !important',
			border: '2.5px solid #000000 !important',
		},
	},
	gridSprintFinalFlag: {
		width: 'fit-content !important',
		borderBottomLeftRadius: 15,
		borderBottomRightRadius: 15,
		padding: '0.5rem 0.75rem 1rem',
		background:
			'linear-gradient(-45deg, rgba(217, 217, 217, 0.88) 0%, rgba(255, 255, 255, 0.8) 49.4%, rgba(225, 225, 225, 0.68) 100%)',
		boxShadow: '4px 4px 10px 0px rgba(0, 0, 0, 0.5)',
		cursor: 'pointer',
		height: 'fit-content',
		gap: 4,
		backgroundColor: 'transparent',
		transition: 'ease 0.3s',
		'&:hover $gridInnerSprintFinalFlag': {
			transform: 'scale(1.05)',
		},
		'&:hover': {
			padding: '0.5rem 1rem 1rem',
		},
	},
	gridInnerSprintFinalFlag: {
		transition: 'ease 0.1s',
		textDecoration: 'none',
	},
	containerTextField: {
		paddingRight: '4rem',
		[theme.breakpoints.down('md')]: {
			paddingRight: 'unset',
		},
	},
	gridImage: {
		padding: '1.5rem',
		borderTopRightRadius: 15,
		borderTopLeftRadius: 15,
		backgroundSize: 'cover',
		height: 125,
		[theme.breakpoints.down('xl')]: {
			height: 108,
		},
		[theme.breakpoints.down('lg')]: {
			height: 79,
		},
		[theme.breakpoints.down('md')]: {
			height: 109,
		},
		[theme.breakpoints.down('sm')]: {
			height: 145,
		},
		[theme.breakpoints.down(394)]: {
			height: 138,
		},
		[theme.breakpoints.down(391)]: {
			height: 137,
		},
		[theme.breakpoints.down(376)]: {
			height: 130,
		},
		[theme.breakpoints.down(361)]: {
			height: 125,
		},
	},
	boldEditionText: {
		fontSize: '1.5rem !important',
		fontWeight: 'bold !important',
		zIndex: 4,
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.25rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	regularEditionText: {
		fontSize: '1.05rem !important',
		fontWeight: 'bold !important',
		zIndex: 3,
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.9rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	gridPastEdition: {
		padding: '0.5rem 0 !important',
		borderTopRightRadius: 5,
		left: 90,
		position: 'relative !important',
		alignSelf: 'flex-end',
		cursor: 'pointer',
		[theme.breakpoints.down('xl')]: {
			left: 100,
		},
		[theme.breakpoints.down('sm')]: {
			left: 50,
		},
	},
}));
