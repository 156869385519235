/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useRef } from 'react';
import { Button, Container, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../../Components/Footer';
import { Header } from '../../../../../Components/Header';
// import Form from '../../../../../Components/Utils/Forms/PreRegistration';
import Form from '../../../../../Components/Utils/Forms/PreRegistration-Revaliday';
// import { useState } from 'react';
import CalendarIcon from '../Assets/calendar-vector.svg';
import PinIcon from '../Assets/pin-vector.svg';

export default function Revaliday() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));
	const matchesDownXl = useMediaQuery(theme.breakpoints.down('xl'));

	const formRef = useRef(null);

	const executeScroll = () => formRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

	// const [closeGroup, setCloseGroup] = useState(false);

	// const { email } = useParams()
	const mobileBg = require('../../../../LandingPages/FreeContent/HardworkEm/Assets/bg-pagina-mobile.jpg');
	const desktopBg = require('../../../../LandingPages/FreeContent/HardworkEm/Assets/bg-pagina-full.jpg');

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}

			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
			</Container>
			<Grid
				style={{
					backgroundImage: matchesMobile ? `url(${mobileBg})` : `url(${desktopBg})`,
					height: '100%',
					backgroundSize: 'cover',
				}}
			>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					<Grid
						container
						justifyContent={'space-between'}
						alignContent="center"
						className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
						position={'relative'}
					>
						<Grid
							item
							xs={12}
							md={6}
							container
							justifyContent={'flex-start'}
							className={`${classes.gridTextContainer} revalida`}
							gap={1}
						>
							{matchesMobile ? (
								<img
									src={
										require('../../../../../Assets/Extensivo/Revalida/logo-revalilogo-revalida-pontapora-day.svg')
											.default
									}
									alt="Logo Revalida Ponta Porã"
									style={matchesDownXl ? null : matchesTablet ? { height: 120 } : null}
									className={classes.logoSecond}
								/>
							) : null}
							{matchesMobile && (
								<Grid
									className={classes.buttonScroll}
									container
									justifyContent={'center'}
									alignContent={'center'}
								>
									<Button onClick={() => executeScroll()}>
										<Typography style={{ color: 'white', fontWeight: 'bold' }}>
											GARANTIR MINHA VAGA
										</Typography>
									</Button>
								</Grid>
							)}

							<Grid className={classes.firstGrid}>
								<Typography
									className={`${classes.title} roboto-condensed`}
									color="secondary"
								>
									VAMOS ARREPIAR <br /> EM PONTA PORÃ!
									<br />
									UMA NOITE COM TEMAS QUENTES PARA O REVALIDA.
								</Typography>
								<Typography
									className={`${classes.text} poppins`}
									color="secondary"
								>
									No dia 06 de junho o Hardwork estará novamente em Ponta Porã, e dessa vez com muito
									mais discussões. Uma aula exclusiva e gratuita de temas essenciais da Clínica
									Médica.
									<br />
									<br />
									Além de tudo, sorteios de brindes, cursos e cupons exclusivos para os presentes.
									<br />
									<br />
									<span style={{ fontWeight: 'bold', fontSize: '1.4rem' }}>
										As inscrições são gratuitas, mas as vagas são limitadas!
									</span>
								</Typography>
							</Grid>

							<Grid className={classes.secondGrid}>
								<Grid
									container
									alignItems={'center'}
									gap={2}
								>
									<img
										style={{ alignSelf: 'flex-start' }}
										src={CalendarIcon}
										alt=""
									/>
									<Typography
										align="left"
										color="secondary"
										className={`${classes.impactText} poppins`}
										style={{ fontWeight: 'bold' }}
									>
										PROGRAMAÇÃO 06 DE JUNHO
									</Typography>
								</Grid>
								<Grid
									item
									container
									// style={{ marginBottom: '3em' }}
								>
									<Divider
										variant="middle"
										orientation="vertical"
										className={classes.divider}
									/>
									<Grid
										item
										container
										wrap="nowrap"
										gap={2}
									>
										<img
											src={require('../Assets/19.svg').default}
											alt=""
											style={{ height: 30, top: 5 }}
										/>
										<Grid
											item
											container
										>
											<Typography className={`${classes.timelineText} poppins`}>
												Credenciamento
											</Typography>
											<Typography
												color="secondary"
												className={`${classes.text} poppins`}
											>
												{/* Uma aula de temas sobre Clínica Médica. */}
												<br />
												<br />
											</Typography>
										</Grid>
									</Grid>
									<Grid
										item
										container
										wrap="nowrap"
										gap={2}
									>
										<img
											src={require('../Assets/20.svg').default}
											alt=""
											style={{ height: 30, top: 5 }}
										/>
										<Grid
											item
											container
										>
											<Typography className={`${classes.timelineText} poppins`}>
												Show de aulas Hardwork
											</Typography>
											<Typography
												color="secondary"
												className={`${classes.text} poppins`}
											>
												Discussão de vários temas quentes da Clínica Médica, com o professor
												Fabrício.
												<br />
												<br />
											</Typography>
										</Grid>
									</Grid>
									<Grid
										item
										container
										wrap="nowrap"
										gap={2}
									>
										<img
											src={require('../Assets/21.svg').default}
											alt=""
											style={{ height: 30, top: 5 }}
										/>
										<Grid
											item
											container
										>
											<Typography className={`${classes.timelineText} poppins`}>
												Sorteios Hardwork
											</Typography>
											<Typography
												color="secondary"
												className={`${classes.text} poppins`}
											>
												Brindes, cursos e cupons serão sorteados ao final do evento.
											</Typography>
										</Grid>
									</Grid>
									<Grid
										container
										justifyContent={'center'}
										marginTop={5}
									>
										<Grid
											container
											className={classes.borderdGrid}
										>
											<Typography
												align="left"
												color="secondary"
												className={`${classes.impactTextTitle} poppins`}
											>
												QUINTA-FEIRA
												<span className="bullet"> &#8226; </span>
												06JUN
												<span className="bullet"> &#8226; </span>
												19H
											</Typography>
                                            <Typography>
                                                *Conforme horário de Brasília
                                            </Typography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>

							<Grid className={classes.thirdGrid}>
								<Grid
									container
									alignItems={'center'}
									gap={3}
								>
									<img
										style={{ alignSelf: 'flex-start' }}
										src={PinIcon}
										alt=""
									/>
									<Typography
										align="left"
										color="secondary"
										className={`${classes.impactText} poppins`}
										style={{ fontWeight: 'bold' }}
									>
										ONDE SERÁ O EVENTO?
									</Typography>
								</Grid>

								<Typography
									color="secondary"
									className={`${classes.text} poppins`}
									style={{ marginBottom: '1.5rem' }}
								>
									Hotel Pousada do Bosque
									<br />
									Av. Pres. Vargas, 1151 - Centro, Ponta Porã - MS, 79900-000
								</Typography>
								<iframe
									src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3685.3070379245737!2d-55.724973625966925!3d-22.530168324517277!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94626efd0015b56b%3A0xa4e4dc734a292d3a!2sHotel%20Pousada%20do%20Bosque!5e0!3m2!1spt-BR!2sbr!4v1713444789279!5m2!1spt-BR!2sbr"
									width={
										matchesMobile
											? '345'
											: matchesTablet
											? '580'
											: matchesLg
											? '390'
											: matchesDownXl
											? '550'
											: '660'
									}
									height="233"
									allowfullscreen=""
									loading="lazy"
									referrerpolicy="no-referrer-when-downgrade"
									style={{ borderRadius: '12px' }}
								></iframe>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							md={5}
							container
							className={classes.formControl}
							justifyContent="center"
							style={{ height: 'auto' }}
							ref={formRef}
						>
							<Grid
								item
								xs={12}
								className={classes.gridForm}
								gap={1}
							>
								{matchesMobile ? null : (
									<img
										src={
											require('../../../../../Assets/Extensivo/Revalida/logo-revalilogo-revalida-pontapora-day.svg')
												.default
										}
										alt="Logo Revalida Ponta Porã"
										className={classes.logo}
									/>
								)}
								<Form
									group="LISTA DE ESPERA - REVALIDA INEP 2024(PRESENCIAL PONTA)"
									title={['Inscrições Encerradas!']}
									titleButton="Inscrever-se na lista de espera"
									type="revalida"
                                    // titleConfirmationMessage
									hasName
									hasAdvice
									finished
									// urlToRedirect="/dashboard"
									confirmationMessage={'Seu nome foi incluído na lista de espera! Informaremos quando novas vagas forem disponibilizadas.'}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</Grid>
			{matchesTablet ? null : <Footer />}

			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	// section: {
	// 	maxWidth: '1410px',
	// 	margin: '1% 5% auto',
	// },
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	formControl: {
		[theme.breakpoints.up('lg')]: {
			//1200
			paddingTop: '90px !important',
		},
		[theme.breakpoints.down('lg')]: {
			//1200
			paddingTop: '90px !important',
		},
		[theme.breakpoints.down('md')]: {
			//1200
			paddingTop: '40px !important',
		},
	},
	buttonScroll: {
		position: 'absolute',
		top: '16rem',
		right: '4rem',
		height: '32px',
		width: '260px !important',
		borderRadius: '15px',
		backgroundColor: 'black',
		[theme.breakpoints.down('sm')]: {
			right: '8rem',
			top: '17rem',
		},
		[theme.breakpoints.down(600)]: {
			right: '4rem',
			top: '17rem',
		},
		[theme.breakpoints.down(400)]: {
			right: '2rem',
			top: '17rem',
		},
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.35rem !important',
		lineHeight: 'unset !important',
		marginBottom: '2rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
			marginBottom: '8px !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.18rem !important',
			marginBottom: '8px !important',
			lineHeight: 'unset !important',
		},
	},
	text: {
		fontSize: '1.3125rem !important',
		color: 'rgba(255, 255, 255, 0.8)',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	firstGrid: {
		backgroundColor: 'black',
		borderRadius: '15px',
		marginTop: '6rem',
		padding: '20px',
	},
	secondGrid: {
		backgroundColor: 'black',
		borderRadius: '15px',
		marginTop: '2rem',
		padding: '20px',
	},
	thirdGrid: {
		backgroundColor: 'black',
		borderRadius: '15px',
		marginTop: '2rem',
		padding: '20px',
	},
	borderdGrid: {
		backgroundColor: 'rgba(9, 215, 133, 0.2)',
		border: '2px solid #09D785',
		borderRadius: '8px',
		justifyContent: 'center',
		width: 'fit-content !important',
		height: '65% !important',
		padding: '8px',
	},
	gridForm: {
		width: 567,
		position: 'sticky',
		// bottom: 40,
		right: 0,
		top: 40,
		zIndex: 10,
		display: 'flex',
		flexDirection: 'column !important',
		alignItems: 'center !important',
		alignSelf: 'flex-start',
		[theme.breakpoints.down(1300)]: {
			width: 467,
			right: 30,
			bottom: 10,
		},
		[theme.breakpoints.down('lg')]: {
			right: 50,
		},
		[theme.breakpoints.down(1010)]: {
			right: 20,
		},
		[theme.breakpoints.down('md')]: {
			position: 'unset',
			right: 'unset',
			top: 'unset',
			zIndex: 0,
		},
	},
	gridTextContainer: {
		paddingBottom: '1rem',
		paddingLeft: '2rem',
		[theme.breakpoints.down('lg')]: {
			paddingRight: '2rem',
		},
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
	},

	gridTitle: {
		backgroundColor: '#000000',
		padding: '0px 25px',
		borderTopRightRadius: 20,
		borderTopLeftRadius: 20,
		[theme.breakpoints.down('sm')]: {
			padding: '0px 15px',
		},
	},
	impactText: {
		margin: '0 0 2rem !important',
		fontSize: '2.2rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.3rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1rem !important',
		},
	},
	impactTextTitle: {
		margin: '0 0 2rem !important',
		fontSize: '2.2rem !important',
		lineHeight: 'unset !important',
		padding: 10,
		justifyContent: 'center',
		alignItems: 'center',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1rem !important',
		},
	},
	logo: {
        width: '100% !important',
		[theme.breakpoints.down('xl')]: {
			height: 'unset',
			width: 250,
		},
	},
	logoSecond: {
		marginTop: '6rem',
	},
	divider: {
		position: 'absolute',
		border: '1.5px solid #fff !important',
		left: '4.85em',
		backgroundColor: '#fff',
		height: '210px !important',
		// zIndex: -1,
		[theme.breakpoints.down('xl')]: {
			height: '200px !important',
		},
		[theme.breakpoints.down('lg')]: {
			height: '200px !important',
		},
		[theme.breakpoints.down('md')]: {
			height: '200px !important',
		},
		[theme.breakpoints.down('sm')]: {
			height: '190px !important',
		},
	},
	imgTime: {
		height: 30,
		top: 5,
	},
	timelineText: {
		fontSize: '1.5rem !important',
		color: 'white',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.2rem !important',
		},
	},
	impactText2: {
		margin: '1.5rem 0 0 !important',
		fontSize: '1.75rem !important',
		lineHeight: 'unset !important',
		fontWeight: 'bold !important',

		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.45rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1rem !important',
		},
	},
}));
