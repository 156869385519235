import React, { useState } from 'react';
import { Button, Container, Divider, Grid, Modal, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import { Header } from '../../../../Components/Header';
import ArenaHwmCard from '../../../../Components/Cards/ArenaHwmCard';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

const pdfs1 = [
	{
		id: 1,
		imgUrl: require('../../../../Assets/Arena/estacao-cm-1.jpg'),
		title: 'CENÁRIO DE ATUAÇÃO',
	},
	{
		id: 2,
		imgUrl: require('../../../../Assets/Arena/estacao-cm-2.jpg'),
		title: 'IMPRESSO 01',
	},
	{
		id: 3,
		imgUrl: require('../../../../Assets/Arena/estacao-cm-3.jpg'),
		title: 'IMPRESSO 02',
	},
	{
		id: 4,
		imgUrl: require('../../../../Assets/Arena/estacao-cm-4.jpg'),
		title: 'IMPRESSO 03',
	},
	{
		id: 5,
		imgUrl: require('../../../../Assets/Arena/estacao-cm-5.jpg'),
		title: 'CHECKLIST 01',
	},
	{
		id: 6,
		imgUrl: require('../../../../Assets/Arena/estacao-cm-6.jpg'),
		title: 'CHECKLIST 02',
	},
];

const pdfs2 = [
	{
		id: 1,
		imgUrl: require('../../../../Assets/Arena/estacao-mp-1.jpg'),
		title: 'CENÁRIO DE ATUAÇÃO',
	},
	{
		id: 2,
		imgUrl: require('../../../../Assets/Arena/estacao-mp-2.jpg'),
		title: 'IMPRESSO 01',
	},
	{
		id: 3,
		imgUrl: require('../../../../Assets/Arena/estacao-mp-3.jpg'),
		title: 'IMPRESSO 01-2',
	},
	{
		id: 4,
		imgUrl: require('../../../../Assets/Arena/estacao-mp-4.jpg'),
		title: 'CHECKLIST 01',
	},
	{
		id: 5,
		imgUrl: require('../../../../Assets/Arena/estacao-mp-5.jpg'),
		title: 'CHECKLIST 02',
	},
];

const pdfs3 = [
	{
		id: 1,
		imgUrl: require('../../../../Assets/Arena/estacao-ped-1.jpg'),
		title: 'CENÁRIO DE ATUAÇÃO',
	},
	{
		id: 2,
		imgUrl: require('../../../../Assets/Arena/estacao-ped-2.jpg'),
		title: 'IMPRESSO 01',
	},
	{
		id: 3,
		imgUrl: require('../../../../Assets/Arena/estacao-ped-3.jpg'),
		title: 'IMPRESSO 02',
	},
	{
		id: 4,
		imgUrl: require('../../../../Assets/Arena/estacao-ped-4.jpg'),
		title: 'IMPRESSO 03',
	},
	{
		id: 5,
		imgUrl: require('../../../../Assets/Arena/estacao-ped-5.jpg'),
		title: 'IMPRESSO 04',
	},
	{
		id: 6,
		imgUrl: require('../../../../Assets/Arena/estacao-ped-6.jpg'),
		title: 'IMPRESSO 04-2',
	},
	{
		id: 7,
		imgUrl: require('../../../../Assets/Arena/estacao-ped-7.jpg'),
		title: 'CHECKLIST 01',
	},
	{
		id: 8,
		imgUrl: require('../../../../Assets/Arena/estacao-ped-8.jpg'),
		title: 'CHECKLIST 02',
	},
	{
		id: 9,
		imgUrl: require('../../../../Assets/Arena/estacao-ped-9.jpg'),
		title: 'CHECKLIST 03',
	},
];

const PdfBox = ({ title, classes, imgUrl, dataLiberacao, matchesMobile }) => {
	const [open, setOpen] = useState(false);

	const handleOpen = (index) => {
		var momentoAtual = new Date();
		var momentoLiberacao = new Date(dataLiberacao);
		setOpen(momentoAtual >= momentoLiberacao);
	};

	const handleClose = (event) => {
		event.stopPropagation();
		setOpen(false);
	};

	return (
		<Grid
			item
			container
			direction={'column'}
			alignContent={'center'}
			className={classes.pdfBox}
			gap={1}
			onClick={() => handleOpen()}
		>
			<img
				src={require('../../../../Assets/Arena/img-pdf.svg').default}
				alt=""
				style={{ width: 40, alignSelf: 'center' }}
			/>
			<Typography
				color="secondary"
				className={classes.titlePdfBox}
				align="center"
			>
				{title}
			</Typography>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Grid
					item
					container
					alignContent={'center'}
					style={{
						margin: matchesMobile ? '50px auto' : '0 auto 0',
						width: matchesMobile ? '100%' : '50%',
						backgroundColor: '#333333',
						maxHeight: '100%',
						borderRadius: 15,
						overflowY: 'auto',
					}}
				>
					<Grid
						item
						container
						justifyContent={'space-between'}
						style={matchesMobile ? null : { position: 'absolute', zIndex: 3 }}
					>
						<Typography
							className={`${classes.pdfTitleContainer} poppins`}
							color="secondary"
							style={{ margin: '0 0 0.25em 1em' }}
						>
							{title}
						</Typography>
						<CancelRoundedIcon
							onClick={handleClose}
							className={classes.cancelIcon}
						/>
					</Grid>
					<Grid
						item
						container
						justifyContent={'center'}
						style={{
							width: '100%',
						}}
					>
						<img
							src={imgUrl}
							alt=""
							style={{ height: '100%' }}
						/>
					</Grid>
				</Grid>
			</Modal>
		</Grid>
	);
};

export default function LiveArenaRelease() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					justifyContent="center"
					alignContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
				>
					<Grid
						item
						xs={12}
						container
						justifyContent={matchesMobile ? 'center' : 'flex-start'}
						className={`${classes.gridTextContainer}`}
						gap={2}
					>
						<img
							src={require('../../../../Assets/Arena/logo-live-revalida.svg').default}
							alt="Logo React Revalida"
						/>
						<Typography
							className={`${classes.title} roboto-condensed`}
							color="secondary"
						>
							Abertura Arena Hardwork
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<Divider
							variant="middle"
							className={classes.divider}
						/>
					</Grid>
					<Grid
						item
						container
						className={matchesMobile ? null : 'section'}
						alignContent={'flex-end'}
						style={matchesMobile ? { margin: '20px auto' } : { margin: '60px auto' }}
					>
						<Grid
							item
							xs={12}
							lg={7}
							container
							justifyContent={matchesMobile ? 'center' : 'flex-start'}
						>
							<iframe
								// width="1141"
								width="890"
								// height={matchesMobile ? '250' : '641'}
								height={matchesMobile ? '250' : '470'}
								loading="lazy"
								src="https://www.youtube.com/embed/mjOLV1PzDxg"
								title="Live stream video player"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media;"
								allowfullscreen
								style={{ borderRadius: '20px' }}
							></iframe>
						</Grid>
						<Grid
							item
							xs={12}
							lg={5}
							container
							justifyContent="center"
							style={matchesMobile ? { margin: '15px 0 0' } : { paddingLeft: '1.5rem' }}
						>
							<Grid
								item
								container
								justifyContent={'center'}
								style={{
									backgroundColor: 'green',
									borderTopRightRadius: 15,
									borderTopLeftRadius: 15,
									padding: '1rem',
								}}
							>
								<Typography
									className={`${classes.pdfTitleContainer} poppins`}
									color="secondary"
								>
									Clique<strong> APENAS</strong> durante a resolução das estações
								</Typography>
							</Grid>
							<Grid
								item
								container
								rowGap={3}
								className={classes.backgroundContainerPdfs}
							>
								<Grid
									item
									xs={12}
									container
									alignContent={'center'}
									gap={2}
								>
									<Grid
										item
										className={classes.gridTitleBoxes}
									>
										<Typography
											className={`${classes.subtitlePdfContainer} poppins`}
											color="primary"
										>
											ARENA 01
										</Typography>
									</Grid>
									<Grid
										item
										container
										xs={12}
										direction={'row'}
										alignContent={'center'}
										rowGap={matchesTablet ? 2 : 1}
										justifyContent={matchesTablet ? 'space-around' : 'flex-start'}
									>
										{pdfs3.map((item) => {
											return (
												<PdfBox
													title={item.title}
													classes={classes}
													imgUrl={item.imgUrl}
													dataLiberacao={'2023-04-12T18:00:00'}
													matchesMobile={matchesMobile}
												/>
											);
										})}
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									container
									alignContent={'center'}
									gap={2}
								>
									<Grid
										item
										className={classes.gridTitleBoxes}
									>
										<Typography
											className={`${classes.subtitlePdfContainer} poppins`}
											color="primary"
										>
											ARENA 02
										</Typography>
									</Grid>
									<Grid
										item
										container
										xs={12}
										direction={'row'}
										alignContent={'center'}
										justifyContent={matchesTablet ? 'space-around' : 'flex-start'}
										rowGap={matchesTablet ? 2 : 0}
									>
										{pdfs2.map((item) => {
											return (
												<PdfBox
													title={item.title}
													classes={classes}
													imgUrl={item.imgUrl}
													dataLiberacao={'2023-04-12T18:40:00'}
													matchesMobile={matchesMobile}
												/>
											);
										})}
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									container
									alignContent={'center'}
									gap={2}
								>
									<Grid
										item
										className={classes.gridTitleBoxes}
									>
										<Typography
											className={`${classes.subtitlePdfContainer} poppins`}
											color="primary"
										>
											ARENA 03
										</Typography>
									</Grid>
									<Grid
										item
										container
										xs={12}
										direction={'row'}
										alignContent={'center'}
										rowGap={matchesTablet ? 2 : 1}
										justifyContent={matchesTablet ? 'space-around' : 'flex-start'}
									>
										{pdfs1.map((item) => {
											return (
												<PdfBox
													title={item.title}
													classes={classes}
													imgUrl={item.imgUrl}
													dataLiberacao={'2023-04-12T19:00:00'}
													matchesMobile={matchesMobile}
												/>
											);
										})}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						container
						style={matchesMobile ? { marginTop: 16 } : null}
					>
						<Typography
							className={`${classes.subtitle1} roboto-condensed`}
							color="secondary"
						>
							Interaja nas estações pela janela abaixo:
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						container
						justifyContent="center"
						style={matchesMobile ? { margin: '20px 0px' } : { margin: '30px 0px 80px' }}
					>
						<iframe
							width="100%"
							loading="lazy"
							src="//kahoot.it"
							title="Live stream video player"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media;"
							allowfullscreen
							style={{ borderRadius: '20px', minHeight: 600 }}
						></iframe>
					</Grid>
					<Grid
						item
						xs={12}
						lg={8}
						container
						justifyContent={'center'}
						style={matchesTablet ? { margin: '20px 0 40px' } : { marginBottom: 60 }}
					>
						<Button
							className={classes.btn}
							href="/arena-hardwork"
							target="_blank"
						>
							<img
								src={require('../../../../Assets/Svg/Icons/bagIcon.svg').default}
								alt="Logo React Revalida"
								style={matchesTablet ? null : { width: 50 }}
							/>
							<Typography
								className={`${classes.titleButton} poppins`}
								color="secondary"
							>
								Clique aqui para ver mais detalhes do Arena
							</Typography>
						</Button>
					</Grid>
					<Grid
						item
						xs={12}
						container
						justifyContent={'center'}
					>
						<ArenaHwmCard
							group="ARENA HARDWORK 2023.1"
							title="Cadastre-se para participar dos sorteios da live"
							titleButton={'confirmar pré-inscrição'}
							urlToRedirect={'live-arena-hardwork'}
						/>
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.35rem !important',
		alignSelf: 'flex-end',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
			lineHeight: 'unset !important',
			marginBottom: '8px !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.18rem !important',
			marginBottom: '8px !important',
			lineHeight: 'unset !important',
		},
	},
	text: {
		fontSize: '1.324rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	bottomSignInCardText: {
		fontSize: '1.15rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
	},
	divider: {
		margin: 'unset !important',
		backgroundColor: 'grey',
		minHeight: 1,
	},
	workHourTitle: {
		fontSize: '1.7em !important',
		lineHeight: 1.25,
		fontWeight: 'bold !important',
	},
	containerWorkHours: {
		padding: '2em 0 2em 2em',
		[theme.breakpoints.down('lg')]: {
			padding: 0,
		},
	},
	workHourPaper: {
		height: '100%',
		[theme.breakpoints.down('lg')]: {
			height: 'unset !important',
		},
	},
	imgExtensivo: {
		width: '100% !important',
		[theme.breakpoints.down('md')]: {
			margin: '40px 0 !important',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '0px !important',
		},
	},
	impactText: {
		fontSize: '2.25rem !important',
		margin: '24px 0 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.6rem !important',
			margin: '16px 0 !important',
		},
	},
	paper: {
		width: 777,
		marginBottom: 50,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		[theme.breakpoints.down('xl')]: {
			width: 585,
		},
		[theme.breakpoints.down('lg')]: {
			height: 'unset',
			width: 435,
			marginBottom: 30,
		},
		[theme.breakpoints.down('md')]: {
			width: 585,
		},
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
			width: 'unset',
		},
	},
	titleInformationalCard: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		textShadow: '2px 2px 4px #000000',
		margin: '8px 0 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '16px 0 !important',
			lineHeight: 'unset !important',
		},
	},
	gridDescriptionCard: {
		backgroundColor: '#FFFFFF !important',
		padding: '1em 2em',
		borderRadius: 16,
		[theme.breakpoints.down('sm')]: {
			padding: '1em',
		},
	},
	gridForm: {
		width: 567,
		position: 'fixed',
		top: 385,
		zIndex: 10,
		[theme.breakpoints.down(1300)]: {
			width: 467,
			right: 30,
			top: 120,
			// height: 500,
		},
		[theme.breakpoints.down('lg')]: {
			right: 50,
		},
		[theme.breakpoints.down(1010)]: {
			right: 20,
		},
		[theme.breakpoints.down('md')]: {
			position: 'unset',
			right: 'unset',
			top: 'unset',
			zIndex: 0,
		},
	},
	gridTextContainer: {
		paddingBottom: '1em',
		[theme.breakpoints.down('lg')]: {
			paddingRight: '2em',
		},
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
	},
	titleButton: {
		fontSize: '1.5rem !important',
		fontWeight: 'bold !important',
		marginLeft: '0.5em !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			marginLeft: 'unset !important',
		},
	},
	pdfTitleContainer: {
		fontSize: '1.3rem !important',
	},
	titlePdfBox: {
		fontSize: '0.8rem !important',
	},
	subtitlePdfContainer: {
		fontWeight: 'bold !important',
	},
	gridTitleBoxes: {
		backgroundColor: '#ffffff !important',
		padding: '0.25rem 0.5rem',
		borderRadius: 15,
		height: 35,
		display: 'flex',
		alignItems: 'center',
	},
	backgroundContainerPdfs: {
		backgroundColor: '#33333380',
		// height: '100%',
		borderBottomLeftRadius: 15,
		borderBottomRightRadius: 15,
		padding: '1.5rem',
	},
	pdfBox: {
		width: '20% !important',
		[theme.breakpoints.down('sm')]: {
			width: '30% !important',
		},
	},
	subtitle1: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('md')]: {
			lineHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
			lineHeight: 'unset !important',
		},
	},
	btn: {
		backgroundImage: `url(${require('../../../../Assets/Arena/bg-button-live-arena.png')})`,
		backgroundSize: 'cover',
		height: 76,
		border: '2px solid #ffffff !important',
		borderRadius: '15px !important',
		width: '100%',
		transition: '0.2s !important',
		'&:hover': {
			scale: 1.025,
		},
	},
	cancelIcon: {
		color: '#000000',
		right: '5px',
		'&:hover': {
			cursor: 'pointer',
		},
		[theme.breakpoints.down('sm')]: {
			top: '5px',
			right: '10px',
		},
	},
}));
