import React from 'react';
import { Container, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import { Header } from '../../../../Components/Header';
import FormV3 from '../../../../Components/Utils/Forms/PreRegistrationV3';
// import FormV2 from '../../../../Components/Utils/Forms/PreRegistrationV2';
// import Form from '../../../../Components/Utils/Forms/PreRegistration';

// const formConfig = {
// 	type: 'revalida',
// 	title: ['Inscreva-se para ser', <br />, 'avisado sobre a live!'],
// 	group: 'HARDWORK FORTALEZA 2023',
// 	fields: [
// 		{
// 			type: 'string',
// 			name: 'nome',
// 			placeholder: 'Seu nome completo',
// 			required: true,
// 		},
// 		{
// 			type: 'email',
// 			name: 'email',
// 			placeholder: 'Seu melhor e-mail',
// 			required: true,
// 		},
// 	],
// 	submitButtonText: 'Garantir minha vaga',
// 	urlToRedirect: '/',
// 	confirmationMessage: 'Sua inscrição está confirmada!',
// };

export default function LiveTipsRevalida08052023() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesDownXl = useMediaQuery(theme.breakpoints.down('xl'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					justifyContent={'space-between'}
					alignContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
				>
					<Grid
						item
						xs={12}
						md={6}
						container
						justifyContent={'flex-start'}
						className={`${classes.gridTextContainer} revalida`}
						gap={3}
					>
						<img
							src={require('../../../../Assets/Arena/logo-live-revalida.svg').default}
							alt=""
						/>
						<Typography
							className={`${classes.title} gradient-text roboto-condensed`}
							color="secondary"
						>
							Os 7 erros que podem te reprovar na prova prática do INEP
						</Typography>
						<Typography
							className={`${classes.text} poppins`}
							color="secondary"
						>
							Vamos te mostrar TUDO que tu precisas evitar a qualquer custo para dominar a prova prática
							do Revalida INEP!!!
						</Typography>
						<Typography
							align="left"
							color="secondary"
							className={`${classes.impactText} poppins`}
						>
							SEGUNDA-FEIRA
							<span className="bullet"> &#8226; </span>
							08MAI
							<span className="bullet"> &#8226; </span>
							21:30h
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						md={5}
						container
						justifyContent="center"
						// alignContent={'center'}
						className={classes.gridWithBackgroundImage}
						gap={matchesTablet ? 4 : 0}
					>
						<Grid
							item
							xs={12}
							className={classes.gridForm}
							gap={1}
						>
							<FormV3 // Medida paliativa
								group="LIVE 7 ERROS PROVA PRÁTICA INEP"
								title={['Inscreva-se para ser', <br />, 'avisado sobre a live!']}
								titleButton="Inscreva-se"
								type="revalida"
								urlToRedirect="/"
								confirmationMessage={
									'Sua inscrição está confirmada! Tu receberás o link da transmissão no email cadastrado momentos antes da live.'
								}
							/>
							{/* <Form
								group="HARDWORK SÃO LUIS 2023"
								title={['Inscreva-se para garantir sua vaga e participar do evento']}
								titleButton="Garantir minha vaga"
								type="revalida"
								urlToRedirect="/"
								confirmationMessage={
									'Sua inscrição está confirmada! Não esqueça que sua entrada está condicionada a doação de 1kg de alimento não perecível.'
								}
							/> */}
							{/* <FormV2 formConfig={formConfig} /> Implementar */}
						</Grid>
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.2rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
			marginBottom: '8px !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.18rem !important',
			marginBottom: '8px !important',
		},
	},
	text: {
		fontSize: '1.3125rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	gridForm: {
		width: 567,
		flexDirection: 'column !important',
		alignItems: 'center !important',
		[theme.breakpoints.down('xl')]: {
			bottom: 20,
		},
		[theme.breakpoints.down(1300)]: {
			width: 467,
		},
		[theme.breakpoints.down('md')]: {
			position: 'unset',
			right: 'unset',
			top: 'unset',
			zIndex: 0,
		},
	},
	gridTextContainer: {
		[theme.breakpoints.down('lg')]: {
			paddingRight: '2em',
		},
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
	},
	gridWithBackgroundImage: {
		// backgroundImage: `url(${require('../Assets/bg-residencia.png')})`,
		[theme.breakpoints.down('md')]: {
			paddingLeft: 'unset',
			backgroundImage: 'unset',
		},
	},
	impactText: {
		margin: '0 0 2rem !important',
		fontSize: '2rem !important',
		lineHeight: 'unset !important',
		background: 'linear-gradient(90deg, rgba(0,145,166,1) 10%, rgba(0,194,110,1) 100%)',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.45rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1rem !important',
		},
	},
	logo: {
		[theme.breakpoints.down('xl')]: {
			height: 'unset',
			width: 250,
		},
	},
	timelineText: {
		fontSize: '1.5rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.2rem !important',
		},
	},
	divider: {
		position: 'absolute',
		border: '1.5px solid #fff !important',
		left: '4.65em',
		backgroundColor: '#fff',
		height: '230px !important',
		zIndex: -1,
		[theme.breakpoints.down('xl')]: {
			height: '200px !important',
		},
		[theme.breakpoints.down('lg')]: {
			height: '280px !important',
		},
		[theme.breakpoints.down('md')]: {
			height: '265px !important',
		},
		[theme.breakpoints.down('sm')]: {
			height: '180px !important',
		},
		[theme.breakpoints.down(376)]: {
			height: '220px !important',
		},
	},
	imgTime: {
		height: 30,
		top: 5,
	},
}));
