import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import DefaultButton from '../../../../../Components/Buttons/DefaultButton';
import BoxInfoWithCheckIcon from './BoxInfoWithCheckIcon';
import { useEffect, useState } from 'react';
import Api from '../../../../../Services/Api';
import { Link } from 'react-router-dom';

export default function PriceCard({
	classes,
	logoProduct,
	benefits,
	benefits2,
	infoTitles,
	idTurma,
	handleOpenWaitList,
}) {
	const theme = useTheme();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const [turma, setTurma] = useState([]);
	const [lot, setLot] = useState([]);

	const currentDate = new Date();

	useEffect(() => {
		Api.get(`turmasabertas/${idTurma}`).then((res) => {
			if (res) {
				setTurma(res[0]);
			}
			const activeLot = res[0]?.lotes?.find((lot, index) => {
				return lot?.id === res[0]?.id_lote_vigente;
				// const startDate = new Date(lot.data_inicio);
				// const endDate = new Date(lot.data_limite);

				// return startDate <= currentDate && currentDate <= endDate;
			});

			if (activeLot) {
				setLot([activeLot]);
				// setLotValueLimit(new Date(activeLot?.data_limite).getTime());
			} else {
				const nextLot = res[0]?.lotes?.find((lot, index) => {
					const startDate = new Date(lot.data_inicio);

					return currentDate < startDate;
				});
				// Caso contrário, não há lote ativo, define 'lots' como vazio
				setLot([nextLot]); //
			}
			// setIsOpenSubscription(currentDate >= opening_subscription_date);
		});
	}, [idTurma]);

	function dateFormat(data) {
		if (data) {
			const dataObjeto = new Date(data);
			const dia = String(dataObjeto.getDate()).padStart(2, '0');
			const mes = String(dataObjeto.getMonth() + 1).padStart(2, '0'); // Adicionar +1 pois o mês começa em zero (janeiro é 0)
			return `${dia}/${mes}`;
		} else {
			return null;
		}
	}

	return (
		<Grid
			item
			container
			direction={'column'}
			wrap={'nowrap'}
			justifyContent={'space-between'}
			style={{
				backgroundColor: '#101010',
				borderRadius: 16,
				flex: !matchesMobile && 1,
				padding: '2rem 1rem',
				border: '1px solid #656565',
			}}
		>
			<Grid
				item
				container
				justifyContent={'center'}
				xs={12}
				style={{ borderBottom: '1px solid #CCCCCC', margin: '0 0 1rem', maxHeight: 155, paddingBottom: '1rem' }}
			>
				<img
					src={logoProduct}
					alt=""
				/>
			</Grid>
			<Grid
				item
				xs={12}
				container
				rowGap={2}
				style={{
					margin: '0.5rem 0',
					height: 'fit-content',
				}}
			>
				<Grid
					item
					container
					alignContent={'flex-start'}
					rowGap={1}
					style={{ height: 160 }}
				>
					{infoTitles?.map((item, index) => {
						return (
							<Grid
								key={index}
								item
								container
								style={{
									borderBottom: infoTitles.length - 1 > index && '1px solid #303030',
								}}
							>
								<Typography
									// className={`${classes.text}`}
									fontSize={'1rem'}
									fontFamily={'Manrope'}
									style={{ lineHeight: 'unset' }}
								>
									{item.title}
								</Typography>
							</Grid>
						);
					})}
				</Grid>
				<BoxInfoWithCheckIcon
					title={'TENHA ACESSO LOGO DE INÍCIO'}
					classes={classes}
					benefits={benefits}
				/>
				<BoxInfoWithCheckIcon
					title={'E TEM MAIS DURANTE O TRILHA'}
					classes={classes}
					benefits={benefits2}
				/>
				{/* <Grid
					item
					xs={12}
					container
					direction={'column'}
				>
					<Typography
						color={'#656565'}
						fontSize={'0.875rem'}
						fontFamily={'Manrope'}
						fontWeight={500}
					>
						De: R${' '}
						{turma?.valor?.toLocaleString('pt-BR', {
							minimumFractionDigits: 2,
						})}
					</Typography>
					<Typography
						color={'#CCCCCC'}
						fontFamily={'Manrope'}
						fontWeight={600}
						fontSize={'0.875rem'}
					>
						Por{' '}
						<strong
							style={{
								fontSize: '1.5rem',
								fontWeight: 'bold',
								fontFamily: 'Outfit',
								color: 'linear-gradient(90deg, #FF5F45 50%, #FF357D 100%)',
								display: 'inline',
							}}
							className="gradient-text-trilha-r1-residencia"
						>
							R${' '}
							{lot[0]?.valor?.toLocaleString('pt-BR', {
								minimumFractionDigits: 2,
							})}
						</strong>{' '}
						à vista
						<br />
						(5% de desconto)
					</Typography>
					{turma?.parcelas && (
						<Typography
							color={'#CCCCCC'}
							fontFamily={'Manrope'}
							fontWeight={600}
							fontSize={'0.875rem'}
						>
							{' '}
							ou de {turma?.max_parcelas}x de{' '}
							<strong
								style={{
									fontSize: '1.5rem',
									fontWeight: 'bold',
									fontFamily: 'Outfit',
									color: 'linear-gradient(90deg, #FF5F45 50%, #FF357D 100%)',
									display: 'inline',
								}}
								className="gradient-text-trilha-r1-residencia"
							>
								R${' '}
								{turma?.parcelas[turma?.parcelas?.length - 1]?.valor?.toLocaleString('pt-BR', {
									minimumFractionDigits: 2,
								})}
							</strong>{' '}
						</Typography>
					)}
					<Typography
						color={'#656565'}
						fontSize={'0.875rem'}
						fontFamily={'Manrope'}
						fontWeight={500}
					>
						Valor total: R${' '}
						{lot[0]?.valor?.toLocaleString('pt-BR', {
							minimumFractionDigits: 2,
						})}
					</Typography>
				</Grid> */}
				<Grid
					item
					xs={12}
					container
					justifyContent={'center'}
				>
					<Typography
						color={'#FCFCFC'}
						fontSize={'2.5rem'}
						fontFamily={'Bebas Neue'}
					>
						Abertura em {dateFormat(turma?.data_inicio_inscricoes)}
					</Typography>
				</Grid>
				{idTurma && (
					<DefaultButton
						variant="outlined"
						color="secondary"
						marginTop={5}
						className={`${classes.btn} btn button-info-card`}
						style={{ width: '100%' }}
						// component={Link}
						// to={`//hardworkmedicina.com.br/inscricao/${idTurma}`}
						onClick={() => handleOpenWaitList({ idTurma })}
					>
						<img
							src={require('../../../../../Assets/Arena/icon-waitList.svg').default}
							alt=""
							style={{ width: 20, marginRight: 16 }}
						/>{' '}
						{/* <img
						src={require('../../../../../Assets/Svg/Icons/bagIcon.svg').default}
						alt=""
						style={{
							width: 16,
							height: 16,
						}}
					/>
					&nbsp; Inscreva-se */}
						Avise-me!
					</DefaultButton>
				)}
			</Grid>
		</Grid>
	);
}
