import React from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import { Header } from '../../../../Components/Header';

import Form from '../../../../Components/Utils/Forms/PreRegistration';

export default function LiveR1251023Subscription() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					alignContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
				>
					<Grid
						item
						xs={12}
						md={7}
						container
					>
						<Typography
							className={`${classes.title} roboto-condensed`}
							color="secondary"
							style={matchesTablet ? { marginTop: 16, paddingLeft: 0 } : { paddingLeft: 15 }}
						>
							Como os alunos da USP se preparam para a prova da USP?
						</Typography>
						<img
							src={process.env.PUBLIC_URL + '../../../../Assets/Img/bg-live-r1.png'}
							alt="Plataforma Hardwork"
							className={classes.imgExtensivo}
						/>
						<Grid
							item
							xs={12}
							container
							justifyContent={matchesTablet ? 'center' : 'flex-start'}
							style={matchesMobile ? null : { position: 'absolute', bottom: 10, paddingLeft: 16 }}
						>
							<Typography
								align="left"
								color="secondary"
								className={`${classes.impactText} poppins`}
							>
								25OUT às 19h
							</Typography>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						md={5}
						container
						justifyContent="center"
						style={{ height: 'fit-content', alignSelf: 'flex-end' }}
					>
						<Form
							group="LIVE R1 25/10/23"
							title={['Cadastre-se gratuitamente', <br />, 'para participar!']}
							titleButton="Confirmar inscrição"
							titleConfirmationMessage={'Massa!'}
							confirmationMessage={
								'Sua inscrição está confirmada! Em breve você também receberá uma confirmação pelo e-mail cadastrado.'
							}
							urlToRedirect={'/live/estudo-usp'}
							type="residencia"
						/>
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.5rem !important',
		lineHeight: '1 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '3.2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.8rem !important',
		},
	},
	text: {
		fontSize: '1.324rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	bottomSignInCardText: {
		fontSize: '1.15rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
	},
	divider: {
		margin: 'unset !important',
		backgroundColor: 'grey',
		minHeight: 1,
	},
	workHourTitle: {
		fontSize: '1.7em !important',
		lineHeight: 1.25,
		fontWeight: 'bold !important',
	},
	containerWorkHours: {
		padding: '2em 0 2em 2em',
		[theme.breakpoints.down('lg')]: {
			padding: 0,
		},
	},
	workHourPaper: {
		height: '100%',
		[theme.breakpoints.down('lg')]: {
			height: 'unset !important',
		},
	},
	imgExtensivo: {
		[theme.breakpoints.down('md')]: {
			margin: '40px 0 !important',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '0px !important',
		},
	},
	impactText: {
		fontSize: '2.25rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.6rem !important',
		},
	},
}));
