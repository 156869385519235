import React, { useRef } from 'react';
import { Grid, Typography, useTheme, useMediaQuery, Button, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { color } from 'chart.js/helpers';

const StyledFirstSectionSprintR1 = styled(Grid)(({ theme }) => ({
	gap: theme.spacing(8),
	// paddingTop: '5px',
	[theme.breakpoints.down('md')]: {
		paddingTop: '30px',
	},
}));

export const FirstSectionSale = ({
	scrollToPayment,
	firstParagraph,
	secondParagraph,
	colorBorderButton,
	colorBgButton,
	colorTextButton,
	logoImg,
	imageApp,
	colorStartButton,
	colorEndButton,
	color,
}) => {
	const theme = useTheme();
	const classes = useStyles({ colorBorderButton, colorBgButton, colorTextButton, color });

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));
	const matchesXl = useMediaQuery(theme.breakpoints.down('xl'));

	return (
		<StyledFirstSectionSprintR1>
			<Grid
				container
				// className="section"
			>
				<Grid
					item
					container
					alignItems={'center'}
					justifyContent={'space-between'}
					flexWrap={'nowrap'}
					flexDirection={matchesTablet ? 'column' : 'row'}
				>
					<Grid
						item
						xs={12}
						md={6.5}
						container
						rowGap={5}
					>
						<Grid
							item
							container
							rowGap={ matchesTablet ? 0 : 3 }
						>
							<Grid
								item
								container
							>
								<img
									src={logoImg}
									alt={''}
									style={{ width: '95%',  }}
                                    
								/>
							</Grid>
							<Grid
								item
								container
								className={classes.containerSubtitle}
								rowGap={2}
							>
								<Typography
									color={color ? 'secondary' : 'primary'}
									fontFamily={'Manrope'}
									fontSize={matchesTablet ? '1rem' : '1.5rem'}
                                   
								>
									{firstParagraph}
								</Typography>
								<Typography
									color={color ? 'secondary' : 'primary'}
									fontFamily={'Manrope'}
									fontSize={matchesTablet ? '1rem' : '1.5rem'}
								>
									{secondParagraph}
								</Typography>
							</Grid>
						</Grid>
						{!matchesTablet && (
							<Grid
								item
								container
								className="residencia"
								style={{ marginTop: '2rem' }}
							>
								{/* <CTASprintR1 text={'Inscreva-se'} scrollToPayment={scrollToPayment} /> */}
								<Button
									className={`${classes.button}`}
									onClick={() => scrollToPayment()}
									style={{
										':hover': {
											background: `linear-gradient(45deg, ${colorStartButton} 30%, ${colorEndButton} 90%) !important`,
										},
										borderColor: colorBorderButton,
										color: colorBorderButton,
										borderRadius: '25px ',
										border: '2px solid',
										padding: '0.5rem 3rem',
									}}
								>
									<Typography
										className={`${classes.buttonText}`}
										fontFamily={'Manrope'}
									>
										Inscreva-se
									</Typography>
								</Button>
							</Grid>
						)}
					</Grid>
					<Grid
						item
						xs={12}
						md={4.5}
						container
						justifyContent={'flex-end'}
					>
						<img
							src={imageApp}
							alt={''}
							className={classes.imgPhone}
                            style={{marginTop: matchesMobile ? 20 : 0 }}
						/>
					</Grid>
					{matchesTablet && (
						<Button
							className={`${classes.button}`}
							onClick={() => scrollToPayment()}
							style={{
								':hover': {
									background: `linear-gradient(45deg, ${colorStartButton} 30%, ${colorEndButton} 90%) !important`,
								},
								borderColor: colorBorderButton,
								color: colorBorderButton,
								borderRadius: '25px ',
								border: '2px solid',
								padding: '0.5rem 3rem',
								marginTop: '2rem',
							}}
						>
							<Typography
								className={`${classes.buttonText}`}
								fontFamily={'Manrope'}
							>
								Inscreva-se
							</Typography>
						</Button>
					)}
				</Grid>
				{/* {!matchesTablet && (
					<Grid
						container
						alignItems={'center'}
						justifyContent={'center'}
					>
						<img
							src={iconArrowDownGradient}
							width={35}
							className={classes.arrowNextSection}
							alt=""
						/>
					</Grid>
				)} */}
			</Grid>
		</StyledFirstSectionSprintR1>
	);
};

const useStyles = makeStyles((theme) => ({
	btnMobile: (props) => ({
		border: '2px solid',
		width: '100% !important',
		background: props.colorBgButton,
		borderRadius: '40px !important',
		textTransform: 'none !important',
		fontSize: '20px !important',
		marginTop: '32px !important',
		marginBottom: '40px !important',
		transition: 'all 0.2s ease-in-out !important',
		color: props.colorBorderButton,
		'&:hover': {
			transform: 'scale(1.10)',
		},
	}),
	btnLargeScreens: {
		width: '40%',
		borderRadius: '40px !important',
		fontFamily: 'Poppins !important',
		textTransform: 'none !important',
		fontSize: '18px !important',
		marginTop: '64px !important',
		transition: 'all 0.2s ease-in-out !important',
		'&:hover': {
			transform: 'scale(1.10)',
		},
	},
	containerSubtitle: {
		width: '100%',
		[theme.breakpoints.down('xl')]: {
			marginTop: '40px !important',
		},
        [theme.breakpoints.down('sm')]: {
			marginTop: '20px !important',
		},
	},
    
	imgPhone: {
		maxWidth: '700px',
		[theme.breakpoints.down('lg')]: {
			maxWidth: '100%',
		},
	},
	arrowNextSection: {
		transition: 'all 0.2s ease-in-out',
		marginTop: '20px',
		paddingBottom: '30px',
		cursor: 'pointer',
		'&:hover': {
			transform: 'scale(1.10)',
		},
	},
	boldText: {
		fontFamily: 'Manrope',
		fontWeight: 600,
	},
	button: {
		height: 'fit-content',
		borderRadius: '25px !important ',
		border: '2px solid !important ',
		position: 'relative',
		overflow: 'hidden',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2) !important ',
		cursor: 'pointer',
		transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
		'&:hover': {
			transform: 'scale(1.05)',
			boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.3)',
		},
	},
	buttonText: (props) => ({
		textTransform: 'none !important',
		fontSize: '1.25rem !important',
		fontWeight: '500 !important',
		// color: props.colorTextButton,
		// '&:hover': {
		// 	color: '#fff !important',
		// },
	}),
}));
