import { useState } from 'react';
import { useRef } from 'react';
import { Button, Container, Divider, Grid, Modal, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import FlipCard from './FlipCard';
import PurchaseCard from './PurchaseCard';
import DefaultButton from '../../../../../Components/Buttons/DefaultButton';
import OnlineBonusCard from './onlineBonusContainer';

const presencialTraining = [
	{
		id: 1,
		img: require('../../../../../Assets/Arena/icon-intense-training.svg').default,
		title: ['2 dias de treinamento intenso'],
		description: [
			'Dois dias de programação',
			<strong
				style={{ margin: '0 auto' }}
				className="gradient-text"
			>
				intensa e muito dinâmica
			</strong>,
			'para entrar de vez na alma da prova prática do INEP.',
		],
	},
	{
		id: 2,
		img: require('../../../../../Assets/Arena/icon-stations-of-training.svg').default,
		title: ['+ de 20 estações para treinamento no novo formato INEP'],
		description: [
			'Mais de ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				20 estações para treinamento intenso
			</strong>,
			'; todas as estações simulam em detalhes a prova prática do INEP.',
		],
	},
	{
		id: 3,
		img: require('../../../../../Assets/Arena/icon-discussion-checklist-black.svg').default,
		title: ['Discussões de estações e checklist'],
		description: [
			'No presencial temos uma oportunidade adicional de ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				aprender estações e aprender checklists
			</strong>,
			'.',
		],
	},
	{
		id: 4,
		img: require('../../../../../Assets/Arena/icon-training-skills-black.svg').default,
		title: ['Oficinas para treinamento de', <br />, 'habilidades'],
		description: [
			'Treinamento de ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				habilidades específicas
			</strong>,
			' em pequenos grupos.',
		],
	},
	{
		id: 5,
		img: require('../../../../../Assets/Arena/icon-personal-guidelines-black.svg').default,
		title: ['Orientações personalizadas'],
		description: [
			'Orientações personalizadas do time Hardwork, com ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				professores e instrutores que já dominaram
			</strong>,
			' a prova prática do INEP.',
		],
	},
	{
		id: 6,
		img: require('../../../../../Assets/Arena/icon-presencial-training-black.svg').default,
		title: ['Treinamento de gatilhos de', <br />, 'prova prática'],
		description: [
			'O jogo da prova prática exige raciocínio rápido. ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				Uma informação pode mudar tudo...
			</strong>,
			'são os "gatilhos de estação". No PRESENCIAL, treinamos brincando!',
		],
	},
	{
		id: 7,
		img: require('../../../../../Assets/Arena/icon-food-black.svg').default,
		title: ['Café da manhã, almoço ', <br />, 'e lanche'],
		description: [
			'Momentos de integração com teus colegas, professores e instrutores. ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				Trocas riquíssimas
			</strong>,
			' durante os dois dias.',
		],
	},
];

const onlineTraining = [
	{
		id: 1,
		img: require('../../../../../Assets/Arena/icon-60-stations-white.svg').default,
		title: ['+ de 60 estações', <br />, 'de aprendizagem'],
		description: [
			'Várias estações que ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				simulam a prova prática
			</strong>,
			' do INEP. Algumas no formato interativo, outras no modelo de vídeo. Todas com orientação do professor.',
		],
	},
	{
		id: 2,
		img: require('../../../../../Assets/Arena/icon-discussions-by-teachers-white.svg').default,
		title: ['Discussões de estações ', <br />, 'pelos professores'],
		description: [
			'Entre na alma de cada estação, saiba exatamente ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				o que é esperado da banca
			</strong>,
			' para diversas situações, revise checklists e assimile conceitos importantes.',
		],
	},
	{
		id: 3,
		img: require('../../../../../Assets/Arena/icon-learn-white.svg').default,
		title: ['Aprenda avaliando', <br />, 'outros alunos'],
		description: [
			'Entre na cabeça do examinador, ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				veja pelo ponto de vista dele
			</strong>,
			' o que é esperado em cada situação e avalie o desempenho de outro aluno.',
		],
	},
	{
		id: 4,
		img: require('../../../../../Assets/Arena/icon-learning-at-different-stations-white.svg').default,
		title: ['Aprenda em estações', <br />, 'interativas'],
		description: [
			'Interaja com a plataforma para ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				treinar a busca de elementos numa estação
			</strong>,
			'. É a hora de aprender a fazer as perguntas.',
		],
	},
	{
		id: 5,
		img: require('../../../../../Assets/Arena/icon-lung-white.svg').default,
		title: ['+ de 150 casos para', <br />, 'aprendizado teórico'],
		description: [
			'É fundamental ter conhecimento teórico na prova prática! No ARENA nós fazemos isso de forma diferente: ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				mais de 150 casos para revisar os temas
			</strong>,
			' que podem aparecer na prova prática do INEP.',
		],
	},
	{
		id: 6,
		img: require('../../../../../Assets/Arena/icon-teacher-orientation-white.svg').default,
		title: ['Para cada caso, uma orientação ', <br />, 'em vídeo do professor'],
		description: [
			'Os casos temáticos trazem consigo ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				orientações do professor em vídeo
			</strong>,
			' sobre o tema.',
		],
	},
	{
		id: 7,
		img: require('../../../../../Assets/Arena/icon-cyclic-revisions-white.svg').default,
		title: ['Revisões cíclicas', <br />, 'automáticas '],
		description: [
			'Além de estudar os casos, vamos ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				revisar SEMPRE
			</strong>,
			', até a proximidade da prova! Não podemos esquecer nada.',
		],
	},
	{
		id: 8,
		img: require('../../../../../Assets/Arena/icon-dm-teachers-white.svg').default,
		title: ['Contato direto', <br />, 'com o professor'],
		description: [
			'É isso mesmo, tenha acesso aos ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				contatos de todos os professores envolvidos no projeto
			</strong>,
			'. E-mail e Instagram, para tirar suas dúvidas e compartilhar experiências.',
		],
	},
	{
		id: 9,
		img: require('../../../../../Assets/Arena/icon-exam-resources-white.svg').default,
		title: ['Orientações para', <br />, 'recursos de prova'],
		description: [
			'Obtenha apoio dos professores Hardwork para ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				avaliar a possibilidade de recursos
			</strong>,
			' para suas estações da prova prática',
		],
	},
];

const photoGallery = [
	{
		id: 1,
		img: require('../../../../../Assets/Arena/gallery-arena-2022-1.png'),
	},
	{
		id: 2,
		img: require('../../../../../Assets/Arena/gallery-arena-2022-2.png'),
	},
	{
		id: 3,
		img: require('../../../../../Assets/Arena/gallery-arena-2022-3.png'),
	},
	{
		id: 4,
		img: require('../../../../../Assets/Arena/gallery-arena-2022-4.png'),
	},
	{
		id: 5,
		img: require('../../../../../Assets/Arena/gallery-arena-2022-5.png'),
	},
	{
		id: 6,
		img: require('../../../../../Assets/Arena/gallery-arena-2022-6.png'),
	},
	{
		id: 7,
		img: require('../../../../../Assets/Arena/gallery-arena-2022-7.png'),
	},
	{
		id: 8,
		img: require('../../../../../Assets/Arena/gallery-arena-2022-8.png'),
	},
	{
		id: 9,
		img: require('../../../../../Assets/Arena/gallery-arena-2022-9.png'),
	},
	{
		id: 10,
		img: require('../../../../../Assets/Arena/gallery-arena-2022-10.png'),
	},
	{
		id: 11,
		img: require('../../../../../Assets/Arena/gallery-arena-2022-11.png'),
	},
	{
		id: 12,
		img: require('../../../../../Assets/Arena/gallery-arena-2022-12.png'),
	},
	{
		id: 13,
		img: require('../../../../../Assets/Arena/gallery-arena-2022-13.png'),
	},
	{
		id: 14,
		img: require('../../../../../Assets/Arena/gallery-arena-2022-14.png'),
	},
	{
		id: 15,
		img: require('../../../../../Assets/Arena/gallery-arena-2022-15.png'),
	},
	{
		id: 16,
		img: require('../../../../../Assets/Arena/gallery-arena-2022-16.png'),
	},
	{
		id: 17,
		img: require('../../../../../Assets/Arena/gallery-arena-2022-17.png'),
	},
	{
		id: 18,
		img: require('../../../../../Assets/Arena/gallery-arena-2022-18.png'),
	},
	{
		id: 19,
		img: require('../../../../../Assets/Arena/gallery-arena-2022-19.png'),
	},
];

export default function ToggleContainer({ arena_card, executeScroll }) {
	const classes = useStyles();
	const theme = useTheme();
	const arena_card_container = useRef(null);
	const [isContainerOpen, setIsContainerOpen] = useState(false);
	const [activeButtonId, setActiveButtonId] = useState(null);
	const [open, setOpen] = useState(false);
	// const [selectedImageId, setSelectedImageId] = useState(null);
	const [selectedImageIndex, setSelectedImageIndex] = useState(0);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const handleOpen = (index) => {
		setSelectedImageIndex(index);
		// setSelectedImageId(imageId);
		setOpen(true);
	};

	const handleClose = () => {
		// setSelectedImageId(null);
		setOpen(false);
	};

	const handleButtonClick = (buttonId) => {
		setActiveButtonId(buttonId);
		setIsContainerOpen(true);
		executeScroll();
	};

	return (
		<>
			<Grid
				container
				className={`${!isContainerOpen ? classes.containerBackground : null}`}
				ref={arena_card}
			>
				<Grid
					container
					className="section"
					style={{ margin: '0 auto' }}
				>
					<Grid
						item
						container
						className={`${classes.mainContainer} ${isContainerOpen ? classes.containerBackground : null}`}
						gap={matchesMobile ? 1 : 4}
					>
						<Grid
							item
							container
							style={matchesMobile ? { marginBottom: '1rem' } : null}
						>
							<Typography
								align={matchesMobile ? 'center' : 'left'}
								color="secondary"
								className={`${classes.optionTitle} roboto-condensed`}
							>
								{activeButtonId === 1
									? 'Tu estás selecionando o Arena Presencial + Online'
									: activeButtonId === 2
									? 'Tu estás selecionando o Arena Online'
									: 'Primeiro, de que tipo de Arena tu vais participar?'}
							</Typography>
						</Grid>
						<Grid
							item
							container
							justifyContent={'space-around'}
							alignContent={'flex-start'}
							// style={matchesMobile ? { padding: '0 0.75em' } : null}
						>
							<Grid
								item
								xs={6}
								lg={5}
								container
								justifyContent={'flex-start'}
								alignContent={'center'}
								className="revalida"
							>
								<Button
									variant="contained"
									color={activeButtonId === 1 ? 'primary' : 'secondary'}
									className={`${classes.btn} ${
										activeButtonId === 1 ? classes.whiteBorder : classes.transparentBorder
									} poppins ${activeButtonId === 1 ? 'white-btn-secondary' : null}`}
									onClick={() => handleButtonClick(1)}
									style={{ flexDirection: 'column' }}
								>
									<Grid
										item
										container
										justifyContent={'center'}
										columnGap={matchesMobile ? 1 : 4}
									>
										<img
											src={
												require('../../../../../Assets/Arena/icon-arena-presencial.svg').default
											}
											alt=""
											className={`${classes.iconButton} ${
												activeButtonId === 1 ? classes.activeIcons : null
											}`}
										/>
										<img
											src={require('../../../../../Assets/Arena/icon-arena-online.svg').default}
											alt=""
											className={`${classes.iconButton} ${
												activeButtonId === 1 ? classes.activeIcons : null
											}`}
										/>
									</Grid>
									<Typography
										align="center"
										color={activeButtonId === 1 ? 'secondary' : 'primary'}
										className={`${classes.titleSelectButton} poppins`}
									>
										PRESENCIAL + ONLINE
									</Typography>
									<Grid
										item
										container
										justifyContent={'center'}
										className={classes.gridSelectButton}
									>
										<DefaultButton
											variant="contained"
											color={activeButtonId === 1 ? 'secondary' : 'primary'}
											className={`${classes.selectButton} poppins`}
										>
											{activeButtonId === 1 ? 'Selecionado' : 'Selecione'}
										</DefaultButton>
									</Grid>
								</Button>
							</Grid>
							<Grid
								item
								xs={6}
								lg={5}
								container
								justifyContent={'flex-end'}
								alignContent={'center'}
								className="revalida"
							>
								<Button
									variant="contained"
									color={activeButtonId === 2 ? 'primary' : 'secondary'}
									className={`${classes.btn} ${
										activeButtonId === 2 ? classes.whiteBorder : classes.transparentBorder
									} poppins ${activeButtonId === 2 ? 'white-btn-secondary' : null}`}
									onClick={() => handleButtonClick(2)}
									style={{ flexDirection: 'column' }}
								>
									<Grid
										item
										container
										justifyContent={'center'}
									>
										<img
											src={require('../../../../../Assets/Arena/icon-arena-online.svg').default}
											alt=""
											className={`${classes.iconButton} ${
												activeButtonId === 2 ? classes.activeIcons : null
											}`}
										/>
									</Grid>
									<Typography
										align="center"
										color={activeButtonId === 2 ? 'secondary' : 'primary'}
										className={`${classes.titleSelectButton} poppins`}
									>
										EXCLUSIVAMENTE ONLINE
									</Typography>
									<Grid
										item
										container
										justifyContent={'center'}
										// style={{ position: 'absolute', width: '86%', bottom: -30 }}
										className={classes.gridSelectButton}
									>
										<DefaultButton
											variant="contained"
											color={activeButtonId === 2 ? 'secondary' : 'primary'}
											className={`${classes.selectButton} poppins`}
										>
											{activeButtonId === 2 ? 'Selecionado' : 'Selecione'}
										</DefaultButton>
									</Grid>
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{activeButtonId ? (
				<>
					<Grid
						className={classes.containerBackgroundColor}
						ref={arena_card_container}
					>
						<Grid
							item
							xs={12}
							container
							style={matchesMobile ? { marginBottom: 16, paddingLeft: '1em' } : { left: '2em' }}
							className="revalida"
						>
							<Typography
								className={`${classes.subtitle1} roboto-condensed gradient-text`}
								color="primary"
							>
								{activeButtonId === 1
									? 'No treinamento presencial tu receberás:'
									: 'No treinamento online tu receberás:'}
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							container
							justifyContent={'center'}
							style={{ marginTop: matchesMobile ? 0 : '2rem', paddingBottom: '2rem' }}
						>
							{activeButtonId === 1 ? (
								<>
									{presencialTraining.map((item, index) => {
										return (
											<Grid
												key={item.id}
												item
												xs={6}
												md={/*activeButtonId === 1 ? 4 : */ 3}
												container
												justifyContent={'center'}
												className={`${classes.gridFlipCard} revalida`}
											>
												<FlipCard
													key={item.id}
													image={item.img}
													title={item.title}
													description={item.description}
													color="secondary"
													mobile={matchesMobile}
													size={/*activeButtonId === 1 ? 4 : */ 3}
													classes={classes}
												/>
											</Grid>
										);
									})}
									<Grid
										item
										xs={12}
										container
										justifyContent={'center'}
									>
										<Divider
											variant="middle"
											className={classes.divider}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										container
										style={
											matchesMobile
												? { marginBottom: 16, paddingLeft: '1em' }
												: { left: '2em', marginBottom: '2rem' }
										}
										className="revalida"
									>
										<Typography
											className={`${classes.subtitle1} roboto-condensed gradient-text`}
											color="primary"
										>
											E ainda, tu também receberás no treinamento online:
										</Typography>
									</Grid>
									{!matchesTablet ? (
										<>
											{' '}
											{onlineTraining.map((item, index) => {
												if (item.id < 5) {
													return (
														<Grid
															key={index}
															item
															xs={6}
															md={3}
															container
															justifyContent={'center'}
															className={`${classes.containerFlipCard} revalida`}
														>
															<FlipCard
																key={index}
																image={item.img}
																title={item.title}
																description={item.description}
																color="secondary"
																mobile={matchesMobile}
																size={3}
																classes={classes}
															/>
														</Grid>
													);
												} else {
													return null;
												}
											})}{' '}
										</>
									) : (
										<>
											{onlineTraining.map((item, index) => {
												return (
													<Grid
														key={index}
														item
														xs={6}
														md={3}
														container
														justifyContent={'center'}
														className={`${classes.containerFlipCard} revalida`}
													>
														<FlipCard
															key={index}
															image={item.img}
															title={item.title}
															description={item.description}
															color="secondary"
															mobile={matchesMobile}
															size={3}
															classes={classes}
														/>
													</Grid>
												);
											})}{' '}
										</>
									)}
									{!matchesTablet && (
										<>
											{' '}
											{onlineTraining.map((item, index) => {
												if (item.id > 4 && item.id < 8) {
													return (
														<Grid
															key={index}
															item
															xs={6}
															md={3}
															container
															justifyContent={'center'}
															className={`${classes.containerFlipCard} revalida`}
														>
															<FlipCard
																key={index}
																image={item.img}
																title={item.title}
																description={item.description}
																color="secondary"
																mobile={matchesMobile}
																size={3}
																classes={classes}
															/>
														</Grid>
													);
												} else {
													return null;
												}
											})}
											<Grid
												item
												container
												justifyContent={'center'}
											>
												{onlineTraining.map((item, index) => {
													if (item.id > 7) {
														return (
															<Grid
																key={index}
																item
																xs={6}
																md={3}
																container
																justifyContent={'center'}
																className={`${classes.containerFlipCard} revalida`}
															>
																<FlipCard
																	key={index}
																	image={item.img}
																	title={item.title}
																	description={item.description}
																	color="secondary"
																	mobile={matchesMobile}
																	size={3}
																	classes={classes}
																/>
															</Grid>
														);
													} else {
														return null;
													}
												})}
											</Grid>{' '}
										</>
									)}
								</>
							) : (
								<>
									{onlineTraining.map((item, index) => {
										if (item.id < 8) {
											return (
												<Grid
													key={index}
													item
													xs={6}
													md={activeButtonId === 1 ? 4 : 3}
													container
													justifyContent={'center'}
													className={`${classes.containerFlipCard} revalida`}
												>
													<FlipCard
														key={index}
														image={item.img}
														title={item.title}
														description={item.description}
														color="secondary"
														mobile={matchesMobile}
														size={activeButtonId === 1 ? 4 : 3}
														classes={classes}
													/>
												</Grid>
											);
										} else {
											return null;
										}
									})}
									{
										<Grid
											item
											container
											justifyContent={'center'}
										>
											{onlineTraining.map((item, index) => {
												if (item.id > 7) {
													return (
														<Grid
															key={index}
															item
															xs={6}
															md={3}
															container
															justifyContent={'center'}
															className={`${classes.containerFlipCard} revalida`}
														>
															<FlipCard
																key={index}
																image={item.img}
																title={item.title}
																description={item.description}
																color="secondary"
																mobile={matchesMobile}
																size={3}
																classes={classes}
															/>
														</Grid>
													);
												} else {
													return null;
												}
											})}
										</Grid>
									}
								</>
							)}

							<Grid
								item
								xs={12}
								container
								justifyContent="center"
								rowGap={2}
								style={{ marginTop: '2rem' }}
							>
								<Grid
									item
									xs={10}
									sm={7}
									md={5}
									lg={4}
									container
									justifyContent="space-around"
									className={classes.multimidiaContainer}
									style={{ marginBottom: '2rem' }}
									gap={1}
								>
									<img
										src={require('../../../../../Assets/Arena/multidiaHwmBlack.svg').default}
										alt="Multimídia Hardwork"
									/>
									<Typography
										color="primary"
										className={`${classes.textMultimidiaCard} poppins`}
									>
										Ganhe acesso ao novo treinamento
										<br />
										multimídia 2023 do HWM
									</Typography>
								</Grid>
								{activeButtonId !== 2 && (
									<Grid
										item
										xs={12}
										container
										wrap={'nowrap'}
										columnGap={1.5}
										style={{ backgroundColor: '#0D0D0D', padding: '1rem', borderRadius: 10 }}
									>
										<Typography
											color="secondary"
											style={{ fontSize: '2rem', alignSelf: 'center' }}
										>
											*
										</Typography>
										<Typography
											color="secondary"
											className={`${classes.disclaimerText} poppins`}
										>
											O acesso ao Arena Online que acompanha o curso presencial, garante acesso
											aos casos temáticos ("Revise o conteúdo"), às arenas online ("Aprenda
											estações"), às arenas interativas ("Jogo das perguntas") e ao treinamento
											multimídia. Para os alunos do modelo PRESENCIAL, as 03 estações simuladas do
											modelo online, serão feitas e discutidas exclusivamente no treinamento
											presencial, juntamente com os mais de 20 cenários do Arena PRESENCIAL.
										</Typography>
									</Grid>
								)}
							</Grid>
						</Grid>
					</Grid>
					{activeButtonId === 2 ? <OnlineBonusCard /> : null}
					{activeButtonId === 1 ? (
						<Grid className={classes.imagesContainer}>
							<Grid
								item
								xs={12}
								container
								style={matchesMobile ? { marginBottom: 16 } : { left: '2em', marginTop: 16 }}
								className="revalida"
							>
								<Typography
									className={`${classes.subtitle1} roboto-condensed gradient-text`}
									style={{ marginBottom: '1rem !important' }}
									color="primary"
								>
									Confira como foi o último Arena Hardwork presencial:
								</Typography>
							</Grid>
							<Grid
								container
								justifyContent={'space-between'}
								style={{ padding: matchesMobile ? 'unset' : '1rem 2rem' }}
							>
								<Splide
									className={classes.splideMainContainer}
									options={matchesMobile && { arrows: false, autoplay: true, rewind: true }}
								>
									{!matchesMobile ? (
										<>
											<SplideSlide>
												<Grid
													item
													container
													justifyContent={'space-around'}
												>
													{photoGallery.map((item, index) => {
														if (item.id < 7) {
															return (
																<Grid
																	item
																	key={item.id}
																	className={classes.gridImage}
																	onClick={() => handleOpen(index)}
																>
																	<img
																		src={item.img}
																		alt=""
																		className={classes.image}
																	/>
																</Grid>
															);
														} else {
															return null;
														}
													})}
												</Grid>
											</SplideSlide>
											<SplideSlide>
												<Grid
													item
													container
													justifyContent={'space-around'}
												>
													{photoGallery.map((item, index) => {
														if (item.id >= 7 && item.id < 13) {
															return (
																<Grid
																	item
																	key={item.id}
																	className={classes.gridImage}
																	onClick={() => handleOpen(index)}
																>
																	<img
																		src={item.img}
																		alt=""
																		className={classes.image}
																	/>
																</Grid>
															);
														} else {
															return null;
														}
													})}
												</Grid>
											</SplideSlide>
											<SplideSlide>
												<Grid
													item
													container
													justifyContent={'space-around'}
												>
													{photoGallery.map((item, index) => {
														if (item.id >= 13 && item.id < 19) {
															return (
																<Grid
																	item
																	key={item.id}
																	className={classes.gridImage}
																	onClick={() => handleOpen(index)}
																>
																	<img
																		src={item.img}
																		alt=""
																		className={classes.image}
																	/>
																</Grid>
															);
														} else {
															return null;
														}
													})}
												</Grid>
											</SplideSlide>
										</>
									) : (
										<>
											{photoGallery.map((item, index) => {
												return (
													<SplideSlide
														key={item.id}
														style={{ width: '100%', height: '100%' }}
													>
														<img
															src={item.img}
															alt=""
															style={{ borderRadius: 15 }}
														/>
													</SplideSlide>
												);
											})}
										</>
									)}
								</Splide>
							</Grid>
							<Modal
								open={open}
								onClose={handleClose}
								aria-labelledby="modal-modal-title"
								aria-describedby="modal-modal-description"
							>
								<Grid
									style={{
										position: 'absolute',
										top: '50%',
										left: '50%',
										transform: 'translate(-50%, -50%)',
										width: '70%',
									}}
								>
									<Splide
										options={{
											arrows: true,
											start: selectedImageIndex,
											paginationKeyboard: true,
											keyboard: 'focused',
											accessibility: true,
										}}
										className={classes.splideContainer}
									>
										{photoGallery.map((item) => {
											return (
												<SplideSlide
													key={item.id}
													style={{ width: '100%', height: '100%' }}
												>
													<img
														src={item.img}
														alt=""
														style={{ width: '100%' }}
													/>
												</SplideSlide>
											);
										})}
									</Splide>
								</Grid>
							</Modal>
						</Grid>
					) : null}
				</>
			) : null}
			<Grid
				container
				className="section-revalida-background"
			>
				<Grid
					container
					className={classes.backgroundWaves}
				>
					<Grid
						container
						className="section"
						style={{ marginBottom: matchesMobile ? 20 : null }}
					>
						<Container
							fixed
							maxWidth="xl"
							disableGutters={true}
						>
							<Grid
								item
								xs={12}
								container
								style={{ marginBottom: 16 }}
							>
								<Typography
									className={`${classes.subtitle} roboto-condensed`}
									color="secondary"
								>
									Garanta sua inscrição
								</Typography>
							</Grid>
							{!activeButtonId ? (
								<Grid
									item
									container
									alignContent="center"
									justifyContent="center"
									className={classes.glassContainer}
								>
									<Grid
										item
										container
										justifyContent={'center'}
										style={matchesMobile ? { marginBottom: '1em' } : null}
									>
										<img
											src={require('../../../../../Assets/Arena/logo-lock.svg').default}
											alt=""
										/>
									</Grid>
									<Grid
										item
										container
										alignContent="center"
										justifyContent="center"
									>
										<Typography
											align="center"
											color="secondary"
											className={`${classes.optionTitle} roboto-condensed`}
										>
											Selecione acima de qual Arena tu vais participar para visualizar os valores
											e fazer inscrição.
										</Typography>
									</Grid>
								</Grid>
							) : null}
							<PurchaseCard
								activeButtonId={activeButtonId}
								idTurma={activeButtonId === 1 || !activeButtonId ? '1248' : '1249'}
								urlToRedirect="/arena-hardwork"
								confirmationMessage={
									'Sua inscrição na lista de espera está confirmada! Avisaremos caso surjam novas vagas.'
								}
							/>
							{activeButtonId === 2 && (
								<Typography
									color="primary"
									className={`${classes.disclaimerText2} poppins`}
									style={{ marginTop: '1.5rem' }}
								>
									{' '}
									*Válido para alunos Extensivo Revalida, Sprint Final Revalida ou Arena Hardwork sem
									pendências financeiras.
								</Typography>
							)}
						</Container>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}

const useStyles = makeStyles((theme) => ({
	containerBackground: {
		backgroundImage: `url(${require('../../../../../Assets/Arena/background-options-container.png')})`,
		backgroundSize: 'cover',
	},
	backgroundWaves: {
		backgroundImage: `url(${process.env.PUBLIC_URL + '/Assets/Img/bgWave2.png)'})`,
		width: '100vw !important',
	},
	mainContainer: {
		borderTopLeftRadius: 15,
		borderTopRightRadius: 15,
		padding: '6em 2em 7.5em',
		[theme.breakpoints.down('xl')]: {
			padding: '4em 2.5em 6em',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '1em 0 2em',
			height: 'unset',
		},
	},
	optionTitle: {
		fontSize: '4rem !important',
		fontWeight: 'bold !important',
		textShadow: '0 0 0.2em #121213, 0 0 0.2em #0c0c0d',
		alignSelf: 'flex-end',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.75rem !important',
		},
	},
	btn: {
		width: '95%',
		fontSize: '2rem !important',
		fontWeight: 'bold !important',
		borderRadius: '15px !important',
		padding: '1.75rem 2rem !important',
		textTransform: 'unset !important',
		backgroundColor: '#ffffff !important',
		'&:hover': {
			'& $selectButton': {
				'-webkit-box-shadow': '0px 0px 30px 0px rgba(255, 255, 255, 1)',
				'-moz-box-shadow': '0px 0px 30px 0px rgba(255, 255, 255, 1)',
				boxShadow: '0px 0px 30px 0px rgba(255, 255, 255, 1)',
			},
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			width: '95%',
			padding: '1rem 0.5rem !important',
		},
	},
	whiteBorder: {
		border: '4px solid #fff !important',
		fontSize: '2.25rem !important',
		height: '105%',
		[theme.breakpoints.down('md')]: {
			fontSize: '1.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	transparentBorder: {
		border: '4px solid transparent !important',
		'&:hover': {
			border: '4px solid green !important',
			backgroundColor: '#B7E9D5 !important',
		},
	},
	subtitle: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		marginBottom: '1rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.7rem !important',
			marginBottom: '0.5rem !important',
		},
	},
	subtitle1: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('md')]: {
			lineHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
			lineHeight: 'unset !important',
		},
	},
	textMultimidiaCard: {
		fontSize: '1.12rem !important',
		alignSelf: 'center',
		fontWeight: '500 !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '1.05rem !important',
		},
		[theme.breakpoints.down(710)]: {
			fontSize: '0.9rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.7rem !important',
		},
	},
	splideMainContainer: {
		'& .splide__arrow': {
			width: '4em',
			height: '4em',
			marginTop: '23%',
		},
		'& .splide__arrow svg': {
			height: '2em',
			width: '2em',
		},
		'& .splide__pagination': {
			display: 'flex !important',
			bottom: '-0.5em !important',
		},
		'& .splide__track--draggable': {
			margin: '.5em 0 !important',
		},
		[theme.breakpoints.down('sm')]: {
			'& .splide__pagination': {
				bottom: '-1.25em !important',
			},
		},
	},
	splideContainer: {
		'& .splide__arrow': {
			width: '4em',
			height: '4em',
			marginTop: '30%',
		},
		'& .splide__arrow svg': {
			height: '2em',
			width: '2em',
		},
	},
	containerBackgroundColor: {
		backgroundColor: '#33333380',
		margin: '0 auto 30px',
		borderBottomLeftRadius: 15,
		borderBottomRightRadius: 15,
		maxWidth: '1410px !important',
		padding: '2vh 2.5% 3vh !important',
		[theme.breakpoints.down('sm')]: {
			padding: '3vh 2.5% 0 !important',
		},
	},
	multimidiaContainer: {
		backgroundColor: '#ffffff',
		borderRadius: 10,
		padding: 8,
		height: 148,
	},
	imagesContainer: {
		backgroundColor: '#33333380',
		marginTop: 0,
		borderRadius: 15,
		maxWidth: '1410px',
		margin: '60px auto !important',
		paddingTop: '1rem',
		[theme.breakpoints.down('sm')]: {
			padding: '2vh 5% 4vh !important',
		},
	},
	divider: {
		minHeight: 1,
		backgroundColor: '#6D6D6D',
		width: '90%',
		margin: '2rem 0 !important',
	},
	glassContainer: {
		backdropFilter: 'blur(5px)',
		boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.37)',
		height: '100%',
		width: '100%',
		position: 'absolute',
		zIndex: 1,
		borderRadius: 15,
		[theme.breakpoints.down('sm')]: {
			padding: '0 1.5rem ',
		},
	},
	containerFlipCard: {
		margin: '15px 0 !important',
		height: 270,
		padding: '0 1rem ',
		[theme.breakpoints.down('sm')]: {
			padding: 'unset',
			height: 200,
		},
	},
	gridFlipCard: {
		margin: '15px 0 !important',
		height: 270,
		padding: '0 1rem',
		[theme.breakpoints.down('sm')]: {
			padding: 'unset',
			height: 180,
		},
	},
	gridImage: {
		borderRadius: 15,
		width: '31%',
		margin: '1rem 0 !important',
		backgroundColor: '#ffffff !important',
	},
	image: {
		borderRadius: 15,
		// transition: 'ease-in-out 2s',
		'&:hover': {
			opacity: 0.85,
			cursor: 'pointer',
		},
	},
	titleSelectButton: {
		fontSize: '2.1rem !important',
		fontWeight: 'bold !important',
		margin: '1rem 0 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			margin: '0.5rem 0 !important',
		},
		[theme.breakpoints.down(394)]: {
			fontSize: '0.75rem !important',
		},
		[theme.breakpoints.down(361)]: {
			fontSize: '0.7rem !important',
		},
	},
	selectButton: {
		fontSize: '1.5rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	activeIcons: {
		filter: 'invert(100%) !important',
	},
	iconButton: {
		width: '40%',
	},
	gridSelectButton: {
		position: 'absolute',
		width: '86% !important',
		bottom: -30,
		[theme.breakpoints.down('sm')]: {
			bottom: -22,
		},
	},
	disclaimerText: {
		fontSize: '0.8rem !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.6rem !important',
		},
	},
	disclaimerText2: {
		fontSize: '0.95rem !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.85rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.7rem !important',
		},
	},
}));
