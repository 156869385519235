import {
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
// import { useState } from 'react';
import { Link } from 'react-router-dom';
import DefaultButton from '../../../../../Components/Buttons/DefaultButton';

export default function TableHwm({ /*filteredData, uniqueLocation*/ location, contest, headerRows }) {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesDownLg = useMediaQuery(theme.breakpoints.down('lg'));
	const matchesDownXl = useMediaQuery(theme.breakpoints.down('xl'));
	// const [contestRow, setContestRow] = useState([]);

    const singleAcronym = ['PSU', 'CERMAM', 'SES', 'SMS', 'PUC'];
    const singleAcronymTwo = ['HOBRA', 'HMD', 'HNSG', 'HSVP'];
    const singleAcronymThree = ['UNIATENAS'];

	useEffect(() => {
		// setContestRow(contest);
		// const dataOriginal = contest[0].data_edital;
		// const dataObjeto = new Date(dataOriginal);
		// const dia = String(dataObjeto.getDate()).padStart(2, '0');
		// const mes = String(dataObjeto.getMonth() + 1).padStart(2, '0'); // Adicionar +1 pois o mês começa em zero (janeiro é 0)
		// const ano = dataObjeto.getFullYear();
		// const dataEditada = `${dia}/${mes}/${ano}`;
	}, [contest]);

	function dateFormat(data) {
		if (data) {
			const dataObjeto = new Date(data);
			const dia = String(dataObjeto.getDate()).padStart(2, '0');
			const mes = String(dataObjeto.getMonth() + 1).padStart(2, '0'); // Adicionar +1 pois o mês começa em zero (janeiro é 0)
			const ano = dataObjeto.getFullYear();
			return `${dia}/${mes}/${ano}`;
		} else {
			return 'Aguarda edital';
		}
	}

	function dateRangeFormat(startDate, endDate, type) {
		const start = dateFormat(startDate);
		const end = endDate ? dateFormat(endDate) : null;
		if (type === 'provas') {
			return (
				<>
					1º fase:
					<br />
					{start}
					{/* até {end} */}
				</>
			);
		}

		return (
			<>
				{start}
				{endDate === 'Aguarda edital' ? null : (
					<>
						<br />
						{end ? `até ${end}` : null}
					</>
				)}
			</>
		);
	}

    function removeAccents(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }
    
    function generateAcronym(name) {
        let fullName;
        // Remove espaços extras e espaços em branco no início e no final do nome
        name = name.trim();
        
        // Divide o nome em palavras separadas por espaços ou traços
        const words = name.split(/\s*-\s*/);
        
        // console.log(words.toString().replace(',', ' '));
        // Obtém o ultimo nome após o "-" 
        
        if (words.some(word => singleAcronym.includes(word))) {
            fullName = words.toString().replace(',', ' ');
        } else if (words.some(word => singleAcronymTwo.includes(word))) {
            fullName = words[words.length - 2];
        } else if (words.some(word => singleAcronymThree.includes(word))) {
            fullName = words[1];
        } else {

            fullName = words[words.length - 1];
        }

        // Remove os acentos
        const formatAccents = removeAccents(fullName);
        
        // Formata e troca os espaços por hífen
        const formattedName = formatAccents.replace(/\s+/g, "-");

        // Converte o nome completo para minúsculas
        const acronym = formattedName.toLowerCase();
        
        // Retorna o nome completo em minúsculas
        return acronym;
    }

	return (
		<Grid
			item
			xs={12}
			container
			style={{ overflowX: matchesMobile ? 'auto' : null, marginBottom: '1.5em', height: 'fit-content' }}
		>
			<Grid
				item
				xs={12}
				container
				style={
					matchesMobile
						? null
						: {
								position: 'sticky',
								top: 0,
								zIndex: 2 /*height: 50*/,
								backgroundColor: '#0d0d0d',
						  }
				}
			>
				<Grid
					item
					xs={12}
					container
					className={`${classes.tableHeader} background-central-concursos-horizontal`}
					style={
						matchesMobile
							? null
							: {
									position: 'sticky',
									top: 0,
									zIndex: 2 /*height: 50*/,
							  }
					}
				>
					<Grid
						item
						xs={12}
						container
						style={{
							backgroundImage: `url(${require('../Assets/bg-header-wave.png')})`,
							padding: '1em',
							backgroundSize: 'cover',
						}}
					>
						<Typography
							color="secondary"
							className={`${classes.filterText} poppins`}
						>
							&nbsp;{location}
						</Typography>
					</Grid>
					{!matchesTablet && (
						<TableHead
							style={{ backgroundColor: '#CCCCCC', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.53)' }}
						>
							<TableRow>
								{headerRows.map((item, index) => {
									return (
										<TableCell
											key={index}
											align="center"
											className={`${
												index === 0 ? classes.firstCellTableHeader : classes.headerCell
											} poppins`}
											style={
												index === 1
													? { width: matchesDownLg ? 92 : matchesDownXl ? 92 : 109 }
													: index === 2
													? { width: matchesDownLg ? 106 : matchesDownXl ? 106 : 135 }
													: index === 3
													? { width: matchesDownLg ? 92 : matchesDownXl ? 92 : 109 }
													: index === 4
													? { width: matchesDownLg ? 91 : matchesDownXl ? 91 : 108 }
													: index === 5
													? { width: matchesDownLg ? 93 : matchesDownXl ? 93 : 110 }
													: { width: matchesDownLg ? 93 : matchesDownXl ? 93 : 108 }
											}
										>
											{item}
										</TableCell>
									);
								})}
							</TableRow>
						</TableHead>
					)}
				</Grid>
			</Grid>
			<TableContainer
				component={Paper}
				style={{
					// maxHeight: 650,
					borderBottomLeftRadius: 15,
					borderBottomRightRadius: 15,
					borderTopLeftRadius: 0,
					borderTopRightRadius: 0,
					paddingBottom: '2em',
				}}
			>
				<Table
					sx={{ minWidth: 650 }}
					aria-label="simple table"
				>
					{matchesTablet && (
						<TableHead style={{ backgroundColor: '#CCCCCC' }}>
							<TableRow>
								{headerRows.map((item, index) => {
									return (
										<TableCell
											key={index}
											align="center"
											className={`${
												index === 0 ? classes.firstCellTableHeader : classes.headerCell
											} poppins`}
											style={
												index === 1
													? { width: matchesDownLg ? 92 : matchesDownXl ? 85 : 109 }
													: index === 2
													? { width: matchesDownLg ? 106 : matchesDownXl ? 94 : 135 }
													: index === 3
													? { width: matchesDownLg ? 92 : matchesDownXl ? 84 : 109 }
													: index === 4
													? { width: matchesDownLg ? 91 : matchesDownXl ? 84 : 108 }
													: index === 5
													? { width: matchesDownLg ? 93 : matchesDownXl ? 85 : 110 }
													: { width: matchesDownLg ? 81 : matchesDownXl ? 78 : 108 }
											}
										>
											{item}
										</TableCell>
									);
								})}
							</TableRow>
						</TableHead>
					)}
					<TableBody style={{ borderBottom: '1px solid #000000' }}>
						{contest.map((item, index) => {
							if (item.estados.includes(location)) {
								const rowClass = index % 2 === 0 ? classes.tableRowOdd : classes.tableRowEven;
								return (
									<>
										<TableRow
											key={index}
											sx={{
												'&:last-child td, &:last-child th': {
													border: 0,
												},
											}}
											// style={
											// 	index === contest.length - 1
											// 		? { borderBottom: '1px solid black' }
											// 		: null
											// }
											className={rowClass}
										>
											<TableCell
												component="th"
												align="center"
												scope="row"
												className={`${classes.firstCellTableBody} poppins`}
												// style={
												// 	item.status_sprint
												// 		? {
												// 				display: matchesDownXl ? null : 'flex',
												// 				justifyContent: 'center',
												// 				alignItems: 'center',
												// 		  }
												// 		: null
												// }
											>
												<Grid container>
													<Grid
														item
														xs={item.status_sprint ? 10 : 12}
														container
														// justifyContent={'center'}
														alignContent={'center'}
													>
														<Link //link to
															to={`/materiais/residencia-medica/${generateAcronym(
																item.nome
															)}-${item.id}`}
															className={classes.contestName}
															style={
																item.status_sprint
																	? {
																			width: '100%',
																			wordWrap: 'break-word',
																			left:
																				item.nome.length < 18
																					? 'unset'
																					: 'unset',
																	  }
																	: null
															}
														>
															<>{item.nome}</>
														</Link>
													</Grid>
													{item.status_sprint && (
														<Grid
															item
															xs={2}
															container
															justifyContent={'flex-end'}
														>
															<a
																href="/sprint-final"
																rel="noopener noreferrer"
																style={{
																	width: '100%',
																	// position: 'absolute',
																	right: -5,
																	top: matchesMobile ? -10 : matchesTablet ? -15 : -9,
																	cursor: 'pointer',
																	display: 'flex',
																	justifyContent: 'flex-end',
																}}
															>
																<Tooltip title="Esta prova tem Sprint Final">
																	<img
																		src={
																			require('../Assets/icon-sf-tag.svg').default
																		}
																		alt="Tag Sprint Final"
																		style={{ height: matchesMobile ? 50 : 40 }}
																	/>
																</Tooltip>
															</a>
														</Grid>
													)}
												</Grid>
											</TableCell>
											<TableCell
												align="center"
												className={`${classes.tableCell} poppins`}
												style={{ width: matchesDownXl ? 92 : 109 }}
											>
												{item?.data_edital === 'Aguarda edital'
													? item.data_edital
													: dateFormat(item.data_edital)}
											</TableCell>
											<TableCell
												align="center"
												className={`${classes.tableCell} poppins`}
												style={{ width: matchesDownXl ? 106 : 135 }}
											>
												{item?.data_inscricoes_de === 'Aguarda edital'
													? item.data_inscricoes_de
													: dateRangeFormat(
															item.data_inscricoes_de,
															item.data_inscricoes_ate
													  )}
											</TableCell>
											<TableCell
												align="center"
												className={`${classes.tableCell} poppins`}
												style={{ width: matchesDownXl ? 92 : 109 }}
											>
												{item?.data_prova_fase_1 === 'Aguarda edital'
													? item.data_prova_fase_1
													: dateRangeFormat(item.data_prova_fase_1, null, 'provas')}
											</TableCell>
											<TableCell
												align="center"
												className={`${classes.tableCell} poppins`}
												style={{ width: matchesDownXl ? 91 : 108 }}
											>
												{item?.data_gabarito_preliminar_ate
													? dateRangeFormat(
															item?.data_gabarito_preliminar_de,
															item?.data_gabarito_preliminar_ate
													  )
													: dateRangeFormat(item?.data_gabarito_preliminar_de, null)}
											</TableCell>
											<TableCell
												align="center"
												className={`${classes.tableCell} poppins`}
												style={{ width: matchesDownXl ? 93 : 110 }}
											>
												{item?.data_recursos_fase_1_de === 'Aguarda edital'
													? item.data_recursos_fase_1_de
													: item?.data_recursos_gabarito_de
													? item?.data_recursos_gabarito_ate
														? dateRangeFormat(
																item?.data_recursos_gabarito_de,
																item?.data_recursos_gabarito_ate
														  )
														: dateFormat(item?.data_recursos_gabarito_de)
													: dateRangeFormat(item.data_recursos_fase_1_de, null)}
											</TableCell>
											<TableCell
												align="center"
												className={`${classes.tableCell} poppins`}
												style={{ width: matchesDownXl ? 93 : 108 }}
											>
												<DefaultButton
													color="primary"
													className="poppins"
													style={{
														border: '2px solid #000000',
														fontSize: '0.75rem',
														fontWeight: 'bold',
														padding: '0 0.25rem',
													}}
													component={Link}
													to={`/materiais/residencia-medica/${generateAcronym(item.nome)}-${
														item.id
													}`}
												>
													Ver mais
												</DefaultButton>
											</TableCell>
										</TableRow>
										{/* {item.status_sprint && (
											<Grid
												style={{
													position: 'absolute',
													top: '50%',
													right: 10,
													transform: 'translateY(-50%)',
													backgroundColor: 'orange',
													padding: '0.5rem',
												}}
											>
												<img
													src={require('../../ContestCentral/Assets/logo-sf-tag.svg').default}
													alt="Sprint Status"
												/>
											</Grid>
										)} */}
									</>
								);
							} else {
								return null;
							}
						})}
					</TableBody>
				</Table>
				{/* {contest.map((item, index) => {
					if (item.estados.includes(location) && item.status_sprint) {
						return (
							<Grid
								key={index}
								style={{
									position: 'absolute',
									top: `calc(${index + 1} * 48px)`,
									right: 10,
									backgroundColor: 'orange',
									padding: '0.5rem',
								}}
							>
								<img
									src={require('../../ContestCentral/Assets/logo-sf-tag.svg').default}
									alt="Sprint Status"
								/>
							</Grid>
						);
					} else {
						return null;
					}
				})} */}
			</TableContainer>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	tableHeader: {
		// height: 62,
		// height: '10%',
		// background: 'linear-gradient(90deg, rgba(255,72,0) 10%, rgba(255,0,66) 90%)',
		borderTopLeftRadius: 15,
		borderTopRightRadius: 15,
	},
	filterText: {
		fontSize: '1.75rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		textShadow: '2px 2px 4px #000000',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.2rem !important',
		},
	},
	firstCellTableHeader: {
		borderRight: '1px solid black !important',
		padding: '8px 14px !important',
		fontSize: '0.9rem !important',
		width: '245px !important',
		fontWeight: 'bold !important',
		textAlign: 'left !important',
		[theme.breakpoints.down('xl')]: {
			width: 'unset !important',
			minWidth: '233px !important',
		},
		[theme.breakpoints.down('lg')]: {
			minWidth: '333px !important',
		},
	},
	firstCellTableBody: {
		borderRight: '1px solid black !important',
		padding: '8px 14px !important',
		fontSize: '0.75rem !important',
		// width: '245px !important',
		textAlign: 'left !important',
		// padding: index === 0 ? '0 3em' : '1rem',
		[theme.breakpoints.down('xl')]: {
			width: 'unset !important',
			minWidth: '228px !important',
		},
	},
	headerCell: {
		fontSize: '0.9rem !important',
		fontWeight: 'bold !important',
		borderRight: '1px solid black !important',
		width: 'fit-content',
		padding: '0.5rem !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '0.85rem !important',
		},
	},
	headerCell90: {
		fontSize: '0.9rem !important',
		fontWeight: 'bold !important',
		borderRight: '1px solid black !important',
		width: '90px', // Defina o tamanho mínimo desejado para a célula do header
	},
	tableCell: {
		borderRight: '1px solid black !important',
		fontSize: '0.75rem !important',
		padding: '8px 0.6rem !important',
		// minWidth: '100px', // Defina o tamanho mínimo desejado para as células do corpo
		[theme.breakpoints.down('xl')]: {
			padding: '8px 0.5rem !important',
		},
	},
	tableRowEven: {
		backgroundColor: '#CCCCCC !important',
	},
	contestName: {
		color: '#000000',
		fontWeight: '600 !important',
	},
}));
