import React, { useRef, useState } from 'react';
import { Checkbox, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import Api from '../../../Services/Api';
import DefaultButton from '../../Buttons/DefaultButton';
import { makeStyles } from '@mui/styles';
import { Link, NavLink, useSearchParams } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';
import { useEffect } from 'react';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

export default function Register({ tags, title, titleButton, type, urlToRedirect }) {
	const classes = useStyles();
	const [interestSelected, setInterestSelected] = useState('');
	const [graduationYearSelected, setGraduationYearSelected] = useState('');
	const [interests] = useState([
		{
			// id: 1172,
			name: 'Prova de residência',
		},
		{
			// id: 1165,
			name: 'Revalidação de diploma',
		},
		{
			// id: 1168,
			name: 'Título de especialista',
		},
	]);
	const [graduationYear] = useState([
		{
			// id: 1172,
			year: 2023,
		},
		{
			// id: 1165,
			year: 2024,
		},
		{
			// id: 1168,
			year: 2025,
		},
		{
			// id: 1172,
			year: 2026,
		},
		{
			// id: 1165,
			year: 2027,
		},
		{
			// id: 1168,
			year: 2028,
		},
		{
			// id: 1168,
			year: 2029,
		},
	]);
	const [searchParams] = useSearchParams();
	const interestInputRef = useRef();
	const graduationYearRef = useRef();
	const [confirmacaoSenha, setConfirmacaoSenha] = useState();
	const [optin, setOptin] = useState(false);

	const [registerFormFields, setRegisterFormFields] = useState({
		tags: tags,
		nome: '',
		email: '',
		telefone: '',
		interesse_momento: '',
		ano_formatura: '',
		senha: '',
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
	});

	useEffect(() => {
		setRegisterFormFields({
			...registerFormFields,
			utm_source: searchParams.get('utm_source'),
			utm_medium: searchParams.get('utm_medium'),
			utm_campaign: searchParams.get('utm_campaign'),
		});
	}, []);

	let register = function () {
		if (!registerFormFields.nome) {
			alert('Ops... Por favor informe seu nome');
			return;
		}
		if (
			!registerFormFields.email ||
			!registerFormFields.email.includes('@') ||
			!registerFormFields.email.includes('.')
		) {
			alert('Ops... Por favor preencha seu email');
			return;
		}
		if (!registerFormFields.telefone) {
			alert('Ops... Por favor preencha seu telefone com um número válido');
			return;
		}
		if (!registerFormFields.interesse) {
			alert('Ops... Por favor selecione seu maior interesse');
			return;
		}
		if (!registerFormFields.ano) {
			alert('Ops... Por favor selecione o ano de formatura');
			return;
		}
		if (!registerFormFields.senha || registerFormFields.senha.length < 6 || registerFormFields.senha.length > 10) {
			alert('Ops... Por favor preencha sua senha com pelo menos 6 e no máximo 10 caracteres');
			return;
		}
		if (registerFormFields.senha !== confirmacaoSenha) {
			alert('Ops... as senhas não correspondem. Preencha a senha novamente.');
			return;
		}
		if (optin === false) {
			alert('Ops... Você precisa ler e aceitar os termos de uso acima para se pré-inscrever');
			return;
		}

		trackPromise(
			Api.post('aluno', registerFormFields).then((res) => {
				if (res) {
					Swal.fire({
						title: 'Massa! ',
						text: 'Seu cadastro foi efetuado com sucesso!.',
						icon: 'success',
						confirmButtonText: 'Fechar',
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then((result) => {
						if (result.isConfirmed) {
							if (urlToRedirect) {
								document.location.href = `${urlToRedirect}`;
							} else {
								document.location.href = '/';
							}
						}
					});
				}
			})
		);
	};

	// const handlePhone = (event) => {
	// 	let input = event.target;
	// 	input.value = phoneMask(input.value);
	// 	setRegisterFormFields({
	// 		...registerFormFields,
	// 		telefone: input.value,
	// 	});
	// };

	// const phoneMask = (value) => {
	// 	if (!value) return '';
	// 	value = value.replace(/\D/g, '');
	// 	value = value.replace(/(\d{2})(\d)/, '($1) $2');
	// 	value = value.replace(/(\d)(\d{4})$/, '$1-$2');
	// 	return value;
	// };

	const handlePhone = (valid, phoneNumber) => {
		if (valid) {
			setRegisterFormFields({
				...registerFormFields,
				telefone: phoneNumber,
			});
		} else if (registerFormFields.telefone.length) {
			setRegisterFormFields({
				...registerFormFields,
				telefone: '',
			});
		}
	};

	const handleChangeInterest = (event) => {
		var interest = event.target.value;
		if (interest === null) {
			setInterestSelected(null);
			return;
		}
		setInterestSelected(interest);
		setRegisterFormFields({
			...registerFormFields,
			interesse: interest,
		});
	};

	const handleChangeGraduationYear = (event) => {
		var year = event.target.value;
		if (year === null) {
			setGraduationYearSelected(null);
			return;
		}
		setGraduationYearSelected(year);
		setRegisterFormFields({
			...registerFormFields,
			ano: year,
		});
	};

	return (
		<Paper
			elevation={4}
			className={`${classes.paper} rounded-card background-${type}`}
		>
			<form
				className="form-control"
				action="javascript:;"
			>
				<Grid
					container
					gap={1}
				>
					<Grid
						item
						container
						justifyContent="center"
						style={{ marginBottom: '0.5rem' }}
					>
						<Typography
							className={`${classes.signInCardTitle} poppins`}
							color="secondary"
							align="center"
						>
							{title}
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<TextField
							fullWidth
							type="string"
							name="nome"
							placeholder="Seu nome completo"
							required={true}
							InputProps={{
								className: `${classes.form} ${classes.formHeight}`,
							}}
							InputLabelProps={{
								shrink: true,
							}}
							onChange={(e) =>
								setRegisterFormFields({
									...registerFormFields,
									nome: e.target.value,
								})
							}
						/>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<TextField
							fullWidth
							type="email"
							name="email"
							placeholder="Seu melhor e-mail"
							required={true}
							InputProps={{
								className: `${classes.form} ${classes.formHeight}`,
							}}
							InputLabelProps={{
								shrink: true,
							}}
							onChange={(e) =>
								setRegisterFormFields({
									...registerFormFields,
									email: e.target.value,
								})
							}
						/>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<IntlTelInput
							preferredCountries={['BR', 'PY', 'AR', 'CL', 'BO', 'UY', 'CU', 'PT']}
							style={{ width: '100%', border: 'unset' }}
							inputClassName={`${classes.phoneNumberForm} ${classes.formHeight}`}
							nationalMode={true}
							onPhoneNumberChange={(valid, value, selectedCountryData, fullNumber) =>
								handlePhone(valid, fullNumber)
							}
						/>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<FormControl
							style={{ width: '100%', border: 'transparent' }}
							className={`${classes.form} ${classes.formHeight}`}
						>
							{interestSelected ? null : (
								<InputLabel id="test-select-label">Seu maior interesse no momento</InputLabel>
							)}
							<input
								type="hidden"
								name="maior_interesse"
								ref={interestInputRef}
							/>
							<Select
								value={interestSelected}
								labelId="test-select-label"
								onChange={handleChangeInterest}
								className={classes.selectMenu}
							>
								{interests.map((interest) => {
									return (
										<MenuItem
											key={interest.id}
											value={interest.name}
											className="poppins"
										>
											<Typography
												color="primary"
												className={`${classes.textSelectItem} poppins`}
											>
												{interest.name}
											</Typography>
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<FormControl
							style={{ width: '100%', border: 'transparent' }}
							className={`${classes.form} ${classes.formHeight}`}
						>
							{graduationYearSelected ? null : (
								<InputLabel id="test-select-label">Seu ano de formatura</InputLabel>
							)}
							<input
								type="hidden"
								name="ano_graduacao"
								ref={graduationYearRef}
							/>
							<Select
								value={graduationYearSelected}
								labelId="test-select-label"
								onChange={handleChangeGraduationYear}
								className={classes.selectMenu}
							>
								{/* <MenuItem value={null}>
								<em>Selecione a cidade...</em>
							</MenuItem> */}
								{graduationYear.map((year) => {
									return (
										<MenuItem
											key={year.id}
											value={year.year}
											className="poppins"
										>
											<Typography
												color="primary"
												className={`${classes.textSelectItem} poppins`}
											>
												{year.year}
											</Typography>
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<TextField
							fullWidth
							type="password"
							placeholder="Crie uma senha de acesso"
							required={true}
							inputProps={{
								maxLength: 10,
							}}
							InputProps={{
								className: `${classes.form} ${classes.formHeight}`,
							}}
							InputLabelProps={{
								shrink: true,
							}}
							onChange={(e) =>
								setRegisterFormFields({
									...registerFormFields,
									senha: e.target.value,
								})
							}
						/>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<TextField
							fullWidth
							type="password"
							placeholder="Confirme novamente sua senha"
							required={true}
							inputProps={{
								maxLength: 10,
							}}
							InputProps={{
								className: `${classes.form} ${classes.formHeight}`,
							}}
							InputLabelProps={{
								shrink: true,
							}}
							onChange={(e) => setConfirmacaoSenha(e.target.value)}
						/>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<Checkbox
							style={{ padding: '0px' }}
							checked={registerFormFields.optin}
							onChange={(e) => setOptin(!optin)}
						/>
						<Typography
							variant="caption"
							color="primary"
							className={classes.captionText}
						>
							Estou de acordo com o tratamento dos meus dados pessoais para fins de inscrição na
							plataforma Hardwork Medicina, bem como para envio de comunicações relativas aos serviços
							educacionais que se adequem ao meu perfil, de acordo com a nossa{' '}
							<Link
								color="primary"
								href="https://hardworkmedicina.com.br/politica-de-privacidade"
								target="_blank"
								underline="hover"
								variant="caption"
								align="right"
								className={`${classes.copyrightText} poppins`}
							>
								Politica de privacidade
							</Link>
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						container
						justifyContent="center"
						className="residencia"
						style={{ margin: '1rem 0' }}
					>
						<DefaultButton
							variant="outlined"
							color="secondary"
							className={`${classes.btn} btn`}
							onClick={() => register()}
						>
							{titleButton}
						</DefaultButton>
					</Grid>
					<NavLink
						to="//hardworkmedicina.com.br/banco-de-lives"
						className={`${classes.alreadyRegisteredGrid} residencia`}
					>
						<Typography
							className={`${classes.bottomText} poppins`}
							color="primary"
						>
							Já é aluno cadastrado?&nbsp;
						</Typography>
						<Link
							to="//hardworkmedicina.com.br/banco-de-lives"
							className={`${classes.link} ${classes.bottomText} poppins`}
						>
							Acesse aqui
						</Link>
					</NavLink>
				</Grid>
			</form>
		</Paper>
	);
}

const useStyles = makeStyles((theme) => ({
	paper: {
		margin: '0 auto',
		padding: '1.5rem',
		[theme.breakpoints.up('lg')]: {
			// height: 449,
		},
		[theme.breakpoints.down('sm')]: {
			padding: '1rem',
		},
	},
	form: {
		borderRadius: '8px !important',
		backgroundColor: theme.palette.secondary.main,
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'transparent !important',
		},
	},
	formHeight: {
		maxHeight: '3em',
		[theme.breakpoints.down('sm')]: {
			maxHeight: '2.75em',
		},
	},
	signInCardTitle: {
		fontSize: '1.5rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
		},
	},
	btn: {
		fontSize: '1.15rem !important',
		width: '75%',
		fontWeight: 'bold !important',
		textTransform: 'uppercase !important',
		backgroundColor: '#000000 !important',
		border: '1px solid transparent !important',
		'&:hover': {
			border: '1px solid #ffffff !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	copyrightText: {
		color: theme.palette.primary.main,
	},
	captionText: {
		[theme.breakpoints.down('xl')]: {
			fontSize: '0.65rem !important',
		},
	},
	link: {
		alignSelf: 'center',
		color: '#ffffff',
	},
	bottomText: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	alreadyRegisteredGrid: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: '#ffffff4d',
		borderRadius: 5,
		padding: '0.5em 0',
		textDecoration: 'none !important',
		'&:hover': {
			backgroundColor: '#ffe9e94d',
			cursor: 'pointer !important',
		},
	},
	phoneNumberForm: {
		height: '3em',
		borderRadius: 8,
		width: '100%',
		border: 'unset',
	},
}));
