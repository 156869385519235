import { useTheme, useMediaQuery, Button, Grid, Typography, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState, useEffect } from 'react';
import Api from '../../../../Services/Api';
import IconHourglass from '../componentes/assets/icon-hourglass.svg';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';
import DefaultButton from '../../../../Components/Buttons/DefaultButton';
import IntlTelInput from 'react-intl-tel-input';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

const PaymentCardClosed = ({
	idTurma,
	logo,
	shadow,
	color,
	institutionName,
	product,
	productColor,
	title,
	subTitle,
}) => {
	const [preInscricao, setPreInscricao] = useState({
		agrupamento: `SPRINT ENARE R3 2025`,
		// agrupamento: contest,
		nome: '',
		email: '',
		telefone: '',
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
	});
	const [waitListVisibility, setWaitListVisibility] = useState(false);
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const handleCloseWaitList = () => {
		setWaitListVisibility(false);
	};

	const handleOpenWaitList = () => {
		setWaitListVisibility(true);
	};
	let sendPreInscricao = function (event) {
		event.preventDefault();
		const nomeCompleto = preInscricao.nome.trim();
		const nomeSobrenome = nomeCompleto.split(' ');
		if (!preInscricao.nome || nomeSobrenome.length < 2) {
			alert('Ops... Por favor informe seu nome e sobrenome');
			return;
		}
		if (!preInscricao.email || !preInscricao.email.includes('@') || !preInscricao.email.includes('.')) {
			alert('Ops... Por favor preencha seu email');
			return;
		}
		if (!preInscricao.telefone) {
			alert('Ops... Por favor preencha seu telefone');
			return;
		}

		trackPromise(
			Api.post('PreInscricao', preInscricao).then((res) => {
				if (res) {
					Swal.fire({
						title: 'Massa! ',
						text: `${'O cadastro está confirmado, avisaremos das novidades em breve.'}`,
						icon: 'success',
						confirmButtonText: 'Fechar',
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then((result) => {
						if (result.isConfirmed) {
							setTimeout(() => {
								document.location.reload();
							}, 500);
						}
					});
				}
			})
		);
	};

	const handlePhone = (valid, phoneNumber) => {
		if (valid) {
			setPreInscricao({
				...preInscricao,
				telefone: phoneNumber,
			});
		} else if (preInscricao.telefone.length) {
			setPreInscricao({
				...preInscricao,
				telefone: '',
			});
		}
	};

	const isRegistrationOpen = true;

	return (
		<Grid
			container
			justifyContent={'end'}
			alignItems={'center'}
			marginTop={matchesMobile ? '2rem' : '0'}
			style={{ background: color ? '#101010' : '#EBEBEB', borderRadius: '24px', boxShadow: shadow }}
		>
			<Grid
				item
				container
				xl={12}
				lg={12}
				justifyContent={'center'}
				alignItems={'center'}
				direction="column"
				style={{ textAlign: 'start', padding: '4rem 2rem 3rem' }}
			>
				<Grid
					item
					container
					direction={'column'}
					className="residencia"
					style={{ marginBottom: '1.5rem' }}
				>
					<Typography
						style={{ fontSize: '1.5rem', fontWeight: 500, lineHeight: 'unset' }}
						fontFamily={'Manrope'}
						className={productColor}
					>
						{product}
					</Typography>
					<Typography
						style={{ fontSize: '3rem', fontWeight: 'bold', lineHeight: 'unset' }}
						fontFamily={'Manrope'}
						className={productColor}
					>
						{institutionName}
					</Typography>
				</Grid>
				<Grid
					justifyContent="flex-start"
					alignItems="flex-start"
				>
					<Grid
						container
						direction="column"
						justifyContent="start"
						alignItems="start"
						gap={'2px'}
					>
						<Typography
							fontFamily={'Manrope'}
							fontSize={'24px'}
							fontWeight={'bold'}
							textAlign={'start'}
							color={color ? '#FCFCFC' : '#000'}
						>
							{title}
						</Typography>
						<Typography
							fontFamily={'Manrope'}
							fontSize={'20px'}
							fontWeight={400}
							textAlign={'start'}
							color={color ? '#FCFCFC' : '#000'}
						>
							{subTitle}
						</Typography>
					</Grid>
				</Grid>
				<Button
					onClick={handleOpenWaitList}
					startIcon={
						<img
							src={''}
							alt=""
						/>
					}
					className={`${classes.subscribeButton}`}
					style={{ padding: '0.25rem 3rem', background: '#656565 ', color: '#fff' }}
				>
					<img
						src={IconHourglass}
						alt=""
						style={{ width: '1.2rem', marginRight: '0.5rem' }}
					/>
					<Typography
						color={'secondary'}
						fontFamily={'Manrope'}
						style={{
							fontSize: '1.31rem',
							fontWeight: 'bold',
						}}
					>
						Avise-me
					</Typography>
				</Button>
			</Grid>
			{waitListVisibility && (
				<form
					className="form-control"
					onSubmit={sendPreInscricao}
					action="javascript:;"
					style={
						waitListVisibility
							? {
									overflow: 'hidden',
									transition: 'opacity 1s ease, height 1s ease',
									marginTop: '2.5rem',
									position: 'absolute',
									top: '50%',
									left: '50%',
									transform: 'translate(-50%, -50%)',
									width: '400px',
									padding: '0.5rem',
									borderRadius: '10px',
									boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
									zIndex: 1,
                                    // backdropFilter: 'blur(5px)',
                                    // backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                    
							  }
							: { width: '100%', visibility: 'hidden', height: 0 }
					}
				>
					<Grid
						item
						container
						justifyContent={'space-between'}
						className={classes.containerWaitList}
						rowGap={matchesMobile ? 1 : 0}
					>
						<Grid
							item
							container
							justifyContent="space-between"
						>
							<Typography
								color="primary"
								className={`${classes.textWaitList} poppins`}
							>
								Cadastre-se para receber as novidades
							</Typography>
							<CancelRoundedIcon
								onClick={() => handleCloseWaitList()}
								className={classes.cancelIcon}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={5.75}
							container
						>
							<TextField
								fullWidth
								type="string"
								name="nome"
								placeholder="Seu nome completo"
								required={true}
								InputProps={{
									className: `${classes.form} ${classes.formHeight}`,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										nome: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={5.75}
							container
							alignContent={'center'}
						>
							<IntlTelInput // Componente Input de telefones internacionais
								name="mobile"
								type="mobile"
								preferredCountries={['BR', 'PY', 'AR', 'CL', 'BO', 'UY', 'CU', 'PT']}
								style={{ width: '100%', border: 'unset' }}
								inputClassName={`${classes.phoneNumberForm} ${classes.form} ${classes.formHeight}`}
								nationalMode={true}
								onPhoneNumberChange={(valid, value, selectedCountryData, fullNumber) =>
									handlePhone(valid, fullNumber)
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							container
						>
							<TextField
								fullWidth
								type="email"
								name="email"
								placeholder="Seu melhor e-mail"
								required={true}
								InputProps={{
									className: `${classes.form} ${classes.formHeight}`,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								style={{ width: '100%' }}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										email: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={10}
							lg={5}
							container
							className="residencia"
							style={{ padding: '4px 0px', margin: '0 auto' }}
						>
							<DefaultButton
								type="submit"
								variant="outlined"
								color="secondary"
								className={`${classes.btnWaitList} btn`}
								onClick={(e) => sendPreInscricao(e)}
							>
								<img
									src={require('../../../../Assets/Arena/icon-waitList.svg').default}
									alt=""
									style={{ width: 20 }}
								/>{' '}
								&nbsp; Confirmar cadastro
							</DefaultButton>
						</Grid>
					</Grid>
				</form>
			)}
		</Grid>
	);
};

export default PaymentCardClosed;

const useStyles = makeStyles((theme) => ({
	subscribeButton: {
		borderRadius: '24px !important',
		width: '100%',
		marginTop: '2rem !important',
		textTransform: 'unset !important',
	},
	optionButton: {
		position: 'absolute !important',
		top: '0.5rem',
		cursor: 'pointer',
		borderRadius: '24px !important',
		marginRight: '1rem !important',
		marginTop: '0.5rem !important',
		width: 'fit-content',
		fontFamily: 'Manrope',
		fontSize: '10px !important',
		color: '#fff !important',
		alignSelf: 'flex-end',
	},
	btnWaitList: {
		fontSize: '0.9rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		backgroundColor: '#00AB55 !important',
		border: '1px solid #707070 !important',
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				// minWidth: '72% !important',
				// fontSize: '1.025rem !important',
				// borderWidth: '3px !important',
				backgroundColor: '#00543b !important',
				transition: '0.1s',
			},
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	containerWaitList: {
		// width: '100% !important',
		backgroundColor: '#d6cece',
		zIndex: 1,
		borderRadius: 15,
		padding: '0.5rem 1.25rem',
		boxShadow: '0px 0px 30px 0px #000000',
		border: '2px solid #000000',
		// position: 'absolute',
		// bottom: 0,
		[theme.breakpoints.down('sm')]: {
			padding: '1rem 1.25rem',
		},
	},
	textWaitList: {
		fontSize: '0.9rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.95rem !important',
		},
	},
	form: {
		borderRadius: '8px !important',
		backgroundColor: '#E1E1E1 !important',
		margin: '5px 0 !important',
		'& .MuiInputBase-input': {
			padding: '0.5rem !important',
		},
	},
	formHeight: {
		maxHeight: '2.5em',
	},
	cancelIcon: {
		color: '#000000',
		right: '-15px',
		top: '-5px',
		'&:hover': {
			cursor: 'pointer',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'absolute',
			top: '-15px',
			right: '-18px',
		},
	},
	phoneNumberForm: {
		height: '2.5em !important',
		borderRadius: 8,
		width: '100%',
		border: 'unset',
	},
}));
