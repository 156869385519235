import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Container, Grid, Modal, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { styled, Box } from '@mui/system';

import FabricioValois from './Assets/fabricio.png';
import FabioColagrossi from './Assets/fabioColagrossi.png';
import FabioCabar from './Assets/fabioCabar.png';
import ClaudioLima from './Assets/claudioLima.png';
import CynaraCarvalho from './Assets/cynaraCarvalho.png';
import LaisKomatsu from './Assets/laisKomatsu.png';
import EricCosta from './Assets/ericCosta.png';
import DecarthonVitor from './Assets/decarthonVitor.png';
import Sophia from './Assets/sophia.png';
import FabioYuji from './Assets/fabioYuji.png';
import Benito from './Assets/benito.png';
import ArrowBack from '../../../../Assets/Svg/arrowBack.svg';
import ArrowForward from '../../../../Assets/Svg/arrowForward.svg';
import CaraDaProva from './Assets/caraDaProva.png';
import Shots from './Assets/shots.png';
import Imagens from './Assets/imagens.png';
import Apostas from './Assets/apostas.png';
import XoteDosMilagres from './Assets/xote-dos-milagres.png';
import GifApostas from '../LDL/Assets/gif-ldl-apostas.gif';
import GifCaraDaProva from '../LDL/Assets/gif-ldl-caradaprova.gif';
import GifImagens from '../LDL/Assets/gif-ldl-imagens.gif';
import GifShots from '../LDL/Assets/gif-ldl-shots.gif';

// COMPONENTS
import Footer from '../../../../Components/Footer';
import { Header } from '../../../../Components/Header';
import HostCard from '../../../../Components/Cards/HostCard';
import { Helmet } from 'react-helmet';
import WhereWhenComponent from './components/WhereWhenComponent';
import Api from '../../../../Services/Api';

import LogoLdLHQ from './Assets/logo-hq-ldl.png';
import CellphoneImg from './Assets/cellphone-img.png';
import IconGoogle from './Assets/google-play.svg';
import IconApple from './Assets/apple-store.svg';
import IconBrowser from './Assets/browser.svg';
import GiftBag from './Assets/gift-bag-mais.png';
import GiftFlashcards from './Assets/gift-flashcards-mais.png';

import BoxApp from './components/BoxApp';
import KitHardwork from './components/KitHardwork';
import PriceCard from './components/PriceCard';
import DefaultButton from '../../../../Components/Buttons/DefaultButton';
import { Link } from 'react-router-dom';
import { UploadModal } from './components/UploadModal';

const Hosts = [
	{
		ig_username: '@fabricio.valois',
		img: FabricioValois,
	},
	{
		img: FabioColagrossi,
	},
	{
		img: FabioCabar,
	},
	{
		img: ClaudioLima,
	},
	{
		img: CynaraCarvalho,
	},
	{
		img: LaisKomatsu,
	},
	{
		img: DecarthonVitor,
	},
	{
		img: EricCosta,
	},
	{
		img: Sophia,
	},
	{
		img: FabioYuji,
	},
	{
		img: Benito,
	},
];

const ScriptCard = ({ img, gif, title, description, classes }) => {
	const [openGif, setOpenGif] = useState(true);

	const handleHover = () => {
		setOpenGif(!openGif);
	};

	return (
		<Paper
			className={`${classes.scriptPaper} rounded-card`}
			color="secondary"
			elevation={4}
			onMouseEnter={handleHover}
			onMouseLeave={handleHover}
		>
			<Grid style={{ backgroundColor: '#000000', borderRadius: 20 }}>
				{openGif ? (
					<img
						src={img}
						alt=""
						style={{ width: '100%' }}
					/>
				) : (
					<img
						src={gif}
						alt=""
						style={{ width: '100%', borderRadius: '20px 20px 0 0' }}
					/>
				)}
				<Typography
					align="left"
					color="secondary"
					className={`${classes.scriptTitle} title-card-home`}
				>
					{title}
				</Typography>
			</Grid>
			<Grid
				container
				alignContent="center"
				className={classes.gridDescriptionScriptCard}
			>
				<Typography
					align="left"
					color="primary"
					className={`${classes.scriptText} poppins`}
				>
					{description}
				</Typography>
			</Grid>
		</Paper>
	);
};

export default function LiveDasLivesResidenciaTurmaQuintoAno2024UFRJ() {
	const classes = useStyles();
	const theme = useTheme();
	const carousel = useRef(null);
	const scriptCarousel = useRef(null);
	const purchase_card = useRef(null);
	const feedbackCarousel = useRef(null);
	const [currentContest, setCurrentContest] = useState('');

	const [contest] = useState({
		id: '1438',
		name: 'ufrj',
		logo: require(`./Assets/logo-ldl-ufrj-presencial.svg`).default,
		modality: 'presencial',
		shortName: 'ufrj',
		testBench: 'ufrj',
		address: 'IDOMED Cittá - Rio de Janeiro - RJ',
		date: '22/11',
		redirectLink: '/live-das-lives-ufrj',
		locationCity: 'Rio de Janeiro',
		// opening_date_sprint_final: '25/09/23',
		// close_date_sprint_final: '26/09/23',
		// opening_date_general_public: '29/09/23',
		logoPriceCard: require('./Assets/ldl-ufrj-presencial.svg').default,
		firstDescription: [
			'A Live das Lives UFRJ acontecerá no ',
			<strong style={{ color: '#ffffff' }}>Rio de Janeiro</strong>,
			', na ',
			<strong style={{ color: '#ffffff' }}>Universidade Estácio de Sá - Campus Città</strong>,
			', com a presença dos professores Hardwork no palco.',
			// <a
			// 	href="https://maps.app.goo.gl/GGkzuFzsX36Mjbvf8"
			// 	target="_blank"
			// 	rel="noreferrer"
			// 	style={{ color: '#ffffff' }}
			// >
			// 	clique aqui.
			// </a>,
		],
		secondDescription: [
			'Dia ',
			<strong style={{ color: '#ffffff' }}>22/11/2024</strong>,
			', a partir das ',
			<strong style={{ color: '#ffffff' }}>10h.</strong>,
			// '. Previsão de término às 16h.',
		],
		thirdDescription: '',
		feedbacks: [
			// require('./Assets/img-feedback-enare-01.png'),
			// require('./Assets/img-feedback-enare-02.png'),
			// require('./Assets/img-feedback-enare-03.png'),
			// require('./Assets/img-feedback-enare-04.png'),
			// require('./Assets/img-feedback-enare-05.png'),
			// require('./Assets/img-feedback-enare-06.png'),
			require('./Assets/img-ldl-feedback-gerais-01.png'),
			require('./Assets/img-ldl-feedback-gerais-02.png'),
			require('./Assets/img-ldl-feedback-gerais-03.png'),
			require('./Assets/img-ldl-feedback-gerais-04.png'),
			require('./Assets/img-ldl-feedback-gerais-05.png'),
			require('./Assets/img-ldl-feedback-gerais-06.png'),
			require('./Assets/img-ldl-feedback-gerais-07.png'),
			require('./Assets/img-ldl-feedback-gerais-08.png'),
			require('./Assets/img-ldl-feedback-gerais-09.png'),
			require('./Assets/img-ldl-feedback-gerais-10.png'),
		],
	});
	const [currentLotTurma1, setCurrentLotTurma1] = useState();
	const [turma1, setTurma1] = useState([]);
	const [open, setOpen] = useState(false);
	const [numParticipants, setNumParticipants] = useState();
	const [selectedVideo, setSelectedVideo] = useState('');
	const [scriptMock, setScriptMock] = useState([]);
	const [sold, setSold] = useState();
	const [uploadPopup, setUploadPopup] = useState(false);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		if (contest) {
			setCurrentContest(contest);
		}
	}, [contest]);

	useEffect(() => {
		if (currentContest) {
			Api.get(`turmasabertas/${currentContest?.id}`).then((res) => {
				const currentDate = new Date();
				setTurma1(res[0]);
				// Encontre o lote ativo com base na data atual e na data limite
				const activeLotTurma1 = res[0]?.lotes?.find((lot, index) => {
					return lot?.id === res[0]?.id_lote_vigente;
				});
				// Turma 1
				if (activeLotTurma1) {
					setCurrentLotTurma1([activeLotTurma1]);
					setNumParticipants(res[0]?.qtd_inscritos);
				} else {
					setNumParticipants(res[0]?.qtd_inscritos);
					const nextLot = res[0]?.lotes?.find((lot, index) => {
						const startDate = new Date(lot.data_inicio);

						return currentDate < startDate;
					});
					// Caso contrário, não há lote ativo, define 'lots' como vazio
					const valueLimitLot4 = res[0]?.lotes?.find((lot) => lot.nome === '4')?.qtd_limite;
					if (valueLimitLot4 <= res[0]?.qtd_inscritos) {
						setSold(true);
					} else {
						// console.log('Não chegou no limite: false');
					}
					setCurrentLotTurma1([nextLot]);
				}
			});
		}

		const scriptMockArray = generateScriptMock(
			currentContest?.name?.toUpperCase(),
			currentContest?.testBench?.toUpperCase()
		);

		setScriptMock(scriptMockArray);
	}, [currentContest]);

	function generateScriptMock(exam, testBench) {
		return [
			{
				id: 1,
				img: CaraDaProva,
				gif: GifCaraDaProva,
				//source: require('./Assets/caraDaProva.mp4'),
				source: process.env.PUBLIC_URL + '/Assets/ResidenciaTemplate/caraDaProva.mp4',
				title: 'A cara da prova',
				description: `Um choque de realidade! A gente já começa mostrando os temas que mais diferenciam a prova ${adjustArticle(
					exam
				)} ${exam} das demais... os professores de todas as áreas participam, selecionando cerca de 15 temas que são A CARA DA PROVA!`,
			},
			{
				id: 2,
				img: Shots,
				gif: GifShots,
				//source: require('./Assets/shots.mp4'),
				source: process.env.PUBLIC_URL + '/Assets/ResidenciaTemplate/shots.mp4',
				title: 'Shots',
				description: `Hora do Stand Up Hardwork. O bloco queridinho da Live das Lives, com professores de diversas áreas no palco. Quando a luz se acende o professor relembra um tema de forma enfática, no padrão ${testBench}; a luz se apaga e já trocamos de área, tema, professor... assim seguimos por cerca de 20 temas numa dinâmica avassaladora.`,
			},
			{
				id: 3,
				img: Imagens,
				gif: GifImagens,
				//source: require('./Assets/imagens.mp4'),
				source: process.env.PUBLIC_URL + '/Assets/ResidenciaTemplate/imagens.mp4',
				title: 'Imagens',
				description: `Imagens, MUITAS imagens! Assim é a prova ${adjustArticle(
					exam
				)} ${exam}, assim é o bloco de imagens. Hora de desmistificar uma série de temas com imagens muito relevantes em diversas áreas.`,
			},
			{
				id: 4,
				img: XoteDosMilagres,
				gif: GifShots,
				//source: require('./Assets/shots.mp4'),
				source: process.env.PUBLIC_URL + '/Assets/ResidenciaTemplate/shots.mp4',
				title: 'Xote dos milagres',
				description:
					'Calma... não é música... mas até que poderia ser... É mais um bloco de shots, repleto de temas importantes, só que agora diferente. São temas um tanto milagrosos, inspirados por insights profundos dos professores Hardwork. ',
			},
			{
				id: 5,
				img: Apostas,
				gif: GifApostas,
				//source: require('./Assets/apostas.mp4'),
				source: process.env.PUBLIC_URL + '/Assets/ResidenciaTemplate/apostas.mp4',
				title: 'Apostas',
				description: `Por fim, o bloco mais esperado da Live das Lives... os professores retornam ao palco para trazer, em cada área, as apostas Hardwork para a prova ${adjustArticle(
					exam
				)} ${exam}!`,
			},
		];
	}

	const handleLeftClick = (e) => {
		e.preventDefault();

		carousel.current.scrollLeft -= carousel.current.offsetWidth;
	};

	const handleRightClick = (e) => {
		e.preventDefault();

		carousel.current.scrollLeft += 380;
	};

	const handleScriptLeftClick = (e) => {
		e.preventDefault();

		scriptCarousel.current.scrollLeft -= scriptCarousel.current.offsetWidth;
	};

	const handleScriptRightClick = (e) => {
		e.preventDefault();

		scriptCarousel.current.scrollLeft += scriptCarousel.current.offsetWidth;
	};

	const handleFeedbackLeftClick = (e) => {
		e.preventDefault();

		feedbackCarousel.current.scrollLeft -= feedbackCarousel.current.offsetWidth;
	};

	const handleFeedbackRightClick = (e) => {
		e.preventDefault();

		feedbackCarousel.current.scrollLeft += feedbackCarousel.current.offsetWidth;
	};

	function adjustArticle(contestName) {
		if (
			contestName === 'enare' ||
			contestName === 'sus-sp' ||
			contestName === 'sus-sp' ||
			contestName === 'SUS-BA' ||
			contestName === 'sus-ba'
		) {
			return 'do';
		} else {
			return 'da';
		}
	}

	const handleOpen = (source) => {
		setSelectedVideo(source);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setSelectedVideo('');
	};

	const handleUploadPopup = () => {
		setUploadPopup(true);
	};

	const executeScroll = () => purchase_card.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{currentContest && (
				<Helmet>
					<title>{currentContest?.name?.toUpperCase()} Live das Lives 2024</title>
					<meta
						name="description"
						content={''}
					/>
				</Helmet>
			)}
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className="residencia"
			>
				{matchesMobile || matchesTablet ? null : <Header />}
			</Container>
			<Grid
				container
				alignItems={'center'}
				style={matchesMobile ? { marginTop: 80 } : null}
			>
				<video
					src={process.env.PUBLIC_URL + '/Assets/video-ldl-background-24-2.mp4'}
					autoPlay
					loop
					muted
					playsInline
					className={classes.videoBackground}
				/>
				<Grid
					item
					xs={12}
					container
					direction={'column'}
					alignItems={'center'}
					justifyContent={'center'}
					className={`${classes.gridLogoContainer} residencia`}
				>
					<img
						src={currentContest.logo}
						alt=""
						className={classes.ldlLogo}
						style={matchesMobile ? { margin: '1rem 0', width: '72%' } : null}
					/>
					<Typography
						color={'secondary'}
						fontSize={matchesTablet ? 14 : 32}
						fontWeight={'bold'}
						className={'poppins'}
						style={{ width: '60%' }}
					>
						+ Kit de Flashcards Hardwork
					</Typography>
					<Grid
						item
						xs={12}
						container
						direction={'column'}
						alignItems={'center'}
						justifyContent={'center'}
						rowGap={1}
					>
						<DefaultButton
							variant="outlined"
							color={'primary'}
							className={`${classes.subscribeBtn} white-btn-secondary poppins`}
							onClick={() => executeScroll()}
						>
							<img
								src={require('../../../../Assets/Svg/Icons/bagIcon.svg').default}
								alt=""
								className={classes.logoBtn}
							/>
							&nbsp;&nbsp;Garantir vaga
						</DefaultButton>
						<Typography
							color={'secondary'}
							fontSize={matchesTablet ? 12 : 20}
							fontWeight={600}
							className={'poppins'}
						>
							Vagas limitadas
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className="residencia"
			>
				<Grid className="section">
					<Typography
						align="left"
						className={`${classes.title} gradient-text title-card-home`}
					>
						{`Vai fazer o ${contest?.name.toUpperCase()} só ano que vem? Comece agora!`}
					</Typography>
					<Typography
						color={'#fff'}
						fontSize={matchesTablet ? 15 : 30}
						className="poppins"
					>
						Aproveite a oportunidade de conhecer antecipadamente o modo Hardwork de entrar na alma da banca
						e comece sua preparação pela maior e melhor revisão de véspera: a toda poderosa Live das Lives
						Hardwork, que domina as provas de residência. E ainda, ganhe um{' '}
						<strong> Kit de Flashcards exclusivos </strong>
						Hardwork, para te acompanhar do internato à residência!
					</Typography>
				</Grid>
				<Grid
					container
					className="section"
				>
					<Grid
						item
						xs={12}
						container
						justifyContent={'space-between'}
					>
						<Grid
							item
							xs={12}
							md={6}
							container
							alignContent={'center'}
							style={{ top: matchesMobile ? null : matchesTablet ? 15 : 30 }}
						>
							<img
								src={require('./Assets/revisao-de-vespera.svg').default}
								alt=""
							/>
							<Typography
								color="secondary"
								className={'Poppins'}
								style={{
									fontSize: matchesTablet ? '1.1rem' : '2.05rem',
									fontWeight: 'bold',
									marginTop: '0.5rem',
									lineHeight: 'unset',
								}}
							>
								A estratégia perfeita para entrar na alma da banca.
							</Typography>
							<img
								src={
									matchesMobile
										? require('./Assets/img-ldl-experience-01-mobile-rev1.png')
										: require('./Assets/img-ldl-experience-01-rev1.png')
								}
								alt=""
							/>
							<Typography
								color={'#C1C1C1'}
								className={`${classes.firstText} poppins`}
							>
								Foco total na prova {adjustArticle(currentContest?.name)} <br />
								{currentContest?.name?.toUpperCase()}
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							md={5.5}
							container
						>
							<img
								src={
									matchesMobile
										? require('./Assets/img-ldl-experience-02-mobile-rev1.png')
										: require('./Assets/img-ldl-experience-02-rev1.png')
								}
								alt=""
								className={classes.SecondImageLDL}
							/>
							<Typography
								color={'#C1C1C1'}
								className={`${classes.secondText} poppins`}
							>
								Imersão total do
								<br />
								começo ao fim!
							</Typography>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						container
					>
						<Grid
							item
							xs={12}
							md={7}
							container
							alignContent={'center'}
						>
							<img
								src={
									matchesMobile
										? require('./Assets/img-ldl-experience-03-mobile-rev1.png')
										: require('./Assets/img-ldl-experience-03-rev1.png')
								}
								alt=""
							/>
							<Typography
								color={'#C1C1C1'}
								className={`${classes.thirdText} poppins`}
							>
								Um show de didática com
								<br />
								os melhores professores
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							md={5}
							container
							alignContent={'center'}
						>
							<img
								src={
									matchesMobile
										? require('./Assets/img-ldl-experience-04-mobile-rev1.png')
										: require('./Assets/img-ldl-experience-04-rev1.png')
								}
								alt=""
							/>
							<Typography
								color={'#C1C1C1'}
								className={`${classes.fourthText} poppins`}
							>
								"Eu ouvi as vozes dos professores na hora da prova"
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Container>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					container
					alignContent="space-around"
					justifyContent="center"
					className="section"
				>
					<Grid
						item
						xs={12}
						className="residencia"
					>
						<Typography
							align="left"
							className={`${classes.title} gradient-text title-card-home`}
						>
							Como funciona a live?
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						style={{ margin: matchesMobile ? '0.5rem 0 1.5rem' : '1.5em 0 2.5em' }}
					>
						<Typography
							align="left"
							color="secondary"
							className={`${classes.text} poppins`}
						>
							Uma revisão de véspera explorando todos os temas importantes das bancas.
							<br />A Live das Lives {currentContest?.name?.toUpperCase()} é um {''}
							{currentContest?.name === 'enare' ? (
								<strong>evento EXCLUSIVAMENTE presencial. {''}</strong>
							) : (
								<strong>
									evento EXCLUSIVAMENTE {''}
									{currentContest?.modality}. {''}
								</strong>
							)}
							{currentContest?.modality === 'presencial' ? 'Não há modelo online.' : null}
							<br />
							<br />A prova {adjustArticle(currentContest?.name)} {currentContest?.name?.toUpperCase()}
							{currentContest?.name === 'enare'
								? `, e mais específicamente a banca ${currentContest?.testBench},`
								: null}{' '}
							exige uma revisão muito especial. Para a conexão ser intensa do início ao fim, discutir e
							sedimentar muitos temas, a live é dividida em blocos diferentes, envolvendo vários
							professores simultaneamente:
						</Typography>
					</Grid>

					<Grid
						item
						xs={12}
						container
						justifyContent={matchesMobile ? 'space-between' : 'center'}
					>
						<Grid
							item
							className={classes.gridCarouselContainer}
							ref={scriptCarousel}
						>
							{currentContest &&
								scriptMock?.map((item, index) => {
									return (
										<Grid
											item
											xs={12}
											md={6}
											key={index}
											className={classes.gridScriptCard}
											style={index > 0 ? { marginLeft: '1.5em' } : null}
											onClick={() => handleOpen(item.source)}
										>
											<ScriptCard
												img={item.img}
												gif={item.gif}
												title={item.title}
												description={item.description}
												key={index}
												classes={classes}
											/>
										</Grid>
									);
								})}
						</Grid>
						<StyledModal
							aria-labelledby="unstyled-modal-title"
							aria-describedby="unstyled-modal-description"
							open={open}
							onClose={handleClose}
							slots={{ backdrop: StyledBackdrop }}
						>
							<Box
								display={'flex'}
								justifyContent={'center'}
								direction={''}
								sx={containerBox}
								style={matchesMobile ? { width: '330px', height: '190px' } : null}
							>
								<video
									sx={videoModal}
									src={selectedVideo}
									autoPlay
									loop
									style={matchesMobile ? { width: '25rem', borderRadius: 15 } : { borderRadius: 15 }}
								/>
							</Box>
						</StyledModal>

						<Button
							onClick={handleScriptLeftClick}
							className={`${classes.arrowBtn} ${classes.leftArrowBtn}`}
						>
							<img
								src={ArrowBack}
								alt=""
								className={classes.arrowIcon}
							/>
						</Button>
						<Button
							onClick={handleScriptRightClick}
							className={`${classes.arrowBtn} ${classes.arrowBtnRight}`}
						>
							<img
								src={ArrowForward}
								alt=""
								className={classes.arrowIcon}
							/>
						</Button>
					</Grid>
				</Grid>
				<Grid
					container
					alignContent="space-around"
					justifyContent="center"
					className="section"
					style={matchesMobile ? null : { margin: matchesTablet ? '4rem auto' : '3rem auto' }}
				>
					<Grid
						item
						xs={12}
						className="residencia"
					>
						<Typography
							align="left"
							className={`${classes.title} gradient-text title-card-home`}
						>
							Quando e onde a Live das Lives {currentContest?.name?.toUpperCase()} irá acontecer?
						</Typography>
					</Grid>
					<WhereWhenComponent contest={currentContest} />
					<BoxApp
						CellphoneImg={CellphoneImg}
						IconApple={IconApple}
						IconBrowser={IconBrowser}
						IconGoogle={IconGoogle}
						LogoLdLHQ={LogoLdLHQ}
					/>
					<div className="section">
						<KitHardwork
							img2={GiftBag}
							title={`E para alunos que comparecerem à Live das Lives presencial em ${contest?.locationCity}...`}
							text={
								'Receba o kit de prova exclusivo do Hardwork, com caderno, caneta, capa de chuva, cordão de crachá e	mais…'
							}
						/>
						<KitHardwork
							img2={GiftFlashcards}
							title={'Receba o Kit de Flashcards exclusivos Hardwork!'}
							text={
								'Flashcards criados pelos professores Hardwork que abordam os temas mais quentes das provas de residência médica. '
							}
						/>
					</div>
				</Grid>
			</Container>
			<Grid
				container
				className="background-new-ldl-residencia-horizontal"
			>
				<UploadModal
					uploadPopup={uploadPopup}
					idTurma={currentContest?.id}
					setUploadPopup={setUploadPopup}
				/>
				<Grid
					container
					style={{ padding: '2vh 5%' }}
					className={classes.backgroundWaves}
				>
					<Container
						fixed
						maxWidth="xl"
						disableGutters={true}
					>
						<Grid
							container
							alignContent="center"
							justifyContent="center"
							className={`${matchesTablet ? null : 'section'}`}
							style={{
								borderRadius: 15,
								margin: matchesTablet ? '30px auto' : '30px auto 60px',
							}}
							ref={purchase_card}
						>
							<Grid
								item
								xs={12}
								style={{ marginBottom: '2rem' }}
							>
								<Typography
									align="left"
									color="primary"
									className={`${classes.title} title-card-home`}
								>
									Garanta sua inscrição!
								</Typography>
							</Grid>
							{currentLotTurma1 && (
								<PriceCard
									lot={currentLotTurma1[0]}
									idTurma={currentContest?.id}
									contest={currentContest}
									numParticipants={numParticipants}
									lotModality={currentContest?.modality}
									limit={sold}
									handleUploadPopup={handleUploadPopup}
								/>
							)}
							<Typography
								align="left"
								color={'#E8E8E8'}
								fontSize={16}
								className="poppins"
								style={{ marginTop: '1rem' }}
							>
								*Evento exclusivo para público de residência médica
							</Typography>
						</Grid>
						<Grid
							item
							container
							direction={'column'}
							justifyContent={'center'}
							alignItems={'center'}
							rowGap={1.5}
						>
							<Typography
								color={'#FFFFFF'}
								fontSize={32}
								fontWeight={'bold'}
							>
								Não é aluno do quinto ano?
							</Typography>
							<DefaultButton
								variant="outlined"
								color={'primary'}
								// className={`${classes.subscribeBtn} ${
								// 	isActive ? 'background-new-ldl-residencia-horizontal' : null
								// } poppins`}
								style={{
									backgroundColor: '#ffffff',
									width: 'fit-content',
									fontSize: 20,
									padding: '0.5rem 2rem',
									fontWeight: 'bold',
									border: 0,
								}}
								className={'poppins'}
								component={Link}
								to={contest?.redirectLink}
							>
								Acesse por esta página
							</DefaultButton>
						</Grid>
					</Container>
				</Grid>
			</Grid>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					container
					alignContent="space-around"
					justifyContent="center"
					className="section"
				>
					<Grid
						item
						xs={12}
						className="residencia"
						style={{ marginBottom: '2rem' }}
					>
						<Typography
							align="left"
							className={`${classes.title} gradient-text title-card-home`}
						>
							Depoimentos de nossos alunos
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						container
						justifyContent={matchesMobile ? 'space-between' : 'center'}
						style={matchesMobile ? { marginBottom: '1rem' } : null}
					>
						<Grid
							item
							container
							className={classes.gridCarouselContainer}
							ref={feedbackCarousel}
							style={matchesMobile ? { marginBottom: '1rem' } : null}
						>
							{currentContest?.feedbacks &&
								currentContest?.feedbacks.map((img, index) => {
									return (
										<Grid
											key={index}
											item
											xs={12}
											md={3}
											container
											className={classes.gridFeedbackCards}
											style={index > 0 ? { marginLeft: '1.5em' } : null}
										>
											<Grid
												item
												xs={12}
											>
												<img
													src={img}
													alt=""
													style={{
														width: '100%',
														borderBottomLeftRadius: 15,
														borderBottomRightRadius: 15,
													}}
												/>
											</Grid>
										</Grid>
									);
								})}
						</Grid>
						<Button
							onClick={handleFeedbackLeftClick}
							className={`${classes.arrowBtn} ${classes.leftArrowBtnFeedbackSection}`}
						>
							<img
								src={ArrowBack}
								alt=""
								className={classes.arrowIcon}
							/>
						</Button>
						<Button
							onClick={handleFeedbackRightClick}
							className={`${classes.arrowBtn} ${classes.arrowBtnRightFeedbackSection}`}
							style={{ top: 200 }}
						>
							<img
								src={ArrowForward}
								alt=""
								className={classes.arrowIcon}
							/>
						</Button>
					</Grid>
				</Grid>
			</Container>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					container
					alignContent="space-around"
					justifyContent="center"
					className="section"
				>
					<Grid
						item
						xs={12}
						className="residencia"
					>
						<Typography
							align="left"
							className={`${classes.title} gradient-text title-card-home`}
						>
							{matchesMobile
								? ['Olha quem ', <br />, ' vai conduzir o show: *']
								: 'Olha quem vai conduzir o show: *'}
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						container
						justifyContent={matchesMobile ? 'space-between' : 'center'}
					>
						<Grid
							item
							className={classes.gridCarouselContainer}
							ref={carousel}
							style={matchesMobile ? { left: 0 } : null}
						>
							{Hosts.map((item, index) => (
								<Grid
									item
									key={index}
									style={
										matchesMobile && index === 0
											? { marginRight: 35, right: 0 }
											: index === Hosts?.length - 1
											? { marginRight: 10 }
											: { marginRight: 35 }
									}
								>
									<HostCard
										ig_username={item.ig_username}
										img={item.img}
										specialty={item.specialty}
										key={index}
									/>
								</Grid>
							))}
						</Grid>
						<Grid
							item
							xs={12}
							style={matchesMobile ? { margin: '1em 0' } : { margin: '1em 0 1.5em' }}
						>
							<Typography
								align="left"
								color="secondary"
								className={`${classes.text} poppins`}
							>
								*Sujeito a alterações
							</Typography>
						</Grid>
						<Button
							onClick={handleLeftClick}
							className={`${classes.arrowBtn} ${classes.leftArrowBtn}`}
							style={{ top: 200 }}
						>
							<img
								src={ArrowBack}
								alt=""
								className={classes.arrowIcon}
							/>
						</Button>
						<Button
							onClick={handleRightClick}
							className={`${classes.arrowBtn} ${classes.arrowBtnRight}`}
							style={{ top: 200 }}
						>
							<img
								src={ArrowForward}
								alt=""
								className={classes.arrowIcon}
							/>
						</Button>
					</Grid>
				</Grid>
			</Container>
			<Footer />
		</Container>
	);
}
//Quando abre o modal deixa o background escuro
const Backdrop = React.forwardRef((props, ref) => {
	const { open, className, ...other } = props;
	return (
		<div
			className={clsx({ 'MuiBackdrop-open': open }, className)}
			ref={ref}
			{...other}
		/>
	);
});

Backdrop.propTypes = {
	className: PropTypes.string.isRequired,
	open: PropTypes.bool,
};

const useStyles = makeStyles((theme, linkActive) => ({
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.75rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.9rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '2.35rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.8rem !important',
		},
	},
	paper: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
	},
	scriptPaper: {
		height: 403,
		width: 684,
		cursor: 'pointer',
		[theme.breakpoints.down('xl')]: {
			width: 585,
			height: 349,
		},
		[theme.breakpoints.down('lg')]: {
			height: 'unset',
			width: 435,
		},
		[theme.breakpoints.down('md')]: {
			height: 393,
			width: 585,
		},
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
			width: 'unset',
		},
	},
	text: {
		fontSize: '1.3125rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
			lineHeight: '1.5 !important',
		},
	},
	arrowIcon: {
		width: '47%',
	},
	arrowBtn: {
		position: 'absolute !important',
		zIndex: 1000,
		borderRadius: '50% !important',
		width: 80,
		height: 80,
		backgroundColor: '#2D2D2D !important',
		opacity: 0.75,
		'&:hover': { opacity: 1, transition: '0.2s' },
		[theme.breakpoints.down('sm')]: {
			position: 'unset !important',
			zIndex: 'unset',
			width: 60,
			height: 60,
		},
	},
	leftArrowBtn: {
		left: -90,
		top: 150,
		[theme.breakpoints.down('xl')]: {
			left: -90,
		},
		[theme.breakpoints.down(1389)]: {
			left: -50,
		},
		[theme.breakpoints.down('md')]: {
			left: -90,
		},
	},
	arrowBtnRight: {
		right: -80,
		top: 150,
		[theme.breakpoints.down('xl')]: {
			right: -35,
		},
		[theme.breakpoints.down('lg')]: {
			right: -55,
		},
	},
	gridCarouselContainer: {
		display: 'flex !important',
		overflowX: 'hidden',
		scrollBehavior: 'smooth',
		flexWrap: 'nowrap !important',
		overflowY: 'hidden',
		[theme.breakpoints.down('sm')]: {
			overflowX: 'auto',
		},
	},
	scriptTitle: {
		fontSize: '3.125rem !important',
		fontWeight: 'bold !important',
		position: 'absolute',
		bottom: 10,
		left: 35,
		textTransform: 'none',
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem !important',
			left: 17,
		},
	},
	scriptText: {
		fontSize: '1.125rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	gridScriptCard: {
		[theme.breakpoints.down('sm')]: {
			minWidth: '100%',
		},
	},
	gridDescriptionScriptCard: {
		backgroundColor: '#FFFFFF !important',
		padding: '1em 2em',
		borderRadius: 16,
		textTransform: 'none',
		[theme.breakpoints.down('sm')]: {
			padding: '1em',
		},
	},
	defaultBtn: {
		fontSize: '1.05rem !important',
		fontWeight: '700 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	logoBtn: {
		filter: 'invert(1)',
		transition: 'ease-in-out 0.2s',
		[theme.breakpoints.down('xl')]: {
			height: 30,
		},
		[theme.breakpoints.down('sm')]: {
			height: 17,
		},
	},
	subscribeBtn: {
		width: 'fit-content',
		padding: '0.5rem 5rem !important',
		alignSelf: 'center',
		fontSize: '1.35rem !important',
		fontWeight: 'bold !important',
		marginTop: '4rem !important',
		'&:hover': {
			scale: 1.05,
			color: '#ffffff !important',
			'& $logoBtn': {
				filter: 'invert(0)',
			},
		},
		[theme.breakpoints.down('xl')]: {
			padding: '0.25rem 2.5rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '0.85rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.75rem !important',
			padding: '0.25rem 1.25rem !important',
			border: '2px solid transparent !important',
			marginTop: '2rem !important',
		},
	},
	gridLogoContainer: {
		position: 'absolute',
	},
	ldlLogo: {
		alignSelf: 'center',
		[theme.breakpoints.down(1509)]: {
			width: '60%',
		},
		[theme.breakpoints.down('sm')]: {
			alignSelf: 'center',
			width: '80%',
		},
	},
	videoBackground: {
		objectFit: 'cover',
		height: 676,
		width: '100%',
		[theme.breakpoints.down('xl')]: {
			height: 480,
		},
		[theme.breakpoints.down('md')]: {
			height: 332,
		},
		[theme.breakpoints.down('sm')]: {
			height: 283,
		},
	},
	backgroundWaves: {
		backgroundImage: `url(${process.env.PUBLIC_URL + '/Assets/Img/bgWave2.png)'})`,
		width: '100vw !important',
		backgroundSize: 'cover',
	},
	gridFeedbackCards: {
		backgroundColor: '#313131',
		paddingTop: '1rem !important',
		borderRadius: '15px',
		minWidth: '25%',
		[theme.breakpoints.down('md')]: {
			minWidth: '50%',
		},
		[theme.breakpoints.down('sm')]: {
			minWidth: '70%',
		},
	},
	leftArrowBtnFeedbackSection: {
		left: -90,
		top: 245,
		[theme.breakpoints.down('xl')]: {
			left: -90,
		},
		[theme.breakpoints.down(1389)]: {
			left: -50,
		},
		[theme.breakpoints.down('md')]: {
			left: -90,
		},
	},
	arrowBtnRightFeedbackSection: {
		right: -80,
		top: 245,
		[theme.breakpoints.down('xl')]: {
			right: -35,
		},
		[theme.breakpoints.down('lg')]: {
			right: -55,
		},
	},
	firstText: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		letterSpacing: '-2px !important',
		position: 'absolute',
		bottom: '10px',
		left: '70px',
		textShadow: '2px 2px 4px #000000',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.5rem !important',
			bottom: '0px',
			left: '50px',
			color: 'secondary !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.5rem !important',
			left: '30px',
		},
		[theme.breakpoints.down('sm')]: {
			bottom: '5px',
			fontSize: '1.5rem !important',
		},
	},
	secondText: {
		letterSpacing: '-2px !important',
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		position: 'absolute',
		bottom: '5px',
		left: '70px',
		textShadow: '2px 2px 4px #000000',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.5rem !important',
			left: '50px',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.5rem !important',
			left: '30px',
		},
		[theme.breakpoints.down('sm')]: {
			bottom: '0px',
			fontSize: '1.5rem !important',
		},
	},
	thirdText: {
		fontSize: '3rem !important',
		letterSpacing: '-2px !important',
		fontWeight: 'bold !important',
		position: 'absolute',
		bottom: '155px',
		left: '70px',
		lineHeight: 'unset !important',
		textShadow: '2px 2px 4px #000000',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.5rem !important',
			bottom: '90px',
			left: '50px',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.5rem !important',
			bottom: '60px',
		},
		[theme.breakpoints.down('sm')]: {
			bottom: '-8px',
			fontSize: '1.5rem !important',
			left: '30px',
		},
	},
	fourthText: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		letterSpacing: '-2px !important',
		position: 'absolute',
		bottom: '-8px',
		left: '70px',
		textShadow: '2px 2px 4px #000000',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.5rem !important',
			left: '50px',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.5rem !important',
			left: '30px',
		},
		[theme.breakpoints.down('sm')]: {
			bottom: '-2px',
			fontSize: '1.5rem !important',
		},
	},
}));

const StyledModal = styled(Modal)`
	position: fixed;
	z-index: 1300;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
	z-index: -1;
	position: fixed;
	inset: 0;
	background-color: rgb(0 0 0 / 0.5);
	-webkit-tap-highlight-color: transparent;
`;

const containerBox = {
	width: 710,
	height: 400,
	borderRadius: '12px',
	boxShadow: '0px 2px 24px',
	padding: '0.5rem',
	backgroundColor: '#343a40',
};

const videoModal = {};
