import React from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import { Header } from '../../../../Components/Header';

import Form from '../../../../Components/Utils/Forms/PreRegistration';
import { Helmet } from 'react-helmet';

export default function SprintFinal2024Pre() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			<Helmet>
				<title>Pré Inscrição - Hardwork Medicina</title>
				<meta
					name="description"
					content="Inspire-se. Hardwork-se. Medicine-se. Aprove-se"
				/>
			</Helmet>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
			</Container>
			<Grid
				container
				style={{ backgroundColor: '#F5F5F5' }}
			>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					<Grid
						container
						className="section"
						style={{ paddingBottom: '2rem !important' }}
					>
						<Grid
							item
							xs={12}
							lg={6}
							container
							alignContent="center"
							justifyContent={'center'}
							className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
							style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
						>
							<Grid
								item
								xs={12}
								container
								alignContent="flex-end"
								rowGap={2}
							>
								<img
									src={require('./assets/logo-sf-residencia-2024.svg').default}
									alt=""
									style={{ width: '100%' }}
								/>
								<Typography
									fontFamily={'Manrope'}
									color={'primary'}
									style={{ fontSize: matchesTablet ? 18 : 28, fontWeight: 'bold' }}
								>
									14.673 histórias Hardwork construídas.
								</Typography>
								<Typography
									color={'primary'}
									className={`${classes.mainTitle}`}
									fontFamily={'Manrope'}
								>
									O primeiro e único intensivo específico para cada banca de residência médica. Cada
									banca tem o seu Sprint!
									<br />
									<br />
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<div>
											<span
												style={{
													background: '#000',
													color: '#fff',
													padding: '0 4px 0 4px',
													borderRadius: '4px',
													fontWeight: 'bold',
												}}
											>
												LIVE
											</span>{' '}
											<span style={{ fontWeight: 'bold' }}>ABERTURA DE INSCRIÇÕES</span>
										</div>

										<span style={{ marginTop: '0.5rem', fontWeight: 500 }}>
											O método Hardwork: como prever questões de provas?
										</span>
									</div>
								</Typography>
								<Grid
									container
									justifyContent={'center'}
									alignContent={'center'}
									style={{
										padding: '12px 0 12px 0',
										width: '98%',
										background: '#303030',
										borderRadius: '8px',
										letterSpacing: '1.68px',
									}}
								>
									<Typography
										fontFamily={'Manrope'}
										color={'secondary'}
										style={{ fontSize: matchesTablet ? '18px' : '28px', fontWeight: 'bold' }}
									>
										16JUL • TERÇA-FEIRA • 19:00H
									</Typography>
								</Grid>
								<Grid>
									<Typography
										color={'primary'}
										className={`${classes.mainTitle}`}
										fontFamily={'Manrope'}
										style={{ paddingTop: matchesTablet ? '' : '2rem' }}
									>
										Inscreva-se gratuitamente para participar da live e aproveitar as condições de
										lançamento.
									</Typography>
								</Grid>
								{matchesTablet && (
									<Grid
										item
										xs={12}
										container
										justifyContent={'center'}
										style={{ paddingLeft: '2rem', margin: '1rem 0' }}
									>
										<img
											src={require('./assets/sprint-final-cellphone-2024.png')}
											alt=""
											style={{ width: '100%' }}
										/>
									</Grid>
								)}
								<Grid style={{ marginTop: !matchesTablet && '1rem' }}>
									<Form
										group={'PRÉ INSCRIÇÃO SPRINT FINAL RESIDÊNCIA - 2024'}
										title={'Inscreva-se gratuitamente!'}
										titleButton={'Confirmar inscrição'}
										type={'sprint-gray'}
										urlToRedirect={'/pre-inscricao/sprint-final-2024'}
										confirmationMessage={'Sua pré-inscrição está confirmada!'}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							lg={6}
							container
							justifyContent={'center'}
							style={matchesTablet ? { display: 'none' } : { paddingLeft: '9rem' }}
						>
							<img
								src={require('./assets/sprint-final-cellphone-2024.png')}
								alt=""
								style={{ width: '100%' }}
							/>
						</Grid>
					</Grid>
				</Container>
			</Grid>
			{matchesTablet ? null : <Footer />}
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	sectionMobile: {
		padding: '2vh 5% 0',
		[theme.breakpoints.down('md')]: {
			padding: '2vh 0 2rem',
		},
	},
	mainTitle: {
		fontSize: '1.75rem !important',
		lineHeight: 'unset !important',
		alignSelf: 'center',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.25rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	text: {
		fontSize: '1rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
			marginBottom: '1rem !important',
		},
	},
	textImage: {
		[theme.breakpoints.down('lg')]: {
			width: '60%',
		},
	},
}));
