import React, { useEffect, useState } from 'react';
import {
	Checkbox,
	Container,
	Divider,
	FormControlLabel,
	FormGroup,
	Grid,
	Radio,
	RadioGroup,
	Slider,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../Components/Footer';
import { Header } from '../../Components/Header';
import DefaultButton from '../../Components/Buttons/DefaultButton';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useRef } from 'react';
import Api from '../../Services/Api';
import { trackPromise } from 'react-promise-tracker';
import { useSearchParams } from 'react-router-dom';

const QuestionSlide = ({ classes, title, children, step, onHandleAnswer, questions, index }) => {
	return index === -1 ? (
		<Grid
			item
			xs={6}
			container
			justifyContent={'center'}
			className={`revalida`}
			style={{ margin: '0 auto', bottom: '-52%' }}
		>
			<DefaultButton
				variant="outlined"
				color="secondary"
				className={`${classes.startBtn} btn btn-primary`}
				onClick={() => onHandleAnswer(step, index)}
			>
				Iniciar pesquisa
			</DefaultButton>
		</Grid>
	) : (
		<Grid
			container
			direction={'column'}
			justifyContent={step > 7 ? 'center' : 'space-between'}
			alignItems={'center'}
			style={{ height: '100%' }}
		>
			<Grid
				item
				container
				direction={'column'}
				className={classes.gridTitle}
				// style={{
				// 	backgroundImage: `url(${img})`,
				// }}
			>
				<Typography
					align="left"
					color="secondary"
					className={`${classes.titleInformationalCard} roboto-condensed`}
					style={step > 7 ? { textAlign: 'center' } : null}
				>
					{title}
				</Typography>
				{children}
			</Grid>
			{step > 7 ? null : (
				<Grid
					item
					xs={6}
					container
					justifyContent={'center'}
					alignContent="center"
					style={{ flexBasis: 0, marginBottom: '1.5rem' }}
				>
					<Grid
						item
						xs={8}
						md={6}
						container
						justifyContent={'center'}
						className={`${classes.gridBtn} revalida`}
					>
						<DefaultButton
							variant="outlined"
							color="secondary"
							className={`${classes.btn} btn btn-primary`}
							onClick={() => onHandleAnswer(step, index)}
						>
							{step > 7 ? 'Concluir pesquisa' : 'Avançar'}
						</DefaultButton>
					</Grid>
				</Grid>
			)}
		</Grid>
	);
};

const AssesmentCard = ({ classes }) => {
	const [sliderValueObjectiveTest, setSliderValueObjectiveTest] = useState(50);
	const [sliderValueDiscursiveTest, setSliderValueDiscursiveTest] = useState(50);
	const [step, setStep] = useState(-1);
	const refSplide = useRef(Splide);
	const [questions, setQuestions] = useState([
		{
			cod: 'Q0',
			options: ['Atingi a nota de corte', 'Fiquei abaixo da nota de corte', 'Ainda dependo de recursos'],
			answer: '',
		},
		{
			cod: 'Q1',
			options: [''],
			answer: '',
		},
		{
			cod: 'Q2',
			options: [''],
			answer: '',
		},
		{
			cod: 'Q3',
			options: ['Objetiva', 'Discursiva'],
			answer: '',
		},
		{
			cod: 'Q4',
			options: [
				'Clínica Médica',
				'Cirurgia Geral',
				'Ginecologia e Obstetrícia',
				'Pediatria',
				'Medicina Preventiva',
			],
			answer: '',
		},
		{
			cod: 'Q5',
			options: [
				'Clínica Médica',
				'Cirurgia Geral',
				'Ginecologia e Obstetrícia',
				'Pediatria',
				'Medicina Preventiva',
			],
			answer: '',
		},
		{
			cod: 'Q6a',
			options: ['Arena Presencial + Online', 'Arena Online'],
			answer: '',
		},
		{
			cod: 'Q6b',
			options: [''],
			answer: '',
		},
		{
			cod: 'Q7',
			options: [''],
			answer: '',
		},
	]);
	const [searchParams] = useSearchParams();

	useEffect(() => {
		let emailAluno = searchParams.get('e');
		if (!emailAluno) {
			document.location.href = '/';
		}
	}, [searchParams]);

	const handleAnswer = (currentStep, index) => {
		var nextStep = currentStep + 2;
		if (step > nextStep) {
			nextStep = step;
		}
		if (index < 0) {
			setStep(nextStep);
			setTimeout(() => refSplide.current.go(nextStep), 250);
		} else if (questions[index].answer !== '' || index === 6 || index === 7) {
			setStep(nextStep);
			setTimeout(() => refSplide.current.go(nextStep), 250);
		} else {
			alert('Selecione ao menos alguma opção para avançar!');
		}
		var pesquisaRevalida = {
			pesquisa: 'Prova Teórica',
			aluno: searchParams.get('e'),
			respostas: questions,
		};
		trackPromise(Api.post('PesquisaRevalida', pesquisaRevalida));
	};

	const responder = (questionIndex, answerValue) => {
		console.log(questionIndex, answerValue);
		let q = [...questions];
		q[questionIndex].answer = answerValue;
		setQuestions(q);
	};

	const handleCheckboxChange = (questionIndex, newValue, checked) => {
		let q = [...questions];
		let answer = q[questionIndex].answer.split('|');
		if (checked) {
			if (!answer.includes(newValue)) answer.push(newValue);
		} else {
			answer.splice(answer.indexOf(newValue), 1);
		}
		responder(questionIndex, answer.join('|'));
	};

	const handleSliderChangeObjective = (questionIndex, newValue) => {
		setSliderValueObjectiveTest(newValue);
		setTimeout(() => {
			responder(questionIndex, sliderValueObjectiveTest);
		}, 750);
	};

	const handleSliderChangeDiscursive = (questionIndex, newValue) => {
		setSliderValueDiscursiveTest(newValue);
		setTimeout(() => {
			responder(questionIndex, sliderValueDiscursiveTest);
		}, 750);
	};

	// const handleTextFieldChange = (event, nestedQuestionIndex, questionIndex) => {
	// 	const newValue = event.target.value;
	// 	setSliderValue(newValue);
	// 	// setTextFieldValue(newValue);
	// 	responder(nestedQuestionIndex, questionIndex, newValue);
	// };

	const handleSteps = (step) => {
		setStep(step);
		setTimeout(() => refSplide.current.go(step), 250);
	};

	return (
		<Grid
			container
			direction={'column'}
			justifyContent={'space-between'}
			className={`${classes.paper} rounded-card`}
		>
			<Splide
				className={classes.splideMainContainer}
				options={{
					arrows: false,
					height: '450px',
					width: '100%',
					drag: false,
					speed: 900,
					pagination: false,
				}}
				ref={refSplide}
			>
				<SplideSlide>
					<QuestionSlide
						step={-1}
						classes={classes}
						onHandleAnswer={handleAnswer}
						index={-1}
					></QuestionSlide>
				</SplideSlide>
				<SplideSlide>
					<QuestionSlide
						title="Qual foi o resultado da prova teórica?"
						classes={classes}
						step={0}
						onHandleAnswer={handleAnswer}
						questions={questions}
						index={0}
					>
						<Grid
							item
							container
							style={{ overflowY: 'auto', maxHeight: '315px' }}
						>
							<RadioGroup
								aria-labelledby="demo-radio-buttons-group-label"
								name="radio-buttons-group"
								style={{ rowGap: '0.5rem' }}
							>
								{questions[0].options.map((item, index) => {
									return (
										<FormControlLabel
											key={index}
											value={item}
											control={
												<Radio
													color="secondary"
													sx={{
														color: '#ffffff',
													}}
												/>
											}
											label={<span className={`${classes.spanStyle} poppins`}>{item}</span>}
											onChange={() => responder(0, item)}
											className={classes.radioBtnRoot}
										/>
									);
								})}
							</RadioGroup>
						</Grid>
					</QuestionSlide>
				</SplideSlide>
				{questions[0].answer !== '' && step > 0 && (
					<SplideSlide>
						{/*Slider  */}
						<QuestionSlide
							title={'Qual foi a tua pontuação na prova objetiva?'}
							classes={classes}
							step={1}
							onHandleAnswer={handleAnswer}
							questions={questions}
							index={1}
						>
							<Grid
								container
								justifyContent="space-between"
								alignItems="center"
								style={{
									backgroundColor: '#FFFFFF',
									borderRadius: '10px',
									opacity: 0.9,
									padding: '1rem',
									marginTop: '1rem',
									overflowY: 'auto',
									maxHeight: '315px',
								}}
							>
								<Grid
									item
									xs={9.5}
								>
									<Slider
										value={sliderValueObjectiveTest}
										onChange={(e) => handleSliderChangeObjective(1, e.target.value)}
										aria-label="Default"
										valueLabelDisplay="auto"
										className={classes.slider}
										style={{ width: '100%' }}
									/>
								</Grid>
								<Grid
									item
									container
									xs={2}
								>
									<Grid
										container
										justifyContent="center"
										alignItems="center"
										style={{
											backgroundColor: '#828282',
											borderRadius: 8,
											padding: '0 0.5rem',
											width: '100%',
										}}
									>
										<Typography
											color="secondary"
											className={`${classes.textSlider} poppins`}
										>
											{sliderValueObjectiveTest}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</QuestionSlide>
					</SplideSlide>
				)}
				{step > 1 && (
					<SplideSlide>
						<QuestionSlide
							title="Qual foi a tua nota na prova discursiva?"
							classes={classes}
							step={2}
							onHandleAnswer={handleAnswer}
							questions={questions}
							index={2}
						>
							<Grid
								container
								justifyContent="space-between"
								alignItems="center"
								style={{
									backgroundColor: '#FFFFFF',
									borderRadius: '10px',
									opacity: 0.9,
									padding: '1rem',
									marginTop: '1rem',
									overflowY: 'auto',
									maxHeight: '315px',
								}}
							>
								<Grid
									item
									xs={9.5}
								>
									<Slider
										value={sliderValueDiscursiveTest}
										onChange={(e) => handleSliderChangeDiscursive(2, e.target.value)}
										aria-label="Default"
										valueLabelDisplay="auto"
										className={classes.slider}
										style={{ width: '100%' }}
										step={0.01}
									/>
								</Grid>
								<Grid
									item
									container
									xs={2}
								>
									<Grid
										container
										justifyContent="center"
										alignItems="center"
										style={{
											backgroundColor: '#828282',
											borderRadius: 8,
											padding: '0 0.5rem',
											width: '100%',
										}}
									>
										<Typography
											color="secondary"
											className={`${classes.textSlider} poppins`}
										>
											{sliderValueDiscursiveTest}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</QuestionSlide>
					</SplideSlide>
				)}
				{step > 2 && (
					<SplideSlide>
						<QuestionSlide
							title="Qual o tipo de prova que é mais desafiador pra ti?"
							classes={classes}
							step={3}
							onHandleAnswer={handleAnswer}
							questions={questions}
							index={3}
						>
							<Grid
								item
								container
								style={{ overflowY: 'auto', maxHeight: '315px' }}
							>
								<RadioGroup
									aria-labelledby="demo-radio-buttons-group-label"
									name="radio-buttons-group"
									style={{ rowGap: '0.5rem' }}
								>
									{questions[3].options.map((item, index) => {
										return (
											<FormControlLabel
												key={index}
												value={item}
												control={
													<Radio
														color="secondary"
														sx={{
															color: '#ffffff',
														}}
													/>
												}
												label={<span className={`${classes.spanStyle} poppins`}>{item}</span>}
												onChange={() => responder(3, item)}
												className={classes.radioBtnRoot}
											/>
										);
									})}
								</RadioGroup>
							</Grid>
						</QuestionSlide>
					</SplideSlide>
				)}
				{step > 3 && (
					<SplideSlide>
						<QuestionSlide
							title={'Qual foi a área que tiveste mais facilidade?'}
							classes={classes}
							step={4}
							onHandleAnswer={handleAnswer}
							questions={questions}
							index={4}
						>
							<Grid className={classes.gridFormScroll}>
								<FormGroup style={{ rowGap: '1rem' }}>
									{questions[4].options.map((item, index) => {
										return (
											<FormControlLabel
												key={index}
												control={
													<Checkbox
														value={item}
														className={classes.checkbox}
														onChange={(e) =>
															handleCheckboxChange(4, e.target.value, e.target.checked)
														}
														disabled={
															!questions[4].answer.split('|').includes(item) &&
															questions[4].answer.split('|').length >= 4
														}
													/>
												}
												label={<span className={`${classes.spanStyle} poppins`}>{item}</span>}
												style={{ color: '#ffffff', margin: 0 }}
											/>
										);
									})}
								</FormGroup>
							</Grid>
						</QuestionSlide>
					</SplideSlide>
				)}
				{step > 4 && (
					<SplideSlide>
						<QuestionSlide
							title="Qual foi a área que tiveste mais dificuldade?"
							classes={classes}
							step={5}
							onHandleAnswer={handleAnswer}
							questions={questions}
							index={5}
						>
							<Grid className={classes.gridFormScroll}>
								<FormGroup style={{ rowGap: '1rem' }}>
									{questions[5].options.map((item, index) => {
										return (
											<FormControlLabel
												key={index}
												control={
													<Checkbox
														value={item}
														className={classes.checkbox}
														onChange={(e) =>
															handleCheckboxChange(5, e.target.value, e.target.checked)
														}
														disabled={
															!questions[5].answer.split('|').includes(item) &&
															questions[5].answer.split('|').length >= 4
														}
													/>
												}
												label={<span className={`${classes.spanStyle} poppins`}>{item}</span>}
												style={{ color: '#ffffff', margin: 0 }}
											/>
										);
									})}
								</FormGroup>
							</Grid>
						</QuestionSlide>
					</SplideSlide>
				)}
				{step > 5 &&
					(questions[0].answer === 'Atingi a nota de corte' ||
					questions[0].answer === 'Ainda dependo de recursos' ? (
						<SplideSlide>
							<QuestionSlide
								title={['Qual Arena Hardwork gostaria de fazer?']}
								classes={classes}
								step={6}
								onHandleAnswer={handleAnswer}
								questions={questions}
								index={6}
							>
								<Grid
									item
									container
									style={{ overflowY: 'auto', maxHeight: '315px' }}
								>
									<RadioGroup
										aria-labelledby="demo-radio-buttons-group-label"
										name="radio-buttons-group"
										style={{ rowGap: '0.5rem' }}
									>
										{questions[6].options.map((item, index) => {
											return (
												<FormControlLabel
													key={index}
													value={item}
													control={
														<Radio
															color="secondary"
															sx={{
																color: '#ffffff',
															}}
														/>
													}
													label={
														<span className={`${classes.spanStyle} poppins`}>{item}</span>
													}
													onChange={() => responder(6, item)}
													className={classes.radioBtnRoot}
												/>
											);
										})}
									</RadioGroup>
								</Grid>
							</QuestionSlide>
						</SplideSlide>
					) : (
						<SplideSlide>
							<QuestionSlide
								title={['Como pensa em se preparar a partir de agora?']}
								classes={classes}
								step={6}
								onHandleAnswer={handleAnswer}
								questions={questions}
								index={6}
							>
								<Grid style={{ overflowY: 'auto', maxHeight: '315px' }}>
									<TextField
										fullWidth
										type="string"
										placeholder="Escreva sua mensagem..."
										multiline
										rows={8}
										InputProps={{
											className: classes.form,
										}}
										InputLabelProps={{
											shrink: true,
										}}
										onChange={(e) => responder(7, e.target.value)}
									/>
								</Grid>
							</QuestionSlide>
						</SplideSlide>
					))}
				{step > 6 && (
					<SplideSlide>
						<QuestionSlide
							title={['Caso queira, compartilhe com a gente a tua experiência com o Hardwork até agora.']}
							classes={classes}
							step={7}
							onHandleAnswer={handleAnswer}
							questions={questions}
							index={7}
						>
							<Grid style={{ overflowY: 'auto', maxHeight: '315px' }}>
								<TextField
									fullWidth
									type="string"
									placeholder="Escreva sua mensagem..."
									multiline
									rows={8}
									InputProps={{
										className: classes.form,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									onChange={(e) => responder(8, e.target.value)}
								/>
							</Grid>
						</QuestionSlide>
					</SplideSlide>
				)}
				{step > 7 ? (
					<SplideSlide>
						<QuestionSlide
							title={['Show! Vamos juntos!', <br />, 'Em breve entraremos em contato.']}
							classes={classes}
							step={8}
							onHandleAnswer={handleAnswer}
							questions={questions}
						></QuestionSlide>
					</SplideSlide>
				) : null}
			</Splide>
			{step >= 0 && 9 > step ? (
				<Grid
					item
					container
					justifyContent={'center'}
					columnGap={1.5}
				>
					{questions.map((item, index) => {
						if (index !== 0 && index !== 9) {
							return (
								<Grid
									key={index}
									style={{ cursor: 'pointer', alignSelf: 'center' }}
									onClick={() => (index < step ? handleSteps(index) : null)}
								>
									<Typography
										style={{
											fontSize: index === step ? '3.5rem' : '2.5rem',
											color: index === step ? '#ffffff' : null,
										}}
									>
										&bull;
									</Typography>
								</Grid>
							);
						} else {
							return null;
						}
					})}
				</Grid>
			) : null}
		</Grid>
	);
};

export default function RevalidaResearchPage() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					justifyContent={'space-between'}
					alignContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
				>
					<Grid
						item
						xs={12}
						md={6}
						container
						justifyContent={'flex-start'}
						className={`${classes.gridTextContainer} revalida`}
						gap={2}
						style={{ height: 'fit-content' }}
					>
						<Typography
							className={`${classes.title} gradient-text roboto-condensed`}
							color="secondary"
						>
							Queremos saber de ti!
						</Typography>

						<Typography
							className={`${classes.subtitle} poppins`}
							color="secondary"
						>
							Compartilhe tua jornada com a gente!
						</Typography>
						<Divider
							variant="middle"
							className={classes.divider}
						/>
						<Typography
							align="left"
							color="secondary"
							className={`${classes.text} poppins`}
						>
							Nós estamos sempre contigo. O time Hardwork caminha junto até o fim.
							<br />
							<br />
							Conte pra gente como foi a tua última prova teórica, pra sabermos o que precisamos fazer
							para te auxiliar na caminhada.
							<br />
							<br />É uma pesquisa rápida, prática, mas que vai ajudar muito para fazermos o melhor pra
							ti.
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
						container
						justifyContent="center"
						className={classes.gridWithBackgroundImage}
						gap={matchesTablet ? 4 : 3}
					>
						<AssesmentCard classes={classes} />
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto 0',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.2rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
			marginBottom: '8px !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.18rem !important',
		},
	},
	subtitle: {
		fontWeight: 'bold !important',
		fontSize: '1.25rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.95rem !important',
		},
	},
	text: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	gridForm: {
		width: 567,
		flexDirection: 'column !important',
		alignItems: 'center !important',
		[theme.breakpoints.down('xl')]: {
			bottom: 20,
		},
		[theme.breakpoints.down(1300)]: {
			width: 467,
		},
		[theme.breakpoints.down('md')]: {
			position: 'unset',
			right: 'unset',
			top: 'unset',
			zIndex: 0,
		},
	},
	gridTextContainer: {
		[theme.breakpoints.down('lg')]: {
			paddingRight: '2em',
		},
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
	},
	gridWithBackgroundImage: {
		paddingLeft: '2rem',
		[theme.breakpoints.down('lg')]: {
			padding: 'unset',
		},
		[theme.breakpoints.down('md')]: {
			backgroundImage: 'unset',
			marginTop: '2rem !important',
		},
	},
	logo: {
		marginBottom: '1rem',
		[theme.breakpoints.down('xl')]: {
			marginBottom: '2.5rem',
		},
		[theme.breakpoints.down('md')]: {
			marginBottom: '1rem',
		},
	},
	divider: {
		margin: 'unset !important',
		backgroundColor: '#707070',
		minHeight: 1,
		width: '100%',
	},
	paper: {
		border: '1px solid #00FFA8',
		height: '100%',
		padding: '1.5rem 0 0 !important',
		backgroundImage: `url(${require('../../Assets/Extensivo/Revalida/bg-assesment-card-page.png')})`,
		backgroundSize: 'cover',
		[theme.breakpoints.down('xl')]: {
			width: 585,
		},
		[theme.breakpoints.down('lg')]: {
			height: 'unset',
			width: 435,
			marginBottom: 30,
		},
		[theme.breakpoints.down('md')]: {
			width: 585,
		},
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
			width: 'unset',
		},
	},
	gridTitle: {
		padding: '0 2rem',
		// width: '95% !important',
		borderTopRightRadius: 20,
		borderTopLeftRadius: 20,
		height: 'fit-content',
		[theme.breakpoints.down('sm')]: {
			padding: '0px 15px',
		},
	},
	titleInformationalCard: {
		fontSize: '2rem !important',
		fontWeight: 'bold !important',
		margin: '8px 0 !important',
		lineHeight: 'unset !important',
		marginBottom: '1rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '16px 0 !important',
			lineHeight: 'unset !important',
			fontSize: '1.25rem !important',
		},
	},
	gridDescriptionCard: {
		backgroundColor: '#FFFFFF !important',
		padding: '1em 1.5em',
		borderBottomLeftRadius: 16,
		borderBottomRightRadius: 16,
		[theme.breakpoints.down('sm')]: {
			padding: '1em',
		},
	},
	paperDescription: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	textDateStyle: {
		borderTopLeftRadius: 20,
		borderBottomRightRadius: 20,
		width: '240px !important',
		[theme.breakpoints.down('sm')]: {
			width: '200px !important',
		},
	},
	ctaTextCard: {
		fontSize: '1.3rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
			fontWeight: '500 !important',
			margin: '5px 0 !important',
		},
	},
	form: {
		border: '1px solid #ffffff',
		backgroundColor: theme.palette.secondary.main,
		padding: '0.5rem !important',
		'& .MuiOutlinedInput-notchedOutline': {
			borderStyle: 'unset',
		},
	},
	startBtn: {
		fontSize: '1.75rem !important',
		fontWeight: 'bold !important',
		padding: '0px !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	btn: {
		fontSize: '1.25rem !important',
		fontWeight: 'bold !important',
		padding: '0px !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	slider: {
		height: '11px !important',
		padding: '8px 0 !important',
		'& .MuiSlider-track': {
			color: '#09D785 !important',
		},
		'& .MuiSlider-thumb': {
			color: '#ffffff',
			border: '1px solid #000000',
			width: '51px',
			borderRadius: '13px',
		},
		'& .MuiSlider-rail': {
			color: '#6A6A6A',
		},
	},
	splideMainContainer: {
		'& .splide': {
			height: '100%',
		},
	},
	radioBtnRoot: {
		color: '#ffffff !important',
		fontSize: '0.75rem !important',
		'& .MuiButtonBase-root-MuiRadio-root.Mui-checked': { color: '#ffffff', fontSize: '0.75rem !important' },
		'& .MuiTypography-root MuiTypography-body1 MuiFormControlLabel-label': {
			fontSize: '0.75rem !important',
		},
	},
	checkbox: {
		color: '#ffffff !important',
		// paddingTop: '0.25rem !important',
		alignSelf: 'start',
		'& .MuiFormControlLabel-root': {
			color: '#ffffff !important',
		},
	},
	gridFormScroll: {
		overflowY: 'auto',
		maxHeight: '240px',
		'&::-webkit-scrollbar-thumb': {
			background: '#ffffff',
		},
		'&::-webkit-scrollbar-track': {
			background: '#6D6D6D',
		},
	},
	spanStyle: {
		color: '#ffffff !important',
		fontSize: '0.9rem !important',
		lineHeight: 'unset',
	},
	textSlider: {
		fontSize: '1.5rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
}));
