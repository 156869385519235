import React, { useRef, useState } from 'react';
import { Container, Divider, Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';

// COMPONENTS
import Footer from '../../../Components/Footer';
import { Header } from '../../../Components/Header';
import ProductCardWhite from '../../../Components/Cards/ProductCardWhite';
import InformationalWhiteCard from '../../../Components/Cards/InformationalWhiteCard';
import cardIcon from '../Assets/sweepstakeIconRevalida.svg';
import Form from '../../../Components/Utils/Forms/PreRegistration';

const LiveReactCard = ({ classes }) => {
	return (
		<Paper
			className={`${classes.paper} rounded-card box box-primary`}
			elevation={4}
		>
			<Grid
				container
				justifyContent="center"
				// style={{ padding: '0.5em 1em' }}
			>
				{/* <img
					src={require('../Assets/logo-correction-live.svg').default}
					alt="Logo Live React Revalida"
				/> */}
			</Grid>

			<Grid
				container
				alignContent="center"
				// style={ matchesLg ? {height: '271px', width: '450px'}  : null }
			>
				<Form
					group="LIVE DE CORREÇÃO REVALIDA INEP 2024.1"
					title={['Inscreva-se para participar da live e do sorteio']}
					titleButton="Confirmar Inscrição"
					type="revalida"
					noCellphone
					urlToRedirect="/live/react-revalida"
					confirmationMessage={'Sua inscrição está confirmada! Te avisaremos antes do início da live.'}
				/>
				{/* <Typography
					align="left"
					color="secondary"
					className={`${classes.text} poppins`}
				>
					Correção ao vivo da prova, com comentários das questões mais polêmicas do Inep, download de recursos
					em PDF e sorteio de uma vaga e cupons de desconto para o Arena Hardwork!
				</Typography>
				<Typography
					align="left"
					color="secondary"
					className={`${classes.impactText} poppins`}
					style={{ padding: '0 10px' }}
				>
					QUINTA-FEIRA
					<span className="bullet"> &#8226; </span>
					21MAR
					<span className="bullet"> &#8226; </span>
					11:00
				</Typography> */}
			</Grid>
		</Paper>
	);
};

export default function ReactRevalida() {
	const classes = useStyles();
	const theme = useTheme();
	const embeddedContent = useRef(null);
	const [isSubscribed, setIsSubscribed] = useState(false);
	const [isOpenSignUp, setIsOpenSignUp] = useState(false);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const executeScroll = () => embeddedContent.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

	const handleSubscribed = () => {
		setIsSubscribed(true);
	};

	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const cidade = query.get('cidade');

	// const cidades = {
	// 	sp: 'São Paulo',
	// 	poa: 'Porto Alegre',
	// 	bh: 'Belo Horizonte',
	// 	bsb: 'Brasília',
	// 	ctb: 'Curitiba',
	// 	pv: 'Porto Velho',
	// 	rf: 'Recife',
	//     cg: 'Campo Grande',
	//     rib: 'Rio Branco',
	//     slv: 'Salvador'
	// };

	// if (cidades.hasOwnProperty(cidade)) {
	// 	cidade = cidades[cidade];
	// }

	return (
		<Container
			fixed
			maxWidth="xl"
			disableGutters={true}
		>
			{matchesMobile || matchesTablet ? null : <Header />}
			<Grid
				container
				className="section"
				style={matchesMobile ? { marginTop: 80 } : { marginBottom: 0 }}
			>
				<Grid
					item
					xs={12}
					md={9}
					container
					justifyContent="center"
				>
					{matchesMobile ? (
						<img
							src={require('../Assets/live-react-banner-arena-vertical.png')}
							// style={{left: -20}}
							alt=""
						/>
					) : (
						<img
							src={require('../Assets/live-react-banner-arena.png')}
							style={{ left: -20 }}
							alt=""
						/>
					)}
				</Grid>
				<Grid
					item
					xs={12}
					md={4}
					lg={6}
					// xl={6}
					className={classes.firstSectionGridCard}
					container
					justifyContent="flex-end"
					alignItems="center"
				>
					<LiveReactCard classes={classes} />
				</Grid>
				<Grid
					item
					xs={12}
					container
					style={matchesMobile ? { marginBottom: 16 } : { marginTop: '2em' }}
				>
					<Typography
						className={`${classes.subtitle1} roboto-condensed`}
						color="secondary"
					>
						Correção da prova Revalida INEP de março 2024
					</Typography>
					<Typography
						className={`${classes.subtitle2} poppins`}
						color="secondary"
					>
						Inscreva-se ou faça login para acompanhar a correção de prova no player abaixo!
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
				>
					<Divider
						variant="middle"
						className={classes.divider}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					ref={embeddedContent}
				>
					<iframe
						title="correcao"
						src={`//hardworkmedicina.com.br/correcao-prova/127?cidade=${cidade}`}
						style={{ border: 0, width: '100%', height: '630px', backgroundColor: 'transparent' }}
					></iframe>
				</Grid>
			</Grid>
			<Grid
				className="section"
				container
				style={{ marginTop: 0 }}
			>
				<Grid
					item
					xs={12}
				>
					<Divider
						variant="middle"
						className={classes.divider}
					/>
				</Grid>
				<Grid
					container
					// justifyContent="space-between" Apply with 3 Cards
					justifyContent={matchesMobile ? 'center' : 'space-around'}
					style={{ marginTop: 16 }}
				>
					<ProductCardWhite
						img={require('../../../Assets/Arena/arenaDemoCardImage.png')}
						logo={require('../../../Assets/Arena/logo-arena-24.svg').default}
						description="Preparação para prova prática do revalida inep. Faça a pré-inscrição para ter prioridade nas inscrições e mais chances de garantir a vaga!"
						titleButton="Pré-inscrição"
						classes={classes}
						url={'/pre-inscricao/arena-hardwork'}
					/>
					{/* <ProductCardWhite
						img={require('../../../Assets/Arena/arenaDemoCardImage.png')}
						logo={require('../../../Assets/Arena/logo-arenaHwm.svg').default}
						description="Preparação para prova prática do revalida inep. Faça a pré-inscrição para ter prioridade nas inscrições e mais chances de garantir a vaga!"
						titleButton="Pré-inscrição"
						classes={classes}
						url={'/arena-hardwork-2023-1'}
					/>
					<ProductCardWhite
						img={require('../../../Assets/Extensivo/Revalida/revalidaBannerImage.png')}
						logo={require('../../../Assets/Extensivo/Revalida/revalida241Logo.svg').default}
						description="Vai fazer a prova do revalida inep de fevereiro de 2023? Agora o extensivo e sprint estão juntos!!! Inscrições abertas"
						titleButton="Saiba mais"
						classes={classes}
						url={'//hardworkmedicina.com.br/extensivo-revalida'}
					/> */}
				</Grid>
				<Grid
					item
					xs={12}
					// md={isSubscribed ? 12 : 8}
					// lg={12}
					lg={isSubscribed ? 12 : 6}
					container
					justifyContent="center"
					style={
						matchesMobile
							? { margin: '20px auto 0', padding: 0 }
							: { margin: '100px auto 0', padding: `0px 1.5em` }
					}
				>
					<InformationalWhiteCard
						title="Sorteio Arena Hardwork"
						description={[
							<strong>Você já está inscrito!</strong>,
							<br />,
							<br />,
							'Sortearemos vagas e cupons de desconto para o Arena Hardwork ao final da live de correção. ',
							<br />,

							<br />,
							'Para participar e estar elegível, o usuário deverá ter se pré-inscrito para acompanhar a live nesta página e se manifestar no chat caso seja um dos sorteados',
							<br />,
							<br />,
						]}
						img={require('../../../Assets/Extensivo/Revalida/banner-bg-revalida04.png')}
						icon={cardIcon}
						classes={classes}
					/>
					{/* <InformationalWhiteCard
						title="Ganhadores do sorteio"
						description={[
							<strong>Sorteada para uma vaga do Arena Hardwork Online:</strong>,
							<br />,
							'• Cynthia Dominguez',
							<br />,
							<br />,
							<strong>Sorteados para um cupom 30% de desconto do Arena Hardwork Presencial:</strong>,
							<br />,
							'• Maria Marta Teixeira',
							<br />,
							<br />,
							<strong>Sorteados para um cupom 30% de desconto do Arena Hardwork Online:</strong>,
							<br />,
							'• Savio Henrique',
							<br />,
							'• Kesia Santos',
						]}
						img={require('../../../Assets/Extensivo/Revalida/banner-bg-revalida04.png')}
						classes={classes}
					/> */}
					{/* {isSubscribed ? (
						<Grid
							item
							container
							justifyContent="center"
							alignContent="center"
							style={{
								backgroundColor: '#00c26e',
								height: `${matchesMobile ? 'unset' : '100px'}`,
								borderRadius: 20,
								padding: `${matchesMobile ? '1em' : '0px'} ${matchesMobile ? '1em' : '3em'}`,
							}}
						>
							<Typography
								color="secondary"
								style={{ fontSize: `${matchesTablet ? '0.8rem' : '1.25rem'}` }}
								className="poppins"
							>
								Sua inscrição foi efetuada com sucesso! Agora basta acompanhar a live nesta página e se
								manifestar no chat caso seja um dos sorteados.
							</Typography>
							<TaskAltIcon color="secondary" />
						</Grid>
					) : (
						<Sweepstake
							group="CORREÇÃO INEP 2023.2"
							title={['Sorteio Arena Hardwork']}
							description={[
								'Sortearemos uma vaga e cupons de desconto para o ',
								<span style={{ fontWeight: '600' }}>Arena Hardwork</span>,
								' ao final da live de correção.',
								<>
									<br />
									<br />
								</>,
								'Para participar e estar elegível, o usuário deverá ter se inscrito para acompanhar a live nesta página e se manifestar no chat caso seja um dos sorteados.',
							]}
							subtitle="Inscreva-se para concorrer ao sorteio"
							titleButton="Confirmar inscrição"
							type="revalida"
							isSubscribed={handleSubscribed}
							confirmationMessage="Massa! Em breve enviaremos um email com as orientações para participar da correção da prova!"
						/>
					)} */}
				</Grid>
			</Grid>
			<Footer />
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	paper: {
		width: 462,
		[theme.breakpoints.down('lg')]: {
			width: '200 !important',
		},
		[theme.breakpoints.down('md')]: {
			width: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '1.2em',
		},
	},
	firstSectionGridCard: {
		position: 'absolute',
		right: 0,
		top: 20,
		[theme.breakpoints.down('md')]: {
			position: 'unset',
			margin: '16px 0px !important',
		},
		// [theme.breakpoints.down('sm')]: {
		// 	position: 'unset',
		// 	margin: '16px 0px !important',
		// },
	},
	text: {
		fontSize: '1.5rem !important',
		lineHeight: '1.25 !important',
		marginTop: '8px !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.35rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	divider: {
		margin: '24px 0px !important',
		backgroundColor: 'grey',
		minHeight: 1,
	},
	subtitle1: {
		fontSize: '2.5rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('lg')]: {
			marginTop: '4rem !important',
		},
		[theme.breakpoints.down('md')]: {
			lineHeight: 'unset !important',
			marginTop: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
			lineHeight: 'unset !important',
			marginBottom: '8px !important',
		},
	},
	subtitle2: {
		fontSize: '1rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	impactText: {
		marginTop: '16px !important',
		fontSize: '1.5rem !important',
		lineHeight: 'unset',
		background: 'linear-gradient(90deg, rgba(0,145,166,1) 10%, rgba(0,194,110,1) 100%)',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1rem !important',
		},
	},
	btn: {
		width: 400,
		backgroundColor: '#FFFFFF !important',
		fontSize: '1.5rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		'&:hover': {
			scale: 1.05,
		},
		[theme.breakpoints.down('sm')]: {
			width: 'unset',
			fontSize: '1rem !important',
		},
	},
	form: {
		backgroundColor: '#ffffff',
		borderRadius: '10px !important',
		'& .MuiOutlinedInput-input': {
			color: '#75858D',
		},
	},
	smallText: {
		fontSize: '0.8rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.7rem !important',
		},
	},
}));
