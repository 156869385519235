/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import { Container, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../../Components/Footer';
import { Header } from '../../../../../Components/Header';
import Form from '../../../../../Components/Utils/Forms/PreRegistration';
// import { useState } from 'react';
import CalendarIcon from '../Assets/calendar-vector.svg';
import PinIcon from '../Assets/pin-vector.svg';

export default function Revaliday() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm')); //600 down
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md')); //900 down
	const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));     //1200 down
	const matchesDownXl = useMediaQuery(theme.breakpoints.down('xl')); //1536 down

	// const [closeGroup, setCloseGroup] = useState(false);

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}

			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className={classes.background}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
			</Container>
			<Grid
				style={{
					backgroundImage: matchesMobile ? `url(${require('../../../../LandingPages/FreeContent/HardworkEm/Assets/bg-pagina-mob.jpg')})`
                    : `url(${require('../../../../LandingPages/FreeContent/HardworkEm/Assets/bg-pagina-full.jpg')})`,
					height: '100%',
					backgroundSize: 'cover',
				}}
			>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
					className={classes.background}
				>
					<Grid
						container
						justifyContent={'space-between'}
						alignContent="center"
						className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					>
						<Grid
							item
							xs={12}
							md={6}
							container
							justifyContent={'flex-start'}
							className={`${classes.gridTextContainer} revalida`}
							gap={1}
						>
							{matchesDownXl ? (
								<img
									src={
										require('../Assets/logo-revaliday-cochabamba.svg')
											.default
									}
									alt="Logo Revalida cochabamba"
									style={matchesDownXl ? null : matchesTablet ? { height: 120 } : null}
									className={classes.logoSecond}
								/>
							) : null}
							<Grid className={classes.firstGrid}>
								<Typography
									className={`${classes.title} roboto-condensed`}
									color="secondary"
								>
									Vamos arrepiar em Foz! 
                                    <br />
                                    Uma noite com 8 aulas quentes para o Revalida.
								</Typography>
								<Typography
									className={`${classes.text} poppins`}
									color="secondary"
								>
									No dia 30/04 o Hardwork estará novamente em Foz, e dessa vez com muito mais discussões.
                                    Várias aulas exclusivas e gratuitas de temas essenciais da Clínica Médica e da Pediatria.
									<br />
									<br />
									Além de tudo, sorteios de brindes, cursos e cupons exclusivos para os presentes.
									<br />
                                    <br />
                                    As inscrições são gratuitas, mas as vagas são limitadas!
									<br />
									<br />
								</Typography>
							</Grid>

							<Grid className={classes.secondGrid}>
								<Grid
									container
									alignItems={'center'}
									gap={2}
								>
                                    {
                                        matchesMobile ? null :

                                        <img
                                            style={{ alignSelf: 'flex-start' }}
                                            src={CalendarIcon}
                                            alt=""
                                        />
                                    }
									<Typography
										align="left"
										color="secondary"
										className={`${classes.impactText} poppins`}
										style={{ fontWeight: 'bold' }}
									>
										PROGRAMAÇÃO DE 30 DE ABRIL
									</Typography>
								</Grid>
								<Grid
									item
									container
									style={{ marginBottom: '3em' }}
								>
									<Divider
										variant="middle"
										orientation="vertical"
										className={classes.divider}
									/>
									<Grid
										item
										container
										wrap="nowrap"
										gap={2}
									>
										<img
											src={require('../Assets/18.svg').default}
											alt=""
											style={{ height: 30, top: 5, paddingLeft: 2 }}
										/>
										<Grid
											item
											container
										>
											<Typography className={`${classes.timelineText} poppins`}>
												Credenciamento
											</Typography>
											<Typography
												color="secondary"
												className={`${classes.text} poppins`}
											>
												{/* Uma aula de temas sobre Clínica Médica e Pediatria. */}
												<br />
												<br />
											</Typography>
										</Grid>
									</Grid>
									<Grid
										item
										container
										wrap="nowrap"
										gap={2}
									>
										<img
											src={require('../Assets/19.svg').default}
											alt=""
											style={{ height: 30, top: 5 }}
										/>
										<Grid
											item
											container
										>
											<Typography className={`${classes.timelineText} poppins`}>
												Show de aulas Hardwork
											</Typography>
											<Typography
												color="secondary"
												className={`${classes.text} poppins`}
											>
												Discussão de vários temas quentes da Clínica e da Pediatria,
                                                com os professores Fabrício e Benito.
												<br />
                                                <br />
											</Typography>
										</Grid>
									</Grid>
									<Grid
										item
										container
										wrap="nowrap"
										gap={2}
									>
										<img
											src={require('../Assets/21.svg').default}
											alt=""
											style={{ height: 30, top: 5 }}
										/>
										<Grid
											item
											container
										>
											<Typography className={`${classes.timelineText} poppins`}>
												Sorteios Hardwork
											</Typography>
											<Typography
												color="secondary"
												className={`${classes.text} poppins`}
											>
												Brindes, cursos e cupons serão sorteados ao final do evento.
											</Typography>
										</Grid>
									</Grid>
									<Grid
										container
										justifyContent={'center'}
										marginTop={5}
									>
										<Grid
											container
											className={classes.borderdGrid}
										>
											<Typography
												align="left"
												color="secondary"
												className={`${classes.impactTextTitle} poppins`}
											>
												TERÇA-FEIRA
												<span className="bullet"> &#8226; </span>
												30ABR
												<span className="bullet"> &#8226; </span>
												18H
											</Typography>
										</Grid>
										{/* <Typography
											color="secondary"
											className={`${classes.text} poppins`}
										>
											Marque na agenda, as vagas são limitadas!
										</Typography> */}
									</Grid>
								</Grid>
							</Grid>

							<Grid className={classes.thirdGrid}>
								<Grid
									container
									alignItems={'center'}
									gap={3}
								>
									<img
										style={{ alignSelf: 'flex-start' }}
										src={PinIcon}
										alt=""
									/>
									<Typography
										align="left"
										color="secondary"
										className={`${classes.impactText} poppins`}
										style={{ fontWeight: 'bold' }}
									>
										ONDE SERÁ O EVENTO?
									</Typography>
								</Grid>

								<Typography
									color="secondary"
									className={`${classes.text} poppins`}
									style={{ marginBottom: '1.5rem' }}
								>
									Rafain Palace e Convention:
                                    <br />
                                    Av. Olímpio Rafagnin, 2357 - Parque Imperatriz, Foz do
									Iguaçu - PR, 85862-210.
                                    <br />
                                    Expocenter 1 e 2.
								</Typography>
								<iframe
									src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3601.110682761925!2d-54.54129742583196!3d-25.501354935716517!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94f690bd12f0fa11%3A0xf3cc0430e9da3a3a!2sRafain%20Palace%20Hotel%20%26%20Convention!5e0!3m2!1spt-BR!2sbr!4v1713448249364!5m2!1spt-BR!2sbr"
									width={matchesMobile ? '345' : matchesTablet ? '580' : matchesLg ? '390' : matchesDownXl ? '550' : '660'}
									height="233"
									allowfullscreen=""
									loading="lazy"
									referrerpolicy="no-referrer-when-downgrade"
                                    style={{borderRadius: '12px'}}
								></iframe>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							md={5}
							container
							justifyContent="center"
                            style={{height: 'auto', paddingTop: matchesTablet ? '' : 110}}
						>
							<Grid
								item
								xs={12}
								className={classes.gridForm}
								gap={1}
							>
								{matchesDownXl ? null : (
									<img
										src={
											require('../Assets/logo-revaliday-cochabamba.svg')
												.default
										}
										alt="Logo Revalida cochabamba"
										className={classes.logo}
									/>
								)}
								<Form
									group="REVALIDA INEP 2024(PRESENCIAL COCHA)"
									title={['Inscreva-se agora. As vagas são limitadas.']}
									titleButton="Garantir minha vaga"
									type="revalida"
									urlToRedirect="/revaliday/cochabamba-sucesso"
									confirmationMessage={'Sua inscrição está confirmada!'}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</Grid>
			{matchesTablet ? null : <Footer />}

			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '1% 5% auto',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3rem !important',
		lineHeight: 'unset !important',
		marginBottom: '2rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
			marginBottom: '8px !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.18rem !important',
			marginBottom: '8px !important',
			lineHeight: 'unset !important',
		},
	},
	text: {
		fontSize: '1.3125rem !important',
		color: 'rgba(255, 255, 255, 1)',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	firstGrid: {
		backgroundColor: 'black',
		borderRadius: '15px',
		marginTop: '6rem',
		padding: '20px',
	},
	secondGrid: {
		backgroundColor: 'black',
		borderRadius: '15px',
		marginTop: '2rem',
		padding: '20px',
	},
	thirdGrid: {
		backgroundColor: 'black',
		borderRadius: '15px',
		marginTop: '2rem',
		padding: '20px',
	},
	borderdGrid: {
		backgroundColor: 'rgba(9, 215, 133, 0.2)',
		border: '2px solid #09D785',
		borderRadius: '8px',
		justifyContent: 'center',
		width: '80% !important',
		height: '65% !important',
		padding: '8px',
	},
	gridForm: {
		width: 567,
		position: 'sticky',
		// bottom: 40,
        right: 0,
		top: 40,
		zIndex: 10,
		display: 'flex',
		flexDirection: 'column !important',
		alignItems: 'center !important',
        alignSelf:'flex-start',
		[theme.breakpoints.down(1300)]: {
			width: 467,
			right: 30,
			bottom: 10,
		},
		[theme.breakpoints.down('lg')]: {
			right: 50,
		},
		[theme.breakpoints.down(1010)]: {
			right: 20,
		},
		[theme.breakpoints.down('md')]: {
			position: 'unset',
			right: 'unset',
			top: 'unset',
			zIndex: 0,
		},
	},
	gridTextContainer: {
		paddingBottom: '1em',
		[theme.breakpoints.down('lg')]: {
			paddingRight: '2em',
		},
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
	},

	gridTitle: {
		backgroundColor: '#000000',
		padding: '0px 25px',
		borderTopRightRadius: 20,
		borderTopLeftRadius: 20,
		[theme.breakpoints.down('sm')]: {
			padding: '0px 15px',
		},
	},
	impactText: {
		margin: '0 0 2rem !important',
		fontSize: '2.2rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.3rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1rem !important',
		},
	},
	impactTextTitle: {
		margin: '0 0 2rem !important',
		fontSize: '2.2rem !important',
		lineHeight: 'unset !important',
        padding: 10,
        justifyContent: 'center',
        alignItems: 'center',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1rem !important',
		},
	},
	logo: {
        width: '120%',
		[theme.breakpoints.down('xl')]: {
			height: 'unset',
			width: 250,
		},
	},
	logoSecond: {
		marginTop: '6rem',
	},
	divider: {
		position: 'absolute',
		border: '1.5px solid #fff !important',
		left: '4.85em',
		backgroundColor: '#fff',
		height: '210px !important',
		// zIndex: -1,
		[theme.breakpoints.down('xl')]: {
			height: '200px !important',
		},
		[theme.breakpoints.down('lg')]: {
			height: '280px !important',
		},
		[theme.breakpoints.down('md')]: {
			height: '295px !important',
		},
		[theme.breakpoints.down('sm')]: {
			height: '190px !important',
		},
	},
	imgTime: {
		height: 30,
		top: 5,
	},
	timelineText: {
		fontSize: '1.5rem !important',
		color: 'white',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.2rem !important',
		},
	},
	impactText2: {
		margin: '1.5rem 0 0 !important',
		fontSize: '1.75rem !important',
		lineHeight: 'unset !important',
		fontWeight: 'bold !important',

		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.45rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1rem !important',
		},
	},
}));
