import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import transmissionLive from '../../../Pages/LandingPages/Subscription/Residencia/assets/live-transmission-live-cm-usp.png';

const InfoBox = ({ title, subtitle, img, themeColor }) => {
	const classes = useStyles();

	return (
		<Grid
			item
			xs={12}
			container
			justifyContent={'space-between'}
			alignItems={'center'}
		>
			<Grid
				item
				xs={3}
				md={2}
			>
				<img
					src={img}
					alt=""
				/>
			</Grid>
			<Grid
				item
				xs={8.5}
				md={9.5}
				container
				rowGap={2}
				style={{ height: 'fit-content' }}
			>
				<Typography
					align="left"
					color={themeColor ? '#FCFCFC' : 'primary'}
					className={`${classes.titleBox}`}
					fontFamily={'Manrope'}
				>
					{title}
				</Typography>
				<Typography
					align="left"
					color={themeColor ? '#CCCCCC' : 'primary'}
					className={`${classes.boxText}`}
					fontFamily={'Manrope'}
				>
					{subtitle}
				</Typography>
			</Grid>
		</Grid>
	);
};

export default function LiveClinicaMedicaUsp({ themeColor }) {
	const classes = useStyles();
	const theme = useTheme();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Grid
			container
			direction={'column'}
			wrap={'nowrap'}
			rowGap={2}
			className="clinica-medica"
		>
			{matchesTablet && (
				<img
					src={
						require('../../../Pages/LandingPages/Subscription/Residencia/assets/logo-live-clinica-usp.svg')
							.default
					}
					alt=""
					style={{ width: matchesMobile && '80%', margin: matchesTablet && '0 auto' }}
				/>
			)}
			<Typography
				align="left"
				color={themeColor ? '#FCFCFC' : 'primary'}
				className={`${classes.title} gradient-text`}
				fontFamily={'Manrope'}
			>
				Clínica Médica da USP
			</Typography>
			<Typography
				align="left"
				color={themeColor ? '#CCCCCC' : 'primary'}
				className={`${classes.text}`}
				fontFamily={'Manrope'}
			>
				Um dia inteiro de discussões com foco total na prova de clínica médica da USP, abordando os temas mais
				relevantes de cada uma das especialidades clínicas.
			</Typography>
			<Typography
				align="left"
				color={themeColor ? '#FCFCFC' : 'primary'}
				className={`${classes.impactText}`}
				fontFamily={'Manrope'}
			>
				100% on-line e 100% gratuito
			</Typography>
			<Grid
				item
				container
				justifyContent={'center'}
				style={{
					backgroundColor: '#303030',
					height: 'fit-content',
					padding: '0.5rem 2rem',
					borderRadius: '1rem',
					width: 'fit-content',
					margin: matchesTablet ? '1rem 0' : '1.5rem 0 2rem',
				}}
			>
				<Typography
					align="left"
					color={themeColor ? '#FCFCFC' : 'primary'}
					className={`${classes.impactText}`}
					fontFamily={'Manrope'}
				>
					09NOV • A partir das 09H00
				</Typography>
			</Grid>
			<Grid
				item
				xs={12}
				container
				rowGap={4}
			>
				<InfoBox
					title="Transmissão ao vivo"
					img={transmissionLive}
					themeColor={themeColor}
				/>
				{/* <InfoBox
							title="Download de materiais"
							subtitle="Baixe aqui o material personalizado que preparamos especialmente para você."
							img={materialsDownloadImg}
						/> */}
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: '800 !important',
		fontSize: '3.5rem !important',
		lineHeight: '1 !important',
		marginBottom: '1.5rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem !important',
		},
	},
	text: {
		fontSize: '1.25rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	impactText: {
		fontWeight: '800 !important',
		fontSize: '1.5rem !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1rem !important',
		},
	},
	titleBox: {
		fontWeight: 'bold !important',
		fontSize: '1.5rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.25rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1rem !important',
		},
	},
	boxText: {
		fontSize: '1rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.75rem !important',
		},
	},
}));
