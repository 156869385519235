import React from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../Components/Footer';
import { Header } from '../../../Components/Header';
import SheetArrow from './Assets/file-arrow-down.svg';

import Form from '../../../Components/Utils/Forms/PreRegistration';

export default function RevaldaDownloads() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					justifyContent={'space-between'}
					alignContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
				>
					<Grid
						item
						xs={12}
						md={6}
						container
						style={{ paddingBottom: !matchesMobile && '6rem' }}
					>
						<img
							src={require('./Assets/live-downloads.png')}
							alt=""
						/>

						<Grid className={classes.gridText}>
							<Grid
								container
								flexDirection={'column'}
								gap={'1rem'}
							>
								<Grid
									style={{
										backgroundColor: '#09D68640',
										padding: '8px',
										border: '1px solid #09D686',
										borderRadius: '8px',
										display: 'flex',
										gap: '1rem',
										marginTop: '2rem',
										justifyContent: 'center',
									}}
								>
									<img
										src={SheetArrow}
										alt=""
									/>
									<Typography
										color={'secondary'}
										className={`${classes.title} poppins`}
									>
										Inscreva-se gratuitamente para receber
									</Typography>
								</Grid>
							</Grid>
							<Typography
								color="secondary"
								style={{ marginTop: '1.8rem', marginBottom: '1rem' }}
								className={`${classes.text} poppins`}
							>
								O time Hardwork preparou um super resumo com atualizações de temas importantes dos anos
								de 2023 e 2024. Para receber, basta se cadastrar gratuitamente que encaminharemos para
								teu e-mail.
							</Typography>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						md={5}
						container
						justifyContent="center"
						className={classes.gridWithBackgroundImage}
					>
						<Grid
							item
							xs={12}
							className={classes.gridForm}
							gap={1}
						>
							<Form
								group="DOWNLOAD ATUALIZACAO TEMAS REVALIDA 2023-2024"
								title={['Inscreva-se para receber o material no seu e-mail!']}
								titleButton="Cadastre-se agora"
								type="gradient-residencia-light-shadow-revalida"
								urlToRedirect="/materiais/atualizacao-temas-2023-2024"
								confirmationMessage={'Em instantes você receberá o material em seu email cadastrado!'}
								noCellphone
							/>
						</Grid>
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto 10%',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	gridForm: {
		width: 567,
		position: 'sticky',
		// bottom: 40,
		right: 0,
		top: 40,
		zIndex: 10,
		display: 'flex',
		flexDirection: 'column !important',
		alignItems: 'center !important',
		alignSelf: 'flex-start',
	},
	desafioSection: {
		[theme.breakpoints.down('lg')]: {
			width: '180px !important',
			flexDirection: 'row',
		},
		[theme.breakpoints.down('sm')]: {
			width: '160px !important',
			flexDirection: 'row',
		},
	},
	title: {
		fontSize: '1.8rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		// color: 'rgba(54, 207, 70, 0.94) !important',

		[theme.breakpoints.down('lg')]: {
			fontSize: '1.35rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.2rem !important',
			lineHeight: 'unset !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1.1rem !important',
		},
	},
	titleSecond: {
		fontSize: '2.2rem !important',
		fontWeight: '700 !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.3rem !important',
			lineHeight: 'unset !important',
		},
	},
	titleDecoration: {
		fontSize: '6.5rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		position: 'absolute',
		left: '14rem',
		bottom: '-12px',
		// color: 'rgba(54, 207, 70, 0.94)',
		[theme.breakpoints.down('lg')]: {
			fontSize: '6.25rem !important',
			bottom: '-9px',
			left: '15rem',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '5.25rem !important',
			left: '12rem',
		},
	},
	text: {
		fontSize: '1.5rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.25rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	gridWithBackgroundImage: {
		paddingLeft: 20,
		[theme.breakpoints.down('md')]: {
			paddingLeft: 'unset',
		},
	},
	gridText: {
		// position: 'absolute',
		// top: 500,
		[theme.breakpoints.down('md')]: {
			top: 'unset',
			position: 'initial',
		},
	},
	impactText: {
		margin: '0 0 2rem !important',
		fontSize: '1.5rem !important',
		lineHeight: 'unset !important',
		fontWeight: '500 !important',
		width: 'fit-content !important',
		background: 'linear-gradient(90deg, var(#BB5CFF) 10%, var(#BB5CFFF0) 50%)',
		[theme.breakpoints.up('lg')]: {
			fontSize: '2.6rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.75rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1.3rem !important',
		},
	},
	divider: {
		position: 'absolute',
		border: '1.5px solid #fff !important',
		left: '4.85em',
		backgroundColor: '#fff',
		height: '230px !important',
		zIndex: -1,
		[theme.breakpoints.down('xl')]: {
			height: '200px !important',
		},
		[theme.breakpoints.down('lg')]: {
			height: '280px !important',
		},
		[theme.breakpoints.down('md')]: {
			height: '295px !important',
		},
		[theme.breakpoints.down('sm')]: {
			height: '240px !important',
		},
	},
}));
