import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

export default function BoxTextAndImage({ title, description, invert, img }) {
	const theme = useTheme();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<Grid
			item
			container
			direction={invert && !matchesMobile ? 'row-reverse' : 'row'}
			wrap={matchesMobile ? 'wrap':'nowrap'}
			style={{ padding: '1.5rem 1rem', backgroundColor: '#1D1D1D', borderRadius: 24 }}
		>
			<Grid item xs={12} md={4} paddingX={matchesMobile ? '4rem' : '0rem'}>
				<img
					src={img}
					alt=""
				/>
			</Grid>
			<Grid
				item
				container
				justifyContent={invert && !matchesMobile ? 'flex-end' : 'flex-start'}
				alignContent={'center'}
				rowGap={1.5}
				marginTop={matchesMobile ? '1.5rem' : '0rem'}
				xs={12}
				md={8}
			>
				<Typography
					color={'#F5F5F5'}
					fontFamily={'Manrope'}
					fontSize={matchesMobile? '1.5rem' : '2rem'}
					fontWeight={800}
					align={invert && !matchesMobile ? 'right' : 'left'}
					style={{
						lineHeight: 'unset',
					}}
				>
					{title}
				</Typography>
				<Typography
					color={'#F5F5F5'}
					align={invert && !matchesMobile ? 'right' : 'left'}
					fontSize={matchesMobile? '0.875rem' : '1.5rem'}
					fontFamily={'Manrope'}
				>
					{description}
				</Typography>
			</Grid>
		</Grid>
	);
}
