import React from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../../Components/Footer';
import { Header } from '../../../../../Components/Header';

import Form from '../../../../../Components/Utils/Forms/PreRegistration';

export default function SubscriptionEventRevalidaFoz() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					justifyContent={'space-between'}
					alignContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
				>
					<Grid
						item
						xs={12}
						md={6}
						container
						justifyContent={'flex-start'}
						className={`${classes.gridTextContainer} revalida`}
					>
						{matchesMobile ? (
							<img
								src={require('../../../../../Assets/Extensivo/Revalida/logo-revalida-foz.svg').default}
								alt="Logo Revalida Foz do Iguaçu"
								style={{ height: 120 }}
							/>
						) : null}
						<Typography
							className={`${classes.title} gradient-text roboto-condensed`}
							color="secondary"
						>
							Os temas mais quentes das provas do Revalida
						</Typography>
						<Typography
							className={`${classes.text} poppins`}
							color="secondary"
						>
							O convite está feito. Será uma <strong>aula presencial e gratuita em Foz do Iguaçu </strong>
							inesquecível, arrepiante.
							<br />
							<br />
							Vamos falar sobre a preparação para o Revalida e te mostrar os segredos dos temas mais
							abordados nos concursos.
							<br />
							<br />
							Marque na agenda. As vagas serão limitadas.
							<br />
							<br />
						</Typography>
						<Typography
							className={`${classes.text} poppins`}
							style={{ fontStyle: 'italic' }}
							color="secondary"
						>
							*A pré-inscrição não garante vaga para o evento, porém você será avisado primeiro assim que
							as inscrições forem abertas.
						</Typography>
						<Typography
							align="left"
							color="secondary"
							className={`${classes.impactText} poppins`}
						>
							SÁBADO
							<span className="bullet"> &#8226; </span>
							03JUN
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						md={5}
						container
						justifyContent="center"
						className={classes.gridWithBackgroundImage}
					>
						<Grid
							item
							xs={12}
							className={classes.gridForm}
							gap={1}
						>
							{matchesMobile ? null : (
								<img
									src={
										require('../../../../../Assets/Extensivo/Revalida/logo-revalida-foz.svg')
											.default
									}
									alt="Logo Revalida Foz do Iguaçu"
									className={classes.logoRevalidaFoz}
								/>
							)}
							<Form
								group="PRE INSCRICAO HARDWORK EM FOZ 2023"
								title={['Seja avisado quando as', <br />, 'inscrições abrirem']}
								titleButton="Confirmar Pré-inscrição"
								type="revalida"
								urlToRedirect="/"
							/>
						</Grid>
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.35rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
			lineHeight: 'unset !important',
			marginBottom: '8px !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.18rem !important',
			marginBottom: '2rem !important',
			lineHeight: 'unset !important',
		},
	},
	text: {
		lineHeight: 'unset !important',
		fontSize: '1.3125rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	bottomSignInCardText: {
		fontSize: '1.15rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
	},
	divider: {
		margin: 'unset !important',
		backgroundColor: 'grey',
		minHeight: 1,
	},
	workHourTitle: {
		fontSize: '1.7em !important',
		lineHeight: 1.25,
		fontWeight: 'bold !important',
	},
	containerWorkHours: {
		padding: '2em 0 2em 2em',
		[theme.breakpoints.down('lg')]: {
			padding: 0,
		},
	},
	workHourPaper: {
		height: '100%',
		[theme.breakpoints.down('lg')]: {
			height: 'unset !important',
		},
	},
	imgExtensivo: {
		width: '100% !important',
		[theme.breakpoints.down('md')]: {
			margin: '40px 0 !important',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '0px !important',
		},
	},
	paper: {
		width: 777,
		marginBottom: 50,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		[theme.breakpoints.down('xl')]: {
			width: 585,
		},
		[theme.breakpoints.down('lg')]: {
			height: 'unset',
			width: 435,
			marginBottom: 30,
		},
		[theme.breakpoints.down('md')]: {
			width: 585,
		},
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
			width: 'unset',
		},
	},
	titleInformationalCard: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		textShadow: '2px 2px 4px #000000',
		margin: '8px 0 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '16px 0 !important',
			lineHeight: 'unset !important',
		},
	},
	gridDescriptionCard: {
		backgroundColor: '#FFFFFF !important',
		padding: '1em 2em',
		borderRadius: 16,
		[theme.breakpoints.down('sm')]: {
			padding: '1em',
		},
	},
	gridForm: {
		width: 567,
		zIndex: 10,
		display: 'flex',
		flexDirection: 'column !important',
		alignItems: 'center !important',
		[theme.breakpoints.down('md')]: {
			position: 'unset',
			right: 'unset',
			top: 'unset',
			zIndex: 0,
		},
	},
	gridTextContainer: {
		paddingBottom: '1em',
		[theme.breakpoints.down('lg')]: {
			paddingRight: '2em',
		},
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
	},
	gridWithBackgroundImage: {
		paddingLeft: 20,
		backgroundImage: `url(${require('../../../../../Assets/Extensivo/Revalida/live-react-bg.png')})`,
		[theme.breakpoints.down('xl')]: {
			paddingLeft: 'unset',
		},
		[theme.breakpoints.down('md')]: {
			paddingLeft: 'unset',
		},
	},
	paperDescription: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	gridTitle: {
		backgroundColor: '#000000',
		padding: '0px 25px',
		borderTopRightRadius: 20,
		borderTopLeftRadius: 20,
		[theme.breakpoints.down('sm')]: {
			padding: '0px 15px',
		},
	},
	ctaTextCard: {
		fontSize: '1.3rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
			fontWeight: '500 !important',
			margin: '5px 0 !important',
		},
	},
	textDateStyle: {
		borderTopLeftRadius: 20,
		borderBottomRightRadius: 20,
		width: '192px !important',
		[theme.breakpoints.down('sm')]: {
			width: '162px !important',
		},
	},
	impactText: {
		height: '2rem',
		fontSize: '2rem !important',
		lineHeight: 'unset !important',
		background: 'linear-gradient(90deg, rgba(0,145,166,1) 10%, rgba(0,194,110,1) 100%)',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
			height: '1.5rem',
		},
		[theme.breakpoints.down('md')]: {
			margin: '2rem 0 !important',
			height: 'unset',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.45rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1rem !important',
		},
	},
	logoRevalidaFoz: {
		[theme.breakpoints.down('xl')]: {
			height: 'unset',
		},
	},
}));
