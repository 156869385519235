
import iconArrow from '../assets/icon-arrow-sprint.svg'
import iconCam from '../assets/icon-cam-sprint.svg'
import iconBallon from '../assets/icon-ballon-sprint.svg'
import iconGlass from '../assets/icon-glass-sprint.svg'
import iconMetrics from '../assets/icon-metrics-sprint.svg'
import iconApp from '../assets/icon-tablet-sprint.svg'

import card01 from '../assets/card01-cm.png'
import card02 from '../assets/card02-cm.png'
import card01Mobile from '../assets/card01-cm-mobile.png'
import card02Mobile from '../assets/card02-cm-mobile.png'

import bgFabricio from '../assets/bg-fabricio.png'
import bgFabricioMobile from '../assets/bg-fabricio-mobile.png'


export const resume = [
	{
		icon: iconArrow,
		title: 'Desafios Temáticos',
		text: 'Mapeamos todos os temas de cada banca e preparamos desafios temáticos com blocos de questões específicos; em cada desafio, aprendizados essenciais com comentários em texto, além de vídeos objetivos com detalhamento dos temas.',
	},
	{
		icon: iconCam,
		title: 'Vídeos objetivos',
		text: 'Vídeos objetivos direcionados para cada banca. Foco total nas mensagens relevantes para o R+ de cirurgia, com detalhamento de temas de cada subespecialidade.​',
	},
	{
		icon: iconBallon,
		title: 'Banco de questões',
		text: 'Centenas de questões comentadas de provas de R+ de cirurgia. Tenha acesso também a questões de de temas cirúrgicos de provas de acesso direto.​',
	},
	{
		icon: iconGlass,
		title: 'Revisões cíclicas diárias',
		text: 'Depois de desbravar cada tema nos desafios temáticos, hora de revisar até a data da prova! Todos os dias, um pequeno grupo de questões para ampliar, revisar e sedimentar todo o aprendizado.',
	},
	{
		icon: iconMetrics,
		title: 'Métricas personalizadas',
		text: 'Aqui o estudo é totalmente monitorado! Métricas detalhadas para acompanhar o aprendizado de cada tema relevante para a banca.',
	},

	{
		icon: iconApp,
		title: 'Aplicativo',
		text: 'Toda a flexibilidade de acesso através de aplicativo específico. Mas, se quiser, acesse também pelo navegador do computador.',
	},
];

export const cardsData = [
	{
		bg: card01,
		bgMobile: card01Mobile,
		title: 'Foco total nas provas de R3 de clínica médica',
		text: [
			'Existem similaridades entre as provas de acesso direto e as de R3 de ',
			<br />,
			'clínica? Sim, existem…',
			<br />,
            <br/>,
			'No entanto, o que faz a diferença são os temas peculiares do R3, e ',
			<br />,
			'cada banca tem os seus!',
			<br />,
			<br />,
			'O Sprint disseca tudo!',
			
            
		],
	},
	{
		bg: card02,
		bgMobile: card02Mobile,
		title: 'Revisão direcionada e intensa',
		text: [
			'No Sprint a revisão é intensa e dinâmica.',
			<br />,
			'O Time Hardwork te mostra o caminho para dominar cada tema na',
			<br />,
			'USP e/ou no ENARE!',
			<br />,
			<br />,
			'Tudo no Sprint é baseado no estudo minucioso da banca, e isso vale',
			<br />,
			'tanto para a tradicional banca da USP, quanto a nova do ENARE, a',
			<br />,
            'FGV…',
            <br/>,
            <br/>,
			'O Hardwork tem tudo nas mãos.',
			
		],
	},
];


export const cardsTeacher = [
	{
		bg: bgFabricio,
		bgMobile: bgFabricioMobile,
		title: 'O que é o Sprint Final Hardwork?',
		text: [
			'O Sprint Final surgiu há alguns anos para revolucionar a ',
			<br />,
			'preparação final para as provas de Residência.',
			<br />,
            <br/>,
			'Mostramos o impacto do estudo direcionado por banca e ',
			<br />,
			'personalizado para cada aluno. ',
			<br />,
			<br />,
			'Desconstruímos os modelos limitados a aulas resumidas, e ',
            <br/>,
            'atualizamos o conceito de intensivo para provas de ',
            <br/>,
            'residência.',
            <br/>,
            'O resultado? Milhares de histórias Hardwork criadas…'

			
            
		],
	},
	
];

export const temasEnare = [
    'Acidente por animais peçonhentos',
    'Acidente vascular encefálico',
    'Acidentes com animais peçonhentos',
    'Anafilaxia',
    'Anemias hemolíticas',
    'Anemias hipoproliferativas',
    'Artrite idiopática juvenil',
    'Artrite reumatoide',
    'Asma',
    'Asma aguda',
    'Bradiarritmias',
    'Câncer de pulmão',
    'Cefaleias',
    'Cirrose hepática',
    'Delirium',
    'Dengue',
    'Diabetes - complicações agudas',
    'Diabetes - complicações crônicas',
    'Diabetes - diagnóstico e tratamento',
    'Dislipidemia',
    'Distúrbio ácido-base',
    'Distúrbios eletrolíticos',
    'Doença inflamatória intestinal',
    'Doença renal crônica',
    'Doenças do pericárdio',
    'Doenças glomerulares',
    'DPOC',
    'Epilepsia',
    'Febre reumática',
    'Hepatites virais',
    'Hipertensão arterial sistêmica',
    'Hipertensão secundária',
    'Hipotireoidismo',
    'HIV',
    'Injúria renal aguda',
    'Insuficiência cardíaca',
    'Intoxicações exógenas',
    'Leptospirose',
    'Leucemias',
    'Lúpus',
    'Meningite',
    'Monoartrite aguda',
    'Osteoporose',
    'Pneumonia',
    'Profilaxia de raiva',
    'Psiquiatria da infância',
    'Sepse',
    'Síndrome coronariana aguda',
    'Síndrome do intestino irritável',
    'Tabagismo',
    'Taquiarritmias',
    'Tireotoxicose',
    'Transtornos do humor',
    'Tuberculose',
    'Úlceras genitais',
];

export const temasUsp = [
    'ACLS',
    'Alcoolismo',
    'Anafilaxia e angioedema hereditário',
    'Anemia Falciforme',
    'Anemias hemolíticas',
    'Anemias hipoproliferativas',
    'Antibióticos e Infecção de corrente sanguínea',
    'Arboviroses',
    'Arritmias',
    'Artrite Reumatoide',
    'Artrites Infecciosas',
    'Artrites Soronegativas',
    'Asma',
    'AVCH',
    'AVCI',
    'Câncer colorretal',
    'Cefaleias',
    'Cirrose',
    'Cuidados Paliativos',
    'Delirium',
    'Derrame pleural',
    'Diabetes - Complicações',
    'Diabetes - Diagnóstico e Tratamento',
    'Diabetes - Manejo hospitalar',
    'Dislipidemia',
    'Dispepsia',
    'Distúrbios Ácido-Básicos',
    'Distúrbios da hemostasia primária',
    'Distúrbios da hemostasia secundária',
    'Distúrbios do Cálcio',
    'Distúrbios do Potássio',
    'Distúrbios do Sódio',
    'Doença inflamatória intestinal',
    'Doença Renal Crônica',
    'Doenças das adrenais',
    'Doenças do pericárdio',
    'Doenças Glomerulares',
    'Doenças neuromusculares',
    'Doenças tireoidianas',
    'DPOC',
    'Endocardite',
    'Epilepsia',
    'Esclerose Sistêmica',
    'Estudos epidemiológicos',
    'Fundamentos da Bioestatística',
    'Hepatites',
    'Hiperparatireoidismo',
    'Hipertensão Arterial Primária',
    'Hipertensão Arterial Secundária',
    'HIV/AIDS',
    'Injúria Renal Aguda',
    'Insuficiência Cardíaca',
    'Leucemias',
    'Linfomas e mieloma múltiplo',
    'Litíase urinária',
    'Lombalgia',
    'Lúpus Eritematoso Sistêmico',
    'Meningite',
    'Neutropenia Febril',
    'Nódulo de tireóide',
    'Nódulo e câncer de pulmão',
    'Oncologia',
    'Osteoporose',
    'Outros temas em terapia intensiva',
    'Perioperatório',
    'Pneumonia',
    'Polimiosite e Dermatomiosite',
    'Psiquiatria',
    'Rastreamento',
    'Reações Transfusionais',
    'Sarcoidose',
    'Síndrome Coronariana',
    'Suporte ventilatório',
    'Tabagismo',
    'Testes diagnósticos',
    'Traumatismo cranioencefálico',
    'Tromboembolia pulmonar',
    'Tuberculose',
    'Vasculites',
];