import React from 'react';
import { Container, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import { Header } from '../../../../Components/Header';

import DefaultButton from '../../../../Components/Buttons/DefaultButton';
import { Link } from 'react-router-dom';
import Form from '../../../../Components/Utils/Forms/PreRegistration';
import { useEffect } from 'react';
import { useState } from 'react';

export default function LiveTrilhaRevalida2024() {
	const classes = useStyles();
	const theme = useTheme();
	const startDate = new Date('2023-06-13T20:30:00');
	// const startDate = new Date('2023-06-13T18:58:00');
	const [openTrilhaRevalida, setOpenTrilhaRevalida] = useState(false);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		let currentDate = new Date();
		if (currentDate >= startDate) {
			setOpenTrilhaRevalida(true);
		}
	}, [openTrilhaRevalida]);

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
			</Container>
			<Grid className={classes.backgroundArrows}>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					<Grid
						item
						xs={12}
						container
						alignContent="center"
						className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
						style={matchesTablet ? { marginTop: 80 } : null}
					>
						<Grid
							item
							xs={12}
							container
							justifyContent={matchesMobile ? 'center' : 'flex-start'}
							className={`${classes.gridTextContainer}`}
							wrap={'nowrap'}
							gap={matchesTablet ? 1 : 3}
						>
							<img
								src={require('../../../../Assets/Arena/logo-live-revalida.svg').default}
								alt=""
							/>
							<Typography
								className={`${classes.title} roboto-condensed`}
								color="secondary"
							>
								Qual a melhor forma de estudar e aprender medicina?
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							container
							justifyContent={'center'}
						>
							<Divider
								variant="middle"
								className={classes.divider}
							/>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						container
						className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
						direction={matchesTablet ? 'column' : 'row'}
					>
						<Grid
							item
							xs={12}
							md={8}
							container
							justifyContent="center"
						>
							<iframe
								// width="560"
								width="940"
								// height="315"
								height={matchesMobile ? '200' : '529'}
								src="https://www.youtube.com/embed/nYztaQSB-6U"
								title="YouTube video player"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowfullscreen
								style={{ borderRadius: '20px' }}
							></iframe>
						</Grid>
						<Grid
							item
							xs={12}
							md={4}
							gap={2}
							container
							justifyContent="center"
							className={`${classes.gridImage} revalida`}
						>
							<img
								src={process.env.PUBLIC_URL + '/Assets/TrilhaRevalida/newRevalidaHwmLogo.svg'}
								alt=""
							/>
							<Typography
								className={`${classes.text} poppins`}
								color="secondary"
							>
								A maior revolução na preparação para o Revalida INEP.
							</Typography>
							<DefaultButton
								variant="outlined"
								color={'primary'}
								className={`${classes.btn} white-btn-secondary poppins`}
								component={Link}
								to={'/trilha-revalida'}
								disabled={!openTrilhaRevalida}
							>
								{openTrilhaRevalida ? 'Saiba mais' : 'Em breve'}
							</DefaultButton>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						lg={8}
						container
						justifyContent={'center'}
						className={classes.gridForm}
						gap={1}
					>
						<Form
							group="LIVE LANÇAMENTO TRILHA REVALIDA 2024"
							title={['Inscreva-se para ', <br />, 'participar do sorteio']}
							titleButton="Confirmar Inscrição"
							type="revalida"
							urlToRedirect="/live/trilha-revalida"
							titleConfirmationMessage={'Inscrição confirmada!'}
							confirmationMessage={'Acompanhe a live para participar do sorteio.'}
						/>
					</Grid>
				</Container>
			</Grid>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px !important',
		margin: '5% auto !important',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.5rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
			lineHeight: 'unset !important',
			marginBottom: '8px !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.4rem !important',
			marginBottom: 'unset !important',
			lineHeight: 'unset !important',
			alignSelf: 'center',
		},
	},
	text: {
		fontSize: '1.324rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	gridTextContainer: {
		paddingBottom: '1em',
		[theme.breakpoints.down('lg')]: {
			paddingRight: '2em',
		},
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
		[theme.breakpoints.down('sm')]: {
			paddingRight: 0,
			paddingBottom: 0,
		},
	},
	textDateStyle: {
		borderTopLeftRadius: 20,
		borderBottomRightRadius: 20,
		width: '192px !important',
		[theme.breakpoints.down('sm')]: {
			width: '162px !important',
		},
	},
	divider: {
		minHeight: 1,
		backgroundColor: '#6D6D6D',
		width: '100%',
		margin: '1rem 0 !important',
	},
	btn: {
		width: '85%',
		fontSize: '1.425rem !important',
		fontWeight: 'bold !important',
		marginTop: '2rem !important',
		'&:hover': {
			scale: 1.05,
			color: '#ffffff',
			'& $logoBtn': {
				filter: 'invert(0%) !important' /* Linha para passar estilo do pai para o filho */,
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
			minHeight: 'unset !important',
			width: '80%',
		},
		[theme.breakpoints.down('md')]: {
			marginBottom: '2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
			width: '75%',
			marginTop: 'unset !important',
		},
	},
	backgroundArrows: {
		backgroundImage: `url(${require('../../../../Assets/Extensivo/Revalida/bg-new-arrows.png')})`,
		backgroundSize: 'cover',
		padding: '0 0 3rem',
		[theme.breakpoints.down('md')]: {
			paddingTop: '3rem',
		},
		[theme.breakpoints.down('sm')]: {
			paddingTop: 'unset',
			marginTop: '3rem',
		},
	},
	gridImage: {
		paddingLeft: '2rem',
		height: 'fit-content',
		marginTop: '2rem !important',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: 'unset',
		},
	},
	gridForm: {
		maxWidth: '567px !important',
		flexDirection: 'column !important',
		alignItems: 'center !important',
		margin: '0 auto !important',
		padding: '0 1rem !important',
		[theme.breakpoints.down('xl')]: {
			bottom: 20,
		},
		[theme.breakpoints.down(1300)]: {
			width: 467,
		},
		[theme.breakpoints.down('md')]: {
			position: 'unset',
			right: 'unset',
			top: 'unset',
			zIndex: 0,
			marginBottom: '1.5rem !important',
		},
	},
}));
