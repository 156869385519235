import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default function PreInscricaoArena() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
    const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<Grid
        container
        height={matchesMobile ? 'auto' : '60vh'}
        alignItems={'center'}
        justifyContent={ 'center'}
		>
			<Grid
				item
				xs={12}
				container
				rowGap={0}
                justifyContent={'center'}
                alignContent={'center'}
                alignSelf={'center'}
                marginLeft={matchesMobile ? 5 : 0}
                marginTop={matchesMobile ? 0 : 10}
                
			>
				<img
					src={require('../Arena/assets/img-live-arena.png')}
					alt=""
                    
				/>
			</Grid>

			<Grid
				item
				xs={12}
				container
				alignItems={'center'}
				//columnGap={matchesTablet ? 1 : 1.5}
				className="residencia"
				justifyContent={'center'}
				borderRadius={5}
				padding={0}      
			>
				
				{/* <Grid xs={12}>
					<Typography
						align="left"
						color="secondary"
						className={`${classes.text}`}
						fontFamily={'Manrope'}
						marginTop={3}
						whiteSpace={matchesTablet ? 'normal' : 'pre-wrap'}
					>{`Chegou a hora de dominarmos a nova prova prática do Revalida Inep.`}</Typography>
				</Grid>
				<Grid xs={12}>
					<Typography
						align="left"
						color="secondary"
						className={`${classes.text}`}
						fontFamily={'Manrope'}
						marginTop={5}
					>
						• Treinamento Intensivo em 2 dias
					</Typography>
				</Grid>
				<Grid xs={12}>
					<Typography
						align="left"
						color="secondary"
						className={`${classes.text}`}
						fontFamily={'Manrope'}
						marginTop={3}
						whiteSpace={matchesTablet ? 'normal' : 'pre-wrap'}
					>
						{`• 20 estações individuais no novo formato INEP `}
					</Typography>
				</Grid>
                <Grid xs={12}>
					<Typography
						align="left"
						color="secondary"
						className={`${classes.text}`}
						fontFamily={'Manrope'}
						marginTop={3}
						whiteSpace={matchesTablet ? 'normal' : 'pre-wrap'}
					>
						{`• Orientações personalizadas com instrutores Hardwork `}
					</Typography>
				</Grid>
                <Grid xs={12}>
					<Typography
						align="left"
						color="secondary"
						className={`${classes.text}`}
						fontFamily={'Manrope'}
						marginTop={5}
						whiteSpace={matchesTablet ? 'normal' : 'pre-wrap'}
					>
						{`Conheça todos os benefícios e treinamentos importantes para a prova.`}
					</Typography>
				</Grid> */}
                <Grid
					item
					style={{
						backgroundColor: 'transparent',
						width: 'fit-content',
						borderRadius: 16,
						padding: '0rem',
						margin: 0,
					}}
				>
					<Typography
						align="right"
						color="secondary"
						className={`${classes.impactText}`}
						fontFamily={'Manrope'}
                        marginTop={0}
					>
						QUINTA • 26SET • 11H
					</Typography>
				</Grid>           
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: '800 !important',
		fontSize: '40px !important',
		fontFamily: 'Manrope !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '32px !important',
		},
	},
	subtitle: {
		fontSize: '1.25rem !important',
		lineHeight: '1.25 !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	text: {
		fontWeight: '300 !important',
		fontSize: '20px !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '16px !important',
		},
	},
	impactText: {
		fontSize: '32px !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '28px !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '24px !important',
		},
	},
}));
