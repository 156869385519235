import React from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import { Header } from '../../../../Components/Header';
import Form from '../../../../Components/Utils/Forms/PreRegistration';

export default function LiveSubscriptionTrilhaRevalida2024() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
			</Container>
			<Grid
				container
				style={{
					backgroundImage: `url(${require('../../../../Assets/Extensivo/Revalida/bg-new-arrows.png')})`,
					backgroundSize: 'cover',
				}}
			>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					<Grid
						container
						justifyContent={'space-between'}
						alignContent="center"
						className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
						style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
					>
						{matchesTablet ? null : (
							<img
								src={require('../../FreeContent/HardworkEm/Assets/bg-fabricio.png')}
								alt=""
								className={classes.fabricioImg}
							/>
						)}
						<Grid
							item
							xs={12}
							md={7}
							container
							justifyContent={'flex-start'}
							className={`${classes.gridTextContainer} revalida`}
							gap={3}
							style={{ height: 'fit-content' }}
						>
							{matchesMobile && (
								<Grid
									style={{
										position: 'absolute',
										width: '100%',
										height: '100%',
										overflow: 'hidden',
									}}
								>
									<img
										src={require('../../FreeContent/HardworkEm/Assets/bg-fabricio.png')}
										alt=""
										className={classes.fabricioImg2}
									/>
								</Grid>
							)}
							<Grid
								item
								container
								wrap={matchesTablet ? 'wrap' : 'nowrap'}
								gap={2}
							>
								<img
									src={require('../../../../Assets/Arena/logo-live-revalida.svg').default}
									alt=""
								/>
								<Typography
									className={`${classes.title} gradient-text roboto-condensed`}
									color="secondary"
								>
									Qual a melhor forma de&nbsp;
									{!matchesTablet && <br />}
									estudar e aprender medicina?
								</Typography>
							</Grid>
							<Typography
								className={`${classes.text} poppins`}
								color="secondary"
								style={{ marginBottom: matchesMobile ? null : '1rem' }}
							>
								{matchesLg ? (
									<>
										Vamos conversar sobre aquilo que ninguém nos conta: afinal de contas,{' '}
										<strong>o que é preciso fazer para aprender medicina?</strong>
										<br />
										<br />
										De quebra iremos lançar a maior revolução na preparação para a prova do
										Revalida: <strong>TRILHA REVALIDA.</strong>&nbsp; E ainda teremos sorteios e
										condições especiais de inscrições para quem estiver na Live!
										<br />
										<br />
										Para participar da Live, basta se inscrever gratuitamente.
									</>
								) : (
									<>
										Vamos conversar sobre aquilo que ninguém nos conta:
										<br />
										afinal de contas, <strong>o que é preciso fazer para aprender medicina?</strong>
										&nbsp;
										<br />
										<br />
										De quebra iremos lançar a maior revolução na preparação
										<br />
										para a prova do Revalida: <strong>TRILHA REVALIDA.</strong>&nbsp;
										<br />
										<br />E ainda teremos sorteios e condições especiais de inscrições
										<br />
										para quem estiver na Live!
										<br />
										<br />
										Para participar da Live, basta se inscrever gratuitamente.
									</>
								)}
							</Typography>
							<Typography
								align="left"
								color="secondary"
								className={`${classes.impactText} poppins`}
							>
								TERÇA-FEIRA
								<span className="bullet"> &#8226; </span>
								13JUN
								<span className="bullet"> &#8226; </span>
								20H
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							md={5}
							container
							justifyContent="center"
							className={classes.gridWithBackgroundImage}
							gap={matchesTablet ? 4 : 0}
						>
							{matchesMobile ? null : (
								<img
									src={process.env.PUBLIC_URL + '/Assets/TrilhaRevalida/newRevalidaHwmLogo.svg'}
									alt="Logo Trilha Revalida"
									className={classes.logo}
								/>
							)}
							<Grid
								item
								xs={12}
								className={classes.gridForm}
								gap={1}
							>
								<Form
									group="LIVE LANÇAMENTO TRILHA REVALIDA 2024"
									title={['Inscreva-se para ', <br />, 'participar da live']}
									titleButton="Confirmar Inscrição"
									type="revalida"
									urlToRedirect="/live/trilha-revalida"
									titleConfirmationMessage={'Inscrição confirmada!'}
									confirmationMessage={
										'Tu serás avisado pelo email cadastrado momentos antes da live.'
									}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</Grid>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesTablet ? null : <Footer noMarginBottom />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto 0',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.2rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
			marginBottom: '8px !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.18rem !important',
			marginBottom: 'unset !important',
		},
	},
	subtitle: {
		fontWeight: 'bold !important',
		fontSize: '2.5rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
			marginBottom: '8px !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.18rem !important',
			marginBottom: '8px !important',
		},
	},
	text: {
		fontSize: '1.3125rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.95rem !important',
		},
	},
	subtext: {
		fontSize: '1.35rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	gridForm: {
		width: 567,
		flexDirection: 'column !important',
		alignItems: 'center !important',
		[theme.breakpoints.down('xl')]: {
			bottom: 20,
		},
		[theme.breakpoints.down(1300)]: {
			width: 467,
		},
		[theme.breakpoints.down('md')]: {
			position: 'unset',
			right: 'unset',
			top: 'unset',
			zIndex: 0,
			marginBottom: '1.5rem !important',
		},
	},
	gridTextContainer: {
		[theme.breakpoints.down('lg')]: {
			paddingRight: '2em',
		},
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
	},
	gridWithBackgroundImage: {
		padding: '1rem 2rem 2.5rem',
		marginBottom: '2rem !important',
		[theme.breakpoints.down('lg')]: {
			padding: 'unset',
		},
		[theme.breakpoints.down('md')]: {
			backgroundImage: 'unset',
			marginBottom: 'unset !important',
		},
	},
	impactText: {
		margin: '0 0 2rem !important',
		fontSize: '2.75rem !important',
		lineHeight: 'unset !important',
		background: 'linear-gradient(90deg, rgba(0,145,166,1) 10%, rgba(0,194,110,1) 100%)',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.75rem !important',
		},
		[theme.breakpoints.down('md')]: {
			marginBottom: '2.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.75rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1.5rem !important',
		},
	},
	logo: {
		marginBottom: '1.5rem',
		[theme.breakpoints.down('xl')]: {
			marginBottom: '2.5rem',
		},
		[theme.breakpoints.down('md')]: {
			marginBottom: '1rem',
		},
	},
	timelineText: {
		fontSize: '1.5rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.2rem !important',
		},
	},
	divider: {
		position: 'absolute',
		border: '1.5px solid #fff !important',
		left: '4.65em',
		backgroundColor: '#fff',
		height: '230px !important',
		zIndex: -1,
		[theme.breakpoints.down('xl')]: {
			height: '200px !important',
		},
		[theme.breakpoints.down('lg')]: {
			height: '280px !important',
		},
		[theme.breakpoints.down('md')]: {
			height: '265px !important',
		},
		[theme.breakpoints.down('sm')]: {
			height: '180px !important',
		},
		[theme.breakpoints.down(376)]: {
			height: '220px !important',
		},
	},
	imgTime: {
		height: 30,
		top: 5,
	},
	fabricioImg: {
		position: 'absolute',
		left: '30%',
		top: '9%',
		width: '36%',
		opacity: '50%',
	},
	fabricioImg2: {
		left: '28%',
		top: '9%',
		width: '36%',
		opacity: '50%',
		[theme.breakpoints.down('md')]: {
			width: 'unset',
			left: '42%',
			top: '11%',
			opacity: '35%',
		},
		[theme.breakpoints.down('sm')]: {
			left: '22%',
			top: '0%',
		},
	},
}));
