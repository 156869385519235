import React, { useRef } from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

// COMPONENTS
import Footer from '../../Components/Footer';
import { Header } from '../../Components/Header';
import StudentsFeedbackSection from '../../Components/Sections/StudentsFeedback';
import DiferencialBox from '../../Components/Cards/DiferencialBox';

// TO IMPLEMENT
// import FaqSection from '../../Components/Sections/FaqSection';
// import Newsletter from '../../Components/Utils/Newsletter';

// ASSETS
import ExtensivoHwmLogo from '../../Assets/Extensivo/R1/2023/extensivoHwmLogo.svg';
import SprintHwmLogoSection from '../../Assets/SprintFinal/sprintHwmLogoSection.svg';
import SprintFinalMobile from '../../Assets/SprintFinal/sprintFinalMobile.svg';
import ExtensivoLogo from '../../Assets/Extensivo/R1/2023/extensivoLogo.svg';

// SF PROVAS
import AmrigsLogo from '../../Assets/SprintFinal/amrigsLogo.svg';
import EinsteinLogo from '../../Assets/SprintFinal/einsteinLogo.svg';
import EnareLogo from '../../Assets/SprintFinal/enareLogo.svg';
import IamspeLogo from '../../Assets/SprintFinal/iamspeLogo.svg';
import SesgoLogo from '../../Assets/SprintFinal/sesgoLogo.svg';
import FacMedicinaJundiaiLogo from '../../Assets/SprintFinal/facMedicinaJundiaiLogo.svg';
import PsuMgLogo from '../../Assets/SprintFinal/psuMgLogo.svg';
import SantaCasaLogo from '../../Assets/SprintFinal/santaCasaSpLogo.svg';
import SesDfLogo from '../../Assets/SprintFinal/sesDfLogo.svg';
import SesPeLogo from '../../Assets/SprintFinal/sesPeLogo.svg';
import UfrjLogo from '../../Assets/SprintFinal/ufrjLogo.svg';
import AbcLogo from '../../Assets/SprintFinal/abcLogo.svg';
import SirioLibanesLogo from '../../Assets/SprintFinal/sirioLibanesLogo.svg';
import SurceLogo from '../../Assets/SprintFinal/surceLogo.svg';
import SusBaLogo from '../../Assets/SprintFinal/susBaLogo.svg';
import UnicampLogo from '../../Assets/SprintFinal/unicampLogo.svg';
import SusSpLogo from '../../Assets/SprintFinal/susSpLogo.svg';
import UnespBotucatuLogo from '../../Assets/SprintFinal/unespBotucatuLogo.svg';
import AmpLogo from '../../Assets/SprintFinal/ampLogo.svg';
import UnifespLogo from '../../Assets/SprintFinal/unifestpLogo.svg';
import UspLogo from '../../Assets/SprintFinal/uspLogo.svg';
import UspRibeiraoLogo from '../../Assets/SprintFinal/uspRibeiraoLogo.svg';
import FamemaLogo from '../../Assets/SprintFinal/famemaLogo.svg';
import BagIcon from '../../Assets/Svg/Icons/bagIcon.svg';

// TO IMPLEMENT
// import DoctorCard from '../../Components/Cards/DoctorCard';

import TestCard from '../../Components/Cards/TestCard';
import FirstSection from '../../Components/Sections/FirstSection';
import LearningPath from '../../Components/Sections/LearningPath';
import PriceCardVersion2 from '../../Components/Cards/PriceCardVersion2';

const exams = [
	{
		imageUrl: AmrigsLogo,
	},
	{
		imageUrl: EinsteinLogo,
	},
	{
		imageUrl: EnareLogo,
	},
	{
		imageUrl: IamspeLogo,
	},
	{
		imageUrl: SesgoLogo,
	},
	{
		imageUrl: FacMedicinaJundiaiLogo,
	},
	{
		imageUrl: PsuMgLogo,
	},
	{
		imageUrl: SantaCasaLogo,
	},
	{
		imageUrl: SesDfLogo,
	},
	{
		imageUrl: SesPeLogo,
	},
	{
		imageUrl: UfrjLogo,
	},
	{
		imageUrl: AbcLogo,
	},
	{
		imageUrl: SirioLibanesLogo,
	},
	{
		imageUrl: SurceLogo,
	},
	{
		imageUrl: SusBaLogo,
	},
	{
		imageUrl: UnicampLogo,
	},
	{
		imageUrl: SusSpLogo,
	},
	{
		imageUrl: UnespBotucatuLogo,
	},
	{
		imageUrl: AmpLogo,
	},
	{
		imageUrl: UnifespLogo,
	},
	{
		imageUrl: UspLogo,
	},
	{
		imageUrl: UspRibeiraoLogo,
	},
	{
		imageUrl: FamemaLogo,
	},
];

const learningPathTemplate = [
	{
		title: 'Primeira liga',
		description: [
			'A trilha do aprendizado funciona por ligas de evolução. Começaremos por temas mais palpáveis, evoluindo para outros mais peculiares.',
			<>
				<br />
				<br />
			</>,
			'São 8 ligas no total, cada uma com temas diferentes. Para passar para a próxima, precisaremos dominar por completo a atual.',
			<>
				<br />
				<br />
			</>,
			'Veja como tudo vai funcionar em cada liga:',
		],
		bgColor: '#ffffff',
		titleColor: '#121212',
		descriptionColor: 'primary',
		url: 'https://player.vimeo.com/video/769930065',
	},
	{
		title: 'Aula Hardwork',
		description: [
			'Tudo começa com a apresentação ao tema, que no Hardwork é feito com rigor em didática.',
			<>
				<br />
				<br />
			</>,
			'São as melhores aulas que você já viu sobre cada tema.',
		],
		bgColor: '#0D0D0D',
		titleColor: '#FF005D',
		descriptionColor: 'secondary',
		url: 'https://player.vimeo.com/video/769929590',
	},
	{
		title: 'Estudo por questões',
		description: [
			'Alguns dias após a apresentação ao tema, vamos te conduzir pelo estudo por questões.',
			<>
				<br />
				<br />
			</>,
			'Aqui você entenderá todas as formas em que cada parte do tema é explorado nas provas.',
			<>
				<br />
				<br />
			</>,
			'O professor Hardwork te mostrará tudo em detalhes, aprofundando em cada tema.',
		],
		bgColor: '#0D0D0D',
		titleColor: '#FF005D',
		descriptionColor: 'secondary',
		url: 'https://player.vimeo.com/video/769929662',
	},
	{
		title: 'Revisões cíclicas e personalizadas',
		description: [
			'Questões, questões e questões! É assim que revisamos no Hardwork!',
			<>
				<br />
				<br />
			</>,
			'Toda a dinâmica de revisões é, na verdade, uma continuidade do estudo por questões, explorando com mais ênfase os pontos que você tiver mais dificuldade.',
			<>
				<br />
				<br />
			</>,
			'Todos os temas precisam ser dominados!',
		],
		bgColor: '#0D0D0D',
		titleColor: '#FF005D',
		descriptionColor: 'secondary',
		url: 'https://player.vimeo.com/video/769929881',
	},
	{
		title: 'Avaliação de desempenho',
		description: [
			'A regra é dominar temas! A avaliação de desempenho e domínio dos temas será diária, usando métricas do estudo por questões.',
			<>
				<br />
				<br />
			</>,
			'Assim que o grau de suficiência em um tema for atingido, ele estará dominado! Domine todos e migre para a próxima liga de aprendizado.',
			<>
				<br />
				<br />
			</>,
			'"E se eu tiver dificuldade"? Calma! Siga a trilha...',
		],
		bgColor: '#0D0D0D',
		titleColor: '#FF005D',
		descriptionColor: 'secondary',
		url: 'https://player.vimeo.com/video/769929824',
	},
	{
		title: 'Estude com professor',
		description: [
			'Teve dificuldade? O professor estudará contigo!',
			<>
				<br />
				<br />
			</>,
			'Isso mesmo... vamos abrir o livro juntos, entender frase por frase, parágrafo por parágrafo, o tema ou seu fragmento!',
			<br />,
			'Vamos fazer isso usando a principal referência disponível, que estará à sua disposição na biblioteca virtual do Extensivo.',
			<>
				<br />
				<br />
			</>,
			'Após o estudo em conjunto, nova avaliação de desempenho!',
		],
		bgColor: '#0D0D0D',
		titleColor: '#FF005D',
		descriptionColor: 'secondary',
		url: 'https://player.vimeo.com/video/769929949',
	},
	{
		title: 'Próxima liga',
		description: [
			'A evolução é contínua, são 8 ligas no total.',
			<>
				<br />
				<br />
			</>,
			'Na liga do aprendizado seguinte teremos novos temas, ou fragmentos complementares de temas anteriores.',
			<>
				<br />
				<br />
			</>,
			'Mas não pense que os já vistos serão esquecidos... jamais!',
			<>
				<br />
				<br />
			</>,
			'As revisões serão contínuas, até o fim!',
		],
		bgColor: '#ffffff',
		titleColor: '#121212',
		descriptionColor: 'primary',
		url: 'https://player.vimeo.com/video/769929780',
	},
];

export default function ExtensivoR12023() {
	const classes = useStyles();
	const theme = useTheme();
	const subscribe_card_extensivo_r1 = useRef(null);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const executeScroll = () =>
		subscribe_card_extensivo_r1.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className="residencia"
			>
				{matchesMobile || matchesTablet ? null : <Header />}

				<FirstSection
					backgroundImageUrl={process.env.PUBLIC_URL + '/Assets/Img/sprintStudy.png'}
					logoUrl={ExtensivoHwmLogo}
					type="red"
					// buttonText="Garanta sua vaga!"
					executeScroll={executeScroll}
					firstTextPaper={[
						<Typography
							align="left"
							color="secondary"
							className={`${classes.firstText}`}
						>
							A maior revolução da história
							<br /> da preparação para
						</Typography>,
						<Typography
							variant="h1"
							className={`${classes.gradientText} residencia gradient-text roboto-condensed`}
						>
							Provas de Residência.
						</Typography>,
						<Typography
							align="left"
							color="secondary"
							className={`${classes.text} poppins`}
						>
							Caminharemos juntos na{' '}
							<strong>
								trilha do <br />
								aprendizado
							</strong>
							&nbsp;definitivo para dominar
							<br />
							qualquer prova de residência do Brasil
						</Typography>,
					]}
				/>
			</Container>

			{/* Trilha do aprendizado */}
			<LearningPath
				title={'A Revolução: A Trilha do aprendizado definitivo'}
				cardsInfo={learningPathTemplate}
				background={'background-gradient-residencia'}
			/>

			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className="section"
				>
					<Grid
						item
						xs={12}
						container
						style={matchesMobile ? { marginBottom: 16 } : null}
					>
						<Typography
							className={`${classes.subtitle1} roboto-condensed`}
							color="secondary"
						>
							Além da trilha do aprendizado você também ganha
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
					>
						<DiferencialBox
							// key={0}
							title="Banco de lives Hardwork"
							description="Acesso imediato ao banco de lives do Hardwork Medicina, com discussões de mais de 200 dos  principais temas voltados para provas de residência médica."
							details="Acesso ao banco de lives com temas das diversas áreas, numa conversa informal e olho-no-olho, no padrão consagrado do Hardwork Medicina."
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
					>
						<DiferencialBox
							// key={1}
							title="Acesso aos principais livros"
							description={[
								'Apostila? De jeito nenhum!',
								<>
									<br />
								</>,
								'Tu vais estudar junto com o professor pelas grandes obras!',
							]}
							details="Sim! No extensivo tu terás acesso a milhares de obras consagradas...Vamos estudar pelas principais referências. Tudo JUNTO com o professor! Vamos evoluir frase por frase dentro dos problemas explorados pelas questões. Nada de se restringir a textos resumidos! Vamos dominar as principais fontes! Vamos te mostrar como."
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
					>
						<DiferencialBox
							// key={2}
							title="Fala professor!"
							description={[
								'A base do aprendizado é a relação mestre-aprendiz!',
								<>
									<br />
								</>,
								'No Hardwork o seu contato com o professor será direto!',
							]}
							details="Não existe escola em que o aluno não fale com o professor! Aqui o contato é direto, todos os alunos têm acesso aos professores, tanto nos times, quanto via whatsapp."
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
					>
						<DiferencialBox
							// key={3}
							title="Banco de questões"
							description="Mais de 100 mil questões de provas dos principais concursos de residência do Brasil."
						/>
					</Grid>
				</Grid>
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className="section"
				>
					<Grid
						item
						xs={12}
						container
					>
						<Grid
							item
							xs={12}
							container
						>
							<Typography
								className={`${classes.subtitle1} roboto-condensed`}
								color="secondary"
							>
								E na hora da revisão, receba todos os Sprints
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
						>
							<DiferencialBox
								// key={4}
								title="Simulados com comentários em vídeo"
								description="Simulados mensais a partir de abril, com análise de desempenho e comentários em vídeo."
								details="A partir de abril de 2023 teremos simulados mensais, com questões de provas antigas, com objetivo único de identificar lacunas na preparação e permitir ajustes no estudo enquanto há tempo. Nada de ranking! Aqui fazemos análise de desempenho questão por questão...além disso, todos os simulados trazem consigo questões comentadas em texto e em vídeos pelos professores. Ah, mas se tu quiseres, podes configurar um simulado para fazer a qualquer momento!"
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
						>
							<DiferencialBox
								// key={5}
								title="Desempenho por concurso"
								description="Cada prova tem sua alma."
								details="Vamos te mostrar toda a tua evolução de acordo com as provas que farás.. Afinal, cada prova tem seus temas de predileção, sua forma de abordar cada assunto."
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
						>
							<DiferencialBox
								// key={6}
								title="Grupos de discussão"
								description="Grupos com professores, mentores e alunos Hardwork, para discussão de tudo relacionado às provas!
Seguiremos juntos, sempre!"
								details="Tu farás parte de um time com um grupo de hardworkers, capitaneado por um tutor HWM... o objetivo? Aprender com os pares! Troca de informações, de opiniões, auxílio na orientação dos estudos, na resolução daquelas questões aparentemente espinhosas...Ah, no grupo também estão todos os professores do HWM."
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
						>
							<DiferencialBox
								// key={7}
								title="Resumos Hardwork"
								description="Resumos dos principais temas explorados nas provas de residência médica."
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className="section"
				>
					<Grid
						item
						xs={12}
						container
						className="residencia"
						style={matchesMobile ? { paddingBottom: '1em' } : { paddingBottom: '2em' }}
					>
						<Typography
							className={`${classes.subtitle1} roboto-condensed`}
							color="secondary"
						>
							E no segundo semestre de 2023
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						container
					>
						<Grid
							item
							xs={4}
							container
							alignContent={matchesMobile ? 'center' : null}
						>
							{matchesMobile ? (
								<img
									src={SprintFinalMobile}
									alt=""
								/>
							) : (
								<img
									src={SprintHwmLogoSection}
									alt=""
								/>
							)}
						</Grid>
						<Grid
							item
							xs={8}
							container
							alignContent="center"
							style={{ paddingLeft: '2em' }}
						>
							<Typography
								color="secondary"
								className={`${classes.thirdSectionText} poppins`}
							>
								Acesso garantido a todos os <strong>Sprints Finais 2023*</strong> para provas de
								residência: a única revisão realmente focada em provas
							</Typography>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						container
						alignContent="center"
						justifyContent="flex-start"
						style={{ margin: '40px 0px 20px' }}
					>
						{exams.map((item, index) => (
							<Grid
								item
								xs={4}
								md={3}
								lg={2}
								key={index}
								container
								justifyContent="center"
								style={{ margin: '8px 0' }}
							>
								<TestCard imageUrl={item.imageUrl} />
							</Grid>
						))}
					</Grid>
					<Grid
						item
						xs={12}
						container
						alignContent="center"
						justifyContent="flex-start"
					>
						<Typography
							color="secondary"
							className={`${classes.thirdSectionText} poppins`}
						>
							*Sprints previstos para 2023. Listagem sujeita a alterações para mais ou para menos.
						</Typography>
					</Grid>
				</Grid>
			</Container>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className="section"
				>
					<Grid
						item
						xs={12}
						container
						className="residencia"
						style={{ padding: '20px 0' }}
					>
						<Typography
							className={`${classes.subtitle1} roboto-condensed`}
							color="secondary"
						>
							E tem mais: um presente muito especial
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						container
					>
						<Grid
							item
							xs={10}
							md={4}
						>
							<img
								src={process.env.PUBLIC_URL + '/Assets/Img/alexaInTheHands.png'}
								alt=""
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={8}
							container
							alignContent="center"
							style={
								matchesMobile
									? { padding: '1em 0' }
									: matchesTablet
									? { padding: '1em 0' }
									: { padding: '0 0 1.25em 2em' }
							}
						>
							<Grid
								item
								xs={12}
								className="residencia"
							>
								<Typography
									className={`${classes.flashCardsTitle} residencia gradient-text`}
									color="secondary"
								>
									Flashcards Hardwork
								</Typography>
							</Grid>
							<Grid
								item
								xs={12}
							>
								<Typography
									color="secondary"
									className={`${classes.thirdSectionText} poppins`}
								>
									Hora de revisar "brincando" com a skill Residência Médica do Hardwork Medicina na
									Alexa!
									<br />
									Você escolhe o nome da prova do Sprint Final e o jogo de revisões começa!
									<br />
									Você receberá em casa, gratuitamente, um dispositivo <strong>
										Echo dot 4
									</strong>{' '}
									para usar como quiser! Basta pedir: "Alexa, abra Residência Médica" e ver a mágica
									acontecer...
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className="section"
					ref={subscribe_card_extensivo_r1}
				>
					<PriceCardVersion2
						idTurma={'1157'}
						// imageBackground={'../../Assets/Extensivo/R3CM/cardBackground.png'}
						logo={ExtensivoLogo}
						iconBtn={BagIcon}
						// parcel={'21'}
						// valuePerParcel={'479,49'}
					/>
				</Grid>
			</Container>
			<StudentsFeedbackSection type="extensivo" />
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{/* <Grid
					container
					alignContent="center"
					justifyContent="center"
					className={classes.section}
				>
					<Grid
						item
						xs={12}
						style={{ paddingTop: '2em' }}
						container
						spacing={3}
					>
						{data.doctors.map((item, index) => (
							<Grid
								item
								xs={3}
								key={index}
							>
								<DoctorCard
									name={item.name}
									specialty={item.specialty}
									type="residencia"
									insta={item.insta}
								/>
							</Grid>
						))}
					</Grid>
				</Grid> */}
				{/* TO DO DEPOIS
				<FaqSection /> */}
				{/* <StudentsFeedbackSection type="residencia" /> */}
				{/* <Newsletter type="residencia" /> */}
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.5em !important',
		lineHeight: '1 !important',
	},
	gradientText: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		lineHeight: '1.5 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '2.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.8rem !important',
		},
	},
	firstText: {
		fontSize: '3rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
		},
	},
	text: {
		fontSize: '1.75rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.35rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.15rem !important',
		},
	},
	subtitle1: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('md')]: {
			lineHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
			lineHeight: 'unset !important',
		},
	},
	flashCardsTitle: {
		fontSize: '1.8em !important',
		fontWeight: 'bold !important',
	},
	thirdSectionText: {
		fontSize: '1.12rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
}));
