import React, { useState } from 'react';
import { Checkbox, Grid, Paper, TextField, Typography } from '@mui/material';
import DefaultButton from '../../Buttons/DefaultButton';
import { makeStyles } from '@mui/styles';
import { Link, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

export default function FormV2({ formConfig }) {
	const classes = useStyles();
	const [searchParams] = useSearchParams();
	const [setTestDateSelected] = useState('');
	const [preInscricao, setPreInscricao] = useState({
		agrupamento: formConfig.group,
		nome: '',
		email: '',
		telefone: '',
		rg: '',
		provas: '',
		provas_outras: '',
		optin: false,
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
	});

	useEffect(() => {
		setPreInscricao({
			...preInscricao,
			utm_source: searchParams.get('utm_source'),
			utm_medium: searchParams.get('utm_medium'),
			utm_campaign: searchParams.get('utm_campaign'),
		});
	}, []);

	let sendPreInscricao = function () {
		if (!preInscricao.nome) {
			alert('Ops... Por favor informe seu nome');
			return;
		}
		if (!preInscricao.email || !preInscricao.email.includes('@') || !preInscricao.email.includes('.')) {
			alert('Ops... Por favor preencha seu email');
			return;
		}
		if (
			formConfig.fields.find((field) => field.name === 'telefone') ||
			!preInscricao.telefone ||
			preInscricao.telefone.length < 11
		) {
			alert('Ops... Por favor preencha seu telefone');
			return;
		}
		// if (rgField && !preInscricao.rg) {
		// 	alert('Ops... Por favor informe seu RG');
		// 	return;
		// }
		// if (cpfField && !preInscricao.cpf) {
		// 	alert('Ops... Por favor informe seu CPF');
		// 	return;
		// }
		// if (testDateField && !preInscricao.provas) {
		// 	alert('Ops... Por favor informe a data que vai fazer a prova');
		// 	return;
		// }
		if (!preInscricao.optin) {
			alert('Ops... Você precisa ler e aceitar os termos de uso acima para se pré-inscrever');
			return;
		}
		console.log(preInscricao);

		// trackPromise(
		// 	Api.post('PreInscricao', preInscricao).then((res) => {
		// 		if (res) {
		// 			Swal.fire({
		// 				title: 'Massa! ',
		// 				text: `${
		// 					/*
		// 					confirmationMessage
		// 						? confirmationMessage
		// 						: */ 'Sua pré-inscrição está confirmada! Avisaremos assim que a nova turma estiver liberada.'
		// 				}`,
		// 				icon: 'success',
		// 				confirmButtonText: 'Fechar',
		// 				allowOutsideClick: false,
		// 				allowEscapeKey: false,
		// 			}).then((result) => {
		// 				if (result.isConfirmed) {
		// 					/*
		// 					if (urlToRedirect) {
		// 						document.location.href = `${urlToRedirect}`;
		// 					} else {*/
		// 					document.location.href = '/';
		// 					// }
		// 				}
		// 			});
		// 		}
		// 	})
		// );
	};

	const handlePhone = (event) => {
		let input = event.target;
		input.value = phoneMask(input.value);
		setPreInscricao({
			...preInscricao,
			telefone: input.value,
		});
	};

	const phoneMask = (value) => {
		if (!value) return '';
		value = value.replace(/\D/g, '');
		value = value.replace(/(\d{2})(\d)/, '($1) $2');
		value = value.replace(/(\d)(\d{4})$/, '$1-$2');
		return value;
	};

	const handleChangeTestYear = (event) => {
		var year = event.target.value;
		if (year === null) {
			setTestDateSelected(null);
			return;
		}
		setTestDateSelected(year);
		setPreInscricao({
			...preInscricao,
			provas: year,
		});
	};

	return (
		<Paper
			elevation={4}
			className={`${classes.paper} rounded-card background-${formConfig.type}`}
		>
			<Grid container>
				<form
					className="form-control"
					action="javascript:;"
				>
					<Grid
						item
						xs={12}
						container
						rowGap={1}
					>
						<Grid
							item
							container
							justifyContent="center"
						>
							<Typography
								className={`${classes.signInCardTitle} poppins`}
								color="secondary"
								align="center"
							>
								{formConfig.title}
							</Typography>
						</Grid>
						{formConfig.fields.map((field, index) => {
							return (
								<Grid
									item
									xs={12}
								>
									<TextField
										fullWidth
										type={field.type}
										name={field.name}
										placeholder={field.placeholder}
										required={field.isRequired}
										InputProps={{
											className: `${classes.form} ${classes.formHeight}`,
										}}
										InputLabelProps={{
											shrink: true,
										}}
										// onChange={(e) => {
										// 	field.name === 'nome' ?
										// 		setPreInscricao({
										// 			...preInscricao,
										// 			nome: e.target.value,
										// 		})
										//     : field.name === 'email' ?
										// 		setPreInscricao({
										// 			...preInscricao,
										// 			email: e.target.value,
										// 		})
										//     : field.name === 'telefone' ?
										// 		setPreInscricao({
										// 			...preInscricao,
										// 			telefone: e.target.value,
										// 		})
										//     : field.name === 'rg' ?
										// 		setPreInscricao({
										// 			...preInscricao,
										// 			rg: e.target.value,
										// 		})
										// }}
										onChange={(e) => {
											switch (field.name) {
												case 'nome':
													setPreInscricao({ ...preInscricao, nome: e.target.value });
													break;
												case 'email':
													setPreInscricao({ ...preInscricao, email: e.target.value });
													break;
												case 'telefone':
													setPreInscricao({ ...preInscricao, telefone: e.target.value });
													break;
												case 'rg':
													setPreInscricao({ ...preInscricao, rg: e.target.value });
													break;
												default:
													// Se o nome do campo não corresponder a nenhuma das opções anteriores, não faça nada.
													break;
											}
										}}
									/>
								</Grid>
							);
						})}
						<Grid
							item
							xs={12}
						>
							<Checkbox
								style={{ padding: '0px' }}
								checked={preInscricao.optin}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										optin: !preInscricao.optin,
									})
								}
							/>
							<Typography
								variant="caption"
								color="primary"
								className={classes.captionText}
							>
								Estou de acordo com o tratamento dos meus dados pessoais para fins de inscrição na
								plataforma Hardwork Medicina, bem como para envio de comunicações relativas aos serviços
								educacionais que se adequem ao meu perfil, de acordo com a nossa{' '}
								<Link
									color="primary"
									href="https://hardworkmedicina.com.br/politica-de-privacidade"
									target="_blank"
									underline="hover"
									variant="caption"
									align="right"
									className={`${classes.copyrightText} poppins`}
								>
									Politica de privacidade
								</Link>
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							container
							justifyContent="center"
							className="residencia"
						>
							<DefaultButton
								variant="outlined"
								color="secondary"
								className={`${classes.btn} btn`}
								onClick={() => sendPreInscricao()}
							>
								{formConfig.submitButtonText}
							</DefaultButton>
						</Grid>
					</Grid>
				</form>
			</Grid>
		</Paper>
	);
}

const useStyles = makeStyles((theme) => ({
	paper: {
		margin: '0 auto',
		padding: '1.5rem',
		[theme.breakpoints.up('lg')]: {
			// height: 449,
		},
		[theme.breakpoints.down('sm')]: {
			padding: '1rem',
		},
	},
	form: {
		borderRadius: '8px !important',
		backgroundColor: theme.palette.secondary.main,
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'transparent !important',
		},
	},
	formHeight: {
		maxHeight: '3em',
	},
	formHeightShort: {
		maxHeight: '2.5em',
	},
	signInCardTitle: {
		fontSize: '1.75rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.5rem !important',
		},
	},
	btn: {
		fontSize: '1.15rem !important',
		width: '75%',
		fontWeight: 'bold !important',
		textTransform: 'uppercase !important',
		backgroundColor: '#000000 !important',
		border: '1px solid transparent !important',
		'&:hover': {
			border: '1px solid #ffffff !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	copyrightText: {
		color: theme.palette.primary.main,
	},
	captionText: {
		[theme.breakpoints.down('xl')]: {
			fontSize: '0.65rem !important',
		},
	},
}));
