import React, { useEffect, useRef, useState } from 'react';
import { Container, Grid, Modal, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Helmet } from 'react-helmet';
import { Header } from '../../../../Components/Header';
import Footer from '../../../../Components/Footer';
import BgTrilha from './Assets/bg-trilha-r1-25.png';
import LogoR1 from './Assets/logo-trilha-r1-2025.svg';
import FirstSectionFullScreen2024 from '../../../../Components/Sections/FirstSectionFullScreen-2024';
import FeedbackContainerSection from './components/FeedbackContainerSection';
import LearningPathSectionCarrousel from './components/LearningPathSectionCarrousel';
import Faq from './components/Faq';
import PriceCard from './components/PriceCard';
import BoxTextAndImage from './components/BoxTextAndImage';
import BoxInfoOqueTemMais from './components/BoxInfoOqueTemMais';
import BoxInfo from './components/BoxInfo';
import MetricBoxInfo from './components/MetricBoxInfo';
import CounterDown from './components/CountDown';
import Api from '../../../../Services/Api';
import DefaultButton from '../../../../Components/Buttons/DefaultButton';
import IntlTelInput from 'react-intl-tel-input';

import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';

const learningPathTemplate = [
	{
		title: 'Aula Hardwork	',
		description: [
			'Tudo começa com a apresentação ao tema, que no Hardwork é feito com rigor em didática. ',
			<>
				<br />
				<br />
			</>,
			'São + de 250 aulas das melhores que você já viu sobre cada tema.',
		],
		bgColor: '#ffffff',
		titleColor: '#FCFCFC',
		descriptionColor: '#FCFCFC',
		url: 'https://player.vimeo.com/video/769930065',
	},
	{
		title: 'Estudo por questões',
		description: [
			'No estudo por questões o professor te conduzirá para que você entenda todas as formas em que cada tema é explorado nas provas.',
			<>
				<br />
				<br />
			</>,
			'No final, crie seu resumo de estudos e a Inteligência Artificial do Hardwork apontará as correções necessárias.',
		],
		bgColor: '#0D0D0D',
		titleColor: '#FCFCFC',
		descriptionColor: '#FCFCFC',
		url: 'https://player.vimeo.com/video/769929590',
	},
	{
		title: 'FREE RECALL',
		description: [
			'A melhor maneira de aprender é explicando o tema! Você será convidado a explicar o tema em diversos momentos, para que o conhecimento seja sedimentado e resulte no resumo de estudos mais completo que você já fez.',
		],
		bgColor: '#0D0D0D',
		titleColor: '#FCFCFC',
		descriptionColor: '#FCFCFC',
		url: 'https://player.vimeo.com/video/769929881',
	},
	{
		title: 'Revisões cíclicas e personalizadas',
		description: [
			'Questões, Flashcards e Free Recall combinados no algoritmo do Hardwork, que garante que os temas sejam vistos na quantidade exata que você precisa! É assim que revisamos no Hardwork!',
			<>
				<br />
				<br />
			</>,
			'Toda a dinâmica de revisões é, na verdade, uma continuidade do estudo por questões, explorando com mais ênfase os pontos que você tiver mais dificuldade.',
		],
		bgColor: '#0D0D0D',
		titleColor: '#FCFCFC',
		descriptionColor: '#FCFCFC',
		url: 'https://player.vimeo.com/video/769929824',
	},
];

const benefitsR1 = [
	{ title: 'Banco de aulas Hardwork;' },
	{ title: 'Banco de lives Hardwork;' },
	{ title: 'Banco de questões;' },
	{ title: 'Banco de Flashcards;' },
	{ title: [<strong>BÔNUS:</strong>, ' Acesso à Minha Biblioteca™', <br />] },
];

const eTemMaisDuranteOTrilha = [
	{ title: 'Acesso à trilha do aprendizado (Estudo por questões);' },
	{ title: 'Simulados online' },
	{ title: 'Hardwork Hands on online;' },
	{ title: 'Mentoria em grupo;' },
	{ title: 'Grupos de whatsapp com tutoria.' },
];

const eTemMaisDuranteOTrilha_AllSprints = [
	{ title: 'Acesso à trilha do aprendizado (Estudo por questões);' },
	{ title: 'Simulados online' },
	{ title: 'Hardwork Hands on online;' },
	{ title: 'Mentoria em grupo;' },
	{ title: 'Grupos de whatsapp com tutoria.' },
	{
		title: [
			'Acesso a ',
			<p
				className="gradient-text-trilha-r1-residencia"
				style={{ display: 'inline' }}
			>
				Sprint final com todas as provas
			</p>,
		],
	},
];

const eTemMaisDuranteOTrilha_SpExams = [
	{ title: 'Acesso à trilha do aprendizado (Estudo por questões);' },
	{ title: 'Simulados online' },
	{ title: 'Hardwork Hands on online;' },
	{ title: 'Mentoria em grupo;' },
	{ title: 'Grupos de whatsapp com tutoria.' },
	{
		title: [
			'Acesso a ',
			<p
				className="gradient-text-trilha-r1-residencia"
				style={{ display: 'inline' }}
			>
				Sprint final provas de SP
			</p>,
		],
	},
];

const eTemMaisDuranteOTrilha_OneSprint = [
	{ title: 'Acesso à trilha do aprendizado (Estudo por questões);' },
	{ title: 'Simulados online' },
	{ title: 'Hardwork Hands on online;' },
	{ title: 'Mentoria em grupo;' },
	{ title: 'Grupos de whatsapp com tutoria.' },
	{
		title: [
			'Acesso a ',
			<p
				className="gradient-text-trilha-r1-residencia"
				style={{ display: 'inline' }}
			>
				1 Sprint final
			</p>,
		],
	},
];

const infoTitlesR1 = [
	{
		title: 'Acesso ao Trilha R1 2025.',
	},
	{
		title: 'Treinamento Hands-On online.',
	},
	{
		title: 'Kit de Flashcards Hardwork.',
	},
	{
		title: 'Mentoria mensal em grupo.',
	},
];

const infoTitles_AllSprints = [
	{
		title: 'Acesso ao Trilha R1 2025.',
	},
	{
		title: 'Treinamento Hands-On online.',
	},
	{
		title: 'Kit de Flashcards Hardwork.',
	},
	{
		title: 'Mentoria mensal em grupo.',
	},
	{
		title: [
			'Acesso a revisão focada ',
			<p className="gradient-text-trilha-r1-residencia">Hardwork - Sprint Final todas as provas</p>,
		],
	},
];

const infoTitles_SpExams = [
	{
		title: 'Acesso ao Trilha R1 2025.',
	},
	{
		title: 'Treinamento Hands-On online.',
	},
	{
		title: 'Kit de Flashcards Hardwork.',
	},
	{
		title: 'Mentoria mensal em grupo.',
	},
	{
		title: [
			'Acesso a revisão focada ',
			<p className="gradient-text-trilha-r1-residencia">Hardwork - Sprint Final foco em provas de São Paulo</p>,
		],
	},
];

const infoTitles_OneSprint = [
	{
		title: 'Acesso ao Trilha R1 2025.',
	},
	{
		title: 'Treinamento Hands-On online.',
	},
	{
		title: 'Kit de Flashcards Hardwork.',
	},
	{
		title: 'Mentoria mensal em grupo.',
	},
	{
		title: [
			'Acesso a revisão focada em ',
			<p className="gradient-text-trilha-r1-residencia">01 prova que escolher Hardwork-Sprint Final.</p>,
		],
	},
];

export default function TrilhaR1Residencia2025() {
	const classes = useStyles();
	const theme = useTheme();
	const subscribe_card_trilha_r1 = useRef(null);
	const [promotionalDate, setPromotionalDate] = useState();
	const [scroll, setScroll] = useState();
	const [group, setGroup] = useState();
	const [preInscricao, setPreInscricao] = useState({
		agrupamento: group ? `${group} - AVISE-ME` : '',
		nome: '',
		email: '',
		telefone: '',
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
	});

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const [waitListVisibility, setWaitListVisibility] = useState(false);

	useEffect(() => {
		Api.get(`turmasabertas/1408`).then((res) => {
			if (res) {
				// PARA O INÍCIO DAS VENDAS
				const targetDate = new Date(res[0]?.data_inicio_inscricoes);
				// PARA O FINAL DO 1º LOTE
				// const targetDate = new Date(res[0]?.lotes[0]?.data_limite);
				setPromotionalDate(targetDate);
			}
		});
		const handleScroll = () => {
			const scrollPosition = window.scrollY;
			if (scrollPosition > 500) {
				setScroll(true);
			} else {
				setScroll(false);
			}
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const executeScroll = () =>
		subscribe_card_trilha_r1.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

	const oQueTemMaisNoTrilhaR1 = [
		{
			title: [<p style={{ color: '#FFFFFF', display: 'inline' }}>+ DE 250</p>, ' AULAS'],
			icon: require('./Assets/icon-classes.svg').default,
			description: 'Acesso imediato a mais de 250 aulas Hardwork, elaboradas com rigor em didática.',
		},
		{
			title: [
				<p style={{ color: '#FFFFFF', display: 'inline' }}>+ DE 190</p>,
				' Materiais de Apoio ao Estudo por Questões',
			],
			icon: require('./Assets/icon-support-material.svg').default,
			description: 'Resumos Hardwork dos principais temas explorados nas provas de residência médica.',
		},
		{
			title: [<p style={{ color: '#FFFFFF', display: 'inline' }}>+ DE 100 MIL</p>, ' QUESTÕES DE PROVAS'],
			icon: require('./Assets/icon-question.svg').default,
			description: 'Mais de 100 mil questões de provas dos principais concursos de residência do Brasil.',
		},
		{
			title: [<p style={{ color: '#FFFFFF', display: 'inline' }}>+ DE 200</p>, ' LIVES DE DISCUSSÃO'],
			icon: require('./Assets/icon-camera.svg').default,
			description:
				'Acesso imediato ao banco de lives do Hardwork Medicina, com discussões de mais de 200 dos principais temas voltados para provas de residência médica.',
		},
		{
			title: [<p style={{ color: '#FFFFFF', display: 'inline' }}>MENTORIA</p>, ' MENSAL EM GRUPO'],
			icon: require('./Assets/icon-mentor.svg').default,
			description:
				'Acesso a uma mentoria mensal em grupo, para explorar dúvidas e dicas para os estudos e para a vida.',
		},
		{
			title: ['GRUPOS COM ', <p style={{ color: '#FFFFFF', display: 'inline' }}>TUTORIA</p>],
			icon: require('./Assets/icon-tutor.svg').default,
			description: 'Grupos de whatsapp com tutor, para tirar dúvidas e estimular os estudos.',
		},
		{
			title: ['CONTATO DIRETO COM OS ', <p style={{ color: '#FFFFFF', display: 'inline' }}>PROFESSORES</p>],
			icon: require('./Assets/icon-dm-contact.svg').default,
			description:
				'Não existe escola em que o aluno não fale com o professor! Aqui o contato é direto, todos os alunos têm acesso aos professores via whatsapp.',
		},
		{
			title: [
				'ACESSO A BIBILIOTECA DIGITAL COM ',
				<br />,
				<p style={{ color: '#FFFFFF', display: 'inline' }}>+ DE 7 MIL LIVROS DE MEDICINA</p>,
			],
			icon: require('./Assets/icon-library.svg').default,
			description:
				'Nada de se restringir a textos resumidos! Vamos dominar as principais fontes! Vamos te mostrar como.',
		},
	];

	const handleOpenWaitList = ({ idTurma }) => {
		setWaitListVisibility(true);

		if (idTurma) {
			setGroup(getGroup(idTurma));
			setPreInscricao({
				...preInscricao,
				agrupamento: getGroup(idTurma),
			});
		}
	};

	const handleCloseWaitList = () => {
		setWaitListVisibility(false);
	};

	function getGroup(id) {
		switch (id) {
			case 1408:
				return 'TRILHA R1 2025 RESIDÊNCIA';
			case 1440:
				return 'TRILHA R1 2025 RESIDÊNCIA TODOS SPRINTS';
			case 1441:
				return 'TRILHA R1 2025 RESIDÊNCIA PROVAS SP';
			case 1439:
				return 'TRILHA R1 2025 RESIDÊNCIA 1 SPRINT';
			default:
				return null;
		}
	}

	let sendPreInscricao = function () {
		if (!preInscricao.nome) {
			alert('Ops... Por favor informe seu nome');
			return;
		}
		if (!preInscricao.email || !preInscricao.email.includes('@') || !preInscricao.email.includes('.')) {
			alert('Ops... Por favor preencha seu email');
			return;
		}
		if (!preInscricao.telefone || preInscricao.telefone.length < 11) {
			alert('Ops... Por favor preencha seu telefone');
			return;
		}

		// console.log('group: ', preInscricao);
		trackPromise(
			Api.post('PreInscricao', preInscricao).then((res) => {
				if (res) {
					handleCloseWaitList();
					Swal.fire({
						title: 'Massa! ',
						text: 'Sua inscrição na lista de espera está confirmada! Avisaremos assim que a nova turma estiver liberada.',
						icon: 'success',
						confirmButtonText: 'Fechar',
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then((result) => {
						if (result.isConfirmed) {
							window.location.reload();
						}
					});
				}
			})
		);
	};

	const handlePhone = (valid, phoneNumber) => {
		if (valid) {
			setPreInscricao({
				...preInscricao,
				telefone: phoneNumber,
			});
		} else if (preInscricao.telefone.length) {
			setPreInscricao({
				...preInscricao,
				telefone: '',
			});
		}
	};

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
			style={{
				backgroundColor: '#09090A',
			}}
		>
			<Helmet>
				<title>Trilha R1 2025</title>
				<meta
					name="description"
					content=""
				/>
			</Helmet>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className="residencia"
			>
				{matchesMobile || matchesTablet ? null : <Header />}
			</Container>
			<Grid
				container
				style={{ backgroundImage: `url(${require('./Assets/bg-glitch-trilha-25.png')})` }}
			>
				<FirstSectionFullScreen2024
					backgroundImageUrl={BgTrilha}
					logoUrl={LogoR1}
					imageProps={{ left: '4rem' }}
					type="red"
					buttonText="Avise-me!"
					executeScroll={executeScroll}
					firstTextPaper={[
						<Typography
							align="left"
							color="secondary"
							fontWeight={600}
							fontFamily={'Manrope'}
							className={`${classes.firstText}`}
						>
							A maior revolução da
							<br /> história da preparação
							<br /> para Provas de Residência.
						</Typography>,
						<Typography
							align="left"
							color="#B3B3B3"
							className={`${classes.secondSectionText}`}
							fontFamily={'Manrope'}
							style={{ marginTop: '2.5rem' }}
						>
							No Extensivo R1 do Hardwork
							<br />
							caminharemos juntos na trilha do{' '}
							<strong style={{ color: '#FCFCFC' }}>
								aprendizado definitivo para
								<br /> dominar
							</strong>{' '}
							qualquer prova de
							<br /> residência do Brasil.
						</Typography>,
					]}
				/>
				<LearningPathSectionCarrousel
					title={'A Trilha do aprendizado definitivo'}
					cardsInfo={learningPathTemplate}
				/>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					<Grid
						container
						className="section"
						direction={'column'}
						rowGap={3}
					>
						<Typography
							fontWeight={700}
							fontFamily={'Manrope'}
							color={'#FCFCFC'}
							className={classes?.subtitle1}
						>
							Avaliação de desempenho metrificada
						</Typography>
						<Grid
							container
							wrap={matchesMobile ? 'wrap' : 'nowrap'}
							columnGap={2}
							rowGap={2}
							style={{ marginTop: '1.5rem' }}
						>
							<MetricBoxInfo
								icon={require('./Assets/icon-platform.svg').default}
								title={'PLATAFORMA'}
								description={
									'A constância é a chave do sucesso. As métricas de plataforma te mostram o que você fez ao longo do tempo, quanto estudou, quantas aulas viu...'
								}
							/>
							<MetricBoxInfo
								icon={require('./Assets/icon-learning.svg').default}
								title={'APRENDIZADO'}
								description={
									'Acompanhe seu desempenho em cada tema e entenda seus pontos fortes e pontos que precisam de atenção.'
								}
							/>
							<MetricBoxInfo
								icon={require('./Assets/icon-institution.svg').default}
								title={'INSTITUIÇÃO'}
								description={
									'Selecione sua instituição de interesse e veja seu desempenho nos temas importantes para ela.'
								}
							/>
						</Grid>
					</Grid>
					<Grid
						container
						className="section"
						direction={'column'}
						rowGap={3}
						style={{ marginTop: '1.5rem' }}
					>
						<Typography
							fontWeight={700}
							fontFamily={'Manrope'}
							color={'#FCFCFC'}
							className={classes?.subtitle1}
						>
							Plataforma alinhada a sua rotina de estudo
						</Typography>
						<Grid
							item
							container
							style={{
								border: '1px solid #656565',
								backgroundColor: '#1D1D1D',
								borderRadius: 24,
								padding: '1rem',
								marginTop: '1.5rem',
							}}
							// columnGap={2}
						>
							<Grid
								item
								xs={12}
								md={6}
								container
								justifyContent={'center'}
								// style={{ height: 437 }}
							>
								<img
									src={require('./Assets/bg-plataform-trilha-r1-25.png')}
									alt=""
									// style={{ width: '48%' }}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								md={6}
								container
								alignContent={'center'}
								rowGap={2}
							>
								<BoxInfo
									title={'A plataforma que se ajusta à sua rotina!'}
									description={[
										'Os dias não são todos iguais e nem precisam ser. Ajuste seus estudos de acordo com sua rotina e tempo disponível.',
										<br />,
										<br />,
										'Selecione o tempo que vai dedicar ao estudo e a plataforma te direciona para a melhor forma de estudar: revisão, aula, estudo por questões...',
										<br />,
										<br />,
										'E tudo isso com previsão de conclusão, para que possa acompanhar e direcionar seus estudos da melhor maneira, de acordo com seus objetivos.',
									]}
								/>
								{/* <BoxInfo
									title={'Estude ativamente por questões'}
									description={
										'Precisamos nos conhecer! Tu terás uma sessão de mentoria individual, para adaptarmos o trilha a sua vida.'
									}
								/>
								<BoxInfo
									title={'Revise'}
									description={
										'Precisamos nos conhecer! Tu terás uma sessão de mentoria individual, para adaptarmos o trilha a sua vida.'
									}
								/> */}
							</Grid>
						</Grid>
					</Grid>
					<Grid
						container
						className="section"
						direction={'column'}
						rowGap={3}
					>
						<Typography
							fontWeight={700}
							fontFamily={'Manrope'}
							color={'#FCFCFC'}
							className={classes.subtitle1}
						>
							O que mais tem no Trilha R1 2025
						</Typography>
						<Grid
							item
							container
							justifyContent={'space-between'}
							alignItems={'strech'}
							rowGap={2}
						>
							{oQueTemMaisNoTrilhaR1.map((elm, i) => {
								return (
									<BoxInfoOqueTemMais
										key={i}
										title={elm?.title}
										icon={elm.icon}
										description={elm.description}
									/>
								);
							})}
						</Grid>
					</Grid>
					<Grid
						container
						className="section"
						direction={'column'}
						rowGap={3}
						marginBottom={5}
					>
						<Typography
							fontWeight={700}
							fontFamily={'Manrope'}
							color={'#FCFCFC'}
							className={classes.subtitle1}
						>
							Para completar sua jornada...
						</Typography>
						<BoxTextAndImage
							title={'Flashcards'}
							description={[
								'Receba o kit físico de Flashcards criados pelos professores ',
								<br />,
								'Hardwork que abordam os temas mais quentes das provas de residência médica.',
							]}
							img={require('./Assets/img-flashcards.png')}
						/>{' '}
						<BoxTextAndImage
							title={'Treinamento Hands-On online'}
							description={[
								'Uma série de treinamentos focados na prática médica,',
								<br />,
								'essencial para as provas e para a vida.',
							]}
							img={require('./Assets/img-fafa.png')}
							invert
						/>{' '}
					</Grid>
				</Container>
				<Grid
					item
					xs={12}
					container
					style={{
						backgroundImage: `url(${require('./Assets/bg-gradient-price-card-trilha-r1-25.png')})`,
						backgroundSize: 'cover',
					}}
				>
					<Container
						fixed
						maxWidth="xl"
						disableGutters={true}
						className="residencia"
					>
						<Grid
							container
							className="section"
							rowGap={3}
							ref={subscribe_card_trilha_r1}
						>
							<Typography
								fontWeight={700}
								fontFamily={'Manrope'}
								color={'#FCFCFC'}
								className={classes.subtitle1}
							>
								Garanta sua inscrição!
							</Typography>
							<Grid
								item
								xs={12}
								container
								justifyContent={'space-between'}
								style={{ marginTop: '1.5rem' }}
								columnGap={2}
								rowGap={3}
								paddingBottom={5}
							>
								<PriceCard
									classes={classes}
									logoProduct={require('./Assets/logo-trilha-r1-2025.svg').default}
									benefits={benefitsR1}
									benefits2={eTemMaisDuranteOTrilha}
									infoTitles={infoTitlesR1}
									idTurma={1408}
									handleOpenWaitList={handleOpenWaitList}
								/>
								<PriceCard
									classes={classes}
									logoProduct={require('./Assets/logo-trilha-r1-25-all-sprints.svg').default}
									benefits={benefitsR1}
									benefits2={eTemMaisDuranteOTrilha_AllSprints}
									infoTitles={infoTitles_AllSprints}
									idTurma={1440}
									handleOpenWaitList={handleOpenWaitList}
								/>
								<PriceCard
									classes={classes}
									logoProduct={require('./Assets/logo-trilha-r1-25-sp-exams.svg').default}
									benefits={benefitsR1}
									benefits2={eTemMaisDuranteOTrilha_SpExams}
									infoTitles={infoTitles_SpExams}
									idTurma={1441}
									handleOpenWaitList={handleOpenWaitList}
								/>
								<PriceCard
									classes={classes}
									logoProduct={require('./Assets/logo-trilha-r1-25-one-sprint.svg').default}
									benefits={benefitsR1}
									benefits2={eTemMaisDuranteOTrilha_OneSprint}
									infoTitles={infoTitles_OneSprint}
									idTurma={1439}
									handleOpenWaitList={handleOpenWaitList}
								/>
							</Grid>
						</Grid>
					</Container>
				</Grid>
				<FeedbackContainerSection
					type={'residencia'}
					title={'Depoimentos de nossos alunos'}
				/>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					<Grid
						container
						className="section"
						rowGap={2}
					>
						<Typography
							fontWeight={700}
							fontFamily={'Manrope'}
							color={'#FCFCFC'}
							style={{ fontSize: '2.5rem' }}
						>
							FAQ
						</Typography>
						<Faq />
					</Grid>
				</Container>
			</Grid>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{' '}
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
			<CounterDown
				scrollToPayment={executeScroll}
				text={'Abertura do Trilha R1 2025 em'}
				// text={'Valores especiais de lançamento'}
				date={promotionalDate}
				colorStartButton={'#FF5F45'}
				colorEndButton={'#FF357D'}
			/>
			<Modal
				open={waitListVisibility}
				// onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				// className={classes.modalContent}
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					// visibility: waitListVisibility,
				}}
			>
				<Grid
					container
					justifyContent={'center'}
					alignItems={'center'}
					style={{
						backgroundColor: '#fcfcfc',
						width: matchesTablet ? '90%' : '35%',
						padding: '1rem',
						borderRadius: 18,
					}}
				>
					<form
						className="form-control"
						style={{
							width: '100%',
						}}
					>
						<Grid
							item
							xs={12}
							container
							justifyContent={'center'}
						>
							<Grid
								item
								container
								justifyContent="space-between"
							>
								<Typography
									color="primary"
									className={`${classes.textWaitList} poppins`}
								>
									Inscrever-se na Lista de Espera
								</Typography>
								<CancelRoundedIcon
									onClick={() => handleCloseWaitList()}
									className={classes.cancelIcon}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								md={6}
								container
								justifyContent={matchesTablet ? 'center' : 'flex-start'}
							>
								<TextField
									fullWidth
									type="string"
									name="nome"
									placeholder="Seu nome completo"
									required={true}
									InputProps={{
										className: `${classes.form} ${classes.formHeight}`,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									style={matchesTablet ? { width: '100%' } : { width: '97%' }}
									onChange={(e) =>
										setPreInscricao({
											...preInscricao,
											nome: e.target.value,
										})
									}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								md={6}
								container
								justifyContent={matchesTablet ? 'center' : 'flex-end'}
								alignContent={'center'}
							>
								<IntlTelInput // Componente Input de telefones internacionais
									name="telefone"
									type="telefone"
									preferredCountries={['BR', 'PY', 'AR', 'CL', 'BO', 'UY', 'CU', 'PT']}
									style={{ width: '100%' }}
									inputClassName={`${classes.phoneNumberForm} ${classes.form} ${classes.formHeight}`}
									nationalMode={true}
									onPhoneNumberChange={(valid, value, selectedCountryData, fullNumber) =>
										handlePhone(valid, fullNumber)
									}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								container
								justifyContent={matchesMobile ? 'center' : 'flex-start'}
							>
								<TextField
									fullWidth
									type="email"
									name="email"
									placeholder="Seu melhor e-mail"
									required={true}
									InputProps={{
										className: `${classes.form} ${classes.formHeight}`,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									style={{ width: '100%' }}
									onChange={(e) =>
										setPreInscricao({
											...preInscricao,
											email: e.target.value,
										})
									}
								/>
							</Grid>
							{group && (
								<Grid
									item
									xs={12}
									md={10}
									lg={8}
									container
									className="residencia"
									style={{ height: 50, padding: '4px 0px' }}
								>
									<DefaultButton
										// type="submit"
										variant="outlined"
										color="secondary"
										className={`${classes.btnWaitList} btn`}
										onClick={() => sendPreInscricao()}
									>
										{/* <img
                                        src={require('../../Assets/Arena/icon-waitList.svg').default}
                                        alt=""
                                        style={{ width: 20 }}
                                    />{' '} */}
										&nbsp; Confirmar inscrição
									</DefaultButton>
								</Grid>
							)}
						</Grid>
					</form>
				</Grid>
			</Modal>
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.5em !important',
		lineHeight: '1 !important',
	},
	firstText: {
		fontSize: '2.5rem !important',
		lineHeight: 'unset !important',
		color: '#FCFCFC !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
		},
	},
	text: {
		fontSize: '1.15rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	subtitle1: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('md')]: {
			lineHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
			lineHeight: 'unset !important',
		},
	},
	scriptText: {
		fontSize: '0.95rem !important',
		overflowY: 'auto',
		paddingRight: 20,
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
			maxHeight: 175,
			minHeight: 'unset !important',
		},
	},
	gridButton: {
		bottom: 25,
		left: '-5%',
		[theme.breakpoints.down('lg')]: {
			bottom: -17,
		},
		[theme.breakpoints.down('sm')]: {
			left: '-7%',
			bottom: -15,
		},
	},
	btn: {
		fontSize: '1rem !important',
		backgroundColor: '#33C96F !important',
		fontWeight: 'bold !important',
		'&:hover': {
			scale: 1.05,
			color: '#ffffff !important',
			'& $logoBtn': {
				filter: 'invert(0)',
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.75rem !important',
			minHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.75rem !important',
			width: '50%',
		},
	},
	gridTextSecondSection: {
		paddingBottom: 70,
		[theme.breakpoints.down('lg')]: {
			paddingBottom: 35,
		},
	},
	textSecondSection: {
		fontSize: '1.85rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.4rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	btnDetail: {
		fontWeight: 'bold',
		width: '100%',
		maxHeight: 50,
		[theme.breakpoints.down('lg')]: {
			width: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			width: '25%',
			fontSize: '.8rem !important',
		},
	},
	gridButtonDetails: {
		bottom: 5,
		left: '70%',
		[theme.breakpoints.down('lg')]: {
			bottom: 20,
		},
		[theme.breakpoints.down('sm')]: {
			left: '-3%',
			bottom: 40,
		},
	},
	gridBtnHeight: {
		transition: 'all .2s',
		display: 'flex',
		alignContent: 'flex-start',
	},
	description: {
		display: 'inline-block',
		fontSize: '1.25rem !important',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			fontSize: '1rem !important',
		},
	},
	descriptionGrid: {
		width: '100% !important',
		margin: '1.5rem 0rem 1.5rem 0rem',
		transition: 'all 0.5s ease',
	},
	subtitle: {
		fontSize: '2.85rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		// marginBottom: '1rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
			marginBottom: '0.5rem !important',
		},
	},
	focusImg: {
		position: 'absolute !important',
		top: 260,
		zIndex: 1,
		left: 215,
		width: '35%',
		[theme.breakpoints.up('xl')]: {
			top: 300,
		},
		[theme.breakpoints.down('lg')]: {
			left: 180,
			top: 230,
			// width: '45%',
		},
		[theme.breakpoints.down('md')]: {
			left: 60,
			// bottom: -45,
		},
	},
	strategyImg: {
		position: 'absolute',
		top: 300,
		zIndex: 1,
		right: 200,
		width: '50%',
		[theme.breakpoints.up('xl')]: {
			top: 360,
		},
		[theme.breakpoints.down('lg')]: {
			right: 130,
			top: 250,
			width: '40%',
		},
		[theme.breakpoints.down('md')]: {
			right: 130,
			width: '15%',
		},
	},
	sweatImg: {
		position: 'absolute',
		top: 320,
		zIndex: 1,
		left: 200,
		width: '50%',
		[theme.breakpoints.up('xl')]: {
			top: 360,
		},
		[theme.breakpoints.down('lg')]: {
			left: 130,
			top: 290,
			width: '40%',
		},
		[theme.breakpoints.down('md')]: {
			width: '15%',
		},
	},
	btnFirstSection: {
		// display: 'none',
		position: 'fixed !important',
		zIndex: '1500',
		border: '5px solid black !important',
		width: 'fit-content',
		padding: '0 3rem !important',
		bottom: '3rem',
		right: '3rem',
		fontSize: '1.425rem !important',
		fontWeight: 'bold !important',
		'&:hover': {
			scale: 1.05,
			color: '#ffffff',
			'& $logoBtn': {
				filter: 'invert(0%) !important',
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
			minHeight: 'unset !important',
			width: 'fit-content',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
			width: 'fit-content',
		},
	},
	firstSectionText: {
		fontSize: '2rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '8px !important',
			fontSize: '1.25rem !important',
		},
	},
	secondSectionText: {
		fontSize: '2rem !important',
		lineHeight: '1.25 !important',
		// [theme.breakpoints.down('lg')]: {
		// 	fontSize: '1.5rem !important',
		// },
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},

	checkIcon: {
		color: '#FCFCFC',
		fontSize: '1rem !important',
	},
	btnWaitList: {
		fontSize: '1rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		backgroundColor: '#00AB55 !important',
		border: '1px solid #707070 !important',
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				minWidth: '72% !important',
				fontSize: '1.025rem !important',
				backgroundColor: '#00543b !important',
				transition: '0.1s',
			},
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	form: {
		borderRadius: '8px !important',
		backgroundColor: '#E1E1E1 !important',
		margin: '5px 0 !important',
	},
	formHeight: {
		maxHeight: '3em',
	},
	phoneNumberForm: {
		height: '3em',
		borderRadius: 8,
		width: '100%',
		border: 'unset',
	},
	textWaitList: {
		fontSize: '1.3rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.95rem !important',
		},
	},
	cancelIcon: {
		color: '#000000',
		top: '-5px',
		'&:hover': {
			cursor: 'pointer',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'absolute',
			top: '-10px',
			right: '-10px',
		},
	},
}));
