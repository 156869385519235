import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Helmet } from 'react-helmet';
import { Header } from '../../../../Components/Header';
import Footer from '../../../../Components/Footer';
import Form from '../../../../Components/Utils/Forms/PreRegistration';

import bgArena from './assets/bg-area.png';
import logoArena from './assets/logo-arena-2024.png';
import btnLive from './assets/btn-live.svg';
import btnExtraInfos from './assets/btn-extra-infos.svg';
import imgProfessores from './assets/img-professores.svg';

export default function PreInscricaoArena20242() {
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesLaptop = useMediaQuery(theme.breakpoints.down('xl'));

	return (
		<div style={{ backgroundColor: '#020202', minHeight: '100vh' }}>
			<Container
				maxWidth={false}
				disableGutters={true}
			>
				<Helmet>
					<title>Pré-Inscrição Arena 2024.2</title>
					<meta
						name="description"
						content={''}
					/>
				</Helmet>

				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
					className="residencia"
				>
					{!matchesMobile && <Header />}
				</Container>

				<Grid
					container
					width={'100vw'}
					height={'100%'}
					flexWrap={'wrap'}
					paddingTop={matchesMobile ? 12 : 0}
				>
					<Grid
						item
						container
						alignItems={'center'}
						justifyContent={matchesTablet ? 'center' : 'flex-end'}
						xs={12}
						lg={6.5}
						paddingY={4}
						paddingLeft={matchesTablet ? 4 : 8}
						paddingRight={matchesTablet ? 4 : 8}
					>
						<Grid
							item
							container
							xs={12}
							height={'fit-content'}
							justifyContent={'center'}
						>
							<Grid
								item
								container
								justifyContent={'center'}
								paddingLeft={matchesTablet ? 0 : matchesLaptop ? 2 : 20}
								marginBottom={-14}
							>
								<img src={imgProfessores} />
							</Grid>

							<Grid
								item
								container
								gap={3}
								flexWrap={'nowrap'}
								paddingLeft={matchesLaptop ? 0 : matchesLaptop ? 2 : 24}
							>
								<img
									src={btnLive}
									width={matchesLaptop ? 80 : 100}
									height={'auto'}
									alt="btn Live"
								/>
								<Typography
									fontFamily={'Manrope'}
									fontWeight={800}
									color={'white'}
									fontSize={matchesMobile ? 24 : matchesLaptop ? 30 : 41}
									whiteSpace={matchesLaptop ? 'normal' : 'pre-wrap'}
									lineHeight={0.9}
								>
									{`Como treinar o raciocínio\nclínico para a prova prática`}
								</Typography>
							</Grid>
							<Grid
								item
								container
								paddingLeft={matchesLaptop ? 0 : matchesLaptop ? 2 : 24}
							>
								<Typography
									fontFamily={'Manrope'}
									fontWeight={400}
									color={'white'}
									marginTop={3}
									fontSize={matchesMobile ? 16 : matchesLaptop ? 20 : 24}
								>
									{`A estratégia perfeita para dominar checklists`}
								</Typography>
							</Grid>
							<Grid
								item
								container
								paddingLeft={matchesLaptop ? 0 : matchesLaptop ? 2 : 24}
							>
								<Typography
									fontFamily={'Manrope'}
									fontWeight={800}
									color={'white'}
									marginTop={matchesTablet ? 1 : 3}
									fontSize={32}
								>
									{`17SET • 20H00`}
								</Typography>
							</Grid>
							<Grid
								item
								container
								paddingLeft={matchesTablet ? 0 : matchesLaptop ? 2 : 24}
								marginTop={matchesTablet ? 2 : 6}
							>
								<img
									src={btnExtraInfos}
									width={matchesTablet ? '100%' : '75%'}
									height={'auto'}
									alt="btn Live"
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid
						item
						container
						alignItems={'center'}
						xs={12}
						lg={5.5}
						paddingY={4}
						style={{ backgroundImage: `url(${bgArena})`, backgroundSize: 'cover' }}
					>
						<Grid
							container
							item
							justifyContent={'center'}
							xs={12}
							lg={matchesTablet ? 8 : matchesLaptop ? 12 : 8}
							paddingLeft={matchesTablet ? 4 : 8}
							paddingRight={matchesTablet ? 4 : 8}
						>
							<img
								src={logoArena}
								alt="logo arena"
								width={450}
							/>
							<Grid
								item
								marginTop={10}
							>
								<Form
									urlToRedirect={'/pre-inscricao/arena-online-24-2'}
									group="Live Lançamento Arena Online 24.2"
									title={['Inscreva-se gratuitamente!']}
									titleButton="Confirmar inscrição"
									type="sprint-gray"
									confirmationMessage={'Sua inscrição foi confirmada!'}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Footer />
			</Container>
		</div>
	);
}
