import React, { useEffect, useState } from 'react';
import { Container, Divider, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../../Components/Footer';
import { Header } from '../../../../../Components/Header';

import HostsCarrousel from '../../../../../Components/Sections/HostsCarrousel';

const Hosts = [
	{
		ig_username: '@fabricio.valois',
		img: require('../Assets/fabricioValois.png'),
		specialty: 'Clínica Médica',
	},
	{
		ig_username: '@drfabiocolagrossi',
		img: require('../Assets/fabioColagrossi.png'),
		specialty: 'Cirurgia Geral',
	},
	{
		ig_username: '@fabio.cabar',
		img: require('../Assets/fabioCabar.png'),
		specialty: 'Ginecologia e obstetrícia',
	},
	// {
	// 	ig_username: '@drdecarthonvitor',
	// 	img: require('../Assets/decarthon.png'),
	// 	specialty: 'Cardiologia',
	// },
];

export default function SubscriptionEventSalvador() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesDownXl = useMediaQuery(theme.breakpoints.down('xl'));

	// const [closeGroup, setCloseGroup] = useState(false);

	// useEffect(() => {
	// 	const group = 'HARDWORK SALVADOR 2023';
	// 	Api.get(`preinscricao/?agrupamento=${group}`).then((res) => {
	// 		setCloseGroup(res >= 420);
	// 	});
	// }, []);

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					justifyContent={'space-between'}
					alignContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
				>
					{matchesTablet ? null : (
						<img
							src={require('../Assets/bg-fabricio.png')}
							alt=""
							className={classes.fabricioImg}
						/>
					)}
					<Grid
						item
						xs={12}
						md={7}
						container
						justifyContent={'flex-start'}
						className={`${classes.gridTextContainer} residencia`}
						gap={1}
					>
						{matchesMobile && (
							<Grid
								style={{
									position: 'absolute',
									width: '100%',
									height: '100%',
									overflow: 'hidden',
									// backgroundImage: `url(${require('../Assets/bg-fabricio.png')})`,
								}}
							>
								<img
									src={require('../Assets/bg-fabricio.png')}
									alt=""
									className={classes.fabricioImg2}
								/>
							</Grid>
						)}
						{matchesTablet ? (
							<img
								src={require('../Assets/logo-hwm-salvador.svg').default}
								alt="Logo Hardwork em Salvador"
								style={matchesDownXl ? null : matchesTablet ? { height: 120 } : null}
							/>
						) : null}
						<Typography
							className={`${classes.title} gradient-text roboto-condensed`}
							color="secondary"
						>
							Uma noite com o show de didática Hardwork e com a revolução na preparação para as provas.
						</Typography>
						<Typography
							className={`${classes.text} poppins`}
							color="secondary"
						>
							No dia 28 de junho estaremos contigo em Salvador para te mostrar o modo Hardwork de ver a
							medicina...
							<br />
							<br />
							Serão três aulas de temas quentes, tanto para a vida real, quanto para as provas. E no
							final, sorteios especiais.
							<br />
							<br />
							As inscrições são gratuitas. As vagas são limitadas.
							{/* e levar 2kg
							de alimento no dia do evento, que serão doados a instituições de caridade de São Luís. */}
							<br />
							<br />
						</Typography>
						<Typography
							align="left"
							color="secondary"
							className={`${classes.impactText} poppins`}
							style={{ marginBottom: '1rem' }}
						>
							CONFIRA O CRONOGRAMA:
						</Typography>
						<Grid
							item
							container
							gap={3}
							style={{ marginBottom: '3em' }}
						>
							<Divider
								variant="middle"
								orientation="vertical"
								className={classes.divider}
							/>
							<Grid
								item
								container
								wrap="nowrap"
								gap={2}
							>
								<img
									src={require('../Assets/1930.svg').default}
									alt=""
									className={classes.imgTime}
								/>
								<Grid
									item
									container
								>
									<Typography
										color="secondary"
										className={`${classes.timelineText} gradient-text poppins`}
										style={{ marginBottom: 'unset !important' }}
									>
										Abdome agudo
									</Typography>
								</Grid>
							</Grid>
							<Grid
								item
								container
								wrap="nowrap"
								gap={2}
							>
								<img
									src={require('../Assets/2000.svg').default}
									alt=""
									className={classes.imgTime}
								/>
								<Grid
									item
									container
								>
									<Typography
										color="secondary"
										className={`${classes.timelineText} gradient-text poppins`}
										style={{ marginBottom: 'unset !important' }}
									>
										Hipertensão e gestação
									</Typography>
								</Grid>
							</Grid>
							<Grid
								item
								container
								wrap="nowrap"
								gap={2}
							>
								<img
									src={require('../Assets/2030.svg').default}
									alt=""
									className={classes.imgTime}
								/>
								<Grid
									item
									container
									alignContent={'center'}
								>
									<Typography
										color="secondary"
										className={`${classes.timelineText} gradient-text poppins`}
										style={{ marginBottom: 'unset !important' }}
									>
										Crise hipertensiva
									</Typography>
								</Grid>
							</Grid>
							<Grid
								item
								container
								wrap="nowrap"
								gap={2}
							>
								<img
									src={require('../Assets/icon-grey-dot.svg').default}
									alt=""
									style={{ height: 30 }}
								/>
								<Grid
									item
									container
								>
									<Typography
										color="secondary"
										className={`${classes.timelineText} gradient-text poppins`}
										style={{ marginBottom: 'unset !important' }}
									>
										Sorteios
									</Typography>
									<Typography
										color="secondary"
										className={`${classes.text} poppins`}
										style={{ marginBottom: '1.25rem' }}
									>
										Alexas Hardwork (Flashcards Hardwork), cupons de desconto e bolsa de estudos
										Sprint Final.
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Typography
							align="left"
							color="secondary"
							className={`${classes.impactText} poppins`}
						>
							QUARTA-FEIRA
							<span className="bullet"> &#8226; </span>
							28JUN
							<span className="bullet"> &#8226; </span>
							19H30
						</Typography>
						<Typography
							align="left"
							color="secondary"
							className={`${classes.impactText2} poppins`}
						>
							ONDE SERÁ O EVENTO?
						</Typography>
						<Typography
							color="secondary"
							className={`${classes.text} poppins`}
							style={{ marginBottom: '1.5rem' }}
						>
							<strong>Fiesta Bahia,</strong>
							<br />
							Av. Antônio Carlos Magalhães, 741 - Itaigara,
							<br />
							Salvador - BA, 40280-000.
						</Typography>
						{!matchesTablet && (
							<HostsCarrousel
								Hosts={Hosts}
								type="residencia"
							/>
						)}
					</Grid>
					<Grid
						item
						xs={12}
						md={5}
						container
						justifyContent="center"
						className={classes.gridWithBackgroundImage}
						gap={matchesTablet ? 4 : 0}
					>
						<Grid
							item
							xs={12}
							className={classes.gridForm}
							gap={2}
						>
							{matchesTablet ? null : (
								<img
									src={require('../Assets/logo-hwm-salvador.svg').default}
									alt="Logo Hardwork em Salvador"
								/>
							)}
							<Grid
								item
								container
								justifyContent={'center'}
								className="background-gradient-residencia"
								style={{ borderRadius: 15, padding: '1rem 1.5rem' }}
								gap={1}
							>
								<Typography
									color="secondary"
									className="poppins"
									align={matchesTablet ? 'left' : 'center'}
									style={{ fontWeight: 'bold', fontSize: '2.5rem' }}
								>
									VAGAS ESGOTADAS!
								</Typography>
								<Typography
									color="secondary"
									className="poppins"
									align={matchesTablet ? 'left' : 'center'}
									style={{ fontSize: '1.65rem' }}
								>
									Fique ligado nas redes sociais para
									<br />
									não perder os próximos eventos!
								</Typography>
								<Grid
									item
									container
									justifyContent={matchesMobile ? 'flex-start' : 'center'}
									alignContent={'center'}
									style={{ marginTop: '1.5rem' }}
								>
									<IconButton
										href="https://www.instagram.com/hardwork.revalida/"
										className="default-icon-button"
										style={{
											paddingLeft: '0',
										}}
									>
										<img
											src={require('../Assets/icon-ig.svg').default}
											alt=""
										/>
									</IconButton>

									<a
										color="secondary"
										className="poppins"
										style={{
											alignSelf: 'center',
											fontSize: '1.25rem',
											color: '#ffffff',
											textDecoration: 'unset',
										}}
										href="https://www.instagram.com/hardworkmedicina/"
									>
										@hardworkmedicina
									</a>
								</Grid>
							</Grid>
							{/* <Form
									closeGroup={closeGroup}
									group="HARDWORK SALVADOR 2023"
									title={
										closeGroup
											? [
													'VAGAS ESGOTADAS!',
													<br />,
													'Cadastre-se para entrar',
													<br />,
													'na lista de espera.',
											  ]
											: ['Inscreva-se para garantir sua vaga e participar do evento']
									}
									titleButton={closeGroup ? 'Entrar na lista de espera' : 'Garantir minha vaga'}
									type="residencia"
									urlToRedirect="/materiais/hardwork-em-salvador"
									titleConfirmationMessage={'Massa!'}
									confirmationMessage={
										closeGroup
											? 'Seu cadastro na lista de espera está confirmado!'
											: 'Sua inscrição está confirmada! Em breve você também receberá uma confirmação pelo e-mail cadastrado.'
									}
								/> */}
						</Grid>
						{matchesTablet && (
							<HostsCarrousel
								Hosts={Hosts}
								type="residencia"
							/>
						)}
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.35rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
			marginBottom: '8px !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.18rem !important',
			marginBottom: '8px !important',
		},
	},
	text: {
		fontSize: '1.3125rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	gridForm: {
		zIndex: 10,
		display: 'flex',
		flexDirection: 'column !important',
		alignItems: 'center !important',
		[theme.breakpoints.down('md')]: {
			position: 'unset',
			right: 'unset',
			top: 'unset',
			zIndex: 0,
		},
	},
	gridTextContainer: {
		paddingRight: '4rem',
		[theme.breakpoints.down('lg')]: {
			paddingRight: '2em',
		},
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
	},
	gridWithBackgroundImage: {
		backgroundImage: `url(${require('../Assets/bg-residencia.png')})`,
		[theme.breakpoints.down('md')]: {
			paddingLeft: 'unset',
			backgroundImage: 'unset',
		},
	},
	impactText: {
		margin: '0 0 2rem !important',
		fontSize: '2rem !important',
		lineHeight: 'unset !important',
		fontWeight: 'bold !important',
		background: 'linear-gradient(90deg, rgb(255 72 0) 10%, rgb(255 0 66) 100%)',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.45rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down(361)]: {
			fontSize: '1.25rem !important',
		},
	},
	timelineText: {
		fontSize: '2.2rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		alignSelf: 'center',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.8rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.2rem !important',
			lineHeight: 'unset !important',
		},
	},
	divider: {
		position: 'absolute',
		border: '1.5px solid #fff !important',
		left: '6.95em',
		backgroundColor: '#fff',
		height: '190px !important',
		zIndex: -1,
		[theme.breakpoints.down('xl')]: {
			height: '175px !important',
		},
		[theme.breakpoints.down(361)]: {
			height: '185px !important',
		},
	},
	imgTime: {
		height: 30,
		alignSelf: 'center',
	},
	impactText2: {
		margin: '1.5rem 0 0 !important',
		fontSize: '1.75rem !important',
		lineHeight: 'unset !important',
		fontWeight: 'bold !important',
		background: 'linear-gradient(90deg, rgb(255 72 0) 10%, rgb(255 0 66) 100%)',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.45rem !important',
			margin: 'unset !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1rem !important',
		},
	},
	impactText3: {
		margin: '0 0 0.5rem !important',
		fontSize: '2rem !important',
		lineHeight: 'unset !important',
		background: 'linear-gradient(90deg, rgb(255 72 0) 10%, rgb(255 0 66) 100%)',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.25rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.45rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(361)]: {
			fontSize: '1.2rem !important',
		},
	},
	logoLdlExperience: {
		width: '55%',
		[theme.breakpoints.down('lg')]: {
			width: 'unset',
		},
	},
	fabricioImg: {
		position: 'absolute',
		left: '28%',
		top: '9%',
		width: '36%',
		opacity: '50%',
	},
	fabricioImg2: {
		left: '28%',
		top: '9%',
		width: '36%',
		opacity: '50%',
		[theme.breakpoints.down('md')]: {
			width: 'unset',
			left: '42%',
			top: '11%',
			opacity: '35%',
		},
		[theme.breakpoints.down('sm')]: {
			left: '29%',
			top: '25%',
		},
	},
}));
