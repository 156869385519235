import {
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import IntlTelInput from 'react-intl-tel-input';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import DefaultButton from '../../Buttons/DefaultButton';
import { trackPromise } from 'react-promise-tracker';
import Api from '../../../Services/Api';
import Swal from 'sweetalert2';

export default function Trilha25Survey() {
	const classes = useStyles();
	const theme = useTheme();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const [preInscricao, setPreInscricao] = useState({
		agrupamento: 'TRILHA 25.1 - PRÉ-INSCRICAO - SORTEIO',
		nome: '',
		email: '',
		telefone: '',
		provas: '',
		pesquisa1: '',
		pesquisa2: '',
		optin: false,
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
	});

	let sendPreInscricao = function (event) {
		event.preventDefault();
		const nomeCompleto = preInscricao.nome.trim();
		const nomeSobrenome = nomeCompleto.split(' ');

		if (!preInscricao.nome || nomeSobrenome.length < 2) {
			alert('Ops... Por favor informe seu nome e sobrenome');
			return;
		}
		if (!preInscricao.email || !preInscricao.email.includes('@') || !preInscricao.email.includes('.')) {
			alert('Ops... Por favor preencha seu email');
			return;
		}
		if (!preInscricao.telefone) {
			alert('Ops... Por favor preencha seu telefone com um número válido');
			return;
		}
		// if (preInscricao.provas.length < 4) {
		// 	alert('Ops... Por favor informe um ano de provas válido');
		// 	return;
		// }
		if (!preInscricao.pesquisa1) {
			alert('Ops... Por favor selecione qual sua forma de aprender');
			return;
		}
		if (preInscricao.pesquisa1 === 'Outro' && !preInscricao.pesquisa2) {
			alert('Ops... Por favor descreva qual sua melhor forma de aprender');
			return;
		}
		if (!preInscricao.optin) {
			alert('Ops... Você precisa ler e aceitar os termos de uso acima para se pré-inscrever');
			return;
		}

		trackPromise(
			Api.post('PreInscricao', preInscricao).then((res) => {
				if (res) {
					Swal.fire({
						title: 'Massa!',
						text: `${'Sua pré-inscrição está confirmada! Mais informações em breve.'}`,
						icon: 'success',
						confirmButtonText: 'Fechar',
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then((result) => {
						if (result.isConfirmed) {
							document.location.href = '/pre-inscricao/trilha-2025-r1';
						}
					});
				}
			})
		);
	};

	const [options] = useState([
		{
			label: 'Ensinando',
			value: 'Ensinando',
		},
		{
			label: 'Aulas',
			value: 'Aulas',
		},
		{
			label: 'Flashcards',
			value: 'Flashcards',
		},
		{
			label: 'Estudo por questões',
			value: 'Estudo por questões',
		},
		{
			label: 'Questões',
			value: 'Questões',
		},
		{
			label: 'Resumos',
			value: 'Resumos',
		},
		// {
		// 	label: 'Discussão em grupo ',
		// 	value: 'Discussão em grupo ',
		// },
		{
			label: 'Livros',
			value: 'Livros',
		},
	]);

	const handlePhone = (valid, phoneNumber) => {
		if (valid) {
			setPreInscricao({
				...preInscricao,
				telefone: phoneNumber,
			});
		} else if (preInscricao.telefone.length) {
			setPreInscricao({
				...preInscricao,
				telefone: '',
			});
		}
	};

	return (
		<Grid
			container
			backgroundColor={'#101010'}
			alignItems={'center'}
			justifyContent={'center'}
			flexDirection={'column'}
		>
			<Grid
				item
				container
				xs={12}
				padding={'16px'}
				backgroundColor={'#303030'}
				borderRadius={'16px'}
				flexDirection={'column'}
				alignItems={'center'}
				justifyContent={'center'}
			>
				<Typography
					fontFamily={'Manrope'}
					fontSize={matchesMobile ? '20px' : '32px'}
					fontWeight={600}
					color={'#FCFCFC'}
				>
					Inscreva-se gratuitamente para participar dos sorteios!
				</Typography>
				<Grid
					item
					container
					xs={12}
					columnGap={2}
					alignItems={'center'}
					justifyContent={'center'}
					flexWrap={matchesMobile ? 'wrap' : 'nowrap'}
					marginTop={'16px'}
					gap={1}
				>
					<Grid
						item
						xs={12}
						id={'input-test'}
					>
						<input
							type={'text'}
							name="nome"
							placeholder={'Seu nome completo'}
							className={classes.inputTextField}
							required
							onChange={(e) => setPreInscricao({ ...preInscricao, nome: e.target.value })}
						/>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<input
							type={'email'}
							name="email"
							placeholder={'Seu melhor e-mail'}
							className={classes.inputTextField}
							required
							onChange={(e) => setPreInscricao({ ...preInscricao, email: e.target.value })}
						/>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<IntlTelInput // Componente Input de telefones internacionais
							name="telefone"
							type="tel"
							preferredCountries={['BR', 'PY', 'AR', 'CL', 'BO', 'UY', 'CU', 'PT']}
							style={{ width: '100%', border: 'unset' }}
							nationalMode={true}
							inputClassName={classes.phoneInput}
							onPhoneNumberChange={(valid, value, selectedCountryData, fullNumber) =>
								handlePhone(valid, fullNumber)
							}
						/>
					</Grid>
					{/*<Grid*/}
					{/*	item*/}
					{/*	xs={12}*/}
					{/*>*/}
					{/*	<input*/}
					{/*		type={'number'}*/}
					{/*		name="formatura"*/}
					{/*		placeholder={'Ano de formatura'}*/}
					{/*		className={classes.inputTextField}*/}
					{/*		required*/}
					{/*		onChange={(e) => setPreInscricao({ ...preInscricao, provas: e.target.value })}*/}
					{/*	/>*/}
					{/*</Grid>*/}
				</Grid>
				<Typography
					fontFamily={'Manrope'}
					fontSize={matchesMobile ? '20px' : '32px'}
					fontWeight={600}
					color={'#FCFCFC'}
					marginTop={'32px'}
				>
					Para ti, qual a melhor forma de aprender e não esquecer?
				</Typography>

				<FormControl style={{ width: '100%', marginTop: '16px' }}>
					<RadioGroup
						row
						aria-labelledby="demo-controlled-radio-buttons-group"
						name="controlled-radio-buttons-group"
						onChange={() => {}}
					>
						<Grid
							container
							item
							xs={12}
							gap={1}
						>
							{options?.map((opt) => (
								<Grid
									item
									xs={12}
									md={5.9}
									paddingX={2}
									className={`${classes.inputTextField}`}
								>
									<FormControlLabel
										name={opt.label}
										value={opt?.value}
										control={
											<Radio
												sx={{
													color: '#959595',
													'&.Mui-checked': {
														color: '#959595',
													},
												}}
												classes={'poppins'}
											/>
										}
										label={opt.label}
										onChange={(e) =>
											setPreInscricao({ ...preInscricao, pesquisa1: e.target.value })
										}
									/>
								</Grid>
							))}

							<Grid
								item
								xs={12}
								md={5.9}
								paddingX={2}
								className={`${classes.inputTextField}`}
							>
								<FormControlLabel
									name={'Outro'}
									value={'Outro'}
									control={
										<Radio
											sx={{
												color: '#959595',
												'&.Mui-checked': {
													color: '#959595',
												},
											}}
											classes={'poppins'}
										/>
									}
									label={'Outro (Qual?)'}
									onChange={(e) => setPreInscricao({ ...preInscricao, pesquisa1: e.target.value })}
								/>
							</Grid>
						</Grid>
					</RadioGroup>
				</FormControl>

				{preInscricao?.pesquisa1 === 'Outro' && (
					<Grid
						container
						xs={12}
						marginTop={'16px'}
					>
						<input
							type={'text'}
							placeholder={'Melhor forma de aprendizado'}
							className={classes.inputTextField}
							required
							onChange={(e) => setPreInscricao({ ...preInscricao, pesquisa2: e.target.value })}
						/>
					</Grid>
				)}
			</Grid>

			<Grid
				item
				container
				xs={12}
				marginTop={'24px'}
				marginBottom={'24px'}
				flexWrap={'nowrap'}
			>
				<Grid item>
					<Checkbox
						style={{
							marginRight: '4px',
							color: 'white',
						}}
						checked={preInscricao.optin}
						onChange={(e) => setPreInscricao({ ...preInscricao, optin: !preInscricao.optin })}
					/>
				</Grid>
				<Grid item>
					<Typography
						variant="caption"
						color={'secondary'}
						className={classes.captionText}
						fontFamily={'Manrope'}
						lineHeight={0}
					>
						Estou de acordo com o tratamento dos meus dados pessoais para fins de inscrição na plataforma
						Hardwork Medicina, bem como para envio de comunicações relativas aos serviços educacionais que
						se adequem ao meu perfil, de acordo com a nossa{' '}
						<Link
							// color="secondary"
							href="https://hardworkmedicina.com.br/politica-de-privacidade"
							target="_blank"
							underline="hover"
							variant="caption"
							align="right"
							className={'secondary'}
						>
							{' '}
							Politica de privacidade.
						</Link>
					</Typography>
				</Grid>
			</Grid>
			<Grid
				item
				xs={12}
				container
				justifyContent="center"
				className="residencia"
				marginTop={'32px'}
				marginBottom={'24px'}
			>
				<DefaultButton
					type={'submit'}
					variant="outlined"
					color={'secondary'}
					className={`${classes.btn} btn btn-secondary-residencia poppins`}
					onClick={(e) => sendPreInscricao(e)}
				>
					Confirmar inscrição
				</DefaultButton>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme, props) => ({
	inputTextField: {
		width: '100%',
		height: '40px !important',
		paddingLeft: '12px',
		border: 'unset',
		borderRadius: '8px !important',
		backgroundColor: '#1D1D1D !important',
		fontFamily: 'Manrope',
		fontSize: '16px !important',
		color: '#FCFCFC',
		'&::placeholder': {
			color: '#FCFCFC',
			opacity: '0.5',
		},
		'&:hover': {
			transition: 'all 300ms ease',
			backgroundColor: '#303030 !important',
		},
	},
	phoneInput: {
		width: '100%',
		height: '40px',
		border: 'unset',
		borderRadius: '8px',
		backgroundColor: '#1D1D1D',
		fontFamily: 'Manrope',
		color: '#FCFCFC',
		'& .intl-tel-input': {
			width: '100%', // If needed, add !important only here if absolutely necessary
		},
	},
	btn: {
		fontSize: '1.15rem !important',
		width: '30%',
		fontWeight: 'bold !important',
		textTransform: 'uppercase !important',
		// backgroundColor: '#000000 !important',
		border: '1px solid transparent !important',
		// '&:hover': {
		// 	border: '1px solid #ffffff !important',
		// },
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	captionText: {
		[theme.breakpoints.down('xl')]: {
			fontSize: '0.65rem !important',
		},
	},
}));
