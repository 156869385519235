import React from 'react';
import { Container, useMediaQuery, useTheme } from '@mui/material';
import FaqSection from '../Components/Sections/Faq';
import { Header } from '../Components/Header';
import Footer from '../Components/Footer';
import HeaderMobile from '../Components/HeaderMobile';

function FaqPage() {
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile ? <HeaderMobile /> : matchesTablet ? null : <Header />}
				<FaqSection />
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

export default FaqPage;
