import { Divider, Grid, Typography, Collapse } from '@mui/material';
import DefaultButton from '../Buttons/DefaultButton';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';

const DiferencialBoxV2 = ({ icon, title, description, type }) => {
	const classes = useStyles();
	const [openDetails, setOpenDetails] = useState();

	const showDetails = () => {
		setOpenDetails(!openDetails);
	};
	return (
		<Grid
			container
			className={classes.mainContainer}
			alignItems={'center'}
			justifyContent="space-between"
		>
			<Grid
				item
				xs={12}
				container
				alignItems="center"
				justifyContent="space-between"
				style={{ paddingBottom: '1rem' }}
			>
				<Grid
					item
					xs={12}
					sm={8}
					container
					alignItems="center"
					gap={3}
					flexWrap="nowrap"
				>
					<Grid item>
						<img
							src={icon}
							alt=""
						/>
					</Grid>
					<Grid
						item
						sm={10}
					>
						<Typography
							className={`${classes.title} poppins`}
							color="secondary"
						>
							{title}
						</Typography>
					</Grid>
				</Grid>
				{description && (
					<Grid
						item
						xs={12}
						sm={3}
						container
						className={`${type === 'cm' ? null : 'residencia'} ${classes.gridButton} ${
							classes.gridBtnHeight
						}`}
						justifyContent="flex-end"
					>
						<DefaultButton
							variant="outlined"
							color="secondary"
							className={`${classes.btn} btn-secondary poppins`}
							onClick={showDetails}
						>
							{openDetails ? '- Detalhes' : '+ Detalhes'}
						</DefaultButton>
					</Grid>
				)}
			</Grid>
			<Collapse
				in={openDetails}
				orientation="vertical"
				style={{ width: '100%' }}
			>
				<Divider
					style={
						openDetails
							? {
									borderColor: '#a2a2a280',
									borderWidth: '1.5px',
									transition: 'all 0.5s ease',
							  }
							: null
					}
				/>

				<Grid
					className={classes.descriptionGrid}
					style={!openDetails ? { display: 'none' } : null}
				>
					<Typography
						color="secondary"
						className={`${classes.description} poppins`}
						lineHeight={1.25}
					>
						{description}
					</Typography>
				</Grid>
			</Collapse>
		</Grid>
	);
};

export default DiferencialBoxV2;

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		width: '100%',
		backgroundColor: '#6161615e',
		padding: '1rem 2rem 0rem 1.25rem',
		borderRadius: '1rem',
		transition: 'all 0.5s ease',
	},
	icon: {
		[theme.breakpoints.down('lg')]: {
			width: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			width: '80%',
		},
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '1.5rem !important',

		[theme.breakpoints.down('sm')]: {
			width: '100%',
			fontSize: '1rem !important',
		},
	},
	description: {
		display: 'inline-block',
		fontSize: '1.25rem !important',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			fontSize: '1rem !important',
		},
	},
	descriptionGrid: {
		width: '100% !important',
		margin: '1.5rem 0rem 1.5rem 0rem',
		transition: 'all 0.5s ease',
	},
	btn: {
		fontWeight: 'bold',
		width: '50%',
		maxHeight: 35,
		[theme.breakpoints.down('lg')]: {
			width: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			width: '50%',
			fontSize: '.8rem !important',
		},
	},
	gridButton: {
		[theme.breakpoints.down('lg')]: {
			marginTop: 20,
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: 0,
		},
	},
	gridBtnHeight: {
		transition: 'all .2s',
		display: 'flex',
		alignContent: 'flex-start',
	},
}));
